import {roles} from "../components/quiz/quizHelper";

export default {
    "a1": "Yes",
    "a2": "No",
    "a3": "I am not sure",
    "a4": "Yes, definitely",
    "a5": "To some extent",
    "a6": "Remarkably (to a significant extent)",
    "a7": "Somewhat (to a certain extent)",
    "a8": "Not much",
    "a9": "Not at all",
    "a10": "Head of HR or Director of HR department",
    "a11": "Member of the HR team (HR specialist or HR partner)",
    "a12": "HR assistant",
    "a13": "HR consultant",
    "a14": "HR intern",
    "a15": "Micro",
    "a16": "Small",
    "a17": "Medium",
    "a18": "Large",
    "a19": "Public organization",
    "a20": "Private organization",
    "a21": "NGO (non-governmental organization)",
    "a22": "Metropolitan area",
    "a23": "Urban area/city center",
    "a24": "Suburban",
    "a25": "Rural",
    "a26": "Not sure what it is about => take a test (Annex 4)",
    "a27": "1-5",
    "a28": "6-10",
    "a29": "11-20",
    "a30": "21-50",
    "a31": ">50",
    "q2.2": "What is your functional level in HR?",
    "q2.3": "Are you a member of the Executive Committee in your organization?",
    "q2.4": "Do you consider that you have a strategic HR role in your company (organization)?",
    "q2.5": "In your HR role, to what extent would you say you contribute to business strategies (strategic planning in organization)?",
    "q2.6": "In your HR role, how much of your work is related to personnel administration (record-keeping, contracts, compliance)?",
    "q2.7": "In your HR role, how much of your work is related to payroll and benefits administration, and other routine HR activities?",
    "q2.8": "To what extent does your work focus on ensuring HR operations like recruiting, training, performance management etc?",
    "q2.9": "In your HR role, to what extent would you say that you act as a bridge between the organization and its employees (i.e. you listen to your employees and support them)?",
    "q2.10": "In your HR role, to what extent do you focus on creating a positive work environment, promoting an employee engagement and satisfaction?",
    "q2.11": "To what extent would you say you have scope to influence the culture of your organisation (such as promoting inclusivity, fairness, and respect)?",
    "q2.12": "In your HR role, to what extent does your every-day work consist of promoting change initiatives and helping employees and teams navigate transitions?",
    "q2.13": "In your HR role, to what extent does your every-day work consist of facilitating and managing organizational change like driving cultural transformation, organizational restructuring etc?",
    "q2.14": "To what extent does your every-day work consist of fostering a change-ready mindset of employees and managers?",
    "q1.1": "What is the size of your organisation?",
    "q1.3": "Is it a public or a private organization or NGO (non-governmental organisation)?",
    "q1.4": "Where is your company located?",
    "q1.5": "Do you have a policy on sustainability in your organisation?",
    "q2.1": "How many people work in HR in your organization?",
    "st_3_th1": "NWoW/Hybrid",
    "st_3_th2": "Mobility",
    "st_3_th3": "Sustainable IT",
    "st_3_tc0_0": "Stakeholders",
    "st_3_tc0_1": "Internal or external parties involved and their role in contributing to emission reduction.",
    "st_3_tc1_0": "Culture",
    "st_3_tc1_1": "Creating a sustainable culture in collaboration with employees.",
    "st_3_tc2_0": "Tools",
    "st_3_tc2_1": "Concrete instruments to support emission reduction.",
    "st_3_tc3_0": "HR Processes",
    "st_3_tc3_1": "HR Processes roles in contributing to emission reduction.",
    "st_3_tc4_0": "Inspiring Initiatives",
    "st_3_tc4_1": "Effective actions or practices already taken by companies.",
    "st_3_tc5_0": "Recommended Actions",
    "st_3_tc5_1": "Guidance helps HR achieve consistency in target setting.",
    "selectValueOption": "-- Select Value --",
    "noInformation": "Based on your selection there are no information in this section",
    "hybridStakeHolders": [
        {
            "roles": [roles.EmployeeChampion, roles.AdministrativeExpert, roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div>
<p dir="ltr">While in charge of Human Resources Management and Work Organisation, the Human Resources Director/Manager is only partly responsible for matters relating to the New Ways of Working.</p>
<p>In order to contribute to the reduction of company's carbon emissions, the Human Resources Director/Manager has to work closely with different stakeholders, identified as:</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2 dir="ltr">CEO/Top Management</h2>

<h3>Define a Vision</h3>

<p>CEOs must recognize the leadership role they personally need to play to embed sustainability deep and wide in their organizations. The CEO and Executive Committee have to define a vision, establish a clear line of ethics and values in terms of sustainability, and articulate ambitions for sustainability and carbon reduction. At the end, they answer on what is the commitment, the direction the company is taking.</p>

<p>This will help managers and employees to connect sustainability to company's mission, and to their own daily work.</p>

<h3>Exemplarity / Walk the Talk</h3>

<p>Research shows that exemplarity is crucial and has a strong influence on employees’ change acceptance. It is essential 'to walk the talk' and provide employees with clear examples of what is expected and valued. Exemplarity can be practiced at diverse levels.</p>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/image-do44gt28.png",
        },
        // {
        //     "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
        //     "heading": "Facilities",
        //     "description": "Combination of Responsibilities \n" +
        //         "\n" +
        //         "It is no coincidence that we are now seeing more and more HR Directors who are also in charge of facilities, with the aim of integrating a reflection on occupancy rates, the dimensioning, and optimisation of spaces, the adaptation of heating technologies, etc.\n" +
        //         "\n" +
        //         "Close Cooperation \n" +
        //         "\n" +
        //         "If Human Resources and Facilities remain two separate departments, then it is important to establish close cooperation. Facilities can help to reduce energy consumption through investments in high-efficiency installations, low-polluting alternative energies such as photovoltaic panels e.g. \n" +
        //         "\n" +
        //         "They can manage heating according to the people on-site and/or explore with HR regrouping people on the site or floor/close site or floor with few people. \n" +
        //         "\n" +
        //         "Prospective \n" +
        //         "\n" +
        //         "Study future real estate needs, both on the side of employees and companies: the latter may be encouraged to bring together distributed local sites into a more central location, which naturally implies a longer commute. \n" +
        //         "\n" +
        //         "Coupled with the potential for urban drift, these practices will need to be analysed for their impact on air quality, actual mobility variations, and leisure mobility. It is important to analyse trends in the use of third places (which could maintain residual travel and multiply real estate) and housing extensions: remote work could lead to the acquisition of larger dwellings to have a dedicated place for telework.  \n"
        // },
        // {
        //     "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
        //     "heading": "Communication",
        //     "description": "Communication Strategy \n" +
        //         "\n" +
        //         "To really change behaviors related to work organisation, it is important that companies set up a communication strategy and that the effort is deployed on a regular basis. The message must be hammered continuously.\n"
        // },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Sustainability Manager/Team</h2>

<p>The Sustainability manager, or team is a key actor to involve in NWoW Projects which aim towards the carbon emission reduction.</p>

<p>Experience sharing is key and the company can initiate or participate in a network of Sustainability managers in a sector, industrial park, or geographic area to share knowledge and good practices.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Trade Unions</h2>

<p>If you develop a project on NWoW or Hybrid Work using a top down approach, without involving trade unions, without making sure you have ambassadors, and without testing things on the ground, it won't work. You also have to be prepared to go back when you make a mistake and accept to review your plans.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Maintenance department </h2>

<h3>Combination of Responsibilities</h3>

<p>It is of no coincidence that we are now seeing more and more HR Directors who are also in charge of maintainance of facilities, with the aim of integrating a reflection on occupancy rates, dimensioning, and optimisation of spaces, the adaptation of heating technologies, etc.</p>

<h3>Close Cooperation</h3>

<p>If Human Resources and Facilities remain two separate departments, then it is important to establish close cooperation. Facilities can help to reduce energy consumption through investments in high-efficiency installations, low-polluting alternative energies such as photovoltaic panels e.g.</p>

<p>They can manage heating according to people on-site and/or explore with HR regrouping people on the site or floor/ close site or floor with few people.</p>

<h3>Prospective</h3>

<p>Study future real estate needs, both on the side of employees and companies: the latter may be encouraged to bring together distributed local sites into a more central location, which naturally implies a longer commute.</p>

<p>Coupled with the potential for urban drift, these practices will need to be analysed for their impact on air quality, actual mobility variations, and leisure mobility. It is important to analyse trends in the use of third places (which could maintain residual travel and multiply real estate) and housing extensions: remote work could lead to the acquisition of larger dwellings to ensure a dedicated place for telework.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": '',
            "description": `<p dir="ltr">To really change behaviors related to work organisation, it is important that companies set up a communication strategy and that the communication is done on a regular basis. The message on sustainability needs to communicated continuously.</p>`
        }
    ],
    "mobilityStakeHolders": [
        {
            "roles": [roles.StrategicPartner, roles.EmployeeChampion, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<p>The Human Resources Director/Manager is only partly responsible for matters relating to work mobility (mobility to and from work).</p>
<p>In order to contribute to the reduction of company's carbon emissions, the Human Resources Director/Manager has to work closely with different stakeholders, as:<br>
</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing has-scrolled" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>CEO/Top Management</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Business Location</h3>

<p>Business location is defined as a place or structure occupied by a company to run its operations. When we look at the rationale behind the choice of location for companies, one criterion is not negligible and generally appears to be in the top five: the employment pool in which the company is going to position itself. This is a consideration which is rather upstream from the perspective of HRDs.</p>

<p>When your organisation has relocation plans, investigate a ‘stay or go’ decision, consider merging multiple offices or opening a satellite office, it is important to analyse associated impacts and opportunities for commuting. Choose a location near a train or metro station, and with access to other public transports.</p>

<h3>Positive Approach</h3>

<p>One of the key points to work on is to make the Top management and the company understand what it can gain by approaching the work from home in the morning, to home in the evening, and seriouselly consider mobility as an integral part of the work activity. Once this is the case, it is easier to understand why the company needs to spend time on this, why it needs to put energy and resources into it, or take certain measures about it. It would be beneficial to move away from the administrative constraints associated with the obligation to draw up a mobility plan, and instead to take a positive approach.</p>

<h3>Opportunities and… Constraints</h3>

<p>Supporting the process for less carbon-intensive mobility is never the core business of a company - and it will not become so. The amount of flexibility in terms of the constraints that can be imposed is not so great. The first step is therefore to establish the level of constraints that the company is prepared to impose.</p>

<h3>Walk the Talk</h3>

<p>If initiatives are taken in favour of more carbon-friendly mobility, it is essential that they are used and promoted by top management in an exemplary manner. Among the plans studied outlined in our Study, a leading figure championing the plan is a key to success.</p>
</div></div></div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/image-Yp7OiIAH.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Finance</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>The amount of money that companies are willing to spend on mobility at a strategic level is usually extremely small compared to what they may spend on tax optimisation, or HR service providers. It was evaluated to be between eight to ten times less for awareness-raising activities. It is impossible with such low ambitions to make a significant difference. Unfortunately, we see that working on mobility plans is still perceived as a constraint by companies, and not as a real opportunity to change their environmental impact. Yet, the return on investment on mobility projects can be direct and significant.</p>

<p>The HR Director will be able to make progress in this area by developing a realistic business case with concrete figures.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Communication</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Communication Strategy</h3>

<p>To really change behaviours related to mobility, it is important that companies develop a communication strategy and that the effort is regularly deployed. The message must be hammered continuously. Gamification, for instance, is a great opportunity here, by making it light-hearted, with prizes to win. Creating a logo and/or a brand for the workplace mobility plan can help attract and maintain attention.</p>

<ul>
\t<li>
\t<p>At SWIFT in Belgium, a "charity challenge" was organised to encourage cycling during the mobility weeks: for every kilometre cycled, 1 euro was donated to cycling-related associations. This system was very successful with employees, as it combined extrinsic and intrinsic motivation.</p>
\t</li>
</ul>

<ul>
\t<li>
\t<p>At the Service Public de Wallonie in Belgium, a "Round the world cycling tour" was organised: participants were asked to enter their work-related cycling journeys on an online form as part of a collective challenge to cycle 40,075 kilometres together over 26 weeks. Destinations were collectively reached, with anecdotes, goodies, and a diploma at the end. It used the dynamics of levels and badges, while playing on the escape, unpredictability, and creativity of the participants.</p>
\t</li>
</ul>

<p><em><strong>Source: UWE Mobility Unit</strong></em></p>

<h3>Focus on the Right Thing</h3>

<p>Mobility should not be approached directly from an environmental angle. The environmental dimension will be the positive result, the objective towards which we must strive. But it is not the communication tool: people may not change for environmental reasons, but rather for reasons of convenience, efficiency, community... In short, for reasons linked to human behaviours.</p>

<p>Employees generally do not change their behaviour because it decreases the company's carbon footprint, but because they are going to have new options to better manage their families, make their journeys more pleasant... and, ultimately, have a positive impact on the planet.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Mobility Manager</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Appoint a Mobility Manager</h3>

<p>A Mobility manager is an internal person who leads the mobility project and, ideally, reports to the general management, because the mandate is transversal: communication, HR, facilities, etc. The person can come from any department - and, why not, from HR - but must have direct contact with top management and staff representatives. This person must then be trained in mobility issues.</p>

<h3>A Mobility Manager is Not Enough</h3>

<p>Mobility remains the expression of individual freedom outside of any service constraints. From an individual freedom perspective, for example the normative role of the car remains very important. There is therefore a lot of convincing and educating to be done. HR Directors can act on travel related to work (for example in the transport sector, or if the company employs mobile employees), but it will be more difficult for the home-work commute, which partly touches the private sphere. They are then much more dependent on people's goodwill, or on what people are willing to do, consciously or unconsciously.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Data Manager</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Becoming actors of change means companies must invest the resources – financial, human, and time – to conduct a thorough examination of their current mobility practices and how individual variables (age, gender, employment position, commuting routes, etc.) affect their preferred mode of transportation. Gathering information through surveys, employees, supplier and customer interviews and other tools is crucial in crafting an appropriate response. Companies already have an enormous amount of data and, if they are willing, often have the necessary elements on hand to progress in a simple and inexpensive way.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Trade Unions</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>If you produce mobility plans with a top down, without involving the trade unions, without making sure you have ambassadors, and without testing things on the ground, it won't work. Mobility is one of the most sensitive subjects in companies. And once damage is done, it is extremely difficult to get back into a positive dynamic. You also have to be prepared to go back when you make a mistake and accept to review your plans.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Public Authorities</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>When drafting a successful carbon-neutral mobility plan, it is essential to get a clear engagement from territories and official institutions. Local planning is crucial. And usually, policies are the main motor for change: 60% of corporate shifts in mobility planning are motivated by the wish to align (or to comply) with national, regional, or local policies.</p>

<p>The development of remote working will be regulated by the employer, but it also has to be integrated in a public policy perspective. If remote working practices are allowed to develop without specifying the objectives sought from a public policy point of view, there is little chance of achieving them.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content" style=""><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Public Transport Operators</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>If Mobility or HR Managers of companies, even from different sectors, work together in a 500- to 800-metre area around a transportation hub - a train station, a metro station, etc. - they will be able to start a dynamic and, above all, to go and meet the public authorities, the public transport operators and the mobility services and say to them: 'There are, let's say, 30 of us; we have identified such and such a need; what can we do about it'. If just one company comes, it will have no impact. With 30 companies, the situation changes. You need this scale to have an impact.</p>
</div></div></div>`
        }


    ],
    "sustainableStakeHolders": [
        {
            "roles": [roles.StrategicPartner, roles.EmployeeChampion, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<p dir="ltr">The Human Resources Director/Manager is not directly responsible for the internal information and telecom systems within the company. But s/he can raise staff awareness of sustainability issues related to digital technologies, and influence their use.</p>
<p>To contribute to the reduction of company's carbon emissions, the Human Resources Director/Manager has to work closely with different stakeholders as:</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div>
<p><strong><span style="font-size:1.5em;"><h2>CEO/Top Management</h2></span></strong></p>
<div class="h5p-image-hotspot-popup-content overflowing has-scrolled" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Defining a vision</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>CEOs need to recognise the leadership role they need to play to embed sustainability deeply and widely in organisations.</h3>

<p>The CEO and Executive Committee must define a vision, establish a clear line of ethics and values in terms of sustainability, and express ambitions on sustainability and carbon emission reduction. What are company’s commitments and directions regarding the responsible use of information and telecommunications technologies?</p>

<p>This will help managers and employees make the connection between sustainability and company's mission, and then to their own daily work.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Governance</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Another challenge consists of integrating responsible digital criteria into company's overall governance in every project linked to IT. This does not mean that certain projects will not be carried out, but rather that the right questions will be asked to improve the approach — for example, not to develop certain little-used functions that would waste resources.</h3>

<p>This spirit applies to IT, but could very well extend to all company's departments, including HR: more and more companies are now associating a carbon budget with their various projects.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Exemplarity / Walk the talk</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Research shows that exemplarity is highly critical and has a strong influence on employees’ change acceptance. It is essential for top management to ‘walk the talk’ and provide employees with clear examples of what is expected and valued. Exemplarity can be practiced at diverse levels.</p>
</div></div></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/image-ns6btF0w.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-929571f3-be1a-4ea3-9a15-a42c546e1f03"><p><strong><span style="font-size:1.5em;">Chief information officer (CIO) / IT Director</span></strong><br>
</p>

<p>The CIO/IT Director and its department are the stakeholders in charge of developing company’s IT strategy and ensuring that all systems necessary to support its operations and objectives are in place.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e92dda56-c129-4b24-b8c1-a4b82751c7d8"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-65a10d763bf1e.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-46916a4a-0b71-4b36-b198-90c8bdb5325f">

<p>In the current state of knowledge and practice, IT cannot be «sustainable ». But it can be «responsible », in the sense of limiting its impact on the environment. Responsible digital is not just about «Green IT » — reducing the environmental footprint of digital technology — or «IT for Green » — using digital technology to reduce the environmental footprint in other areas. There are two other axes: «Human IT » and « IT for Human ». These axes question how to reduce the negative social impacts of digital technology (for example, accessibility issues for people with disabilities) and how to put IT at the service of people.</p>

<p>Therefore, it is important to make sure you work with IT on responsible behaviours. Otherwise, you risk making recommendations that IT will not support, sometimes simply because they don't even know about it. It is important to have a concerted and consistent approach.</p>

<p>Example — An expert explains how he worked with a large French multinational on a Green IT project. For them, IT represented 2% of the global carbon footprint. But given the size of the organisation, this impact was very significant. The executive committee decided that all departments, no matter their size and carbon footprint, should find ways to halve this footprint. From that moment on, corporate cohesion was found in a joint effort, within and across teams.. They tried to understand what was happening in the other departments, how to help each other, etc.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-R5KB7XDY.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-26d6c56f-23dd-4489-9f7a-dbb45a9d4ded"><p><strong><span style="font-size:1.5em;">Procurement </span></strong></p>

<p>The IT department is responsible for the technical specifications and the frequency of replacement of the equipment, together with procurement.</p>

<p>The main impact of IT from an environmental point of view is first and foremost the manufacture of equipment, well before data centres and networks. The real problem is the speed of replacement: we tend to replace our equipment far too quickly.</p>

<p>For example, a smartphone in Belgium has a lifespan of 18 to 23 months, whereas from an electronic point of view, it can last 7 or 8 years without any problems, or even longer.</p>

<p>Therefore, it is important to have a consistent policy in terms of sustainable use of equipment within the organisation.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-638dde93-1fe4-4d1b-87dc-7c7cd5340826"><p><strong><span style="font-size:1.5em;">IT and Telecom Hardware Suppliers</span></strong></p>

<p>Suppliers of digital solutions and devices are important stakeholders as they possess information and knowledge on the energy efficiency of the products they develop and sell, the product life cycle, product maturity, and the possibilities for its recycling.</p>

<p>Along with procurement staff, HR professionals can involve IT suppliers by asking them about the origin of products. Suppliers could also impart their expertise by training staff on the benefits of sustainable use and energy consumption, and of proper disposal of electronic waste.</p>

<p>80% to 90% of the environmental footprint of the device is already present when it is first switched on. Particularly, the extraction of minerals and the pollution linked to their purification to obtain the metals needed to manufacture digital devices has a major impact. To get a gram of gold, one must extract no less than a ton of rock! For other metals, it is even worse than that. It is estimated that it takes more than 200 kilograms of ore to make a 120-gram smartphone. This production will emit around 80 kilograms of CO2 equivalent (the unit of measurement for greenhouse gas emissions).</p>

<p>Furthermore, the recycling capacity of these metals is extremely low. In a smartphone, there are about 60 metals, among which we only can recycle 17. The rest is thrown away. The key message is to buy less equipment, take care of it, and keep it for as long as possible.</p>

<p>If you don't use an item of equipment anymore, don't put it in a drawer, but give it a second life by donating it to associations, reselling it second-hand, reconditioning it, etc. Today, it has become possible for a company to buy reconditioned equipment.</p>

<p>HR Directors have a role to play in raising awareness of good digital practices and more specifically explaining why it is important to take care of one's equipment. </p>


</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-JgxtRsaR.jpg"
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-ba79971c-3cf7-4525-ad9a-494e0afef16a"><p><strong><span style="font-size:1.5em;">Regulators </span></strong></p>

<p>Regulators are important stakeholders as they develop requirements related to Green IT — such as energy efficiency standards, e-waste disposal regulations, and carbon footprint reporting requirements. These regulations are becoming very strict in the EU and cover a wide scope of behaviours. Organisations will be subject to increasing regulations in favor of digital responsibility. The digital field is set to become increasingly standardised, as evidenced by the European Parliament's July 2017 vote on a resolution for longer product lifetimes.</p>

<p>For HR Directors and their teams, Regulators are an important source of information and knowledge on how to develop organisational policies and strategies which cover the responsible use of IT and the digital sobriety and how to incorporate them into human resources management. Therefore, it is important for you to communicate and collaborate with them as opportunities arise, meeting them at training, workshops, info days which are sometimes organized by regulators.</p>


</div>`
        }


    ],
    "hybridCulture": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d621e8b2-0c32-40ec-a524-408f805b4851"><p><strong><span style="font-size:1.5em;">The Culture is Key</span></strong></p>

<p>Acting in favour of a reduction of carbon emissions involves the cultural variable. It is truly through work on the organisational and managerial culture, as well as on more rational use of travel and IT tools, for example, that we can achieve changes in behaviour and consumption that will make the balance more positive for the environment.</p>

<p>Check the consistency of company's environmental policy with its overall culture. How can this be done? By examining the practices, habits, and incentives already in place to check whether they are consistent or contradictory with the environmental strategy.</p>
</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-ec71dbde-552a-4919-89c6-8adb3e59180f"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-659b0e3914c5e.png" alt="" title="Culture is Key" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": 'https://nwow4net-zero.eu/images/t1/file-b6WaEZDM.png'
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4db97028-02a0-45df-b3dc-a2400e1f6992"><p><span style="font-size:1.5em;"><strong>Focus On the Right Thing</strong></span></p>

<p>Remote work is not intrinsically "better" for the environment. There is just as much research showing that this would be the case, as there is research demonstrating the opposite. The company, for example, is not going to stop heating and lighting the workspaces because some employees are working elsewhere, at home, or in another space.</p>

<p>The company's energy consumption might therefore remain the same. But the energy consumption at workers' homes will consequently be higher. There is, of course, a seasonal effect here, which can vary from one country to another – even though heating can be replaced by air conditioning.</p>

<p>In addition, several studies show that travel is not eliminated and that there is even a tendency to travel more, such as running a small errand, continuing to drop/pick up the children from the nursery or school, going out for lunch, etc. There would thus be additional travel, but shorter trips. As a result, in the end, we are talking about an "uncertain" eco-balance.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e93095af-95fc-4a5d-b59d-b465dbb8ebde"><p><span style="font-size:1.5em;"><strong>Raising Awareness</strong></span></p>

<p>Awareness raising and education on workplace behaviours based on scientific evidence are one of the main levers available to HRDs. Informing employees about the environmental cost of their work behaviour is always interesting. It is even better when this awareness-raising, information, or training is driven by a strategy, in line with the activities, and/or with the ambitions of the company to achieve carbon neutrality.</p>

<p>This triggers the multiplication of initiatives.</p>

<p>Usually, the focus on remote/hybrid work is mainly placed on well-being/work organisation, and less on sustainability. Inform and train employees on the stakes of climate change and on the impact their work behaviour can have on carbon emission reduction. Awareness is facilitated by constant interaction and dialogue.</p>
</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a2f1c3ca-765a-48bc-9ae8-5bf7b0657d0b"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-659b0e6194c3e.png" alt="" title="Raising Awareness" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-2brlApB0.png"
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-56f5683d-ac8f-457a-aa74-6ceab16d8394"><p><strong><span style="font-size:1.5em;">Initiate a Participatory Approach</span></strong></p>

<p>Develop a participatory approach involving employees, who are sensitive to, or active in the field of sustainability, and make them ambassadors to get other employees on board.</p>

<ul dir="ltr">
\t<li>collecting ideas, \`</li>
\t<li>sharing best practices,</li>
\t<li>making proposals,</li>
\t<li>etc.</li>
</ul>

<p>Several studies conducted in Western countries in 2022 have shown that most employees see the value in hybrid work and that work flexibility is one of the most important factors in deciding whether to accept a job offer. The norm that is gradually emerging in large companies is 3 days in the office per week.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d36a5e02-5941-4954-acb4-08a1432622b7"><p><strong><span style="font-size:1.5em;">Pay Attention to Tensions </span></strong></p>

<p>You need to identify the tensions that will emerge when it comes to pursuing both economic performance and social and environmental performance objectives, in both short-term logic - which characterises organisations today - and longer-term logic - in which the notion of sustainability is anchored.</p>
</div>`
        }

    ],
    "mobilityCulture": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-067d5e0e-e4fb-43be-84fa-825384498127"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-6509dd97d9c6f.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8dc96038-5902-4fae-b1bc-6ff31e4bd15f"><p><strong><span style="font-size:1.5em;">Influence Behaviours </span></strong></p>

<p>Organisations cannot influence where employees choose to live, as it would be an obvious breach of their privacy. It is hard to counterweigh commuting to work, even when parameters such as cheaper housing or better personal life quality are considered. Work with champions to sensitise colleagues.</p>

<p><strong>Example:</strong></p>

<p>Wever is a platform created in France that proposes to reinvent inclusive and efficient mobility by placing the user and his or her expressed needs at the heart of the design of the offer. It gives everyone the opportunity to express their needs, to test new solutions and to vote to implement the most interesting ones in the long term.</p>
</div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9772c0de-3792-4fe2-a5f6-899984bb576a"><p><strong>How does it work?</strong></p>

<ol>
\t<li>A mobility diagnostic is used to collect the travel habits and needs of employees. It only takes a few minutes to collect the needs of each individual and establish a diagnosis.</li>
\t<li>
\t<p>A dashboard enables the continuous monitoring of the diagnoses carried out and to see the recommendations for action with comments. This visual tool allows the offer to be co-constructed with the users.</p>
\t</li>
\t<li>
\t<p>The algorithm detects mobility opportunities by revealing existing solutions and modelling new acceptable solutions.</p>
\t</li>
\t<li>
\t<p>With individualised support for change and a logic of proximity rewards, the transformations are visible in a few weeks.</p>
\t</li>
</ol>

<p>More information:<a target="_blank" href="http://www.wever.fr/">www.wever.fr</a></p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-0lBHW18J.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-065d48e1-ed65-4c87-803a-8063f0d2bb9b"><p><strong><span style="font-size:1.5em;">Make it Concrete</span></strong><br>
</p>

<p>Don't do campaigns on work-related mobility with posters in the lift. You may not change any behaviour by doing it. Go to the cafeteria three times a week to talk to people, take an interest in their mobility and their concerns, and suggest ways forward. In short, personalise your approach for each person. This takes time and energy. Another example is to create a bicycle group, and hold bicycle events on- and off-site. You could also ask convinced colleagues to make a presentation or speak on the topic.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6d757e35-654c-416e-a38f-5fbf7e44a845"><p><strong><span style="font-size:1.5em;">Address Car Parking Issues</span></strong></p>

<p>Car parking is a central issue: companies developed measures ranging from incentives (a financial reward for not bringing one’s car on site for instance) to penalties (introducing on-site parking charges or a maximum number of days/week or month to use the car park).</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8cb77e76-e0c3-439c-9f90-d582dd82222c"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650a0615ebf86.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-jONe6a2U.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42f4fadb-eb58-4f8a-a545-94bbab8675ab"><p><span style="font-size:1.5em;"><strong>Initiate a ParticipatoryApproach</strong></span></p>

<p>Set up a Mobility cluster involving voluntary staff members to collect ideas and suggestions, and develop internal ambassadors. Some organisations delegated the role of mobility/travel plan management to line managers, to create some in-group emulation.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-a57aaa91-50f0-4dce-b73b-23410fda6402"><p><strong><span style="font-size:1.5em;">Promote Thinking Outside the Box </span></strong></p>

<p>Encourage people thinking about the reason for travel before choosing/thinking of the way/mode of travel to work.</p>




</div>`
        }
    ],
    "sustainableCulture": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-056e0c6d-cc34-469f-8ced-3b7f996472c6"><p dir="ltr"><strong><span style="font-size:1.5em;">Raising awareness</span></strong></p>

<p>Depending on the source, digital technology now accounts for 3 to 4% of global greenhouse gas emissions. Compared to other sectors, this share remains modest, but the annual growth in digital consumption should raise concerns for companies, warns the Institut du Numérique Responsable (France/Belgium). In service companies, for example, the top three carbon emission items are travel, buildings and IT — in an order that can vary from one organisation to another. IT can at times be the main item, unbeknownst to the company because it is not measured or is assessed poorly. Mobility, for instance, is very clearly associated in people's minds with environmental issue, but IT is much less so.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-192ec88b-a197-4536-95ee-13ea3703a0e2"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b2348b9ee9.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-HLGgm3YH.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-59071f32-37c3-4315-89a9-cd5beb84ac23"><p><strong><span style="font-size:1.5em;">Promoting understanding </span></strong></p>

<p>It is key to learn to distinguish the essential from the accessory. If you want to reduce the environmental impact of digital technology, you need to know that limiting the number of emails will only have a marginal impact — although this is an action that is often advocated. The main negative impact of digital technology on the planet comes from equipment and their premature renewal. We must not fight the wrong battle.</p>

<p>Let us distinguish very early on between direct effects, indirect effects and rebound effects, and therefore value the positive actions that really make a difference.</p>


</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-4abab26f-e818-4d12-b498-6b40280bffee"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b67a5efee2.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-19JBhPTu.jpeg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8fee0210-9975-43b3-b9a3-862916f098b6"><p><strong><span style="font-size:1.5em;">Encouraging Digital Sobriety </span></strong></p>

<p>Responsible IT does not mean that we will/should encourage the reduction of all digital uses. In fact, people are increasingly asking themselves how to reduce the negative environmental impact of digital use, since everything around them is digital. The whole society has become digital. We can hardly do anything without it.</p>

<p>The real challenge is therefore to remove everything that is futile. And, here, HR can provide some valuable answers.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-80a48b73-1d1c-416d-8cbd-ff20039a441d"><p dir="ltr"><strong><span style="font-size:1.5em;">Promoting a responsible use of IT / Telecom technologies </span></strong></p>

<p>As far as equipment use is concerned, video emphatically plays the most negative role. As much as 80% of internet bandwidth is used for video — of which only a quarter is for video conferencing or video calling, and the remaining three quarters is used for streaming platforms, such as Netflix, YouTube, adult content, etc.</p>

<p>Turning off your camera during an individual call makes little difference. Things escalate during a group video session. The COVID-19 crisis was a strong witness of this change. Intense use of video entailed a drastic change in bandwidth allocation and data centre equipment – which is precisely where the real impact of remote working lies.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-c9087ca2-6557-4911-9f64-03a6d9a035df"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b6812dd3f5.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-16ec8bc4-3636-4635-b576-83bb425e0be5"><p>The consequences of the pandemic were twofold: on the one hand, a whole series of companies that were not equipped to enable remote working had to over-equip themselves — buying extra laptops, screens, etc. On the other hand, all the people who could not work remotely and were stuck at home during the lockdown ended up watching Netflix, with real bandwidth impacts. The network providers added more hardware to increase their capacity, causing a significant impact in terms of environmental footprint.</p>

<p>It should be remembered that a responsible use of technology is a very marginal dimension, compared to the impact of the extremely rapid renewal of equipment and data centres.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-re4wePLp.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0cddd6a4-52aa-42a3-8484-5539737f8a1d"><p><span style="font-size:1.5em;"><strong>Double check for coherence </strong></span></p>

<p>Today, everyone is taking a stand on sustainable development. The reality is more nuanced, however: some companies truly want to change their business model — fully aware that it could be a prerequisite to remain in certain markets in the short or medium term —, and others that are doing some fairly effective greenwashing.</p>

<p>A third category of companies sends concerningly mixed messages. They advertise for sustainable practices, yet invest massively in new, energy-greedy technologies such as the metaverse. This would be the equivalent of having a manager advocating for greener mobility practices while booking a jet for a short-distance trip. The net zero dynamic reveals profound human nature, between creating facades and fostering a true desire to act.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-7192ddeb-d87d-4c96-8d91-5f579d91095c"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b68e420112.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-cd099baa-8a59-455f-aa3c-1529a2f405a5"><p dir="ltr">A third category of companies sends concerningly mixed messages. They advertise for sustainable practices, yet invest massively in new, energy-greedy technologies such as the metaverse. This would be the equivalent of having a manager advocating for greener mobility practices while booking a jet for a short-distance trip. The net zero dynamic reveals profound human nature, between creating facades and fostering a true desire to act.</p>

<p>The HR Director/department has a role to play in being the conductor of all departments in the organisation. Let's take the example of training: what is the point of training in digital responsibility and sobriety if, at the same time, you propose a major training programme about the metaverse for technicians?</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-h1GH98Y5.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5c6fe4df-bd40-485d-9629-7de6aeb00952"><p><span style="font-size:1.5em;"><strong>Promoting creative thinking </strong></span></p>

<p>Encourage people to think about ways they can reduce greenhouse emissions through responsible use of IT.</p>


</div>

<div class="h5p-image-hotspot-popup-content overflowing has-scrolled" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Digital detox challenges</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">Organise digital detox challenges for employees, encouraging them to take a break from technology for a specified period of time, such as a day or a week. This can be done as an individual challenge or as a team-building activity.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Screen-free breaks</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">You can encourage employees to take screen-free breaks throughout the day, by providing opportunities for physical exercise, mindfulness practices, or other non-digital activities.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Digital-free meeting policy</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>You can establish a policy that discourages the use of digital devices during meetings.</p>
</div></div></div>
</div>`,
            "imageSource": "https://nwow4net-zero.eu/images/t3/image-eeGDl5ev.jpg"
        }
    ],
    "hybridTools": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9f6a9bf2-ee6b-4dea-beb7-8f269fdd47e6"><p dir="ltr"><strong><span style="font-size:1.5em;">Establish a Sustainability Initiative Task Force or Green Team/Ambassadors</span></strong></p>

<p dir="ltr">Not much can be done without motivated people behind it. Identify the likely change agents in the organisation, and if possible try to locate them at several levels and departments. Try to solicit them for the idea by exploring possibilities and benefits of hybrid work for the reduction of carbon emissions.The attitude is there, the motivation too, the position although purely voluntarily will provide them with a means/title for action within the organisation working well with their intrinsic motivation for taking care of the environment.</p>
</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-658bbecb-b411-40b2-8187-4d368eb13db1"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a076563db5.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-GBdvjo7v.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4efad5a4-1ecd-417f-a6e1-c245bfb5ecbe"><p><strong><span style="font-size:1.5em;">Audit Office-Related Consumption </span></strong></p>

<p>Some examples of actions below (source: <a target="_blank" href="http://www.sustainablebusinesstoolkit.com">www.sustainablebusinesstoolkit.com</a>):</p>

<ol>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Eliminate Single-Use Cups:</strong> Single-use cups are harmful to the environment. Plastic and Styrofoam cups are not biodegradable can spend years in landfills or make their way into our oceans and rivers. Try carrying around a reusable bottle or thermos for your water or coffee. If quitting single-use cups altogether is too jarring, then consider using environmentally friendly cups instead.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Institute a BYORB policy </strong>(Bring Your Own Reusable Bottle)</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Use Recycled Printing Paper:</strong> Putting recycled paper in your printers is an easy-go-green idea. With today's technology, you and your colleagues won't even notice the difference.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Print Double-sided:</strong> Make sure your computer and printer are automatically set to print double-sided. According to the research, printing double-sided can reduce your paper waste by 10-40%.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Go Paperless </strong></p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Try Recycled and Biodegradable pens</strong>, rather than throwing away plastic pens.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Decrease the Brightness on Your Computer Monitor.</strong> According to a Harvard University professor, reducing your computer monitor’s brightness from 100% to 70% can save up to 20% of the monitor's energy. Most people will not notice the difference from making this adjustment, so it is a quick and easy-go-green idea.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Turn Off Your Computer Monitor at Night</strong> to save energy.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Implement Waste Walks.</strong> A Waste Walk is when employees take a step back, walk the floor and identify wasteful activities. It is a fun way to get everyone involved in creating ideas to decrease your office’s impact on the environment. Waste Walks don’t require additional funds, resources, or third parties. They take advantage of everyone’s brainpower to identify low hanging fruit and make improvements.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Get Rid of Individual Trash Cans:</strong> Some offices have trash cans at every individual’s desk. You will increase your recycling rates and reduce waste after removing individual trash cans and utilizing central trash cans instead.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Buy Eco-friendly and Responsibly Sourced Coffee.</strong></p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Optimize the Use of Natural Light. </strong>If your office has windows, then be sure to optimize natural light. Avoid turning on light bulbs during the day by keeping curtains, shades, and blinds open.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Invest in Energy-Efficient Lighting: </strong>Using energy-efficient lights saves electricity and money. Energy-efficient lights come in all shapes and sizes. There is something for every need and every budget!</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Invest in Smart Plugs:</strong> Offices can automatically save energy by using smart plugs. Smart plugs turn off lights, fans, and appliances automatically. Most smart plugs allow you to control your devices from anywhere using an app on your phone.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Adjust Your Thermostat 2 degrees up in the summer and 2 degrees down in the winter. </strong>Alternatively, you could use an energy-efficient thermostat that conserves energy by itself.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Encourage Water Saving with Tips and Instructions in Bathrooms. </strong>Try to swap the signs out and use creative designs to keep the message fresh.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Choose repairing over replacing.</strong></p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Organize green competition events with your team</strong></p>
\t</li>
</ol>

<p><strong>Tip: </strong>Taking baby steps instead of drastic changes is the best way to achieve your environmental goals. Rather than incorporating large changes which may overwhelm some of your employees, focus on making one little transformation in each area of your workplace.</p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-44756050-178f-436e-893f-651f0a73f718"><p><strong><span style="font-size:1.5em;">Carbon Footprint Assessment on Remote Work Policy and Practices</span></strong></p>

<p>A survey by Reuters of 20 major corporations that embrace remote work found that only half had attempted to estimate the emissions associated with remote workers, and even fewer had attempted to mitigate or offset the impact. Even Microsoft—which has an extraordinarily robust corporate sustainability program and started estimating remote worker emissions in 2020—is still trying to get a handle on the carbon impact of its off-site workers.</p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-kVIDIWNk.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-96673788-aac0-4bb1-a147-1f060a8bad55"><p><strong><span style="font-size:1.5em;">Energy Consumption Indicators </span></strong></p>

<p>There are measurement tools, but they come with several challanges. The first is that the measurement is based on what a site consumes and, often, companies have several sites in very different geographical areas and with very different histories. The second is the need to factor in an entire ecosystem, including workers and suppliers. Bringing them into the equation and having an impact on them is much more complicated.</p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-72db514d-8a33-4eb4-a525-83ba1cf3c4c2"><p><strong><span style="font-size:1.5em;">Hybrid-Manager Scheduling</span></strong></p>

<p><strong>Adopt a policy that takes into account the overall impacts on travel and the environment (e.g. avoid remote working on Mondays and Fridays, which is used by most companies).</strong></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a0e4f2b1-4e13-4f99-b95f-0ef8a62df29a"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a0d17a1b25.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-23380b42-0403-427c-ad5c-a2eb83219cfa"><p>The model proposed by David Autissier could be interesting in this respect. It invites us to question the work methods to be considered in terms of face-to-face and remote work through the notions of 'Build' and 'Run' borrowed from agile methods. Remote working works well in the Run - i.e. in known, recurring activities - but is less adapted for the Build - i.e. all activities that involve investment in the future, such as new projects, for example. In other words, we know how to remotely manage the current activity, for which we are processed, but less so the one that requires interactions, exchanges, trust and collective intelligence to get started. In this case, face-to-face will probably be preferable.</p>

<p>As a result, the HR Director can stimulate managers to think with their teams about the times or tasks when it is important for everyone to be there, together, and for what purpose; and about the times and tasks that can be gained by conducting certain activities online. For example, an operational project monitoring meeting will be more effective if conducted online. As for welcoming a new staff member to the team, it is better to do it in person. A carbon impact criterion can then be added to this reflection. This can lead to tasks being grouped together to make a remote work cluster and others to make a face-to-face work cluster, taking into account all the parameters: the number of people involved, the distances they will have to travel, and modes of transportation, office occupancy rates, the impact in terms of digital use, etc.</p>

<p>The idea is not to have a mathematical approach, but rather an opportunistic one: if a group meeting can have a better result than what would be achieved remotely, despite a higher environmental cost, it is probably still relevant to conduct it face-to-face. It will be a question of choice. The interest will be in being able to equip oneself with the means to decide by constantly weighing up the different parameters of work organisation.</p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-9MJjwGNw.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6ab0511b-b00e-4b8e-af60-873709089171"><p dir="ltr"><strong><span style="font-size:1.5em;">App to Allow Office Space Optimisation Related to Remote/Hybrid Working</span></strong></p>

<p>Various apps exist on the market in helping to minimize effort on workspace arrangement and better manage office layout as well as employee attendance. Please see our selection of tools for impact assessemnt in result 3.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-27fceef1-f99c-4f3a-adc2-ca263f01efff"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a11737328b.png" alt="" title="" style="width: 100%; height: 100%;"></div>

</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-kUM3Ss3g.png",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-79477eba-a9a7-4660-bb64-90d2f1600a3e"><p><strong><span style="font-size:1.5em;">Organise Regular Workshop/Lunchs &amp; Learn on Sustainability@theoffice</span></strong></p>

<p>Sustainability Workshop is intended to make it easy and accessible for all to learn the broader context of how to use sustainability at the office, and enable them to develop strategies to incorporate sustainability considerations into their daily work.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-783f311b-b0bc-455e-8ff2-8602b11a7275"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a12151f7ae.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            "imageSource": "https://nwow4net-zero.eu/images/t1/file-357JJyIZ.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9e69f91d-beba-4166-86c4-9e3c563b3d40"><p><strong><span style="font-size:1.5em;">Climate Fresk</span></strong></p>

<p>In just 3 hours, the collaborative Climate Fresk workshop teaches the fundamental science behind climate change and empowers to take action. By using, activating the group’s collective intelligence, Climate Fresk enables participants to take ownership of the subject matter. The methodology doesn’t use a hierarchical learning structure, allowing everyone to take part and find their place in the exercise. As participants link the cause and effects of climate change, they are able to take a step back and understand the systemic nature of the challenges for the first time.</p>

<p dir="ltr"><strong>More information:</strong> <a target="_blank" href="https://climatefresk.org/">https://climatefresk.org/</a></p>
</div>`
        }
    ],
    "mobilityTools": [
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4fba2945-7e82-4e52-8e74-afa0bdfe1f8b"><p><strong><span style="font-size:1.5em;">Conduct Mobility Analysis</span></strong></p>

<p>Conduct a carbon emissions assessment of your company: dedicate an expert to identify and focus on the most impactful topics (mobility, plane travel, building, etc.). Then conduct a mobility scan based on accurate and current data.</p>

<p><strong>Examples:</strong></p>

<p>Mobility Label, tools for a smarter commuting</p>

<p><a target="_blank" href="https://mobilitylabel.com/">https://mobilitylabel.com</a></p>

<p><strong>Mob Box </strong>aggregates and compiles mass raw data. From internal corporate fleet data to external open-source data, it turns figures into straightforward maps and intuitive charts. It will help you to get a clear view on your employees’ mobility. Assess the overall modal mix and analyse the CO2 emissions and the related costs.</p>

<p><a target="_blank" href="http://www.mob-box.eu/">www.mob-box.eu</a></p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-08185ad9-d286-407c-8269-b3bcecba9cc2"><p><strong><span style="font-size:1.5em;">Explore available public solutions </span></strong></p>

<ul>
\t<li>
\t<p>In France: the <a target="_blank" href="https://www.ecologie.gouv.fr/sites/default/files/mobilite%20durable%20en%20entreprise.pdf">Forfait Mobilité Durable</a> (Sustainable Mobility Package)- each company can subsidise or finance its employees' home-work journeys up to 800 euros per year, exempt from tax and social security contributions, as long as they are used in 'green' means of transport - public transport, electric scooters, shared electric cars, carpooling…</p>
\t</li>
\t<li>
\t<p>In Belgium: the <strong>Budget Mobilité</strong>(Mobility Budget) allows companies to offer employees who have a company car a budget for more environmentally friendly mobility options —<a target="_blank" href="https://lebudgetmobilite.be/fr" target="_blank">https://lebudgetmobilite.be/fr</a></p>
\t</li>
</ul>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/image-V0D782D4.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-eb98b054-5f2c-454c-9a29-05efff3cce27"><p><strong><span style="font-size:1.5em;">Individual diagnostics / Mobility Surveys </span></strong></p>

<p>Look at how people travel to their workplace and offer them alternatives, by means of individualised marketing actions. There is a whole process of diagnosis and support for people to show them that the car is not necessarily the most efficient way to move around. A more in-depth analysis (by following people for several days) can show them that there are other ways of doing things, less motorised, more economical, etc.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d7ebb14-1b14-4a0b-a034-151adcc5f3bb"><p><strong><span style="font-size:1.5em;">Multimodal Itinerary Platforms</span></strong></p>

<p>Multimodal itinerary platforms can show the different options and allow people to realise that travelling by car will be less efficient than with other modes of transportation. Some of these tools display the CO2 emissions associated with different modes of travel.</p>

<p><strong>Example:</strong></p>

<p>Skipr, the all-in-one solution for managing corporate mobility</p>

<p><a target="_blank" href="http://www.skipr.co/">www.skipr.co</a></p>

<p>Skipr fights against single-user car use in the city. It provides employees with an application that allows them to see how to get from A to B by combining the different means of transportation that exist around them: public transport, shared vehicles, electric scooters, green vehicles to get to the station, etc. It is a co-pilot that will secure them the option of not taking their car. It allows buying public transport tickets on the company's account, combined with a payment card, linked to the same ‘mobility budget’, for alternative types of mobility. For the company, it provides a management platform that will do two things: set up the company guidelines (determine who is entitled to what) and provide a reporting tool that gives full visibility on the savings generated and the reduction in carbon emissions compared to the use of a traditional car. Employees have a dashboard with their own situation, but the company only has aggregate reports. When searching for travel options, employees receive three pieces of information: the arrival time, the cost in euros and the CO2 emissions.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4bf3cd48-c7e8-4a5f-a5a3-4727c4478547"><p><strong><span style="font-size:1.5em;">Encourage Carpooling </span></strong></p>

<p>Conditions of success: flexibility of working hours is one of the first reasons for not carpooling between colleagues. Not having the same working hours is a limiting factor, especially when personal constraints - picking up the children, having to run errands, etc. - are added. Having a project manager, who with a table and a pencil, records journeys and matches them up, can help. If you leave it up to people to arrange it between themselves, it's more complicated. If you leave it to an application, it's complicated too. Digital tools work very well for long distances, but they are not as effective for short distances, recurring and daily use.</p>

<p>However, there are platforms to facilitate carpooling with matching options, information on fiscal benefits, and tracking possibilities to orient communication. It is important to note that carpooling does not work well when the work site is near a good offer of public transport.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e97d6b7b-85ca-4b8e-adc4-c549a30315f2"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650ae2ffdc5cc.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-tomPlVwo.jpeg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f2705860-d508-4b16-9089-9bba70468e72"><p><strong><span style="font-size:1.5em;">Shuttle </span></strong></p>

<p>Several organisations with the highest growth in public transport use had implemented a free, dedicated company shuttle connected to local towns and/or local train and bus stations. A partnership included organisations that increased the number of bus services arriving at the site by an impressive average of about 14 extra buses in the peak hour in one research. Having appropriate and tailored services appeared more important than absolute numbers: cheaper fares, negotiated tickets, improved off-site infrastructure/service quality, and funding for new bus stops on or near the site, for example.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c293a877-1d7a-44a0-8b05-524e78f4b318"><p><strong><span style="font-size:1.5em;">Cycling </span></strong></p>

<p>Factors most closely correlated with having high levels of cycling appeared to be having high quality off-site access or improving off-site access; increasing available parking for cyclists; and offering a cycle repair services. It was also notable that most of the organisations in the study had provided showers, changing, drying, and/or locker facilities; negotiated discounts on cycle equipment.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-457e418e-7a81-4932-82ba-2fcbf1e21fcd"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650a17655699c.jpg" alt="" title="Cycling" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-rCJUADxS.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-801a1d35-3531-4738-807c-79f59dd9e601"><p><span style="font-size:1.5em;"><strong>Electric Cars? Yes, But…</strong></span></p>

<p>The electric car is not a solution to traffic jams. A car is still a car, whether it's electric, hydrogen, petrol or diesel, it takes up space on the road. It does not emit 'zero CO2', it emits less: it all depends on how the electricity was produced. This is the difficulty with mobility issues: there is no such thing as one size fits all. Everyone will have their own specificity. With electric cars, there are also problems linked to cost and charging to be taken into account. There are still obstacles. Things are changing fast, but there is no miracle solution.</p>

<p>Install charging infrastructure for electric cars: they are necessary to increase the use of electric cars.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-07fc7d73-8673-4f28-8e07-2c87d26235f0"><p><strong><span style="font-size:1.5em;">Bike leasing (with electric bikes)</span></strong></p>

<p><strong>Example:</strong></p>

<p>Lease a bike (Belgium)</p>

<p>As an employer in Belgium, you can choose to offer bicycle leasing in various ways : free of charge or with a (partial) contribution to the bicycle. In all scenarios, employees enjoy a tax advantage of around 40%.</p>

<p>\`You do not pay any benefit in kind on a leased bicycle, because you use the bicycle regularly for commuting. You can also pay out a bicycle allowance. This motivates your employees to cycle. The bike leasing regulations make no distinction between a leased and a private bike. Results? Fitter employees: 1-2 days less absenteeism and a powerful contribution to a green mobility plan.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6c224ae6-6652-45b9-a674-13597a5bb424"><p><strong><span style="font-size:1.5em;">Eco-driving Training </span></strong></p>

<p><strong>Example:</strong></p>

<p>Being ecologically responsible when you’re on the road calls for more than an environmentally friendly vehicle. You need to have a green driving style. And this is something your drivers learn in the E-positive training programme, a one-year training course by ALD Automotive.</p>

<p><strong>How it works:</strong></p>

<p>«We start with what your drivers can do; this puts their current driving styles and their vehicle usage on the cards. After that, they follow an e-learning course and receive training – individually and in a group.The instructor gives them constant personalised tips and points they need to pay attention to. The result? At the end of the training, everyone drives with an e-positive attitude.»</p>

<p><strong>10 tips for Ecological Driving</strong></p>

<ol>
\t<li>
\t<p>Shift to a higher gear at an appropriate time. With a diesel engine, anywhere from 2,000 revs and with a petrol engine, 2,500 revs.</p>
\t</li>
\t<li>
\t<p>Time to get moving? Push your accelerator in fully, because speeding up slowly will certainly not save you any fuel.</p>
\t</li>
\t<li>
\t<p>Keep sufficient distance from the vehicle in front of you. Then you won’t need to constantly brake and accelerate.</p>
\t</li>
\t<li>
\t<p>Do you need to slow down or stop? Don’t go straight to the brake, but release the accelerator in good time. You’ll slow with engine braking and without riding your clutch.</p>
\t</li>
\t<li>
\t<p>Turn cruise control on for consistency – and therefore environmental friendliness.</p>
\t</li>
\t<li>
\t<p>Will you be at a complete stop for more than 20 seconds? Then turn your engine off.</p>
\t</li>
\t<li>
\t<p>Plan your route in advance so that you don’t click up any unnecessary mileage.</p>
\t</li>
\t<li>
\t<p>You don’t need your roof box, bike- or baggage rack? Take it off and cut down your air resistance.</p>
\t</li>
\t<li>
\t<p>Regularly check your tyre pressure.</p>
\t</li>
\t<li>
\t<p>Get your vehicle serviced punctually: only then will you be able to drive economically and safely.</p>
\t</li>
</ol>

<p><em><strong>Source: ALD Automotive</strong></em></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-0c1c9678-0a6c-445a-95ce-4a25e6395d09"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650a17e559c50.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-mbYWXvUd.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3e08a77a-372e-478d-a71b-1e82dbff5942"><p><strong><span style="font-size:1.5em;">MaestroMobile </span></strong></p>

<p>MaestroMobile is a serious game developed by Espaces-Mobilités in Belgium, in the field of mobility that aims to change behaviour through experimentation.</p>

<p>More information:<a target="_blank" href="https://www.maestromobile.eu/">https://www.maestromobile.eu</a></p>

<ul>
\t<li>The <strong>Mobility Van</strong>is made up of mobility experts who travel to public spaces and companies and show employees all the mobility solutions in a practical way (test of micro mobility machines, bicycles, applications…)</li>
\t<li>The <strong>City Game </strong>is an urban serious game that is organised in the form of a teambuilding and allows participants to test different mobility solutions in a practical way while (re)discovering the city, completing challenges,answering quizzes, etc. By using the codes of popular games such as Pokemon Go or the principles of urban races, the City Game has created its own universe related to the discovery of mobility services.</li>
\t<li>In the same way as fitness programmes, the <strong>Mobility Passport</strong>offers a mobility challenge that takes participants through the various stages of learning about multi mobility, from getting to know a service to using it, over a period of about a month. Through a mobile application, the participant obtains validations of their skills and is awarded certificates that take the form of a stamp on their mobility passport.</li>
\t<li><strong>GO Mobility</strong>is a hybrid platform that offers a complete programme of coaching and discovery of mobility alternatives.</li>
\t<li><strong>Critical MaaS</strong>is a personalised coaching to experiment with new mobilities and change your way of moving. Accompanied by experts, you test all the new services and become a master of your mobility.</li>
</ul>




</div>`
        }
    ],
    "sustainableTools": [
        {
            "roles": [roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-de5d235d-00c3-4b16-93a5-164773aa7ff5"><p dir="ltr"><strong><span style="font-size:1.5em;">Sign the «Responsible Digital » Charter </span></strong></p>

<p>With the signature of this charter, organisations affirm their commitments to enter resolutely into a "Responsible Digital" approach.</p>

<p>Link : <a target="_blank" href="https://isit-be.org/wp-content/uploads/2021/03/Sustainable-IT-Charter-ISIT-BE.pdf">https://isit-be.org/wp-content/uploads/2021/03/Sustainable-IT-Charter-ISIT-BE.pdf</a></p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1fb3c548-59b6-43b1-a623-fb6df78af94b"><p><strong><span style="font-size:1.5em;">Measurement </span></strong></p>

<p>In order to shed light on the impact of our over-consumption of digital technology, the Institut du Numérique Responsable has created a simple calculator that allows individuals to assess their professional digital impact.</p>

<p>Another measurement tool, called WeNR, evaluates the footprint of the Information System of organisations to enable them to identify where the impact lies and to identify their level of Digital Responsibility maturity, both qualitative and quantitative. This tool takes the form of an online questionnaire, free to use and accessible to all organisations, whether large or small. Produced in partnership with La Rochelle University and EIGSI, WeNR is based on the work of a thesis financed by La Rochelle Agglomeration and a scientific collaboration between INR France, INR Switzerland, the Belgian ISIT and the University of Louvain (UCLouvain).</p>

<p>WeNR comes in 3 versions to meet the needs of as many organisations as possible: from the qualitative maturity audit to the comparative audit by sector of activity, detailed quantitative and actionable.</p>


</div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>WeNR Light</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">This questionnaire, available online, allows to assess the level of maturity of organisations in terms of Digital Sustainability (People-Planet-Profit). (end of September 2022).</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>WeNR Standard</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">WeNR takes the form of a set of quantitative and qualitative questionnaires to be completed by each participating organisation, using a specific file template provided by the ISIT. This questionnaire is accessible online until September 9, 2022, the data is then treated confidentially and asynchronously with the results being submitted 2 to 3 months later in the form of a first-level report.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>WeNR Plus</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>This version of the tool is reserved for ISIT member organisations. WeNR + uses the standard WeNR model and calculator. It provides comprehensive and detailed reports from a quantitative, qualitative, and comparative point of view with organisations in the same sector.</p>
</div></div></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e98ca209-cede-40c3-a2b8-680db2729109"><p dir="ltr" role="presentation">​​​​​​</p>

<p dir="ltr">The analysis tools provided allow for the identification of courses of action to establish a sustainable IT strategy.<br>
To access these tools: <a target="_blank" href="https://wenr.isit-europe.org/">https://wenr.isit-europe.org/ </a></p>

<p>Assess your individual/professional digital environmental footprint in kg CO2 eq.</p>

<p dir="ltr">To shed light on the impacts linked to our overconsumption of digital technology, the ISIT has created a calculator that allows us to simply calculate our professional digital impact. This assessment covers:</p>

<ul>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Equipment</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Online usage</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Cloud storage</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Sending mails</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Business travels</p>
\t</li>
</ul>

<p dir="ltr">And shows your impact compared to</p>

<ul>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">the average carbon footprint of a French person</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">the quota to be respected in 2050 to comply with the Paris Agreement</p>
\t</li>
</ul>

<p dir="ltr">To access the calculator: <a target="_blank" href="https://myimpact.isit-europe.org/">https://myimpact.isit-europe.org/</a></p>

<p dir="ltr"></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-5a88bc86-804b-4334-a3d1-e2a3f3b4ff81"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b78923fbf8.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-75c025a2-65b8-4dba-99fa-ada96ab7a13c"><p>fruggr is an application developed to measure the environmental and social impact of the IT applications of companies. Regular measurement is the key to continuous improvement. fruggr automatically collects various raw data: data usage, source code, and analytics. The software relies on recognised impact factors (One byte model of the Shift Project, Ademe carbon base, IEA, EEA...) to calculate the most accurate scores possible. fruggr offers a carbon assessment covering all 3 scopes. The tool also offers a certificate rating grid.</p>

<p dir="ltr">Link: <a target="_blank" href="http://www.fruggr.io">www.fruggr.io</a></p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/image-MJ9YeML0.png",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1f715907-f760-4fe6-a7a5-f73d1d0a9432"><p><span style="font-size:1.5em;"><strong>A Strategic Roadmap </strong></span></p>

<p>The priority must be the definition of a strategic roadmap for responsible digital technology, based on concrete and effective measures, with the definition of indicators, implementation of a skills-development programme, signing of a charter, participation in global events such as Digital Clean-up Day (usually in March), a global awareness day about the environmental footprint of digital technology.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-ba27207c-8a81-48a3-bf0e-b3e4e01270bc"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b790538667.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-91a70d9f-e554-44da-be68-4187d51895b9"><p>As in many other areas, the main issue is inaction and lack of transparency. The roadmap must also be realistic, paced, and sustainable if it is to be successful. Launching the idea that any given organisation could be carbon-neutral within six months is a dangerous allegation, for instance. It is impossible to achieve, illusory and utopian. This would ultimately discredit the project itself.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-G9NDge8J.png",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-29debb56-e454-4201-9ad4-89a80df5fe27"><p><span style="font-size:1.5em;">Sustainable IT MOOCs</span></p>

<ul>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">ISIT and its partners are proposing a MOOC on Sustainable IT to enable everyone to be informed and trained. The Awareness Sustainable IT MOOC is a short training program allowing a first approach to Sustainable IT for everyone. The complete Sustainable IT MOOC consists of 4:30 of video, text, and interactive content. It offers theoretical knowledge to master the fundamentals of the Sustainable IT Approach. These two modules are in free access: <a target="_blank" href="http://www.isit-academy.org">www.isit-academy.org</a></p>
\t</li>
</ul>

<ul>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">The Mooc Environmental impacts of digital technologies aims to address the impact of Digital on the environment, its positive and negative effects, the phenomena observable today, and the projections we can make for the future. It is intended for educational mediators and more generally for the public. Their webpage offers for each part a presentation of the content, an entertaining and educational video, ready-to-use teaching capsules, concept sheets to deepen certain notions and a bibliography with additional resources. Link: <a target="_blank" href="https://learninglab.gitlabpages.inria.fr/mooc-impacts-num/mooc-impacts-num-ressources/en/index.html">https://learninglab.gitlabpages.inria.fr/mooc-impacts-num/mooc-impacts-num-ressources/en/index.html</a></p>
\t</li>
</ul>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4a03a0c2-6e3d-4bc5-9ca9-83c9b0f413ac"><p><strong><span style="font-size:1.5em;">Responsible Digital Best Practices Guide for Organisations</span></strong></p>

<p>The objective of this guide is to raise awareness of the impacts of certain practices encountered within organisations, and to share examples of responsible digital practices that can help reduce the environmental footprint of digital technology. This guide is intended for all types of organisations, in the public and private sectors. Some examples of good practices are more adapted than others depending on the context and maturity of the responsible digital approach within organisations. More responsible digital is a vast subject, which does not only concern the digital department. Many cross-functional departments such as purchasing, human resources, communication, general services, etc. are also involved. This guide is therefore aimed at a wide audience in relation to information systems such as IT departments, system and network administrators, developers, buyers, users, etc.</p>



</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8a1b99af-3853-4ab2-b59f-c54163ae8d5f"><p><span style="font-size:1.5em;"><strong>Digital Sobriety Certificate</strong></span></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-678b08eb-b4f7-4548-9eeb-d4b9fcbb8217"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b79a54737b.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0c979019-a342-4766-b91c-2a64ee1540b3"><p>A digital sobriety certificate considers that users can significantly reduce their environmental impact through their choice of equipment and online behaviour. Inspired by the energy saving requirements introduced in several European countries from the year 2000 onwards, these certificates would make it possible to internalise the environmental externalities of digital technology and correct certain failings of the digital market. It may be that in the years to come, the use of these certificates could develop in companies, with the idea that it would be up to the HR function to collect data from managers on what has been put in place to encourage employees to reduce their use of digital technology: meeting policies, sending emails, hybrid work, video conferences, etc.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-2mBACaFf.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-138519da-a516-4b09-8269-bf6315bd58ce"><p><strong><span style="font-size:1.5em;">Responsible Design of Digital Services</span></strong></p>

<p>Shifting to a responsible design of digital services means creating value by designing digital products and services that are more efficient from an environmental, social, and economic point of view. This falls in line with the new performance indicators that companies must now face: more than just economic performance, organisations must also provide evidence of their social, and environmental performances. This does not mean that certain projects will not be approved, but rather that the design of the digital services needs to be improved — for example, not to work on developing certain little-used functions that would waste resources.</p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-19678c96-8c5b-41a1-999d-10f2fce2a381"><p><strong><span style="font-size:1.5em;">Use the auto switch-off hardware/features</span></strong></p>

<p>Cap Gemini assessed 24 sustainable IT use cases. The analysis shows that the automatic shutdown of hardware and functionality delivers the greatest savings (14% on average) through reduced energy consumption while reducing carbon emissions in this category.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-625facb2-24e7-4f6e-a22b-082c8dbf4d7d"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b7a2a045c2.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-iNB15wRc.jpg",
        }
    ],
    "hybridHr": [
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-47f54b42-11d9-482f-8720-84285c76de62"><p dir="ltr"><strong><span style="font-size:1.5em;">HR POLICIES</span></strong></p>

<p>It is important to <strong>develop an HR policy to raise awareness on sustainability</strong> and try to shape to some extent the organization's actions in this area at several stages: onboarding, ongoing training, performance management, compensation.</p>

<p dir="ltr">Before developing the HR Sustainability policy conduct a cross-sectional study that involves NWoW, and its impact on employee well-being and sustainable development goals.</p>



<p dir="ltr">While developing the HR policies ensure coherence of all policies to the objectives of carbon emission reduction.</p>
</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-40fb375e-8fd6-46af-9670-4af0cb630dbb"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a000f9022c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-VtIux57G.jpg"
        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-51d2007f-0995-4d97-be89-ede2d14b5c4b"><p><strong><span style="font-size:1.5em;">RECRUITMENT</span></strong></p>

<p><strong>Evaluate candidates’ awareness and values related to sustainable development and their compatibility with the ambitions and objectives of the organisation</strong></p>

<p>When hiring, for example, will we look at candidates’ carbon footprint? Will we check whether their behaviour is aligned with the organisation's ambitions?</p>

<p>The challenges linked to the climate constitute a formidable lever of attractiveness for candidates in search of meaning and motivation for employees who are already committed to the issue.</p>

<p>That said, it is increasingly the candidates and workers who come up with these questions: what is the company’s mobility offer, is there a remote working policy, what initiatives are in place to promote sustainable development, etc.? If the company does not have a clear policy, it will be condemned to improvisation and to case-by-case responses, which may fail to attract promising candidates.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-82f5014d-e54b-4a0f-8cab-1a43a166b8b1"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a005f92b37.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-5DD74X27.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0c101637-6a96-4185-86a2-7b6ad760abbd"><p><strong><span style="font-size:1.5em;">TRAINING</span></strong></p>

<p><strong>Explain why sustainable transformation is an issue for companies and employees.</strong></p>

<p dir="ltr"><strong>The first challenge </strong>is about understanding what is at stake, starting with science: what scientists are saying about climate change, natural resources,biodiversity and the impact on our lives - communities, businesses, individuals.</p>

<p><strong>The second challenge</strong> is about action: sustainable transformation affects all professions.Job by job, you have to understand how, then draw up your roadmap for action thanks to specific courses depending on whether you work in Purchasing, IT, legal, Finance, Marketing, HR, etc."</p>

<p><strong>Train managers</strong> to manage the commitment of employees: how do you react when, for example, the best salesperson, when coming to a seminar with the team, reminds them that they should no longer fly?</p>

<p>Introduce in libraries of competences the ecological dimensions.</p>

<p>Anticipate tomorrow's skill requirements: what will be the skills of the new accountant, the new IT specialist, the new buyer, the new HR, etc. in the context of sustainable transformation?</p>

<p>Integrate the environmental criterion into the choice of service providers, particularly for training. It is always possible to inform and challenge service providers on this criterion of choice, in order to encourage them to take action.</p>
</div>
<img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a00b6f3b38.jpg" alt="" title="" style="width: 100%; height: 100%;">
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-H35LwF6W.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-78bed962-f188-4975-8f73-b0dea716bef1"><p><strong><span style="font-size:1.5em;">PERFORMANCE MANAGEMENT &amp; EVALUATION</span></strong></p>

<p>Companies are increasingly setting sustainable development objectives for themselves, which must be implemented in departments and teams in order to be achieved. It is therefore important to evaluate what managers do, with their teams, to achieve those objectives.</p>

<p><strong>Integrate sustainable development issues in the annual objectives of employees and in their skills development plan.</strong></p>

<p>Very few companies to date translate these sustainability objectives into performance indicators. When they do, they generally refer to the 17 United Nations Sustainable Development Goals, but it needs to be much more concrete than that.</p>

<p>Take it as far as daily behaviour: take the sustainability aspect into consideration when organising a meeting, when organising a company party, when deciding on a training offer, when making choices about travel, etc.</p>
</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-bcf69465-7d98-4b54-8f57-16c62f1e31cf"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a010e1bb7c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-xUv8hn2l.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c5702d04-36d7-48a5-bdc3-dfd6ae0b7e6e"><p><strong><span style="font-size:1.5em;">REMUNERATION</span></strong></p>

<p><strong>Incentivize and appreciate green behaviors.</strong></p>

<p>Whether in the choice of benefits that make up the salary package, or in the integration of criteria linked to sustainable development in the remuneration of directors, managers, and even of all employees. If employees achieve their results, but at the expense of the environment, will the bonus be questioned?</p>




</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-31d857a2-1f29-40e7-bf53-f14345717159"><p><strong><span style="font-size:1.5em;">WORKING FLEXIBILITY</span></strong></p>

<p>Offer alternative workplaces as close as possible to where employees live (third places, coworking spaces, etc.). coworking spaces, etc.</p>


</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-335b1072-9195-49d8-bb14-1838c832c36b"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a013f7d826.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-aSD8KIPy.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<p><strong><span style="font-size:1.5em;">COMMUNICATION</span></strong></p>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-Er7RBrXn.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.EmployeeChampion],
            "heading": false,
            "description": `<p>Communicate on a regular basis rather than on an ad hoc basis. Annual communication on the subject will not, for example, be enough if you want to get your employees on board and involved.</p>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": false,
            "description": `<p>Be authentic and transparent, especially about the progress that remains to be made. Your discourse must be aligned with the reality of the company's climate trajectory, or it will not be well received by your employees.</p>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": false,
            "description": `<p>Target your communications to specific topics. For example: "toolkit to act on a business level for the ecological transition"; "how to invest your employee savings in funds serving the environment"; etc.</p>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": false,
            "description": `<p>Diversify the formats (memo, video, podcast, presentation, etc.) and channels used (email, intranet, corporate social network, road show, etc.) in order to maximise the reach of your messages.</p>`
        }
    ],
    "mobilityHr": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing has-scrolled" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>WORK ORGANISATION - Formalising Remote Working Agreements</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Until three or four years ago, remote work was largely informal. Today, it is becoming more and more formalised due to its widespread adoption in large organisations following the health crisis. This formalisation can take the form of a company policy and/or discussions between the employer/managers and the unions/employees on the guidelines within which the employer wants to frame remote work. Faced with the saturation of public transport and road networks, there is a challenge to develop remote work as a way to regulate these mobility flows.</p>
</div></div></div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/image-rWDYpb9a.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>REWARDS - Sustainable Mobility Package</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>In the same way that public transport is covered for employees, such a scheme offers a form of compensation for those who come to work using ‘virtuous’ modes such as cycling or carpooling, for example. As far as company cars are concerned: diesel cars to be forbidden, replacement of gazoline vehicles with zero-emissions vehicles.</p>

<p><strong>In France,</strong>employers can pay a sustainable mobility package to their employees who use a sustainable means of transport for their business trips. The following means of transport are covered: personal bicycle (including electric bicycle),carpooling as passenger and driver, shared mobility services (electric or hybrid car sharing, rental and self-service provision of scooters or bicycles), public transport (excluding season tickets). The maximum amount of the sustainable mobility package is €700 per year and per employee. The amount paid is exempt from social security contributions and income tax.</p>

<p><strong>In Belgium,</strong>the bicycle allowance is a mileage allowance granted by an employer to employees who use a bicycle to travel all or part of the distance between their home and workplace. The allowance is intended to cover the costs of the cyclist,but above all it is intended to encourage more employees to use their bikes. The kilometre allowance is tax-free up to EUR 0.25 per km travelled for the financial year 2023, income 2022. In other words, as long as the amount of the mileage allowance does not exceed EUR 0.25 per kilometre travelled, there is no tax to pay. If the employer grants a higher mileage allowance, this surplus is taxable as professional income.</p>

<p><strong>Check what exists in your country!!!</strong></p>
</div></div></div>`,

        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>WORKING TIME - Encourage Soft Mobility Travel</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>The employer can credit mobility time as working time, but only in the case of soft mobility travel - i.e. any mode of transportation that does not use a combustion engine, nor emits greenhouse gases. This has the added advantage to give the person the opportunity to travel outside peak hours.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>RECRUITMENT - Recruiting Locally</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Recruiting by taking into account the distance between home and office in the candidate selection could be used as a parameter. But it will be all the more difficult to activate when the job market is tight, or profiles are rare to find. With the very strong experience we have acquired in remote working - imposed by Covid-19 –, local recruitment continues to make sense in itself but seems even more difficult to practice.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>WELL-BEING - Mobility Matters</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Mobility is one of the pillars of well-being at work. The approaches can then be very transversal. Promoting cycling can, for example, contributes to improving physical fitness, stimulating concentration at work, and even fostering community life.</p>
</div></div></div>`
        }
    ],
    "sustainableHr": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-752b8e3c-11a1-4c42-973e-dc3ef7f73ad2"><p dir="ltr"><strong><span style="font-size:1.5em;">Be involved as a Strategic Partner </span></strong></p>

<p>Traditionally, HR teams do not take part in the development of Sustainability strategies — particularly in the case of Responsible IT strategies —, nor in their implementation. As is the case for most «green » initiatives, top management tends to foster trends and relies on middle management to implement them. However, because of their implication in staff hiring and their impact on corporate culture, HR members can be instrumental in the introduction of these standards.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8eb9a5dd-38d3-4496-b38d-d53e12fad37c"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b6c7a88e25.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-365fb268-3738-49d5-bf8b-e5a5432ff57a"><p>As an HR Director you need to support the development of Responsible IT standards across the organisation and collaborate with the IT department in defining tasks, roles, and responsibilities of employees.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-nt1UFmSu.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-ad83967d-c3aa-4c3a-b142-0ba6278bcfe8"><p><strong><span style="font-size:1.5em;">Formalise Responsible IT Behaviours </span></strong></p>

<p>Formalise Responsible IT behaviours of employees through the development and introduction of policies. These policies may cover:</p>

<ol>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Cloud Usage Policies, which define optimised use of cloud services by employees, contractors, and third-party vendors.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Policies requiring employees to power off their computers at the end of the day, or requiring employees to use smartphones and tablets, instead of desktop computers or laptops that consume higher amounts of energy and increase the carbon footprint.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Policies for responsible use of IT equipment. Good examples are the following policies :</p>
\t</li>
</ol>

<ul>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Implement a Bring Your Own Device (BYOD) policy -Employment contracts could stipulate that the employee can use his or her own computer equipment and that he or she should be compensated for this.</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Introduce a Corporate Owned, Personally Enabled (COPE) policy -A second lever is the use of work equipment for personal use at home. In the past, it was extremely rare for companies to allow this.</p>
\t</li>
</ul>

<p><strong>In either case, these approaches ensure that people do not end up with a work laptop and a home computer. It is possible to plan a dual boot to start the laptop in two ways, one for work use and the other for personal use.</strong></p>



<p><em>Good practice</em></p>

<p dir="ltr"><strong>Work via a «cafetaria plan »</strong></p>

<p dir="ltr"><span style="font-size:0.875em;"><em>(if your national regulations allow this type of system)</em></span></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-c400386d-d3f1-4075-8e89-74d85fed26e6"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b6ea96369c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-96f393ca-7d6a-4bd8-ab36-66d978b4130a"><p dir="ltr">In Belgium, the «cafetaria plan » is a programme that allows the worker to select certain elements of the remuneration themselves, but always within a framework defined in advance by the employer.</p>

<p dir="ltr">In such a framework, you could give incentives for the purchase of reconditioned Equipment over new devices, the same way you can subsidise electric bikes more easily, and big cars less easily.</p>

<p dir="ltr"></p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-EUUxAwta.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-497686b5-fb48-4cd7-83ee-6654c0d12df9"><p dir="ltr"><span style="font-size:1.5em;"><strong>TRAINING - Decrease usage by raising awareness and training</strong></span></p>

<p>Training is an essential dimension: HR Directors/departments will have to ensure that the people in their organisation have the capacity to carry out a responsible digital strategy, so as to understand, integrate and deploy it. Without the brains and the people behind it, it remains wishful thinking.</p>

<p>There are various good practices to be transferred, whether it is about equipment, the use of video, or even e-mails.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-b33c71b4-d6d9-4523-a973-e59050b4fc11"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b6f84025db.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-84dfb087-83d8-46cb-a6c5-9d79a26fbeca"><p>For example, rather than sending documents by email, it is better to share them through a common folder. A link to a shared document allows people to have a single copy to work on, rather than sending commented documents to each other that are replicated on several servers. When sending an email, instead of extensive recipient lists, carefully choose the people who are really concerned.</p>

<p>Deleting emails is sometimes recommended. However, it is also true that in some cases, email clearing can have more negative impacts than keeping them — which might sound counter-intuitive! Bundle-deleting (for instance all emails before a certain date) is a responsible decision, which should be enacted whenever possible. However, painstaking, case-by-case email deletion uses extensive IT resources and becomes more environmentally costly than keeping them in storage.</p>

<p>The important thing is not to send data in the first place if it is not useful. Sending data consumes as much as one to two years of storage.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-7f6e4a48-84b3-4f31-8416-0206ba746cd6"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b6f743acbb.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d162dbf3-2ecf-4474-b605-3dae52c594d3"><p>A team chat will usually be better than emails, especially as it allows exchanges to be found in the history and the information is then stored in one place. But, again, as long as we stick to text, this is marginal. It is more likely to be the uploading of videos and, to a lesser extent, photos that has a real impact.</p>

<p>Before creating a new tutorial, it's best to check that there aren't already some existing ones on the topic.</p>

<p>Some examples of skills to be acquired in training:</p>

<ul dir="ltr">
\t<li role="presentation">Understanding of global environmental issues</li>
\t<li role="presentation">Understanding of the impacts of digital technology</li>
\t<li role="presentation">Notion of the life cycle of a device</li>
\t<li role="presentation">Understanding the life cycle of a digital service</li>
\t<li role="presentation">Identification of the consumption factors on a web page, in software, etc.</li>
\t<li role="presentation">Identification of the direct and indirect impacts of a technology</li>
\t<li role="presentation">Mastery of the issues and good practices related to the purchase of equipment, the management of Waste Electrical and Electronic Equipment (WEEE) and reuse</li>
\t<li role="presentation">Ability to conduct an awareness campaign internally or with suppliers and service providers, etc.</li>
\t<li role="presentation">Accessibility and eco-design of digital services</li>
\t<li role="presentation">Ability to measure energy consumption during the use phase of digital equipment</li>
\t<li role="presentation">etc.</li>
</ul>

<p dir="ltr"><em><strong>Source</strong>: Responsible Digital Best Practices Guide for Organisations, 2022</em></p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-KOyYsXyp.jpeg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d4f52298-4fcd-41dd-bbc1-ff9edd5a07c9"><p><strong><span style="font-size:1.5em;">DATA &amp; ANALYTICS - A practice that needs to be questioned</span></strong></p>

<p>The HR function has not escaped the hype of analytics and data... Rightly so?</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-29bc9c0c-9fcc-47fc-adfa-8dc6e8b82f1c"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b6fcf40937.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3afc5921-814b-40b5-be49-8f4c1b457dbe"><p dir="ltr">This is worth questioning, and for several reasons, according to experts of the Institut du Numérique Responsable. Very often, HR services use vast amounts of data to function within the company. Such data is often iterated and compartmentalised in different places. We must avoid any duplication of data by trying to have central points where the primary data is available and where, when in need, we can go and find it. It is then no longer necessary to copy, copy, copy... and thus multiply the quantities of data.</p>

<p>On the other hand, it is true that data analytics is on the rise in HR. The larger the structure, the more interesting the statistics can be. But in many cases, in our countries, companies are small, albeit a few exceptions. We must therefore question the real relevance of such tools: are we using them because they are really useful or because everyone else is doing it? Let's reduce our needs to what is truly useful. This is the whole value of sobriety, which also applies to IT!</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-fwAS3Cih.png",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6ea068da-64a4-4efe-bb3e-01bf802add29"><p><strong><span style="font-size:1.5em;">EVALUATION &amp; RECOGNITION - Value positive actions recognise</span></strong></p>

<p>Positive actions of employees should and must be rewarded to be incentivised. Here the HRDs are the first to be on the front line. Let's stop valuing only the sales performance, the number of millions attained, the number of publications or followers.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-791352ae-e018-4f65-a0ea-09af2c26f5f8"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b71b368d4e.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-92591520-9f7c-4687-a25f-cd04c71fdae6"><p>Let's also value positive actions for the climate, for social issues, for well-being. Let's report on what is being done well, what needs to be done. This means reviewing evaluation and recognition practices.</p>

<p>There is a real responsible digital component to be included in evaluation interviews: 'How long have you had your computer and smartphone?', 'What eco-actions do you take?', 'What practices could you change for more sustainable digital practices?' are questions that need to be asked and included in discussions. An action grid on responsible digital use should be requested at each professional interview.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-ZECdRYBX.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4c81c2d8-43d1-4fb3-9ec9-e1e9e578b626"><p><strong><span style="font-size:1.5em;">REWARD - Should part of the variable pay be linked to the implementation of more responsible IT practices?</span></strong></p>

<p>A growing trend considers that the bonus is clearly better than the malus: the carrot is better than the stick. Instead of punishing non-sustainable behaviour (and risk creating disengagement, or even resignations), it is always more beneficial for the company to apprehend behaviour change through a positive lens. Therefore, rather than giving a malus to collaborators who do not adhere to greener behaviours, reward those who do!</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d23a97ff-921c-43bd-be83-e9d189423939"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b7194630d5.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8b65cbfd-c38d-478a-9698-541665e3f2c6"><p>On the other hand, distributing bonuses and maluses must be handled with care and, above all, not used to have collaborators against each other. To say that only the 'best' will get a share of the cake is harmful and unhealthy. This is especially true in the case of green behaviour: most of those who act do not do so for extra income.</p>

<p>Moreover, giving a bonus can entail more purchase down the road, which is not a good solution for the environment. There are other ways of valuing social involvement than the financial approach, and more innovative ways to do so need to be invented.</p>

<p>It would be much better to make responsible digital technology part of a development and encouragement process, to make it a strength and an opportunity in a participation process.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-MOtrX9gI.jpg",
        }
    ],
    "hybridInspiringInitiatives": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d50e317c-49f4-4ce7-b827-1e7618ea8802"><p><span style="font-size:1.5em;"><strong>Integrate the carbon footprint of individuals (remote workers) in the calculation of company's carbon footprint.</strong></span></p>

<p>Although individual carbon footprint is not taken into account in the measurement of organisation’s carbon footprint, it needs to be considered in remote working, because the work activities are shifted to the household of the worker. Remote workers will likely increase their individual carbon footprint because they use more electricity and heating, and in some cases these emissions increase more because of adding additional space for work in the household, .</p>


</div>`,
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1fe06bb5-7157-48c0-b078-a9a1bbedf826"><p><strong><span style="font-size:1.5em;">Reduce the number of square meters associated with relocation projects and new technologies in heating and cooling the building.</span></strong></p>

<p>Introducing New Ways of Working actually means reducing the workforce in offices. Existing offices can be remodelled and office space can be reduced in terms of square metres. This is also a good opportunity to invest in more energy efficient heating and cooling technologies.</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-2e6c4420-0191-429c-ae35-3c163eee07a7"><p><strong><span style="font-size:1.5em;">Close the headquarters one day a week.</span></strong></p>

<p>Offer alternative solutions for those not interested in homeworking to work in commercial agencies spread throughout the territory, which remain open.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-9bb64ff6-9682-46c1-b4d0-851b3c36cfb3"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-659e73b7cfb38.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-fe2e9f0a-6c9f-4004-a698-7d8150687ee6"><p><strong>Example:</strong></p>

<p><em>Since May 2022, the Belgian insurance company Ethias has closed its national offices (Liege and Hasselt) one day a week and invites its employees to work from home. It also allows homeworking for up to 3 days a week, i.e., 60% of working time. This measure, which is innovative in the Belgian market, enables Ethias to reduce its dependence on fossil fuels. It comes on top of the numerous initiatives deployed over the past few years, which have enabled Ethias to reduce its carbon footprint by a third, four years ahead of schedule.</em></p>

<p dir="ltr"><em>Although the move towards more teleworking is in line with the wishes of the vast majority of employees, those who do not wish to do so still have the option of doing their working day in some of the other buildings. "Before streamlining access to its buildings, Ethias took a long view and evaluated several scenarios to ensure that they were beneficial to both employees and the environment. According to CO2Logic's data, the ecological gain of teleworking is positive as soon as the work-home journey is greater than 2 km, which is the case for more than 90% of our employees.</em></p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-Ux4xbbe0.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3316ea8d-10cc-4b2e-8619-da7fe9bbc817"><p><strong><span style="font-size:1.5em;">Development of an app for employees to select the office they will occupy before coming to work.</span></strong></p>

<p>For hybrid work to be efficient, the organisation needs to provide tools to facilitate the use of office space in days when workers come to the office. Developing an application for workers to select the office they will occupy before coming to work is a good solution. There are applications available on the Internet that can easily be personalised for the space the organisation occupies.</p>


</div>`,
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c6362667-2265-4e67-bf80-74a50e2a5925"><p><strong><span style="font-size:1.5em;">Proposal: possibility for an employer to invest, with de-taxable remuneration, in solutions that reduce the carbon footprint at the employee’s place.</span></strong></p>

<p>One could imagine converting part of the remuneration, which today is taxable, to pay the bill for the installation of a geothermic installation, a solar panel, a water collector, or a similar type of investment at an employee’s house.</p>


</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d7c13367-6a61-421e-a373-eebd7190de95"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650ae2a5b19be.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-GwVFSFMm.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-706a5767-ffee-4003-b5a9-c2fd99b9978d"><p><strong><span style="font-size:1.5em;">Use gamification.</span></strong></p>

<p>Introduce the 'Power ranger' game to mobilise employees on reduction of energy consumption</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-49e80623-fa73-4a32-ad4b-77f75ce8a5c4"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a245a56b04.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-EUWiWwyc.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5ea9601e-15c0-41fa-b006-440a9fd9e737"><p><strong><span style="font-size:1.5em;">Offer personalized counseling.</span></strong></p>

<p>Help to each person as each has a different situation – from helping to change transport mode, to help change at their own place – with a little bit of financial incentive.</p>
</div>`
        }
    ],
    "mobilityInspiringInitiatives": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Remove Parking Spaces</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>To discourage the use of cars to go to work, some companies removed reserved parking spaces in companies. If all employees are almost guaranteed a space at their destination, they will use the car much more to commute to work than if they did not.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Facilitate Shared Mobility</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>A Brazilian energy company devised an application to facilitate shared mobility in its São Paulo location: users would enter their commuting route, and the app would cross the data with all other inputs and introduce employees following similar itineraries. Shared mobility increased by 18% of transportation and saved the company US$300,000 per year.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Encourage Cycling</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>More innovative best practice amongst the case studies included the provision of better security for bikes (individually lockable parking stands; police tagging or offering cycle insurance schemes); cycle equipment loans (batteries, lights, pumps, locks, helmets, car racks, child seats, etc.); site-specific cycle maps; financial incentives and complimentary products (for example, offering safety jackets).</p>

<p>Examples of positive actions: a partnership to rent cargo bikes for deliveries or use by technical workers, an electric bike lending campaign to allow employees to test them, a ‘Bike to work tool’ to measure carbon neutrality and participate to different challenges.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Decentralised Shared Offices</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Pilot project on centralized15 shared office space to allow those who cannot do telework on some days not to have to come all the way to HQ, if they live far away or if it is too complicated for them. Done with coworking operator, with a centralized system for invoicing.</p>

<p><strong>Make Train more Competitive </strong></p>

<p>Business travel policy:</p>

<ul>
\t<li>
\t<p>allocation of funds to compensate the higher price of the plane to make trains competitive.</p>
\t</li>
\t<li>
\t<p>not allowed to take the plane for trips less than 2hrs that can be done by train.</p>
\t</li>
</ul>
</div></div></div>`
        },
        // {
        //     "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
        //     "heading": "Make Train more Competitive",
        //     "description": "Business travel policy: \n" +
        //         "allocation of funds to compensate the higher price of the plane to make trains competitive.\n" +
        //         "not allowed to take the plane for trips less than 2hrs that can be done by train.\n"
        // },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-bd0d1aab-e9f3-4954-82d7-5ca803053822"><p><strong><span style="font-size:1.5em;">Pay what I consume' approach</span></strong></p>

<p>Pay what I consume' approach for company cars: people control what they consume + reimbursement of km that have not been consumed or overconsumed / influences leasing price and budget that will be allocated.</p>


</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/image-u29XBURD.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": false,
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6a2b61ed-ff09-4ac7-b91e-57a126803fc6"><p><strong><span style="font-size:1.5em;">GOOD PRACTICE</span></strong></p>

<p><strong><em>Solvay launches internal carbon price for company travel</em></strong></p>

<p><strong><em>Solvay is rolling out a new initiative to track the company’s carbon travel footprint, raise funds to support Group sustainability projects, and build awareness around responsible and sustainable travel. The initiative aims to highlight every employee’s individual carbon footprint.</em></strong></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-729c6f13-e17a-4f6e-9735-eca08661ed72"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650ae6a3ab523.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c18ce683-799a-4f5b-8956-09bf30e83725"><p><em>Since 2019, Solvay has reduced its overall carbon usage linked to air and rail travel by nearly 70%, due in large part to the Covid-19 pandemic, but also to the implementation of Solvay’s New Way of Working, where digital tools have made virtual visits easily accessible to all.</em></p>

<p><em>The Group is now focused on sustaining these new behaviors and minimizing the company’s travel carbon footprint by scheduling fewer in-person meetings, reducing the number of international flights, suppressing one-day air trips and recommending switching from business to economy class or from air to rail when those alternatives exist. These measures are emphasized as a way to raise awareness about everyone's individual carbon footprint.</em></p>

<p><em>Tracking Solvay’s travel footprint will ultimately finance a fund to be used for sponsoring sustainability projects with a carbon-offset focus wherever feasible. Global business units will contribute a monetary amount based on the entity’s travel footprint calculated at €100 per ton of CO2. That amount will be charged to each entity and transferred to the Group Travel Carbon Contribution Fund.</em></p>

<p><em>This contribution fund is about striving toward United Nations sustainability goals and encouraging every employee to do their part to protect the planet as owners and citizens.</em></p>
</div>
</div>`
        }
    ],
    "sustainableInspiringInitiatives": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3f37caaa-982f-468c-be2a-72514d04656a"><p><strong><span style="font-size:1.5em;">Create a narrative </span></strong></p>

<p>The creation of a narrative can drive social orientation and get employees' engagement. Implementing disparate actions, such as reducing email, can be unfairly perceived as greenwashing. This is why it is necessary to create a cohesive narrative within the company. You need to have a real story to tell, one that embodies a committed, systemic, and long-term approach. This narrative must, of course, start with small steps, but also needs to have greater ambition.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6326cb82-49ee-40c6-8a63-fa8377d14d27"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b7c89cd21f.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-fmT9o4iB.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d67971a-f08c-4bc2-8cb9-0d43903d2d07"><p><strong><span style="font-size:1.5em;">Develop a network of "responsible digital" referents within the organisation</span></strong></p>

<p>Such a network allows early adopters (pioneers) who will be the best ambassadors for your policy. They have a better knowledge of their organisations, especially if they are spread over several sites. These early adopters are trained, and therefore they gain skills and know how to mobilise the resources at their disposal to circulate the information internally.<br>
</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-98d6b18e-4994-4314-bbaf-bf7d295acc68"><p><strong><span style="font-size:1.5em;">Limit Geolocalisation </span></strong></p>

<p>Geolocation resources (such as Google Maps and Waze) have revolutionized travel, but they can use up considerable amounts of data due to long trips and general overuse. Tips for users:</p>

<ul>
\t<li>
\t<p>Limit the use of geolocation apps when traveling to previously visited destinations.</p>
\t</li>
\t<li>
\t<p>Only use one geolocation service.</p>
\t</li>
\t<li>
\t<p>Turn off mobile data when geolocation is not needed (such as during a long stretch of highway driving).</p>
\t</li>
\t<li>
\t<p>Utilise offline geo-tracking options where possible.</p>
\t</li>
</ul>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d5f8a0b6-ec6a-489d-b2d9-dd8db9bfbe80"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b7d2fccf93.png" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-LGlALooC.png",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-7e55a634-d22d-4d4b-a781-e9a043b632be"><p><strong><span style="font-size:1.5em;">Change the settings of your printers </span></strong></p>

<p>A quick win in terms of energy consumption can be made in printer fleet management. Changing the settings for standby, low power or sleep mode is interesting and will result in energy savings. With printer power consumption dashboards available to everyone, this is a concrete, "tangible" thing that everyone can see (See Cap Gemini study on Sustainable IT <a target="_blank" href="http://www.capgemini.com/fr-fr/wp-content/uploads/sites/6/2022/04/Final-Web-Report-Sustainable-IT-1.pdf">www.capgemini.com/fr-fr/wp-content/uploads/sites/6/2022/04/Final-Web-Report-Sustainable-IT-1.pdf</a>)</p>
</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-f6daacdd-2479-475f-9e8b-a567f54b59e3"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650b7d52602b9.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-X9LcEPlf.jpg",
        }
    ],
    "hybridRecommendedActions": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3427f056-8466-4a95-adec-01f1e32a4592"><p><span style="font-size:1.5em;"><strong>Reorganise Workspaces so That They Consume Less Energy</strong></span></p>

<p>This can be done in several ways, such as the conditions in which equipment is manufactured, the choice of energy supply and lighting regulation, the type of materials used for glazing, the use of thermal insulation, the implementation of heating systems that allow for more targeted use, etc.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6a1ff1da-bea0-45f6-96ee-7326ac1f3395"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650ae72798dfa.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-e5jYUunU.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d7f97920-3d31-4127-9d19-2a50d6307c52"><p><strong><span style="font-size:1.5em;">Adapt These Systems According to Activities and Uses</span></strong></p>

<p>This means thinking ahead about how to organise the work and the types of activities to be carried out. Ensuring predictability of remote-work practices to be able to anticipate when people are there or not.</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d04f5047-e710-4960-aea1-5f2dd5c634c4"><p><span style="font-size:1.5em;"><strong>Avoid 'One Size Fits All'</strong></span></p>

<p>What might be beneficial in a company may be much less so at the level of an urban centre - for example, if the latter declines or empties out due to the desertion of its workers... Similarly, not all remote workers are equal: some have a dedicated workspace and some not, for example. The cost of energy proves to be an important variable in the choice of working from home or the office, depending on the mode and duration of travel, for example.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-54956a77-38f6-4873-8ff7-904c45dc5241"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650ae952abe3a.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-5SruR9UH.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c741d811-2784-453f-ae4a-6f38fd9c9466"><p><strong><span style="font-size:1.5em;">Good Ideas Often Come From Operational Staff because they are closest to the real work</span></strong></p>

<p>And that's where you can really have an impact. However, the challenge is to maintain consistency. If the participatory approach is isolated or appears to be an isolated initiative, without being part of a broader strategy; or if the corporate message is that the environment is important, but that there are only financial performance KPIs, the approach will not be credible. Beware of greenwashing!</p>


</div>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<p><strong><span style="font-size:1.5em;">Close Offices on Certain Days of the Week to Save Energy</span></strong></p>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-cfe59f92-0f7d-4a1b-8a5b-4dc310c7c7b8"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650ae9e7ad642.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3a5a13fb-d0a4-4437-87c0-8cca2baa698a"><p>If many workers tend to work from home on certain days, organisations may consider closing the office on that day. It is recommended to select consecutive days of closure as it saves more energy. For example closing the Monday after the weekend reduces the energy used in switching on and off the energy consuming systems in the office (i.e. heating and cooling and etc).</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-ynBuLM58.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-93509733-1264-40a8-a25b-183892b8d10d"><p><strong><span style="font-size:1.5em;">Combine Remote Work, Flex-Office and Office Space Reduction </span></strong></p>

<p>A study by ADEME (France) shows that when the reductions in real estate that telework brings about when coupled with flex office (the practice of not having a dedicated office in the workplace) are taken into account, the overall environmental balance of telework improves significantly by +52% per day of telework per week (i.e. -234kg eqCO2/year for each additional day of telework per week), thus consolidating its environmental benefits. The environmental benefits of the flex office could exceed the mobility benefits.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-2d3e4c85-3a9c-4d3a-a7e2-d1ea74bf3ce5"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650aea5b69adc.png" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-BKcUY47M.png",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d25f0f5-0bf7-4f85-b059-69b9e42fb240"><p><strong><span style="font-size:1.5em;">Discourage Part-Day Homeworking</span></strong></p>

<p>Remote working both at home and in third place leads to a significantly negative environmental balance.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-28e9f5f3-2c2d-4dbf-94b1-1e6affc73458"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650aeaf74c038.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-cfa3ffa5-6b28-41ef-aa8a-4bc8bde9fc55"><p><strong><span style="font-size:1.5em;">Remote Work: Be Careful!</span></strong></p>

<p>A study by ADEME (France) shows that remote work reduces "office" consumption (paper, ink, supplies, cups, decoration, video projectors, etc.) and the company's energy consumption. However, some of these reductions are passed on to the remote worker. In particular, energy consumption at home is increasing. The study estimates that energy consumption at home during the telework day would increase mechanically: +20.7 kg eqCO2/year for one day of telework per week for the teleworker.</p>


</div>`
        }
    ],
    "mobilityRecommendedActions": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f3a1154d-cf21-4744-8f37-5b942ddae908"><p><span style="font-size:1.5em;"><strong>Measure… But It is Better to Advance With Imperfect Measurements…</strong></span></p>

<p>Waiting for perfection is the best way of not doing anything…</p>
</div>`,
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9c17bf2a-baba-43d2-a449-63d3a162ff6a"><p><span style="font-size:1.5em;"><strong>Choose a Company Location Accessible By Sustainable Means of Transport </strong></span></p>

<p>Companies do not have the capacity to ‘recruit locally’, nor to impose living locations on their employees, except by providing accommodation as part of the employment contract. They cannot influence the home-work distance, but they can choose a location that is accessible for their workers by more sustainable means than the car.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-32b491be-187b-4b28-b25e-c93cdd367c4c"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650ae7da4ccaf.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-r5ICVTKu.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42a6405c-6e2a-4f70-b7d8-501f3b5e3f66"><p><span style="font-size:1.5em;">Plan, Operate, Activate</span></p>

<p>The first pillar is the diagnosis and definition of a strategy. Then comes the implementation with mobility services, data management, financial monitoring, infrastructure, etc. Most companies stop there and then see that it doesn't work. Why Simply because the behavioural change dimension is missing.</p>

<p>All of the above is necessary, but not sufficient. You have to 'activate'. When a company tells you that it has placed ten electric bicycles in the reception area, but no one uses them, this step is missing: activation. You haven't motivated people.</p>

<p>Motivating them can be done in different ways, like organising a game with a reward for those who use them the most.</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-7565574d-621a-4f00-b920-c201f9d2f507"><p><strong><span style="font-size:1.5em;">Gain an Understanding in Long-term Commuting or Over Very Long distances</span></strong></p>

<p>E.g.: dual residences linked to work - either with a second residence, or in a hotel, or even in an AirBnB - , dual residences linked to people with two jobs in two different cities; very frequent travel linked to work, etc.</p>

<p>Yet this deserves to be considered from an HR point of view, and even more so when there is a move to a new workplace in terms of the impact on commuting and the necessary support.</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-80c0adf5-cab3-4fbb-98bd-c46a956d2977"><p><strong><span style="font-size:1.5em;">Do Not Focus on The Budget Aspect Only</span></strong></p>

<p>The logic of mobility choice also includes speed (if it takes 1.5 hours by public transport, as opposed to 40 minutes by car, money will not have much impact), but also many other criteria such as comfort (i.e. the whole experience of travelling: the train has a comparative advantage if you can do other activities on it - sleep, read, work, etc. - but that advantage is nulled if the train is crowded and you don't have a seat, for example), the image or experience you have of the different modes of transportation, habits, safety, etc. Consider a mobility policy that integrates all the dimensions (cost, speed, comfort, etc.) so that the alternatives are attractive.</p>

<p>Shift from a mobility budget of 500 euros, to a budget of x kilos of CO2 and allocate employee’s mobility budget accordingly, possibly with trading between colleagues or the possibility of exchanging savings for days off.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6594bb16-8d4a-40a3-baae-b2c961991ac6"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650ae87fc0ae4.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-HA5eJBjj.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-db56f5ca-9960-46ca-b07c-3aa08b24a38f"><p><strong><span style="font-size:1.5em;">Encourage Multimodality </span></strong></p>

<p>Promote modal shifts so that people minimise the role of the car in their journeys. People who live in sparsely populated, peri-urban areas may be able to reduce the use of the car if they use other modes of transportation in addition to the car.</p>

<p>Shift to the augmented rather than the diminished car: continue to have a company car, possibly smaller, with better environmental performance, but let's also give people the opportunity to travel differently. This does not always require increasing the offer, but just agreeing to facilitate access to public transport or other forms of mobility.</p>

<p>There is a plethora of multimodal solutions on offer today.</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-2b595af0-7cfc-41da-b1a7-f849be29cfe8"><p><strong><span style="font-size:1.5em;">Company Cars: Pay Attention To…</span></strong></p>

<p>If the company feels obliged to provide company cars (for talent attraction, retention, status, representation…), pay attention to the type of vehicle, even if we know that the environmental impact of an electric vehicle is not neutral either</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e2b6406f-ba7a-45b7-83b8-84806274d67f"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650ae8f013a9d.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-DgZHPTOT.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5821544b-625c-4c3a-a64e-9ddf0f3eeb2f"><p><strong><span style="font-size:1.5em;">Deal With Parking Spaces </span></strong></p>

<p>If the company offers parking space, it is, in a way, paying for employees to use their car. What we need is to simply balance out the advantages across different transportation types: if you commute by bike, you don't take up any parking space. If you take up less space, the company could grant you a certain amount - for example 200 euros - because you save the company money by coming by bike.</p>

<p>Target reserved parking spaces for managers, which are generally better situated. This hierarchical advantage does not reflect the company’s ambition for mobility that is less carbon intensive. If there is a car park, the spaces closest to the office entrance should be reserved for carpooling.</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d56820b5-cdac-4d65-b9c3-e7e19d058c6a"><p><strong><span style="font-size:1.5em;">Use ‘Internal Ambassadors’</span></strong></p>

<p>Work with pilot projects, with small groups of ambassadors ready to play this role. Don't revolutionise mobility in your company; start with people who are willing, and with small initiatives that will germinate and grow. Mobilise top management: when the CEO and top managers get on their bikes and do this regularly, it has a major effect on the organisation.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-96546409-df37-40a1-8000-e20cc49697d3"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650ae968de311.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-bJXBYOuI.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3c1ff5d8-04bb-4246-b817-45823add9089"><p><strong><span style="font-size:1.5em;">Key-Word: Collaboration </span></strong></p>

<p>The Kiba-Janiak and Witkowski review notes that a key indicator of a successful mobility plan is the collaboration with a network of stakeholders, may they be public or private, and announces it as inherent to reducing GHG emissions.</p>




</div>`
        }
    ],
    "sustainableRecommendedActions": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-5a468dcd-341e-4c76-a18e-49a772df8881"><img src="https://lp.youngkreativ.eu/pluginfile.php/284/mod_hvp/content/42/images/file-650afa383a24c.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c9054d6f-c1fd-4779-9615-5a933109a477">

<p><strong><span style="font-size:1.5em;">Be careful with Remote Working </span></strong></p>



<p>A study by ADEME in France shows that the positive effects of reduced commuting are modulated by significant rebound effects. The rebound effect is described as unfavourable due to four mechanisms, including an increase in video flows mainly linked to video conferencing and new energy consumption at home (heating, lighting, PCs, etc.). The study also points to new systemic or long-term rebound effects to be assessed and monitored, such as an increase in digital equipment.</p>

<p>The impact of teleworking will only be positive if a company otherwise reduces the amount of workspace used. If they maintain them and continues to heat and light them in a hybrid work environment, teleworking does not represent a real gain.</p>




</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-8IE7xrpv.jpeg",
        }
    ],
    "foreword": [
        {
            roles: [],
            "heading": "",
            "description": "New Ways of Working (NWoW) cover spatio-temporal flexibility practices – remote working, workspace planning, flexible working hours, etc. -, management practices - autonomy, trust, participation, etc. -, as well as work organisation practices - (semi-)autonomous teams, etc. \n" +
                "\n" +
                "It is rather in flex-work that one will find such levers to contribute to the reduction of CO2 emissions, by working on the working environments. "
        }
    ],
    "hybridLearningObject": [
        {
            "roles": [],
            "heading": "",
            "description": "The aim is to empower HR professionals to lead the way in creating a hybrid work environment that not only supports organisational goals but also contributes positively to environmental sustainability.\n" +
                "\n" +
                "1. Hybrid Work Leadership and Strategy Competence: \n" +
                "• Develop the ability to lead and strategize effectively in a hybrid work environment, focusing on the integration of sustainability principles. This includes acquiring skills to collaborate with leadership and cross-functional teams to embed sustainable practices in both remote and office settings. \n" +
                "• Learn to create a strategic vision that aligns hybrid work models with the organisation's sustainability goals. \n" +
                "• Enhance competencies in designing hybrid work policies and practices that reduce the organisation's carbon footprint, such as optimizing remote work schedules to minimize travel and energy consumption. \n" +
                "• Gain insights into evaluating and selecting office locations based on accessibility to sustainable transport and potential for reducing commuting emissions.\n" +
                "2. Sustainable HR Management in Hybrid Environments: \n" +
                "• Cultivate skills in managing human resources in a way that supports and enhances the effectiveness of hybrid work models, while also promoting sustainability. This includes developing and implementing HR policies that encourage environmentally friendly work practices in both remote and office settings. \n" +
                "• Learn to integrate sustainability into recruitment, training, and performance management, ensuring that staff are equipped and motivated to engage in sustainable practices irrespective of their work location. \n" +
                "• Enhance abilities in using digital tools and communication strategies to effectively engage and support employees in a hybrid work setup. \n"
        }
    ],
    "mobilityLearningObject": [
        {
            "roles": [],
            "heading": "",
            "description": "The overall objective is to empower HR professionals to implement practical, innovative mobility solutions that contribute to reducing the company's carbon emissions and enhancing overall sustainability.\n" +
                "1. Stakeholder Engagement and Strategic Mobility Management: \n" +
                "• Develop competencies in engaging effectively with a diverse range of stakeholders including CEOs, top management, finance departments, and public authorities to create and implement strategic mobility plans. This includes understanding the significance of business location decisions in relation to the employment pool and public transport accessibility, and their impact on the company's carbon footprint. \n" +
                "• Gain skills in leading and influencing top management to adopt a positive approach towards work mobility, transcending mere compliance to actively integrate mobility as a part of the work experience. \n" +
                "• Learn to construct and present compelling business cases to finance departments, demonstrating the direct and significant return on investment of sustainable mobility projects. \n" +
                "• Enhance abilities in setting up effective communication strategies, utilizing tools like gamification and continuous messaging, to change employee behavior related to mobility.\n" +
                "2. Operational Implementation and Innovation in Mobility: \n" +
                "• Learn how to implement mobility strategies through the appointment and training of Mobility Managers who can lead projects across various departments. \n" +
                "• Learn to gather and analyze data on current mobility practices and individual commuting preferences to tailor mobility solutions. \n" +
                "• Develop skills in initiating participatory approaches, creating internal ambassadors, and fostering collaboration among employees to promote sustainable commuting methods. \n" +
                "• Understand the importance of addressing practical aspects such as car parking policies and encouraging alternatives like cycling, carpooling, and the use of public transport. \n" +
                "• Gain insights into leveraging technological tools such as multimodal itinerary platforms, and the implementation of sustainable mobility packages to incentivize environmentally friendly commuting. \n"
        }
    ],
    "sustainableLearningObject": [
        {
            "roles": [],
            "heading": "",
            "description": "1. Strategic Leadership and Governance in Digital Sobriety: \n" +
                "• Develop skills in advocating and reinforcing the importance of responsible digital behaviour within the organisation, working collaboratively with CEOs, top management, and CIOs/IT Directors. \n" +
                "• Develop skills in influencing and embedding sustainability in the organisation's vision and ethics, specifically relating to the use of digital technologies. \n" +
                "• Learn to guide top management in integrating responsible digital criteria into the company's governance, ensuring sustainability is a core consideration in every IT-related project. \n" +
                "• Learn to practice and promote exemplarity, where leaders 'walk the talk', setting clear examples of responsible digital practices. \n" +
                "• Acquire skills in coordinating with IT departments to develop a responsible IT strategy that limits environmental impact while serving people's needs, focusing on aspects like Green IT, Human IT, and IT for Human. Understand the significance of working with procurement and IT hardware suppliers to implement sustainable practices in equipment manufacturing, usage, and recycling.\n" +
                "2. Operational Implementation and Responsible Digital Practices: \n" +
                "• Develop skills in implementing operational strategies for responsible digital behaviour. Learn to work with procurement to extend the lifespan of IT equipment and promote sustainable usage. \n" +
                "• Acquire competencies in engaging IT and telecom hardware suppliers for energy-efficient products and educating staff on their benefits. Understand how to raise awareness among staff about the sustainability issues related to digital technologies, promoting a culture of digital sobriety. \n" +
                "• Gain expertise in encouraging staff to adopt responsible digital behaviours, such as optimized cloud usage, responsible email practices, and mindful video conferencing. Learn to formalise these behaviours through policies and integrate them into HR practices like training, evaluation, and recognition. \n" +
                "• Develop the ability to measure the digital environmental footprint of the organisation and individual employees, and to use this data to drive continuous improvement in digital sobriety practices.\n"
        }
    ],
    "inner_cont_title": "SELF-ASSESSMENT",
    "st_2_title": "Now let’s determine your predominant HR role in organization / company",
    "st_2_p1": "This tool can define your individual learning path inspired by Dave Ulrich’s ‘HR Business Partner Model’ model (Ulrich, 1997) as it provides frameworks and guidance for HR professionals to align their activities with business goals, drive organizational change, and create value within their organizations in order to maximize the impact and effectiveness of their HR function.",
    "st_2_p2": "This alignment ensures that HR initiatives and practices directly support the organization's goals and enhance overall performance. The model suggests that HR professionals should play four key roles: strategic partner, administrative expert, employee champion, and change agent (Ulrich, 1997; Ulrich & Brockbank, 2005). The description of these roles is given on following table.",
    "st_3_title": "LEARNING ABOUT NEW WAYS OF WORKING FOR NET ZERO EMISSION IN COMPANIES",
    "st_3_p1": "There are three ways how HR can contribute:",
    "st_3_r0_0": "HYBRID WORK",
    "st_3_r0_1": "What forms of hybridity between ‘office-based work’ and ‘remote working’ can best contribute to a positive environmental impact, and under which conditions?",
    "st_3_r1_0": "Sustainable mobility",
    "st_3_r1_1": "Sustainable mobility - what methodologies and (digital) solutions can contribute to reducing travel and positively impact carbon footprint?",
    "st_3_r2_0": "Sustainable and responsible use of IT",
    "st_3_r2_1": "Sustainable and responsible use of IT – what (digital) solutions and HR practices support Responsible Digital Behaviour and Digital Sobriety in organisations?",
    "st_3_title_2": "To determine your lerning path please select your area of interest:",
    "st_3_bullet_1": "to select some or all of the Tolkits (horisontaly positioned Checkboxes)",
    "st_3_bullet_2": "to select some or all of the Areas of interest Stakeholders, Culture, Tools, HR Processes, Inspiring initiatives, Recommendations (verticaly positioned Checkboxes)",
    "r_c_text_1": "Based on your answers, your (current) role in HR is considered as:",
    "r_c_text_2": "We recommend that learning path to participate in development of New Ways of Working for Net Zero Emission in your company/organization.",
    "r_c_text_3": "Still you can select a different role or continue with this one.",
    "r_c_text_4": "Please select your preferred role or leave it as it is and proceed further:",
    "StrategicPartner": 'Strategic Partner',
    "AdministrativeExpert": "Administrative Expert",
    "EmployeeChampion": "Employee Champion",
    "ChangeAgent": "Change Agent",
    "StrategicPartnerContent": "As strategic partners, HR professionals collaborate closely with business leaders to understand the organization\'s strategic goals and align HR practices and initiatives accordingly. They contribute to the development of business strategies and ensure that HR activities support the achievement of those strategies. Strategic partners actively participate in decision-making processes and provide insights on how HR can contribute to organizational success.",
    "AdministrativeExpertContent": "The administrative expert role focuses on ensuring effective and efficient HR operations and processes. HR professionals in this role manage routine administrative tasks such as payroll, benefits administration, HR policies, compliance, and record-keeping. By effectively managing these administrative functions, HR professionals free up time and resources to focus on strategic initiatives.",
    "EmployeeChampionContent": "The employee champion role emphasizes the importance of creating a positive work environment and advocating for the needs and well-being of employees. HR professionals in this role act as a bridge between the organization and its employees, promoting employee engagement, satisfaction, and overall employee experience. They address employee concerns, provide support, and foster a culture of inclusivity, fairness, and respect.",
    "ChangeAgentContent": "The change agent role highlights HR\'s responsibility in facilitating and managing organizational change. HR professionals act as change agents by proactively identifying and addressing the need for change, promoting change initiatives, and helping employees and teams navigate transitions. They play a crucial role in driving cultural transformation, organizational restructuring, and fostering a change-ready mindset within the organization.",
    "toolkit1": "TOOLKIT 1",
    "toolkit2": "TOOLKIT 2",
    "toolkit3": "TOOLKIT 3",
    "hybrid": "HYBRID",
    "SustainableMobility": "Sustainable mobility",
    "responsibleUseOfIt": "responsible use of  IT",
    "hybridContent": "Hybrid work - what forms of hybridity between ‘office-based work’ and ‘remote working’ can best contribute to a positive environmental impact, and under which conditions?",
    "SustainableMobilityContent": "What methodologies and (digital) solutions can contribute to reducing travel and positively",
    "responsibleUseOfItContent": "What (digital) solutions and HR practices support Responsible Digital Behaviour and Digital Sobriety in organisations?",
    "ZeroCarbon": "Zero Carbon",
    "ZeroCarbonContent": "Zero carbon signifies that a product or service has the characteristic of generating absolutely no carbon emissions. For instance, renewable energy sources like wind and solar power are categorised as zero carbon because they do not emit carbon dioxide (CO2) when generating electricity. In contrast, net-zero pertains to the practice of offsetting, or balancing any carbon emissions generated by a company, whereas zero carbon specifically denotes a product or service that emits no carbon in the form of CO2 equivalents (CO2e). Furthermore, achieving net-zero carbon emissions signifies that an activity results in a net-zero release of carbon emissions into the atmosphere, indicating a state where emissions are effectively counterbalanced, or cancelled out.",
    "Compensation": "Compensation",
    "CompensationContent": "Compensation, often referred to as (carbon) offsetting, involves the deliberate and sometimes mandatory acquisition of carbon credits to counterbalance the emissions attributed to a particular entity. The cost associated with procuring carbon credits for compensation purposes serves as a reference point when evaluating investments aimed at achieving direct internal emissions reductions. Certain greenhouse gas (GHG) emissions may be inherently difficult to completely eliminate, and compensation via carbon credits plays a pivotal role in advancing climate neutrality and net-zero emission objectives. Compensation strategies encompass various forms of investment, including those in renewable energy, energy efficiency, reforestation, carbon capture, and other quantifiably effective carbon mitigation activities.",
    "Offsetting": "Offsetting",
    "OffsettingContent": "Carbon offsetting is a procedure involving the reduction or elimination of carbon dioxide and other greenhouse gas emissions to counterbalance emissions generated in other locations. Carbon offset projects enable both companies and individuals to engage in tangible environmental initiatives aimed at offsetting their carbon emissions. These offsetting initiatives encompass a range of technologies and activities, including reforestation, cleaner cooking stoves, and carbon capture. Carbon offsetting forms an integral component of corporate sustainability strategies, especially when it complements efforts to reduce carbon emissions, ultimately contributing to the goal of achieving net-zero emissions.",
    "ScienceBasedTargets": "Science-Based Targets (SBT)",
    "ScienceBasedTargetsContent": "The Science-Based Targets initiative (SBTi) offers a clearly outlined framework for companies to systematically reduce their greenhouse gas (GHG) emissions, with a specific focus on achieving emission reduction numbers that align with the targets established in the Paris Climate Agreement. Targets are regarded as 'science-based' when they are in accordance with the latest climate science findings, aiming to meet the objectives of limiting global warming to no more than 1.5 degrees Celsius above pre-industrial levels.\n" +
        "\n" +
        "SBTi plays a pivotal role in promoting best practices and providing well-defined guidelines for emissions reduction. It offers methodologies for setting emission reduction targets based on the scientific understanding of climate change. The initiative actively assists businesses in establishing carbon reduction objectives that are fully in compliance with the targets stipulated in the Paris Agreement.",
    "Greenwashing": "Greenwashing",
    "GreenwashingContent": "Greenwashing refers to a deceptive practice wherein a company employs advertising and public communication to portray itself as more environmentally responsible and sustainable than it genuinely is. It is a strategy employed by some companies to divert consumer attention away from the detrimental environmental impacts of their business operations and models. Greenwashing tactics can manifest in various forms, including advertising campaigns, sponsorships, and public messaging across traditional media and social media platforms.",
    "LifeCycleAssessment": "Life Cycle Assessment (LCA)",
    "LifeCycleAssessmentContent": "LCA is a rigorous, science-driven approach employed to assess the ecological footprint of a product or process across its complete lifecycle. This method takes into account every stage, beginning with the extraction of raw materials and extending to the eventual disposal or recycling phase. LCA serves as a valuable tool for quantifying the environmental consequences associated with products and processes, empowering companies to make well-informed choices and implement enhancements in their sustainability efforts.",
    "CarbonFootprint": "Carbon Footprint",
    "CarbonFootprintContent": "An indicator of the GHG cost, usually Carbon Dioxide, associated with a company, country, product, or activity; often including both the direct and indirect emissions involved. For example, the Carbon Footprint of a product made using imported materials will include both the carbon costs of transporting the imported materials, and the cost of manufacturing the product. These Footprints should give an idea of the environmental impact businesses have and provide a starting point to move towards more environmentally responsible practices.",
    "EmissionsTradingScheme": "Emissions Trading Scheme (ETS)",
    "EmissionsTradingSchemeContent": "An Emissions Trading Scheme is a 'cap-and-trade' system that caps emissions and fines companies for exceeding the cap.  However, emissions credits are given to these companies that allow them to exceed the cap with no penalty.  These credits can be bought or sold to other companies encouraging them to reduce emissions, either to have more credits to sell, or reduce their need to buy them.  Such systems are commonly used across the world, best exemplified by the EU-ETS.",
    "sm_tab_1": "1. Training Toolkits",
    "sm_tab_2": "2. Learning Path",
    "sm_tab_3": "3. Impact Tools",
    "sm_tab_4": "4. Interactive Toolkits",
    "sm_tab_5": "5. Glossary",
    "home_tab_label": "Home",
    "about_tab_label": "About the Project",
    "project_tab_label": "Project Results",
    "news_tab_label": "News",
    "newsletters_tab_label": "Newsletters",
    "contact_tab_label": "Contact Us",
    "takeQuiz": "Take this quiz to understand which HR role your activity corresponds to, and receive personalised recommendations",
    "takeQuizLabel": "TAKE THE QUIZ",
    "selectHrRole": "Take the quiz to understand which HR role corresponds to your position, and receive personalised recommendations",
    "demographics": "Demographics",
    "yourRole": "Your Role",
    "interests": "Interests",
    "learningPath": "Learning Path",
    "selectPosition": "SELECT POSITION",
    "or": "OR",
    "hrmEductor": "If You are an HRM Educator, or simply would like to know how the learning process is envisioned with our learning paths explore our Learning Guide!",
    "downloadPdf": "DOWNLOAD PDF",
    "downloadPdfFileName": "https://nwow4net-zero.eu/images/NWOW4NetZero/NWoW_LearningGuide_EN.pdf",
    "continue": "Continue",
    "back": "Back",
    "nextBook": "NEXT",
    "backBook":"BACK",
    "stakeholdersTitle": "Stakeholders",
    "stakeholdersDesc":"In order to contribute to the reduction of the company's carbon emissions, the Human Resources Director/Manager has to work closely with different stakeholders.",
    "createCultureTitle": "Creating a Culture of Sustainability",
    "createCultureDesc": "Achieve changes in behavior and consumption through organisational and managerial culture.",
    "toolsTitle": "Tools",
    "toolsDesc": "Tools to support emissions reduction",
    "hrPrTitle": "HR Processes",
    "hrPrDesc": "Adapting HR processes to help reduce carbon emissions",
    "inspiringTitle": "Inspiring initiatives",
    "inspiringDesc": "Integrate the carbon footprint of the individuals (remote workers) in the calculation of the company's carbon footprint.",
    "recoTitle": "Recommendations",
    "recoDesc": "Recommendation to implement the HR contribution to Net-zero",
    "forewordTitle": "Foreword",
    "learningObjectives": "Learning objectives",
    "pr3_home_title": "PR4 INTERACTIVE TOOLKITS",
    "pr3_home_subtitle": "Three modular toolkits covering modification of HR practices to the demands arising from the new trends in information technology, mobility, and sustainability which affect the contemporary workplaces.",
    "pr3_home_toolkit_1": "TOOLKIT",
    "pr3_home_toolkit_0_2": "New ways of working",
    "pr3_home_toolkit_1_2": "Work-related mobility",
    "pr3_home_toolkit_2_2": "Responsible use of IT",

}
