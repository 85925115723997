import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import './toolkits.scss'


const rowsLength = 6;

class Toolkits extends Component {


    headers = [
        '',
        window.getContent('st_3_th1'),
        window.getContent('st_3_th2'),
        window.getContent('st_3_th3'),
    ]

    handleChange = (e, index) => {
        this.props.handleChangeToolkit(e.target.name, index, e.target.checked);
    }
    getRows = () => { //615px
        let rows = [];
        for (let i = 0; i < rowsLength; i++) {
            rows.push(<TableRow>
                <Cell>
                    {this.getFirstCell(i)}
                </Cell>
                <Cell>
                    <div className={'b_r b_l'}/>
                    <Checkbox size={window.innerWidth <= 615 ? 'small' : 'medium'} name={"hybrid"}
                              checked={this.props.toolKitObj.hybrid[i + 1]}
                              onChange={(e) => this.handleChange(e, i + 1)}/>
                </Cell>
                <Cell>
                    <div className={'b_r b_l'}/>
                    <Checkbox size={window.innerWidth <= 615 ? 'small' : 'medium'} name={"mobility"}
                              checked={this.props.toolKitObj.mobility[i + 1]}
                              onChange={(e) => this.handleChange(e, i + 1)}/>
                </Cell>
                <Cell>
                    <div className={'b_l b_r'}/>
                    <Checkbox size={window.innerWidth <= 615 ? 'small' : 'medium'} name={"sustanableIt"}
                              checked={this.props.toolKitObj.sustanableIt[i + 1]}
                              onChange={(e) => this.handleChange(e, i + 1)}/>
                </Cell>
            </TableRow>)
        }


        return rows;
    }

    getFirstCell = (index) => {
        return <>
            <div className={'h_cell bold'}>{window.getContent(`st_3_tc${index}_0`)}</div>
            <div className={'h_cell'}>{window.getContent(`st_3_tc${index}_1`)}</div>
        </>
    }

    setArr = (val = false, obj = {}) => {
        Object.keys(obj).forEach(key => obj[key] = val);
        this.forceUpdate();
    }

    getColumnChecked = (index) => {
        let obj = null;

        switch (index) {
            case 1:
                obj = this.props.toolKitObj.hybrid
                break;
            case 2:
                obj = this.props.toolKitObj.mobility
                break;
            case 3:
                obj = this.props.toolKitObj.sustanableIt
                break;
        }

        let checked = true;

        Object.keys(obj).forEach(c => {
            if (!obj[c]) {
                checked = false;
            }
        })

        return checked;
    }

    handleChangeColumnCheckBox = (checked, index) => {
        // eslint-disable-next-line default-case
        switch (index) {
            case 1:
                this.setArr(checked, this.props.toolKitObj.hybrid)
                break;
            case 2:
                this.setArr(checked, this.props.toolKitObj.mobility)
                break;
            case 3:
                this.setArr(checked, this.props.toolKitObj.sustanableIt)
                break;
        }
    }

    render() {
        return (
            <div className={'toolkits'}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {this.headers.map((c, index) => <Cell>
                                <div className={'b_t b_r b_l'}/>
                                <div>{c}</div>
                                {c &&
                                    <Checkbox onChange={(e) => this.handleChangeColumnCheckBox(e.target.checked, index)}
                                              checked={this.getColumnChecked(index)}
                                              size={window.innerWidth <= 615 ? 'medium' : 'large'}/>}
                            </Cell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.getRows()}
                    </TableBody>
                </Table>
            </div>
        );
    }
}

const Cell = (props) => {
    const size = (window.innerWidth <= 615 ? 'small' : 'medium');

    return <TableCell {...props} size={size}/>
}

Toolkits.propTypes = {};

export default Toolkits;
