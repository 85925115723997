import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {useTheme} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './tabs.scss';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const style = {
    '.MuiTabs-root': {
        // backgroundColor: '#F5F5F5',
        overflow: 'visible'
    },
    '.MuiAppBar-root': {
        boxShadow: 'unset',
        backgroundColor: 'transparent'
    },
    '.MuiButtonBase-root': {
        backgroundColor: 'white',
        color: 'blue',
        border: '2px solid',
        borderRadius: '20px 20px 0 0',
        borderBottom: 0,
        fontWeight: 700
    },

    '.Mui-selected': {
        zIndex: 1,
        position: 'relative',
        overflow: 'visible',

        '.border_hider': {
            // position: 'absolute',
            // display: 'block',
            // width: '100%',
            // backgroundColor:'red',
            // height:'13px',
            // bottom: '-5px',
            // zIndex: 100
        }
    },
    '.MuiTabs-indicator': {
        display: 'none'
    }

}


export default function FullWidthTabs({
                                          fourthStep = false,
                                          isBook = false,
                                          tabs = [],
                                          accordion = false,
                                          onChange = () => {
                                          },
                                          initial_value = 0
                                      }) {
    const theme = useTheme();
    const [value, setValue] = React.useState(initial_value);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        onChange(newValue)
    };

    const getBookProps = () => {
        return {
            fontWeight: 700,
            fontSize: '16px'
        }
    }

    const getAdditionalStyle = () => {
        if (fourthStep) {
            return {
                '.MuiTab-root': {
                    fontSize: isBook ? getBookProps() : {},
                    '.sub-label': {
                        fontSize: '12px'
                    },
                    borderBottom: 0,
                    '&:first-child': {
                        color: '#45BF55',
                        borderColor: '#45BF55',
                    },
                    '&:nth-child(2)': {
                        color: '#64AAFA',
                        borderColor: '#64AAFA',
                    },
                    '&:nth-child(3)': {
                        color: '#c3a100',
                        borderColor: '#FFD618',
                    },
                    zIndex: 0,
                    '.border_hider': {
                        display: 'none'
                    },
                    '&.b_b_1': {
                        borderBottom: '2px solid #45BF55',
                    },
                    '&.b_b_2': {
                        borderBottom: '2px solid #64AAFA',
                    },
                    '&.b_b_3': {
                        borderBottom: '2px solid #FFD618',
                    },
                },
            }
        }
        return {
            '.MuiTab-root': {
                borderBottom: 0,
                backgroundColor: '#f8f7f7',
                '&:nth-child(1)': {
                    color: '#c3a100',
                    // filter: 'contrast(2)',
                    borderColor: 'rgba(255, 214, 24, 0.2)',
                    // textShadow: '0.5px 0.5px 0.5px #444444'

                },
                '&:nth-child(2)': {
                    color: '#c35ee6',
                    borderColor: 'rgba(212, 140, 237, 0.2)',
                },
                '&:nth-child(3)': {
                    color: 'rgba(69, 191, 85, 1)',
                    borderColor: 'rgba(69, 191, 85, 0.2)',
                },
                '&:nth-child(4)': {
                    color: 'rgba(100, 170, 250, 1)',
                    borderColor: 'rgba(100, 170, 250, 0.2)',
                },
                zIndex: 0,
                '.border_hider': {
                    display: 'none'
                },
                '&.b_b_1': {
                    borderBottom: '2px solid rgba(255, 214, 24, 0.2)',
                },
                '&.b_b_2': {
                    borderBottom: '2px solid rgba(212, 140, 237, 0.2)',
                },
                '&.b_b_3': {
                    borderBottom: '2px solid rgba(69, 191, 85, 0.2)',
                },
                '&.b_b_4': {
                    borderBottom: '2px solid rgba(100, 170, 250, 0.2)',
                },
                '&.Mui-selected': {
                    backgroundColor: '#fff'
                },
            },
        }
    }

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <Box sx={{width: '100%', ...style, ...getAdditionalStyle()}} className={`tabs_box ${window.language}`}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    // indicatorColor="secondary"
                    // textColor="inherit"
                    variant="fullWidth"
                    // variant="standard"
                    // selectionFollowsFocus
                    // variant={(window.innerWidth < 750)? "scrollable": 'fullWidth'}
                    // scollButtons='auto'
                    aria-label="full width tabs"
                >
                    {tabs.map((c, index) => <Tab className={`${value != index ? `b_b_${value + 1}` : ''}`}
                                                 label={
                                                     <div>
                                                         {c.label}
                                                         {c.subLabel ?
                                                             <div className={'sub-label'}>{c.subLabel}</div> : ""}
                                                     </div>
                                                 } {...a11yProps(index)} />)}

                    {/*<Tab label={<>Item Two <div className={'border_hider'}/></>} {...a11yProps(1)} />*/}
                    {/*<Tab label={<>Item Three <div className={'border_hider'}/></>} {...a11yProps(2)} />*/}
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {tabs.map((c, index) => <TabPanel
                    className={`tab_panel ${fourthStep && 'fourth_step'} ${value === index ? `selected_${index + 1}` : 'not_selected'}`}
                    value={value}
                    index={index}
                    dir={theme.direction}>
                    {c.content}
                </TabPanel>)}
            </SwipeableViews>
        </Box>
    );
}
