import * as React from 'react';
import {MenuItem as MenuItemMui, Button} from "@mui/material";
import {languageRouteAbbreviations, routes} from "../../Enums/Enums";
import './nav.scss';
import {SmallDownArrow} from "../icons/svgIcons";
import {ExpandMore} from "@mui/icons-material";
import {Menu, MenuItem} from "@szhsin/react-menu";
import {isSelected, LocationHook} from "../side_menu/sideMenu";
import {useState} from "react";
import LeftDrawer from "./side_nav";
import RightDrawer from "./lang_nav";


export default function NavTabs() {
    const [render, setRender] = useState(0);



    const getButton = (button, isChild = false) => {
        let onClick = button.children ? () => {
        } : (e) => {
            if (button.route) {
                // e.keepOpen = true;
                window.navigateTo(button.route + `/${window.language}`)
            } else {
                window.location.href = button.link;
            }
        }
        const btn_props = {
            onClick,
            endIcon: button.endIcon,
            variant: button.selected ? 'outlined' : 'text',
            children: [button.label],
            className: isSelected(button.route) ? 'selected' : ''
        }
        const button_component = isChild ? <MenuItem {...btn_props}/> : <Button {...btn_props}/>

        if (button.children) {
            return <Menu menuButton={button_component}>
                {button.children.map(c => getButton(c, true))}
                {/*<MenuItem>Hello</MenuItem>*/}
            </Menu>
        }
        return button_component;
    }

    return (
        <div className={'right_nav_container'}>
            <LocationHook onLocationChange={() => setRender(render + 1)}/>
            {buttonsConfig().map((c) => getButton(c))}
            <RightDrawer/>

        </div>
    );
}

export const buttonsConfig = () => [
    {
        label: window.getContent('home_tab_label'),
        link: `https://nwow4net-zero.eu/index.php/${languageRouteAbbreviations[window.language].short}/`
    },
    {
        label: window.getContent('about_tab_label'),
        link: `https://nwow4net-zero.eu/index.php/${languageRouteAbbreviations[window.language].short}/about-the-project-${languageRouteAbbreviations[window.language].long}/`
    },
    {
        label: window.getContent('project_tab_label'),
        selected: true,
        // link: `https://nwow4net-zero.eu/index.php/${languageRouteAbbreviations[window.language].short}/project-results-${languageRouteAbbreviations[window.language].long}`
        children: [
            {
                label: window.getContent("sm_tab_1"),
                link: languageRouteAbbreviations[window.language].pr1Link
            },
            {
                label: window.getContent("sm_tab_2"),
                link: routes.pr2,
                route: routes.pr2,
            },
            {
                label: window.getContent("sm_tab_3"),
                link: `https://nwow4net-zero.eu/index.php/${languageRouteAbbreviations[window.language].short}/project-results-${languageRouteAbbreviations[window.language].long}/pr3-impact-tool`
            },
            {
                label: window.getContent("sm_tab_4"),
                link: routes.book,
                route: routes.book,
            },
            {
                label: window.getContent("sm_tab_5"),
                link: routes.dialog,
                route: routes.dialog,
            },
        ],
        endIcon: <ExpandMore/>
    },
    {
        label: window.getContent('news_tab_label'),
        link: `https://nwow4net-zero.eu/index.php/${languageRouteAbbreviations[window.language].short}/news`
    },
    {
        label: window.getContent('newsletters_tab_label'),
        link: `https://nwow4net-zero.eu/index.php/${languageRouteAbbreviations[window.language].short}/${languageRouteAbbreviations[window.language].newsletterSpecialSuffix}`
    },
    {
        label: window.getContent('contact_tab_label'),
        link: `https://nwow4net-zero.eu/index.php/${languageRouteAbbreviations[window.language].short}/contact-us`
    },
]
