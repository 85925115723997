export const mobility = {
    en: {
        learningObjectives: {
            heading: "Learning Objectives",
            html: `
<div>
    <p>The overall objective is to empower HR professionals to implement practical, innovative mobility solutions that contribute to reducing the company's carbon emissions and enhancing overall sustainability.&nbsp;</p>
    
    <p><span style="font-size:1.25em;"><strong>Stakeholder Engagement and Strategic Mobility Management: &nbsp;</strong></span></p>
    
    <ul>
    <li>Develop competencies in engaging effectively with a diverse range of stakeholders including CEOs, top management, finance departments, and public authorities to create and implement strategic mobility plans. This includes understanding the significance of business location decisions in relation to the employment pool and public transport accessibility, and their impact on the company's carbon footprint.</li>
    <li>Gain skills in leading and influencing top management to adopt a positive approach towards work mobility, transcending mere compliance to actively integrate mobility as a part of the work experience.</li>
    <li>Learn to construct and present compelling business cases to finance departments, demonstrating the direct and significant return on investment of sustainable mobility projects.</li>
    <li>Enhance abilities in setting up effective communication strategies, utilizing tools like gamification and continuous messaging, to change employee behavior related to mobility.</li>
    </ul>
    
    <p><span style="font-size:1.25em;"><strong>Operational Implementation and Innovation in Mobility: &nbsp;</strong></span></p>
    
    <ul>
    <li>Learn how to implement mobility strategies through the appointment and training of Mobility Managers who can lead projects across various departments.</li>
    <li>Learn to gather and analyze data on current mobility practices and individual commuting preferences to tailor mobility solutions.</li>
    <li>Develop skills in initiating participatory approaches, creating internal ambassadors, and fostering collaboration among employees to promote sustainable commuting methods.</li>
    <li>Understand the importance of addressing practical aspects such as car parking policies and encouraging alternatives like cycling, carpooling, and the use of public transport.</li>
    <li>Gain insights into leveraging technological tools such as multimodal itinerary platforms, and the implementation of sustainable mobility packages to incentivize environmentally friendly commuting.</li>
    </ul>
</div>`
        },
        stakeHolders: {
            heading: "Stakeholders",
            html: `
            <div>
            <p>The Human Resources Director/Manager is only partly responsible for matters relating to work mobility (mobility to and from work).&nbsp;</p>
            <p>In order to contribute to the reduction of company's carbon emissions, the Human Resources Director/Manager has to work closely with different stakeholders, as:&nbsp;<br>
&nbsp;</p>
<div class="h5p-image-hotspot-popup-content has-scrolled"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>CEO/Top Management</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Business Location&nbsp;</h3>

<p>Business location is defined as a place or structure occupied by a company to run its operations. When we look at the rationale behind the choice of location for companies, one criterion is not negligible and generally appears to be in the top five: the employment pool in which the company is going to position itself. This is a consideration which is rather upstream from the perspective of HRDs.&nbsp;</p>

<p>When your organisation has relocation plans, investigate a ‘stay or go’ decision, consider merging multiple offices or opening a satellite office, it is important to analyse associated impacts and opportunities for commuting. Choose a location near a train or metro station, and with access to other public transports.&nbsp;&nbsp;</p>

<h3>Positive Approach&nbsp;</h3>

<p>One of the key points to work on is to make the Top management and the company understand what it can gain by approaching the work from home in the morning, to home in the evening, and seriouselly consider mobility as an integral part of the work activity. Once this is the case, it is easier to understand why the company needs to spend time on this, why it needs to put energy and resources into it, or take certain measures about it. It would be beneficial to move away from the administrative constraints associated with the obligation to draw up a mobility plan, and instead to take a positive approach.</p>

<h3>Opportunities and… Constraints&nbsp;</h3>

<p>Supporting the process for less carbon-intensive mobility is never the core business of a company - and it will not become so. The amount of flexibility in terms of the constraints that can be imposed is not so great. The first step is therefore to establish the level of constraints that the company is prepared to impose.</p>

<h3>Walk the Talk&nbsp;</h3>

<p>If initiatives are taken in favour of more carbon-friendly mobility, it is essential that they are used and promoted by top management in an exemplary manner. Among the plans studied outlined in our Study, a leading figure championing the plan is a key to success.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Finance</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>The amount of money that companies are willing to spend on mobility at a strategic level is usually extremely small compared to what they may spend on tax optimisation, or HR service providers. It was evaluated to be between eight to ten times less for awareness-raising activities. It is impossible with such low ambitions to make a significant difference. Unfortunately, we see that working on mobility plans is still perceived as a constraint by companies, and not as a real opportunity to change their environmental impact. Yet, the return on investment on mobility projects can be direct and significant.&nbsp;</p>

<p>The HR Director will be able to make progress in this area by developing a realistic business case with concrete figures.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Communication</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Communication Strategy&nbsp;</h3>

<p>To really change behaviours related to mobility, it is important that companies develop a communication strategy and that the effort is regularly deployed. The message must be hammered continuously. Gamification, for instance, is a great opportunity here, by making it light-hearted, with prizes to win. Creating a logo and/or a brand for the workplace mobility plan can help attract and maintain attention.</p>

<ul>
\t<li>
\t<p>At SWIFT in Belgium, a "charity challenge" was organised to encourage cycling during the mobility weeks: for every kilometre cycled, 1 euro was donated to cycling-related associations. This system was very successful with employees, as it combined extrinsic and intrinsic motivation.&nbsp;</p>
\t</li>
</ul>

<ul>
\t<li>
\t<p>At the Service Public de Wallonie in Belgium, a "Round the world cycling tour" was organised: participants were asked to enter their work-related cycling journeys on an online form as part of a collective challenge to cycle 40,075 kilometres together over 26 weeks. Destinations were collectively reached, with anecdotes, goodies, and a diploma at the end. It used the dynamics of levels and badges, while playing on the escape, unpredictability, and creativity of the participants.</p>
\t</li>
</ul>

<p><em><strong>Source: UWE Mobility Unit</strong></em></p>

<h3>Focus on the Right Thing&nbsp;</h3>

<p>Mobility should not be approached directly from an environmental angle. The environmental dimension will be the positive result, the objective towards which we must strive. But it is not the communication tool: people may not change for environmental reasons, but rather for reasons of convenience, efficiency, community... In short, for reasons linked to human behaviours.</p>

<p>Employees generally do not change their behaviour because it decreases the company's carbon footprint, but because they are going to have new options to better manage their families, make their journeys more pleasant... and, ultimately, have a positive impact on the planet.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Mobility Manager</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Appoint a Mobility Manager</h3>

<p>A Mobility manager is an internal person who leads the mobility project and, ideally, reports to the general management, because the mandate is transversal: communication, HR, facilities, etc. The person can come from any department - and, why not, from HR - but must have direct contact with top management and staff representatives. This person must then be trained in mobility issues.</p>

<h3>A Mobility Manager is Not Enough</h3>

<p>Mobility remains the expression of individual freedom outside of any service constraints. From an individual freedom perspective, for example the normative role of the car remains very important. There is therefore a lot of convincing and educating to be done. HR Directors can act on travel related to work (for example in the transport sector, or if the company employs mobile employees), but it will be more difficult for the home-work commute, which partly touches the private sphere. They are then much more dependent on people's goodwill, or on what people are willing to do, consciously or unconsciously.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Data Manager</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Becoming actors of change means companies must invest the resources – financial, human, and time – to conduct a thorough examination of their current mobility practices and how individual variables (age, gender, employment position, commuting routes, etc.) affect their preferred mode of transportation. Gathering information through surveys, employees, supplier and customer interviews and other tools is crucial in crafting an appropriate response. Companies already have an enormous amount of data and, if they are willing, often have the necessary elements on hand to progress in a simple and inexpensive way.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Trade Unions</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>If you produce mobility plans with a top down, without involving the trade unions, without making sure you have ambassadors, and without testing things on the ground, it won't work. Mobility is one of the most sensitive subjects in companies. And once damage is done, it is extremely difficult to get back into a positive dynamic. You also have to be prepared to go back when you make a mistake and accept to review your plans.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Public Authorities</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>When drafting a successful carbon-neutral mobility plan, it is essential to get a clear engagement from territories and official institutions. Local planning is crucial. And usually, policies are the main motor for change: 60% of corporate shifts in mobility planning are motivated by the wish to align (or to comply) with national, regional, or local policies.&nbsp;</p>

<p>The development of remote working will be regulated by the employer, but it also has to be integrated in a public policy perspective. If remote working practices are allowed to develop without specifying the objectives sought from a public policy point of view, there is little chance of achieving them.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Public Transport Operators</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>If Mobility or HR Managers of companies, even from different sectors, work together in a 500- to 800-metre area around a transportation hub - a train station, a metro station, etc. - they will be able to start a dynamic and, above all, to go and meet the public authorities, the public transport operators and the mobility services and say to them: 'There are, let's say, 30 of us; we have identified such and such a need; what can we do about it'. If just one company comes, it will have no impact. With 30 companies, the situation changes. You need this scale to have an impact.</p>
</div></div></div>
</div>
            `
        },
        culture: {
            heading: "Culture",
            html: `
            <div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1c70fc1f-9e1f-4d2d-bb3d-c7867a33f9e3">

</div><div class="h5p-column-ruler h5p-column-space-after"></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-067d5e0e-e4fb-43be-84fa-825384498127"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-6509dd97d9c6f.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8dc96038-5902-4fae-b1bc-6ff31e4bd15f"><p><strong><span style="font-size:1.5em;">Influence Behaviours </span></strong></p>

<p>Organisations cannot influence where employees choose to live, as it would be an obvious breach of their privacy. It is hard to counterweigh commuting to work, even when parameters such as cheaper housing or better personal life quality are considered. Work with champions to sensitise colleagues.&nbsp;</p>

<p><strong>Example:</strong></p>

<p>Wever is a platform created in France that proposes to reinvent inclusive and efficient mobility by placing the user and his or her expressed needs at the heart of the design of the offer. It gives everyone the opportunity to express their needs, to test new solutions and to vote to implement the most interesting ones in the long term.</p>
</div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9772c0de-3792-4fe2-a5f6-899984bb576a"><p><strong>How does it work?&nbsp;</strong></p>

<ol>
\t<li>A mobility diagnostic is used to collect the travel habits and needs of employees. It only takes a few minutes to collect the needs of each individual and establish a diagnosis.&nbsp;</li>
\t<li>
\t<p>A dashboard enables the continuous monitoring of the diagnoses carried out and to see the recommendations for action with comments. This visual tool allows the offer to be co-constructed with the users.&nbsp;</p>
\t</li>
\t<li>
\t<p>The algorithm detects mobility opportunities by revealing existing solutions and modelling new acceptable solutions.&nbsp;</p>
\t</li>
\t<li>
\t<p>With individualised support for change and a logic of proximity rewards, the transformations are visible in a few weeks.</p>
\t</li>
</ol>

<p>More information:&nbsp;<a target="_blank" href="http://www.wever.fr/" target="_blank">www.wever.fr</a></p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-065d48e1-ed65-4c87-803a-8063f0d2bb9b"><p><strong><span style="font-size:1.5em;">Make it Concrete</span></strong><br>
&nbsp;</p>

<p>Don't do campaigns on work-related mobility with posters in the lift. You may not change any behaviour by doing it. Go to the cafeteria three times a week to talk to people, take an interest in their mobility and their concerns, and suggest ways forward. In short, personalise your approach for each person. This takes time and energy. Another example is to create a bicycle group, and hold bicycle events on- and off-site. You could also ask convinced colleagues to make a presentation or speak on the topic.&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6d757e35-654c-416e-a38f-5fbf7e44a845"><p><strong><span style="font-size:1.5em;">Address Car Parking Issues</span></strong></p>

<p>Car parking is a central issue: companies developed measures ranging from incentives (a financial reward for not bringing one’s car on site for instance) to penalties (introducing on-site parking charges or a maximum number of days/week or month to use the car park).&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8cb77e76-e0c3-439c-9f90-d582dd82222c"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650a0615ebf86.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42f4fadb-eb58-4f8a-a545-94bbab8675ab"><p><span style="font-size:1.5em;"><strong>Initiate a ParticipatoryApproach</strong></span></p>

<p>Set up a Mobility cluster involving voluntary staff members to collect ideas and suggestions, and develop internal ambassadors. Some organisations delegated the role of mobility/travel plan management to line managers, to create some in-group emulation.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-a57aaa91-50f0-4dce-b73b-23410fda6402"><p><strong><span style="font-size:1.5em;">Promote Thinking Outside the Box </span></strong></p>

<p>Encourage people thinking about the reason for travel before choosing/thinking of the way/mode of travel to work.</p>
</div></div>`
        },
        hr: {
            heading: "HR Policies",
            html: `
            <div>
            <div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>WORK ORGANISATION - Formalising Remote Working Agreements</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Until three or four years ago, remote work was largely informal. Today, it is becoming more and more formalised due to its widespread adoption in large organisations following the health crisis. This formalisation can take the form of a company policy and/or discussions between the employer/managers and the unions/employees on the guidelines within which the employer wants to frame remote work. Faced with the saturation of public transport and road networks, there is a challenge to develop remote work as a way to regulate these mobility flows.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content overflowing has-scrolled" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>REWARDS - Sustainable Mobility Package</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>In the same way that public transport is covered for employees, such a scheme offers a form of compensation for those who come to work using ‘virtuous’ modes such as cycling or carpooling, for example. As far as company cars are concerned: diesel cars to be forbidden, replacement of gazoline vehicles with zero-emissions vehicles.&nbsp;</p>

<p><strong>In France,&nbsp;</strong>employers can pay a sustainable mobility package to their employees who use a sustainable means of transport for their business trips. The following means of transport are covered: personal bicycle (including electric bicycle),&nbsp;carpooling as passenger and driver, shared mobility services (electric or hybrid car sharing, rental and self-service provision of scooters or bicycles), public transport (excluding season tickets). The maximum amount of the sustainable mobility package is €700 per year and per employee. The amount paid is exempt from social security contributions and income tax.</p>

<p><strong>In Belgium,&nbsp;</strong>the bicycle allowance is a mileage allowance granted by an employer to employees who use a bicycle to travel all or part of the distance between their home and workplace. The allowance is intended to cover the costs of the cyclist,&nbsp;but above all it is intended to encourage more employees to use their bikes. The kilometre allowance is tax-free up to EUR 0.25 per km travelled for the financial year 2023, income 2022. In other words, as long as the amount of the mileage allowance does not exceed EUR 0.25 per kilometre travelled, there is no tax to pay. If the employer grants a higher mileage allowance, this surplus is taxable as professional income.</p>

<p><strong>Check what exists in your country!!!</strong></p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>WORKING TIME - Encourage Soft Mobility Travel</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>The employer can credit mobility time as working time, but only in the case of soft mobility travel - i.e. any mode of transportation that does not use a combustion engine, nor emits greenhouse gases. This has the added advantage to give the person the opportunity to travel outside peak hours.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>RECRUITMENT - Recruiting Locally</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Recruiting by taking into account the distance between home and office in the candidate selection could be used as a parameter. But it will be all the more difficult to activate when the job market is tight, or profiles are rare to find. With the very strong experience we have acquired in remote working - imposed by Covid-19 –, local recruitment continues to make sense in itself but seems even more difficult to practice.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>WELL-BEING - Mobility Matters</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Mobility is one of the pillars of well-being at work. The approaches can then be very transversal. Promoting cycling can, for example, contributes to improving physical fitness, stimulating concentration at work, and even fostering community life.&nbsp;</p>
</div></div></div>
</div>
`
        },
        tools: {
            heading: "Tools",
            html: `
            <div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-ad634c59-8c77-49de-b651-13e5f6d885f5">
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4fba2945-7e82-4e52-8e74-afa0bdfe1f8b"><p><strong><span style="font-size:1.5em;">Conduct Mobility Analysis</span></strong></p>

<p>Conduct a carbon emissions assessment of your company: dedicate an expert to identify and focus on the most impactful topics (mobility, plane travel, building, etc.). Then conduct a mobility scan based on accurate and current data.&nbsp;</p>

<p><strong>Examples:</strong></p>

<p>Mobility Label, tools for a smarter commuting</p>

<p><a target="_blank" href="https://mobilitylabel.com/">https://mobilitylabel.com</a>&nbsp;</p>

<p><strong>Mob Box </strong>aggregates and compiles mass raw data. From internal corporate fleet data to external open-source data, it turns figures into straightforward maps and intuitive charts. It will help you to get a clear view on your employees’ mobility. Assess the overall modal mix and analyse the CO2 emissions and the related costs.</p>

<p><a target="_blank" href="http://www.mob-box.eu/" target="_blank">www.mob-box.eu</a>&nbsp;</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-08185ad9-d286-407c-8269-b3bcecba9cc2"><p><strong><span style="font-size:1.5em;">Explore available public solutions </span></strong></p>

<ul>
\t<li>
\t<p>In France: the <a target="_blank" href="https://www.ecologie.gouv.fr/sites/default/files/mobilite%20durable%20en%20entreprise.pdf" target="_blank">Forfait Mobilité Durable</a> (Sustainable Mobility Package)- each company can subsidise or finance its employees' home-work journeys up to 800 euros per year, exempt from tax and social security contributions, as long as they are used in 'green' means of transport - public transport, electric scooters, shared electric cars, carpooling…&nbsp;</p>
\t</li>
\t<li>
\t<p>In Belgium: the <strong>Budget Mobilité</strong>&nbsp;(Mobility Budget) allows companies to offer employees who have a company car a budget for more environmentally friendly mobility options —&nbsp;<a target="_blank" href="https://lebudgetmobilite.be/fr" target="_blank">https://lebudgetmobilite.be/fr</a>&nbsp;</p>
\t</li>
</ul>
</div><div class="h5p-column-ruler h5p-column-space-after"></div><div class="h5p-column-content not-an-ios-device h5p-image-hotspots" id="h5p-interactive-book-section-076e63a2-1daa-4256-bf2d-59c590c6cbe8"><div class="h5p-image-hotspots-container" style="width: 100%;font-size: 39.8404px;"><img class="h5p-image-hotspots-background" src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/image-650a166eaf053.jpg" aria-hidden="true" style="width: 100%;"><button class="h5p-image-hotspot h5p-image-hotspot-info" role="button" tabindex="0" aria-haspopup="true" title="Hotspot 1" aria-label="Hotspot 1" style="top: 35.9893%; left: 25.2%; color: rgb(0, 88, 145);"></button><button class="h5p-image-hotspot h5p-image-hotspot-info" role="button" tabindex="0" aria-haspopup="true" title="Hotspot 2" aria-label="Hotspot 2" style="top: 84.2714%; left: 73.6%; color: rgb(0, 88, 145);"></button></div></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-eb98b054-5f2c-454c-9a29-05efff3cce27"><p><strong><span style="font-size:1.5em;">Individual diagnostics / Mobility Surveys </span></strong></p>

<p>Look at how people travel to their workplace and offer them alternatives, by means of individualised marketing actions. There is a whole process of diagnosis and support for people to show them that the car is not necessarily the most efficient way to move around. A more in-depth analysis (by following people for several days) can show them that there are other ways of doing things, less motorised, more economical, etc.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d7ebb14-1b14-4a0b-a034-151adcc5f3bb"><p><strong><span style="font-size:1.5em;">Multimodal Itinerary Platforms</span></strong></p>

<p>Multimodal itinerary platforms can show the different options and allow people to realise that travelling by car will be less efficient than with other modes of transportation. Some of these tools display the CO2 emissions associated with different modes of travel.</p>

<p><strong>Example:</strong></p>

<p>Skipr, the all-in-one solution for managing corporate mobility</p>

<p><a target="_blank" href="http://www.skipr.co/">www.skipr.co</a></p>

<p>Skipr fights against single-user car use in the city. It provides employees with an application that allows them to see how to get from A to B by combining the different means of transportation that exist around them: public transport, shared vehicles, electric scooters, green vehicles to get to the station, etc. It is a co-pilot that will secure them the option of not taking their car. It allows buying public transport tickets on the company's account, combined with a payment card, linked to the same ‘mobility budget’, for alternative types of mobility. For the company, it provides a management platform that will do two things: set up the company guidelines (determine who is entitled to what) and provide a reporting tool that gives full visibility on the savings generated and the reduction in carbon emissions compared to the use of a traditional car. Employees have a dashboard with their own situation, but the company only has aggregate reports. When searching for travel options, employees receive three pieces of information: the arrival time, the cost in euros and the CO2 emissions.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4bf3cd48-c7e8-4a5f-a5a3-4727c4478547"><p><strong><span style="font-size:1.5em;">Encourage Carpooling </span></strong></p>

<p>Conditions of success: flexibility of working hours is one of the first reasons for not carpooling between colleagues. Not having the same working hours is a limiting factor, especially when personal constraints - picking up the children, having to run errands, etc. - are added. Having a project manager, who with a table and a pencil, records journeys and matches them up, can help. If you leave it up to people to arrange it between themselves, it's more complicated. If you leave it to an application, it's complicated too. Digital tools work very well for long distances, but they are not as effective for short distances, recurring and daily use.</p>

<p>However, there are platforms to facilitate carpooling with matching options, information on fiscal benefits, and tracking possibilities to orient communication. It is important to note that carpooling does not work well when the work site is near a good offer of public transport.&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e97d6b7b-85ca-4b8e-adc4-c549a30315f2"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650ae2ffdc5cc.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f2705860-d508-4b16-9089-9bba70468e72"><p><strong><span style="font-size:1.5em;">Shuttle </span></strong></p>

<p>Several organisations with the highest growth in public transport use had implemented a free, dedicated company shuttle connected to local towns and/or local train and bus stations. A partnership included organisations that increased the number of bus services arriving at the site by an impressive average of about 14 extra buses in the peak hour in one research. Having appropriate and tailored services appeared more important than absolute numbers: cheaper fares, negotiated tickets, improved off-site infrastructure/service quality, and funding for new bus stops on or near the site, for example.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c293a877-1d7a-44a0-8b05-524e78f4b318"><p><strong><span style="font-size:1.5em;">Cycling </span></strong></p>

<p>Factors most closely correlated with having high levels of cycling appeared to be having high quality off-site access or improving off-site access; increasing available parking for cyclists; and offering a cycle repair services. It was also notable that most of the organisations in the study had provided showers, changing, drying, and/or locker facilities; negotiated discounts on cycle equipment.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-457e418e-7a81-4932-82ba-2fcbf1e21fcd"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650a17655699c.jpg" alt="" title="Cycling" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-801a1d35-3531-4738-807c-79f59dd9e601"><p><span style="font-size:1.5em;"><strong>Electric Cars? Yes, But…&nbsp;</strong></span></p>

<p>The electric car is not a solution to traffic jams. A car is still a car, whether it's electric, hydrogen, petrol or diesel, it takes up space on the road. It does not emit 'zero CO2', it emits less: it all depends on how the electricity was produced. This is the difficulty with mobility issues: there is no such thing as one size fits all. Everyone will have their own specificity. With electric cars, there are also problems linked to cost and charging to be taken into account. There are still obstacles. Things are changing fast, but there is no miracle solution.</p>

<p>Install charging infrastructure for electric cars: they are necessary to increase the use of electric cars.&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-07fc7d73-8673-4f28-8e07-2c87d26235f0"><p><strong><span style="font-size:1.5em;">Bike leasing (with electric bikes)</span></strong></p>

<p><strong>Example:</strong></p>

<p>Lease a bike (Belgium)&nbsp;</p>

<p>As an employer in Belgium, you can choose to offer bicycle leasing in various ways : free of charge or with a (partial) contribution to the bicycle. In all scenarios, employees enjoy a tax advantage of around 40%.&nbsp;</p>

<p>\`You do not pay any benefit in kind on a leased bicycle, because you use the bicycle regularly for commuting. You can also pay out a bicycle allowance. This motivates your employees to cycle. The bike leasing regulations make no distinction between a leased and a private bike. Results? Fitter employees: 1-2 days less absenteeism and a powerful contribution to a green mobility plan.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6c224ae6-6652-45b9-a674-13597a5bb424"><p><strong><span style="font-size:1.5em;">Eco-driving Training </span></strong></p>

<p><strong>Example:</strong></p>

<p>Being ecologically responsible when you’re on the road calls for more than an environmentally friendly vehicle. You need to have a green driving style. And this is something your drivers learn in the E-positive training programme, a one-year training course by ALD Automotive.</p>

<p><strong>How it works:</strong></p>

<p>«&nbsp;We start with what your drivers can do; this puts their current driving styles and their vehicle usage on the cards. After that, they follow an e-learning course and receive training – individually and in a group.&nbsp;The instructor gives them constant personalised tips and points they need to pay attention to. The result? At the end of the training, everyone drives with an e-positive attitude.&nbsp;»</p>

<p><strong>10 tips for Ecological Driving</strong></p>

<ol>
\t<li>
\t<p>Shift to a higher gear at an appropriate time. With a diesel engine, anywhere from 2,000 revs and with a petrol engine, 2,500 revs.</p>
\t</li>
\t<li>
\t<p>Time to get moving? Push your accelerator in fully, because speeding up slowly will certainly not save you any fuel.</p>
\t</li>
\t<li>
\t<p>Keep sufficient distance from the vehicle in front of you. Then you won’t need to constantly brake and accelerate.</p>
\t</li>
\t<li>
\t<p>Do you need to slow down or stop? Don’t go straight to the brake, but release the accelerator in good time. You’ll slow with engine braking and without riding your clutch.</p>
\t</li>
\t<li>
\t<p>Turn cruise control on for consistency – and therefore environmental friendliness.</p>
\t</li>
\t<li>
\t<p>Will you be at a complete stop for more than 20 seconds? Then turn your engine off.</p>
\t</li>
\t<li>
\t<p>Plan your route in advance so that you don’t click up any unnecessary mileage.</p>
\t</li>
\t<li>
\t<p>You don’t need your roof box, bike- or baggage rack? Take it off and cut down your air resistance.</p>
\t</li>
\t<li>
\t<p>Regularly check your tyre pressure.</p>
\t</li>
\t<li>
\t<p>Get your vehicle serviced punctually: only then will you be able to drive economically and safely.</p>
\t</li>
</ol>

<p><em><strong>Source: ALD Automotive</strong></em></p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-0c1c9678-0a6c-445a-95ce-4a25e6395d09"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650a17e559c50.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3e08a77a-372e-478d-a71b-1e82dbff5942"><p><strong><span style="font-size:1.5em;">MaestroMobile </span></strong></p>

<p>MaestroMobile is a serious game developed by Espaces-Mobilités in Belgium, in the field of mobility that aims to change behaviour through experimentation.</p>

<p>More information:&nbsp;<a target="_blank" href="https://www.maestromobile.eu/">https://www.maestromobile.eu</a>&nbsp;</p>

<ul>
\t<li>The <strong>Mobility Van</strong>&nbsp;is made up of mobility experts who travel to public spaces and companies and show employees all the mobility solutions in a practical way (test of micro mobility machines, bicycles, applications…)</li>
\t<li>The <strong>City Game </strong>is an urban serious game that is organised in the form of a teambuilding and allows participants to test different mobility solutions in a practical way while (re)discovering the city, completing challenges,&nbsp;answering quizzes, etc. By using the codes of popular games such as Pokemon Go or the principles of urban races, the City Game has created its own universe related to the discovery of mobility services.</li>
\t<li>In the same way as fitness programmes, the <strong>Mobility Passport</strong>&nbsp;offers a mobility challenge that takes participants through the various stages of learning about multi mobility, from getting to know a service to using it, over a period of about a month. Through a mobile application, the participant obtains validations of their skills and is awarded certificates that take the form of a stamp on their mobility passport.</li>
\t<li><strong>GO Mobility</strong>&nbsp;is a hybrid platform that offers a complete programme of coaching and discovery of mobility alternatives.</li>
\t<li><strong>Critical MaaS</strong>&nbsp;is a personalised coaching to experiment with new mobilities and change your way of moving. Accompanied by experts, you test all the new services and become a master of your mobility.&nbsp;</li>
</ul>




</div></div>
            `
        },
        inspiringInitiatives: {
            heading: "Inspiring initiatives",
            html: `
            <div>
            <div class="h5p-image-hotspot-popup-content has-scrolled"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Remove Parking Spaces</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>To discourage the use of cars to go to work, some companies removed reserved parking spaces in companies. If all employees are almost guaranteed a space at their destination, they will use the car much more to commute to work than if they did not.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Facilitate Shared Mobility</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>A Brazilian energy company devised an application to facilitate shared mobility in its São Paulo location: users would enter their commuting route, and the app would cross the data with all other inputs and introduce employees following similar itineraries. Shared mobility increased by 18% of transportation and saved the company US$300,000 per year.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Encourage Cycling</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>More innovative best practice amongst the case studies included the provision of better security for bikes (individually lockable parking stands; police tagging or offering cycle insurance schemes); cycle equipment loans (batteries, lights, pumps, locks, helmets, car racks, child seats, etc.); site-specific cycle maps; financial incentives and complimentary products (for example, offering safety jackets).</p>

<p>Examples of positive actions: a partnership to rent cargo bikes for deliveries or use by technical workers, an electric bike lending campaign to allow employees to test them, a ‘Bike to work tool’ to measure carbon neutrality and participate to different challenges.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Decentralised Shared Offices</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Pilot project on centralized15 shared office space to allow those who cannot do telework on some days not to have to come all the way to HQ, if they live far away or if it is too complicated for them. Done with coworking operator, with a centralized system for invoicing.</p>

<p><strong>Make Train more Competitive </strong></p>

<p>Business travel policy:&nbsp;</p>

<ul>
\t<li>
\t<p>allocation of funds to compensate the higher price of the plane to make trains competitive.</p>
\t</li>
\t<li>
\t<p>not allowed to take the plane for trips less than 2hrs that can be done by train.</p>
\t</li>
</ul>
</div></div></div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-bd0d1aab-e9f3-4954-82d7-5ca803053822"><p><strong><span style="font-size:1.5em;"><h2>Pay what I consume' approach</h2></span></strong></p>

<p>Pay what I consume' approach for company cars: people control what they consume + reimbursement of km that have not been consumed or overconsumed / influences leasing price and budget that will be allocated.</p>
</div>
</div>`
        },
        recommendation: {
            heading: "Recommendation",
            html: `
            <div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4052f6b2-2d6c-437b-bd67-4e316610281b">
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f3a1154d-cf21-4744-8f37-5b942ddae908"><p><span style="font-size:1.5em;"><strong>Measure… But It is Better to Advance With Imperfect Measurements…&nbsp;&nbsp;</strong></span></p>

<p>Waiting for perfection is the best way of not doing anything…&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9c17bf2a-baba-43d2-a449-63d3a162ff6a"><p><span style="font-size:1.5em;"><strong>Choose a Company Location Accessible By Sustainable Means of Transport </strong></span></p>

<p>Companies do not have the capacity to ‘recruit locally’, nor to impose living locations on their employees, except by providing accommodation as part of the employment contract. They cannot influence the home-work distance, but they can choose a location that is accessible for their workers by more sustainable means than the car.&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-32b491be-187b-4b28-b25e-c93cdd367c4c"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650ae7da4ccaf.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42a6405c-6e2a-4f70-b7d8-501f3b5e3f66"><p><span style="font-size:1.5em;">Plan, Operate, Activate</span></p>

<p>The first pillar is the diagnosis and definition of a strategy. Then comes the implementation with mobility services, data management, financial monitoring, infrastructure, etc. Most companies stop there and then see that it doesn't work. Why Simply because the behavioural change dimension is missing.&nbsp;</p>

<p>All of the above is necessary, but not sufficient. You have to 'activate'. When a company tells you that it has placed ten electric bicycles in the reception area, but no one uses them, this step is missing: activation. You haven't motivated people.&nbsp;</p>

<p>Motivating them can be done in different ways, like organising a game with a reward for those who use them the most.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-7565574d-621a-4f00-b920-c201f9d2f507"><p><strong><span style="font-size:1.5em;">Gain an Understanding in Long-term Commuting or Over Very Long distances</span></strong></p>

<p>E.g.: dual residences linked to work - either with a second residence, or in a hotel, or even in an AirBnB - , dual residences linked to people with two jobs in two different cities; very frequent travel linked to work, etc.&nbsp;</p>

<p>Yet this deserves to be considered from an HR point of view, and even more so when there is a move to a new workplace in terms of the impact on commuting and the necessary support.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-80c0adf5-cab3-4fbb-98bd-c46a956d2977"><p><strong><span style="font-size:1.5em;">Do Not Focus on The Budget Aspect Only</span></strong></p>

<p>The logic of mobility choice also includes speed (if it takes 1.5 hours by public transport, as opposed to 40 minutes by car, money will not have much impact), but also many other criteria such as comfort (i.e. the whole experience of travelling: the train has a comparative advantage if you can do other activities on it - sleep, read, work, etc. - but that advantage is nulled if the train is crowded and you don't have a seat, for example), the image or experience you have of the different modes of transportation, habits, safety, etc. Consider a mobility policy that integrates all the dimensions (cost, speed, comfort, etc.) so that the alternatives are attractive.&nbsp;</p>

<p>Shift from a mobility budget of 500 euros, to a budget of x kilos of CO2 and allocate employee’s mobility budget accordingly, possibly with trading between colleagues or the possibility of exchanging savings for days off.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6594bb16-8d4a-40a3-baae-b2c961991ac6"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650ae87fc0ae4.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-db56f5ca-9960-46ca-b07c-3aa08b24a38f"><p><strong><span style="font-size:1.5em;">Encourage Multimodality </span></strong></p>

<p>Promote modal shifts so that people minimise the role of the car in their journeys. People who live in sparsely populated, peri-urban areas may be able to reduce the use of the car if they use other modes of transportation in addition to the car.</p>

<p>Shift to the augmented rather than the diminished car: continue to have a company car, possibly smaller, with better environmental performance, but let's also give people the opportunity to travel differently. This does not always require increasing the offer, but just agreeing to facilitate access to public transport or other forms of mobility.&nbsp;</p>

<p>There is a plethora of multimodal solutions on offer today.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-2b595af0-7cfc-41da-b1a7-f849be29cfe8"><p><strong><span style="font-size:1.5em;">Company Cars: Pay Attention To…&nbsp;</span></strong></p>

<p>If the company feels obliged to provide company cars (for talent attraction, retention, status, representation…), pay attention to the type of vehicle, even if we know that the environmental impact of an electric vehicle is not neutral either</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e2b6406f-ba7a-45b7-83b8-84806274d67f"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650ae8f013a9d.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5821544b-625c-4c3a-a64e-9ddf0f3eeb2f"><p><strong><span style="font-size:1.5em;">Deal With Parking Spaces </span></strong></p>

<p>If the company offers parking space, it is, in a way, paying for employees to use their car. What we need is to simply balance out the advantages across different transportation types: if you commute by bike, you don't take up any parking space. If you take up less space, the company could grant you a certain amount - for example 200 euros - because you save the company money by coming by bike.</p>

<p>Target reserved parking spaces for managers, which are generally better situated. This hierarchical advantage does not reflect the company’s ambition for mobility that is less carbon intensive. If there is a car park, the spaces closest to the office entrance should be reserved for carpooling.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d56820b5-cdac-4d65-b9c3-e7e19d058c6a"><p><strong><span style="font-size:1.5em;">Use ‘Internal Ambassadors’&nbsp;</span></strong></p>

<p>Work with pilot projects, with small groups of ambassadors ready to play this role. Don't revolutionise mobility in your company; start with people who are willing, and with small initiatives that will germinate and grow. Mobilise top management: when the CEO and top managers get on their bikes and do this regularly, it has a major effect on the organisation.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-96546409-df37-40a1-8000-e20cc49697d3"><img src="https://lp.youngkreativ.eu/pluginfile.php/283/mod_hvp/content/41/images/file-650ae968de311.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3c1ff5d8-04bb-4246-b817-45823add9089"><p><strong><span style="font-size:1.5em;">Key-Word: Collaboration </span></strong></p>

<p>The Kiba-Janiak and Witkowski review notes that a key indicator of a successful mobility plan is the collaboration with a network of stakeholders, may they be public or private, and announces it as inherent to reducing GHG emissions.</p>




</div></div>
            `,
        }
    },
    mk: {
        learningObjectives: {
            heading: "Целите на учењето",
            html: `
            <div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-fea7cbcd-7eb5-400b-bcff-997ed232168a">

<p>Општата цел е да сѐ поттикнат професионалците за човечки ресурси да имплементираат практични, иновативни решенија за мобилност кои придонесуваат за намалување на емисиите на јаглерод на компанијата и подобрување на севкупната одржливост.</p>

<p><span style="font-size:1.25em;"><strong>Ангажирање на засегнатите страни и стратегиско управување со мобилноста:</strong></span></p>

<ul>
\t<li>Развијте компетенции за ефективно ангажирање со различен опсег на засегнати страни, вклучувајќи ги извршните директори, врвниот менаџмент, секторите за финансии и јавните власти сѐ со цел креирање и спроведување на стратешки планови за мобилност. Ова вклучува разбирање на важноста на одлуките за деловна локација во однос на опсегот на вработување и пристапноста до јавниот транспорт, и нивното влијание врз јаглеродната потрошувачка на компанијата.&nbsp;</li>
\t<li>
\t<p>Стекнувајте вештини за водење и влијаење врз врвниот менаџмент за усвојување позитивен пристап кон работната мобилност, надминувајќи ја само усогласеноста за активно интегрирање на мобилноста како дел од работното искуство.</p>
\t</li>
\t<li>
\t<p>Научете да конструирате и презентирате привлечни деловни случаи на финансиските одделенија, покажувајќи го директниот и значителен поврат на инвестицијата на проектите за одржлива мобилност.</p>
\t</li>
\t<li>
\t<p>Подобрете ги способностите за поставување ефективни комуникациски стратегии, користејќи алатки како гејмификација и континуирано испраќање пораки, за да го промените ставот на вработените поврзано со мобилноста.</p>
\t</li>
</ul>

<p><span style="font-size:1.25em;"><strong>Оперативна имплементација и иновации во мобилноста:</strong></span></p>

<ul>
\t<li>Научете како да имплементирате стратегии за мобилност преку назначување и обука на менаџери за мобилност кои можат да водат проекти низ различни оддели.</li>
\t<li>
\t<p>Научете да собирате и анализирате податоци за тековните практики за мобилност и индивидуалните преференции за патување за да ги приспособите решенијата за мобилност.</p>
\t</li>
\t<li>
\t<p>Развијте вештини за иницирање партиципативни пристапи, создавање внатрешни амбасадори и поттикнување на соработка меѓу вработените за промовирање на одржливи методи за патување.</p>
\t</li>
\t<li>
\t<p>Разберете ја важноста од решавањето на практичните аспекти како што се политиките за паркирање автомобили и охрабрувањето на алтернативи како возење велосипед, здружување со автомобил и користење на јавен превоз.</p>
\t</li>
\t<li>
\t<p>Добијте увид во искористувањето на технолошките алатки како што се мултимодалните платформи за патување и имплементацијата на пакети за одржлива мобилност за да се поттикне еколошкиот транспорт.</p>
\t</li>
</ul>




</div>`
        },
        stakeHolders: {
            heading: "Засегнати страни",
            html: `
            <div>
            <p>Директорот/менаџерот за човечки ресурси е само делумно одговорен за прашањата поврзани со новите начини на работа иако е одговорен за управување со човечки ресурси и организација на работа.</p>
            <p>Со цел да придонесе за намалување на емисиите на јаглерод на компанијата, директорот/менаџерот за човечки ресурси треба тесно да соработува со различни засегнати страни, кои се идентификувани како:&nbsp;</p>
            <div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Извршен директор / Топ менаџмент</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Локација на бизнисот</h3>

<p>Деловната локација е дефинирана како место или структура окупирана од компанијата за работа. Кога ќе го погледнеме образложението зад изборот на локација за компаниите, еден критериум не е занемарлив и генерално се појавува во првите пет: Локацијата на вработените и тие кои компанијата планира да ги вработи.&nbsp; Ова е размислување кое треба да се поддржи, но е различно од перспективата за човечки ресурси. Кога вашата организација има планови за преместување, истражете ја одлуката „останете или одете“, и размислете за спојување на повеќе канцеларии или отворете дисперзирани канцеларии, при тоа е важно е да се анализираат поврзаните влијанија и можности за патување. Изберете локација во близина на железничка/метро станица и пристап до друг јавен превоз</p>

<h3>Позитивен пристап</h3>

<p>Една од клучните точки на која треба да се работи е развивање на свест кај врвниот менаџмент и компанијата за тоа што може да се добие со приближување на искуството на вработените од патувањето од дома наутро до дома навечер, и навистина да ја земе мобилноста како составен дел од работната активност. Штом ќе се случи ова, полесно ќе се разбере кои се придобиквките за компанијата да инвестира време на ова, зошто треба да вложи енергија и ресурси во истото и зошто е потребно да преземе одредени мерки за истото. Би било корисно да се оддалечиме од административните ограничувања поврзани со обврската за изготвување на план за мобилност, а наместо тоа да се заземе позитивен пристап за изнаоѓање ефикасни решенија.</p>

<h3>Можности и... ограничувања</h3>

<p>Поддршката на процесот за помалку јаглеродно-интензивна мобилност никогаш не е основна дејност на компанијата - и нема да биде таква. Флексибилноста во однос на ограничувањата што може да се наметнат, не е толку голема. Затоа, првиот чекор е да се утврди нивото на ограничувања што компанијата е подготвена да ги наметне.</p>

<h3>Разговор со вработените</h3>

<p>Доколку се преземат иницијативи во корист на воспоставување јаглеродно поволна мобилност, од суштинско значење е ваквата активност да се користи и да се промовира од стррана на врвниот менаџмент како пример за вработените. Во рамките на плановите, преку нашата студија евидентно е дека лкучот до успех е ако водечката фигура во компанијата го промовира овој план.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Финансии</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Сумата на пари што компаниите се подготвени да ги потрошат за прашања поврзани со &nbsp;мобилноста на вработените на стратешко ниво, обично е мала во споредба со тоа што тие можат да го потрошат за даночна оптимизација или за &nbsp;провајдери на услуги &nbsp;поврзани со човечки ресурси. Трошоците за активностите за подигање на свеста, се оценети помеѓу осум до десет пати помалку. Невозможно е со толку ниски амбиции навистина да се променат работите. За жал, можеме да видиме дека работата во врска со &nbsp;мобилноста сè уште се доживува како ограничување од страна на компаниите, а не како реална можност за промена на нивното влијание врз животната средина. Сепак, повратот на инвестицијата на проектите за мобилност може да биде директен и многу значаен.</p>

<p>Секторот за управување со човечките ресурси може да направи напредок во оваа област преку развивање на деловен случај со конкретни податоци.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Комуникација</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Комуникациска стратегија</h3>

<p>За навистина да се промени однесувањето поврзано со мобилноста, важно е компаниите да воспостават комуникациска стратегија&nbsp; со редовни активности.&nbsp; Во таа насока, мора постојано да се пренесува пораката до вработените. На пример, воведување на игри со награди за победниците&nbsp; е одлична можност за остварување на целта. Создавањето лого и/или брендирање за планот за мобилност на работното место може да помогне да се привлече и задржи вниманието во компанијата</p>

<ul>
\t<li>
\t<p>Во SWIFT во Белгија, беше организиран „добротворен предизвик“ за да се поттикне возењето велосипед за време на неделите на мобилност: за секој поминат километар се донираше 1 евро на здруженија поврзани со велосипедизмот. Овој систем беше многу успешен кај вработените, бидејќи комбинираше надворешна и внатрешна мотивација.</p>
\t</li>
</ul>

<ul>
\t<li>
\t<p>Во Service Public de Wallonie во Белгија, беше организирана „велосипедска тура околу светот“: од учесниците беше побарано да ги внесат своите велосипедски патувања поврзани со работата на онлајн формулар како дел од колективниот предизвик да извозат 40.075 километри заедно во текот на 26 недели. Колективно се” достигнуваа дестинациите, со анегдоти, пофалби и диплома на крајот. Динамиката беше користена за постигнувања преку нивоа и беџови, додека се играше за бегство, непредвидливост и креативност на учесниците.</p>
\t</li>
</ul>

<p><em><strong>Извор: UWE Mobility Unit</strong></em></p>

<h3>Фокусирајте се на вистинската работа</h3>

<p>На мобилноста не треба да се пристапува директно од еколошки агол. Еколошката димензија ќе биде позитивниот резултат, целта кон која треба да се стремиме. Но, тоа не е алатка за комуникација: луѓето се менуваат не поради еколошки причини, туку од причини на практичност, ефикасност, поради заедницата... Накратко, од причини поврзани со човечкото однесување.</p>

<p>Вработените нема да го променат своето однесување затоа што така ќе влијаат врз намалување на &nbsp;јаглеродните емисии на компанијата, туку затоа што ќе имаат нови можности за да бидат повеќе со семејството, да го направат своето патување до работа попријатно... и, на крајот, да имаат позитивно влијание на планетата.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Менаџер за мобилност</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Назначете менаџер за мобилност</h3>

<p>Менаџерот &nbsp;за мобилност е внатрешно лице кое го води проектот за мобилност и, идеално би било да поднесува извештај до генералниот менаџмент, бидејќи раководењето му е трансекторско прек секторите за комуникација, човечки ресурси, капацитети, итн. Лицето може да дојде од кој било сектор - и, зошто да не, од секторот за човечки ресурси - но мора да има директен контакт со највисокото раководство и со претставници на вработените. Ова лице потоа треба да биде обучено во врска со предизвиците на мобилноста до работното место.</p>

<h3>Не е доволен само менаџерот за мобилност..</h3>

<p>Мобилноста е израз на индивидуалната слобода надвор од компанијата и од какви било ограничувања поврзани со работата. На пример, од лична перспектива а во врска со слободата на избор, нормативната улога која што ја има автомобилот останува се уште многу важна.. Затоа, треба да се влијае и образува. Секторот за управување со човечки ресурси, може да влијае на патувањата поврзани со работата (на пример во транспортниот сектор или ако компанијата вработува вработени кои често патуваат), но ќе биде потешко да влијае на патувањето од дома до работа, што делумно ја допира приватната сфера. Тогаш тие се многу повеќе зависни од добрата волја на луѓето или од она што луѓето се подготвени да го направат, свесно или несвесно.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Менаџер со податоци</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>За да станат актери на промени компаниите мора да инвестираат во ресурси - финансиски, човечки и во време - за да спроведат темелно истражување на нивните сегашни практики поврзани со мобилноста и како индивидуалните варијабли (возраст, пол, работна позиција, рутата на патување, итн.) влијаат на нивниот претпочитан начин на транспорт. Собирањето информации преку анкети, интервјуа со вработени, добавувачи и клиенти и други алатки се од клучно значење за создавање соодветен одговор. Компаниите веќе имаат огромно количество податоци и, доколку сакаат, често ги имаат при рака потребните елементи за да напредуваат на едноставен и евтин начин.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Синдикати</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Ако воспоставувате проект за новите начини на работа или хибридна работа по директива одозгора надолу, без да ги вклучите синдикатите, вработените преку преку нивни амбасадори, и без претходно да ги тестирате работите на терен, нема да функционира. Мобилноста е една од најчувствителните теми во компаниите. И штом ќе се направи штета, исклучително е тешко враќањето назад во позитивна динамика. Во таа насока, мора да се биде подготвен да се врати назад штом се направи грешка и да се ревидираат плановите.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Јавни институции</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>При изготвување успешен план за мобилност без јаглеродни емисии, од суштинско значење е да се добие потврда од локалните и официјалните институции. Локалното планирање е од клучно значење. И обично, политиките се главниот мотор за промена: 60% од корпоративните промени во планирањето на мобилноста се мотивирани од желбата да се усогласи компанијата со националните, регионалните или локалните регулативи и барања.</p>

<p>Развојот на работа од далечина го регулира работодавачот, но исто така треба да се интегрира во перспективите на јавните политики. Ако се дозволи работата од далечина да се развива без да се специфицираат целите за истата од аспект на јавната политика, мали се шансите тие да се постигнат..<br>
&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Јавни транспортни превозници</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Ако менаџерите за човечки ресурси и мобилност од различни компании, дури и тие кои доаѓаат од различни сектори, работат заедно за да направат транспортен центар од област од 500 до 800 метри - железничка станица, метро станица итн, тие ке постават динамика, ќе ги поттикнат&nbsp; јавните власти, јавните транспортни превозници и службите за мобилност. Притоа можат да кажат:&nbsp;</p>

<p>„Ние , да речеме, 30 компании идентификувавме таква и таква потреба; што можеме да направиме во врска со тоа“. Ако отиде само една компанија, нема да има влијание. Со 30 компании ситуацијата се менува. Потребна е оваа соработка за да имате влијание.</p>
</div></div></div>
</div>
            `
        },
        culture: {
            heading: "Култура",
            html: `
            <div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1c70fc1f-9e1f-4d2d-bb3d-c7867a33f9e3">
</div><div class="h5p-column-ruler h5p-column-space-after"></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-067d5e0e-e4fb-43be-84fa-825384498127"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-6509dd97d9c6f.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8dc96038-5902-4fae-b1bc-6ff31e4bd15f"><p><strong><span style="font-size:1.5em;">Влијание врз однесувањето</span></strong></p>

<p>Организациите не можат да влијаат на тоа каде вработените избираат да живеат, бидејќи тоа би било очигледно нарушување на нивната приватност. Тешко е да се балансира&nbsp; должината на патувањето до работа, дури и кога се земаат предвид параметрите како поевтино домување или подобар квалитет на живот. Работете со вработени кои ги поддржуваат целите за да ги сензибилизирате колегите.&nbsp;</p>

<p><strong>Пример:</strong></p>

<p>Wever е платформа создадена во Франција која предлага повторно да се измисли инклузивна и ефикасна мобилност со ставање на корисникот и неговите или нејзините изразени потреби во рамките на подготовката на понудата. На сите им нуди можност да ги наведат своите потреби, да тестираат нови решенија и да гласаат за спроведување на најинтересните идеи на долг рок .</p>
</div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9772c0de-3792-4fe2-a5f6-899984bb576a"><p><strong>Како работи?</strong></p>

<ol>
\t<li>Дијагностика за мобилност се користи за собирање на навиките за патување и потребите на вработените. Потребни се само неколку минути за да се соберат индивидуалните потреби и за секој од нив се направи извештај.&nbsp;</li>
\t<li>
\t<p>Контролната табла овозможува континуирано следење на извештаите и овозможува преку коментари да се видат препораките за дејствување. Оваа визуелна алатка овозможува понудата да биде ко-конструирана со корисниците.&nbsp;</p>
\t</li>
\t<li>
\t<p>Алгоритмот ги открива можностите за мобилност преку откривање на постоечките решенија и моделирање на нови прифатливи решенија.&nbsp;</p>
\t</li>
\t<li>
\t<p>Со индивидуализирана поддршка за промени и поставување на добра логика на награди, трансформациите се видливи за неколку недели.</p>
\t</li>
</ol>

<p>Повеќе информации:&nbsp;<a target="_blank" href="http://www.wever.fr/" target="_blank">www.wever.fr</a></p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-065d48e1-ed65-4c87-803a-8063f0d2bb9b"><p><strong><span style="font-size:1.5em;">Направете нешто на конкретен начин</span></strong></p>

<p>Не правете кампањи за мобилност поврзана со работата со&nbsp; поставување на постери во лифтот, бидејќи со тоа не менувате никакво однесување. Одете во кафетеријата три пати неделно за да разговарате со луѓето, заинтересирајте се транспортот кој го користат кои се нивните предизвици, и предложете нашини како да се надминат. Накратко, персонализирајте го вашиот пристап за секоја личност. За ова е потребно време и енергија.</p>

<p>Друг пример: Создадете група за велосипедски тури &nbsp;и одржувајте велосипедски настани. Побарајте од колеги во групата да направат презентација и да зборуваат за темата на други компаниски настани или со своите колеги.<br>
&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6d757e35-654c-416e-a38f-5fbf7e44a845"><p><strong><span style="font-size:1.5em;">Обрнете внимание на паркирањето</span></strong></p>

<p>Паркирањето на автомобили е централно прашање за компанијата: компаниите развија мерки кои се движат од стимулации (финансиска награда за тоа што не се носи автомобил на работа), до казни (воведување на трошоци за паркирање на работа или максимален број денови/недела или месец за користење на службениот паркинг).</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8cb77e76-e0c3-439c-9f90-d582dd82222c"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650a0615ebf86.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42f4fadb-eb58-4f8a-a545-94bbab8675ab"><p><span style="font-size:1.5em;"><strong>Иницирајте партиципативен пристап</strong></span></p>

<p>Создадете кластер за мобилност кој вклучува доброволци од вработените за да собираат идеи, предлози и да одберат внатрешни амбасадори. Некои организации ја делегираа улогата на управување со план за мобилност/патување на линиските менаџери, за да создадат сплотеност помеѓу вработените.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-a57aaa91-50f0-4dce-b73b-23410fda6402"><p><strong><span style="font-size:1.5em;">Промовирајте размислување надвор од рамката</span></strong></p>

<p>Охрабрете ги луѓето да размислуваат за причината за патување пред да го изберат начинот на патување</p>
</div></div>`
        },
        hr: {
            heading: "Политики за HR",
            html: `<div>
<div class="h5p-image-hotspot-popup-content overflowing has-scrolled" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Работна организација - Формализирање на договори за работа на далечина</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>До пред три или четири години, работата од далечина беше главно неформална. Денес, по здравствената криза работата од далечина станува се повеќе и повеќе формализирана заради нејзиното широко усвојување во големите организации. Ова формализирање може да има форма на политика на компанијата и/или дискусии помеѓу работодавачот/менаџерите и синдикатите/вработените за насоките со кои работодавачот сака да ја постави работата од далечина. Соочени со заситеноста на јавниот транспорт и патните мрежи, предизвик е да се развие работата од далечина како начин за регулирање на овие текови поврзани со мобилноста.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content has-scrolled"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>НАГРАДИ - Пакет за одржлива мобилност</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>На ист начин како што јавниот превоз е покриен за вработените, таква шема &nbsp;со компензација &nbsp;може да се направи за оние кои на пример доаѓаат на работа користејќи „етички “ начини како што се возење велосипед или здружување за користење на еден автомобил. Што се однесува до службените автомобили: препорака е да се забранат дизел автомобилите,&nbsp; а да се направи замена на бензинските возила со возила со нула емисија на штетни гасови.</p>

<p><strong>Во Франција,</strong> работодавачите можат да дадат пакет за оддржлива мобилност на своите вработени кои користат одржливо транспортно средство за нивните службени патувања. Опфатени се следните транспортни средства: велосипед (вклучувајќи електричен велосипед), здружување со автомобил како патник и возач, заеднички услуги за мобилност (споделување на електрични или хибридни возила, изнајмување на скутери или велосипеди), јавен превоз (со исклучок на сезонски билети). Максималниот износ на пакетот за одржлива мобилност е 700 евра годишно по вработен. Платениот износ е ослободен од придонеси за социјално осигурување и данок на доход.</p>

<p><strong>Во Белгија,</strong> додатокот за велосипед е надоместок за километража што го доделува работодавачот на вработените кои користат велосипед за да го поминат целото или дел од растојанието помеѓу нивниот дом и работното место. Додатокот е наменет за покривање на трошоците на велосипедистот, но пред се е наменет да поттикне повеќе вработени да ги користат своите велосипеди. Надоместокот за километар е ослободен од данок до 0,25 евра по поминат км за финансиската 2023-та година, согласно приходот од &nbsp;2022-ра година за плата. Доколку работодавачот додели поголема километража, овој вишок се оданочува како личен доход.</p>

<p><strong>Проверете што постои како опција во вашата земја во оваа насока!</strong></p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content overflowing has-scrolled" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>РАБОТНО ВРЕМЕ - Охрабрете Soft Mobility Travel (мека мобилност)</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Работодавачот може да го кредитира времето на мобилност како работно време, но само во случај на патување со мека мобилност - т.е. кој било начин на транспорт што не користи мотор со внатрешно согорување, ниту испушта стакленички гасови. Ова има дополнителна предност што му дава можност на лицето да патува надвор од времето на транспортните тесни грла.&nbsp;&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content overflowing has-scrolled" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>РЕГРУТИРАЊЕ - Регрутирајте локално</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>При регрутирањето може да го користите растојанието помеѓу домот и канцеларијата како параметар при изборот на кандидатот. Но, ќе биде потешко ова да се примени кога пазарот на труд е мал или кога потребните профили ретко се наоѓаат. Искуството со кое се стекнавме преку работењето од&nbsp; далечина, наметнато од Ковид-19, покажа дека локалното регрутирање продолжува да има смисла само по себе, но е потешко за практикување.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content overflowing has-scrolled" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>ДОБРОСОСТОЈБА - Мобилноста е важна</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Мобилноста е еден од столбовите за благосостојбата на работа. Пристапите можат да бидат разновидни. На пример, со промовирањето на возењето на велосипедот, може да се придонесе за подобрување на физичката кондиција, стимулирање на концентрацијата на работа, па дури и поттикнување на активност во заедницата.</p>
</div></div></div>
</div>`
        },
        tools: {
            heading: "Алатки",
            html: `
            <div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-ad634c59-8c77-49de-b651-13e5f6d885f5">
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4fba2945-7e82-4e52-8e74-afa0bdfe1f8b"><p><strong><span style="font-size:1.5em;">Спроведете анализа на мобилноста</span></strong></p>

<p>Спроведете проценка на јаглеродните емисии на вашата компанија: назначете експерт за да ги идентификува и фокусира темите со најголемо влијание на истата (мобилност, патување со авион, канцеларии и други објекти) итн. Потоа спроведете скенирање за мобилноста врз основа на точни и тековни податоци.&nbsp;</p>

<p><strong>Примери:</strong></p>

<p>Mobility Label, алатки за попаметно патување</p>

<p><a target="_blank" href="https://mobilitylabel.com/">https://mobilitylabel.com</a>&nbsp;</p>

<p><strong>Mob Box</strong> собира и составува масовни необработени податоци. Од внатрешни податоци за корпоративната флота на автомобили до надворешни податоци со отворен код, тие ги претвораат бројките во директни карти и интуитивни графикони. Тоа ќе ви помогне да добиете јасен поглед на вашите вработени и мобилноста. Проценете ја севкупната транспортна мешавина и анализирајте ги јаглеродните емисии и поврзаните трошоци.</p>

<p><a target="_blank" href="http://www.mob-box.eu/" target="_blank">www.mob-box.eu</a>&nbsp;</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-08185ad9-d286-407c-8269-b3bcecba9cc2"><p><strong><span style="font-size:1.5em;">Истражете ги достапните јавни решенија&nbsp;</span></strong></p>

<ul>
\t<li>
\t<p><strong>Во Франција:</strong>&nbsp;<a target="_blank" href="https://www.ecologie.gouv.fr/sites/default/files/mobilite%20durable%20en%20entreprise.pdf" target="_blank">Forfait Mobilité Durable</a> (Пакет за одржлива мобилност) - секоја компанија може да ги субвенционира или финансира патувањата на своите вработени од дома до работа до 800 евра годишно, со ослободување од данок и придонеси за социјално осигурување, се додека тие користат &nbsp;зелени транспортни средства - јавен превоз, електрични скутери, заеднички електрични автомобили, дружење со автомобил…&nbsp;&nbsp;</p>
\t</li>
\t<li>
\t<p><strong>Во Белгија: </strong>Budget Mobilité (Буџет за мобилност) им овозможува на компаниите да им понудат буџет за поеколошки опции за мобилност&nbsp;на вработените кои користат службено возило —&nbsp;<a target="_blank" href="https://lebudgetmobilite.be/fr" target="_blank">https://lebudgetmobilite.be/fr</a>&nbsp;</p>
\t</li>
</ul>
</div><div class="h5p-column-ruler h5p-column-space-after"></div><div class="h5p-column-content not-an-ios-device h5p-image-hotspots" id="h5p-interactive-book-section-076e63a2-1daa-4256-bf2d-59c590c6cbe8"><div class="h5p-image-hotspots-container" style="width:100%; font-size: 24px;"><img class="h5p-image-hotspots-background" src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/image-650a166eaf053.jpg" aria-hidden="true" style="width: 100%;"><button class="h5p-image-hotspot h5p-image-hotspot-info" role="button" tabindex="0" aria-haspopup="true" title="Hotspot 1" aria-label="Hotspot 1" style="top: 35.9893%; left: 25.2%; color: rgb(0, 88, 145);"></button><button class="h5p-image-hotspot h5p-image-hotspot-info" role="button" tabindex="0" aria-haspopup="true" title="Hotspot 2" aria-label="Hotspot 2" style="top: 84.2714%; left: 73.6%; color: rgb(0, 88, 145);"></button></div></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-eb98b054-5f2c-454c-9a29-05efff3cce27"><p><strong><span style="font-size:1.5em;">Индивидуална анализа / Анкети за мобилност</span></strong></p>

<p>Преку индивидуализиран маркетинг пристап разгледајте како луѓето патуваат до работното место и понудете им алтернативи. Постои цел процес на дијагностицирање и поддршка за да на вработените им се укаже дека автомобилот не е нужно најефикасниот начин за движење. Подлабока анализа (следејќи ги луѓето неколку дена) може да им покаже дека има и други начини на вршење на работите, помалку моторизирани, поекономични итн.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d7ebb14-1b14-4a0b-a034-151adcc5f3bb"><p><strong><span style="font-size:1.5em;">Платформи за мултимодални транспортни рути</span></strong></p>

<p>Платформите за мултимодални рути можат да ги покажат различните опции и да им овозможат на луѓето да сфатат дека патувањето со автомобил ќе биде помалку ефикасно во споредба со другите начини на транспорт. Некои од овие алатки ги прикажуваат јаглеродните емисии кои произлегуваат од различни начини на патување.</p>

<p><strong>Пример:</strong></p>

<p>Skipr, “се-во-едно” е решение за управување со корпоративна мобилност&nbsp;</p>

<p><a target="_blank" href="http://www.skipr.co/">www.skipr.co</a></p>

<p>Skipr се бори против користењето на автомобил за еден корисник во градот. На вработените им нуди апликација која им овозможува да видат како да стигнат од А до Б со комбинирање на различните превозни средства што постојат околу нив: јавен транспорт, заеднички возила, електрични скутери, зелено возило итн. Постои ко-пилот кој поддржува во опцијата да не се користи автомобилот. Овозможува купување билети за јавен превоз на сметката на компанијата, во комбинација со платежна картичка, поврзана со истиот буџет во #мобилност, за алтернативни видови на мобилност.</p>

<p>За компанијата, платформата овозможува управување кое прави две работи: ги поставува насоките на компанијата (кој е предодреден за што ) и обезбедува алатка за известување што дава целосна видливост за генерираните заштеди и намалување на јаглеродните емисии во споредба со користењето на традиционален автомобил. Вработените имаат контролна табла која ја следи нивната личмна ситуација додека компанијата добива збирни извештаи. Кога пребарувате по опциите за патување, вработените добиваат три информации: времето на пристигнување, трошоците во евра и јаглеродните емисии.<br>
&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4bf3cd48-c7e8-4a5f-a5a3-4727c4478547"><p><strong><span style="font-size:1.5em;">Поттикнете здружување за заедничко патување со колегите</span></strong></p>

<p>Предуслови за успех: флексибилноста на работното време е една од првите преизвици за здружување меѓу колегите. Немањето исти работни часови е ограничувачки фактор, особено кога се додаваат личните ограничувања - собирање на децата, извршување на обврски итн. &nbsp;Може да помогне да имате проект менаџер, кој со табела и молив ги евидентира патувањата и ги совпаѓа. Ако им оставите на луѓето да го средат тоа меѓу себе, ќе биде сложено. Ако го оставите на некоја апликација, и тоа е комплицирано. Дигиталните алатки работат многу добро за патувања на долги растојанија, но не се толку ефикасни за кратки патувања, при повторливо и секојдневно патување. Сепак, постојат платформи кои го овозможуваат здружувањето со автомобили со опции за совпаѓање, информации за фискалните придобивки, можности за следење за ориентирање &nbsp;во насока на комуникацијата. Важно е да се напомене дека здружувањето со автомобил не функционира добро кога работното место е во близина и има понуда за јавен превоз.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e97d6b7b-85ca-4b8e-adc4-c549a30315f2"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650ae2ffdc5cc.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f2705860-d508-4b16-9089-9bba70468e72"><p><strong><span style="font-size:1.5em;">Шатл превоз</span></strong></p>

<p>Неколку организации кои постигнаа најголем раст во користењето на јавниот превоз обезбедија бесплатен, соодветен шатл-автобус на компанијата, поврзувајќи се со клучните локални градови и/или локалните железнички и автобуски станици. Преку партнерство вклучени беа организации кои го зголемуваат бројот на автобуски услуги што пристигнуваат на локацијата, се обезбедија во просек околу 14 дополнителни автобуси во шпицовите кои беа евидентирани преку истражување. Да се има соодветни и приспособени услуги делува дека е поважно од апсолутните бројки: поевтини билети и подобрување на инфраструктурата/квалитетот на услугата надвор од локацијата и со финансирање на нови автобуски постојки до компанијата или во близина, на пример, е бенефит за вработените.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c293a877-1d7a-44a0-8b05-524e78f4b318"><p><strong><span style="font-size:1.5em;">Возење велосипед </span></strong></p>

<p>Се чини дека факторите кои се најтесно поврзани со зачестеното возење велосипед се однесуваат на &nbsp;висококвалитетниот пристап до локацијата или подобрувањето на пристапот од локацијата; зголемување на достапниот паркинг простор за велосипедистите и нудење услуга за поправка на велосипедот. Исто така, забележливо беше дека повеќето од организациите/компаниите во студијата обезбедија тушеви, пресоблекување, сушење и/или шкафчиња; како и договорени попусти за опрема за велосипедистите.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-457e418e-7a81-4932-82ba-2fcbf1e21fcd"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650a17655699c.jpg" alt="" title="Cycling" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-801a1d35-3531-4738-807c-79f59dd9e601"><p><span style="font-size:1.5em;"><strong>Електрични автомобили? Да, но…</strong></span></p>

<p>Електричниот автомобил не е решение за сообраќајниот метеж. Автомобилот е сепак автомобил, без разлика дали е електричен, водороден, бензинец или дизел, сепак зафаќа простор на патот. Не испушта „нула CO2“, испушта помалку: се зависи од тоа како е произведена електричната енергија. Исто така претставува предизвик и тешкотија во врска со мобилноста: не постои такво нешто како “една големина да одговара на сите”. Секој си има своја специфичност. Кај електричните автомобили, има и проблеми поврзани со трошоците и полнењето што треба да се земат предвид. Сè уште има пречки. Работите брзо се менуваат, но соодветно решение од волшебно стапче, нема.</p>

<p>Во таа насока, треба да се инсталираат инфраструктури за полнење на електрични автомобили: тие се неопходни за да се зголеми употребата на електрични автомобили.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-07fc7d73-8673-4f28-8e07-2c87d26235f0"><p><strong><span style="font-size:1.5em;">Лизинг на велосипеди (за електрични велосипеди)</span></strong></p>

<p><strong>Пример:</strong></p>

<p>Како работодавач во Белгија, можете да изберете да понудите лизинг за велосипеди на различни начини: бесплатно или со (делумен) придонес за велосипедот. Во сите ситуации, вработените уживаат даночна предност од околу 40%. На изнајмен велосипед не плаќате никаков надоместок, бидејќи велосипедот го користите редовно за патување. Може да исплатите и додаток за велосипедот. Ова ги мотивира вашите вработени да возат велосипед. Прописите за лизинг на велосипеди не прават разлика помеѓу изнајмен и приватен велосипед. Кои се резултатите? Вработени се физички активни: има 1-2 дена помалку отсуства и голем придонес во планот за зелена мобилност</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6c224ae6-6652-45b9-a674-13597a5bb424"><p><strong><span style="font-size:1.5em;">Обука за еко-возење</span></strong></p>

<p><strong>Пример:</strong></p>

<p>Да се биде еколошки одговорен кога сте на пат бара повеќе од поседување еколошко возило. Треба да имате зелен стил на возење. И ова е нешто што возачите го учат во програмата за обука Е-позитивни - едногодишен курс за обука од ALD Automotive.&nbsp;</p>

<p><strong>Како функционира тоа:</strong></p>

<p>„Се започнува со тоа што можат да направат вашите возачите; тие го опишуваат нивниот сегашен стил на возење на картичка. После тоа, следат е-обука, индивидуално и групно. Инструкторот им дава постојани совети и насоки на кои треба да обрнат внимание. Резултатот? На крајот од обуката сите возат со е-позитивен став.&nbsp;</p>

<p><strong>10 совети за еколошко возење</strong></p>

<ol>
\t<li>
\t<p>Префрлете се на поголема брзина во соодветно време. Со дизел мотор, некаде од 2.000 вртежи и со бензинец, 2.500 вртежи.</p>
\t</li>
\t<li>
\t<p>Време на движење? Притиснете го педалот за гас целосно, бидејќи бавното забрзување сигурно нема да ви заштеди гориво.</p>
\t</li>
\t<li>
\t<p>Одржувајте доволно растојание од возилото пред вас. Тогаш нема да треба постојано да сопирате и да забрзувате.</p>
\t</li>
\t<li>
\t<p>Дали треба да забавите или да престанете? Не одете директно на сопирачката, туку отпуштете го педалот за гас навреме. Ќе успорите со моторно сопирање и без возење на спојката.</p>
\t</li>
\t<li>
\t<p>Вклучете ја контролата на патувањето заради постојаност – а со тоа и еколошка чистота.</p>
\t</li>
\t<li>
\t<p>Дали ќе застанете повеќе од 20 секунди? Тогаш исклучете го моторот.</p>
\t</li>
\t<li>
\t<p>Планирајте ја вашата рута однапред за да не набиете непотребна километража.</p>
\t</li>
\t<li>
\t<p>Не ви е потребна покривна кутија за велосипед или багажник? Тргнете го и намалете го отпорот на воздухот.</p>
\t</li>
\t<li>
\t<p>Редовно проверувајте го притисокот во гумите.</p>
\t</li>
\t<li>
\t<p>Сервисирајте го вашето возило навреме: само така ќе можете да возите економично и безбедно.</p>
\t</li>
</ol>

<p><em><strong>Извор: ALD Automotive</strong></em></p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-0c1c9678-0a6c-445a-95ce-4a25e6395d09"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650a17e559c50.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3e08a77a-372e-478d-a71b-1e82dbff5942"><p><strong><span style="font-size:1.5em;">MaestroMobile </span></strong></p>

<p>Maestro Mobile е сериозна игра развиена од Espaces-Mobilités во Белгија, во областа на мобилноста која има за цел да го промени однесувањето преку експериментирање.</p>

<p>Повеќе информации:&nbsp;<a target="_blank" href="https://www.maestromobile.eu/">https://www.maestromobile.eu</a>&nbsp;</p>

<ul>
\t<li>“Mobility Van” е тим составен од експерти за мобилност кои патуваат на јавни простори и компании и на вработените им ги покажуваат сите решенија за мобилност на практичен начин (преку тест со машини за микромобилност, велосипеди, апликации…)</li>
\t<li>
\t<p>Претставува градска, урбана, сериозна, игра која е организирана во форма на тимбилдинг и им овозможува на учесниците да тестираат различни решенија за мобилност на практичен начин додека (повторно) го откриваат градот, завршуваат предизвици, одговараат квизови итн. Со користење на шифрите од популарните игри како што се “Pokemon Go” или принципите на урбаните трки, “City Game” создаде свој универзум поврзан со откривањето на услуги за мобилност.</p>
\t</li>
\t<li>
\t<p>На ист начин како и програмите за фитнес, “Mobility Passport” нуди предизвик за мобилност што ги води учесниците низ различните фази на учење за мобилноста, од запознавање на услугатa до користење, во период од околу еден месец. Преку мобилната апликација, учесникот добива валидација на своите вештини и му се доделуваат сертификати кои имаат форма со печат за неговата мобилност.</p>
\t</li>
\t<li>
\t<p>“GO Mobility” е хибридна платформа која нуди комплетна програма за коучинг и откривање на алтернативи за мобилност.</p>
\t</li>
\t<li>
\t<p>“Critical MaaS” е персонализиран тренинг за да експериментирате со нови мобилности со цел &nbsp;да го промените вашиот начин на движење. Во придружба на експерти, ги тестирате сите нови услуги и станувате господар на вашата мобилност.</p>
\t</li>
</ul>

</div></div>
            `
        },
        inspiringInitiatives: {
            heading: "Инспиративни иницијативи",
            html: `
            <div>
            <div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Отстранете ги местата за паркирање</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>За да се обесхрабри употребата на автомобили за да се оди на работа, некои компании ги отстранија резервираните паркинг места во компаниите. Ако на сите вработени им е речиси загарантиран просторот за паркирање, тие ќе го користат автомобилот многу повеќе за да патуваат до работа, отколку ако не го сторите тоа.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Олеснете ја заедничката мобилност</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Една бразилска енергетска компанија смислила апликација за да ја олесни споделената мобилност до нејзината локација во Сао Паоло: корисниците би ја внеле нивната рута за патување, а апликацијата би ги вкрстила податоците со сите други влезови и ги воведувала вработените кои следат слични рути. Заедничката мобилност се зголеми за 18% од транспортот и заштедила на компанијата 300.000 УСД годишно.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Охрабрете го  возењето  на велосипед</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Поиновативните добри практики меѓу студиите на случај, вклучуваат обезбедување на подобра безбедност за велосипедите (индивидуално заклучувачки паркинг простори; полициско обезбедување или понуди за осигурување за велосипеди); заеми за велосипедска опрема (батерии, светла, пумпи, брави, шлемови, детски седишта итн.) мапи за специфични локации; финансиски стимулации и бесплатни производи (на пример, понуда на заштитни јакни).&nbsp;</p>

<p>Неколку примери за позитивни активности: партнерство за изнајмување на товарни велосипеди за испорака или нивна употреба од страна на технички работници, кампања за позајмување електрични велосипеди за да им се овозможи на вработените да ја &nbsp;тестираат, апликацијата&nbsp;за мерење на јаглеродна неутралност прекуакцијата „со велосипед до работа“ и учество во различни други предизвици.</p>


</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Децентрализирани заеднички канцеларии</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Пилот-проект за децентрализиран заеднички канцелариски простор со цел да им се овозможи на оние кои не можат да работат од далечина, во некои денови да не мора да доаѓаат до централата, ако живеат далеку или ако им е премногу комплицирано да до доагаат до најблиската канцеларија. Спроведено е со оператор за ко-соработка, преку централизиран систем за функционирање.</p>

<p><strong>Направете го возот поконкурентено транспортно средство</strong></p>

<p>Пример за организациски политики за деловно патување:</p>

<ul>
\t<li>
\t<p>Распределете средства за компензација на пониска цена за авионот, со цел возот да стане конкурентен и користен</p>
\t</li>
\t<li>
\t<p>Не е дозволено да се користи авион за патувања помали од 2 часа каде може да се патува со воз.<br>
\t&nbsp;</p>
\t</li>
</ul>
</div></div></div>         
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-bd0d1aab-e9f3-4954-82d7-5ca803053822"><p><strong><span style="font-size:1.5em;"><h2>Пристап &nbsp;“плаќaш колку што трошиш”</h2></span></strong></p>

<p>Пристап „Плаќаш колку што трошиш“ за службените автомобили: луѓето го контролираат тоа што го трошат преку добивање на надомест по километар за тоа не е потрошено или плаќаат надомест за премногу потрошеното кое влијае и на цената на лизингот и на буџетот.</p>


</div>
</div>`
        },
        recommendation: {
            heading: "Препораки",
            html: `
            <div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4052f6b2-2d6c-437b-bd67-4e316610281b">
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f3a1154d-cf21-4744-8f37-5b942ddae908"><p><span style="font-size:1.5em;"><strong>Воспоставување на мерење... односно подобро е да напредуваш со несовршени мерки...</strong></span></p>

<p>Бидејќи чекањето за совршенство е најдобриот начин да не правиш ништо…</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9c17bf2a-baba-43d2-a449-63d3a162ff6a"><p><span style="font-size:1.5em;"><strong>Изберете локација на компанијата достапна до одржливи транспортни средства</strong></span></p>

<p>Компаниите немаат капацитет да регрутираат само локално, ниту да наметнуваат локации за живеење на своите вработени, освен преку обезбедување на сместување како дел од договорот за вработување. Тие не можат да влијаат на растојанието од дома до работа, но можат да изберат локација која е достапна за нивните вработени со користење на поодржливи средства за превоз од автомобилот.&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-32b491be-187b-4b28-b25e-c93cdd367c4c"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650ae7da4ccaf.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42a6405c-6e2a-4f70-b7d8-501f3b5e3f66"><p><strong><span style="font-size:1.5em;">Планирајте, работете, активирајте се</span></strong></p>

<p>Првиот столб е дијагностицирање и дефинирање на стратегијата. Потоа доаѓа имплементацијата со услуги за мобилност, управување со податоци, финансиски мониторинг, инфраструктури итн. Повеќето компании застануваат тука и потоа гледаат дека тоа не функционира.</p>

<p>Зошто? Едноставно затоа што недостасува димензијата за промена на однесувањето и мотивирање.</p>

<p>Сето горенаведено е неопходно, но не е доволно. Мора да се „активирате“ зада имате влијание врз вработените. Кога некоја компанија ќе ви каже дека поставила десет електрични велосипеди во приемниот дел, но никој не ги користи, недостасува овој чекор: активирање. Не сте ги мотивирале луѓето. Нивното мотивирање може да се направи на различни начини, како организирање на игра со награда за оние кои најмногу ги користат велосипедите.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-7565574d-621a-4f00-b920-c201f9d2f507"><p><strong><span style="font-size:1.5em;">Стекнете разбирање за долгото патување или патувањето на долги растојанија</span></strong></p>

<p>Случаите со двојни резиденции поврзани со работа - или со второ живеалиште, или во хотел, или дури и користење &nbsp;на AirBnB... двојни резиденции поврзани на &nbsp;луѓето со две работни места во два различни града; многу чести патувања поврзани со работа, итн, заслужуваат &nbsp;да се земат во предвид, од гледна точка на човечки ресурси, а уште повеќе кога има преселба на ново работно место во однос на влијанието врз патувањето до работа и воспоставување на неопходна поддршка за истото.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-80c0adf5-cab3-4fbb-98bd-c46a956d2977"><p><strong><span style="font-size:1.5em;">Не се фокусирајте само на буџетскиот аспект</span></strong></p>

<p>Логиката позади изборот на транспортното средство вклучува и брзина (ако се потребни 1,5 часа со јавен превоз, наспроти 40 минути со автомобил, парите нема да имаат големо влијание). И многу други критериуми се вклучени како удобност т.е. целото искуство на патувањето: возот има компаративна предност ако во него можете да правите други активности - спиење, читање, работа итн. Но, таа предност на пример, се поништува ако возот е преполн и немате место, или се поништува од искуството што го имате за различните начини на транспорт, навики, безбедност итн. Размислете за политика за мобилност која ги интегрира сите димензии (трошокот, брзината, удобноста и сл.) така што алтернативите да станат привлечни.</p>

<p>Префрлете се од буџет за мобилност од 500 евра, на буџет за мобилност од по x килограми CO2 и соодветно распределете го буџетот за мобилност на вработените, дадете можност да се потикне и тргување помеѓу колегите како на пример размена на заштедите за слободни денови.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6594bb16-8d4a-40a3-baae-b2c961991ac6"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650ae87fc0ae4.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-db56f5ca-9960-46ca-b07c-3aa08b24a38f"><p><strong><span style="font-size:1.5em;">Поттикнете мултимодалност</span></strong></p>

<p>Промовирајте промена, така што луѓето ќе ја минимизираат улогата на автомобилот во нивните патувања. Луѓето кои живеат во ретко населени, периферни-урбани области, можеби ќе можат да ја намалат употребата на автомобилот ако користат и други начини на транспорт покрај автомобилот.</p>

<p>Потребите ќе наложат префрлување кон зголемен, поширок, а не на намален автомобил: или доколку имате службено возило, евентуално помало, но да биде со подобри еколошки перформанси. Да им се даде на луѓето можност да патуваат поинаку. Ова не секогаш бара зголемување на понудата, туку само договор за олеснување на пристапот до јавниот превоз или други форми на мобилност.</p>

<p>Денес се нудат многу мултимодални решенија.<br>
&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-2b595af0-7cfc-41da-b1a7-f849be29cfe8"><p><strong><span style="font-size:1.5em;">Автомобили од компанијата: обрнете внимание на…</span></strong></p>

<p>Ако компанијата чувствува обврска да обезбеди службени автомобили (за привлекување таленти, задржување вработени, статус, застапеност), обрнете внимание на типот на возилото, дури и ако знаеме дека влијанието на електричното возило врз животната средина не е неутрално.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e2b6406f-ba7a-45b7-83b8-84806274d67f"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650ae8f013a9d.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5821544b-625c-4c3a-a64e-9ddf0f3eeb2f"><p><strong><span style="font-size:1.5em;">Справете се со паркинг местата</span></strong></p>

<p>Ако компанијата нуди паркинг простор, таа на некој начин им плаќа на вработените да го користат својот автомобил. Она што ни треба е едноставно да ги избалансираме предностите на различни видови транспорт: ако патувате со велосипед, не заземајте го местото за паркирање. Во таа насока ако заземете помалку простор, компанијата би можела да ви додели одредена сума - на пример 200 евра - затоа што заштедувате пари на компанијата доаѓајќи со велосипед.</p>

<p>Посочете кон резервираните паркинг места за менаџерите, кои генерално се секогаш подобро лоцирани. Оваа хиерархиска предност не ја одразува амбицијата на компанијата за мобилност и ќе биде помалку интензивна кон намалувањето на јаглеродните емисии. Ако има паркинг, просторите најблиску до влезот на канцеларијата треба да бидат резервирани за тие кои споделуваат автомобил за да дојдат на работа.&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d56820b5-cdac-4d65-b9c3-e7e19d058c6a"><p><strong><span style="font-size:1.5em;">Најдете „внатрешни амбасадори“</span></strong></p>

<p>Работете со пилот проекти, со мали групи амбасадори подготвени да ја играат оваа улога. Не ја револуционизирајте мобилноста во вашата компанија; започнете со луѓе кои се подготвени, и со мали иницијативи кои ќе никнат и ќе растат. Мобилизирај го врвниот менаџмент: кога извршниот директор и врвните менаџери се качуваат на својот велосипед и го прават тоа редовно, тоа има голем влијание врз останатите во организацијата.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-96546409-df37-40a1-8000-e20cc49697d3"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650ae968de311.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3c1ff5d8-04bb-4246-b817-45823add9089"><p><strong><span style="font-size:1.5em;">Клучен збор: соработка</span></strong></p>

<p>Пристапот од &nbsp;Kiba-Janiak и Witkowski укажува дека клучен показател за успешен план за мобилност е соработката со мрежа на засегнати страни, кои може да бидат јавни или приватни, и ја најавува како особено својствена на намалувањето на емисиите на стакленички гасови.</p>




</div></div>`,
        }
    },
    fr:{
        learningObjectives: {
            heading: "Objectifs d'apprentissage",
            html: `
            <div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c8722f2c-f07d-4948-a74e-5ae3323e9358">

<p>L'objectif global est de permettre aux professionnels des ressources humaines de mettre en œuvre des solutions de mobilité pratiques et innovantes qui contribuent à réduire les émissions de carbone de l'entreprise et à améliorer la durabilité globale.</p>



<p><span style="font-size:1.25em;"><strong>Engagement des parties prenantes et gestion stratégique de la mobilité :</strong></span></p>

<ul>
\t<li>Développer des compétences pour interagir efficacement avec un large éventail de parties prenantes, notamment les PDG, la haute direction, les services financiers et les autorités publiques, pour créer et mettre en œuvre des plans de mobilité stratégiques. Cela implique de comprendre l'importance des décisions d'implantation des entreprises par rapport au bassin d'emploi et à l'accessibilité des transports publics, ainsi que leur impact sur l'empreinte carbone de l'entreprise.</li>
\t<li>
\t<p>Acquérir des compétences pour diriger et influencer la haute direction afin qu'elle adopte une approche positive en matière de mobilité professionnelle, transcendant la simple conformité pour intégrer activement la mobilité dans le cadre de l'expérience de travail.</p>
\t</li>
\t<li>
\t<p>Apprenez à construire et à présenter des analyses de rentabilisation convaincantes aux services financiers, démontrant le retour sur investissement direct et significatif des projets de mobilité durable.</p>
\t</li>
\t<li>
\t<p>Améliorez les capacités à mettre en place des stratégies de communication efficaces, en utilisant des outils tels que la gamification et la messagerie continue, pour modifier le comportement des employés lié à la mobilité.</p>
\t</li>
</ul>



<p><span style="font-size:1.25em;"><strong>Mise en œuvre opérationnelle et innovation en mobilité :</strong></span></p>

<ul>
\t<li>Apprenez à mettre en œuvre des stratégies de mobilité grâce à la nomination et à la formation de responsables de la mobilité capables de diriger des projets dans différents départements.</li>
\t<li>Apprenez à rassembler et à analyser des données sur les pratiques de mobilité actuelles et les préférences individuelles en matière de déplacements domicile-travail pour adapter les solutions de mobilité.</li>
\t<li>Développer des compétences pour initier des démarches participatives, créer des ambassadeurs internes et favoriser la collaboration entre les collaborateurs pour promouvoir des modes de déplacement durables.</li>
\t<li>Comprendre l'importance d'aborder les aspects pratiques tels que les politiques de stationnement et d'encourager des alternatives comme le vélo, le covoiturage et l'utilisation des transports publics.</li>
\t<li>Obtenez des informations sur l'exploitation d'outils technologiques tels que les plateformes d'itinéraires multimodaux et la mise en œuvre de forfaits de mobilité durable pour encourager les déplacements domicile-travail respectueux de l'environnement.</li>
</ul>


</div>`
        },
        stakeHolders: {
            heading: "Les parties prenantes",
            html: `<div>
<p>Le directeur/responsable des ressources humaines n'est que partiellement responsable des questions relatives à la mobilité vers et depuis le travail.</p>
<p>Afin de contribuer à la réduction des émissions de carbone de l'entreprise, le Directeur/Responsable des Ressources Humaines doit travailler en étroite collaboration avec différentes parties prenantes, dont les principales ont été identifiées par les partenaires du projet comme :</p>
<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>PDG/Direction générale</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Site de l'entreprise&nbsp;</h3>

<p dir="ltr">L'emplacement de l'entreprise est défini comme un lieu ou une structure occupée par une entreprise pour mener ses activités. Lorsque l'on s'intéresse aux raisons qui motivent le choix de l'implantation des entreprises, un critère n'est pas négligeable et apparaît généralement dans le top 5 des critères : le bassin d'emploi dans lequel l'entreprise va se positionner. C'est une considération qui pourrait être soutenue, mais qui se situe plutôt en amont du point de vue des DRH. Lorsque votre organisation a des projets de délocalisation, qu'elle étudie une décision de "rester ou partir", qu'elle envisage de fusionner plusieurs bureaux ou d'ouvrir un bureau satellite, il est important d'analyser les impacts associés et les opportunités de déplacements. pendulaire. Choisissez un emplacement proche d'une station de train/métro et d'un accès à d'autres transports publics.&nbsp;&nbsp;</p>

<h3>Approche positive&nbsp;</h3>

<p dir="ltr">L'un des points essentiels à travailler est de faire comprendre à la direction et à l'entreprise ce qu'elles peuvent gagner en abordant l'expérience professionnelle de la maison le matin à la maison le soir, et en considérant réellement la mobilité comme une partie intégrante de l'activité professionnelle. Une fois que c'est le cas, il est plus facile de comprendre pourquoi l'entreprise doit y consacrer du temps, pourquoi elle doit y consacrer de l'énergie et des ressources ou prendre certaines mesures. Il serait bénéfique de s'éloigner des contraintes administratives liées à l'obligation d'établir un plan de mobilité et d'adopter plutôt une approche positive.</p>

<h3>Opportunités et... contraintes&nbsp;</h3>

<p dir="ltr">Accompagner la démarche d'une mobilité moins carbonée n'est jamais le cœur de métier d'une entreprise - et ne le deviendra pas. La flexibilité en termes de contraintes pouvant être imposées n’est pas si grande. La première étape consiste donc à établir le niveau de contraintes que l'entreprise est prête à imposer.</p>

<h3>Joindre le geste à la parole &nbsp;</h3>

<p dir="ltr">Si des initiatives sont prises en faveur d'une mobilité plus respectueuse du carbone/décarbonnée , il est essentiel qu'elles soient utilisées et promues par le top management/la direction de manière exemplaire. Parmi les plans étudiés dans le cadre de la recherche documentaire, une figure de proue qui se fait le champion du plan sera une clé du succès.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Les finances</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Le montant que les entreprises sont prêtes à consacrer aux questions de mobilité à un niveau stratégique est généralement extrêmement faible par rapport à ce qu'elles peuvent dépenser pour l'optimisation fiscale ou les prestataires de services RH. Pour les activités de sensibilisation, il a été évalué entre huit et dix fois moins. Il est impossible, avec des ambitions aussi faibles, de changer réellement les choses. On constate malheureusement que travailler sur la mobilité est encore perçu comme une contrainte par les entreprises, et non comme une réelle opportunité de modifier leur impact environnemental.</p>

<p dir="ltr">Pourtant, le retour sur investissement des projets de mobilité peut être direct et très important.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Communication</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Stratégie de communication&nbsp;</h3>

<p>Pour réellement changer les comportements liés à la mobilité, il est important que les entreprises mettent en place une stratégie de communication et que l'effort soit déployé de manière régulière. Le message doit être martelé en permanence. La gamification, par exemple, est une excellente opportunité à cet égard, en la rendant légère, avec des prix à gagner. La création d'un logo et/ou d'une image de marque pour le plan de mobilité professionnelle peut contribuer à attirer et à maintenir l'attention.</p>

<ul>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Chez SWIFT en Belgique, un "défi caritatif" a été organisé pour encourager la pratique du vélo pendant les semaines de mobilité : pour chaque kilomètre parcouru, 1 euro était reversé à des associations liées au cyclisme. Ce système a eu beaucoup de succès auprès des employés, car il combinait motivation extrinsèque et intrinsèque.&nbsp;</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Au Service public de Wallonie, en Belgique, un "Tour du monde à vélo" a été organisé : les participants étaient invités à inscrire leurs trajets à vélo liés au travail sur un formulaire en ligne dans le cadre d'un défi collectif consistant à parcourir ensemble 40 075 kilomètres en 26 semaines. Les destinations étaient atteintes collectivement, avec des anecdotes, des goodies et un diplôme à la fin. Le projet a utilisé la dynamique des niveaux et des badges, tout en jouant sur l'évasion, l'imprévisibilité et la créativité des participants.</p>
\t</li>
</ul>

<p><em><strong>Source: Unité de mobilité de l'UWE</strong></em></p>

<h3>Se concentrer sur la bonne chose&nbsp;</h3>

<p dir="ltr">La mobilité ne doit pas être abordée directement sous l'angle environnemental. La dimension environnementale sera le résultat positif, l'objectif vers lequel nous devons tendre. Mais ce n'est pas l'outil de communication : les gens ne changent pas pour des raisons environnementales, mais pour des raisons de commodité/convenances, d'efficacité, de communauté...&nbsp;</p>

<p dir="ltr">En bref, pour des raisons liées aux comportements humains. Les employés ne vont pas changer leur comportement parce que cela améliore l'empreinte carbone de l'entreprise, mais parce qu'ils vont avoir de nouvelles options pour améliorer la gestion de leur famille, rendre leurs trajets plus agréables... et, en définitive, avoir un impact positif sur la planète.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Gestionnaire de mobilité</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Désigner un responsable de la mobilité</h3>

<p dir="ltr">Une personne interne qui mène le projet de mobilité et, idéalement, rend compte à la direction générale, car le mandat est transversal : communication, RH, installations, etc. La personne en question personne peut venir de n'importe quel service - et, pourquoi pas, des RH - mais doit être en contact direct avec la direction générale et les représentants du personnel. Cette personne doit ensuite être formée aux questions de mobilité.</p>

<h3 dir="ltr">Un gestionnaire de mobilité ne suffit pas</h3>

<p dir="ltr">La mobilité reste l'expression de la liberté individuelle en dehors de toute contrainte de service. Du point de vue de la liberté individuelle, le rôle normatif de la voiture reste très important par exemple. Il y a donc un gros travail de conviction et de pédagogie à faire. Les DRH peuvent agir sur les déplacements liés au travail (par exemple dans le secteur des transports ou si l'entreprise emploie des salariés mobiles), mais ce sera plus difficile pour les déplacements domicile-travail, qui touchent en partie à la sphère privée. Ils sont alors beaucoup plus dépendants de la bonne volonté des gens ou de ce qu'ils sont prêts à faire, consciemment ou inconsciemment.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Gestionnaire de données</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Pour devenir des acteurs du changement, les entreprises doivent investir les ressources - financières, humaines et temporelles - nécessaires à un examen approfondi de leurs pratiques actuelles en matière de mobilité et de la manière dont les variables individuelles (âge, sexe, poste, trajet domicile-travail, etc.) influent sur leur mode de transport préféré. La collecte d'informations par le biais d'enquêtes, d'entretiens avec les employés, les fournisseurs et les clients et d'autres outils est essentielle pour élaborer une réponse appropriée. Les entreprises disposent déjà d'une énorme quantité de données et, si elles le souhaitent, elles ont souvent en main les éléments nécessaires pour progresser de manière simple et peu coûteuse.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Les syndicats</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Si vous élaborez des plans de mobilité du haut vers le bas, sans impliquer les syndicats, sans vous assurer que vous avez des ambassadeurs, sans tester les choses sur le terrain, cela ne fonctionnera pas. La mobilité est l'un des sujets les plus sensibles dans les entreprises. Et une fois que le mal est fait, il est extrêmement difficile de revenir dans une dynamique positive. Il faut aussi être prêt à revenir en arrière quand on fait une erreur et accepter de revoir ses plans.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Les autorités publiques</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Lors de l'élaboration d'un plan de mobilité neutre en carbone, il est essentiel d'obtenir un engagement clair des territoires et des institutions officielles. La planification locale est cruciale. Et généralement, les politiques sont le principal moteur du changement : 60 % des changements opérés par les entreprises en matière de planification de la mobilité sont motivés par la volonté de s'aligner (ou de se conformer) aux politiques nationales, régionales ou locales.&nbsp;</p>

<p dir="ltr">Le développement du travail à distance sera réglementé par l'employeur, mais il doit également être intégré dans une perspective de politique publique. Si on laisse le travail à distance se développer sans préciser les objectifs recherchés du point de vue de la politique publique, il y a peu de chances de les atteindre.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Opérateurs de transport public</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Si des responsables mobilité ou RH d'entreprises, même de secteurs différents, travaillent ensemble dans un périmètre de 500 à 800 mètres autour d'un pôle de transport - une gare, une station de métro, etc. - ils pourront lancer une dynamique et, surtout, aller à la rencontre des pouvoirs publics, des opérateurs de transport public et des services de mobilité pour leur dire : «&nbsp;Nous sommes, disons, 30, nous avons identifié tel ou tel besoin, que pouvons-nous faire ? Si une seule entreprise vient, elle n'aura pas d'impact. Avec 30 entreprises, la situation change. Vous avez besoin de cette échelle pour avoir un impact.</p>
</div></div></div>
</div>`
        },
        culture: {
            heading: "Culture",
            html: `
            <div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1c70fc1f-9e1f-4d2d-bb3d-c7867a33f9e3">
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8dc96038-5902-4fae-b1bc-6ff31e4bd15f"><p><strong><span style="font-size:1.5em;">Influencer les comportements</span></strong></p>

<p>Les organisations ne peuvent pas influencer le lieu de résidence de leurs employés, car cela constituerait une violation évidente de leur vie privée. Il est difficile de contrebalancer les déplacements domicile-travail, même si des paramètres tels qu'un logement moins cher ou une meilleure qualité de vie personnelle sont pris en compte. Travaillez avec des champions pour sensibiliser vos collègues.</p>

<p><strong>Example:</strong></p>

<p>Wever est une plateforme créée en France qui propose de réinventer une mobilité inclusive et efficace en plaçant l'usager et ses besoins exprimés au cœur de la conception de l'offre. Elle offre à chacun la possibilité d'exprimer ses besoins, de tester de nouvelles solutions et de voter pour mettre en œuvre les plus intéressantes sur le long terme.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-067d5e0e-e4fb-43be-84fa-825384498127"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-6509dd97d9c6f.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9772c0de-3792-4fe2-a5f6-899984bb576a"><p><strong>Comment cela fonctionne-t-il ?&nbsp;</strong></p>

<ol>
\t<li>Un diagnostic de mobilité permet de recueillir les habitudes et les besoins de déplacement des salariés. Il suffit de quelques minutes pour recueillir les besoins de chacun et établir un diagnostic.&nbsp;</li>
\t<li>Un tableau de bord permet de suivre en continu les diagnostics réalisés et de voir les recommandations d'actions commentées. Cet outil visuel permet de co-construire l'offre avec les utilisateurs.&nbsp;</li>
\t<li>L'algorithme détecte les opportunités de mobilité en révélant les solutions existantes et en modélisant de nouvelles solutions acceptables.&nbsp;</li>
\t<li>Avec un accompagnement individualisé du changement et une logique de récompenses de proximité, les transformations sont visibles en quelques semaines.</li>
</ol>

<p>Plus d'informations : <a target="_blank" href="http://www.wever.fr/" target="_blank">www.wever.fr</a></p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-065d48e1-ed65-4c87-803a-8063f0d2bb9b"><p><strong><span style="font-size:1.5em;">Faire du concret </span></strong></p>

<p>Ne faites pas de campagnes sur la mobilité liée au travail avec des affiches dans l'ascenseur, car vous ne changerez aucun comportement en faisant cela. Allez à la cafétéria trois fois par semaine pour parler aux gens, intéressez-vous à leur mobilité et à leurs préoccupations, proposez des solutions. Bref, personnalisez votre approche pour chaque personne. Cela demande du temps et de l'énergie. Un autre exemple : créez un groupe de cyclistes et organisez des événements autour de la bicyclette, sur le site et en dehors. Demandez à des collègues convaincus de faire une présentation ou de parler d'un sujet.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8cb77e76-e0c3-439c-9f90-d582dd82222c"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650a0615ebf86.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6d757e35-654c-416e-a38f-5fbf7e44a845"><p><strong><span style="font-size:1.5em;">Aborder la question du stationnement des voitures </span></strong></p>

<p>Le stationnement des voitures est une question centrale : les entreprises ont mis au point des mesures allant des incitations (une récompense financière pour ne pas amener sa voiture sur le site, par exemple) aux sanctions (introduction de frais de stationnement sur le site ou d'un nombre maximum de jours par semaine ou par mois pour utiliser le parking).</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42f4fadb-eb58-4f8a-a545-94bbab8675ab"><p><strong><span style="font-size:1.5em;">Initier une démarche participative</span></strong></p>

<p>Créez un pôle Mobilité impliquant des membres du personnel volontaires pour recueillir des idées, des suggestions, et développer des ambassadeurs internes. Certaines organisations ont délégué le rôle de gestion de la mobilité/du plan de voyage aux responsables hiérarchiques, afin de créer une certaine émulation au sein du groupe.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-a57aaa91-50f0-4dce-b73b-23410fda6402"><p><strong><span style="font-size:1.5em;">Promouvoir la réflexion hors des sentiers battus </span></strong></p>

<p>Encouragez les gens à réfléchir à la raison de leur déplacement avant de choisir/réfléchir au moyen/mode de déplacement.</p>


</div></div>`
        },
        hr: {
            heading: "Processus RH",
            html: `<div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(ORGANISATION DU TRAVAIL) Formaliser les accords de travail à distance</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Il y a encore trois ou quatre ans, le travail à distance était largement informel. Aujourd'hui, il est de plus en plus formalisé en raison de son adoption généralisée dans les grandes organisations suite à la crise sanitaire. Cette formalisation peut prendre la forme d'une politique d'entreprise et/ou de discussions entre l'employeur/direction et les syndicats/salariés sur les lignes directrices dans lesquelles l'employeur souhaite encadrer le travail à distance. Face à la saturation des réseaux de transports publics et routiers, il y a un enjeu à développer le travail à distance comme moyen de réguler ces flux de mobilité.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(RÉCOMPENSES) Pack mobilité durable</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p><strong>En France</strong>, les employeurs peuvent verser un forfait de mobilité durable à leurs employés qui utilisent un moyen de transport durable pour leurs déplacements professionnels. Les moyens de transport suivants sont couverts: le vélo personnel (y compris le vélo électrique), le covoiturage en tant que passager et conducteur, les services de mobilité partagée (covoiturage électrique ou hybride, location et mise à disposition en libre-service de scooters ou de vélos), les transports publics (à l'exclusion des abonnements). Le montant maximal du forfait mobilité durable est de 700 € par an et par salarié. Le montant versé est exonéré de cotisations sociales et d'impôt sur le revenu.</p>

<p dir="ltr"><strong>En Belgique</strong>, l'indemnité vélo est une indemnité kilométrique accordée par l'employeur aux salariés qui utilisent un vélo pour parcourir tout ou partie de la distance entre leur domicile et leur lieu de travail. L'indemnité est destinée à couvrir les frais du cycliste, mais elle vise surtout à inciter davantage de salariés à utiliser leur vélo. L’allocation kilométrique est exonérée d'impôt à hauteur de 0,25 euro par km parcouru pour l'exercice 2023, revenus 2022. En d'autres termes, tant que le montant de l'indemnité kilométrique  n'excède pas 0,25 euro par kilomètre parcouru, il n'y a pas d'impôt à payer.&nbsp; Si l'employeur accorde une indemnité kilométrique plus élevée, ce surplus est imposable en tant que revenu professionnel.</p>

<p dir="ltr"><strong>Vérifiez ce qui existe dans votre pays !</strong></p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(TEMPS DE TRAVAIL) Encourager les déplacements de mobilité douce</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>L'employeur peut créditer le temps de mobilité comme temps de travail, mais uniquement dans le cas de déplacements à mobilité douce - c'est-à-dire tout mode de transport qui n'utilise pas de moteur à combustion, ni n'émet de gaz à effet de serre. Cela présente l'avantage supplémentaire de donner à la personne la possibilité de voyager en dehors des heures de pointe.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(RECRUTEMENT) Recruter localement</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Recruter en tenant compte de la distance entre le domicile et le bureau dans la sélection des candidats pourrait être utilisé comme paramètre. Mais il sera d'autant plus difficile à activer que le marché du travail est tendu ou que les profils sont rares à trouver. Avec la très forte expérience que nous avons acquise en matière de travail à distance - imposée par Covid-19 -, le recrutement local continue de faire sens en soi, mais semble encore plus difficile à pratiquer.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(BIEN-ÊTRE) La mobilité est importante</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr">La mobilité est l'un des piliers du bien-être au travail. Les approches peuvent alors être très transversales. La promotion du vélo peut, par exemple, contribuer à améliorer la forme physique, à stimuler la concentration au travail, voire à favoriser la vie en communauté.</p>


</div></div></div>
</div>`
        },
        tools: {
            heading: "Outils",
            html: `
<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-ad634c59-8c77-49de-b651-13e5f6d885f5">
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4fba2945-7e82-4e52-8e74-afa0bdfe1f8b"><p><strong><span style="font-size:1.5em;">Effectuer une analyse de mobilité</span></strong></p>

<p>Réalisez un bilan carbone de votre entreprise : dédiez un expert pour identifier et cibler les sujets les plus impactants (mobilité, voyage en avion, bâtiment, etc.). Effectuez ensuite une analyse de mobilité basée sur des données précises et à jour.</p>

<p><strong>Exemples </strong>:</p>

<p>Mobility Label , des outils pour un trajet plus intelligent</p>

<p><a target="_blank" href="https://mobilitylabel.com/">https://mobilitylabel.com</a>&nbsp;</p>

<p>Mob Box agrège et compile des données brutes en masse. Des données internes de la flotte d'entreprise aux données externes open source, il transforme les chiffres en cartes simples et en graphiques intuitifs. Il vous aidera à avoir une vision claire de la mobilité de vos employés. Évaluer le mix modal global et analyser les émissions de CO2 et les coûts associés.</p>

<p><a target="_blank" href="http://www.mob-box.eu/" target="_blank">www.mob-box.eu</a>&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-08185ad9-d286-407c-8269-b3bcecba9cc2"><p><strong><span style="font-size:1.5em;">Explorer les solutions publiques disponibles</span></strong></p>

<ul dir="ltr">
\t<li><strong>En France :</strong> Le <a target="_blank" href="https://www.ecologie.gouv.fr/sites/default/files/mobilite%20durable%20en%20entreprise.pdf" target="_blank">Forfait Mobilité Durable</a> - chaque entreprise peut subventionner ou financer les trajets domicile-travail de ses salariés jusqu'à 800 euros par an, exonérés d'impôts et de cotisations sociales, à condition qu'ils soient utilisés dans des moyens de transport "verts" - transports en commun, trottinettes électriques, voitures électriques partagées, covoiturage…</li>
\t<li><strong>En Belgique :</strong> Le Budget Mobilité permet aux entreprises d'offrir aux salariés disposant d'une voiture de société un budget pour des mobilités plus respectueuses de l'environnement — <a target="_blank" href="https://lebudgetmobilite.be/fr">https://lebudgetmobilite.be/fr</a></li>
</ul>
</div><div></div><div class="h5p-column-content not-an-ios-device h5p-image-hotspots" id="h5p-interactive-book-section-076e63a2-1daa-4256-bf2d-59c590c6cbe8"><div class="h5p-image-hotspots-container" style="width: 100%; font-size: 24px;"><img class="h5p-image-hotspots-background" src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/image-650a166eaf053.jpg" aria-hidden="true" style="width: 100%;"><button class="h5p-image-hotspot h5p-image-hotspot-info" role="button" tabindex="0" aria-haspopup="true" title="Hotspot 1" aria-label="Hotspot 1" style="top: 35.9893%; left: 25.2%; color: rgb(0, 88, 145);"></button><button class="h5p-image-hotspot h5p-image-hotspot-info" role="button" tabindex="0" aria-haspopup="true" title="Hotspot 2" aria-label="Hotspot 2" style="top: 84.2714%; left: 73.6%; color: rgb(0, 88, 145);"></button></div></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-eb98b054-5f2c-454c-9a29-05efff3cce27"><p><strong><span style="font-size:1.5em;">Diagnostics individuels / Enquêtes Mobilité</span></strong></p>

<p dir="ltr">Observez les déplacements des personnes vers leur lieu de travail et proposez-leur des alternatives, au travers d'actions marketing individualisées. Il y a tout un processus de diagnostic et d'accompagnement des personnes pour leur montrer que la voiture n'est pas forcément le moyen le plus efficace pour se déplacer. Une analyse plus approfondie (en suivant les personnes pendant plusieurs jours) peut leur montrer qu'il existe d'autres façons de faire, moins motorisées, plus économiques, etc.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d7ebb14-1b14-4a0b-a034-151adcc5f3bb"><p><span style="font-size:1.5em;"><strong>Plateformes d'itinéraires multimodaux</strong></span></p>

<p>Les plateformes d'itinéraires multimodaux peuvent montrer les différentes options et permettre aux gens de se rendre compte que se déplacer en voiture sera moins efficace qu'avec d'autres modes de transport. Certains de ces outils affichent les émissions de CO2 associées aux différents modes de déplacement.</p>

<p><strong>Exemple :</strong></p>

<p>Skipr , la solution tout-en-un de gestion de la mobilité en entreprise</p>

<p><a target="_blank" href="http://www.skipr.co/">www.skipr.co</a></p>

<p>Skipr lutte contre l'usage unique de la voiture en ville. Elle met à disposition des salariés une application qui leur permet de voir comment se rendre de A à B en combinant les différents moyens de transport qui existent autour d'eux : transports en commun, véhicules partagés, trottinettes électriques, véhicules verts pour se rendre à la gare, etc. est un copilote qui leur assurera la possibilité de ne pas prendre leur voiture. Il permet d'acheter des titres de transports en commun sur le compte de l'entreprise, associés à une carte de paiement, liés au même « budget mobilité », pour des mobilités alternatives. Pour l'entreprise, il fournit une plateforme de gestion qui fera deux choses : mettre en place les lignes directrices de l'entreprise (déterminer qui a droit à quoi) et fournir un outil de reporting qui donne une visibilité complète sur les économies générées et la réduction des émissions de carbone par rapport à la utilisation d'une voiture traditionnelle. Les employés ont un tableau de bord avec leur propre situation, mais l'entreprise n'a que des rapports agrégés. Lors de la recherche d'options de déplacement, les collaborateurs reçoivent trois informations : l'heure d'arrivée, le coût en euros et les émissions de CO2.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4bf3cd48-c7e8-4a5f-a5a3-4727c4478547"><p><strong><span style="font-size:1.5em;">Encourager le covoiturage</span></strong></p>

<p>Conditions de réussite : la flexibilité des horaires de travail est l'une des premières raisons de ne pas faire de covoiturage entre collègues. Ne pas avoir les mêmes horaires de travail est un facteur limitant, surtout quand s'y ajoutent des contraintes personnelles - aller chercher les enfants, faire des courses, etc. -. Avoir un chef de projet, qui avec un tableau et un crayon, enregistre les trajets et les associe, peut aider. Si vous laissez les gens s'arranger entre eux, c'est plus compliqué. Si vous le laissez à une application, c'est compliqué aussi. Les outils numériques fonctionnent très bien pour les longues distances, mais ils sont moins efficaces pour les courtes distances, les usages récurrents et quotidiens.</p>

<p>Cependant, il existe des plateformes pour faciliter le covoiturage avec des options de correspondance, des informations sur les avantages fiscaux et des possibilités de suivi pour orienter la communication. Il est important de noter que le covoiturage ne fonctionne pas bien lorsque le chantier est à proximité d'une bonne offre de transport en commun.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e97d6b7b-85ca-4b8e-adc4-c549a30315f2"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650ae2ffdc5cc.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f2705860-d508-4b16-9089-9bba70468e72"><p><span style="font-size:1.5em;"><strong>Navette</strong></span></p>

<p>Plusieurs organisations ayant la plus forte croissance de l'utilisation des transports publics avaient mis en place une navette d'entreprise gratuite et dédiée reliée aux villes locales et/ou aux gares ferroviaires et routières locales. Un partenariat comprenait des organisations qui ont augmenté le nombre de services d'autobus arrivant sur le site d'une moyenne impressionnante d'environ 14 autobus supplémentaires à l'heure de pointe dans une étude. Disposer de services appropriés et adaptés est apparu plus important que des chiffres absolus : tarifs moins chers, billets négociés, amélioration de l'infrastructure/de la qualité des services hors site et financement de nouveaux arrêts de bus sur le site ou à proximité, par exemple.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c293a877-1d7a-44a0-8b05-524e78f4b318"><p><span style="font-size:1.5em;"><strong>Cyclisme</strong></span></p>

<p>Les facteurs les plus étroitement corrélés avec des niveaux élevés de cyclisme semblaient avoir un accès hors site de haute qualité ou améliorer l'accès hors site ; augmenter le stationnement disponible pour les cyclistes; et offrant un service de réparation de vélos. Il était également à noter que la plupart des organisations de l'étude avaient fourni des douches, des vestiaires, des sèche-linge et/ou des casiers ; des remises négociées sur le matériel de vélo.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-457e418e-7a81-4932-82ba-2fcbf1e21fcd"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650a17655699c.jpg" alt="Cycling" title="Cycling" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-801a1d35-3531-4738-807c-79f59dd9e601"><p><strong><span style="font-size:1.5em;">Voitures électriques ? Oui mais…</span></strong></p>

<p>La voiture électrique n'est pas une solution aux embouteillages. Une voiture reste une voiture, qu'elle soit électrique, hydrogène, essence ou diesel, elle prend de la place sur la route. Elle n'émet pas « zéro CO2 », elle en émet moins : tout dépend de la façon dont l'électricité a été produite. C'est toute la difficulté des problèmes de mobilité : il n'y a pas de taille unique. Chacun aura sa spécificité. Avec les voitures électriques, il y a aussi des problèmes liés au coût et à la recharge à prendre en compte. Il y a encore des obstacles. Les choses évoluent rapidement, mais il n'y a pas de solution miracle.</p>

<p>Installer des infrastructures de recharge pour les voitures électriques : elles sont nécessaires pour augmenter l'utilisation des voitures électriques.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-07fc7d73-8673-4f28-8e07-2c87d26235f0"><p><strong><span style="font-size:1.5em;">Location de vélos (avec vélos électriques)</span></strong></p>

<p dir="ltr"><strong>Exemple: </strong></p>

<p dir="ltr">Louer un vélo (Belgique)</p>

<p dir="ltr">En tant qu'employeur en Belgique, vous pouvez choisir de proposer le leasing de vélos de différentes manières : gratuitement ou avec une participation (partielle) au vélo. Dans tous les scénarios, les salariés bénéficient d'un avantage fiscal d'environ 40 %.</p>

<p>«Vous ne payez aucun avantage en nature sur un vélo loué, car vous utilisez régulièrement le vélo pour vos déplacements. Vous pouvez également verser une allocation vélo. Cela motive vos employés à faire du vélo. La réglementation sur la location de vélos ne fait aucune distinction entre un vélo loué et un vélo privé. Résultats? Salariés plus en forme : 1 à 2 jours d'absentéisme en moins et une contribution puissante à un plan de mobilité verte.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6c224ae6-6652-45b9-a674-13597a5bb424"><p><strong><span style="font-size:1.5em;">Formation à l'éco-conduite</span></strong></p>



<p dir="ltr"><strong>Exemple : </strong></p>

<p dir="ltr">Être éco-responsable sur la route demande plus qu'un véhicule respectueux de l'environnement. Vous devez avoir un style de conduite écologique. Et c'est ce que vos conducteurs apprennent dans le programme de formation E-positive, une formation d'un an par ALD Automotive.</p>

<p dir="ltr"><strong>Comment ça fonctionne:</strong></p>

<p dir="ltr">« Nous commençons par ce que vos chauffeurs savent faire ; cela met leurs styles de conduite actuels et leur utilisation du véhicule sur les cartes. Après cela, ils suivent un cours d'apprentissage en ligne et reçoivent une formation - individuellement et en groupe.</p>

<p dir="ltr">L'instructeur leur donne constamment des conseils personnalisés et des points auxquels ils doivent prêter attention. Le résultat? A l'issue de la formation, chacun conduit avec une attitude e-positive. »</p>

<p dir="ltr">10 conseils pour une conduite écologique</p>

<ol>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Passez à une vitesse supérieure au moment opportun. Avec un moteur diesel, à partir de 2 000 tours et avec un moteur à essence, 2 500 tours.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Il est temps de bouger ? Poussez votre accélérateur à fond, car accélérer lentement ne vous fera certainement pas économiser de carburant.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Gardez une distance suffisante avec le véhicule qui vous précède. Ainsi, vous n'aurez pas besoin de freiner et d'accélérer constamment.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Vous avez besoin de ralentir ou de vous arrêter ? Ne freinez pas directement, mais relâchez l'accélérateur à temps. Vous ralentirez avec le frein moteur et sans utiliser votre embrayage.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Activez le régulateur de vitesse pour plus de cohérence - et donc de respect de l'environnement.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Serez-vous à l'arrêt complet pendant plus de 20 secondes ? Coupez ensuite votre moteur.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Planifiez votre itinéraire à l'avance afin de ne pas cliquer sur un kilométrage inutile.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Vous n'avez pas besoin de coffre de toit, de porte-vélos ou de porte-bagages ? Enlevez-le et réduisez votre résistance à l'air.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Vérifiez régulièrement la pression de vos pneus.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Faites entretenir votre véhicule dans les délais : ce n'est qu'alors que vous pourrez conduire de manière économique et sûre.</p>
\t</li>
</ol>

<p dir="ltr"><em><strong>Source : ALD Automobile</strong></em></p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-0c1c9678-0a6c-445a-95ce-4a25e6395d09"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650a17e559c50.jpg" alt="Maestro mobile" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3e08a77a-372e-478d-a71b-1e82dbff5942"><p><strong><span style="font-size:1.5em;">Maestro Mobile </span></strong></p>

<p>MaestroMobile est un serious game développé par Espaces-Mobilités en Belgique, dans le domaine de la mobilité qui vise à faire évoluer les comportements par l'expérimentation.</p>

<p>Plus d'informations :&nbsp;<a target="_blank" href="https://www.maestromobile.eu/">https://www.maestromobile.eu</a>&nbsp;</p>

<ul>
\t<li><strong>Le Mobility Van</strong> est composé d'experts de la mobilité qui se déplacent dans les espaces publics et les entreprises et présentent aux salariés toutes les solutions de mobilité de manière pratique (test d'engins de micro mobilité, vélos, applications…)</li>
\t<li><strong>Le City Game</strong> est un serious game urbain qui s'organise sous la forme d'un teambuilding et permet aux participants de tester de manière pratique différentes solutions de mobilité tout en (re)découvrant la ville, en relevant des défis, en répondant à des quizz, etc. En utilisant les codes de des jeux populaires comme Pokemon Go ou les principes des courses urbaines, le City Game a créé son propre univers lié à la découverte des services de mobilité.</li>
\t<li>A l'instar des programmes de remise en forme, <strong>le Passeport Mobilité</strong> propose un challenge mobilité qui accompagne les participants à travers les différentes étapes de l'apprentissage de la multi mobilité, de la découverte d'un service à son utilisation, sur une &nbsp;&nbsp; &nbsp;durée d'environ un mois. Grâce à une application mobile, le participant obtient des validations de ses compétences et se voit attribuer des attestations matérialisées par un tampon apposé sur son passeport mobilité.</li>
\t<li><strong>GO Mobility</strong> est une plateforme hybride qui propose un programme complet de coaching et de découverte des alternatives de mobilité.</li>
\t<li><strong>Critical MaaS</strong> est un coaching personnalisé pour expérimenter de nouvelles mobilités et changer sa façon de se déplacer. Accompagné d'experts, vous testez tous les nouveaux services et devenez maître de votre mobilité.</li>
</ul>


</div></div>`
        },
        inspiringInitiatives: {
            heading: "Les initiatives inspirantes",
            html: `<div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Supprimer des espaces de stationnement</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Pour décourager l'utilisation de la voiture pour se rendre au travail, certaines entreprises ont supprimé les places de stationnement réservées dans les entreprises. Si tous les employés sont presque assurés d'avoir une place à destination, ils utiliseront beaucoup plus la voiture pour se rendre au travail que s'ils ne le faisaient pas.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Faciliter la mobilité partagée</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Une entreprise brésilienne d'énergie a conçu une application pour faciliter la mobilité partagée sur son site de São Paulo : les utilisateurs saisiraient leur itinéraire de déplacement, et l'application croiserait les données avec toutes les autres entrées et présenterait les employés suivant des itinéraires similaires. La mobilité partagée a augmenté de 18 % le transport et a permis à l'entreprise d'économiser 300 000 USD par an.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Encourager le cyclisme</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr">Les meilleures pratiques les plus innovantes parmi les études de cas comprenaient la fourniture d'une meilleure sécurité pour les vélos (stations de stationnement verrouillables individuellement ; marquage de la police ou offre de programmes d'assurance vélo)&nbsp;; le prêt d'équipements vélo (batteries, éclairages, pompes, antivols, casques, porte-vélos, sièges enfants, etc.) ; des cartes cyclables spécifiques au site ; incitations financières et produits complémentaires (par exemple, offre de gilets de sécurité).</p>

<p dir="ltr">Exemples d'actions positives : un partenariat pour la location de vélos cargos pour les livraisons ou l'utilisation par des techniciens, une campagne de prêt de vélos électriques pour permettre aux collaborateurs de les tester, un 'Bike to work tool' pour mesurer la neutralité carbone et participer à différents challenges.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Bureaux partagés décentralisés</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Projet pilote d'espace de bureau partagé centralisé pour permettre à ceux qui ne peuvent pas faire de télétravail certains jours de ne pas avoir à faire tout le trajet jusqu'au siège, s'ils habitent loin ou si c'est trop compliqué pour eux. Fait avec un opérateur de coworking, avec un système centralisé de facturation.</p>

<h2 dir="ltr">Rendre le train plus compétitif</h2>

<p dir="ltr">Politique voyages d'affaires :</p>

<ul>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">allocation de fonds pour compenser le prix plus élevé de l'avion afin de rendre les trains compétitifs.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">pas le droit de prendre l'avion pour les trajets de moins de 2h qui peuvent se faire en train.</p>
\t</li>
</ul>
</div></div></div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-bd0d1aab-e9f3-4954-82d7-5ca803053822"><p><strong><span style="font-size:1.5em;"><h2>Approche "payer ce que je consomme"</h2></span></strong></p>

<p dir="ltr">Approche "payer ce que je consomme" pour les voitures de société : les gens contrôlent ce qu'ils consomment + remboursement des km non consommés ou surconsommés / influence le prix de location et le budget qui sera alloué.</p>
</div>
</div>`
        },
        recommendation: {
            heading: "Recommandations",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4052f6b2-2d6c-437b-bd67-4e316610281b">
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f3a1154d-cf21-4744-8f37-5b942ddae908"><p><span style="font-size:1.5em;"><strong>Mesurer : mais il vaut mieux avancer avec des mesures imparfaites</strong></span></p>

<p>Attendre la perfection est le meilleur moyen de ne rien faire…</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9c17bf2a-baba-43d2-a449-63d3a162ff6a"><p><strong><span style="font-size:1.5em;">Choisir un emplacement d'entreprise accessible par des moyens de transport durables</span></strong></p>

<p>Les entreprises n'ont pas la capacité de « recruter localement », ni d'imposer des lieux de vie à leurs salariés, sauf à prévoir un logement dans le cadre du contrat de travail. Ils ne peuvent influencer la distance domicile-travail, mais ils peuvent choisir un lieu accessible à leurs travailleurs par des moyens plus durables que la voiture.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-32b491be-187b-4b28-b25e-c93cdd367c4c"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650ae7da4ccaf.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42a6405c-6e2a-4f70-b7d8-501f3b5e3f66"><p><strong><span style="font-size:1.5em;">Planifier, exploiter, activer</span></strong></p>

<p>Le premier pilier est le diagnostic et la définition d'une stratégie. Vient ensuite la mise en place avec les services de mobilité, la gestion des données, le suivi financier, l'infrastructure, etc. La plupart des entreprises s'arrêtent là et voient ensuite que cela ne fonctionne pas. Pourquoi Tout simplement parce qu'il manque la dimension du changement de comportement.</p>

<p>Tout ce qui précède est nécessaire, mais pas suffisant. Vous devez "activer". Lorsqu'une entreprise vous indique qu'elle a placé dix vélos électriques dans la zone d'accueil, mais que personne ne les utilise, il manque cette étape : l'activation. Vous n'avez pas motivé les gens.</p>

<p>Les motiver peut se faire de différentes manières, comme organiser un jeu avec une récompense pour ceux qui les utilisent le plus.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-7565574d-621a-4f00-b920-c201f9d2f507"><p><strong><span style="font-size:1.5em;">Acquérir une compréhension dans les déplacements de longue durée ou sur de très longues distances</span></strong></p>

<p>Ex : les doubles résidences liées au travail - soit avec une résidence secondaire, soit dans un hôtel, voire dans un AirBnB -, les doubles résidences liées à des personnes ayant deux emplois dans deux villes différentes ; déplacements très fréquents liés au travail, etc.</p>

<p>Cela mérite pourtant d'être considéré d'un point de vue RH, et plus encore lors d'un déménagement dans un nouveau lieu de travail en termes d'impact sur les déplacements et d'accompagnement nécessaire.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-80c0adf5-cab3-4fbb-98bd-c46a956d2977"><p><strong><span style="font-size:1.5em;">Ne vous concentrez pas uniquement sur l'aspect budgétaire</span></strong></p>

<p>La logique du choix de la mobilité inclut aussi la vitesse (si l'on prend 1h30 en transports en commun, contre 40 minutes en voiture, l'argent n'aura pas beaucoup d'impact), mais aussi bien d'autres critères comme le confort (c'est-à-dire toute l'expérience du voyage : le train a un avantage comparatif si vous pouvez y faire d'autres activités - dormir, lire, travailler, etc. - mais cet avantage est annulé si le train est bondé et que vous n'avez pas de siège, par exemple), l'image ou expérience que vous avez des différents modes de transport, habitudes, sécurité, etc. Envisagez une politique de mobilité qui intègre toutes les dimensions (coût, vitesse, confort, etc.) pour que les alternatives soient attractives.</p>

<p>Passer d'un budget mobilité de 500 euros, à un budget de x kilos de CO2 et allouer le budget mobilité du collaborateur en conséquence, éventuellement avec des échanges entre collègues ou la possibilité d'échanger des économies contre des jours de congés.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6594bb16-8d4a-40a3-baae-b2c961991ac6"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650ae87fc0ae4.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-db56f5ca-9960-46ca-b07c-3aa08b24a38f"><p><strong><span style="font-size:1.5em;">Encourager la multimodalité</span></strong></p>

<p>Favoriser les transferts modaux afin que les gens minimisent le rôle de la voiture dans leurs déplacements. Les personnes qui vivent dans des régions peu peuplées ; les zones périurbaines peuvent être en mesure de réduire l'utilisation de la voiture si elles utilisent d'autres modes de transport en plus de la voiture.</p>

<p>Passer à la voiture augmentée plutôt qu'à la voiture diminuée : continuer à avoir une voiture de société, éventuellement plus petite, avec de meilleures performances environnementales, mais donnons aussi la possibilité aux gens de se déplacer autrement. Cela ne nécessite pas toujours d'augmenter l'offre, mais simplement d'accepter de faciliter l'accès aux transports en commun ou à d'autres formes de mobilité.</p>

<p>Il existe aujourd'hui une multitude de solutions multimodales.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-2b595af0-7cfc-41da-b1a7-f849be29cfe8"><p><strong><span style="font-size:1.5em;">Voitures de société : faites attention à…</span></strong></p>

<p>Si l'entreprise se sent obligée de mettre à disposition des voitures de société (pour l'attraction des talents, la fidélisation, le statut, la représentation…), attention au type de véhicule, même si l'on sait que l'impact environnemental d'un véhicule électrique n'est pas non plus neutre.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e2b6406f-ba7a-45b7-83b8-84806274d67f"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650ae8f013a9d.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5821544b-625c-4c3a-a64e-9ddf0f3eeb2f"><p><span style="font-size:1.5em;"><strong>Gérer les places de stationnement</strong></span></p>

<p>Si l'entreprise propose des places de parking, c'est en quelque sorte payer pour que les salariés utilisent leur voiture. Ce dont nous avons besoin, c'est simplement d'équilibrer les avantages entre les différents types de transport : si vous vous déplacez à vélo, vous n'occupez aucune place de parking. Si vous prenez moins de place, l'entreprise pourrait vous accorder une certaine somme - par exemple 200 euros - car vous faites économiser de l'argent à l'entreprise en venant à vélo.</p>

<p>Cibler les places de stationnement réservées aux gestionnaires, généralement mieux situées. Cet avantage hiérarchique ne reflète pas l'ambition de l'entreprise pour une mobilité moins carbonée. S'il y a un parking, les places les plus proches de l'entrée du bureau doivent être réservées au covoiturage.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d56820b5-cdac-4d65-b9c3-e7e19d058c6a"><p><span style="font-size:1.5em;"><strong>Utiliser des "ambassadeurs internes"</strong></span></p>

<p>Travailler avec des projets pilotes, avec de petits groupes d'ambassadeurs prêts à jouer ce rôle. Ne révolutionnez pas la mobilité dans votre entreprise ; commencez avec des gens qui sont volontaires et avec de petites initiatives qui germeront et se développeront. Mobiliser le top management : lorsque le PDG et les top managers enfourchent leurs vélos et le font régulièrement, cela a un effet majeur sur l'organisation.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-96546409-df37-40a1-8000-e20cc49697d3"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650ae968de311.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3c1ff5d8-04bb-4246-b817-45823add9089"><p><span style="font-size:1.5em;"><strong>Mot-clé : Collaboration</strong></span></p>

<p>L' étude de Kiba-Janiak et Witkowski note qu'un indicateur clé d'un plan de mobilité réussi est la collaboration avec un réseau d'acteurs, qu'ils soient publics ou privés, et l'annonce comme inhérente à la réduction des émissions de GES.</p>




</div></div>`,
        }
    },
    et:{
        learningObjectives: {
            heading: "Õppeeesmärgid",
            html: `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-fea7cbcd-7eb5-400b-bcff-997ed232168a">

<p>Üldine eesmärk on anda personalispetsialistidele võimalus rakendada praktilisi uuenduslikke mobiilsuslahendusi, mis aitavad vähendada ettevõtte süsinikdioksiidi heitkoguseid ja suurendada üldist jätkusuutlikkust.</p>

<p><span style="font-size:1.25em;"><strong>Sidusrühmade kaasamine ja strateegiline liikuvuse juhtimine:</strong></span></p>

<ul>
\t<li>Strateegiliste liikuvuskavade koostamiseks ja elluviimiseks arendage pädevust tõhusaks suhtlemiseks mitmesuguste sidusrühmadega, sealhulgas tegevjuhid, tippjuhtkond, finantsosakonnad ja riigiasutused.</li>
\t<li>See hõlmab ettevõtte asukohaotsuste olulisuse mõistmist seoses töökohtade ja ühistranspordi kättesaadavusega ning nende mõju ettevõtte süsiniku jalajäljele.</li>
\t<li>Omandage tippjuhtkonna juhtimise ja mõjutamise oskused, et võtta omaks positiivne lähenemine tööalase liikuvuse suhtes, ületades pelgalt nõuetele vastavuse, et integreerida liikuvus aktiivselt töökogemuse osana.</li>
\t<li>Õppige koostama ja esitama finantsosakondadele mõjuvaid ärijuhtumeid, näidates säästva liikuvuse projektide otsest ja olulist investeeringutasuvust.</li>
\t<li>Täiustage tõhusate suhtlusstrateegiate loomise oskusi, kasutades selliseid tööriistu nagu mängimine ja pidev sõnumside, et muuta töötajate liikuvusega seotud käitumist.</li>
</ul>

<p><span style="font-size:1.25em;"><strong>Operatiivne rakendamine ja innovatsioon liikuvuses:</strong></span></p>

<ul>
\t<li>Õppige liikuvusstrateegiaid rakendama, määrates ametisse ja koolitades liikuvusjuhid, kes saavad juhtida projekte erinevates osakondades.</li>
\t<li>Õppige koguma ja analüüsima andmeid praeguste liikumistavade ja individuaalsete pendelrändeelistuste kohta, et kohandada liikuvuslahendusi.</li>
\t<li>Arendada oskusi osaluspõhiste lähenemisviiside algatamiseks, sisesaadikute loomiseks ja töötajatevahelise koostöö edendamiseks, et edendada jätkusuutlikke pendelrände meetodeid.</li>
\t<li>Mõistke, kui oluline on käsitleda selliseid praktilisi aspekte nagu parkimispoliitika ja julgustada alternatiive, nagu jalgrattasõit, ühistranspordi kasutamine ja ühistranspordi kasutamine.</li>
\t<li>Saate ülevaate tehnoloogiliste tööriistade (nt multimodaalsed marsruudiplatvormid) kasutamisest ja säästva liikuvuse pakettide rakendamisest, et ergutada keskkonnasõbralikku pendelrännet.</li>
</ul>




</div>`
        },
        stakeHolders: {
            heading: "Sidusrühmad",
            html: `<div>
<p>Personalijuht vastutab ainult osaliselt küsimuste eest, mis on seotud töötajate liikuvusega tööle ja töölt koju.&nbsp;</p>
<p>Selleks, et aidata kaasa ettevõtte süsinikuheitmete vähendamisele, peab personalijuht tegema tihedat koostööd erinevate sidusrühmadega, kellest peamised on projektipartnerid määratlenud järgmiselt:&nbsp;</p>
<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Tegevjuht/juhtkond</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Ettevõtte asukoht&nbsp;</h3>

<p>Ettevõtte asukoht on määratletud kui koht või struktuur, mida ettevõte kasutab oma tegevuse juhtimiseks. Kui me vaatame ettevõtete asukohavaliku põhjendusi, ei ole ükski kriteerium tühine ja on üldiselt üks olulistest faktoritest. Siin peame silmas nn tööhõive kogumit, milles ettevõte end positsioneerib.&nbsp;</p>

<p>Kui teie organisatsioonil on kolimisplaanid, uurite kas "jääda või minna" otsust, kaalute mitme kontori ühendamist või satelliitkontori avamist, on oluline analüüsida sellega seotud mõjusid ja töötajate pendelrände võimalusi. Valige asukoht uus rongi- või bussijaama lähedal ja juurdepääsuga teistele ühistranspordi liikidele. &nbsp;</p>

<h3>Positiivne lähenemine</h3>

<p>Üks peamisi teemasid, mille kallal töötada, on panna juhtkond ja ettevõte mõistma, mida võidakse saada lisaks, kui analüüsitakse tööprotsessi ning arvestades sealjuures &nbsp;kogemusena hommikul kodust tulekut ja õhtul kojuminekut ning proovides võtta liikuvust tööalase tegevuse lahutamatu osana. Kui see on nii, on lihtsam mõista, miks ettevõte peab sellele aega kulutama, miks ta peab sellesse energiat ja ressursse panema või võtma tarvitusele teatud meetmeid. Oleks kasulik eemalduda halduspiirangutest, mis on seotud kohustusega koostada liikuvuskava, ja võtta selle asemel positiivne lähenemisviis.</p>

<h3>Võimalused ja... piirangud&nbsp;</h3>

<p>Vähem süsihappegaasiheiteid tekitava liikuvuse kui protsessi toetamine ei ole kunagi ettevõtte põhitegevus - ja see ei muutu selleks ka edaspidi. Paindlikkus piirangute osas, mida saab kehtestada, ei ole paraku väga suur. Esimene samm on seega kehtestada piirangute tase, mida ettevõte on valmis kehtestama.</p>

<h3>Näidake eeskuju&nbsp;</h3>

<p>Kui tehakse algatusi süsinikdioksiidisõbralikuma liikuvuse kasuks, on oluline, et juhtkond kasutaks ja edendaks neid läbi enda eeskuju. Uuringud näitavad, et plaanitavate edusammude võti on antud plaani eest seisev juhtfiguur.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Rahastamine</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Rahasummad, mida ettevõtted on valmis kulutama liikuvusküsimustele strateegilisel tasandil, on tavaliselt äärmiselt väikesed võrreldes nendega, mida nad kulutavad maksude optimeerimisele või personaliteenuste pakkujatele. Teadlikkust tõstvate meetmete puhul hinnati seda kaheksa kuni kümme korda vähemaks võrreldes eeltoodud näidetega. Nii madalate ambitsioonidega on võimatu tegelikult midagi muuta. Kahjuks näeme, et liikuvuskavadega töötamist tajuvad ettevõtted endiselt kui piirangut, mitte kui tegeliku võimalust oma keskkonnamõju muuta. Siiski võib liikuvusprojektide investeeringutasuvus olla otsene ja väga märkimisväärne.&nbsp;</p>

<p>Personalijuhtidel on võimalik selles valdkonnas edusamme teha, kui nad töötavad välja reaalse ärimudeli koos konkreetsete arvudega.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Teavitamine</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Kommunikatsioonistrateegia&nbsp;</h3>

<p>Liikuvusega seotud käitumise tõeliseks muutmiseks on oluline, et ettevõtted töötaksid välja kommunikatsioonistrateegia ja et jõupingutusi tehtaks regulaarselt. Sõnumit tuleb pidevalt korrata. Näiteks mäng on siin suurepärane võimalus, mis muudab selle sõnumi &nbsp;kergemini edastavaks ja pakub ka osalejatele auhindu. Töökoha liikuvuskava jaoks logo ja/või kaubamärgi loomine võib aidata tähelepanu köita ja säilitada.</p>

<ul>
\t<li>Belgias SWIFTis korraldati "heategevuslik väljakutse", et julgustada jalgrattasõitu liikuvusnädalatel: iga läbitud kilomeetri eest annetati 1 euro jalgrattaspordiga seotud ühendustele. See töötajaid kaasav tegevus oli väga edukas, kuna see ühendas nii töötajate välise kui ka sisemise motivatsiooni.&nbsp;</li>
\t<li>Belgias Service Public de Wallonie's korraldati "Round the World Cycling Tour": osalejatel paluti sisestada oma tööga seotud rattaretked veebivormi kaudu osana kollektiivsest väljakutsest läbida 26 nädala jooksul kokku 40 075 kilomeetrit.&nbsp;Sihtkohtadesse jõuti ühiselt, tuuri lõpus räägiti ühiseid nalju, pakuti maiuspalu ja osalejad said ka diplomid, mis kajastasid antud ettevõtmises ka tasemete ja märkide dünaamikat, pakkudes läbi mängu samal ajal ka osalejatele ka ettearvamatusi ja arendasid nende loovust.</li>
</ul>

<p><strong>Allikas: UWE mobiilsusüksus</strong></p>

<h3>Keskenduge õigele asjale&nbsp;</h3>

<p>Liikuvusele ei tohiks läheneda otse ja ainult keskkonna vaatenurgast. Keskkonnamõõde on positiivne tulemus, pigem eesmärk, mille poole peame püüdlema. Kuid see ei ole suhtlusvahend: inimesed ei pruugi muutuda keskkonnakaalutlustel, vaid pigem mugavuse, tõhususe, kogukonna huvides ... Seega, inimkäitumisega seotud põhjused on need, mis aitavad kaasa eesmärkide saavutamisel.</p>

<p>Töötajad üldiselt ei muuda oma käitumist sellepärast, et see vähendab ettevõtte süsinikujalajälge, vaid seetõttu, et neil on uued võimalused oma pere paremaks juhtimiseks, oma teekonna meeldivamaks muutmiseks ... ja lõppkokkuvõttes on neil planeedile positiivne mõju.&nbsp;</p>


</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Liikuvusjuht</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Määrake liikuvusjuht</h3>

<p>Liikuvusjuht on sisemine isik, kes juhib liikuvusprojekti ja annab ideaaljuhul aru juhtkonnale, sest volitused on valdkonnaülesed: kommunikatsioon, personalijuhtimine, rajatised, liikuvusvahendid jne. Isik võib olla pärit mis tahes osakonnast - ja miks mitte ka personaliosakonnast -, kuid tal peab olema otsene kontakt juhtkonna ja töötajate esindajatega. Seejärel tuleb seda isikut liikuvusküsimustes koolitada.</p>

<h3>Liikuvusjuhist ei piisa</h3>

<p>Liikuvus jääb üksikisiku vabaduse väljenduseks väljaspool mis tahes tööalaseid piiranguid. Individuaalse vabaduse seisukohast on näiteks auto normatiivne roll endiselt väga oluline. Seetõttu on vaja teha palju veenvat ja harivat teavitustööd. Personalitöö arendus võib toimuda tööga seotud reiside puhul (näiteks transpordisektoris või kui ettevõttes töötavad liikuvad töötajad), kuid see on raskem kodu-töö pendelrändel, mis osaliselt puudutab erasfääri. Liikuvusega seotud &nbsp;valikud sõltuvad siis palju rohkem inimeste heast tahtest või sellest, mida inimesed on valmis tegema, teadlikult või alateadlikult.<br>
&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Andmehaldur</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Muutustega kaasaminemine tähendab, et ettevõtted peavad investeerima oma vahendeid – rahalisi, inim- ja ajalisi ressursse –, et põhjalikult uurida oma praeguseid liikuvustavasid ja seda, kuidas individuaalsed muutujad (vanus, sugu, tööpositsioon, pendelrändemarsruudid jne) mõjutavad nende töötajate eelistatud transpordiliiki. Teabe kogumine küsitluste, töötajate, tarnijate ja klientide intervjuude ning muude tööriistade kaudu on sobiva vastuse kujundamisel ülioluline. Ettevõtetel on juba tohutul hulgal andmeid ja kui nad soovivad, on neil sageli käepärast vajalikud elemendid, et lihtsal ja odaval viisil edasi liikuda.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Ametiühingud</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Kui koostate liikuvuskavasid ülalt alla, ametiühinguid kaasamata, nende esindajaid kaasamata ja kohapeal asju katsetamata, siis ei pruugi need kavad toimida. Liikuvus on ettevõtetes üks tundlikumaid teemasid. Ja kui kahju on tehtud, on äärmiselt raske positiivsesse dünaamikasse tagasi pääseda. Samuti peate olema valmis tagasi minema, kui teete vea, ja nõustuma oma plaanide ülevaatamisega.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Riigiasutused</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Eduka CO₂-neutraalse liikuvuskava koostamisel on oluline, et kohalikud omavalitsused ja ametlikud institutsioonid oleksid kaasatud sellesse maksimaalselt tõhusalt. Kohalik planeerimine on ülioluline. Ja tavaliselt on poliitika peamine muutuste liikumapanev jõud: 60% ettevõtete muutustest liikuvuse planeerimisel on ajendatud soovist joonduda (või järgida) riiklikku, piirkondlikku või kohalikku poliitikat.&nbsp;</p>

<p>Kuigi kaugtöötamist peaks reguleerima tööandja, kuid see tuleb integreerida ka avaliku poliitika perspektiivi. Kui kaugtöö meetoditel lubatakse areneda ilma avaliku poliitika seisukohast taotletavaid eesmärke täpsustamata, on nende saavutamise võimalused väikesed.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Ühistranspordiettevõtjad</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Kui ettevõtete liikuvus- või personalijuhid, isegi siis, kui nad on erinevatest sektoritest, teevad koostööd 500–800 meetri läbimõõduga alal transpordisõlme ümber - rongijaamas, bussijaamas jne. - saavad nad luua dünaamika ja läbi selle minna ühiselt &nbsp;kohtuma riigiasutuste, ühistranspordiettevõtjate ja liikuvusteenistustega ning öelda neile: "Meid on, ütleme, 30; me oleme tuvastanud sellise ja sellise vajaduse; mida me saame selles suhtes ette võtta". Kui ainult üks ettevõte tuleb, ei ole sellel mingit mõju. 30 ettevõttega olukord muutub. Teil on vaja sellist massi, et mõju avaldada.</p>
</div></div></div>
</div>`
        },
        culture: {
            heading: "Kultuuri",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1c70fc1f-9e1f-4d2d-bb3d-c7867a33f9e3">
</div><div class="h5p-column-ruler h5p-column-space-after"></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-067d5e0e-e4fb-43be-84fa-825384498127"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-6509dd97d9c6f.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8dc96038-5902-4fae-b1bc-6ff31e4bd15f"><p><strong><span style="font-size:1.5em;">Mõjutage käitumist </span></strong></p>

<p>Organisatsioonid ei saa mõjutada seda, kus töötajad otsustavad elada, sest see oleks nende privaatsuse ilmne rikkumine. Tööle sõitmist on raske kaalule panna, isegi kui püütakse arvestada selliseid parameetreid nagu odavam eluase või parem isikliku elu kvaliteet. Töötage koos ekspertidega, et kolleege sellistel teemadel teavitada.&nbsp;</p>

<p><strong>Näide:</strong></p>

<p>Wever on Prantsusmaal loodud platvorm, mis teeb ettepaneku leiutada kaasav ja tõhus liikuvus, asetades kasutaja ja tema väljendatud vajadused pakkumise kavandamise keskmesse. See annab igaühele võimaluse väljendada oma vajadusi, katsetada uusi lahendusi ja hääletada kõige huvitavamate lahenduste elluviimise poolt pikas perspektiivis.</p>
</div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9772c0de-3792-4fe2-a5f6-899984bb576a"><p><strong>Kuidas see töötab?&nbsp;</strong></p>

<ol>
\t<li>Liikumisdiagnostikat kasutatakse töötajate reisiharjumuste ja vajaduste kogumiseks. Iga inimese vajaduste kogumiseks ja diagnoosi määramiseks kulub vaid mõni minut.&nbsp;</li>
\t<li>
\t<p>Armatuurlaud võimaldab jälgida reaalajas tehtud diagnoose ja näha soovitusi tegutsemiseks, lisatud on ka kommentaarid. See visuaalne tööriist võimaldab pakutavat liikuvuskava kasutajatega ühiselt koostada.&nbsp;</p>
\t</li>
\t<li>
\t<p>Algoritm tuvastab liikuvusvõimalused, tuues välja olemasolevad lahendused ja modelleerides uusi vastuvõetavaid lahendusi.&nbsp;</p>
\t</li>
\t<li>
\t<p>Tänu personaliseeritud toetusele muutustele ja individuaalset hüvede loogikat silmas pidades on teenust kasutava isiku muutused nähtavad mõne nädala pärast.</p>
\t</li>
</ol>

<p>Lisainformatsioon:&nbsp;<a target="_blank" href="http://www.wever.fr/" target="_blank">www.wever.fr</a></p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-065d48e1-ed65-4c87-803a-8063f0d2bb9b"><p><strong><span style="font-size:1.5em;">Tee see konkreetseks </span></strong></p>

<p>Ärge tehke tööga seotud liikuvuse kampaaniaid liftis olevate plakatitega, sest seda tehes ei saa te oma käitumist muuta. Käige kolm korda nädalas kohvikus, et rääkida inimestega, tunda huvi nende liikuvuse ja murede vastu ning soovitage edasisi samme. Lühidalt, isikupärastage oma lähenemine iga inimese jaoks. See võtab aega ja energiat. Teine näide on jalgrattarühma loomine ja jalgrattaürituste korraldamine kohapeal ja väljaspool seda. Samuti võite paluda veendunud kolleegidel teha esitlus või rääkida mingil teemal.&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6d757e35-654c-416e-a38f-5fbf7e44a845"><p><strong><span style="font-size:1.5em;">Autode parkimisprobleemide lahendamine</span></strong></p>

<p>Autode parkimine on keskne küsimus: ettevõtted on välja töötanud meetmed alates stiimulitest (rahaline tasu näiteks selle eest, et oma autot ei tooda kohapeale) kuni trahvideni (kohapealse parkimistasu kehtestamine või maksimaalne päevade / nädala või kuu arv parkla kasutamiseks).</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8cb77e76-e0c3-439c-9f90-d582dd82222c"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650a0615ebf86.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42f4fadb-eb58-4f8a-a545-94bbab8675ab"><p><span style="font-size:1.5em;"><strong>Algatada osaluspõhine lähenemisviis</strong></span></p>

<p>Luua liikuvusega seotud teemavaldkond, kuhu on kaasatud vabatahtlikud töötajad, et koguda ideid ja ettepanekuid ning arendada välja sisesaadikute funktsioon ja nende eesmärgid. Mõned organisatsioonid on delegeerinud liikuvuse/tööletulekuviiside haldamise rolli otsestele juhtidele, et luua töörühmade sisene jäljendamine läbi vahetu eeskuju.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-a57aaa91-50f0-4dce-b73b-23410fda6402"><p><strong><span style="font-size:1.5em;">Edendage „kastist väljas“ mõtlemist </span></strong></p>

<p>Julgustage inimesi mõtlema reisi põhjusele, enne kui valite/mõtlete reisimisviisile/-viisile.</p>




</div></div>`
        },
        hr: {
            heading: "HR protsessid",
            html: `<div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(TÖÖKORRALDUS)  - Kaugtöölepingute vormistamine</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Veel kolm-neli aastat tagasi oli kaugtöö suures osas mitteametlik. Täna muutub see üha ametlikumaks, kuna see on pärast tervisekriisi laialdaselt kasutusele võetud suurtes organisatsioonides. Selline formaliseerimine võib toimuda ettevõtte poliitika ja/või tööandja/juhtide ja ametiühingute/töötajate vaheliste arutelude vormis suuniste üle, mille raames tööandja soovib kaugtööd kujundada. Seistes silmitsi ühistranspordi ja teedevõrgu küllastumisega, on väljakutse arendada kaugtööd nende liikuvusvoogude reguleerimiseks.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(PREEMIAD) Säästva liikuvuse pakett</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Samamoodi nagu töötajate ühistransport, pakub selline skeem teatavat liiki hüvitist neile, kes tulevad tööle, kasutades "vooruslikke" transpordiliike, näiteks jalgrattasõitu või koossõitu. Mis puutub ametiautodesse, siis diiselmootoriga autode keelustamine, bensiinimootoriga sõidukite asendamine heitevabade sõidukitega.&nbsp;</p>

<p><strong>Prantsusmaal </strong>saavad tööandjad maksta säästva liikuvuse paketti oma töötajatele, kes kasutavad oma tööreisidel säästvat transpordivahendit. Hõlmatud on järgmised transpordivahendid: isiklik jalgratas (sealhulgas elektrijalgratas), koossõit kaassõitja ja juhina, jagatud liikuvusteenused (elektri- või hübriidautode ühiskasutus, tõukerataste või jalgrataste rentimine ja iseteenindus), ühistransport (välja arvatud perioodipiletid). Säästva liikuvuse paketi maksimaalne summa on 700 eurot aastas töötaja kohta. Makstud summa on vabastatud sotsiaalkindlustusmaksetest ja tulumaksust.</p>

<p><strong>Belgias </strong>on jalgrattatoetus läbisõidutoetus, mida tööandja maksab töötajatele, kes kasutavad jalgratast kodu ja töökoha vahelise vahemaa täielikuks või osaliseks läbimiseks. Toetuse eesmärk on katta jalgratturi kulud, kuid eelkõige on selle eesmärk julgustada rohkem töötajaid oma jalgratast kasutama. Kilomeetrite soodustus on maksuvaba kuni 0,25 eurot läbitud kilomeetri kohta 2023. Majandusaastal. Teisisõnu, kuni läbisõiduhüvitise summa ei ületa 0,25 eurot läbitud kilomeetri kohta, ei tule maksu maksta. Kui tööandja maksab suuremat läbisõidutoetust, maksustatakse see ülejääk kutsetuluna.</p>

<p><strong>Kontrollige, millised võimalused teie riigis olemas on!&nbsp;</strong></p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(TÖÖAEG) soodustage pehme liikuvusega reisimist</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Tööandja võib arvestada liikuvusaega tööajana, kuid ainult pehme liikuvusega reisimise korral - st mis tahes transpordiliik, mis ei kasuta sisepõlemismootorit ega eralda kasvuhoonegaase. Sellel on täiendav eelis, mis annab inimesele võimaluse reisida väljaspool tipptunde.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(VÄRBAMINE) Värbamine kohalikul tasandil</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Ühe parameetrina, mida võiks kasutada värbamisel, oleks kandidaatide valikus arvesse võtta nende kodu ja kontori vahelise kaugust. Seda võib olla vägagi raske aktiveerida, kui tööturg on pingeline või sobivaid profiile on harva leida. Tänu väga tugevale kogemusele, mille oleme omandanud kaugtöös - mida nõuab Covid-19 -, on kohalik värbamine iseenesest jätkuvalt mõistlik, kuid tundub veelgi raskem praktiseerida.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(HEAOLU) Liikuvusega seotud küsimused</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Liikuvus on üks tööheaolu alustalasid. Lähenemisviisid võivad siis olla vägagi valdkonnaülesed. Jalgrattasõidu edendamine võib näiteks aidata parandada füüsilist vormi, stimuleerida keskendumist tööl ja isegi edendada kogukonnaelu.</p>


</div></div></div>
</div>`
        },
        tools: {
            heading: "Vahendid",
            html: `
            <div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-ad634c59-8c77-49de-b651-13e5f6d885f5">
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4fba2945-7e82-4e52-8e74-afa0bdfe1f8b"><p><strong><span style="font-size:1.5em;">Viige läbi liikuvuse analüüs</span></strong></p>

<p>Viige läbi oma ettevõtte süsinikdioksiidi heitkoguste hindamine: leidke ekspert, et selgitada välja kõige mõjukamad teemad (liikuvus, lennukiga reisimine, ehitus jne) ja keskenduge neile. Seejärel viige läbi liikuvuse skaneerimine, mis põhineb täpsetel ja ajakohastel andmetel.&nbsp;</p>

<p><strong>Näited:</strong></p>

<p>Mobiilsusmärgis, vahendid nutikamaks pendelrändeks</p>

<p><a target="_blank" href="https://mobilitylabel.com/">https://mobilitylabel.com</a>&nbsp;</p>

<p><strong>Mob Box </strong>koondab ja koostab massilisi toorandmeid. Alates ettevõttesisestest andmetest kuni väliste avatud lähtekoodiga andmeteni muudab see arvud selgelt mõistetavateks kaartideks ja intuitiivseteks diagrammideks. See aitab teil saada põhjaliku ülevaate oma töötajate liikuvusest ning hinnata transpordiliikide üldist jaotust ning analüüsida CO2-heidet ja sellega seotud kulusid.&nbsp;</p>

<p><a target="_blank" href="http://www.mob-box.eu/" target="_blank">www.mob-box.eu</a>&nbsp;</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-08185ad9-d286-407c-8269-b3bcecba9cc2"><p><strong><span style="font-size:1.5em;">Tutvuge saadaolevate avalike lahendustega</span></strong></p>

<ul>
\t<li>
\t<p>Prantsusmaal:&nbsp; <a target="_blank" href="https://www.ecologie.gouv.fr/sites/default/files/mobilite%20durable%20en%20entreprise.pdf" target="_blank">Forfait Mobilité Durable</a> &nbsp;(säästva liikuvuse pakett)- iga ettevõte võib toetada või rahastada oma töötajate kodu-töö sõite mahus kuni 800 eurot aastas, millele ei rakendu tulumaksudest ja sotsiaalkindlustusmaksetest, kui neid kasutatakse "rohelistes" transpordivahendites – ühistransport, elektrilised tõukerattad, jagatud elektriautod, autode ühiskasutus ...&nbsp;</p>
\t</li>
\t<li>
\t<p>Belgias: <strong>Eelarve mobiliseerimine</strong> (Mobility Budget) võimaldab ettevõtetel pakkuda töötajatele, kellel on ametiauto, eelarvet keskkonnasõbralikumate liikuvusvõimaluste jaoks —&nbsp;<a target="_blank" href="https://lebudgetmobilite.be/fr" target="_blank">https://lebudgetmobilite.be/fr</a>&nbsp;</p>
\t</li>
</ul>
</div><div class="h5p-column-ruler h5p-column-space-after"></div><div class="h5p-column-content not-an-ios-device h5p-image-hotspots" id="h5p-interactive-book-section-076e63a2-1daa-4256-bf2d-59c590c6cbe8"><div class="h5p-image-hotspots-container" style="width: 100%; font-size: 24px;"><img class="h5p-image-hotspots-background" src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/image-650a166eaf053.jpg" aria-hidden="true" style="width: 100%;"><button class="h5p-image-hotspot h5p-image-hotspot-info" role="button" tabindex="0" aria-haspopup="true" title="Hotspot 1" aria-label="Hotspot 1" style="top: 35.9893%; left: 25.2%; color: rgb(0, 88, 145);"></button><button class="h5p-image-hotspot h5p-image-hotspot-info" role="button" tabindex="0" aria-haspopup="true" title="Hotspot 2" aria-label="Hotspot 2" style="top: 84.2714%; left: 73.6%; color: rgb(0, 88, 145);"></button></div></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-eb98b054-5f2c-454c-9a29-05efff3cce27"><p><strong><span style="font-size:1.5em;">Individuaalne diagnostika / liikuvusuuringud </span></strong></p>

<p>Vaadake, kuidas inimesed oma töökohale reisivad, ja pakkuge neile individuaalsete turundusmeetmete abil alternatiive. On olemas terve diagnoosimise ja inimeste toetamise protsess, et näidata neile, et auto ei pruugi olla kõige tõhusam viis liikumiseks. Põhjalikum analüüs (jälgides inimesi mitu päeva) võib neile näidata, et on ka teisi viise, kuidas asju teha, vähem motoriseeritud, ökonoomsemad jne.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d7ebb14-1b14-4a0b-a034-151adcc5f3bb"><p><strong><span style="font-size:1.5em;">Mitmeliigilise transpordi marsruudiplatvormid</span></strong></p>

<p>Mitmeliigilise transpordi marsruudiplatvormid võivad näidata erinevaid võimalusi ja võimaldada inimestel mõista, et autoga reisimine on vähem tõhus kui teiste transpordiliikide puhul. Mõned neist vahenditest näitavad erinevate reisiliikidega seotud CO2-heidet.</p>

<p><strong>Näide:</strong></p>

<p>Skipr, kõik-ühes lahendus ettevõtete liikuvuse haldamiseks</p>

<p><a target="_blank" href="http://www.skipr.co/">www.skipr.co</a></p>

<p>Skipr võitleb ühe kasutajaga autode kasutamise vastu linnas. See pakub töötajatele rakendust, mis võimaldab neil näha, kuidas jõuda punktist A punkti B, kombineerides nende ümber olevaid erinevaid transpordivahendeid: ühistransport, jagatud sõidukid, elektrilised tõukerattad, rohelised sõidukid jaama jõudmiseks jne. See on kaaspiloot, mis tagab neile võimaluse oma autot mitte võtta. See võimaldab osta ühistranspordipileteid ettevõtte kontolt koos maksekaardiga, mis on seotud sama "liikuvuse eelarvega" alternatiivsete liikuvusliikide jaoks. Ettevõtte jaoks pakub see juhtimisplatvormi, mis teeb kahte asja: kehtestab ettevõtte juhised (määrab kindlaks, kellel on õigus millele) ja pakub aruandlusvahendit, mis annab täieliku ülevaate saavutatud säästust ja süsinikdioksiidi heitkoguste vähenemisest võrreldes traditsioonilise auto kasutamisega. Töötajatel on oma andmetega tabloovaade, kuid ettevõttel on ainult koondaruanded. Reisivõimaluste otsimisel saavad töötajad kolme infokildu: saabumisaeg, maksumus eurodes ja CO2 emissioon.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4bf3cd48-c7e8-4a5f-a5a3-4727c4478547"><p><strong><span style="font-size:1.5em;">Julgustage autode ühiskasutust </span></strong></p>

<p>Edu tingimused: tööaja paindlikkus on üks esimesi põhjuseid, miks kolleegide vahel autot ei jagata. Sama tööaja puudumine on piirav tegur, eriti kui isiklikud piirangud – laste pealevõtmine, asjaajamine jne. – lisatakse. Abiks võib olla projektijuhi olemasolu, kes laua ja pliiatsiga teekonnad kirja paneb ja need kokku sobitab. Kui jätate selle inimeste endi otsustada, on see keerulisem. Kui jätate selle rakenduse hooleks, on see ka keeruline. Digitaalsed tööriistad töötavad väga hästi pikkade vahemaade korral, kuid need ei ole nii tõhusad lühikeste vahemaade, korduvate ja igapäevase kasutamise korral.</p>

<p>Siiski on olemas platvormid, mis hõlbustavad autode ühiskasutust sobitamisvõimalustega, teabega maksusoodustuste kohta ja jälgimisvõimalustega suhtluse suunamiseks. Oluline on märkida, et autode ühiskasutus ei toimi hästi, kui töökoht on ühistranspordi hea pakkumise lähedal.&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e97d6b7b-85ca-4b8e-adc4-c549a30315f2"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650ae2ffdc5cc.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f2705860-d508-4b16-9089-9bba70468e72"><p><strong><span style="font-size:1.5em;">Transport  </span></strong></p>

<p>Mitmed ühistranspordi kasutamise suurima kasvuga organisatsioonid olid kasutusele võtnud tasuta spetsiaalse ettevõttebussi, mis oli ühendatud kohalike linnade ja/või kohalike rongi- ja bussijaamadega. Partnerlusse kuulusid organisatsioonid, kes suurendasid ühes uuringus objektile saabuvate bussiteenuste arvu muljetavaldava keskmiselt umbes 14 lisabussi võrra tipptunnil. Absoluutarvudest olulisemad tundusid asjakohaste ja kohandatud teenuste olemasolu: näiteks odavamad piletihinnad, läbiräägitud piletid, parem kohapealne taristu/teenuse kvaliteet ning uute bussipeatuste rahastamine platsil või selle lähedal.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c293a877-1d7a-44a0-8b05-524e78f4b318"><p><strong><span style="font-size:1.5em;">Jalgrattasõit  </span></strong></p>

<p>Tegurid, mis olid kõige tihedamalt seotud jalgrattasõidu kõrge tasemega, näisid olevat kvaliteetne juurdepääs väljaspool tegevuskohta või juurdepääsu parandamine väljaspool tegevuskohta; jalgratturitele parkimisvõimaluste suurendamine; ja jalgrattaremonditeenuse pakkumine. Tähelepanuväärne oli ka see, et enamik uuringus osalenud organisatsioone oli pakkunud dušše, vahetus-, kuivatamis- ja/või kapiruume; kokkulepitud allahindlused jalgrattaseadmetele.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-457e418e-7a81-4932-82ba-2fcbf1e21fcd"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650a17655699c.jpg" alt="" title="Cycling" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-801a1d35-3531-4738-807c-79f59dd9e601"><p><span style="font-size:1.5em;"><strong>Elektriautod? Jah, aga...&nbsp;</strong></span></p>

<p>Elektriauto ei ole lahendus liiklusummikutele. Auto on ikkagi auto, olgu see siis elektri-, vesiniku-, bensiini- või diiselmootor, see võtab teedel ruumi. See ei tekita CO2-heidet, vaid vähem: kõik sõltub sellest, kuidas elektrit toodeti. See ongi liikuvusega seotud probleemide raskus: ei ole olemas sellist asja nagu üks suurus sobib kõigile. Igaühel on oma eripära. Elektriautode puhul tuleb arvestada ka kulude ja laadimisega. Ikka veel on takistusi. Asjad muutuvad kiiresti, kuid imelahendust ei ole.</p>

<p>Paigaldage elektriautode laadimistaristu: need on vajalikud elektriautode kasutamise suurendamiseks.&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-07fc7d73-8673-4f28-8e07-2c87d26235f0"><p><strong><span style="font-size:1.5em;">Jalgrattaliising (elektrijalgratastele)</span></strong><br>
<strong>Näide:</strong></p>

<p>Jalgratta liisimine (Belgia)&nbsp;</p>

<p>Belgia tööandjana saate valida jalgrattaliisingu pakkumise mitmel viisil: tasuta või (osalise) sissemaksega jalgratta jaoks. Kõigi stsenaariumide korral on töötajatel umbes 40% suurune maksusoodustus.</p>

<p>Te ei maksa liisitud jalgratta eest mitterahalist hüvitist, sest kasutate jalgratast regulaarselt pendelrändeks. Aga saate töötajale välja maksta jalgrattatoetust. See motiveerib teie töötajaid jalgrattaga sõitma. Jalgrattaliisingut käsitlevates määrustes ei tehta vahet liisitud ja erajalgrattal. Tulemused? Paremas füüsilises vormis töötajad: 1-2 päeva vähem töölt puudumisi ja võimas panus rohelisse liikuvuskavasse.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6c224ae6-6652-45b9-a674-13597a5bb424"><p><strong><span style="font-size:1.5em;">Ökosõiduoskuse koolitus </span></strong></p>

<p><strong>Näide:</strong></p>

<p>Ökoloogiliselt vastutustundlik liiklemine teel olles nõuab enamat kui keskkonnasõbralikku sõidukit. Teil peab olema roheline sõidustiil. Ja see on midagi, mida teie juhid õpivad E-positiivses koolitusprogrammis, mis on ALD Automotive'i üheaastane koolitus.&nbsp;</p>

<p><strong>Kuidas see töötab?</strong></p>

<p>«Alustame sellest, mida teie juhid saavad teha; See paneb kaartidele nende praegused sõidustiilid ja sõidukikasutuse. Pärast seda läbivad nad e-õppe kursuse ja saavad koolitust – individuaalselt ja grupis. Juhendaja annab neile pidevalt personaalseid näpunäiteid ja punkte, millele nad peavad tähelepanu pöörama. Tulemus? Koolituse lõpus sõidavad kõik e-positiivse suhtumisega.»</p>

<p><strong>10 näpunäidet ökoloogiliseks sõiduks</strong></p>

<ol dir="ltr">
\t<li role="presentation">Minge sobival ajal kõrgemale käigule. Diiselmootoriga, kuskil 2000 pöörde ja bensiinimootoriga 2500 pöörde juures.</li>
\t<li role="presentation">Aeg liikuma hakata? Lükake gaasipedaal täielikult sisse, sest aeglane kiirendamine ei säästa kindlasti kütust.</li>
\t<li role="presentation">Hoidke enda ees olevast sõidukist piisavat vahemaad. Siis ei pea te pidevalt pidurdama ja kiirendama.</li>
\t<li role="presentation">Kas peate aeglustama või peatuma? Ärge vajutage koheselt pidurit, vaid vabastage gaasipedaal aegsasti. Mootoriga pidurdamisel ja sidurit kasutamata aeglustate keskkonnasõbralikumalt.</li>
\t<li role="presentation">Lülitage püsikiiruse hoidja sisse, et tagada ühtlane sõidukiirus ja seega keskkonnasõbralikkus.</li>
\t<li role="presentation">Kas olete täielikult  peatunud kauem kui 20 sekundit, siis lülitage seismise ajal mootor välja.</li>
\t<li role="presentation">Planeerige oma marsruut ette, et te ei tekitaks tarbetut läbisõitu.</li>
\t<li role="presentation">Sa ei vaja oma katuseboksi, jalgratast ega pagasihoidikut? Võtke see ära ja vähendage oma õhutakistust.</li>
\t<li role="presentation">Kontrollige regulaarselt rehvirõhku.</li>
\t<li role="presentation">Laske oma sõidukit õigeaegselt hooldada: ainult siis saate sõita säästlikult ja ohutult.</li>
</ol>



<p><em><strong>Allikas: ALD Automotive</strong></em></p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-0c1c9678-0a6c-445a-95ce-4a25e6395d09"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650a17e559c50.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3e08a77a-372e-478d-a71b-1e82dbff5942"><p><strong><span style="font-size:1.5em;">MaestroMobile </span></strong></p>

<p>MaestroMobile on tõsine mäng, mille on välja töötanud Espaces-Mobilités Belgias liikuvuse valdkonnas ja mille eesmärk on muuta käitumist eksperimenteerimise kaudu.</p>

<p>Lisateave:&nbsp;&nbsp;<a target="_blank" href="https://www.maestromobile.eu/">https://www.maestromobile.eu</a>&nbsp;</p>

<ul>
\t<li>Mobility Van koosneb liikuvusekspertidest, kes sõidavad avalikesse ruumidesse ja ettevõtetesse ning näitavad töötajatele kõiki liikuvuslahendusi praktilisel viisil (mikroliikuvusmasinate, jalgrataste, rakenduste testimine jne</li>
\t<li>The City Game on reaalse linna mäng, mis on korraldatud meeskonnatöö vormis ja võimaldab osalejatel katsetada erinevaid liikuvuslahendusi praktilisel viisil, samal ajal (taas)avastades linna, täites väljakutseid, vastates viktoriinidele jne.&nbsp;</li>
\t<li>Kasutades populaarsete mängude, näiteks Pokemon Go koode või linnarasside põhimõtteid, on City Game loonud oma universumi, mis on seotud liikuvusteenuste avastamisega.</li>
\t<li>Sarnaselt spordiprogrammidega pakub Mobility Passport liikuvusega seotud väljakutset, mis viib osalejad umbes kuu aja jooksul läbi mitme liikuvuse õppimise eri etappide, alates teenuse tundmaõppimisest kuni selle kasutamiseni.&nbsp;</li>
\t<li>Mobiilirakenduse kaudu saab osaleja oma oskuste kinnituse ja talle väljastatakse tunnistused, mis on tema liikumispassis templi kujul.</li>
\t<li>GO Mobility on hübriidplatvorm, mis pakub täielikku programmi juhendamiseks ja liikuvuse alternatiivide avastamiseks.</li>
\t<li>Critical MaaS on personaalne coaching, et katsetada uusi liikuvusi ja muuta oma liikumisviisi. Koos ekspertidega katsetate kõiki uusi teenuseid ja saate oma liikuvuse meistriks.&nbsp;</li>
</ul>


</div></div>`
        },
        inspiringInitiatives: {
            heading: "Inspireerivad algatused",
            html: `<div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Sulgege parkimiskohad</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Et takistada autode kasutamist töölkäimiseks, eemaldasid mõned ettevõtted ettevõtetes reserveeritud parkimiskohad. Kui kõigile töötajatele on sihtkohas peaaegu tagatud koht, kasutavad nad autot tööle sõitmiseks palju rohkem kui siis, kui nad seda ei teeks.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Jagatud liikuvuse hõlbustamine</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Brasiilia energiaettevõte töötas välja rakenduse, et hõlbustada jagatud liikuvust oma São Paulo asukohas: kasutajad sisestasid oma pendelrändemarsruudi ja rakendus ühendas andmed kõigi teiste sisenditega ning tuvastas sarnaseid marsruute järgivaid töötajad. Jagatud liikuvus kasvas 18% võrra ja säästis ettevõttele 300 000 USA dollarit aastas.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Julgustage jalgrattasõitu</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Uuenduslikumad parimad tavad juhtumiuuringutes hõlmasid jalgrataste parema turvalisuse tagamist (individuaalselt lukustatavad parkimisalused; politsei märgistamine või jalgrattakindlustusskeemide pakkumine); jalgrattavarustuse laenutus (akud, tuled, pumbad, lukud, kiivrid, autohoidikud, lasteistmed jne); kohaspetsiifilised tsüklikaardid; rahalised stiimulid ja tasuta tooted (näiteks kaitsevestide pakkumine).</p>

<p>Näited positiivsetest meetmetest: partnerlus kaubajalgrataste rentimiseks tarnimiseks või kasutamiseks tehniliste töötajate poolt, elektrijalgrataste laenutuskampaania, et võimaldada töötajatel neid katsetada, tööriist "Bike to work", et mõõta süsinikuneutraalsust ja osaleda erinevates väljakutsetes.&nbsp;</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Detsentraliseeritud ühised bürood</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Tsentraliseeritud jagatud kontoriruumide pilootprojekt, mis võimaldab neil, kes ei saa mõnel päeval kaugtööd teha, mitte tulla peakorterisse, kui nad elavad kaugel või kui see on nende jaoks liiga keeruline. Lahendus loodi läbi koostöötamise operaatori, tsentraliseeritud arveldussüsteemiga.</p>

<p><strong>Muutke rong konkurentsivõimelisemaks</strong></p>

<p>Ärireiside poliitika:&nbsp;</p>

<ul>
\t<li>
\t<p>Rahaliste vahendite eraldamine lennukist kõrgema hinna kompenseerimiseks, et muuta rongid konkurentsivõimeliseks.</p>
\t</li>
\t<li>
\t<p>Mitte lubada lennukisõitu, mis kestab vähem kui 2 tundi, kui sama tee saab läbida rongiga.</p>
\t</li>
</ul>
</div></div></div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-bd0d1aab-e9f3-4954-82d7-5ca803053822"><p><strong><span style="font-size:1.5em;"><h2>Maksa selle eest, mida tarbid" lähenemine</h2></span></strong></p>

<p>Maksan seda, mida tarbin" lähenemine ametiautodele: inimesed kontrollivad, mida nad tarbivad + kilomeetrite hüvitamine, mida pole tarbitud või üle tarbitud/ mõjutab liisingu hinda ja eraldatavat eelarvet.</p>


</div>
</div>`
        },
        recommendation: {
            heading: "Soovitused",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4052f6b2-2d6c-437b-bd67-4e316610281b">
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f3a1154d-cf21-4744-8f37-5b942ddae908"><p><span style="font-size:1.5em;"><strong>Meede: kuid parem on edasi liikuda ebatäiuslike mõõtmistega</strong></span></p>

<p>Täiuslikkuse ootamine on parim viis mitte midagi teha ..</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9c17bf2a-baba-43d2-a449-63d3a162ff6a"><p><span style="font-size:1.5em;"><strong>Valige ettevõttele asukoht, kuhu pääseb säästlike transpordivahenditega </strong></span></p>

<p>Ettevõtetel ei ole võimalik "värvata kohapealt" ega kehtestada oma töötajatele elamiskohti, välja arvatud juhul, kui nad pakuvad töölepingu raames majutust. Nad ei saa mõjutada kodu-töö distantsi, kuid nad saavad valida asukoha, mis on nende töötajatele ligipääsetav säästvamate vahenditega kui auto.&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-32b491be-187b-4b28-b25e-c93cdd367c4c"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650ae7da4ccaf.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42a6405c-6e2a-4f70-b7d8-501f3b5e3f66"><p><strong><span style="font-size:1.5em;">Planeerige, käitage, aktiveerige</span></strong></p>

<p>Esimene sammas on strateegia diagnoosimine ja määratlemine. Siis tuleb rakendamine mobiilsusteenuste, andmehalduse, finantsseire, infrastruktuuri jms abil. Enamik ettevõtteid peatub seal ja näeb siis, et see ei tööta. Miks? Lihtsalt sellepärast, et käitumismuutuste mõõde puudub.&nbsp;</p>

<p>Kõik ülaltoodud on vajalik, kuid mitte piisav. Sa pead "aktiveerima". Kui ettevõte ütleb teile, et on vastuvõtualale paigutanud kümme elektrijalgratast, kuid keegi neid ei kasuta, puudub see samm: aktiveerimine. Sa ei ole inimesi motiveerinud.&nbsp;</p>

<p>Nende motiveerimist saab teha mitmel viisil, näiteks korraldada preemiaga seotud mäng neile, kes neid kõige rohkem kasutavad.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-7565574d-621a-4f00-b920-c201f9d2f507"><p><strong><span style="font-size:1.5em;">Omandage arusaam pikaajalisest pendelrändest või väga pikkadest vahemaadest</span></strong></p>

<p>Näiteks: inimesel on kaks tööga seotud elukohta – kas teise elukohaga hotellis või isegi AirBnB-s. Seega, eksisteerivad topeltresidentsid, mis on seotud inimestega, kellel on kaks töökohta kahes eri linnas või kellel on väga sagedane tööga seotud reisimine jne.&nbsp;</p>

<p>Ometi väärib see kaalumist personalitöö seisukohast ja veelgi enam, kui kolitakse uuele töökohale, pidades silmas mõju pendelrändele ja vajalikku tuge.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-80c0adf5-cab3-4fbb-98bd-c46a956d2977"><p><strong><span style="font-size:1.5em;">Ärge keskenduge ainult eelarveaspektile</span></strong></p>

<p>Liikuvuse valiku loogika hõlmab ka kiirust (kui ühistranspordiga kulub 1,5 tundi, mitte 40 minutit autoga, ei avalda raha suurt mõju), aga ka paljusid muid kriteeriume, nagu mugavus (st kogu reisikogemus: rongil on suhteline eelis, kui saate sellega muid tegevusi teha - magada, &nbsp;lugeda, töötada jne. - kuid see eelis nullitakse, kui rong on rahvarohke ja teil pole näiteks istekohta), pilt või kogemus, mis teil on erinevatest transpordiliikidest, harjumustest, ohutusest jne. Mõelge liikuvuspoliitikale, mis integreerib kõik mõõtmed (maksumus, kiirus, mugavus jne), et alternatiivid oleksid atraktiivsed.&nbsp;</p>

<p>Minge 500-euroselt liikuvuseelarvelt üle eelarvele, mis on x kilo CO2, ja jaotage vastavalt töötajate liikuvuseelarve, võimaluse korral kolleegide vahelise kauplemise või võimalusega vahetada sääste puhkepäevade vastu.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6594bb16-8d4a-40a3-baae-b2c961991ac6"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650ae87fc0ae4.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-db56f5ca-9960-46ca-b07c-3aa08b24a38f"><p><strong><span style="font-size:1.5em;">Mitmeliigilise transpordi soodustamine </span></strong></p>

<p>Edendage ümbersuunamisi, et inimesed vähendaksid auto rolli oma teekonnal. Inimesed, kes elavad hõredalt asustatud piirkondades või linnalähedastes piirkondades võivad vähendada auto kasutamist, kui nad kasutavad lisaks autole ka teisi transpordiliike.</p>

<p>Minge üle pigem laiendatud kui vähenenud autole: jätkake ametiautoga, võib-olla väiksemaga, parema keskkonnatoimega, kuid andkem inimestele ka võimalus reisida teisiti. See ei nõua alati pakkumise suurendamist, vaid lihtsalt nõustumist ühistranspordile või muudele liikumisviisidele juurdepääsu hõlbustamisega.&nbsp;</p>

<p>Tänapäeval pakutakse hulgaliselt multimodaalseid lahendusi.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-2b595af0-7cfc-41da-b1a7-f849be29cfe8"><p><strong><span style="font-size:1.5em;">Ametiautod: pöörake tähelepanu...&nbsp;</span></strong></p>

<p>Kui ettevõte tunneb kohustust pakkuda ametiautosid (talentide ligimeelitamiseks, hoidmiseks, staatuseks, esindamiseks...), pöörake tähelepanu sõiduki tüübile, isegi kui me teame, et ka elektrisõiduki keskkonnamõju ei ole neutraalne.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e2b6406f-ba7a-45b7-83b8-84806274d67f"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650ae8f013a9d.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5821544b-625c-4c3a-a64e-9ddf0f3eeb2f"><p><strong><span style="font-size:1.5em;">Tegelege parkimiskohtadega</span></strong></p>

<p>Kui ettevõte pakub parkimiskohta, maksab ta teatud mõttes töötajatele auto kasutamise eest. Mida me vajame, on lihtsalt tasakaalustada eelised erinevate transpordiliikide vahel: kui sõidate jalgrattaga, ei võta te parkimiskohta. Kui võtate vähem ruumi, võib ettevõte anda teile teatud summa - näiteks 200 eurot -, sest säästate ettevõtte raha jalgrattaga tulles.</p>

<p>Jälgige ettevõtte juhtidele reserveeritud parkimiskohti, mis on üldiselt paremas asukohas. See hierarhiline eelis ei kajasta ettevõtte ambitsiooni vähem süsinikumahuka liikuvuse osas. Kui on olemas parkla, tuleks kontori sissepääsule kõige lähemal asuv ruum reserveerida ühiskasutavatele autodele.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d56820b5-cdac-4d65-b9c3-e7e19d058c6a"><p><strong><span style="font-size:1.5em;">Kasutage "sisesaadikuid"</span></strong></p>

<p>Töötage katseprojektidega, kus väikesed saadikute rühmad on valmis seda rolli täitma. Ärge muutke oma ettevõttes liikuvust revolutsiooniliselt. Alustage inimestest, kes on valmis, ja väikestest algatustest, mis idanevad ja kasvavad. Mobiliseerige juhtkond: kui tegevjuht ja tippjuhid istuvad ise jalgrataste selga ja teevad seda regulaarselt, on sellel organisatsioonile ja selle väärtustele suur mõju.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-96546409-df37-40a1-8000-e20cc49697d3"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650ae968de311.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3c1ff5d8-04bb-4246-b817-45823add9089"><p><strong><span style="font-size:1.5em;">Võtmesõna: koostöö</span></strong></p>

<p>Kiba-Janiaki ja Witkowski ülevaates märgitakse, et eduka liikuvuskava põhinäitaja on koostöö avaliku või erasektori sidusrühmade võrgustikuga, ning märgitakse, et see on kasvuhoonegaaside heitkoguste vähendamiseks eesmärgi saavutamiseks oluline tegur.</p>




</div></div>`,
        }
    }


}
