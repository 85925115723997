import React, {Component} from 'react';
import Quiz from "../quiz/quiz";
import FullWidthTabs from "../tabs/tabs";
import {roles} from "../quiz/quizHelper";
import {Step} from "./step1";

class Step2 extends Step {

    constructor(props) {
        super(props);
    }

    handleChange = (index) => {
        if (!this.props.skipQuiz) {
            return;
        }
        this.props.changeRole(Object.keys(roles)[index]);
    }

    getInitialValue = () => {
        const index = Object.keys(roles).findIndex(c => c == this.props.role)
        if (index > -1) {
            return index;
        }
        return 0
    }

    render() {
        return (
            <>
                <div className={'st_2_title'}>{window.getContent('st_2_title')}</div>
                <div className={'step_text'}>{window.getContent('st_2_p1')}</div>
                <div className={'step_text'}>{window.getContent('st_2_p2')}</div>
                <FullWidthTabs tabs={[
                    {
                        label: window.getContent('StrategicPartner'),
                        content: window.getContent('StrategicPartnerContent')
                    },
                    {
                        label: window.getContent('AdministrativeExpert'),
                        content: window.getContent('AdministrativeExpertContent')
                    },
                    {
                        label: window.getContent('EmployeeChampion'),
                        content: window.getContent('EmployeeChampionContent')
                    },
                    {
                        label: window.getContent('ChangeAgent'),
                        content: window.getContent('ChangeAgentContent')
                    },
                ]}
                               onChange={this.handleChange}
                               initial_value={this.getInitialValue()}
                />

                {!this.props.skipQuiz && <Quiz changeRole={this.props.changeRole} answersObj={this.props.answersObj}
                                               role={this.props.role}/>}
            </>
        );
    }
}

export default Step2;
