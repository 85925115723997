import React, {Component, useRef} from 'react';
import PropTypes from 'prop-types';
import './book.scss';

import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {SimpleAccordion} from "../../components/accordion/accordion";

class Book extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,

        }

        this.height = this.getContHeight();
        // this.width = props.width;
    }

    getContHeight = () => {
        if (window.innerHeight <= 700) {
            return 450;
        }
        if (window.innerHeight <= 768) {
            return 500;
        }
        if (window.innerHeight <= 830) {
            return 550;
        } else {
            return 600;
        }
    }

    onChange = (toolKit, key, value) => {
        this.props.toolkitOpenState[toolKit][key] = value
        this.forceUpdate();
    }

    buildContent = () => {
        return Object.keys(this.props.content).map((key, index) => {
            return <SimpleAccordion onChange={(value) => this.onChange(this.props.toolKit, key, value)}
                                    expanded={this.props.toolkitOpenState[this.props.toolKit][key]}
                                    content={<div dangerouslySetInnerHTML={{__html: this.props.content[key].html}}/>}
                                    label={this.props.content[key].heading} index={index}/>

        })
    }


    render() {
        return (this.buildContent());
    }
}

Book.propTypes = {};

export default Book;


// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
//
// const images = [
//     {
//         label: 'San Francisco – Oakland Bay Bridge, United States',
//         imgPath:
//             'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
//     },
//     {
//         label: 'Bird',
//         imgPath:
//             'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
//     },
//     {
//         label: 'Bali, Indonesia',
//         imgPath:
//             'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
//     },
//     {
//         label: 'Goč, Serbia',
//         imgPath:
//             'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
//     },
// ];
//
// function SwipeableTextMobileStepper({width = 400, height = 300, text, lastIDKey}) {
//     const theme = useTheme();
//     const [activeStep, setActiveStep] = React.useState(0);
//     const [contentLength, setContentLength] = React.useState(100);
//     const [disabledNext, setDisabledNext] = React.useState(false);
//
//     const newImages = Array(contentLength).fill(undefined).map(c => text);
//     const maxSteps = newImages.length;
//
//
//     const handleNext = () => {
//         setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     };
//
//     const handleBack = () => {
//         setActiveStep((prevActiveStep) => prevActiveStep - 1);
//     };
//
//     const handleStepChange = (step) => {
//         setActiveStep(step);
//     };
//
//     React.useEffect(() => {
//         let lastRect = document.getElementById(`last_${lastIDKey}`).getBoundingClientRect()
//         let boxRect = document.getElementById(`box_${lastIDKey}`).getBoundingClientRect();
//         let newContentLength = Math.ceil(((lastRect.left - boxRect.left) / width) + 1)
//         if (newContentLength !== contentLength) {
//             setContentLength(newContentLength);
//         }
//     }, [])
//
//     const padding = 0;
//
//     const newWidth = width - 2 * padding;
//
//     return (
//         <Box id={`box_${lastIDKey}`} sx={{width: width, justifySelf: 'center', backgroundColor: 'white'}}>
//             <SwipeableViews
//                 axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
//                 index={activeStep}
//                 onChangeIndex={handleStepChange}
//                 enableMouseEvents
//             >
//                 {newImages.map((image, index) => (
//                     <div key={'step' + index} className={'box_container'}>
//                         {Math.abs(activeStep - index) <= 2 ? (
//                             <div style={{
//                                 height: `${height}px`,
//                                 display: 'block',
//                                 maxWidth: `${newWidth}px`,
//                                 overflow: 'hidden',
//                                 width: '100%',
//                             }}>
//                                 <div style={{
//                                     left: `-${index * newWidth}px`,
//                                     columnWidth: `${newWidth}px`,
//                                     width: `${newWidth}px`,
//                                     // maxHeight: `${height}px`,
//                                     // width: `1600px`
//                                     height: `${height}px`,
//                                 }} className={'text_container'}
//                                     // dangerouslySetInnerHTML={{__html: image}}
//                                 >
//                                     {image}
//                                 </div>
//                             </div>
//                         ) : null}
//                     </div>
//                 ))}
//             </SwipeableViews>
//             <MobileStepper
//                 variant={'progress'}
//                 steps={maxSteps}
//                 position="static"
//                 activeStep={activeStep}
//                 nextButton={
//                     <Button
//                         size="small"
//                         onClick={handleNext}
//                         disabled={activeStep === maxSteps - 1 || disabledNext}
//                     >
//                         {window.getContent("nextBook")}
//                         {theme.direction === 'rtl' ? (
//                             <KeyboardArrowLeft/>
//                         ) : (
//                             <KeyboardArrowRight/>
//                         )}
//                     </Button>
//                 }
//                 backButton={
//                     <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
//                         {theme.direction === 'rtl' ? (
//                             <KeyboardArrowRight/>
//                         ) : (
//                             <KeyboardArrowLeft/>
//                         )}
//                         {window.getContent("backBook")}
//                     </Button>
//                 }
//             />
//         </Box>
//     );
// }
//
// const CrazyButton = (props) => {
//     const [disabled, setDisabled] = React.useState(false);
//
//     return <Button {...props} onClick={(e) => {
//         setDisabled(true);
//         props.onClick(e)
//         setTimeout(() => setDisabled(false), 500)
//     }} disabled={disabled || props.disabled}/>
// }
//
//
// SwipeableTextMobileStepper.propTypes = {
//     width: PropTypes.number,
//     height: PropTypes.number,
//     text: PropTypes.any,
//     lastIDKey: PropTypes.any
// }
//
// SwipeableTextMobileStepper.defaultProps = {width: 400, height: 300}
//
// const text = "CEO/Top Management\n" +
//     "Define a Vision  \n" +
//     "\n" +
//     "CEOs must recognize the leadership role they personally need to play to embed sustainability deep and wide in their organizations. The CEO and Executive Committee have to define a vision, establish a clear line of ethics and values in terms of sustainability, and articulate ambitions for sustainability and carbon reduction. What is the commitment, the direction the company is taking? \n" +
//     "\n" +
//     "This will help managers and employees connect sustainability to the company's mission, and then to their own daily work. \n" +
//     "\n" +
//     "Exemplarity / Walk the Talk \n" +
//     "\n" +
//     "Research shows that exemplarity is highly critical and has a strong influence on employees’ change acceptance. It is essential to walk the talk and to provide employees with clear examples of what is expected and valued. Exemplarity can be declined at diverse levels. \n" +
//     "Facilities\n" +
//     "Combination of Responsibilities \n" +
//     "\n" +
//     "It is no coincidence that we are now seeing more and more HR Directors who are also in charge of facilities, with the aim of integrating a reflection on occupancy rates, the dimensioning, and optimisation of spaces, the adaptation of heating technologies, etc.\n" +
//     "\n" +
//     "Close Cooperation \n" +
//     "\n" +
//     "If Human Resources and Facilities remain two separate departments, then it is important to establish close cooperation. Facilities can help to reduce energy consumption through investments in high-efficiency installations, low-polluting alternative energies such as photovoltaic panels e.g. \n" +
//     "\n" +
//     "They can manage heating according to the people on-site and/or explore with HR regrouping people on the site or floor/close site or floor with few people. \n" +
//     "\n" +
//     "Prospective \n" +
//     "\n" +
//     "Study future real estate needs, both on the side of employees and companies: the latter may be encouraged to bring together distributed local sites into a more central location, which naturally implies a longer commute. \n" +
//     "\n" +
//     "Coupled with the potential for urban drift, these practices will need to be analysed for their impact on air quality, actual mobility variations, and leisure mobility. It is important to analyse trends in the use of third places (which could maintain residual travel and multiply real estate) and housing extensions: remote work could lead to the acquisition of larger dwellings to have a dedicated place for telework.  \n" +
//     "Communication\n" +
//     "Communication Strategy \n" +
//     "\n" +
//     "To really change behaviors related to work organisation, it is important that companies set up a communication strategy and that the effort is deployed on a regular basis. The message must be hammered continuously.\n" +
//     "Sustainability Manager/Team\n" +
//     "The Sustainability manager, or team is a key actor to involve in NWoW Projects aiming carbon emission reduction. \n" +
//     "\n" +
//     "Experience sharing is key and the company could initiate or participate in a network of Sustainability managers in a sector, industrial park, or geographic area to share knowledge and good practices. \n" +
//     "Trade Unions\n" +
//     "If you develop a NWoW or Hybrid Work project with a top down, without involving trade unions, without making sure you have ambassadors, and without testing things on the ground, it won't work. You also have to be prepared to go back when you make a mistake and accept to review your plans.";

