import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {languageLongNames, languages} from "../../Enums/Enums";
import LanguageIcon from '@mui/icons-material/Language';
import {IconButton} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ClearIcon from "@mui/icons-material/Clear";

export default function RightDrawer() {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const changeLanguage = (language) => {
        let location = window.location.href;
        let newLocation = '';

        Object.values(languages).forEach(c => {
            if (location.includes("/" + c)) {
                newLocation = location.replace('/' + c, '/' + language)
            }
        })

        if (!newLocation) {
            newLocation = location + '/' + language;
        }

        window.location.href = newLocation;
    }

    const list = (anchor) => (
        <Box
            sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <div className={'btns_container'}>
                    <IconButton><ClearIcon/></IconButton>
                </div>
                <Divider/>
                {Object.keys(languageLongNames).map((key, index) => (
                    <>
                        <ListItem key={key} disablePadding>
                            <ListItemButton>
                                {/*<ListItemIcon>*/}
                                {/*    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}*/}
                                {/*</ListItemIcon>*/}
                                <ListItemText primary={languageLongNames[key]} onClick={() => changeLanguage(key)}/>
                            </ListItemButton>
                        </ListItem>
                        <Divider/>
                    </>
                ))}
            </List>
            {/*<Divider />*/}
        </Box>
    );

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}><LanguageIcon/></Button>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}
