import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {buttonsConfig} from "./nav";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './side_nav.scss';
import ClearIcon from '@mui/icons-material/Clear';
import {IconButton} from "@mui/material";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import LanguageIcon from '@mui/icons-material/Language';

export default function LeftDrawer() {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [childrenOpen, setChildrenOpen] = React.useState(false);

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        if (!open) {
            setChildrenOpen(false)
        }

        setState({...state, [anchor]: open});
    };

    const getContent = () => {
        if (childrenOpen) {
            return childrenOpen;
        }
        return buttonsConfig();
    }

    const list = (anchor) => (
        <Box
            sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <div className={'btns_container'}>
                    {childrenOpen ? <IconButton onClick={(e) => {
                        e.stopPropagation()
                        setChildrenOpen(false);
                    }}><NavigateBeforeIcon/></IconButton> : <div/>}

                    <IconButton><ClearIcon/></IconButton>
                </div>
                <Divider/>
                {getContent().map((button, index) => (
                    <>

                        <ListItem key={button.label} disablePadding>
                            <ListItemButton onClick={(e) => {
                                if (button.children) {
                                    e.stopPropagation()
                                    setChildrenOpen(button.children)
                                    return;
                                }
                                if (button.route) {
                                    window.navigateTo(button.route + `/${window.language}`)
                                } else {
                                    window.location.href = button.link
                                }
                            }}>
                                <ListItemText sx={{justifyContent: 'center', display: 'flex'}} primary={button.label}/>
                                {button.children && <ListItemIcon>
                                    <NavigateNextIcon/>
                                </ListItemIcon>}
                            </ListItemButton>
                        </ListItem>
                        <Divider/>
                    </>
                ))}
            </List>
        </Box>
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button style={{height: '36px'}} className={'side-nav-anchor'} onClick={toggleDrawer(anchor, true)}>
                        <FormatAlignJustifyIcon/>
                    </Button>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}
