import React, {Component} from 'react';
import './blue_card.scss';
import {TreeSVG1, TreeSVG2, TreeSVG3} from "../icons/svgIcons";
import PropTypes from 'prop-types';

export const svgPositioningTypes = {
    type1: 'type1',
    type2: 'type2',
    type3: 'type3',
}

class BlueCard extends Component {

    getClassName = () => {
        if (!this.props.treePosition) {
            return ''
        }
        return ' visible ' + this.props.treePosition;
    }

    getSVG = () => {
        switch (this.props.treePosition) {
            case svgPositioningTypes.type1:
                return <TreeSVG1/>;
            case svgPositioningTypes.type2:
                return <TreeSVG2/>;
            case svgPositioningTypes.type3:
                return <TreeSVG3/>;
        }
    }

    render() {
        return (
            <div className={`blue_card`}>
                <div className={`svg_cont${this.getClassName()}`}>
                    {this.getSVG()}
                </div>
                {this.props.children}
            </div>
        );
    }
}


BlueCard.propTypes = {
    treePosition: PropTypes.string
};

export default BlueCard;
