import {roles} from "../components/quiz/quizHelper";

export default {
    "a1": "Jah",
    "a2": "Ei",
    "a3": "Ma ei ole kindel",
    "a4": "Jah",
    "a5": "Teatud määral",
    "a6": "Suurel määral",
    "a7": "Mõnevõrra",
    "a8": "Mitte eriti",
    "a9": "Üldse mitte",
    "a10": "Personalijuht või Personaliosakonna direktor",
    "a11": "Personaliosakonna töötaja (personalispetsialist või personalipartner)",
    "a12": "Personaliassistent",
    "a13": "Personalikonsultant",
    "a14": "Personalijuhtimise praktikant",
    "a15": "Mikroettevõte",
    "a16": "Väikeettevõte",
    "a17": "Keskmise suurusega ettevõte",
    "a18": "Suurettevõte",
    "a19": "Avalik sektor",
    "a20": "Eraettevõte",
    "a21": "Valitsusväline organisatsioon / mittetulundusühing",
    "a22": "Suurlinn /metropol",
    "a23": "Linn/kesklinna piirkond",
    "a24": "Äärelinn",
    "a25": "Maapiirkond",
    "a26": "Ma ei ole selles kindel => testi oma teadmisi (vt Lisa 4)",
    "a27": "1-5",
    "a28": "6-10",
    "a29": "11-20",
    "a30": "21-50",
    "a31": ">50",
    "q2.2": "Milline on Sinu roll personalitöötajana ettevõttes / organisatsioonis?",
    "q2.3": "Kas kuulud ettevõtte /organisatsiooni juhtkonda?",
    "q2.4": "Kas Sinu hinnangul on Sul strateegiline roll ettevõtte / organisatsiooni personalijuhina?",
    "q2.5": "Mil määral Sinu töö personalivaldonnas panustab ettevõtte ärieesmärkide saavutamisse (organisatsiooni strateegilisse planeerimisse)?",
    "q2.6": "Mil määral on Sinu töö personalivaldkonnas seotud töösuhete administreerimisega (lepingute sõlmimine ja muudatused, arvepidamine jms)?",
    "q2.7": "Mil määral on Sinu töö personalivaldkonnas seotud töö tasustamise ja hüvitamise administreerimisega jt rutiinsete personalitöö tegevustega?",
    "q2.8": "Mil määral Sinu töö keskendub sellistele personalitöö tegevustele nagu värbamine, töötajate koolitamine ja arendamine töösoorituse parandamiseks jms?",
    "q2.9": "Oma igapäevatöös personalitöötajana, kuivõrd sa oled nö “sillaks” ettevõtte ja töötajate vahel (s.t. kuulad töötajate muresid ja toetad neid lahenduste leidmisel)?",
    "q2.10": "Mil määral Sa keskendud personalitöötajana positiivse töökeskkonna loomisse, töötajate kaasatuse ja töörahulolu suurendamisse?",
    "q2.11": "Mil määral on Sul enda hinnangul võimalusi mõjutada/muuta organisatsiooni kultuuri (nt kaasatuse, õigluse, võrdse kohtlemise suurendamine)?",
    "q2.12": "Mil määral Sinu igapäevatöö seisneb muudatuste propageerimises ja algatamises ning töötajate ja meeskondade juhtimises (kuidas üleminekutega toime tulla)?",
    "q2.13": "Mil määral Sinu igapäevatöö seisneb organisatsiooniliste muudatuste juhtimises ja hõlbustamises nagu nt orgasatsioonikultuuri muutmine, ettevõtte ümberstruktureerimine?",
    "q2.14": "Kuivõrd Sinu igapäevatöö sesineb töötajate ja juhtide muudatusi toetava mõttemaailma kujundamises?",
    "q1.1": "Kui suur on ettevõte / organisatsioon, kus Sa töötad?",
    "q1.3": "Kas see on eraettevõte, avaliku sektori organisatsioon, või mittetulundusühing (valitsusväline organisatsioon)?",
    "q1.4": "Kus Su ettevõte / organisatsioon asub?",
    "q1.5": "Kas Sinu ettevõttes / organisatsioonis on välja töötatud jätkusuutlikkuse poliitika, mis toetab kliimaeesmärkide saavutamist?",
    "q2.1": "Kui palju inimesi Sinu ettevõtte / organisatsiooni personaliosakonnas töötab?",
    "st_3_th1": "NWoW/Hübriid",
    "st_3_th2": "Liikuvus",
    "st_3_th3": "Jätkusuutlik IT",
    "st_3_tc0_0": "Kaasatud sidusrühmad",
    "st_3_tc0_1": "Sisemised või välised osapooled ja nende roll heitkoguste vähendamisele kaasaaitamisel.",
    "st_3_tc1_0": "Kultuur",
    "st_3_tc1_1": "Jätkusuutliku kultuuri loomine koostöös töötajatega.",
    "st_3_tc2_0": "Vahendid",
    "st_3_tc2_1": "Konkreetsed vahendid heitkoguste vähendamise toetamiseks.",
    "st_3_tc3_0": "Personalitöö protsessid",
    "st_3_tc3_1": "Personalitöö protsesside roll heitkoguste vähendamisele kaasaaitamisel.",
    "st_3_tc4_0": "Inspireerivad algatused",
    "st_3_tc4_1": "Tõhusad tegevused või tavad, mida ettevõtted on juba teinud.",
    "st_3_tc5_0": "Soovituslikud tegevused",
    "st_3_tc5_1": "Juhised, mis aitavad personalijuhil saavutada eesmärkide seadmisel järjepidevust.",
    "selectValueOption": "-- Vali väärtus --",
    "noInformation": "Teie valiku põhjal pole selles jaotises teavet",
    "hybridStakeHolders": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<p dir="ltr">Kuigi personalijuhtimise ja töökorralduse eest vastutab personalijuht, siis reeglina vastutab ta ainult osaliselt uute tööviisidega seotud küsimuste eest.</p>
<p>Selleks, et aidata kaasa ettevõtte süsinikdioksiidi heitkoguste vähendamisele, peab personalijuht tegema tihedat koostööd erinevate sidusrühmadega, kellest projektipartnerid on määratlenud olulisteks järgmised:</p>
</div>`,
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2 dir="ltr">Tegevjuht / juhtkond&nbsp;</h2>

<h3 dir="ltr">Visiooni määratlemine</h3>

<p dir="ltr">Tegevjuhid peavad tunnistama, et nad peavad isiklikult täitma juhtivat rolli, et juurutada jätkusuutlikkust sügavuti ja laiaulatuslikult oma organisatsioonis. Tegevjuht ja juhtkond peavad määratlema visiooni, kehtestama selge eetika ja väärtused jätkusuutlikkuse osas ning väljendama jätkusuutlikkuse ja süsinikdioksiidi heitkoguste vähendamise alaseid ambitsioone. Milline on ettevõtte kohustusenda jaoks ja suund, mille ta võtab?</p>

<p>Sõnastatud visioon aitab juhtidel ja töötajatel luua seoseid jätkusuutlikkuse ja ettevõtte missiooni vahel ning seejärel ka oma igapäevase tööga.</p>

<h3>Eeskuju / käituge oma sõnade järgi</h3>

<p dir="ltr">Uuringud näitavad, et isiklik eeskuju on väga oluline ja sellel on tugev mõju töötajate poolsele muutuste aktsepteerimisele. Oluline on näidata, mida oodatakse ja mida hinnatakse, ning anda töötajatele selgeid näiteid selle kohta, mida hinnatakse ja neilt oodatakse.</p>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/image-do44gt28.png",
        },
        // {
        //     "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
        //     "heading": "Kommunikatsioon",
        //     "description": "Kommunikatsioonistrateegia \n" +
        //         "Selleks, et töökorraldusega seotud käitumist tõepoolest muuta, on oluline, et ettevõtted kehtestaksid kommunikatsioonistrateegia ja et vastavaid jõupingutusi tehtaks regulaarselt. Sõnumit tuleb pidevalt levitada.\n"
        // },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Ametiühingud&nbsp;</h2>

<p>Kui te rakendate hübriidtööprojekti ülalt alla (top-down), ilma ametiühinguid kaasamata, ilma et teil oleks nn. “saadikuid” ehk toetajaid ja viite läbi seda orgatsioonilist muutust ilma piloteerimata, siis ei pruugi seda tüüpi muutused tööle hakata. Samuti peate olema valmis tagasi pöörduma, kui teete vea, ja nõustuma oma plaanide läbivaatamisega.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Jätkussutlikkuse juht / meeskond&nbsp;</h2>

<p>Jätkusuutlikkuse juht või meeskond on peamine osaline, keda tuleb kaasata NWoW projektidesse, mille eesmärk on vähendada süsinikdioksiidi heitkoguseid.&nbsp;</p>

<p>Kogemuste jagamine on võtmetähtsusega ning ettevõte võiks luua sektori, tööstuspargi või geograafilise piirkonna jätkusuutlikkuse eest vastutavate juhtide võrgustiku või osaleda selles, et jagada teadmisi ja häid tavasid.</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Hooned ja rajatised</h2>

<h3 dir="ltr">Kohustuste kombinatsioon&nbsp;</h3>

<p dir="ltr">Pole juhus, et viimasel ajal näeme üha enam personalijuhte, kes vastutavad ka hoonete eest, eesmärgiga integreerida jätkusuutlikkusele orienteeritud ümbermõtestamine ka hoonete kasutusmäära, ruumide dimensioneerimisse ja optimeerimisse, küttetehnoloogiate kohandamisse jms.</p>

<h3 dir="ltr">Tihe koostöö</h3>

<p dir="ltr">Juhul, kui personaliosakond ja hooned jäävad kaheks eraldi osakonnaks, siis on oluline luua tihe koostöö. Õige ruumide valik ja nende olukord aitab vähendada energiatarbimist. Seda on võimaliku saavutada läbi investeeringute suure energiatõhususega seadmetesse ja vähemsaastavatesse alternatiivsetesse energiaallikatesse – nagu näiteks fotogalvaanilised paneelid.</p>

<p dir="ltr">Hoonete ja ruumide temperatuuri peaks saama juhtida vastavalt kohal viibivatele inimestele ja personaliosakond peaks uurima töötajate ümbergrupeerimise võimalusi ruumides või korrustel, kui töötajate arv on vähenenud.</p>

<h3>Tulevikuvajadused</h3>

<p dir="ltr">On vajalik uurida tulevasi kinnisvaravajadusi nii töötajate kui ka ettevõtte enda tulevikuvajadusi arvestades. Kuigi ettevõtte hajutatud kohalikke tegevuskohti võib koondada kesksemasse asukohta, tuleb arvestada, et see võib kaasa tuua töötajate pikema pendelrände.</p>

<p dir="ltr">Koos võimalike linnades toimuvate liikumistega tuleb analüüsida nende mõju õhukvaliteedile, tegelikule liikuvusele ja elanike vaba aja veetmisele. Oluline on analüüsida suundumusi kolmandate kohtade (st töötaja ei tee tööd ei kontoris ega kodus, vaid üürib-kasutab selleks eraldi pinda) kasutamisel, mis võivad tekitada jääkreisi ja mitmekordistada kinnisvara kasutust ja hindu. Lisaks võib tekkida eluruumide laiendamise vajadus: kaugtöö võib kaasa tuua suuremate eluruumide soetamise, et tagada elukohas võimalus spetsiaalse koha või ruumi jaoks, kust tehakse kaugtööd.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<p dir="ltr">Selleks, et töökorraldusega seotud käitumist tõepoolest muuta, on oluline, et ettevõtted kehtestaksid kommunikatsioonistrateegia ja et vastavaid jõupingutusi tehtaks regulaarselt. Sõnumit tuleb pidevalt levitada.</p>`
        }
    ],
    "mobilityStakeHolders": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<p>Personalijuht vastutab ainult osaliselt küsimuste eest, mis on seotud töötajate liikuvusega tööle ja töölt koju.</p>
<p>Selleks, et aidata kaasa ettevõtte süsinikuheitmete vähendamisele, peab personalijuht tegema tihedat koostööd erinevate sidusrühmadega, kellest peamised on projektipartnerid määratlenud järgmiselt:</p>
</div>`,
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": " ",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Tegevjuht/juhtkond</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Ettevõtte asukoht</h3>

<p>Ettevõtte asukoht on määratletud kui koht või struktuur, mida ettevõte kasutab oma tegevuse juhtimiseks. Kui me vaatame ettevõtete asukohavaliku põhjendusi, ei ole ükski kriteerium tühine ja on üldiselt üks olulistest faktoritest. Siin peame silmas nn tööhõive kogumit, milles ettevõte end positsioneerib.</p>

<p>Kui teie organisatsioonil on kolimisplaanid, uurite kas "jääda või minna" otsust, kaalute mitme kontori ühendamist või satelliitkontori avamist, on oluline analüüsida sellega seotud mõjusid ja töötajate pendelrände võimalusi. Valige asukoht uus rongi- või bussijaama lähedal ja juurdepääsuga teistele ühistranspordi liikidele. </p>

<h3>Positiivne lähenemine</h3>

<p>Üks peamisi teemasid, mille kallal töötada, on panna juhtkond ja ettevõte mõistma, mida võidakse saada lisaks, kui analüüsitakse tööprotsessi ning arvestades sealjuures kogemusena hommikul kodust tulekut ja õhtul kojuminekut ning proovides võtta liikuvust tööalase tegevuse lahutamatu osana. Kui see on nii, on lihtsam mõista, miks ettevõte peab sellele aega kulutama, miks ta peab sellesse energiat ja ressursse panema või võtma tarvitusele teatud meetmeid. Oleks kasulik eemalduda halduspiirangutest, mis on seotud kohustusega koostada liikuvuskava, ja võtta selle asemel positiivne lähenemisviis.</p>

<h3>Võimalused ja... piirangud</h3>

<p>Vähem süsihappegaasiheiteid tekitava liikuvuse kui protsessi toetamine ei ole kunagi ettevõtte põhitegevus - ja see ei muutu selleks ka edaspidi. Paindlikkus piirangute osas, mida saab kehtestada, ei ole paraku väga suur. Esimene samm on seega kehtestada piirangute tase, mida ettevõte on valmis kehtestama.</p>

<h3>Näidake eeskuju</h3>

<p>Kui tehakse algatusi süsinikdioksiidisõbralikuma liikuvuse kasuks, on oluline, et juhtkond kasutaks ja edendaks neid läbi enda eeskuju. Uuringud näitavad, et plaanitavate edusammude võti on antud plaani eest seisev juhtfiguur.</p>
</div></div></div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/image-Yp7OiIAH.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Rahastamine</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Rahasummad, mida ettevõtted on valmis kulutama liikuvusküsimustele strateegilisel tasandil, on tavaliselt äärmiselt väikesed võrreldes nendega, mida nad kulutavad maksude optimeerimisele või personaliteenuste pakkujatele. Teadlikkust tõstvate meetmete puhul hinnati seda kaheksa kuni kümme korda vähemaks võrreldes eeltoodud näidetega. Nii madalate ambitsioonidega on võimatu tegelikult midagi muuta. Kahjuks näeme, et liikuvuskavadega töötamist tajuvad ettevõtted endiselt kui piirangut, mitte kui tegeliku võimalust oma keskkonnamõju muuta. Siiski võib liikuvusprojektide investeeringutasuvus olla otsene ja väga märkimisväärne.</p>

<p>Personalijuhtidel on võimalik selles valdkonnas edusamme teha, kui nad töötavad välja reaalse ärimudeli koos konkreetsete arvudega.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Teavitamine</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Kommunikatsioonistrateegia</h3>

<p>Liikuvusega seotud käitumise tõeliseks muutmiseks on oluline, et ettevõtted töötaksid välja kommunikatsioonistrateegia ja et jõupingutusi tehtaks regulaarselt. Sõnumit tuleb pidevalt korrata. Näiteks mäng on siin suurepärane võimalus, mis muudab selle sõnumi kergemini edastavaks ja pakub ka osalejatele auhindu. Töökoha liikuvuskava jaoks logo ja/või kaubamärgi loomine võib aidata tähelepanu köita ja säilitada.</p>

<ul>
\t<li>Belgias SWIFTis korraldati "heategevuslik väljakutse", et julgustada jalgrattasõitu liikuvusnädalatel: iga läbitud kilomeetri eest annetati 1 euro jalgrattaspordiga seotud ühendustele. See töötajaid kaasav tegevus oli väga edukas, kuna see ühendas nii töötajate välise kui ka sisemise motivatsiooni.</li>
\t<li>Belgias Service Public de Wallonie's korraldati "Round the World Cycling Tour": osalejatel paluti sisestada oma tööga seotud rattaretked veebivormi kaudu osana kollektiivsest väljakutsest läbida 26 nädala jooksul kokku 40 075 kilomeetrit.Sihtkohtadesse jõuti ühiselt, tuuri lõpus räägiti ühiseid nalju, pakuti maiuspalu ja osalejad said ka diplomid, mis kajastasid antud ettevõtmises ka tasemete ja märkide dünaamikat, pakkudes läbi mängu samal ajal ka osalejatele ka ettearvamatusi ja arendasid nende loovust.</li>
</ul>

<p><strong>Allikas: UWE mobiilsusüksus</strong></p>

<h3>Keskenduge õigele asjale</h3>

<p>Liikuvusele ei tohiks läheneda otse ja ainult keskkonna vaatenurgast. Keskkonnamõõde on positiivne tulemus, pigem eesmärk, mille poole peame püüdlema. Kuid see ei ole suhtlusvahend: inimesed ei pruugi muutuda keskkonnakaalutlustel, vaid pigem mugavuse, tõhususe, kogukonna huvides ... Seega, inimkäitumisega seotud põhjused on need, mis aitavad kaasa eesmärkide saavutamisel.</p>

<p>Töötajad üldiselt ei muuda oma käitumist sellepärast, et see vähendab ettevõtte süsinikujalajälge, vaid seetõttu, et neil on uued võimalused oma pere paremaks juhtimiseks, oma teekonna meeldivamaks muutmiseks ... ja lõppkokkuvõttes on neil planeedile positiivne mõju.</p>


</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Liikuvusjuht</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Määrake liikuvusjuht</h3>

<p>Liikuvusjuht on sisemine isik, kes juhib liikuvusprojekti ja annab ideaaljuhul aru juhtkonnale, sest volitused on valdkonnaülesed: kommunikatsioon, personalijuhtimine, rajatised, liikuvusvahendid jne. Isik võib olla pärit mis tahes osakonnast - ja miks mitte ka personaliosakonnast -, kuid tal peab olema otsene kontakt juhtkonna ja töötajate esindajatega. Seejärel tuleb seda isikut liikuvusküsimustes koolitada.</p>

<h3>Liikuvusjuhist ei piisa</h3>

<p>Liikuvus jääb üksikisiku vabaduse väljenduseks väljaspool mis tahes tööalaseid piiranguid. Individuaalse vabaduse seisukohast on näiteks auto normatiivne roll endiselt väga oluline. Seetõttu on vaja teha palju veenvat ja harivat teavitustööd. Personalitöö arendus võib toimuda tööga seotud reiside puhul (näiteks transpordisektoris või kui ettevõttes töötavad liikuvad töötajad), kuid see on raskem kodu-töö pendelrändel, mis osaliselt puudutab erasfääri. Liikuvusega seotud valikud sõltuvad siis palju rohkem inimeste heast tahtest või sellest, mida inimesed on valmis tegema, teadlikult või alateadlikult.<br>
</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Andmehaldur</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Muutustega kaasaminemine tähendab, et ettevõtted peavad investeerima oma vahendeid – rahalisi, inim- ja ajalisi ressursse –, et põhjalikult uurida oma praeguseid liikuvustavasid ja seda, kuidas individuaalsed muutujad (vanus, sugu, tööpositsioon, pendelrändemarsruudid jne) mõjutavad nende töötajate eelistatud transpordiliiki. Teabe kogumine küsitluste, töötajate, tarnijate ja klientide intervjuude ning muude tööriistade kaudu on sobiva vastuse kujundamisel ülioluline. Ettevõtetel on juba tohutul hulgal andmeid ja kui nad soovivad, on neil sageli käepärast vajalikud elemendid, et lihtsal ja odaval viisil edasi liikuda.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Ametiühingud</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Kui koostate liikuvuskavasid ülalt alla, ametiühinguid kaasamata, nende esindajaid kaasamata ja kohapeal asju katsetamata, siis ei pruugi need kavad toimida. Liikuvus on ettevõtetes üks tundlikumaid teemasid. Ja kui kahju on tehtud, on äärmiselt raske positiivsesse dünaamikasse tagasi pääseda. Samuti peate olema valmis tagasi minema, kui teete vea, ja nõustuma oma plaanide ülevaatamisega.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Riigiasutused</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Eduka CO₂-neutraalse liikuvuskava koostamisel on oluline, et kohalikud omavalitsused ja ametlikud institutsioonid oleksid kaasatud sellesse maksimaalselt tõhusalt. Kohalik planeerimine on ülioluline. Ja tavaliselt on poliitika peamine muutuste liikumapanev jõud: 60% ettevõtete muutustest liikuvuse planeerimisel on ajendatud soovist joonduda (või järgida) riiklikku, piirkondlikku või kohalikku poliitikat.</p>

<p>Kuigi kaugtöötamist peaks reguleerima tööandja, kuid see tuleb integreerida ka avaliku poliitika perspektiivi. Kui kaugtöö meetoditel lubatakse areneda ilma avaliku poliitika seisukohast taotletavaid eesmärke täpsustamata, on nende saavutamise võimalused väikesed.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Ühistranspordiettevõtjad</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Kui ettevõtete liikuvus- või personalijuhid, isegi siis, kui nad on erinevatest sektoritest, teevad koostööd 500–800 meetri läbimõõduga alal transpordisõlme ümber - rongijaamas, bussijaamas jne. - saavad nad luua dünaamika ja läbi selle minna ühiselt kohtuma riigiasutuste, ühistranspordiettevõtjate ja liikuvusteenistustega ning öelda neile: "Meid on, ütleme, 30; me oleme tuvastanud sellise ja sellise vajaduse; mida me saame selles suhtes ette võtta". Kui ainult üks ettevõte tuleb, ei ole sellel mingit mõju. 30 ettevõttega olukord muutub. Teil on vaja sellist massi, et mõju avaldada.</p>
</div></div></div>`
        }


    ],
    "sustainableStakeHolders": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<p>Personalidirektor/juht ei vastuta otseselt ettevõtte sisemiste info- ja telekommunikatsioonisüsteemide eest. Kuid ta võib suurendada töötajate teadlikkust digitehnoloogiaga seotud kestlikkuse küsimustest ja mõjutada nende kasutamist.</p>
<p>Et aidata kaasa ettevõtte süsinikuheitmete vähendamisele, peab personalidirektor/juht tegema tihedat koostööd erinevate sidusrühmadega, kellest peamised on projektipartnerid määratlenud järgmiselt:</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div>
<p><strong><span style="font-size:1.5em;">Tegevjuht/tippjuhtkond</span></strong></p>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Visiooni määratlemine</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Tegevjuhid peavad tunnustama juhtivat rolli, mida nad isiklikult vajavad, et integreerida jätkusuutlikkus sügavalt ja laialdaselt oma organisatsioonidesse.</h3>

<p>Tegevjuht ja ettevõtte juhtkond peavad määratlema visiooni, kehtestama selge eetika ja väärtuste printsiibi seoses jätkusuutlikkusega ning väljendama jätkusuutlikkuse ja süsinikdioksiidi heitkoguste vähendamise ambitsioone. Millised on ettevõtte kohustused ja juhised töötajatele seoses info- ja telekommunikatsioonitehnoloogiate vastutustundliku kasutamisega?</p>

<p>Selle määratlemine aitab juhtidel ja töötajatel luua seose jätkusuutlikkuse ja ettevõtte missiooni vahel ning seejärel oma igapäevatööga.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Juhtimine</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Teine väljakutse seisneb vastutustundlike digitaalsete kriteeriumide integreerimises ettevõtte üldisesse juhtimisse igas IT-ga seotud projektis. See ei tähenda, et teatud projekte ei viida ellu, vaid pigem seda, et lähenemisviisi parandamiseks küsitakse õigeid küsimusi – näiteks mitte arendada teatud vähekasutatud funktsioone, mis raiskaksid ressursse.</h3>

<p>See vaimsus kehtib IT-valdkonnas, kuid võib väga hästi laieneda kõigile ettevõtte osakondadele, sealhulgas personaliosakonnale: üha enam ettevõtteid seostab nüüd süsinikueelarvet oma erinevate projektidega.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Eeskuju / Kuidas antud teemast rääkida?</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Uuringud näitavad, et eeskuju on väga oluline ja sellel on tugev mõju töötajatele muutuste aktsepteerimisel. Juhtkonna jaoks on oluline "rääkida" ja pakkuda töötajatele selgeid näiteid selle kohta, mida oodatakse ja hinnatakse. Eeskujulikkust saab varieerida erinevatel tasandite jaoks.</p>
</div></div></div>
</div>`,
            "imageSource": "https://nwow4net-zero.eu/images/t3/image-ns6btF0w.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-929571f3-be1a-4ea3-9a15-a42c546e1f03"><p><strong><span style="font-size:1.5em;">Teabejuht (CIO) / IT-juht</span></strong></p>

<p>CIO/IT-juht ja temale alluv osakond on sidusrühmad, kes vastutavad ettevõtte IT-strateegia väljatöötamise eest ja tagavad, et kõik selle tegevuse ja eesmärkide toetamiseks vajalikud süsteemid on paigas.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e92dda56-c129-4b24-b8c1-a4b82751c7d8"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-65a10dbe39dc1.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-46916a4a-0b71-4b36-b198-90c8bdb5325f">

<p>Praeguses teadmiste ja praktika olukorras ei saa IT olla «jätkusuutlik». Kuid see võib olla «vastutustundlik» selles mõttes, et piiratakse infotehnoloogia mõju keskkonnale. Vastutustundlik digivaldkond ei tähenda ainult «Rohelist IT-d» – digitehnoloogia ökoloogilise jalajälje vähendamist – või "läbi IT roheliseks" – digitehnoloogia kasutamist keskkonnajalajälje vähendamiseks teistes valdkondades. On veel kaks telge: «Inimlik IT» ja «IT Inimesele». Need teljed näitavad teed sellele, kuidas vähendada digitehnoloogia negatiivset sotsiaalset mõju (näiteks puuetega inimeste juurdepääsuprobleemid) ja kuidas panna IT inimeste teenistusse.</p>

<p>Seetõttu on oluline veenduda, et töötate IT-ga vastutustundliku käitumise nimel. Vastasel juhul riskite anda soovitusi, mida IT ei toeta, ise seda endale teadvustamata. Oluline on kooskõlastatud ja järjepidev lähenemisviis selliste soovituste andmisel.</p>

<p><strong>Näide</strong>: - Ekspert selgitab, kuidas ta töötas koos ühe suure Prantsuse rahvusvahelise ettevõttega rohelise IT-projekti kallal. Nad teadsid, et IT moodustab vaid 2% ülemaailmsest süsiniku jalajäljest. Kuid arvestades organisatsiooni suurust, oli see mõju vägagi märkimisväärne. Ettevõtte juhtkond otsustas, et kõik osakonnad, olenemata nende suurusest ja süsinikujalajäljest, peaksid leidma võimalusi selle jalajälje vähendamiseks poole võrra. Sellest hetkest alates tekitati ettevõttes sidusus läbi ühiste jõupingutuste – nii meeskondade sees kui ka meeskondade vahel. Nad püüdsid mõista, mis toimub teistes osakondades, kuidas üksteist aidata jne.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-R5KB7XDY.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-26d6c56f-23dd-4489-9f7a-dbb45a9d4ded"><p><strong><span style="font-size:1.5em;">Hanked  </span></strong></p>

<p>IT-osakond vastutab seadmete tehnilise kirjelduse ja asendamise sageduse ning väljavahetatavate seadmete hangete eest.</p>

<p>IT peamine mõju keskkonnale tekib eelkõige seadmete tootmisest, olles tunduvalt suurem andmekeskuste ja -võrkude mõjust. Tegelik probleem on väljavahetamise kiirus: me kipume oma seadmeid liiga kiiresti välja vahetama.</p>

<p>Näiteks Belgias on nutitelefoni keskmine eluiga 18–23 kuud, samas kui elektroonilisest seisukohast võib see seade probleemideta töötada 7- 8 aastat või isegi kauem.</p>

<p>Seetõttu on oluline, et organisatsioonis oleks järjepidev poliitika seadmete säästva kasutamise osas.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-638dde93-1fe4-4d1b-87dc-7c7cd5340826"><p><strong><span style="font-size:1.5em;">IT- ja telekommunikatsiooniseadmete tarnijad</span></strong></p>

<p>Digilahenduste ja -seadmete tarnijad on olulised sidusrühmad, kuna neil on teavet ja teadmisi arendatavate ja müüdavate toodete energiatõhususe, toote olelusringi, toote küpsuse ja ringlussevõtu võimaluste kohta.</p>

<p>Koos hanketöötajatega saavad personalispetsialistid kaasata IT-tarnijaid, küsides neilt toodete päritolu kohta. Tarnijad võiksid samuti jagada oma eksperditeadmisi, koolitades töötajaid säästva kasutamise ja energiatarbimise ning elektroonikajäätmete nõuetekohase kõrvaldamise eeliste valdkonnas.</p>

<p>80–90% iga seadme ökoloogilisest jalajäljest on juba tekitatud, kui see esimest korda sisse lülitatakse. Suurim mõju on seadmes sisalduvate mineraalide kaevandamisel ja nende puhastamisega seotud reostusel digiseadmetele vajalike metallide tootmisel. Ühe grammi kulla saamiseks tuleb ekstraheerida vähemalt üks tonn kivimeid! Teiste metallide puhul on see veelgi hullem. Hinnanguliselt kulub 120-grammise nutitelefoni valmistamiseks rohkem kui 200 kilogrammi maaki. See toodang paiskab õhku ligikaudu 80 kilogrammi CO2-ekvivalenti (kasvuhoonegaaside heitkoguste mõõtühik).</p>

<p>Lisaks on nende metallide ringlussevõtu võime äärmiselt madal. Nutitelefonis on umbes 60 metalli, millest saame ringlusse võtta ainult 17. Ülejäänud lihtsalt visatakse ära. Põhisõnum on osta vähem seadmeid, hoolitseda selle eest ja hoida seda nii kaua kui võimalik.</p>

<p>Kui te ei kasuta enam mõnda seadet, ärge pange seda sahtlisse, vaid andke sellele teine elu, annetades selle ühingutele, müües selle kasutatud kujul edasi, taastades jne. Tänaseks on paljude ettevõtete puhul saanud praktikaks taastatud seadmete ostmine ja kasutamine.</p>

<p>Personalijuhtidel on oma roll heade digitaalsete tavade alase teadlikkuse tõstmisel ja täpsemalt selgitamisel, miks on oluline oma seadmete eest hoolitseda.</p>


</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-JgxtRsaR.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-ba79971c-3cf7-4525-ad9a-494e0afef16a"><p><strong><span style="font-size:1.5em;">Regulaatorid  </span></strong></p>

<p>Reguleerivad asutused on olulised sidusrühmad, kuna nad töötavad välja keskkonnahoidliku IT-ga seotud nõudeid, nagu energiatõhususe standardid, e-jäätmete kõrvaldamise eeskirjad ja süsinikujalajälje aruandlusnõuded. Need eeskirjad on ELis muutumas väga rangeks ja hõlmavad paljusid käitumisviise. Organisatsioonide suhtes hakatakse kohaldama üha suuremaid digitaalset vastutust toetavaid eeskirju. Digitaalvaldkond muutub üha standardiseeritumaks, mida tõendab Euroopa Parlamendi 2017. aasta juulis toimunud hääletus resolutsiooni üle, mis käsitleb toodete pikemat kasutusiga.</p>

<p>Personalijuhtide ja nende meeskondade jaoks on reguleerivad asutused oluline teabeallikas ja teadmiste allikas selle kohta, kuidas töötada välja organisatsiooniline poliitika ja strateegiad, mis hõlmavad IT vastutustundlikku kasutamist ja digitaalset kainust, ning kuidas kaasata neid personalijuhtimisse. Seetõttu on oluline, et suhtleksite nendega ja teeksite nendega koostööd võimaluste tekkides, kohtudes nendega koolitustel, töötubades, infopäevadel, mida mõnikord korraldavad regulaatorid.</p>


</div>`
        }


    ],
    "hybridCulture": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d621e8b2-0c32-40ec-a524-408f805b4851"><p><strong><span style="font-size:1.5em;">Kultuur kui võti</span></strong></p>

<p>Süsinikdioksiidi heitkoguste vähendamist toetav tegevus hõlmab kindlasti ka kultuurilist muutujat. Just tööga organisatsioonilise ja juhtimiskultuuri, aga ka näiteks reisimise ja IT-vahendite ratsionaalsema kasutamise kallal on võimalik saavutada muutusi töötajate käitumises ja tarbimises, mis muudavad tasakaalu keskkonna jaoks positiivsemaks.</p>

<p>Kontrollige ettevõtte keskkonnapoliitika ja üldise kultuuri kooskõla. Kuidas te saaksite seda teha? Uurides juba olemasolevaid tavasid, harjumusi ja stiimuleid, et kontrollida, kas need ei ole keskkonnastrateegiaga vastuolus.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-ec71dbde-552a-4919-89c6-8adb3e59180f"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-659b0e3914c5e.png" alt="" title="Culture is Key" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": 'https://nwow4net-zero.eu/images/t1/file-b6WaEZDM.png',
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4db97028-02a0-45df-b3dc-a2400e1f6992"><p><span style="font-size:1.5em;"><strong>Keskenduge õigele asjale</strong></span></p>

<p>Kaugtöö ei ole keskkonnale iseenesest "parem." On palju uuringuid, mis toietavad seda väidet ja ka neid, mis näitavad vastupidist. Ettevõte ei lõpeta näiteks tööruumide kütmist ja valgustamist sellepärast, et mõned töötajad töötavad mujal, kodus või mõnes teises ruumis.</p>

<p>Ettevõtte energiatarbimine võib seega vaatmata tööruumides viibivate inimeste väiksemale arvule jääda samaks. Kuid töötajate kodude energiatarbimine on sellest tulenevalt suurem. Tuleb arvestada ka hooajalist mõju, mis võib riigiti erineda – isegi kui kütmist saab asendada kliimaseadmega.</p>

<p>Lisaks sellele näitavad mitmed uuringud, et kaugtöö kasutuselevõtt ei välista inimeste liikuvust ja kohati on isegi kalduvus reisida rohkem, näiteks teha väikeseid otsi, jätkata pereliikmete viimist ja toomist, nt. laste transport lasteaeda või kooli, kodust väljas lõunasöögid jne. Seega, on võimalik, et tekiksid täiendavad, kuigi lühemad, sõidud. Kokkuvõtlikult võib rääkida "ebakindlast" ökotasakaalust.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e93095af-95fc-4a5d-b59d-b465dbb8ebde"><p><span style="font-size:1.5em;"><strong>Tõstke teadlikkust </strong></span></p>

<p>Teadlikkuse tõstmine ja tõenduspõhistel materjalidel põhinev haridus töökoha käitumisviiside kohta on üks peamisi inimressursside arendajate käsutuses olevaid vahendeid. Töötajate teavitamine nende tegevuse keskkonnakuludest on äärmiselt vajalik. Veelgi parem, kui see teadlikkuse tõstmine, teavitamine või koolitus on juhitud strateegiast, mis on kooskõlas tegevusega ja/või ettevõtte ambitsioonidega saavutada süsinikdioksiidi neutraalsus.</p>

<p>Tavaliselt keskendutakse kaug-/hübriidtöö puhul peamiselt heaolule/töökorraldusele ja vähem jätkusuutlikkusele. Töötajate teavitamine ja koolitamine kliimamuutuste teemal ja sellest, kuidas nende töökorraldus võib mõjutada süsinikdioksiidi heitkoguseid peaks toimuma läbi pideva suhtlemise ja dialoogi.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a2f1c3ca-765a-48bc-9ae8-5bf7b0657d0b"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-659b0e6194c3e.png" alt="" title="Raising Awareness" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-2brlApB0.png"
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-56f5683d-ac8f-457a-aa74-6ceab16d8394"><p><strong><span style="font-size:1.5em;">Algatage kaasav lähenemine</span></strong></p>

<p>Töötada välja osaluspõhine lähenemisviis, mis kaasab töötajaid, kes on jätkusuutlikkuse suhtes tundlikud või aktiivsed, et muuta nad antud valdkonna saadikuteks ja kaasata teisi töötajaid.</p>

<ul dir="ltr">
\t<li>ideede kogumine,</li>
\t<li>parimate tavade jagamine</li>
\t<li>ettepanekute tegemine</li>
\t<li>… jne.</li>
</ul>

<p>Mitmed 2022. aastal lääneriikides läbi viidud uuringud on näidanud, et enamik töötajaid näevad hübriidtöö väärtust ja et paindlikkus on üks olulisemaid tegureid, kui otsustatakse, kas võtta vastu tööpakkumine. Suurtes ettevõtetes on järk-järgult kujunemas norm, mille kohaselt viibitakse 3 päeva nädalas kontoris kohal.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d36a5e02-5941-4954-acb4-08a1432622b7"><p><strong><span style="font-size:1.5em;">Pöörake tähelepanu pingetele </span></strong></p>

<p>Peate tuvastama pinged, mis tekivad nii majandusliku tulemuslikkuse kui ka sotsiaalse ja keskkonnaalase tulemuslikkuse eesmärkide saavutamisel, nii lühiajalises loogikas - mis iseloomustab organisatsioone tänapäeval - kui ka pikemaajalises loogikas - milles jätkusuutlikkuse mõiste on kinnitatud.</p>
</div>`
        }

    ],
    "mobilityCulture": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-067d5e0e-e4fb-43be-84fa-825384498127"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-6509dd97d9c6f.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8dc96038-5902-4fae-b1bc-6ff31e4bd15f"><p><strong><span style="font-size:1.5em;">Mõjutage käitumist </span></strong></p>

<p>Organisatsioonid ei saa mõjutada seda, kus töötajad otsustavad elada, sest see oleks nende privaatsuse ilmne rikkumine. Tööle sõitmist on raske kaalule panna, isegi kui püütakse arvestada selliseid parameetreid nagu odavam eluase või parem isikliku elu kvaliteet. Töötage koos ekspertidega, et kolleege sellistel teemadel teavitada.</p>

<p><strong>Näide:</strong></p>

<p>Wever on Prantsusmaal loodud platvorm, mis teeb ettepaneku leiutada kaasav ja tõhus liikuvus, asetades kasutaja ja tema väljendatud vajadused pakkumise kavandamise keskmesse. See annab igaühele võimaluse väljendada oma vajadusi, katsetada uusi lahendusi ja hääletada kõige huvitavamate lahenduste elluviimise poolt pikas perspektiivis.</p>
</div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9772c0de-3792-4fe2-a5f6-899984bb576a"><p><strong>Kuidas see töötab?</strong></p>

<ol>
\t<li>Liikumisdiagnostikat kasutatakse töötajate reisiharjumuste ja vajaduste kogumiseks. Iga inimese vajaduste kogumiseks ja diagnoosi määramiseks kulub vaid mõni minut.</li>
\t<li>
\t<p>Armatuurlaud võimaldab jälgida reaalajas tehtud diagnoose ja näha soovitusi tegutsemiseks, lisatud on ka kommentaarid. See visuaalne tööriist võimaldab pakutavat liikuvuskava kasutajatega ühiselt koostada.</p>
\t</li>
\t<li>
\t<p>Algoritm tuvastab liikuvusvõimalused, tuues välja olemasolevad lahendused ja modelleerides uusi vastuvõetavaid lahendusi.</p>
\t</li>
\t<li>
\t<p>Tänu personaliseeritud toetusele muutustele ja individuaalset hüvede loogikat silmas pidades on teenust kasutava isiku muutused nähtavad mõne nädala pärast.</p>
\t</li>
</ol>

<p>Lisainformatsioon:<a target="_blank" href="http://www.wever.fr/" target="_blank">www.wever.fr</a></p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-0lBHW18J.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-065d48e1-ed65-4c87-803a-8063f0d2bb9b"><p><strong><span style="font-size:1.5em;">Tee see konkreetseks </span></strong></p>

<p>Ärge tehke tööga seotud liikuvuse kampaaniaid liftis olevate plakatitega, sest seda tehes ei saa te oma käitumist muuta. Käige kolm korda nädalas kohvikus, et rääkida inimestega, tunda huvi nende liikuvuse ja murede vastu ning soovitage edasisi samme. Lühidalt, isikupärastage oma lähenemine iga inimese jaoks. See võtab aega ja energiat. Teine näide on jalgrattarühma loomine ja jalgrattaürituste korraldamine kohapeal ja väljaspool seda. Samuti võite paluda veendunud kolleegidel teha esitlus või rääkida mingil teemal.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6d757e35-654c-416e-a38f-5fbf7e44a845"><p><strong><span style="font-size:1.5em;">Autode parkimisprobleemide lahendamine</span></strong></p>

<p>Autode parkimine on keskne küsimus: ettevõtted on välja töötanud meetmed alates stiimulitest (rahaline tasu näiteks selle eest, et oma autot ei tooda kohapeale) kuni trahvideni (kohapealse parkimistasu kehtestamine või maksimaalne päevade / nädala või kuu arv parkla kasutamiseks).</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8cb77e76-e0c3-439c-9f90-d582dd82222c"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650a0615ebf86.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-jONe6a2U.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42f4fadb-eb58-4f8a-a545-94bbab8675ab"><p><span style="font-size:1.5em;"><strong>Algatada osaluspõhine lähenemisviis</strong></span></p>

<p>Luua liikuvusega seotud teemavaldkond, kuhu on kaasatud vabatahtlikud töötajad, et koguda ideid ja ettepanekuid ning arendada välja sisesaadikute funktsioon ja nende eesmärgid. Mõned organisatsioonid on delegeerinud liikuvuse/tööletulekuviiside haldamise rolli otsestele juhtidele, et luua töörühmade sisene jäljendamine läbi vahetu eeskuju.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": ``,
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-a57aaa91-50f0-4dce-b73b-23410fda6402"><p><strong><span style="font-size:1.5em;">Edendage „kastist väljas“ mõtlemist </span></strong></p>

<p>Julgustage inimesi mõtlema reisi põhjusele, enne kui valite/mõtlete reisimisviisile/-viisile.</p>




</div>`
        }
    ],
    "sustainableCulture": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-056e0c6d-cc34-469f-8ced-3b7f996472c6"><p dir="ltr"><strong><span style="font-size:1.5em;">Teadlikkuse tõstmine</span></strong></p>

<p>Sõltuvalt allikast moodustab digitehnoloogia isegi kuni 3–4% ülemaailmsest kasvuhoonegaaside heitest. Võrreldes teiste sektoritega jääb see osakaal tagasihoidlikuks, kuid digitaalse tarbimise aastane kasv peaks tekitama ettevõtetes muret, hoiatab Institut du Numérique Responsable (Prantsusmaa/Belgia). Näiteks teenindusettevõtetes on süsinikuheitmete esikolmikus reisimine, hooned ja IT – see järjekord võib organisatsiooniti erineda. IT (ja selle mõju) võib vahel olla peamine objekt, mis on ettevõttes hindamata, sest seda ei mõõdeta või hinnatakse puudulikult. Näiteks mobiilsus on inimeste meelest väga selgelt seotud keskkonnaprobleemidega, kuid IT mõju kui probleemi nähakse palju vähem.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-192ec88b-a197-4536-95ee-13ea3703a0e2"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b2348b9ee9.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-HLGgm3YH.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-59071f32-37c3-4315-89a9-cd5beb84ac23"><p><strong><span style="font-size:1.5em;">Arusaamise edendamine</span></strong></p>

<p>Oluline on õppida eristama olulist vajalikust. Kui soovite vähendada digitaaltehnoloogia keskkonnamõju, peate teadma, et e-kirjade arvu piiramisel on vaid marginaalne mõju - kuigi see on tegevus, mida sageli soovitatakse. Digitaaltehnoloogia peamine negatiivne mõju planeedile tuleneb hoopis seadmetest ja nende enneaegsest uuendamisest. Me ei tohi pidada valet lahingut.</p>

<p>Tehkem väga varakult vahet otsestel, kaudsetel ja tagasilöögiefektidel ning väärtustagem seetõttu positiivseid meetmeid, mis tegelikult midagi muudavad.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-4abab26f-e818-4d12-b498-6b40280bffee"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b67a5efee2.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-19JBhPTu.jpeg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8fee0210-9975-43b3-b9a3-862916f098b6"><p><strong><span style="font-size:1.5em;">Digitaalse kainuse soodustamine</span></strong></p>

<p>Vastutustundlik IT ei tähenda, et me soodustame/peaksime soodustama igasuguse digitaalse kasutuse vähendamist. Tegelikult küsivad inimesed endalt üha enam, kuidas vähendada digitaalse kasutamise negatiivset keskkonnamõju, kuna kõik nende ümber on digitaalne. Kogu ühiskond on muutunud digitaalseks. Me ei saa ilma selleta midagi teha.</p>

<p>Tõeline väljakutse on seega eemaldada kõik, mis on kasutu. Ja siin võib personalitöötaja anda väärtuslikke vastuseid.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-80a48b73-1d1c-416d-8cbd-ff20039a441d"><p dir="ltr"><strong><span style="font-size:1.5em;">IT- ja telekommunikatsioonitehnoloogiate vastutustundliku kasutamise edendamine</span></strong></p>

<p>Mis puutub seadmete kasutamisse, siis videoedastus kui selline mängib siin rõhutatult kõige negatiivsemat rolli. Koguni 80% Interneti ribalaiusest kasutatakse video jaoks - millest ainult veerand kulub videokonverentside või videokõnede jaoks ja ülejäänud kolm neljandikku kasutatakse voogesitusplatvormide jaoks, nagu Netflix, YouTube, täiskasvanutele mõeldud sisu jne.</p>

<p>Kaamera väljalülitamisel individuaalse kõne ajal pole suurt vahet. Grupivideoseansi ajal asjad eskaleeruvad. COVID-19 kriis oli selle muutuse tugev tunnistaja. Video intensiivne kasutamine tõi kaasa drastilise muutuse ribalaiuse jaotamises ja andmekeskuse seadmetes – just selles peitubki kaugtöö tegelik mõju.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-c9087ca2-6557-4911-9f64-03a6d9a035df"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b6812dd3f5.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-16ec8bc4-3636-4635-b576-83bb425e0be5"><p>Pandeemia tagajärjed olid kahetised: ühelt poolt pidi terve rida ettevõtteid, kes ei olnud kaugtöö võimaldamiseks varustatud, enda riistvara uuendama – ostma juurde sülearvuteid, ekraane jne. Teisest küljest tarbisid kõik inimesed, kes ei saanud kaugtööd teha ja olid töökohtade või avalikes kohtades liikumise sulgemise ajal kodus kinni, Netflixi vaatamist, millel oli tõeline mõju ribalaiuse nõudlusse. Võrguteenuse pakkujad lisasid oma võimsuse suurendamiseks järjest rohkem riistvara, tekitades sellega olulist negatiivset mõju ökoloogilise jalajälje osas.</p>

<p>Tuleks meeles pidada, et tehnoloogia vastutustundlik kasutamine on seadmete ja andmekeskuste äärmiselt kiirest uuendamisest tekitatud mõjuga võrreldes väga marginaalne mõõde.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-re4wePLp.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0cddd6a4-52aa-42a3-8484-5539737f8a1d"><p><span style="font-size:1.5em;"><strong>Sidususe topeltkontroll</strong></span></p>

<p>Täna on igaühel oma seisukoht kestliku arengu suhtes. Tegelikkus on aga nüansirikkam: mõned ettevõtted tahavad tõesti oma ärimudelit muuta – olles täiesti teadlikud, et see võib olla eelduseks teatud turgudele püsimiseks lühikeses või keskpikas perspektiivis –ja on ka teised, kes teevad üsnagi tõhusat rohepesu.</p>

<p>Teine kategooria on ettevõtted, kus üks osa organisatsioonist tahab tõesti mängu muuta, samal ajal kui teine osa teeb massiivseid reklaame, öeldes, et «metaversum on inimkonna tulevik». On juhtumeid, kui ülemus ütleb teile ühel päeval, kui oluline on olla energiatõhus, ja järgmisel päeval liigub ta autoga linna, mis on vähem kui poole tunni bussisõidu kaugusel. Meil on tegemist sügavalt inimlike tõdedega, mis koosnevad vastuoludest, suurest silmakirjalikkusest, väikesest argusest ja tõelisest soovist tegutseda.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-7192ddeb-d87d-4c96-8d91-5f579d91095c"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b68e420112.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-cd099baa-8a59-455f-aa3c-1529a2f405a5"><p>Kolmas kategooria ettevõtteid saadab murettekitavalt segaseid sõnumeid. Nad reklaamivad säästvaid tavasid, kuid investeerivad siiski massiliselt uutesse, energiaahnetesse tehnoloogiatesse, näiteks metaversumisse. See oleks samaväärne sellega, kui juht propageeriks keskkonnahoidlikumaid liikuvustavasid, broneerides samal ajal reaktiivlennuki lühimaareisiks. Netonulldünaamika paljastab sügava inimloomuse, fassaadide loomise ja tõelise tegutsemissoovi soodustamise vahel.</p>

<p>Personalijuhil ja -osakonnal on oma roll olla organisatsiooni kõikide osakondade juht. Võtame näiteks koolituse: mis mõte on koolitada digitaalset vastutust ja kainust, kui samal ajal pakute välja tehnikutele metaversumi käsitleva mahuka koolitusprogrammi?</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-h1GH98Y5.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5c6fe4df-bd40-485d-9629-7de6aeb00952"><p><span style="font-size:1.5em;"><strong>Loova mõtlemise edendamine</strong></span></p>

<p>Julgustage inimesi mõtlema, kuidas nad saaksid vähendada kasvuhoonegaaside heitkoguseid infotehnoloogia vastutustundliku kasutamise kaudu.</p>


</div>

<div class="h5p-image-hotspot-popup-content has-scrolled"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Digitaalsed detox-väljakutsed</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">Korraldage töötajatele digitaalseid detox-väljakutseid, julgustades neid tegema tehnoloogiast pausi kindlaksmääratud aja jooksul, näiteks päeval või nädalal. Seda saab teha individuaalse väljakutsena või meeskonna loomise tegevusena.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Ekraanivabad pausid</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">Saate julgustada töötajaid tegema ekraanivabu pause kogu päeva jooksul, pakkudes võimalusi füüsiliseks treeninguks, tähelepanelikkuse praktikateks või muudeks mittedigitaalseteks tegevusteks.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Digivabade koosolekute poliitika</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Saate luua poliitika, mis takistab digitaalsete seadmete kasutamist koosolekute ajal.</p>
</div></div></div>

</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/image-eeGDl5ev.jpg",
        }
    ],
    "hybridTools": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9f6a9bf2-ee6b-4dea-beb7-8f269fdd47e6"><p dir="ltr"><strong><span style="font-size:1.5em;">Säästvuse algatuse töörühma või rohelise meeskonna / saadikute loomine</span></strong></p>

<p dir="ltr">Ei saa ära teha olulist, kui ei ole motiveeritud inimesi. Tuvastage organisatsioonis tõenäolised muudatuste elluviijad ja proovige neid võimaluse korral leida mitmetest tasemetest ja osakondadest. Proovige neid idee jaoks kaasata, uurides hübriidtöö võimalusi ja kasu süsiniku heitkoguste vähendamiseks. Kuigi see poisitsioon on puhtalt vabatahtlik, on õige hoiak ja motivatsioon olemas, ning see annab neile võimaluse tegevuseks organisatsioonis, kooskõlas nende sisemise motivatsiooniga keskkonna eest hoolitsemiseks.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-658bbecb-b411-40b2-8187-4d368eb13db1"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a076563db5.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-GBdvjo7v.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4efad5a4-1ecd-417f-a6e1-c245bfb5ecbe"><p><strong><span style="font-size:1.5em;">Kontoriga seotud tarbimise auditeerimine</span></strong></p>

<p>Mõned näited alljärgnevatest tegevustest (allikas: <a target="_blank" href="http://www.sustainablebusinesstoolkit.com">www.sustainablebusinesstoolkit.com</a>):</p>

<ol>
\t<li><strong>Eemaldage kasutuselt ühekordsed tassid/topsid</strong> kui keskkonnale kahjulikud. Plastik- ja stürofoamtopsid ei ole biolagunevad ja võivad säilida aastaid pinnases või sattuda merre ja jõgedesse. Proovige kanda kaasas korduvkasutatavat pudelit või termost vee või kohvi jaoks. Kui ühekordselt kasutatavatest tassidest loobumine on liiga häiriv, siis kaaluge selle asemel keskkonnasõbralike tasside kasutamist.</li>
\t<li><strong>Kehtestage BYORB-poliitika</strong> (Bring Your Own Reusable Bottle).</li>
\t<li><strong>Kasutage ringlussevõetud trükipaberit:</strong> ringlussevõetud paberi kasutamine printerites on lihtne roheline idee. Tänapäevase tehnoloogia abil ei märka teie ja teie kolleegid isegi vahet.</li>
\t<li><strong>Prindi kahepoolselt: </strong>Veenduge, et teie arvuti ja printer on vaikimisi seadistatud kahepoolseks printimiseks. Uuringute kohaselt võib kahepoolne printimine vähendada paberijäätmeid 10-40%.</li>
\t<li>Võtke kasutusele <strong>paberivaba dokumendivahetus.</strong></li>
\t<li><strong>Proovige ringlussevõetud ja biolagunevaid pliiatseid</strong>, selle asemel, et plastmassist pastapliiatseid ära visata.</li>
\t<li><strong>Vähendage arvutimonitori heledust.</strong> Harvardi ülikooli uuirngute põhjal võib arvutimonitori heleduse vähendamine 100%-lt 70%-le säästa kuni 20% monitori energiast. Enamik inimesi ei märka selle kohandamise erinevust, seega on see kiire ja lihtne idee roheliseks muutuda.</li>
\t<li><strong>Lülitage oma arvutimonitor öösel välja,</strong> et säästa energiat.</li>
\t<li><strong>Rakendada “jäätmelkäike”. </strong>Jäätmelkäik seisneb selles, kui töötajad käivad oma töökoha korrusel ringi ja teevad kindlaks raiskavad tegevused. See on lõbus viis, kuidas kaasata kõiki, et luua ideid kontori keskkonnamõju vähendamiseks. Jäätmelkäigud ei nõua täiendavaid vahendeid, ressursse ega kolmandaid osapooli. Nad kasutavad ära igaühe aju, et tuvastada “madalal rippuvad viljad” ja teha parandusi.</li>
\t<li><strong>Vabanege individuaalsetest prügikastidest! </strong>Mõnes kontoris on prügikastid iga inimese laua juures. Kui eemaldate individuaalsed prügikastid ja kasutate nende asemel keskseid prügikaste, suurendate oma ringlussevõtu määra ja vähendate jäätmete hulka.</li>
\t<li><strong>Osta keskkonnasõbralikku ja vastutustundlikult hangitud kohvi.</strong></li>
\t<li><strong>Optimeerige loomuliku valguse kasutamist. </strong>Kui teie kontoris on aknad, siis optimeerige kindlasti loomulikku valgust. Vältige päevasel ajal elektripirnide sisselülitamist, hoides kardinad, varjud ja rulood lahti.</li>
\t<li><strong>Investeerige energiatõhusasse valgustusse. </strong>Energiasäästlike valgustite kasutamine säästab elektrit ja raha. Energiaefektiivseid valgusteid on igas vormis ja suuruses. Igale vajadusele ja igale eelarvele leidub midagi!</li>
\t<li><strong>Investeeri nutipistikutesse.</strong> Bürood saavad automaatselt energiat säästa kasutades nutikaid pistikupesasid. Nutipistikud lülitavad tuled, ventilaatorid ja seadmed automaatselt välja. Enamik nutipistikuid võimaldab teil oma seadmeid telefonis oleva rakenduse abil kõikjalt juhtida.</li>
\t<li><strong>Reguleerige oma termostaati suvel 2 kraadi kõrgemale ja talvel 2 kraadi madalamale.</strong> Alternatiivina võite kasutada energiasäästlikku termostaati, mis säästab ise energiat.</li>
\t<li><strong>Julgustage vee säästmist nõuannete ja juhistega vannitubades. </strong>Püüdke märke välja vahetada ja kasutage loomingulisi kujundusi, et hoida sõnumit värskena.</li>
\t<li><strong>Vali parandamine asendamise asemel.</strong></li>
\t<li><strong>Korraldage koos oma meeskonnaga rohelisi võistlusüritusi.</strong></li>
</ol>

<p><strong>Vihje</strong>: Kõige parem viis keskkonnaalaste eesmärkide saavutamiseks on astuda järskude muudatuste asemel väikeseid samme. Selle asemel, et teha suuri muudatusi, mis võivad mõnel töötajal üle jõu käia, keskenduge pigem ühe väikese muudatuse tegemisele igas töökohas.</p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-44756050-178f-436e-893f-651f0a73f718"><p><strong><span style="font-size:1.5em;">Kaugtööpoliitika ja -tavade süsinikujalajälje hindamine</span></strong></p>

<p>Reutersi uuringus 20 suurema ettevõtte kohta, kus kasutatakse kaugtööd, leiti, et ainult pooled neist olid püüdnud hinnata kaugtöötajatega seotud heitkoguseid ja veel vähem olid püüdnud nende mõju leevendada või kompenseerida. Isegi Microsoft, kus on erakordselt jõuline ettevõtte jätkusuutlikkuse programm ja kus alustati kaugtöötajate heitkoguste hindamist 2020. aastal, püüab ikka veel saada aru oma kaugtöötajate süsinikuheitmete mõju suurust.</p>


</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-kVIDIWNk.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-96673788-aac0-4bb1-a147-1f060a8bad55"><p><strong><span style="font-size:1.5em;">Energiatarbimise näitajad </span></strong></p>

<p>Kuigi mõõtmisvahendid on olemas, on nendega seoses mitmeid raskusi. Esiteks see, et mõõtmine põhineb sellel, mida üks tegevuskoht tarbib, ja sageli on ettevõtetel mitu tegevuskohta väga erinevates geograafilistes piirkondades ja väga erineva ajalooga. Teine vajadus arvestada kogu ökosüsteemi, sealhulgas töötajaid ja tarnijaid. Nende kaasamine võrrandisse ja nende mõjutamine on palju keerulisem.</p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-72db514d-8a33-4eb4-a525-83ba1cf3c4c2"><p><strong><span style="font-size:1.5em;">Hübriidhalduri planeerimine</span></strong></p>

<p><strong>Võtke vastu poliitika, mis võtab arvesse üldist mõju reisimisele ja keskkonnale (nt vältida kaugtööd esmaspäeviti ja reedeti, mida enamik ettevõtteid kasutab).</strong></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a0e4f2b1-4e13-4f99-b95f-0ef8a62df29a"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a0d17a1b25.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-23380b42-0403-427c-ad5c-a2eb83219cfa"><p>David Autissieri pakutud mudel võiks selles osas olla huvitav. See kutsub meid üles seadma kahtluse alla töömeetodid, mida tuleb käsitleda näost-näkku- ja kaugtöö osas Agile meetoditest laenatud mõistete "Build" ja "Run" kaudu. Kaugtöö toimib hästi "Run", st tuntud, korduvate tegevuste puhul, kuid on vähem kohandatud "Build", st kõigi tegevuste puhul, mis hõlmavad investeeringuid tulevikku, näiteks uued projektid. Teisisõnu, me oskame kaugtööd teha jooksva tegevuse puhul, mille puhul me töötleme, kuid vähem selle puhul, mis nõuab interaktsioone, vahetusi, usaldust ja kollektiivset intelligentsust, et alustada. Sel juhul on tõenäoliselt eelistatavam näost näkku kohtumine.</p>

<p>Sellest lähtuvalt saab personalijuht innustada juhte mõtlema koos oma meeskonnaga, millal või millistel ülesannetel on oluline, et kõik koos kohal oleksid ja mis eesmärgil; ning millal ja millistel ülesannetel on võimalik võita, kui teatud tegevusi teostatakse veebis. Näiteks operatiivse projekti järelevalve koosolek on tõhusam, kui see viiakse läbi veebis. Mis puutub uue töötaja tervitamisse meeskonnas, siis on parem seda teha isiklikult. jne... Sellele refleksioonile saab siis lisada süsiniku mõju kriteeriumi. Selle tulemusel võib rühmitada ülesanded nii, et need moodustavad kaugtööklastri, ja teised nii, et need moodustavad silmast silma töö klastri, võttes arvesse kõiki parameetreid: kaasatud inimeste arvu, nende poolt läbitavaid vahemaid ja transpordiliike, kontori täituvuse määra, mõju digitaalkasutuse osas jne.</p>

<p>Idee ei ole matemaatiline, vaid pigem oportunistlik lähenemine: kui rühmakohtumine võib anda parema tulemuse kui see, mida saavutataks eemalt, hoolimata suuremast keskkonnakulust, on tõenäoliselt siiski asjakohane seda silmast silma läbi viia. See on valiku küsimus. Huvi on selles, et oleks võimalik anda endale vahendid otsustamiseks, kaaludes pidevalt erinevaid töökorralduse parameetreid.</p>

<p dir="ltr"></p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-9MJjwGNw.jpg"
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6ab0511b-b00e-4b8e-af60-873709089171"><p dir="ltr"><strong><span style="font-size:1.5em;">Rakendus, mis võimaldab kontoriruumi optimeerimist seoses kaug-/hübriidtööga</span></strong></p>

<p>Erinevad rakendused on turul olemas, mis aitavad vähendada tööruumide korraldamisega seotud jõupingutusi ja paremini hallata kontori paigutust ning töötajate kohalolekut.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-27fceef1-f99c-4f3a-adc2-ca263f01efff"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a11737328b.png" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-kUM3Ss3g.png",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-79477eba-a9a7-4660-bb64-90d2f1600a3e"><p><strong><span style="font-size:1.5em;">Korraldage regulaarseid töötubasid / lõuna- ja õppetunde jätkusuutlikkus@kontor teemal.</span></strong></p>

<p>Jätkusuutlikkuse töötuba on mõeldud selleks, et kõik saaksid hõlpsasti ja hõlpsasti tutvuda laiema kontekstiga, kuidas kasutada jätkusuutlikkust kontoris, ning võimaldada neil töötada välja strateegiaid, kuidas lisada jätkusuutlikkuse kaalutlused oma igapäevatöösse.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-783f311b-b0bc-455e-8ff2-8602b11a7275"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a12151f7ae.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-357JJyIZ.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9e69f91d-beba-4166-86c4-9e3c563b3d40"><p><strong><span style="font-size:1.5em;">Kliima Freski</span></strong></p>

<p>Vaid 3 tunniga õpetab koostöövõimeline Climate Freski töötuba kliimamuutuste aluseks olevat teadust ja annab võimaluse tegutseda. Kasutades ja aktiveerides osalejate kollektiivset intelligentsust, võimaldab Climate Fresk neil võtta teema eest vastutuse. Metoodika ei kasuta hierarhilist õpistruktuuri, mistõttu leiab iga osaleja oma koha õppetöös. Kuna osalejad õpivad seostama kliima põhjuseid, mõjusid ning muutusi, suudavad nad esimest korda mõelda valdkonnale teise nurga alt ja mõista probleemide süsteemset olemust.</p>

<p><strong>Lisateave</strong>:<a target="_blank" href="https://climatefresk.org/" target="_blank">https://climatefresk.org/</a></p>
</div>`
        }
    ],
    "mobilityTools": [
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4fba2945-7e82-4e52-8e74-afa0bdfe1f8b"><p><strong><span style="font-size:1.5em;">Viige läbi liikuvuse analüüs</span></strong></p>

<p>Viige läbi oma ettevõtte süsinikdioksiidi heitkoguste hindamine: leidke ekspert, et selgitada välja kõige mõjukamad teemad (liikuvus, lennukiga reisimine, ehitus jne) ja keskenduge neile. Seejärel viige läbi liikuvuse skaneerimine, mis põhineb täpsetel ja ajakohastel andmetel.</p>

<p><strong>Näited:</strong></p>

<p>Mobiilsusmärgis, vahendid nutikamaks pendelrändeks</p>

<p><a target="_blank" href="https://mobilitylabel.com/">https://mobilitylabel.com</a></p>

<p><strong>Mob Box </strong>koondab ja koostab massilisi toorandmeid. Alates ettevõttesisestest andmetest kuni väliste avatud lähtekoodiga andmeteni muudab see arvud selgelt mõistetavateks kaartideks ja intuitiivseteks diagrammideks. See aitab teil saada põhjaliku ülevaate oma töötajate liikuvusest ning hinnata transpordiliikide üldist jaotust ning analüüsida CO2-heidet ja sellega seotud kulusid.</p>

<p><a target="_blank" href="http://www.mob-box.eu/" target="_blank">www.mob-box.eu</a></p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-08185ad9-d286-407c-8269-b3bcecba9cc2"><p><strong><span style="font-size:1.5em;">Tutvuge saadaolevate avalike lahendustega</span></strong></p>

<ul>
\t<li>
\t<p>Prantsusmaal: <a target="_blank" href="https://www.ecologie.gouv.fr/sites/default/files/mobilite%20durable%20en%20entreprise.pdf" target="_blank">Forfait Mobilité Durable</a> (säästva liikuvuse pakett)- iga ettevõte võib toetada või rahastada oma töötajate kodu-töö sõite mahus kuni 800 eurot aastas, millele ei rakendu tulumaksudest ja sotsiaalkindlustusmaksetest, kui neid kasutatakse "rohelistes" transpordivahendites – ühistransport, elektrilised tõukerattad, jagatud elektriautod, autode ühiskasutus ...</p>
\t</li>
\t<li>
\t<p>Belgias: <strong>Eelarve mobiliseerimine</strong> (Mobility Budget) võimaldab ettevõtetel pakkuda töötajatele, kellel on ametiauto, eelarvet keskkonnasõbralikumate liikuvusvõimaluste jaoks —<a target="_blank" href="https://lebudgetmobilite.be/fr" target="_blank">https://lebudgetmobilite.be/fr</a></p>
\t</li>
</ul>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/image-V0D782D4.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-eb98b054-5f2c-454c-9a29-05efff3cce27"><p><strong><span style="font-size:1.5em;">Individuaalne diagnostika / liikuvusuuringud </span></strong></p>

<p>Vaadake, kuidas inimesed oma töökohale reisivad, ja pakkuge neile individuaalsete turundusmeetmete abil alternatiive. On olemas terve diagnoosimise ja inimeste toetamise protsess, et näidata neile, et auto ei pruugi olla kõige tõhusam viis liikumiseks. Põhjalikum analüüs (jälgides inimesi mitu päeva) võib neile näidata, et on ka teisi viise, kuidas asju teha, vähem motoriseeritud, ökonoomsemad jne.</p>
</div>`

        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d7ebb14-1b14-4a0b-a034-151adcc5f3bb"><p><strong><span style="font-size:1.5em;">Mitmeliigilise transpordi marsruudiplatvormid</span></strong></p>

<p>Mitmeliigilise transpordi marsruudiplatvormid võivad näidata erinevaid võimalusi ja võimaldada inimestel mõista, et autoga reisimine on vähem tõhus kui teiste transpordiliikide puhul. Mõned neist vahenditest näitavad erinevate reisiliikidega seotud CO2-heidet.</p>

<p><strong>Näide:</strong></p>

<p>Skipr, kõik-ühes lahendus ettevõtete liikuvuse haldamiseks</p>

<p><a target="_blank" href="http://www.skipr.co/">www.skipr.co</a></p>

<p>Skipr võitleb ühe kasutajaga autode kasutamise vastu linnas. See pakub töötajatele rakendust, mis võimaldab neil näha, kuidas jõuda punktist A punkti B, kombineerides nende ümber olevaid erinevaid transpordivahendeid: ühistransport, jagatud sõidukid, elektrilised tõukerattad, rohelised sõidukid jaama jõudmiseks jne. See on kaaspiloot, mis tagab neile võimaluse oma autot mitte võtta. See võimaldab osta ühistranspordipileteid ettevõtte kontolt koos maksekaardiga, mis on seotud sama "liikuvuse eelarvega" alternatiivsete liikuvusliikide jaoks. Ettevõtte jaoks pakub see juhtimisplatvormi, mis teeb kahte asja: kehtestab ettevõtte juhised (määrab kindlaks, kellel on õigus millele) ja pakub aruandlusvahendit, mis annab täieliku ülevaate saavutatud säästust ja süsinikdioksiidi heitkoguste vähenemisest võrreldes traditsioonilise auto kasutamisega. Töötajatel on oma andmetega tabloovaade, kuid ettevõttel on ainult koondaruanded. Reisivõimaluste otsimisel saavad töötajad kolme infokildu: saabumisaeg, maksumus eurodes ja CO2 emissioon.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4bf3cd48-c7e8-4a5f-a5a3-4727c4478547"><p><strong><span style="font-size:1.5em;">Julgustage autode ühiskasutust </span></strong></p>

<p>Edu tingimused: tööaja paindlikkus on üks esimesi põhjuseid, miks kolleegide vahel autot ei jagata. Sama tööaja puudumine on piirav tegur, eriti kui isiklikud piirangud – laste pealevõtmine, asjaajamine jne. – lisatakse. Abiks võib olla projektijuhi olemasolu, kes laua ja pliiatsiga teekonnad kirja paneb ja need kokku sobitab. Kui jätate selle inimeste endi otsustada, on see keerulisem. Kui jätate selle rakenduse hooleks, on see ka keeruline. Digitaalsed tööriistad töötavad väga hästi pikkade vahemaade korral, kuid need ei ole nii tõhusad lühikeste vahemaade, korduvate ja igapäevase kasutamise korral.</p>

<p>Siiski on olemas platvormid, mis hõlbustavad autode ühiskasutust sobitamisvõimalustega, teabega maksusoodustuste kohta ja jälgimisvõimalustega suhtluse suunamiseks. Oluline on märkida, et autode ühiskasutus ei toimi hästi, kui töökoht on ühistranspordi hea pakkumise lähedal.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e97d6b7b-85ca-4b8e-adc4-c549a30315f2"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650ae2ffdc5cc.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-tomPlVwo.jpeg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f2705860-d508-4b16-9089-9bba70468e72"><p><strong><span style="font-size:1.5em;">Transport  </span></strong></p>

<p>Mitmed ühistranspordi kasutamise suurima kasvuga organisatsioonid olid kasutusele võtnud tasuta spetsiaalse ettevõttebussi, mis oli ühendatud kohalike linnade ja/või kohalike rongi- ja bussijaamadega. Partnerlusse kuulusid organisatsioonid, kes suurendasid ühes uuringus objektile saabuvate bussiteenuste arvu muljetavaldava keskmiselt umbes 14 lisabussi võrra tipptunnil. Absoluutarvudest olulisemad tundusid asjakohaste ja kohandatud teenuste olemasolu: näiteks odavamad piletihinnad, läbiräägitud piletid, parem kohapealne taristu/teenuse kvaliteet ning uute bussipeatuste rahastamine platsil või selle lähedal.</p>
</div>`

        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": " ",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c293a877-1d7a-44a0-8b05-524e78f4b318"><p><strong><span style="font-size:1.5em;">Jalgrattasõit  </span></strong></p>

<p>Tegurid, mis olid kõige tihedamalt seotud jalgrattasõidu kõrge tasemega, näisid olevat kvaliteetne juurdepääs väljaspool tegevuskohta või juurdepääsu parandamine väljaspool tegevuskohta; jalgratturitele parkimisvõimaluste suurendamine; ja jalgrattaremonditeenuse pakkumine. Tähelepanuväärne oli ka see, et enamik uuringus osalenud organisatsioone oli pakkunud dušše, vahetus-, kuivatamis- ja/või kapiruume; kokkulepitud allahindlused jalgrattaseadmetele.</p>
</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-457e418e-7a81-4932-82ba-2fcbf1e21fcd"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650a17655699c.jpg" alt="" title="Cycling" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-rCJUADxS.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-801a1d35-3531-4738-807c-79f59dd9e601"><p><span style="font-size:1.5em;"><strong>Elektriautod? Jah, aga...</strong></span></p>

<p>Elektriauto ei ole lahendus liiklusummikutele. Auto on ikkagi auto, olgu see siis elektri-, vesiniku-, bensiini- või diiselmootor, see võtab teedel ruumi. See ei tekita CO2-heidet, vaid vähem: kõik sõltub sellest, kuidas elektrit toodeti. See ongi liikuvusega seotud probleemide raskus: ei ole olemas sellist asja nagu üks suurus sobib kõigile. Igaühel on oma eripära. Elektriautode puhul tuleb arvestada ka kulude ja laadimisega. Ikka veel on takistusi. Asjad muutuvad kiiresti, kuid imelahendust ei ole.</p>

<p>Paigaldage elektriautode laadimistaristu: need on vajalikud elektriautode kasutamise suurendamiseks.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-07fc7d73-8673-4f28-8e07-2c87d26235f0"><p><strong><span style="font-size:1.5em;">Jalgrattaliising (elektrijalgratastele)</span></strong><br>
<strong>Näide:</strong></p>

<p>Jalgratta liisimine (Belgia)</p>

<p>Belgia tööandjana saate valida jalgrattaliisingu pakkumise mitmel viisil: tasuta või (osalise) sissemaksega jalgratta jaoks. Kõigi stsenaariumide korral on töötajatel umbes 40% suurune maksusoodustus.</p>

<p>Te ei maksa liisitud jalgratta eest mitterahalist hüvitist, sest kasutate jalgratast regulaarselt pendelrändeks. Aga saate töötajale välja maksta jalgrattatoetust. See motiveerib teie töötajaid jalgrattaga sõitma. Jalgrattaliisingut käsitlevates määrustes ei tehta vahet liisitud ja erajalgrattal. Tulemused? Paremas füüsilises vormis töötajad: 1-2 päeva vähem töölt puudumisi ja võimas panus rohelisse liikuvuskavasse.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6c224ae6-6652-45b9-a674-13597a5bb424"><p><strong><span style="font-size:1.5em;">Ökosõiduoskuse koolitus </span></strong></p>

<p><strong>Näide:</strong></p>

<p>Ökoloogiliselt vastutustundlik liiklemine teel olles nõuab enamat kui keskkonnasõbralikku sõidukit. Teil peab olema roheline sõidustiil. Ja see on midagi, mida teie juhid õpivad E-positiivses koolitusprogrammis, mis on ALD Automotive'i üheaastane koolitus.</p>

<p><strong>Kuidas see töötab?</strong></p>

<p>«Alustame sellest, mida teie juhid saavad teha; See paneb kaartidele nende praegused sõidustiilid ja sõidukikasutuse. Pärast seda läbivad nad e-õppe kursuse ja saavad koolitust – individuaalselt ja grupis. Juhendaja annab neile pidevalt personaalseid näpunäiteid ja punkte, millele nad peavad tähelepanu pöörama. Tulemus? Koolituse lõpus sõidavad kõik e-positiivse suhtumisega.»</p>

<p><strong>10 näpunäidet ökoloogiliseks sõiduks</strong></p>

<ol dir="ltr">
\t<li role="presentation">Minge sobival ajal kõrgemale käigule. Diiselmootoriga, kuskil 2000 pöörde ja bensiinimootoriga 2500 pöörde juures.</li>
\t<li role="presentation">Aeg liikuma hakata? Lükake gaasipedaal täielikult sisse, sest aeglane kiirendamine ei säästa kindlasti kütust.</li>
\t<li role="presentation">Hoidke enda ees olevast sõidukist piisavat vahemaad. Siis ei pea te pidevalt pidurdama ja kiirendama.</li>
\t<li role="presentation">Kas peate aeglustama või peatuma? Ärge vajutage koheselt pidurit, vaid vabastage gaasipedaal aegsasti. Mootoriga pidurdamisel ja sidurit kasutamata aeglustate keskkonnasõbralikumalt.</li>
\t<li role="presentation">Lülitage püsikiiruse hoidja sisse, et tagada ühtlane sõidukiirus ja seega keskkonnasõbralikkus.</li>
\t<li role="presentation">Kas olete täielikult  peatunud kauem kui 20 sekundit, siis lülitage seismise ajal mootor välja.</li>
\t<li role="presentation">Planeerige oma marsruut ette, et te ei tekitaks tarbetut läbisõitu.</li>
\t<li role="presentation">Sa ei vaja oma katuseboksi, jalgratast ega pagasihoidikut? Võtke see ära ja vähendage oma õhutakistust.</li>
\t<li role="presentation">Kontrollige regulaarselt rehvirõhku.</li>
\t<li role="presentation">Laske oma sõidukit õigeaegselt hooldada: ainult siis saate sõita säästlikult ja ohutult.</li>
</ol>



<p><em><strong>Allikas: ALD Automotive</strong></em></p>
</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-0c1c9678-0a6c-445a-95ce-4a25e6395d09"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650a17e559c50.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-mbYWXvUd.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3e08a77a-372e-478d-a71b-1e82dbff5942"><p><strong><span style="font-size:1.5em;">MaestroMobile </span></strong></p>

<p>MaestroMobile on tõsine mäng, mille on välja töötanud Espaces-Mobilités Belgias liikuvuse valdkonnas ja mille eesmärk on muuta käitumist eksperimenteerimise kaudu.</p>

<p>Lisateave:<a target="_blank" href="https://www.maestromobile.eu/">https://www.maestromobile.eu</a></p>

<ul>
\t<li>Mobility Van koosneb liikuvusekspertidest, kes sõidavad avalikesse ruumidesse ja ettevõtetesse ning näitavad töötajatele kõiki liikuvuslahendusi praktilisel viisil (mikroliikuvusmasinate, jalgrataste, rakenduste testimine jne</li>
\t<li>The City Game on reaalse linna mäng, mis on korraldatud meeskonnatöö vormis ja võimaldab osalejatel katsetada erinevaid liikuvuslahendusi praktilisel viisil, samal ajal (taas)avastades linna, täites väljakutseid, vastates viktoriinidele jne.</li>
\t<li>Kasutades populaarsete mängude, näiteks Pokemon Go koode või linnarasside põhimõtteid, on City Game loonud oma universumi, mis on seotud liikuvusteenuste avastamisega.</li>
\t<li>Sarnaselt spordiprogrammidega pakub Mobility Passport liikuvusega seotud väljakutset, mis viib osalejad umbes kuu aja jooksul läbi mitme liikuvuse õppimise eri etappide, alates teenuse tundmaõppimisest kuni selle kasutamiseni.</li>
\t<li>Mobiilirakenduse kaudu saab osaleja oma oskuste kinnituse ja talle väljastatakse tunnistused, mis on tema liikumispassis templi kujul.</li>
\t<li>GO Mobility on hübriidplatvorm, mis pakub täielikku programmi juhendamiseks ja liikuvuse alternatiivide avastamiseks.</li>
\t<li>Critical MaaS on personaalne coaching, et katsetada uusi liikuvusi ja muuta oma liikumisviisi. Koos ekspertidega katsetate kõiki uusi teenuseid ja saate oma liikuvuse meistriks.</li>
</ul>


</div>`
        }
    ],
    "sustainableTools": [
        {
            "roles": [roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-de5d235d-00c3-4b16-93a5-164773aa7ff5"><p dir="ltr"><strong><span style="font-size:1.5em;">Allkirjastage «Digitaalse Vastutustundlikkuse» harta</span></strong></p>

<p>Selle harta allkirjastamisega kinnitavad organisatsioonid oma kohustust võtta resoluutselt kasutusele vastutustundlik digitaalne lähenemisviis.</p>

<p>Link:<a target="_blank" href="https://isit-be.org/wp-content/uploads/2021/03/Sustainable-IT-Charter-ISIT-BE.pdf">https://isit-be.org/wp-content/uploads/2021/03/Sustainable-IT-Charter-ISIT-BE.pdf</a></p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1fb3c548-59b6-43b1-a623-fb6df78af94b"><p><strong><span style="font-size:1.5em;">Mõõtmine  </span></strong></p>

<p>Selleks, et heita valgust meie digitaaltehnoloogia liigtarbimise mõjule, on Institut du Numérique Responsable loonud lihtsa kalkulaatori, mis võimaldab inimestel hinnata oma professionaalset digitaalset mõju.</p>

<p>Teine mõõtmisvahend nimega WeNR hindab organisatsioonide infosüsteemi jalajälge, et võimaldada neil tuvastada mõju ja tuvastada nende digitaalse vastutuse küpsuse tase, nii kvalitatiivne kui ka kvantitatiivne. See vahend on veebipõhine küsimustik, mis on tasuta kasutamiseks ja kättesaadav kõigile organisatsioonidele, nii suurtele kui ka väikestele. WeNR, mis on valminud koostöös La Rochelle'i ülikooli ja EIGSI-ga, põhineb La Rochelle'i linnastu rahastatud doktoritööl ning INR Prantsusmaa, INR Šveitsi, Belgia ISITi ja Louvaini ülikooli (UCLouvain) vahelisel teaduskoostööl.</p>

<p>WeNR on saadaval 3 versioonis, et rahuldada võimalikult paljude organisatsioonide vajadusi: alates kvalitatiivsest küpsusauditist kuni võrdleva auditini tegevusvaldkondade kaupa, üksikasjalik kvantitatiivne ja rakendatav.</p>


</div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>WeNR Light</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">See veebis kättesaadav küsimustik võimaldab hinnata organisatsioonide küpsustaset digitaalse jätkusuutlikkuse (People-Planet-Profit) seisukohast. (2022. aasta septembri lõpp)</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>WeNR Standard</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">WeNR on kvantitatiivsete ja kvalitatiivsete küsimustike kogum, mille iga osalev organisatsioon peab täitma, kasutades ISITi pakutavat konkreetset failivormi. See küsimustik on veebis kättesaadav kuni 9. septembrini 2022, seejärel käsitletakse andmeid konfidentsiaalselt ja asünkroonselt, tulemused esitatakse 2–3 kuud hiljem esimese taseme aruande kujul.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>WeNR Plus</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>See tööriista versioon on reserveeritud ISITi liikmesorganisatsioonidele. WeNR + kasutab standardset WeNR mudelit ja kalkulaatorit. See pakub kvantitatiivsest, kvalitatiivsest ja võrdlevast seisukohast põhjalikke ja üksikasjalikke aruandeid sama sektori organisatsioonidega.</p>
</div></div></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e98ca209-cede-40c3-a2b8-680db2729109"><p dir="ltr" role="presentation">​​​​​​</p>

<p>Pakutavad analüüsivahendid võimaldavad kindlaks määrata tegevussuunad jätkusuutliku IT-strateegia loomiseks.</p>

<p>Nendele tööriistadele juurdepääsemiseks:<a target="_blank" href="https://wenr.isit-europe.org/">https://wenr.isit-europe.org/ </a></p>

<p>Hinnake oma individuaalset/professionaalset digitaalset ökoloogilist jalajälge CO2-ekv.</p>

<p>Et heita valgust mõjudele, mis on seotud meie digitaaltehnoloogia ületarbimisega, on ISIT loonud kalkulaatori, mis võimaldab meil lihtsalt arvutada oma professionaalset digitaalset mõju. Hindamine hõlmab järgmist:</p>

<ul>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Varustus</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Veebipõhine kasutamine</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Pilvepõhine salvestusruum</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Kirjade saatmine</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Ärireisid</p>
\t</li>
</ul>

<p dir="ltr">Ja näitab teie mõju võrreldes</p>

<ul>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">prantslase keskmine süsinikujalajäljega</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Pariisi kokkuleppe järgimiseks 2050. aastal järgitava kvoodiga</p>
\t</li>
</ul>

<p dir="ltr">Kalkulaatorile juurdepääsemiseks toimige järgmiselt:: <a target="_blank" href="https://myimpact.isit-europe.org/">https://myimpact.isit-europe.org/</a></p>

<p dir="ltr"></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-5a88bc86-804b-4334-a3d1-e2a3f3b4ff81"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b78923fbf8.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-75c025a2-65b8-4dba-99fa-ada96ab7a13c"><p>FRUGGR on rakendus, mis on välja töötatud ettevõtete IT-rakenduste keskkonna- ja sotsiaalse mõju mõõtmiseks. Regulaarne mõõtmine on pideva täiustamise võti. FRUGGR kogub automaatselt erinevaid toorandmeid: andmete kasutamist, lähtekoodi ja analüütikat. Tarkvara tugineb tunnustatud mõjuteguritele (Shift Projecti ühe baidi mudel, Ademe süsinikubaas, IEA, EEA ...), et arvutada võimalikult täpsed hinded. FRUGGR pakub süsiniku hindamist, mis hõlmab kõiki 3 mõõdet. Tööriist pakub ka sertifikaatide reitingute ruudustikku.</p>

<p dir="ltr">Link: <a target="_blank" href="http://www.fruggr.io">www.fruggr.io</a></p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/image-MJ9YeML0.png",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1f715907-f760-4fe6-a7a5-f73d1d0a9432"><p><span style="font-size:1.5em;"><strong>Strateegiline teekaart</strong></span></p>

<p>Prioriteediks peab olema vastutustundliku digitehnoloogia strateegilise tegevuskava määratlemine, mis põhineb konkreetsetel ja tõhusatel meetmetel ning milles on määratletud näitajad, oskuste arendamise programmi rakendamine, harta allkirjastamine, osalemine ülemaailmsetel üritustel, nagu digitaalne koristuspäev (tavaliselt märtsis), ülemaailmne teadlikkuse päev digitehnoloogia ökoloogilise jalajälje kohta.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-ba27207c-8a81-48a3-bf0e-b3e4e01270bc"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b790538667.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-91a70d9f-e554-44da-be68-4187d51895b9"><p>Nagu paljudes teistes valdkondades, on põhiprobleemiks tegevusetus ja läbipaistvuse puudumine. Edu saavutamiseks peab tegevuskava olema ka realistlik, tempokas ja jätkusuutlik. Näiteks on ohtlik väide, et iga organisatsioon võiks kuue kuu jooksul olla süsinikuneutraalne. Seda on võimatu saavutada, illusoorne ja utoopiline. See diskrediteeriks lõppkokkuvõttes projekti ennast.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-G9NDge8J.png",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-29debb56-e454-4201-9ad4-89a80df5fe27"><p><span style="font-size:1.5em;">Jätkusuutlikud IT-MOOCid</span></p>

<ul>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">ISIT ja tema partnerid pakuvad välja säästva IT MOOC-i , et võimaldada kõigil huvilistel olla informeeritud ja koolitatud. Teadlikkuse jätkusuutlik IT MOOC on lühike koolitusprogramm, mis võimaldab esmast lähenemist jätkusuutlikule IT-le kõigile huvitatutele. Täielik jätkusuutlik IT MOOC koosneb 4:30 videost, tekstist ja interaktiivsest sisust. See pakub teoreetilisi teadmisi jätkusuutliku IT-lähenemisviisi põhialuste valdamiseks. Need kaks moodulit on vaba juurdepääsuga: <a target="_blank" href="http://www.isit-academy.org">www.isit-academy.org</a></p>
\t</li>
</ul>

<ul>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Mooc Digitehnoloogiate keskkonnamõjude eesmärk on käsitleda digitaalsuse mõju keskkonnale, selle positiivseid ja negatiivseid mõjusid, täna jälgitavaid nähtusi ja teha prognoose tulevikuks. See on mõeldud haridusvahendajatele ja üldisemalt avalikkusele. Nende veebileht pakub iga osa kohta sisuesitlust, meelelahutuslikku ja harivat videot, kasutusvalmis õppekapsleid, kontseptsioonilehti teatud mõistete süvaõppeks ja bibliograafiat koos lisaressurssidega. Link: <a target="_blank" href="https://learninglab.gitlabpages.inria.fr/mooc-impacts-num/mooc-impacts-num-ressources/en/index.html">https://learninglab.gitlabpages.inria.fr/mooc-impacts-num/mooc-impacts-num-ressources/en/index.html</a></p>
\t</li>
</ul>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4a03a0c2-6e3d-4bc5-9ca9-83c9b0f413ac"><p><strong><span style="font-size:1.5em;">Vastutustundlike digitaalsete parimate tavade juhend organisatsioonidele</span></strong></p>

<p>Käesoleva juhendi eesmärk on suurendada teadlikkust organisatsioonides esinevate teatavate tavade mõjust ning jagada näiteid vastutustundlikest digitaalsetest tavadest, mis võivad aidata vähendada digitehnoloogia ökoloogilist jalajälge. Käesolev juhend on mõeldud igat liiki organisatsioonidele nii avalikus kui ka erasektoris. Mõned näited headest tavadest on teistest paremini kohandatud, sõltuvalt vastutustundliku digitaalse lähenemisviisi kontekstist ja küpsusest organisatsioonides. Vastutustundlikum digi on suur teema, mis ei puuduta ainult digiosakonda. Paljud funktsionaalsed osakonnad, nagu ostu-, personaliosakond, kommunikatsioon, haldus jt. on samuti kaasatud. Seetõttu on käesolev juhend suunatud seda tüüpi laiemale kasutajateringile nagu on IT-osakonnad, süsteemi- ja võrguadministraatorid, arendajad, ostjad, kasutajad jne.</p>



</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<p><span style="font-size:1.5em;"><strong>Digitaalne kainuse sertifikaat</strong></span></p>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-678b08eb-b4f7-4548-9eeb-d4b9fcbb8217"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b79a54737b.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0c979019-a342-4766-b91c-2a64ee1540b3"><p>Digitaalse kainuse sertifikaat arvestab, et kasutajad saavad seadmete valiku ja veebikäitumise kaudu oluliselt vähendada oma keskkonnamõju. Inspireerituna mitmes Euroopa riigis alates 2000. aastast kehtestatud energiasäästunõuetest, võimaldaksid need sertifikaadid võtta arvesse digitaaltehnoloogia keskkonnaalaseid välismõjusid ja parandada digitaalse turu teatavaid puudusi. Võib juhtuda, et lähiaastatel võib nende sertifikaatide kasutamine ettevõtetes areneda mõttega, et personalifunktsiooni ülesanne oleks koguda juhtidelt andmeid selle kohta, mis on kehtestatud, et julgustada töötajaid vähendama digitaaltehnoloogia kasutamist: koosolekupoliitika, e-kirjade saatmine, hübriidtöö, videokonverentsid jne.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-2mBACaFf.jpg",

        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-138519da-a516-4b09-8269-bf6315bd58ce"><p><strong><span style="font-size:1.5em;">Digiteenuste vastutustundlik kavandamine</span></strong></p>

<p>Üleminek digiteenuste vastutustundlikule kujundamisele tähendab väärtusloomet, kujundades digitaalseid tooteid ja teenuseid, mis on keskkonna, sotsiaalsest ja majanduslikust seisukohast tõhusamad. See on kooskõlas uute tulemusnäitajatega, millega ettevõtted peavad nüüd silmitsi seisma: lisaks majandustulemustele peavad organisatsioonid esitama ka tõendeid oma sotsiaalse ja keskkonnaalase tulemuslikkuse kohta. See ei tähenda, et teatavaid projekte heaks ei kiideta, vaid pigem seda, et digiteenuste ülesehitust tuleb parandada – näiteks mitte pingutada selle nimel, et töötada välja teatavad vähekasutatud funktsioonid, mis vaid raiskavad ressursse.</p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-19678c96-8c5b-41a1-999d-10f2fce2a381"><p><strong><span style="font-size:1.5em;">Automaatse väljalülitamise riistvara/funktsioonide kasutamine</span></strong></p>

<p>Cap Gemini hindas 24 säästva IT kasutamise juhtumit. Analüüs näitab, et riistvara ja funktsionaalsuse automaatne väljalülitamine annab suurima kokkuhoiu (keskmiselt 14%) tänu väiksemale energiatarbimisele, vähendades samal ajal selle kategooria süsinikdioksiidi heitkoguseid.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-625facb2-24e7-4f6e-a22b-082c8dbf4d7d"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b7a2a045c2.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-iNB15wRc.jpg",
        }
    ],
    "hybridHr": [
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-47f54b42-11d9-482f-8720-84285c76de62"><p dir="ltr"><strong><span style="font-size:1.5em;">PERSONALIPOLIITIKA</span></strong></p>

<p>Töötada välja poliitika, et tõsta teadlikkust säästva arengu küsimustest ja organisatsiooni tegevusest selles valdkonnas mitmes etapis: töölevõtmine, pidev koolitus...</p>

<ul>
\t<li>Viia läbi läbilõikeuuring, mis hõlmab NWoW ja heaolu ning säästva arengu küsimusi.</li>
\t<li>Tagada kõigi personalipoliitikate kooskõla süsinikdioksiidi heitkoguste vähendamise eesmärkidega.</li>
</ul>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-40fb375e-8fd6-46af-9670-4af0cb630dbb"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a000f9022c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-VtIux57G.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-51d2007f-0995-4d97-be89-ede2d14b5c4b"><p><strong><span style="font-size:1.5em;">VÄRBAMINE</span></strong></p>

<p><strong>Hindame kandidaatide teadlikkust ja väärtusi seoses säästva arenguga ning nende vastavust organisatsiooni ambitsioonidele ja eesmärkidele.</strong></p>

<p>Kas me näiteks värbamisel arvestame kandidaatide süsinikujalajälge? Kas me kontrollime, kas nende käitumine on kooskõlas organisatsiooni eesmärkidega?</p>

<p>Kliimaga seotud väljakutsed kujutavad endast märkimisväärset atraktiivsuse hooba kandidaatide jaoks, kes otsivad tähendust ja motivatsiooni töötajana, kes on juba pühendunud sellele küsimusele.</p>

<p>See tähendab, et üha enam on värvatvatel ja töötajatel sellised küsimused nagu milline on ettevõtte liikuvuspakkumine, kas on olemas kaugtööpoliitika, milliseid algatusi on tehtud säästva arengu edendamiseks jne. Kui ettevõttel ei ole selget poliitikat, siis on ta sunnitud improviseerima ja vastama juhtumipõhiselt, mis ei pruugi paljulubavaid kandidaate ligi meelitada.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-82f5014d-e54b-4a0f-8cab-1a43a166b8b1"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a005f92b37.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-5DD74X27.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0c101637-6a96-4185-86a2-7b6ad760abbd"><p><strong><span style="font-size:1.5em;">KOOLITUS</span></strong></p>

<p><strong>Selgitage, miks jätkusuutlik ümberkujundamine on ettevõtete ja töötajate jaoks probleemiks.</strong></p>

<p><strong>Esimene väljakutse </strong>seisneb selles, et mõista, mis on kaalul, alustades teadusest: mida teadlased ütlevad kliimamuutuste, loodusvarade, bioloogilise mitmekesisuse ja nende mõju kohta meie elule – kogukondadele, ettevõtetele, üksikisikutele.</p>

<p><strong>Teine väljakutse </strong>on seotud tegevustega, kuna jätkusuutlik ümberkujundamine puudutab kõiki kutsealasid. Töö kohta tuleb aru saada, kuidas, siis koostada oma teekaart tegevuseks spetsiaalsete kursustena sõltuvalt sellest, kas töö on ostu-, IT-, õigus-, finants-, turundus-, personaliosakonnas jne.</p>

<p>Koolitage juhti, kuidas juhtida töötajate pühendumust: kuidas reageerida, kui näiteks parim müügimees, kui ta tuleb koos meeskonnaga seminarile, tuletab meelde, et ta enam ei lenda?</p>

<p>Tutvustage ökoloogilisi mõõtmeid ja nende hindamist.</p>

<p>Prognoosige tulevaste oskuste nõudeid: millised on uue raamatupidaja, uue IT-spetsialisti, uue ostujuhi, uue personalitöötaja jt. oskused jätkusuutlikkusele ülemineku kontekstis?</p>

<p>Keskkonnakriteeriumi integreerimine ettevõtte teenusepakkujate valikusse, eelkõige koolituse puhul. Alati on võimalik teavitada ja vaidlustada teenuseosutajaid selle valikukriteeriumi kohta, et julgustada neid tegutsema.<br>
</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8e9a2632-788a-489a-a9d0-a5cec6137a3a"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a00b6f3b38.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-H35LwF6W.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-78bed962-f188-4975-8f73-b0dea716bef1"><p><strong><span style="font-size:1.5em;">TULEMUSLIKKUSE JUHTIMINE &amp; HINDAMINE</span></strong></p>

<p>Ettevõtted seavad endale üha enam säästva arengu eesmärke, mille saavutamiseks tuleb neid rakendada osakondades ja meeskondades. Seetõttu on oluline hinnata, mida juhid teevad oma meeskondadega nende eesmärkide saavutamiseks.</p>

<p><strong>Integreerige säästva arengu küsimused töötajate iga-aastastesse eesmärkidesse ja nende oskuste arendamise kavasse.</strong></p>

<p>Väga vähesed ettevõtted on seni need jätkusuutlikkuse eesmärgid tulemusnäitajateks muutnud. Kui nad seda teevad, viitavad nad tavaliselt ÜRO 17 säästva arengu eesmärgile, kuid see peab olema palju konkreetsem.</p>

<p>Võtke seda arvesse ka igapäevase käitumise osana: arvestage jätkusuutlikkuse aspekti koosolekuzte korraldamisel, firmapeo korraldamisel, koolituspakkumise üle otsustamisel, reisivalikute tegemisel jne.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-bcf69465-7d98-4b54-8f57-16c62f1e31cf"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a010e1bb7c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-xUv8hn2l.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c5702d04-36d7-48a5-bdc3-dfd6ae0b7e6e"><p><strong><span style="font-size:1.5em;">TASUSTAMINE</span></strong></p>

<p>Palgapaketti kuuluvate soodustuste valikul või säästva arenguga seotud kriteeriumide integreerimine tuleks sisse viia nii direktorite, juhtide kui ka kõigi muude töötajate tasustamisse. Kui töötajad saavutavad oma tulemusi, kuid keskkonna arvelt, siis, kas seada nende preemia kahtluse alla?</p>

<p><strong>Soodustage ja hinnake oma töötajate keskkonnahoidlikku käitumist.</strong></p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-31d857a2-1f29-40e7-bf53-f14345717159"><p><strong><span style="font-size:1.5em;">TÖÖ PAINDLIKKUS</span></strong></p>

<p>Pakkuge alternatiivseid töökohti võimalikult lähedal töötajate elukohale (kolmandad kohad, koostöötamise ruumid jne).</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-335b1072-9195-49d8-bb14-1838c832c36b"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a013f7d826.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-aSD8KIPy.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<p><strong><span style="font-size:1.5em;">KOMMUNIKATSIOON</span></strong></p>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-Er7RBrXn.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.EmployeeChampion],
            "heading": false,
            "description": "<p>Suhtle regulaarselt, mitte ad hoc. Näiteks ei piisa iga-aastasest teabevahetusest, kui soovite oma töötajaid kaasata otsustusprotsessidesse.</p>"
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": false,
            "description": `<p>Olge autentne ja läbipaistev, eriti mis puutub veel saavutamata edusammudesse. Teie sõnavõtt peab olema kooskõlas ettevõtte tegeliku kliimakujundusega, vastasel juhul ei võta töötajad seda hästi vastu.</p>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": false,
            "description": `<p>Suunake oma kommunikatsioon konkreetsetele teemadele. Näiteks: "tööriistakomplekt, et tegutseda ettevõtte tasandil ökoloogilise ülemineku nimel"; "kuidas investeerida oma töötajate säästud keskkonda teenivatesse fondidesse" jne.</p>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": false,
            "description": `<p>Mitmekesistage kasutatavaid formaate (memo, video, podcast, esitlus jne) ja e-kanaleid (e-post, intranet, ettevõtte sotsiaalvõrgustik, road show jne), et maksimeerida oma sõnumite levikut.</p>`
        }
    ],
    "mobilityHr": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(TÖÖKORRALDUS)  - Kaugtöölepingute vormistamine</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Veel kolm-neli aastat tagasi oli kaugtöö suures osas mitteametlik. Täna muutub see üha ametlikumaks, kuna see on pärast tervisekriisi laialdaselt kasutusele võetud suurtes organisatsioonides. Selline formaliseerimine võib toimuda ettevõtte poliitika ja/või tööandja/juhtide ja ametiühingute/töötajate vaheliste arutelude vormis suuniste üle, mille raames tööandja soovib kaugtööd kujundada. Seistes silmitsi ühistranspordi ja teedevõrgu küllastumisega, on väljakutse arendada kaugtööd nende liikuvusvoogude reguleerimiseks.</p>
</div></div></div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/image-rWDYpb9a.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(PREEMIAD) Säästva liikuvuse pakett</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Samamoodi nagu töötajate ühistransport, pakub selline skeem teatavat liiki hüvitist neile, kes tulevad tööle, kasutades "vooruslikke" transpordiliike, näiteks jalgrattasõitu või koossõitu. Mis puutub ametiautodesse, siis diiselmootoriga autode keelustamine, bensiinimootoriga sõidukite asendamine heitevabade sõidukitega.</p>

<p><strong>Prantsusmaal </strong>saavad tööandjad maksta säästva liikuvuse paketti oma töötajatele, kes kasutavad oma tööreisidel säästvat transpordivahendit. Hõlmatud on järgmised transpordivahendid: isiklik jalgratas (sealhulgas elektrijalgratas), koossõit kaassõitja ja juhina, jagatud liikuvusteenused (elektri- või hübriidautode ühiskasutus, tõukerataste või jalgrataste rentimine ja iseteenindus), ühistransport (välja arvatud perioodipiletid). Säästva liikuvuse paketi maksimaalne summa on 700 eurot aastas töötaja kohta. Makstud summa on vabastatud sotsiaalkindlustusmaksetest ja tulumaksust.</p>

<p><strong>Belgias </strong>on jalgrattatoetus läbisõidutoetus, mida tööandja maksab töötajatele, kes kasutavad jalgratast kodu ja töökoha vahelise vahemaa täielikuks või osaliseks läbimiseks. Toetuse eesmärk on katta jalgratturi kulud, kuid eelkõige on selle eesmärk julgustada rohkem töötajaid oma jalgratast kasutama. Kilomeetrite soodustus on maksuvaba kuni 0,25 eurot läbitud kilomeetri kohta 2023. Majandusaastal. Teisisõnu, kuni läbisõiduhüvitise summa ei ületa 0,25 eurot läbitud kilomeetri kohta, ei tule maksu maksta. Kui tööandja maksab suuremat läbisõidutoetust, maksustatakse see ülejääk kutsetuluna.</p>

<p><strong>Kontrollige, millised võimalused teie riigis olemas on!</strong></p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(TÖÖAEG) soodustage pehme liikuvusega reisimist</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Tööandja võib arvestada liikuvusaega tööajana, kuid ainult pehme liikuvusega reisimise korral - st mis tahes transpordiliik, mis ei kasuta sisepõlemismootorit ega eralda kasvuhoonegaase. Sellel on täiendav eelis, mis annab inimesele võimaluse reisida väljaspool tipptunde.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(VÄRBAMINE) Värbamine kohalikul tasandil</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Ühe parameetrina, mida võiks kasutada värbamisel, oleks kandidaatide valikus arvesse võtta nende kodu ja kontori vahelise kaugust. Seda võib olla vägagi raske aktiveerida, kui tööturg on pingeline või sobivaid profiile on harva leida. Tänu väga tugevale kogemusele, mille oleme omandanud kaugtöös - mida nõuab Covid-19 -, on kohalik värbamine iseenesest jätkuvalt mõistlik, kuid tundub veelgi raskem praktiseerida.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(HEAOLU) Liikuvusega seotud küsimused</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Liikuvus on üks tööheaolu alustalasid. Lähenemisviisid võivad siis olla vägagi valdkonnaülesed. Jalgrattasõidu edendamine võib näiteks aidata parandada füüsilist vormi, stimuleerida keskendumist tööl ja isegi edendada kogukonnaelu.</p>


</div></div></div>`
        }
    ],
    "sustainableHr": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-752b8e3c-11a1-4c42-973e-dc3ef7f73ad2"><p dir="ltr"><strong><span style="font-size:1.5em;">Ole kaasatud strateegilise partnerina</span></strong></p>

<p>Reeglina ei osale personalimeeskonnad jätkusuutlikkuse strateegiate väljatöötamises – eriti vastutustundlike IT-strateegiate puhul – ega nende rakendamises. Nagu enamiku «roheliste» algatuste puhul, kipub juhtkond edendama suundumusi ja tugineb nende rakendamisel keskastmejuhtidele. Kuid kuna nemad omakorda mõjutavad töötajate palkamist ja neil on mõju ettevõtte kultuurile, võivad personalitöötajad olla nende standardite kehtestamisel olulised.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8eb9a5dd-38d3-4496-b38d-d53e12fad37c"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b6c7a88e25.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-365fb268-3738-49d5-bf8b-e5a5432ff57a"><p>Personalijuhina peate toetama vastutustundlike IT-standardite väljatöötamist kogu organisatsioonis ja tegema koostööd IT-osakonnaga töötajate ülesannete, rollide ja vastutuse määratlemisel.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-nt1UFmSu.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-ad83967d-c3aa-4c3a-b142-0ba6278bcfe8"><p><strong><span style="font-size:1.5em;">Vastutustundliku IT-käitumise formaliseerimine</span></strong></p>

<p>Formaliseerida töötajate vastutustundlik IT-käitumine poliitikate väljatöötamise ja juurutamise kaudu. Need poliitikad võiksid hõlmata järgmist:</p>

<ol>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Pilve kasutuspoliitikad, mis määratlevad pilveteenuste optimeeritud kasutamise töötajate, töövõtjate ja kolmanda osapoole teenusepakkujate poolt.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p>Poliitikad, mis nõuavad töötajatelt tööpäeva lõppedes arvuti väljalülitamist või nõuavad töötajatelt nutitelefonide ja tahvelarvutite kasutamist laua- või sülearvutite asemel, et tarbida vähem energiat ja vähendada süsinikujalajälge.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p>IT-seadmete vastutustundliku kasutamise põhimõtted. Head näited on järgmised poliitikad:</p>
\t</li>
</ol>

<ul>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Tooge oma seade (BYOD) poliitika rakendamine - Töölepingus võiks ette näha, et töötaja võib kasutada oma arvutiseadmeid ja et talle tuleb see hüvitada.</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p>Tutvustage ettevõttele kuuluvat, isiklikult lubatud (COPE) poliitikat - Teine hoob on töövahendite kasutamine isiklikuks kasutamiseks kodus. Varem oli äärmiselt haruldane, et ettevõtted seda lubasid.</p>
\t</li>
</ul>

<p><strong>Mõlemal juhul tagavad need lähenemisviisid, et inimesel ei oleks töösülearvutit ja koduarvutit eraldi. Sülearvuti käivitamiseks on võimalik planeerida valikuline alglaadimine kahel viisil, üks tööks ja teine isiklikuks kasutamiseks.</strong></p>



<p><em>Hea praktika</em></p>

<p dir="ltr"><strong>Töö läbi «kohviku plaani»</strong></p>

<p dir="ltr"><span style="font-size:0.875em;"><em>(kui teie siseriiklikud õigusaktid seda tüüpi süsteemi lubavad)</em></span></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-c400386d-d3f1-4075-8e89-74d85fed26e6"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b6ea96369c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-96f393ca-7d6a-4bd8-ab36-66d978b4130a"><p>Belgias on "cafetaria plan" programm, mis võimaldab töötajal ise valida töötasu teatud elemente, kuid alati tööandja poolt eelnevalt kindlaks määratud raamistikus.</p>

<p>Sellises raamistikus võiksite pakkuda stiimuleid taastatud seadmete ostmiseks uute seadmete asemel, samamoodi saate kergemini subsideerida elektrijalgrattaid ja vähem suuri autosid.</p>

<p dir="ltr"></p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-EUUxAwta.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-497686b5-fb48-4cd7-83ee-6654c0d12df9"><p dir="ltr"><span style="font-size:1.5em;"><strong>(KOOLITUS) Vähendage kasutamist, suurendades teadlikkust ja lisades koolitusi</strong></span></p>

<p>Koolitusel on oluline mõõde: personalijuhid ja -osakonnad peavad tagama, et nende organisatsiooni inimestel on suutlikkus viia ellu vastutustundlikku digistrateegiat, seda mõistes, integreerides ja juurutades. Ilma mõistuse ja selle taga olevate inimesteta jääb see soovunelmaks.</p>

<p>On mitmeid häid tavasid, mida tuleb edasi anda, olgu see siis seadmete, video kasutamise või isegi e-kirjade kohta.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-b33c71b4-d6d9-4523-a973-e59050b4fc11"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b6f84025db.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-84dfb087-83d8-46cb-a6c5-9d79a26fbeca"><p>Näiteks selle asemel, et saata dokumente e-posti teel, on parem neid jagada ühise kausta kaudu. Link ühisdokumendile võimaldab inimestel töötada ühe eksemplariga, selle asemel et saata üksteisele kommenteeritud dokumente, mida paljundatakse mitmes serveris. E-kirja saatmisel valige ulatuslike aadressiloendite asemel vaid vajalikud adressaadid.</p>

<p>Mõnikord on soovitatav e-kirjad kustutada. Siiski on tõsi ka see, et mõnel juhul võib e-posti kustutamisel olla rohkem negatiivseid mõjusid kui nende hoidmine - mis võib tunduda intuitiivne! Kimpude kustutamine (näiteks kõik e-kirjad enne teatud kuupäeva) on vastutustundlik otsus, mis tuleks võimaluse korral jõustada. Seevastu hoolikas, juhtumipõhine e-kirjade kustutamine kasutab ulatuslikke IT-ressursse ja muutub keskkonnale kulukamaks kui nende ladustamine.</p>

<p>Oluline on mitte saata andmeid kõigepealt, kui see ei ole vajalik. Andmete saatmine kulutab ressurssi samavõrd kui nende ühe kuni kaheaastane säilitamine.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-7f6e4a48-84b3-4f31-8416-0206ba746cd6"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b6f743acbb.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d162dbf3-2ecf-4474-b605-3dae52c594d3"><p>Grupivestlus on tavaliselt keskkonnatõhusam kui e-kirjad, eriti kuna see võimaldab ajaloost leida spetsiifilist infovahetust, mis on salvestatud ühte kindlasse kohta. Kuid jällegi, seni, kuni me tekstist kinni peame, on see marginaalne. Suurem efekt tekib videote ja vähemal määral fotode üleslaadimisel gruppi, pigem on sellel reaalne mõju.</p>

<p>Enne uue juhendi loomist on kõige parem kontrollida, et sellel teemal pole juba mõnda olemasolevat.</p>

<p>Mõned näited koolitusel omandatavatest oskustest:</p>

<ul dir="ltr">
\t<li role="presentation">Globaalsete keskkonnaprobleemide mõistmine</li>
\t<li role="presentation">Digitehnoloogia mõju mõistmine</li>
\t<li role="presentation">Seadme elutsükli mõiste</li>
\t<li role="presentation">Digiteenuse elutsükli mõistmine</li>
\t<li role="presentation">Tarbimistegurite tuvastamine veebilehel, tarkvaras jne.</li>
\t<li role="presentation">Tehnoloogia otsese ja kaudse mõju kindlakstegemine</li>
\t<li role="presentation">Seadmete ostmise, elektri- ja elektroonikaseadmete jäätmete käitlemise ja korduskasutamisega seotud küsimuste ja heade tavade valdamine</li>
\t<li role="presentation">Võimalus viia läbi teadlikkuse suurendamise kampaaniat ettevõttesiseselt või koos tarnijate ja teenusepakkujatega jne.</li>
\t<li role="presentation">Digiteenuste kättesaadavus ja ökodisain</li>
\t<li role="presentation">Võime mõõta energiatarbimist digiseadmete kasutusetapis</li>
\t<li role="presentation">etc.</li>
</ul>

<p dir="ltr"><em><strong>Allikas: Responsible Digital Best Practices Guide for Organisations, 2022</strong></em></p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-KOyYsXyp.jpeg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d4f52298-4fcd-41dd-bbc1-ff9edd5a07c9"><p><strong><span style="font-size:1.5em;">(ANDMED JA ANALÜÜS) Tava, mida tuleb kahtluse alla seada</span></strong></p>

<p>Personalijuhtimisega seotud tegevused ei ole pääsenud analüütika ja andmete haibist... Kas õigustatult?</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-29bc9c0c-9fcc-47fc-adfa-8dc6e8b82f1c"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b6fcf40937.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3afc5921-814b-40b5-be49-8f4c1b457dbe"><p>Institut du Numérique Responsable'i ekspertide sõnul on see teema väärt arutelu ja seda mitmel põhjusel. Väga sageli kasutavad personaliteenused ettevõttes toimimiseks tohutul hulgal andmeid. Selliseid andmeid itereeritakse ja lahterdatakse sageli erinevates kohtades (kaustades, serveritel jm. andmekandijatel). Peame vältima andmete dubleerimist, püüdes hoida käigus keskpunkti, kus esmased andmed on kättesaadavad ja kuhu vajaduse korral saame minna ja need kergesti üles leida. See välistab pideva kopeerimise vajaduse ja seega paljundada kordades andmete kogused.</p>

<p>Teisest küljest on tõsi, et andmeanalüütika on personalijuhtimises tõusuteel. Mida suurem on organisatsiooni struktuur, seda huvitavam võib statistika olla. Kuid paljudel juhtudel on meie riikide ettevõtted väikesed, kuigi esineb ka mõned erandeid. Seetõttu peame seadma kahtluse alla selliste vahendite tegeliku asjakohasuse: kas kasutame neid sellepärast, et need on tõesti meile kasulikud, või sellepärast, et kõik teised seda teevad? Taandame oma vajadused tõeliselt kasulikuks. See ongi kogu kainuse väärtus, mis kehtib ka IT kohta!</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-fwAS3Cih.png",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6ea068da-64a4-4efe-bb3e-01bf802add29"><p><strong><span style="font-size:1.5em;">(HINDAMINE JA TUNNUSTAMINE) Väärtusta positiivseid tegevusi </span></strong></p>

<p>Väärtuspositiivsed tegevused tunnustavad töötajate tegevuse põhjal, keda tuleks ja tuleb premeerida, et neid stimuleerida. Siin on personalitöötajad esimesed, kes on eesliinil tunnustatavate leidmisel. Lõpetame ainult müügitulemuste, saavutatud miljonite arvu, väljaannete või jälgijate arvu hindamise.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-791352ae-e018-4f65-a0ea-09af2c26f5f8"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b71b368d4e.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-92591520-9f7c-4687-a25f-cd04c71fdae6"><p>Väärtustagem ka positiivseid tegevusi kliima, sotsiaalsete probleemide ja heaolu heaks. Anname aru, mida tehakse hästi, mida tuleb teha. See tähendab hindamis- ja tunnustamistavade ülevaatamist.</p>

<p>Hindamisintervjuudesse tuleb lisada tõeline vastutustundlik digitaalne komponent: "Kui kaua on teil olnud arvuti ja nutitelefon?", "Milliseid ökotegevusi te teete?", "Milliseid tavasid saaksite muuta jätkusuutlikumate digitaalsete tavade vastu?" on küsimused, mida tuleb esitada ja aruteludesse kaasata. Igal ametialasel vestlusel tuleks taotleda vastutustundliku digitaalse kasutamise tegevustabelit.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-ZECdRYBX.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4c81c2d8-43d1-4fb3-9ec9-e1e9e578b626"><p><strong><span style="font-size:1.5em;">(TASU) Kas osa muutuvpalgast peaks olema seotud vastutustundlikumate IT-tavade rakendamisega?</span></strong></p>

<p>Kasvav trend leiab, et preemia on selgelt parem kui kärbe: porgand on parem kui kepp. Selle asemel, et karistada mittesäästvat käitumist (ja riskida töötjate vallandamise või isegi lahkumisega), on ettevõttele alati kasulikum tajuda käitumise muutust positiivse lähenemise kaudu. Seega, selle asemel, et kärpida töötaja palka, kes ei järgi rohelisemat käitumist, premeerige neid, kes seda teevad!</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d23a97ff-921c-43bd-be83-e9d189423939"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b7194630d5.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8b65cbfd-c38d-478a-9698-541665e3f2c6"><p>Teisest küljest tuleb preemiate ja kärbete määratlemistega tegeleda ettevaatlikult ja ennekõike mitte kasutada seda, et töötajad oleks üksteise vastu õhutatud. Öelda, et ainult "parimad" saavad osa koogist, on kahjulik ja ebatervislik. Eriti kehtib see rohelise käitumise puhul: enamik rohetegutsejatest ei tee seda lisasissetuleku pärast.</p>

<p>Veelgi enam, preemia andmine võib moodustada suurema kulu, mis ei ole samuti keskkonnale hea lahendus. Sotsiaalse kaasatuse väärtustamiseks on ka teisi viise kui rahaline lähenemisviis ning selleks tuleb leiutada uuenduslikumaid mooduseid.</p>

<p>Palju parem oleks muuta vastutustundlik digitehnoloogia arengu- ja julgustusprotsessi osaks, et muuta see osalusprotsessi tugevuseks ja võimaluseks.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-MOtrX9gI.jpg",
        }
    ],
    "hybridInspiringInitiatives": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d50e317c-49f4-4ce7-b827-1e7618ea8802"><p><span style="font-size:1.5em;"><strong>Integreerige üksikisikute (kaugtöötajate) süsinikujalajälg ettevõtte süsinikujalajälje arvutamisse</strong></span></p>

<p>Kuigi individuaalset süsinikujalajälge ei arvestata organisatsiooni süsinikujalajälje mõõtmisel, tuleb kaugtöö korral seda siiski arvesse võtta, kuna töötegevused nihkuvad töötaja kodusesse keskkonda. Kaugtöötajate individuaalne süsinikujalajälg suureneb tõenäoliselt, kuna nad kasutavad rohkem elektrit ja kütet ning mõnel juhul on vajadus lisaruumi järele koduses keskkonnas, mis suurendab neid heitkogust veelgi.</p>


</div>`,
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1fe06bb5-7157-48c0-b078-a9a1bbedf826"><p><strong><span style="font-size:1.5em;">Vähendage ümberpaigutamisprojektide ja uute tehnoloogiatega seotud ruutmeetrite arvu hoone kütmisel, jahutamisel.</span></strong></p>

<p>Uute tööviiside juurutamine tähendab tegelikult tööjõu vähendamist kontorites. Olemasolevaid kontoreid saab ümber kujundada ja kontoripinda saab vähendada ruutmeetrite osas. See on samuti hea võimalus investeerida energiatõhusamatesse küte- ja jahutustehnoloogiatesse.</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-2e6c4420-0191-429c-ae35-3c163eee07a7"><p><strong><span style="font-size:1.5em;">Sulgege peakorter üheks päevaks nädalas.</span></strong></p>

<p>Pakkuge alternatiivseid lahendusi neile, kes ei ole huvitatud kodutööst, et töötada ettevõtte teistes kontorites, mis jäävad avatuks.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-9bb64ff6-9682-46c1-b4d0-851b3c36cfb3"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-659e73b7cfb38.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-fe2e9f0a-6c9f-4004-a698-7d8150687ee6"><p><strong>Näide:</strong></p>

<p><em>Alates 2022. aasta maist on Belgia kindlustusselts Ethias sulgenud oma riiklikud kontorid (Liege ja Hasselt) üheks päevaks nädalas ja kutsub oma töötajaid üles töötama kodus. Samuti lubab ta kodutööd kuni 3 päeva nädalas, st 60% tööajast. See Belgia turul uuenduslik meede võimaldab Ethiasel vähendada oma sõltuvust fossiilkütustest. Antud uuendus lisandub viimastel aastatel rakendatud arvukatele algatustele, mis on võimaldanud Ethiasel vähendada oma süsinikujalajälge kolmandiku võrra, mis on neli aastat enne kavandatud tähtaega.</em></p>

<p><em>Kuigi liikumine kaugtöö suunas on kooskõlas enamiku töötajate sooviga, on neil, kes seda ei soovi, endiselt võimalus veeta oma tööpäev mõnes teises ettevõtte kontoris, mis asub mujal. "Enne hoonetele juurdepääsu ühtlustamist analüüsis Ethias pikalt ja hindas mitmeid stsenaariume, et tagada, et need oleksid kasulikud nii töötajatele kui ka keskkonnale. CO2Logic'i andmete kohaselt on kaugtöö ökoloogiline kasu positiivne, niipea kui töö- ja kodu vahemaa on suurem kui 2 km, mis kehtib üle 90% meie töötajate puhul.”</em></p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-Ux4xbbe0.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3316ea8d-10cc-4b2e-8619-da7fe9bbc817"><p><strong><span style="font-size:1.5em;">Rakenduse väljatöötamine töötajatele, et nad saaksid enne tööle tulekut valida, millise tööruumi nad tööle asuvad.</span></strong></p>

<p>Hübriidtöö tõhusaks toimimiseks peab organisatsioon pakkuma vahendeid, mis hõlbustavad kontoripinna kasutamist päevadel, mil töötajad kontorisse tulevad. Töötajatele võimaluse andmine valida enne tööle tulekut kontoriruum, arendades selleks rakendust, on hea lahendus. Internetis leidub rakendusi, mis saab kergesti kohandada organisatsiooni hõivatud ruumide jaoks.</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c6362667-2265-4e67-bf80-74a50e2a5925"><p><strong><span style="font-size:1.5em;">Ettepanek: tööandja võimalus investeerida maksuvaba töötasu abil lahendustesse, mis vähendavad süsiniku jalajälge töötaja töökohal</span></strong></p>

<p>Võiksime ette kujutada, et üks osa töötasust, mis täna on maksustatav, konverteeritakse geotermilise paigalduse või päikesepaneeli või veekogumisseadme või muu sarnase investeeringu arve tasumiseks töötaja asukohas (kodus).</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d7c13367-6a61-421e-a373-eebd7190de95"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650ae2a5b19be.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-GwVFSFMm.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-706a5767-ffee-4003-b5a9-c2fd99b9978d"><p><strong><span style="font-size:1.5em;">Kasutage gamification’i</span></strong></p>

<p>Mäng "Power ranger", et mobiliseerida töötajaid energiatarbimise vähendamiseks.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-49e80623-fa73-4a32-ad4b-77f75ce8a5c4"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a245a56b04.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-EUWiWwyc.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5ea9601e-15c0-41fa-b006-440a9fd9e737"><p><strong><span style="font-size:1.5em;">Individuaalse nõustamise pakkumine</span></strong></p>

<p>Abistage iga töötajat eraldi, kuna igaühel on erinev olukord - alates transpordiviisi muutmise valiku toetamisest, kuni abi muutmiseks nende enda juures – nt väikese rahalise stiimuliga.</p>
</div>`
        }
    ],
    "mobilityInspiringInitiatives": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Sulgege parkimiskohad</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Et takistada autode kasutamist töölkäimiseks, eemaldasid mõned ettevõtted ettevõtetes reserveeritud parkimiskohad. Kui kõigile töötajatele on sihtkohas peaaegu tagatud koht, kasutavad nad autot tööle sõitmiseks palju rohkem kui siis, kui nad seda ei teeks.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Jagatud liikuvuse hõlbustamine</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Brasiilia energiaettevõte töötas välja rakenduse, et hõlbustada jagatud liikuvust oma São Paulo asukohas: kasutajad sisestasid oma pendelrändemarsruudi ja rakendus ühendas andmed kõigi teiste sisenditega ning tuvastas sarnaseid marsruute järgivaid töötajad. Jagatud liikuvus kasvas 18% võrra ja säästis ettevõttele 300 000 USA dollarit aastas.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Julgustage jalgrattasõitu</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Uuenduslikumad parimad tavad juhtumiuuringutes hõlmasid jalgrataste parema turvalisuse tagamist (individuaalselt lukustatavad parkimisalused; politsei märgistamine või jalgrattakindlustusskeemide pakkumine); jalgrattavarustuse laenutus (akud, tuled, pumbad, lukud, kiivrid, autohoidikud, lasteistmed jne); kohaspetsiifilised tsüklikaardid; rahalised stiimulid ja tasuta tooted (näiteks kaitsevestide pakkumine).</p>

<p>Näited positiivsetest meetmetest: partnerlus kaubajalgrataste rentimiseks tarnimiseks või kasutamiseks tehniliste töötajate poolt, elektrijalgrataste laenutuskampaania, et võimaldada töötajatel neid katsetada, tööriist "Bike to work", et mõõta süsinikuneutraalsust ja osaleda erinevates väljakutsetes.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Detsentraliseeritud ühised bürood</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Tsentraliseeritud jagatud kontoriruumide pilootprojekt, mis võimaldab neil, kes ei saa mõnel päeval kaugtööd teha, mitte tulla peakorterisse, kui nad elavad kaugel või kui see on nende jaoks liiga keeruline. Lahendus loodi läbi koostöötamise operaatori, tsentraliseeritud arveldussüsteemiga.</p>

<p><strong>Muutke rong konkurentsivõimelisemaks</strong></p>

<p>Ärireiside poliitika:</p>

<ul>
\t<li>
\t<p>Rahaliste vahendite eraldamine lennukist kõrgema hinna kompenseerimiseks, et muuta rongid konkurentsivõimeliseks.</p>
\t</li>
\t<li>
\t<p>Mitte lubada lennukisõitu, mis kestab vähem kui 2 tundi, kui sama tee saab läbida rongiga.</p>
\t</li>
</ul>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-bd0d1aab-e9f3-4954-82d7-5ca803053822"><p><strong><span style="font-size:1.5em;">Maksa selle eest, mida tarbid" lähenemine</span></strong></p>

<p>Maksan seda, mida tarbin" lähenemine ametiautodele: inimesed kontrollivad, mida nad tarbivad + kilomeetrite hüvitamine, mida pole tarbitud või üle tarbitud/ mõjutab liisingu hinda ja eraldatavat eelarvet.</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": '',
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6a2b61ed-ff09-4ac7-b91e-57a126803fc6"><p><strong><span style="font-size:1.5em;">HEA TAVA</span></strong></p>

<p><strong><em>Solvay käivitas ettevõttereiside jaoks sisemise süsinikuhinna</em></strong></p>

<p><strong><em>Solvay käivitab uue algatuse, et jälgida ettevõtte süsinikureiside jalajälge, koguda raha grupi jätkusuutlikkuse projektide toetamiseks ning suurendada teadlikkust vastutustundlikust ja jätkusuutlikust reisimisest. Algatuse eesmärk on rõhutada iga töötaja individuaalset süsinikujalajälge.</em></strong></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-729c6f13-e17a-4f6e-9735-eca08661ed72"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650ae6a3ab523.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c18ce683-799a-4f5b-8956-09bf30e83725"><p><em>Alates 2019. aastast on Solvay vähendanud oma üldist lennu- ja rongireisidega seotud süsinikukasutust ligi 70%, seda suuresti Covid-19 pandeemia tõttu, aga ka Solvay uue tööviisi rakendamise tõttu, kus digitaalsed tööriistad on muutnud virtuaalsed külastused kõigile hõlpsasti kättesaadavaks.</em></p>

<p><em>Grupp on nüüd keskendunud nende uute käitumisviiside säilitamisele ja ettevõtte reiside süsinikujalajälje minimeerimisele, planeerides vähem isiklikke kohtumisi, vähendades rahvusvaheliste lendude arvu, surudes maha ühepäevased lennureisid ja soovitades minna ärilt üle turistiklassile või lennult raudteele, kui need alternatiivid on olemas. Neid meetmeid rõhutatakse kui viisi, kuidas suurendada teadlikkust igaühe individuaalsest süsinikujalajäljest.</em></p>

<p><em>Solvay reisijalajälje jälgimisest rahastatakse lõpuks fondi, mida kasutatakse jätkusuutlikkuse projektide sponsoreerimiseks, keskendudes süsinikdioksiidi kompenseerimisele, kui see on teostatav. Globaalsed äriüksused panustavad (majandus)üksuse reisijalajäljel põhineva rahalise summa, mis arvutatakse 100 eurole ühe tonni CO2 kohta. See summa võetakse igalt üksuselt ja kantakse üle grupireiside süsinikdioksiidi sissemakse fondi.</em></p>

<p><em>Selle sissemaksefondi eesmärk on püüelda ÜRO jätkusuutlikkuse eesmärkide poole ja julgustada iga töötajat andma oma osa planeedi kaitsmiseks omanike ja kodanikena.</em></p>


</div>
</div>`
        }
    ],
    "sustainableInspiringInitiatives": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3f37caaa-982f-468c-be2a-72514d04656a"><p><strong><span style="font-size:1.5em;">Loo narratiiv</span></strong></p>

<p>Narratiivi loomine võib juhtida sotsiaalset orientatsiooni ja kaasata töötajaid. Erinevate meetmete rakendamist, näiteks e-posti vähendamist, võib ebaõiglaselt pidada rohepesuks. Seetõttu on vaja luua ettevõttes ühtne narratiiv. Teil peab olema rääkida tõeline lugu, mis kehastab pühendunud, süsteemset ja pikaajalist lähenemist. See narratiiv peab muidugi algama väikestest sammudest, kuid peab olema ka ambitsioonikam.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6326cb82-49ee-40c6-8a63-fa8377d14d27"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b7c89cd21f.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-fmT9o4iB.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d67971a-f08c-4bc2-8cb9-0d43903d2d07"><p><strong><span style="font-size:1.5em;">Töötada organisatsioonis välja "vastutustundlike digitaalsete" referentside võrgustik</span></strong></p>

<p>Selline võrgustik võimestab varajasi kasutuselevõtjaid (pioneere), kes on teie poliitika parimad saadikud. Neil on paremad teadmised oma organisatsioonidest, eriti kui need on jaotatud mitme asukoha vahel. Varased kasutuselevõtjad, kes on koolituse läbinud, omades vastavaid pädevusi, mobiliseerivad nende käsutuses olevaid ressursse teabe sisemiseks levitamiseks.<br>
</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-98d6b18e-4994-4314-bbaf-bf7d295acc68"><p><strong><span style="font-size:1.5em;">Piirata asukohateavet</span></strong></p>

<p>Geograafilise asukoha määramise ressursid (näiteks Google Maps ja Waze) on reisimist põhjalikult muutnud, kuid pikkade reiside ja üldise liigkasutuse tõttu võivad nad ära kasutada märkimisväärse hulga andmeid. Nõuanded kasutajatele:</p>

<ul>
\t<li>
\t<p>Piirake asukohatuvastusrakenduste kasutamist varem külastatud sihtkohtadesse reisimisel.</p>
\t</li>
\t<li>
\t<p>Kasutage ainult ühte geograafilise asukoha määramise teenust.</p>
\t</li>
\t<li>
\t<p>Lülitage mobiilne andmeside välja, kui geolokatsioon pole vajalik (näiteks pika maanteesõidu ajal).</p>
\t</li>
\t<li>
\t<p>Võimaluse korral kasutage võrguühenduseta geojälgimise võimalusi.</p>
\t</li>
</ul>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d5f8a0b6-ec6a-489d-b2d9-dd8db9bfbe80"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b7d2fccf93.png" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-LGlALooC.png",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-7e55a634-d22d-4d4b-a781-e9a043b632be"><p><strong><span style="font-size:1.5em;">Printerite sätete muutmine</span></strong></p>

<p>Kiire võidu energiatarbimise osas saab teha printeripargi haldamisel. Ooteseisundi, vähese energiatarbega või puhkeloleku seadete muutmine toob kaasa energiasäästu. Kuna printeri energiatarbimise armatuurlauad on kõigile kättesaadavad, on see konkreetne, "käegakatsutav" asi, mida kõik näevad ja oskavad seadistada.</p>

<p>Vaata Cap Gemini uuringut jätkusuutliku IT kohta:</p>

<p><a target="_blank" href="http://www.capgemini.com/fr-fr/wp-content/uploads/sites/6/2022/04/Final-Web-Report-Sustainable-IT-1.pdf">www.capgemini.com/fr-fr/wp-content/uploads/sites/6/2022/04/Final-Web-Report-Sustainable-IT-1.pdf</a>)</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-f6daacdd-2479-475f-9e8b-a567f54b59e3"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650b7d52602b9.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-X9LcEPlf.jpg",
        }
    ],
    "hybridRecommendedActions": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3427f056-8466-4a95-adec-01f1e32a4592"><p><span style="font-size:1.5em;"><strong>Korraldage tööruumid ümber nii, et need tarbiksid vähem energiat</strong></span></p>

<p>Seda saab teha mitmel viisil, näiteks seadmete valmistamise tingimustest lähtumine nende soetamisel, energiavarustuse ja valgustuse reguleerimise teel, klaasidena kasutatavate materjalide tüübii valik, soojusisolatsiooni kasutamine, sihipärasemat kasutamist võimaldavate küttesüsteemide rakendamine jne.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6a1ff1da-bea0-45f6-96ee-7326ac1f3395"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650ae72798dfa.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-e5jYUunU.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d7f97920-3d31-4127-9d19-2a50d6307c52"><p><strong><span style="font-size:1.5em;">Kohandage infra- ja tugisüsteeme vastavalt tegevustele ja kasutusaladele</span></strong></p>

<p>See tähendab, et tuleb ette mõelda, kuidas tööd korraldada ja milliseid tegevusi läbi viia. Kaugtööpraktikate prognoositavuse tagamine, et oleks võimalik ette näha, millal inimesed seda teevad või mitte.</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d04f5047-e710-4960-aea1-5f2dd5c634c4"><p><span style="font-size:1.5em;"><strong>Vältige põhimõtet "Üks suurus sobib kõigile"</strong></span></p>

<p>Mis võib olla kasulik ettevõttes, võib olla palju vähem kasulik linna elamurajoonis - näiteks kui viimase tähtsus väheneb või see tühjeneb oma inimeste lahkumise tõttu... Samamoodi ei ole kõik kaugtöötajad võrdsed: mõnel on spetsiaalne tööruum (oma kodus) ja mõnel mitte. Energia maksumus osutub oluliseks muutujaks kodus või kontoris töötamise valikul, sõltuvalt näiteks kontrorissetuleku transpordiviisist ja selleks kulunud ajast.</p>


</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-54956a77-38f6-4873-8ff7-904c45dc5241"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650ae952abe3a.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-5SruR9UH.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c741d811-2784-453f-ae4a-6f38fd9c9466"><p><strong><span style="font-size:1.5em;">Head ideed tulevad sageli tootmistöötajatelt, sest nad on tegelikule tööle kõige lähemal</span></strong></p>

<p>Ja see on koht, kus saate tõesti mõju avaldada. Väljakutseks on aga järjekindluse säilitamine. Kui osaluspõhine lähenemisviis ei toimi või näib olevat isoleeritud mõni algatus, ilma et see oleks osa laiemast strateegiast; või kui ettevõtte sõnum on, et keskkond on oluline, kuid on olemas ainult finantstulemuste KPI-d, ei ole see lähenemisviis usaldusväärne. Hoiduge rohepesust!</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<p><strong><span style="font-size:1.5em;">Energia säästmiseks sulgege kontorid teatud nädalapäevadel</span></strong></p>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-cfe59f92-0f7d-4a1b-8a5b-4dc310c7c7b8"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650ae9e7ad642.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3a5a13fb-d0a4-4437-87c0-8cca2baa698a"><p>Kui paljud töötajad kipuvad teatud päevadel kodus töötama, võivad organisatsioonid kaaluda kontori sulgemist sel päeval. Soovitatav on valida järjestikused sulgemispäevad, kuna see säästab rohkem energiat. Näiteks nädalavahetusele järgneva esmaspäeva sulgemine vähendab kontoris energiat tarbivate süsteemide sisse- ja väljalülitamiseks kuluvat energiat (st küte ja jahutus jne).</p>


</div>
</div>`,
            "imageSource": "https://nwow4net-zero.eu/images/t1/file-ynBuLM58.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-93509733-1264-40a8-a25b-183892b8d10d"><p><strong><span style="font-size:1.5em;">Kombineerige kaugtöö, Flex-Office ja kontoriruumi kasutuse vähendamine </span></strong></p>

<p>ADEME (Prantsusmaa) uuring näitab, et kui võtta arvesse kinnisvarakasutuse vähenemist, sest kaugtöö tekitab olukorra, kus koos paindliku kontoriga (tava, et töökohal ei ole spetsiaalset kontorit), paraneb kaugtöö üldine keskkonnatasakaal märkimisväärselt +52% kaugtöö päeva kohta nädalas (st -234 kg eqCO2/aastas iga täiendava kaugtööpäeva kohta nädalas), kindlustades seega oma keskkonnakasu. Paindliku kontori keskkonnakasu võib ületada liikuvuse eeliseid.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-2d3e4c85-3a9c-4d3a-a7e2-d1ea74bf3ce5"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650aea5b69adc.png" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-BKcUY47M.png",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d25f0f5-0bf7-4f85-b059-69b9e42fb240"><p><strong><span style="font-size:1.5em;">Hoiduge osapäeva kodutööst</span></strong></p>

<p>Kaugtöö nii kodus kui ka kolmandal kohal toob kaasa oluliselt negatiivse keskkonnatasakaalu.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-28e9f5f3-2c2d-4dbf-94b1-1e6affc73458"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650aeaf74c038.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-cfa3ffa5-6b28-41ef-aa8a-4bc8bde9fc55"><p><strong><span style="font-size:1.5em;">Kaugtöö: ole ettevaatlik!</span></strong></p>

<p>ADEME (Prantsusmaa) uuring näitab, et kaugtöö vähendab kontoritarbimist (paber, tint, tarvikud, tassid, videoprojektorid jne) ja ettevõtte energiatarbimist. Mõned neist vähendamistest kanduvad siiski edasi kaugtöötajale. Eelkõige suureneb energiatarbimine kodus. Uuringu hinnangul suureneks energiatarbimine kodus kaugtööpäeva ajal mehaaniliselt: +20,7 kg eqCO2/aastas ühe kaugtööpäeva kohta nädalas kaugtöötajale.</p>


</div>`
        }
    ],
    "mobilityRecommendedActions": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f3a1154d-cf21-4744-8f37-5b942ddae908"><p><span style="font-size:1.5em;"><strong>Meede: kuid parem on edasi liikuda ebatäiuslike mõõtmistega</strong></span></p>

<p>Täiuslikkuse ootamine on parim viis mitte midagi teha ..</p>
</div>`,
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9c17bf2a-baba-43d2-a449-63d3a162ff6a"><p><span style="font-size:1.5em;"><strong>Valige ettevõttele asukoht, kuhu pääseb säästlike transpordivahenditega </strong></span></p>

<p>Ettevõtetel ei ole võimalik "värvata kohapealt" ega kehtestada oma töötajatele elamiskohti, välja arvatud juhul, kui nad pakuvad töölepingu raames majutust. Nad ei saa mõjutada kodu-töö distantsi, kuid nad saavad valida asukoha, mis on nende töötajatele ligipääsetav säästvamate vahenditega kui auto.</p>
</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-32b491be-187b-4b28-b25e-c93cdd367c4c"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650ae7da4ccaf.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-r5ICVTKu.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42a6405c-6e2a-4f70-b7d8-501f3b5e3f66"><p><strong><span style="font-size:1.5em;">Planeerige, käitage, aktiveerige</span></strong></p>

<p>Esimene sammas on strateegia diagnoosimine ja määratlemine. Siis tuleb rakendamine mobiilsusteenuste, andmehalduse, finantsseire, infrastruktuuri jms abil. Enamik ettevõtteid peatub seal ja näeb siis, et see ei tööta. Miks? Lihtsalt sellepärast, et käitumismuutuste mõõde puudub.</p>

<p>Kõik ülaltoodud on vajalik, kuid mitte piisav. Sa pead "aktiveerima". Kui ettevõte ütleb teile, et on vastuvõtualale paigutanud kümme elektrijalgratast, kuid keegi neid ei kasuta, puudub see samm: aktiveerimine. Sa ei ole inimesi motiveerinud.</p>

<p>Nende motiveerimist saab teha mitmel viisil, näiteks korraldada preemiaga seotud mäng neile, kes neid kõige rohkem kasutavad.</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-7565574d-621a-4f00-b920-c201f9d2f507"><p><strong><span style="font-size:1.5em;">Omandage arusaam pikaajalisest pendelrändest või väga pikkadest vahemaadest</span></strong></p>

<p>Näiteks: inimesel on kaks tööga seotud elukohta – kas teise elukohaga hotellis või isegi AirBnB-s. Seega, eksisteerivad topeltresidentsid, mis on seotud inimestega, kellel on kaks töökohta kahes eri linnas või kellel on väga sagedane tööga seotud reisimine jne.</p>

<p>Ometi väärib see kaalumist personalitöö seisukohast ja veelgi enam, kui kolitakse uuele töökohale, pidades silmas mõju pendelrändele ja vajalikku tuge.</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-80c0adf5-cab3-4fbb-98bd-c46a956d2977"><p><strong><span style="font-size:1.5em;">Ärge keskenduge ainult eelarveaspektile</span></strong></p>

<p>Liikuvuse valiku loogika hõlmab ka kiirust (kui ühistranspordiga kulub 1,5 tundi, mitte 40 minutit autoga, ei avalda raha suurt mõju), aga ka paljusid muid kriteeriume, nagu mugavus (st kogu reisikogemus: rongil on suhteline eelis, kui saate sellega muid tegevusi teha - magada, lugeda, töötada jne. - kuid see eelis nullitakse, kui rong on rahvarohke ja teil pole näiteks istekohta), pilt või kogemus, mis teil on erinevatest transpordiliikidest, harjumustest, ohutusest jne. Mõelge liikuvuspoliitikale, mis integreerib kõik mõõtmed (maksumus, kiirus, mugavus jne), et alternatiivid oleksid atraktiivsed.</p>

<p>Minge 500-euroselt liikuvuseelarvelt üle eelarvele, mis on x kilo CO2, ja jaotage vastavalt töötajate liikuvuseelarve, võimaluse korral kolleegide vahelise kauplemise või võimalusega vahetada sääste puhkepäevade vastu.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6594bb16-8d4a-40a3-baae-b2c961991ac6"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650ae87fc0ae4.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-HA5eJBjj.jpg"
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-db56f5ca-9960-46ca-b07c-3aa08b24a38f"><p><strong><span style="font-size:1.5em;">Mitmeliigilise transpordi soodustamine </span></strong></p>

<p>Edendage ümbersuunamisi, et inimesed vähendaksid auto rolli oma teekonnal. Inimesed, kes elavad hõredalt asustatud piirkondades või linnalähedastes piirkondades võivad vähendada auto kasutamist, kui nad kasutavad lisaks autole ka teisi transpordiliike.</p>

<p>Minge üle pigem laiendatud kui vähenenud autole: jätkake ametiautoga, võib-olla väiksemaga, parema keskkonnatoimega, kuid andkem inimestele ka võimalus reisida teisiti. See ei nõua alati pakkumise suurendamist, vaid lihtsalt nõustumist ühistranspordile või muudele liikumisviisidele juurdepääsu hõlbustamisega.</p>

<p>Tänapäeval pakutakse hulgaliselt multimodaalseid lahendusi.</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-2b595af0-7cfc-41da-b1a7-f849be29cfe8"><p><strong><span style="font-size:1.5em;">Ametiautod: pöörake tähelepanu...</span></strong></p>

<p>Kui ettevõte tunneb kohustust pakkuda ametiautosid (talentide ligimeelitamiseks, hoidmiseks, staatuseks, esindamiseks...), pöörake tähelepanu sõiduki tüübile, isegi kui me teame, et ka elektrisõiduki keskkonnamõju ei ole neutraalne.</p>
</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e2b6406f-ba7a-45b7-83b8-84806274d67f"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650ae8f013a9d.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-DgZHPTOT.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5821544b-625c-4c3a-a64e-9ddf0f3eeb2f"><p><strong><span style="font-size:1.5em;">Tegelege parkimiskohtadega</span></strong></p>

<p>Kui ettevõte pakub parkimiskohta, maksab ta teatud mõttes töötajatele auto kasutamise eest. Mida me vajame, on lihtsalt tasakaalustada eelised erinevate transpordiliikide vahel: kui sõidate jalgrattaga, ei võta te parkimiskohta. Kui võtate vähem ruumi, võib ettevõte anda teile teatud summa - näiteks 200 eurot -, sest säästate ettevõtte raha jalgrattaga tulles.</p>

<p>Jälgige ettevõtte juhtidele reserveeritud parkimiskohti, mis on üldiselt paremas asukohas. See hierarhiline eelis ei kajasta ettevõtte ambitsiooni vähem süsinikumahuka liikuvuse osas. Kui on olemas parkla, tuleks kontori sissepääsule kõige lähemal asuv ruum reserveerida ühiskasutavatele autodele.</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d56820b5-cdac-4d65-b9c3-e7e19d058c6a"><p><strong><span style="font-size:1.5em;">Kasutage "sisesaadikuid"</span></strong></p>

<p>Töötage katseprojektidega, kus väikesed saadikute rühmad on valmis seda rolli täitma. Ärge muutke oma ettevõttes liikuvust revolutsiooniliselt. Alustage inimestest, kes on valmis, ja väikestest algatustest, mis idanevad ja kasvavad. Mobiliseerige juhtkond: kui tegevjuht ja tippjuhid istuvad ise jalgrataste selga ja teevad seda regulaarselt, on sellel organisatsioonile ja selle väärtustele suur mõju.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-96546409-df37-40a1-8000-e20cc49697d3"><img src="https://lp.youngkreativ.eu/pluginfile.php/396/mod_hvp/content/98/images/file-650ae968de311.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-bJXBYOuI.jpg"
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3c1ff5d8-04bb-4246-b817-45823add9089"><p><strong><span style="font-size:1.5em;">Võtmesõna: koostöö</span></strong></p>

<p>Kiba-Janiaki ja Witkowski ülevaates märgitakse, et eduka liikuvuskava põhinäitaja on koostöö avaliku või erasektori sidusrühmade võrgustikuga, ning märgitakse, et see on kasvuhoonegaaside heitkoguste vähendamiseks eesmärgi saavutamiseks oluline tegur.</p>




</div>`
        }
    ],
    "sustainableRecommendedActions": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-5a468dcd-341e-4c76-a18e-49a772df8881"><img src="https://lp.youngkreativ.eu/pluginfile.php/397/mod_hvp/content/99/images/file-650afa383a24c.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c9054d6f-c1fd-4779-9615-5a933109a477">

<p><strong><span style="font-size:1.5em;">Olge kaugtööga ettevaatlik </span></strong></p>



<p>ADEME uuring Prantsusmaal näitab, et vähenenud pendelrände positiivset mõju moduleerivad märkimisväärsed tagasilöögiefektid. Tagasilöögiefekti kirjeldatakse ebasoodsana nelja mehhanismi tõttu, sealhulgas videovoogude suurenemine, mis on peamiselt seotud videokonverentside ja uue energiatarbimisega kodus (küte, valgustus, arvutid jne). Uuring osutab ka uutele süsteemsetele või pikaajalistele tagasilöögiefektidele, mida tuleb hinnata ja jälgida, näiteks digiseadmete arvu suurenemine.</p>

<p>Kaugtöö mõju on positiivne ainult siis, kui ettevõte samaaegselt vähendab kasutatavate tööruumide hulka. Kui nad neid hooldavad ning hübriidses töökeskkonnas jätkuvalt soojendavad ja valgustavad, ei tekita kaugtöö iseeneses reaalset kasu.</p>




</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-8IE7xrpv.jpeg",
        }
    ],
    "foreword": [
        {
            roles: [],
            "heading": "",
            "description": "Uued tööviisid (NWoW) hõlmavad ruumilis-ajalist paindlikkust (kaugtöö, tööruumide planeerimine, paindlik tööaeg jne.), juhtimistavasid (autonoomia, usaldus, osalemine jne.), samuti töökorralduse tavasid ((pool)autonoomsed meeskonnad jne.).\n" +
                "\n" +
                "Eeldatavasti leitakse paindlikku tööd tehes selliseid võimalusi, mis aitavad kaasa CO2 heitkoguste vähendamisele, töötades töökeskkonnaga."
        }
    ],
    "hybridLearningObject": [
        {
            "roles": [],
            "heading": "",
            "description": "Eesmärk on anda personalispetsialistidele eeskuju hübriidse töökeskkonna loomisel, mis mitte ainult ei toeta organisatsiooni eesmärke, vaid aitab positiivselt kaasa keskkonnasäästlikkusele.\n" +
                "\n" +
                "1. Hübriidtöö juhtimise ja strateegia pädevus:\n" +
                "• Arendada suutlikkust juhtida ja strateegiat kujundada tõhusalt hübriidses töökeskkonnas, keskendudes jätkusuutlikkuse põhimõtete integreerimisele. See hõlmab oskuste omandamist koostööks juhtkonna ja ristfunktsionaalsete meeskondadega, et juurutada jätkusuutlikke tavasid nii kaug- kui ka kontorikeskkonda.\n" +
                "• Õppige looma strateegilist visiooni, mis viib hübriidtöömudelid vastavusse organisatsiooni jätkusuutlikkuse eesmärkidega.\n" +
                "• Täiendage pädevust hübriidtööpoliitika ja -tavade kujundamisel, mis vähendavad organisatsiooni süsiniku jalajälge, näiteks optimeerides kaugtöögraafikuid, et minimeerida reisimist ja energiatarbimist.\n" +
                "• Saate ülevaate kontorite asukohtade hindamisest ja valimisest säästva transpordi kättesaadavuse ja pendelrände heitkoguste vähendamise potentsiaali alusel.\n" +
                "2. Jätkusuutlik personalijuhtimine hübriidkeskkondades:\n" +
                "• Kasvatada oskusi inimressursside juhtimisel viisil, mis toetab ja suurendab hübriidtöömudelite tõhusust, edendades samas ka jätkusuutlikkust. See hõlmab personalipoliitika väljatöötamist ja rakendamist, mis soodustab keskkonnasõbralikke töövõtteid nii kaug- kui ka kontoritingimustes.\n" +
                "• Õppige integreerima jätkusuutlikkust värbamisse, koolitusse ja tulemusjuhtimisse, tagades, et töötajad on varustatud ja motiveeritud kasutama säästvaid tavasid olenemata nende töökohast.\n" +
                "• Täiustage digitaalsete tööriistade ja suhtlusstrateegiate kasutamise oskusi, et tõhusalt kaasata ja toetada töötajaid hübriidse töökorraldusega.\n",
        }
    ],
    "mobilityLearningObject": [
        {
            "roles": [],
            "heading": "",
            "description": "Üldine eesmärk on anda personalispetsialistidele võimalus rakendada praktilisi uuenduslikke mobiilsuslahendusi, mis aitavad vähendada ettevõtte süsinikdioksiidi heitkoguseid ja suurendada üldist jätkusuutlikkust.\n" +
                "1. Sidusrühmade kaasamine ja strateegiline liikuvuse juhtimine:\n" +
                "• Strateegiliste liikuvuskavade koostamiseks ja elluviimiseks arendada pädevust tõhusaks suhtlemiseks mitmesuguste sidusrühmadega, sealhulgas tegevjuhid, tippjuhtkond, finantsosakonnad ja riigiasutused. See hõlmab ettevõtte asukohaotsuste olulisuse mõistmist seoses töökohtade ja ühistranspordi kättesaadavusega ning nende mõju ettevõtte süsiniku jalajäljele.\n" +
                "• omandada oskused tippjuhtkonna juhtimisel ja mõjutamisel, et võtta omaks positiivne lähenemine tööalase liikuvuse suhtes, ületades pelgalt nõuetele vastavuse, et integreerida liikuvus aktiivselt töökogemuse osana.\n" +
                "• Õppige koostama ja esitama finantsosakondadele mõjuvaid ärijuhtumeid, näidates säästva liikuvuse projektide otsest ja olulist investeeringutasuvust.\n" +
                "• Täiustage tõhusate suhtlusstrateegiate loomise oskusi, kasutades selliseid tööriistu nagu gamification ja pidev sõnumside, et muuta töötajate liikuvusega seotud käitumist.\n" +
                "2. Operatiivne rakendamine ja innovatsioon liikuvuses:\n" +
                "• Õppige rakendama liikuvusstrateegiaid, määrates ja koolitades liikuvusjuhid, kes saavad juhtida projekte erinevates osakondades.\n" +
                "• Õppige koguma ja analüüsima andmeid praeguste liikuvustavade ja individuaalsete pendelrändeelistuste kohta, et kohandada liikuvuslahendusi.\n" +
                "• Arendada oskusi osaluspõhiste lähenemisviiside algatamiseks, sisesaadikute loomiseks ja töötajatevahelise koostöö edendamiseks, et edendada jätkusuutlikke pendelrände meetodeid.\n" +
                "• Mõista praktiliste aspektidega, nagu auto parkimise poliitika ja alternatiivide, nagu jalgrattasõit, ühistranspordi kasutamine ja ühistranspordi kasutamine, käsitlemise tähtsust.\n" +
                "• Saage teadmisi selliste tehnoloogiliste vahendite kasutamisest nagu multimodaalsed marsruudiplatvormid ja säästva liikuvuse pakettide rakendamine, et ergutada keskkonnasõbralikku pendelrännet.\n",
        }
    ],
    "sustainableLearningObject": [
        {
            "roles": [],
            "heading": "",
            "description": "1. Strateegiline juhtimine ja juhtimine digitaalses kainuses:\n" +
                "• Arendada oskusi propageerida ja tugevdada vastutustundliku digikäitumise tähtsust organisatsioonis, tehes koostööd tegevjuhtide, tippjuhtkonna ja CIO-de/IT-direktoritega.\n" +
                "• Arendada oskusi jätkusuutlikkuse mõjutamiseks ja kinnistamiseks organisatsiooni visiooni ja eetikasse, eriti seoses digitehnoloogiate kasutamisega.\n" +
                "• Õppige juhendama tippjuhtkonda vastutustundlike digikriteeriumide integreerimisel ettevõtte juhtimisse, tagades, et jätkusuutlikkus on iga IT-ga seotud projekti puhul kesksel kohal.\n" +
                "• Õppige praktiseerima ja edendama eeskujulikkust, kus juhid räägivad, tuues selgeid näiteid vastutustundlikest digitaalsetest tavadest.\n" +
                "• Omandage IT-osakondadega koordineerimise oskused, et töötada välja vastutustundlik IT-strateegia, mis piirab keskkonnamõju, teenides samal ajal inimeste vajadusi, keskendudes sellistele aspektidele nagu Green IT, Human IT ja IT for Human. Mõistke hanke- ja IT-riistvaratarnijatega töötamise tähtsust säästvate tavade rakendamisel seadmete tootmisel, kasutamisel ja ringlussevõtul.\n" +
                "2. Operatiivne rakendamine ja vastutustundlikud digitavad:\n" +
                "• Arendada oskusi vastutustundliku digikäitumise tegevusstrateegiate rakendamisel. Õppige töötama hankega, et pikendada IT-seadmete eluiga ja edendada säästvat kasutamist.\n" +
                "• Omandada pädevusi IT- ja telekommunikatsiooniriistvara tarnijate kaasamiseks energiatõhusate toodete tootmiseks ja töötajate koolitamiseks nende eeliste kohta. Saate aru, kuidas tõsta töötajate teadlikkust digitehnoloogiatega seotud jätkusuutlikkuse küsimustest, edendades digitaalse kainuse kultuuri.\n" +
                "• Omandage teadmisi töötajate julgustamisel vastutustundlikule digitaalsele käitumisele, nagu optimeeritud pilvekasutus, vastutustundlikud meilitavad ja teadlikud videokonverentsid. Õppige seda käitumist poliitikate kaudu vormistama ja integreerima need personalipraktikatesse, nagu koolitus, hindamine ja tunnustamine.\n" +
                "• Arendada suutlikkust mõõta organisatsiooni ja üksikute töötajate digitaalset keskkonnajalajälge ning kasutada neid andmeid digitaalse kainuse tavade pidevaks täiustamiseks.",
        }
    ],
    "inner_cont_title": "Enesehinnang",
    "st_2_title": "Nüüd määrame kindlaks teie domineeriva personalirolli organisatsioonis/ettevõttes",
    "st_2_p1": "See tööriist võib määratleda teie individuaalse õpitee, mis on inspireeritud Dave Ulrichi \"HR Business Partner Model\" mudelist (Ulrich, 1997), kuna see pakub personalispetsialistidele raamistikke ja juhiseid, et viia oma tegevus vastavusse ärieesmärkidega, juhtida organisatsioonilisi muutusi ja luua väärtust oma siseselt. organisatsioonidele, et maksimeerida oma personalifunktsiooni mõju ja tõhusust.",
    "st_2_p2": "See ühtlustamine tagab, et personalivaldkonna algatused ja tavad toetavad otseselt organisatsiooni eesmärke ja parandavad üldist tulemuslikkust. Mudel viitab sellele, et personalispetsialistid peaksid täitma nelja võtmerolli: strateegiline partner, haldusekspert, töötajate meister ja muutuste agent (Ulrich, 1997; Ulrich & Brockbank, 2005). Nende rollide kirjeldus on toodud järgmises tabelis.",
    "st_3_r0_0": "Hübriidtöö",
    "st_3_r0_1": "Hübriidtöö – kuidas ja millistel tingimustel kombineerida kontoripõhist töötamist (tööandja kontoriruumides, satelliitkonorites jne) ja kaugtööd (kodus või mõnes teises asukohas, mis ei ole kontor, töötamine) nii, et sel oleks positiivne keskkonnamõju?",
    "st_3_r1_0": "Jätkusuutlik mobiilsus",
    "st_3_r1_1": "Jätkusuutlik mobiilsus – millised metoodikad ja (digitaalsed) lahendused aitavad kaasa tööga seotud reisimise (eelkõige tööle ja töölt koju) vähendamisele ja vähendavad süsinikujalajälge?",
    "st_3_r2_0": "Jätkusuutlik ja vastutustundlik IT (infotehnoloogia)",
    "st_3_r2_1": "Jätkusuutlik ja vastutustundlik IT (infotehnoloogia) – millised (digitaalsed) lahendused ja personalijuhtimise praktikad toetavad digitehnoloogiate vastutustundlikku kasutamist organisatsioonis?",
    "st_3_title": "KOOLITUSKAVA UUTE TÖÖKORRALDUSE VIISIDE KOHTA ETTEVÕTETES MILLE ABIL VÄHENDADA SÜSINIKDIOKSIIDI HEITKOGUSEID",
    "st_3_p1": "Personalijuhtimine saab panustada kolmel viisil:",
    "st_3_title_2": "Õpitee loomiseks palun vali, millised teemad sind kolmest tööriiistakastist huvitavad:",
    "st_3_bullet_1": "Valida mõni või kõik pakutavad tööriistad (horisontaalsed märkeruudud)",
    "st_3_bullet_2": "Valida mõni või kõik huvipakkuvad alateemad: sidusrühmad, kultuur, instrumendid/tööriistad, personaliprotsessid, inspireerivad algatused, soovitused (vertikaalselt asetsevad märkeruudud)",
    "r_c_text_1": "Teie vastuste põhjal loetakse teie (praeguseks) rolliks personalivaldkonnas:",
    "r_c_text_2": "Soovitame seda õpiteed osaleda oma ettevõttes/organisatsioonis uute tööviiside väljatöötamisel netoheitmete vähendamiseks.",
    "r_c_text_3": "Siiski saate valida mõne muu rolli või jätkata selle rolliga.",
    "r_c_text_4": "Valige oma eelistatud roll või jätke see nii, nagu see on ja jätkake:",
    "takeQuiz": "Täitke see viktoriin, et mõista, millisele personalivaldkonna rollile teie tegevus vastab, ja saada isikupärastatud soovitusi",
    "takeQuizLabel": "OSALE EESTIKEELSES VIKTORIINIS",
    "selectHrRole": "Valige oma personalitöötaja roll ja hankige teie konkreetsele ametikohale kohandatud soovitusi.",
    "demographics": "Demograafia",
    "yourRole": "Sinu roll",
    "interests": "Huvid",
    "learningPath": "Õppetee",
    "continue": "Jätka",
    "back": "Tagasi",
    "selectPosition": "VALI ROLL",
    "or": "VÕI",
    "hrmEductor": "KUI OLETE HRM-KOOLITAJA VÕI SOOVITE LIHTSALT TEADA, KUIDAS ÕPPEPROTSESS MEIE ÕPITEEDEGA ETTE KUJUTATAKSE, UURIGE MEIE ÕPIJUHENDIT!",
    "downloadPdf": "LAE ALLA PDF",
    "sm_tab_1": "1. Koolitusvahendid",
    "sm_tab_2": "2. Õpiteed",
    "sm_tab_3": "3. Mõju hindamise tööriist",
    "sm_tab_4": "4. Interaktiivsed tööriistakomplektid",
    "sm_tab_5": "5. Sõnastik",
    "home_tab_label": "Pealeht",
    "about_tab_label": "Projektist",
    "project_tab_label": "Projekti tulemused",
    "news_tab_label": "Uudised",
    "newsletters_tab_label": "Uudiskirjad",
    "contact_tab_label": "Kontakt",
    "StrategicPartner": 'Strateegiline partner',
    "AdministrativeExpert": "Haldusekspert",
    "EmployeeChampion": "Töötajate eestkõneleja",
    "ChangeAgent": "Muudatuste agent",
    "StrategicPartnerContent": "Strateegiliste partneritena teevad personalispetsialistid tihedat koostööd ärijuhtidega, et mõista organisatsiooni strateegilisi eesmärke ning viia personalipraktikad ja -algatused vastavalt vastavusse. Nad aitavad kaasa äristrateegiate väljatöötamisele ja tagavad, et personalialane tegevus toetab nende strateegiate saavutamist. Strateegilised partnerid osalevad aktiivselt otsustusprotsessides ja annavad ülevaate sellest, kuidas HR saab aidata kaasa organisatsiooni edule.",
    "AdministrativeExpertContent": "Halduseksperdi roll keskendub tulemuslike ja tõhusate personalitoimingute ja -protsesside tagamisele. Selle rolli personalispetsialistid haldavad rutiinseid haldusülesandeid, nagu palgaarvestus, hüvitised, personalipoliitikad, vastavus ja arvestuse pidamine. Neid haldusfunktsioone tõhusalt hallates saavad strateegilised partnerid aega ja ressursse, et keskenduda strateegilistele algatustele.",
    "EmployeeChampionContent": "Töötaja eestkõneleja roll rõhutab positiivse töökeskkonna loomise ning töötajate vajaduste ja heaolu eest seismise tähtsust. Selles rollis olevad personalispetsialistid toimivad sillana organisatsiooni ja selle töötajate vahel, edendades töötajate kaasatust, rahulolu ja üldist töötajakogemust. Need käsitlevad töötajate muresid, pakuvad tuge ja edendavad kaasavuse, õigluse ja austuse kultuuri.",
    "ChangeAgentContent": "Muutuste agendi roll rõhutab personalijuhi vastutust organisatsiooniliste muudatuste hõlbustamisel ja juhtimisel. Personalispetsialistid tegutsevad muutuste vahendajatena, tuvastades ja käsitledes ennetavalt vajadust muutuste järele, edendades muudatuste algatusi ning aidates töötajatel ja meeskondadel üleminekutel navigeerida. Neil on oluline roll kultuurilise ümberkujundamise, organisatsiooni ümberkorraldamise ja muutusteks valmis mõtteviisi edendamisel organisatsioonis.",
    "hybrid": "Hübriidtöö",
    "SustainableMobility": "Jätkusuutlik mobiilsus",
    "responsibleUseOfIt": "Jätkusuutlik ja vastutustundlik IT (infotehnoloogia) kasutamine",
    "hybridContent": "Hübriidtöö - millised töökorralduse ja töö hübriidistamise viisid (kontoris töötamine vs kaugtöö kodus vm kontorist väljaspool) panustavad parimal moel positiivse keskkonamõju saavutamisse ja millistel tingimustel?",
    "SustainableMobilityContent": "Jätkusuutlik mobiilsus – millised metoodikad ja (digitalsed) lahendused aitavad vähendada tööga seotud reisimist ja vähendada süsinikujalajälge?",
    "responsibleUseOfItContent": "Jätkusuutlik ja vastutustundlik IT (infotehnoloogia) kasutamine – millised (digitaalsed) lahendused ja personalijuhtimise praktikad toetavad vastutustundlikku digitaalset käitumist ja digitaalset kainust organisatsioonis?",
    "nextBook": "JÄRGMINE",
    "backBook": "TAGASI",
    "stakeholdersTitle": "Sidusrühmad",
    "stakeholdersDesc": "Ettevõtte süsinikdioksiidi heitkoguste vähendamisele kaasaaitamiseks peab personalidirektor/juht tegema tihedat koostööd erinevate huvigruppidega.",
    "createCultureTitle": "Jätkusuutlikkuse kultuuri loomine",
    "createCultureDesc": "Saavutada muutusi käitumises ja tarbimises läbi organisatsiooni- ja juhtimiskultuuri.",
    "toolsTitle": "Tööriistad",
    "toolsDesc":"Vahendid heitkoguste vähendamise toetamiseks",
    "hrPrTitle":"HR protsessid",
    "hrPrDesc":"HR protsesside kohandamine süsinikdioksiidi heitkoguste vähendamiseks",
    "inspiringTitle":"Inspireerivad algatused",
    "inspiringDesc":"Integreerige üksikisikute (kaugtöötajate) süsiniku jalajälg ettevõtte süsiniku jalajälje arvutamisel.",
    "recoTitle":"Recommendations",
    "recoDesc":"Soovitus rakendada HR-panus Net-zero-sse",
    "ZeroCarbon": "Null süsinikku (süsinikuvaba)",
    "ZeroCarbonContent": "Null süsinikku tähendab, et tootel või teenusel on omadus mitte tekitada süsinikdioksiidi heitkoguseid. Näiteks liigitatakse taastuvad energiaallikad, nagu tuule- ja päikeseenergia, süsinikuvabaks, sest need ei eralda elektri tootmisel süsinikdioksiidi (CO2). Seevastu netonullheide on seotud organisatsiooni tekitatud süsinikdioksiidi heitkoguste kompenseerimise või tasakaalustamisega, samas kui null-süsinik tähistab konkreetselt toodet või teenust, mis ei tekita CO2-ekvivalentidena süsinikku. Lisaks tähendab süsinikdioksiidi netonullheite saavutamine seda, et tegevuse tulemuseks on süsinikdioksiidi netonullheite eraldumine atmosfääri, mis näitab olukorda, kus heitkogused on tõhusalt tasakaalustatud või eemaldatud.",
    "Compensation": "Hüvitis",
    "CompensationContent": "Kompenseerimine, mida sageli nimetatakse (süsiniku) kompenseerimiseks, hõlmab süsinikukrediitide tahtlikku ja mõnikord kohustuslikku omandamist, et tasakaalustada konkreetsele üksusele omistatud heitkoguseid. Kulud, mis on seotud süsinikdioksiidi ühikute hankimisega hüvitise saamise eesmärgil, on võrdlusaluseks, kui hinnatakse investeeringuid, mille eesmärk on saavutada otsene liidusisene heitkoguste vähendamine. Teatavaid kasvuhoonegaaside heitkoguseid võib olla oma olemuselt raske täielikult kõrvaldada ning hüvitamisel süsinikukrediitide kaudu on keskne roll kliimaneutraalsuse ja netonullheite eesmärkide saavutamisel. Hüvitusstrateegiad hõlmavad mitmesuguseid investeerimisvorme, sealhulgas investeeringuid taastuvenergiasse, energiatõhususse, metsa uuendamisse, süsinikdioksiidi kogumisse ja muudesse mõõdetavalt tõhusatesse süsinikdioksiidi heitkoguste vähendamise meetmetesse.",
    "Offsetting": "Kompenseerimine",
    "OffsettingContent": "Süsinikdioksiidi kompenseerimine on menetlus, mis hõlmab süsinikdioksiidi ja muude kasvuhoonegaaside heitkoguste vähendamist või kõrvaldamist, et tasakaalustada teistes kohtades tekkinud heitkoguseid. Süsinikdioksiidi kompenseerimise projektid võimaldavad nii organisatsioonidel kui ka üksikisikutel osaleda käegakatsutavates keskkonnaalastes algatustes, mille eesmärk on kompenseerida nende süsinikdioksiidi heitkoguseid. Need kompenseerimisalgatused hõlmavad mitmesuguseid tehnoloogiaid ja tegevusi, sealhulgas metsa uuendamist, puhtamaid küpsetusahjusid ja süsinikdioksiidi kogumist. CO₂-heite kompenseerimine on organisatsioonide jätkusuutlikkuse strateegiate lahutamatu osa, eriti kui see täiendab süsinikdioksiidi heitkoguste vähendamiseks tehtavaid jõupingutusi, aidates lõppkokkuvõttes kaasa netonullheite saavutamise eesmärgi saavutamisele.",
    "ScienceBasedTargets": "Teaduspõhised eesmärgid (inglise keeles Science-Based Targets (SBT))",
    "ScienceBasedTargetsContent": "Teaduspõhiste eesmärkide algatuses on organisatsioonidel ette nähtud selgelt visandatud raamistik kasvuhoonegaaside heitkoguste süstemaatiliseks vähendamiseks, pöörates erilist tähelepanu Pariisi kliimakokkuleppes seatud eesmärkidega kooskõlas olevate heitkoguste vähendamise näitajate saavutamisele. Eesmärke peetakse teaduspõhisteks, kui need on kooskõlas uusimate kliimateaduse avastustega ja nende eesmärk on saavutada eesmärk hoida globaalne soojenemine alla 1,5 °C võrreldes industriaalühiskonna eelse tasemega.\n" +
        "\n" +
        "SBTi-l on keskne roll parimate tavade edendamisel ja täpselt määratletud suuniste andmisel heitkoguste vähendamiseks. Selles pakutakse metoodikat heitkoguste vähendamise eesmärkide seadmiseks, mis põhinevad kliimamuutustest teaduslikul arusaamal. Algatus aitab aktiivselt organisatsioonidel kehtestada süsinikdioksiidi vähendamise eesmärke, mis on täielikult kooskõlas Pariisi kokkuleppes sätestatud eesmärkidega .",
    "Greenwashing": "Rohepesu",
    "GreenwashingContent": "Rohepesu viitab petlikule praktikale, kus organisatsioon kasutab reklaami ja avalikku kommunikatsiooni, et kujutada end keskkonna suhtes vastutustundlikuma ja jätkusuutlikumana, kui see tegelikult on. See on strateegia, mida mõned organisatsioonid kasutavad, et juhtida tarbijate tähelepanu kõrvale oma äritegevuse ja mudelite kahjulikelt keskkonnamõjudelt. Rohepesu taktika võib avalduda erinevates vormides, sealhulgas reklaamikampaaniad, sponsorlus ja avalikud sõnumid traditsioonilises meedias ja sotsiaalmeedia platvormidel .",
    "LifeCycleAssessment": "Olelusringi hindamine (inglise keeles Life Cycle Assessment (LCA))",
    "LifeCycleAssessmentContent": "LCA on range, teaduspõhine lähenemisviis, mida kasutatakse toote või protsessi ökoloogilise jalajälje hindamiseks kogu selle elutsükli jooksul. See meetod võtab arvesse kõiki etappe, alustades tooraine kaevandamisest ja lõpetades lõpliku kõrvaldamise või ringlussevõtu etapiga. LCA on väärtuslik vahend toodete ja protsessidega seotud keskkonnamõjude kvantifitseerimiseks, andes ettevõtetele võimaluse teha teadlikke valikuid ja rakendada oma jätkusuutlikkuse jõupingutustes täiustusi.",
    "CarbonFootprint": "Süsiniku jalajälg",
    "CarbonFootprintContent": "Organisatsiooni, riigi, toote või tegevusega seotud kasvuhoonegaaside kulu (tavaliselt süsinikdioksiid) näitaja; hõlmab sageli nii otseseid kui ka kaudseid heitkoguseid. Näiteks sisaldab imporditud materjalidest valmistatud toote süsinikujalajälg nii imporditud materjalide transportimise süsinikukulusid kui ka toote valmistamise kulusid. Need jalajäljed peaksid andma aimu organisatsioonide keskkonnamõjust ja andma lähtepunkti keskkonnateadlikumate tavade poole liikumiseks.",
    "EmissionsTradingScheme": "Heitkogustega kauplemise süsteem (inglise keeles Emissions Trading Scheme (ETS))",
    "EmissionsTradingSchemeContent": "Heitkogustega kauplemise süsteem on piiramise ja kauplemise süsteem, millega piiratakse heitkoguseid ja trahvitakse organisatsioone ülemmäära ületamise eest.  Siiski antakse nendele ettevõtetele heitkoguste ühikuid, mis võimaldavad neil ülemmäära ületada ilma trahvita.  Neid krediite saab osta või müüa teistele organisatsioonidele, julgustades neid heitkoguseid vähendama, et saada rohkem ühikuid, mida müüa, või vähendada nende ostuvajadust.  Selliseid süsteeme kasutatakse laialdaselt kogu maailmas, mille näiteks on kõige paremini ELi heitkogustega kauplemise süsteem.",
    "toolkit1": "TÖÖRIISTAKOMPLEKT 1",
    "toolkit2": "TÖÖRIISTAKOMPLEKT 2",
    "toolkit3": "TÖÖRIISTAKOMPLEKT 3",
    "forewordTitle": "Eessõna",
    "learningObjectives": "Õppeeesmärgid",
    "pr3_home_title": "PR4 INTERAKTIIVSED TÖÖRIISTAKOMPLEKTID",
    "pr3_home_subtitle": "Kolm modulaarset töövahendit, mis hõlmavad personalitavade muutmist vastavalt nõudmistele, mis tulenevad infotehnoloogia, liikuvuse ja jätkusuutlikkuse uutest suundumustest, mis mõjutavad kaasaegseid töökohti.",
    "pr3_home_toolkit_1": "TÖÖRIISTAKOKK",
    "pr3_home_toolkit_0_2": "Uued tööviisid",
    "pr3_home_toolkit_1_2": "Tööga seotud liikuvus",
    "pr3_home_toolkit_2_2": "IT vastutustundlik kasutamine",
}
