import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import './answer.scss'

// import {CheckBoxOutlineBlankOutlinedIcon, CheckBoxOutlinedIcon } from '@mui/icons-material';

const styles = {
    '.MuiFormControl-root': {
        background: 'rgba(255, 214, 24, 0.2)',
        boxShadow: `0px 4px 4px 0px rgba(184, 184, 184, 0.1) inset`,
    }
}

class Answer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            row: window.innerWidth > 600
        }
    }

    returnAnswers = () => {
        return this.props.answers.map(c => {
            return <FormControlLabel value={window.getContent(c.id)}
                                     control={<Radio icon={<CheckBoxOutlineBlankOutlinedIcon/>}
                                                     checkedIcon={<CheckBoxOutlinedIcon/>}/>}
                                     label={window.getContent(c.id)}/>
        })
    }

    handleChange = (e) => {
        let answer = this.props.answers.find(c => window.getContent(c.id) == e.target.value);
        this.props.onAnswerChange(this.props.questionId, answer.roles, answer.id);
    }


    render() {

        return (
            <div className={`question_container ${this.props.className ?? ''}`}>
                <FormControl>
                    <FormLabel
                        sx={{
                            fontSize: '18px',
                            '.MuiFormLabel-root': {
                                color: '#133861 !important',
                                fontSize: '18px'
                            },
                        }}
                    >{window.getContent(this.props.questionId)}</FormLabel>
                    <RadioGroup
                        row={window.innerWidth > 600}
                        name="row-radio-buttons-group"
                        value={window.getContent(this.props.answersObj[this.props.questionId]?.id)}
                        onChange={this.handleChange}
                    >
                        {this.returnAnswers()}
                    </RadioGroup>
                </FormControl>
            </div>
        );
    }
}


Answer.propTypes = {};

export default Answer;
