import React, {Component, useEffect} from 'react';
import {languageRouteAbbreviations, routes} from "../../Enums/Enums";
import './side_menu.scss'
import {Button} from "@mui/material";
import {useLocation} from "react-router-dom";

class SideMenu extends Component {
    tabsFunctions = [
        () => this.navigateIf(languageRouteAbbreviations[window.language].pr1Link),
        () => {
            window.navigateTo(routes.pr2 + `/${window.language}`)
            if (window.returnToHomePage && window.returnToHomePage[routes.pr2]) {
                window.returnToHomePage[routes.pr2]();
            }
        },
        () => this.navigateIf(`https://nwow4net-zero.eu/index.php/${languageRouteAbbreviations[window.language].short}/project-results-${languageRouteAbbreviations[window.language].long}/pr3-impact-tool`),
        () => {
            window.navigateTo(routes.book + `/${window.language}`)
            if (window.returnToHomePage && window.returnToHomePage[routes.book]) {
                window.returnToHomePage[routes.book]();
            }
        },
        () => window.navigateTo(routes.dialog + `/${window.language}`),
    ];

    tabRoutes = [
        '',
        routes.pr2,
        '',
        routes.book,
        routes.dialog
    ]

    navigateIf = (link) => {
        if (window.location.href != link) {
            window.location.href = link;
        }
    }

    render() {
        return (
            <div className={`side-menu ${window.language}`}>
                <LocationHook onLocationChange={() => this.forceUpdate()}/>
                {this.tabsFunctions.map((c, index) => <Button
                    variant={isSelected(this.tabRoutes[index]) ? 'contained' : 'text'} onClick={c}
                    className={'sm_tab'}>
                    {window.getContent(`sm_tab_${index + 1}`)}
                </Button>)}
            </div>
        );
    }
}

export function LocationHook({onLocationChange}) {
    const location = useLocation();

    useEffect(onLocationChange, [location]);
}

export const isSelected = (route) => {
    if (!route) {
        return false;
    }
    if (window.location.pathname.includes(`/${route}`)) {
        return true;
    }
    return false;
}

export default SideMenu;
