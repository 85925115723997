import React, {Component} from 'react';
import {FormControl, InputLabel, MenuItem, NativeSelect, Select} from "@mui/material";
import './step1.scss';

export class Step extends Component {

    componentDidMount() {
        setTimeout(() => {
            if (window.scrollableEl) {
                window.scrollableEl.scrollTo({top: 0, behavior: 'smooth'})
            } else {
                window.scrollTo({top: 0, behavior: 'smooth'})
            }
        }, 200);
    }
}

class Step1 extends Step {

    constructor(props) {
        super(props);

        this.questions = [
            {id: 'q1.1', answers: ['selectValueOption', 'a15', 'a16', 'a17', 'a18']},
            {id: 'q1.3', answers: ['selectValueOption', 'a19', 'a20', 'a21']},
            {id: 'q1.4', answers: ['selectValueOption', 'a22', 'a23', 'a24', 'a25']},
            {id: 'q1.5', answers: ['selectValueOption', 'a1', 'a2', 'a26']},
            {id: 'q2.1', answers: ['selectValueOption', 'a27', 'a28', 'a29', 'a30', 'a31']},
        ]
    }

    returnQuestions = () => {
        return this.questions.map(c => {
            return <>
                <div className={'body_text_3'}>
                    {window.getContent(c.id)}
                </div>
                <FormControl sx={{
                    '.MuiNativeSelect-select': {
                        paddingLeft: '10px',
                        color: '#133861',
                    },
                    '.MuiInputBase-root': {
                        borderRadius: '10px',
                        backgroundColor: 'white',
                    },
                    '.MuiInput-root::before': {
                        border: '0'
                    },
                }} fullWidth>
                    {/*<InputLabel variant="standard" htmlFor="uncontrolled-native">*/}
                    {/*    Age*/}
                    {/*</InputLabel>*/}
                    <NativeSelect
                        defaultValue={window.getContent(c.answers[0])}
                        inputProps={{
                            name: 'age',
                            id: 'uncontrolled-native',
                            underlined: false
                        }}
                    >
                        {c.answers.map(a => {
                            return <option value={window.getContent(a)}>{window.getContent(a)}</option>
                        })}
                    </NativeSelect>
                </FormControl>
            </>
        })
    }

    render() {
        return (
            <div className={'step st_1'}>
                {this.returnQuestions()}
            </div>
        );
    }
}

export default Step1;
