import React, {Component} from 'react';
import Toolkits from "../toolkits/toolkits";
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {Step} from "./step1";

class Step3 extends Step {
    render() {
        return (
            <>
                <div className={'st_3_title'}>{window.getContent('st_3_title')}</div>
                <div className={'step_text bigger'}>{window.getContent('st_3_p1')}</div>
                <div className={'table_container_s3'}>
                    <Table>
                        <TableBody>
                            {new Array(3).fill(undefined).map((_, index) => <TableRow>
                                <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                        <div className={'st_3_c_0'}>{window.getContent(`st_3_r${index}_0`)}</div>
                                        <div className={'st_3_c_1'}>{window.getContent(`st_3_r${index}_1`)}</div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <div>
                    <div className={'st_3_title'}>{window.getContent('st_3_title_2')}</div>
                    <ul>
                        <li className={'step_text bigger'}>{window.getContent('st_3_bullet_1')}</li>
                        <li className={'step_text bigger'}>{window.getContent('st_3_bullet_2')}</li>
                    </ul>
                </div>
                <Toolkits toolKitObj={this.props.toolKitObj} handleChangeToolkit={this.props.handleChangeToolkit}/>
            </>
        );
    }
}

export default Step3;
