export const TreeSVG1 = () => <svg width="511" height="510" viewBox="0 0 511 510" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
    <path
        d="M151.517 416.869C130.302 394.351 109.388 372.153 88.0382 349.493C173.307 328.68 217.261 269.176 238.259 187.853C242.546 224.908 234.565 258.657 217.233 291.464C251.081 276.188 285.514 270.246 322.382 276.807C239.886 292.721 177.502 332.596 151.517 416.869Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M347.109 239.384C352.934 236.855 356.68 233.191 360.125 229.408C369.755 218.787 381.849 218.019 395.226 222.298C401.126 224.205 406.56 227.224 411.559 231.123C414.209 233.199 414.146 234.941 412.475 237.389C403.986 249.826 390.392 254.832 375.006 250.899C365.687 248.514 357.001 244.255 347.109 239.384Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M252.738 229.024C258.827 223.825 266.395 221.538 275.28 224.937C282.69 227.775 286.52 233.515 287.438 241.125C289.206 255.668 274.922 268.722 260.644 265.764C245.661 262.662 239.598 244.834 249.394 232.645C250.296 231.526 251.3 230.58 252.738 229.024Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M268.497 310.76C271.428 319.768 277.871 325.535 282.34 332.624C285.532 337.687 286.145 343.23 285.686 348.775C284.801 360.033 280.06 369.275 272.367 377.061C270.495 378.96 269.256 379.32 266.772 377.154C254.691 366.61 250.275 351.065 255.442 336.444C258.61 327.743 263.856 320.109 268.497 310.76Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M261.942 166.883C258.713 159.769 253.211 156.142 248.078 152.369C239.255 145.885 236.458 137.154 235.971 127.187C235.486 118.094 238.354 110.079 242.193 102.359C243.595 99.5586 245.237 99.2901 248.088 101.043C261.203 109.066 268.962 119.982 268.487 135.495C268.204 146.119 264.871 155.918 261.942 166.883Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M284.033 135.71C280.733 145.343 281.354 155.654 280.839 167.102C276.162 156.443 272.158 146.965 272.375 136.673C272.652 122.826 282.888 113.653 293.97 109.13C296.08 108.286 296.786 109.706 297.76 111.075C308.107 126.145 307.867 142.31 296.834 155.597C293.323 159.846 289.846 164.131 284.626 167.839C283.601 156.631 284.722 146.159 285.775 135.75C285.506 135.197 285.807 134.51 285.337 133.944C284.969 134.559 284.333 135.024 284.033 135.71Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M200.34 232.184C188.481 233.605 176.955 233.635 165.295 233.322C176.66 237.343 188.118 236.301 199.643 236.136C193.318 240.011 186.89 243.309 179.723 245.151C164.921 249.009 149.294 241.604 141.663 227.54C139.747 223.965 142.091 222.968 143.864 221.633C158.754 210.295 173.766 210.948 190.977 223.721C194.164 226.031 197.719 228.062 200.34 232.184Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M343.326 261.508C356.631 263.164 368.565 266.247 380.465 269.764C369.832 262.699 357.833 260.687 345.9 258.141C355.646 255.417 365.459 252.964 375.95 255.052C387.077 257.28 399.397 270.155 401.326 280.982C401.766 283.459 400.427 284.115 398.887 284.961C385.765 292.077 369.041 289.405 357.496 278.491C352.428 273.648 348.297 267.99 343.326 261.508Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M330.449 198.315C333.097 180.284 337.457 163.868 355.135 156.293C365.614 151.8 366.417 150.842 366.775 164.225C367.096 174.953 362.856 183.387 353.953 189.084C346.925 193.62 338.955 195.884 330.449 198.315Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M382.218 180.345C384.785 173.151 387.521 166.807 392.438 161.771C399.963 154.076 415.074 154.031 423.866 161.486C425.376 162.754 425.21 163.851 424.844 165.272C423.581 170.834 421.58 176.082 418.036 180.43C411.048 188.964 405.555 189.969 395.159 184.832C391.101 182.737 387.31 180.054 382.218 180.345Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M324.869 356.852C331.14 359.956 336.709 363.856 341.409 368.845C349.935 377.895 349.428 394.178 340.596 401.759C339.425 402.728 338.52 402.706 337.28 402.193C329.232 398.745 322.188 394.083 317.684 386.153C314.894 381.181 315.355 376.644 317.893 371.966C320.565 367.162 324.679 363.084 324.869 356.852Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M181.968 168.922C179.819 166.238 176.695 162.186 174.576 157.39C173.399 154.733 173.899 153.723 176.645 152.817C190.274 148.451 204.925 153.547 213.458 165.887C217.153 171.217 220.178 176.841 223.003 182.654C224.079 184.868 223.678 185.649 221.333 185.371C207.325 183.906 193.717 181.459 181.968 168.922Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M310.798 302.647C334.027 307.934 348.593 321.652 352.311 340.074C353.089 343.915 352.119 345.031 348.333 345.033C334.495 345.022 323.024 337.94 317.104 325.022C313.841 317.94 311.985 310.407 310.798 302.647Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M196.482 135C191.119 133.462 185.453 131.604 180.254 128.498C168.481 121.431 164.434 106.278 171.115 95.0731C172.184 93.2589 173.491 93.3727 175.133 93.6414C181.266 94.5887 187.066 96.5898 192.233 100.331C200.888 106.569 202.505 111.57 199.44 121.318C198.574 124.152 197.742 127.021 197.077 129.934C196.677 131.454 196.68 133.132 196.482 135Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M355.916 143.249C364.007 133.407 365.81 130.027 365.966 123.759C362.792 128.499 359.886 133.456 355.872 137.506C354.701 138.677 353.901 140.843 351.923 140.285C350.247 139.846 350.21 137.595 349.772 136.125C348.794 133.01 347.884 129.832 347.409 126.648C345.511 114.312 355.142 103.893 367.541 104.587C368.948 104.674 369.887 105.067 370.593 106.419C371.601 108.361 372.844 110.217 373.753 112.186C381.657 129.422 375.078 141.338 355.916 143.249Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M378.025 347.395C374.466 343.619 370.975 339.846 367.417 336.069C365.604 334.145 365.803 333.016 368.449 332.608C372.636 331.958 376.823 331.175 381.146 331.474C393.008 332.27 402.41 343.723 400.923 355.415C400.692 357.314 399.521 358.215 398.048 358.729C396.072 359.447 394.063 360.062 392.02 360.507C373.934 364.701 363.734 356.421 363.431 336.798C368.5 342.178 372.966 347.857 379.773 350.591C381.078 349.832 380.775 349.176 378.025 347.395Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M144.653 155.987C150.554 158.498 156.122 161.592 161.188 165.764C163.705 167.832 163.573 168.898 160.66 170.028C157.245 171.363 153.93 172.938 150.28 173.688C138.255 176.071 126.815 167.816 124.816 155.44C124.478 153.405 125.448 152.491 126.652 151.491C131.203 147.877 136.291 145.302 142.153 144.353C150.326 143.042 156.329 146.13 161.134 154.247C162.815 157.035 163.993 160.162 165.977 164.212C158.431 160.022 152.324 155.082 144.65 154.443C143.377 154.835 143.11 155.355 144.653 155.987Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M208.637 206.274C190.519 211.372 171.172 203.436 163.304 188.049C161.388 184.272 162.324 183.121 165.941 182.403C181.985 179.192 193.759 186.596 203.531 199.18C205.311 201.404 206.923 203.853 208.637 206.274Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M331.039 285.192C344.078 287.974 356.379 290.24 364.842 301.501C368.704 306.606 373.677 314.162 370.835 317.982C367.794 322.126 359.645 318.504 353.979 316.108C346.166 312.842 341.096 306.589 337.163 299.332C334.81 295.025 332.423 290.749 331.039 285.192Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M310.279 179.25C310.657 165.846 310.702 153.361 320.369 144.119C323.546 141.058 327.261 138.903 331.414 137.479C336.637 135.652 337.14 135.985 337.654 141.891C338.543 152.083 335.945 160.786 328.452 167.577C323.3 172.296 317.51 175.935 310.279 179.25Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M255.375 91.2194C253.765 90.382 252.155 89.5447 250.546 88.6402C235.453 80.1268 236.471 68.3395 242.514 56.5252C243.716 54.1821 245.493 54.7947 247.471 55.554C263.129 61.383 268.279 74.7575 260.634 90.3003C255.565 85.2559 252.137 79.2715 248.743 73.3228C248.239 72.5869 247.735 71.7167 247.201 73.2281C249.054 79.015 251.778 84.3855 255.907 89.0366C256.177 89.9259 256.782 90.903 255.375 91.2194Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M441.704 245.026C435.406 245.647 429.107 245.864 422.538 244.521C430.418 229.294 442.675 226.086 456.362 235.117C459.683 237.302 460.155 239.345 458.519 242.635C451.107 257.286 438.647 259.542 423.818 248.292C430.049 247.802 436.149 248.78 441.707 246.571C442.209 246.501 442.678 246.328 442.71 245.759C442.743 245.191 442.106 245.185 441.704 245.026Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M298.178 307.545C302.543 313.184 305.904 318.829 308.16 324.976C311.964 335.314 309.199 343.838 302.611 351.457C300.271 354.133 299.063 353.387 297.215 350.42C289.084 337.299 289.191 322.2 298.178 307.545Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M335.995 113.189C332.015 117.006 325.886 118.476 319.589 119.634C317.378 120.035 316.908 119.133 316.401 117.189C312.152 101.688 322.35 89.3898 338.267 90.6695C339.909 90.8039 340.915 91.1342 341.287 92.9362C342.875 100.385 342.518 107.245 335.995 113.189Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M235.156 177.188C228.95 172.678 224.485 167.805 220.89 162.247C214.909 153.017 215.195 143.871 220.104 134.874C221.44 132.404 222.377 132.059 224.693 134.249C234.862 143.769 238.2 155.287 236.715 168.254C236.385 171.053 235.753 173.734 235.156 177.188Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M396.044 324.969C391.276 318.969 389.923 311.769 389.139 304.37C388.968 302.647 390.107 302.516 391.514 302.468C410.142 301.665 417.331 312.177 416.658 329.155C416.594 330.964 415.69 331.412 414.116 331.684C408.823 332.433 400.173 329.351 396.044 324.969Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M337.674 210.406C343.93 204.849 349.885 200.179 357.454 197.824C366.127 195.101 373.804 197.687 381.152 203.274C383.903 205.39 383.101 206.482 381.16 208.042C373.498 214.117 364.691 216.832 354.57 215.405C349.006 214.593 343.609 213.087 337.674 210.406Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M140.453 187.383C144.146 191.437 146.131 196.359 148.15 201.25C149.025 203.318 148.726 204.407 146.381 205.069C129.403 209.901 120.108 202.18 116.258 185.36C115.853 183.523 116.186 182.536 118.162 181.818C125.863 179.002 134.846 181.097 140.453 187.383Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M288.678 71.3448C297.506 80.5141 297.361 92.9592 288.999 101.106C287.56 102.529 286.957 102.223 285.782 100.775C283.968 98.4477 282.021 96.2468 280.04 94.0775C271.614 84.9329 271.365 76.8947 279.089 68.0033C280.561 66.3481 281.833 66.1577 283.611 67.4418C285.524 68.8684 287.403 70.3937 288.678 71.3448Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M408.489 275.986C412.405 273.441 416.22 270.788 420.203 268.381C427.867 263.715 438.695 266.697 443.869 274.668C445.347 276.907 443.908 277.927 442.838 279.204C432.205 291.24 418.8 289.946 408.489 275.986Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M403.004 206.901C404.843 204.63 406.212 201.995 408.487 199.987C415.312 193.96 424.292 194.512 431.273 201.319C433.052 203.073 432.083 204.255 431.515 205.597C426.544 217.343 416.029 220.725 404.425 214.373C403.15 213.691 401.876 212.941 400.568 212.223C398.686 209.086 403.042 209.085 403.004 206.901Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M223.812 90.9587C229.284 96.8336 229.976 109.095 225.663 114.436C224.961 115.299 224.325 115.495 223.486 114.739C220.834 112.327 217.348 111.576 214.464 109.72C205.709 104.047 203.343 92.4878 209.159 84.7885C210.296 83.3142 211.469 83.4869 212.843 84.0748C217.035 85.8428 221.126 87.7725 223.812 90.9587Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M292.89 368.015C296.549 371.899 300.006 375.166 302.994 378.807C309.273 386.544 308.051 396.506 300.69 402.096C298.85 403.494 297.675 402.18 296.434 401.398C285.029 394.253 282.529 382.955 290.016 372.404C290.818 371.312 291.52 370.113 292.89 368.015Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M298.867 205.81C297.966 207.601 297.031 209.423 296.062 211.277C291.896 204.912 294.125 195.616 300.787 192.366C304.503 190.547 309.49 187.932 312.711 190.48C316.402 193.392 315.608 199.05 314.51 203.582C312.914 210.264 307.325 214.05 298.113 214.995C298.308 211.382 299.877 208.289 300.977 205.033C300.638 203.065 301.875 201.698 302.543 200.06C303.312 199.134 304.315 198.39 304.65 198.008C304.517 198.94 302.508 199.286 302.075 200.837C301.576 202.989 299.568 203.94 298.867 205.81Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M306.956 231.951C310.368 228.737 313.645 225.447 317.125 222.438C322.21 218.051 330.856 218.85 335.723 223.882C337.166 225.347 336.498 226.381 335.797 227.647C330.017 237.396 321.216 243.401 306.956 231.951Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M269.514 205.146C269.866 195.802 268.979 186.616 276.909 180.49C279.686 178.345 283.266 175.375 286.217 177.101C289.203 178.862 288.407 183.647 287.91 187.141C286.421 197.557 279.024 202.037 269.514 205.146Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M312.416 251.287C327.221 248.504 331.145 250.658 339.951 266.44C324.412 270.521 316.564 266.212 312.416 251.287Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M131.676 264.659C137.924 254.67 149.275 250.164 158.395 253.947C160.44 254.777 163.122 255.748 163.461 257.716C163.901 260.126 160.987 260.585 159.213 261.584C147.798 267.764 141.802 268.504 131.676 264.659Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M252.03 170.303C262.733 178.581 266.816 194.709 260.736 204.809C250.969 195.247 247.426 181.165 252.03 170.303Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M237.671 386.946C232.089 375.324 234.483 365.132 243.522 360.786C247.004 359.12 248.278 359.936 249.357 363.762C251.818 372.272 247.975 377.776 242.422 382.739C241.185 384.509 239.814 386.137 237.671 386.946Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M131.109 245.892C126.928 249.831 120.092 249.747 114.858 245.532C113.684 244.621 113.381 243.83 113.747 242.342C116.243 232.861 130.008 229.51 137.291 236.57C138.432 237.647 138.165 238.235 137.362 239.126C135.322 241.25 133.383 243.413 131.109 245.892Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M382.813 138.69C386.727 135.137 395.405 135.032 399.565 138.174C400.907 139.196 400.775 140.195 400.543 141.49C399.05 150.026 387.029 154.222 379.581 148.931C378.273 148.011 378.506 147.119 378.772 146.196C379.571 143.291 380.538 140.43 382.813 138.69Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M365.153 363.625C369.517 368.391 369.398 376.843 365.219 381.521C364.316 382.573 363.578 382.461 362.539 382.162C354.024 379.558 350.146 365.657 356.166 359.515C357.371 358.313 358.377 359.18 359.517 359.586C361.797 360.465 363.476 362.246 365.153 363.625Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M154.87 136.675C151.682 133.424 150.358 123.776 152.429 120.412C153.331 118.957 154.538 119.501 155.644 119.67C164.324 120.84 170.442 132.092 166.636 139.714C166.069 140.854 165.299 141.176 164.092 140.967C160.707 140.323 157.455 139.351 154.87 136.675Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M309.064 99.6174C306.908 92.7712 307.333 86.8557 309.132 81.1252C309.765 79.1164 310.8 77.2666 312.507 75.995C314.481 74.5386 316.891 73.0752 319.306 74.432C321.519 75.6422 320.619 78.306 320.455 80.2764C319.766 88.3913 314.884 93.8653 309.064 99.6174Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M439.514 313.406C429.126 312.836 422.616 307.401 417.543 299.67C416.905 298.658 416.165 297.001 416.566 296.287C417.3 294.855 419.077 295.401 420.418 295.886C428.934 298.826 435.109 304.442 439.514 313.406Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M133.437 216.998C123.453 217.459 116.211 214.262 110.535 207.166C109.662 206.038 107.85 204.584 108.953 203.007C109.889 201.655 111.867 202.011 113.375 202.406C121.387 204.677 127.393 209.779 133.437 216.998Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M284.034 135.712C283.997 133.595 284.798 131.831 286.234 129.469C287.211 132.315 286.477 134.015 285.776 135.684C285.207 135.75 284.637 135.749 284.034 135.712Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M298.866 205.808C299.668 204.112 300.469 202.415 301.236 200.683C301.772 200.649 302.308 200.279 302.945 200.62C303.015 202.437 301.811 203.706 301.01 205.134C300.273 205.357 299.536 205.581 298.866 205.808Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M378.026 347.395C379.904 348.585 381.749 349.739 384.063 351.19C381.988 352.607 380.847 351.328 379.673 350.618C379.938 349.157 378.63 348.439 378.026 347.395Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M403.004 206.898C403.31 209.233 400.128 209.81 400.534 212.252C399.127 212.166 398.623 211.43 399.526 210.377C400.595 209.1 401.867 208.037 403.004 206.898Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M237.673 386.945C239.245 385.531 240.817 384.117 242.457 382.707C241.858 385.02 239.95 386.212 238.277 387.586C238.21 387.649 237.942 387.566 237.841 387.459C237.707 387.316 237.707 387.115 237.673 386.945Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M441.705 245.026C442.944 244.599 444.182 243.769 445.659 245.001C444.757 246.523 443.316 246.737 441.708 246.638C441.707 246.101 441.706 245.564 441.705 245.026Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M144.652 155.986C143.245 155.967 141.938 155.652 140.294 154.376C141.9 153.467 143.241 153.684 144.649 154.375C144.65 154.912 144.651 155.449 144.652 155.986Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M255.375 91.2184C255.575 90.4922 255.741 89.7975 255.941 89.0712C256.847 89.9661 258.624 90.6459 257.89 92.0778C257.422 92.9219 256.114 92.0694 255.375 91.2184Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M247.235 73.264C246.898 72.2362 245.589 70.7795 247.364 70.5193C248.403 70.3481 248.808 72.1185 248.777 73.3587C248.274 73.3614 247.771 73.2969 247.235 73.264Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M386.706 273.909C384.727 272.68 383.119 272.783 382.378 270.992C384.321 270.507 385.328 271.509 386.706 273.909Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M302.911 200.583C302.342 200.582 301.772 200.648 301.203 200.646C302.541 199.386 303.845 198.157 305.384 196.707C305.388 198.923 303.848 199.567 302.911 200.583Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
</svg>

export const RightArrowCircledIcon = (props = {}) => <svg {...props} width="32" height="32" viewBox="0 0 32 32"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M16.0003 28.332C22.811 28.332 28.3337 22.8107 28.3337 15.9987C28.3337 9.18803 22.811 3.66537 16.0003 3.66537C9.18966 3.66537 3.66699 9.18803 3.66699 15.9987C3.66699 22.8107 9.18966 28.332 16.0003 28.332Z"
          stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.0771 19.998L18.7251 15.37L14.0771 10.742" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
          strokeLinejoin="round"/>
</svg>

export const DownloadIcon = () => <svg width="29" height="27" viewBox="0 0 29 27" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
    <path d="M14.4404 18.2963L14.4404 1" stroke="#133861" strokeWidth="1.72374" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M18.5059 14.0898L14.4401 18.2958L10.3744 14.0898" stroke="#133861" strokeWidth="1.72374"
          strokeLinecap="round" strokeLinejoin="round"/>
    <path
        d="M20.9005 7.79883H22.2014C25.0387 7.79883 27.3379 10.1675 27.3379 13.0922V20.1078C27.3379 23.0238 25.0443 25.3867 22.2139 25.3867H6.6816C3.84424 25.3867 1.54367 23.0166 1.54367 20.0934V13.0763C1.54367 10.1618 3.83866 7.79883 6.66766 7.79883H7.98107"
        stroke="#133861" strokeWidth="1.72374" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export const UpwardArrow = () => <svg width="62" height="60" viewBox="0 0 62 60" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
    <rect x="0.714844" width="60.5046" height="60" rx="15" fill="#45BF55"/>
    <path
        d="M45.3626 28.2243L32.7575 15.7243C32.5177 15.4967 32.235 15.3182 31.9255 15.1993C31.3117 14.9492 30.6233 14.9492 30.0095 15.1993C29.7001 15.3182 29.4174 15.4967 29.1776 15.7243L16.5725 28.2243C16.3374 28.4573 16.151 28.7341 16.0237 29.0386C15.8965 29.3432 15.8311 29.6696 15.8311 29.9993C15.8311 30.665 16.0977 31.3035 16.5725 31.7743C17.0472 32.245 17.691 32.5095 18.3624 32.5095C19.0337 32.5095 19.6776 32.245 20.1523 31.7743L28.4465 23.5243V42.4992C28.4465 43.1623 28.7121 43.7982 29.1849 44.267C29.6577 44.7359 30.2989 44.9992 30.9675 44.9992C31.6361 44.9992 32.2774 44.7359 32.7502 44.267C33.2229 43.7982 33.4886 43.1623 33.4886 42.4992V23.5243L41.7827 31.7743C42.0171 32.0086 42.2959 32.1946 42.6031 32.3215C42.9103 32.4484 43.2398 32.5137 43.5727 32.5137C43.9055 32.5137 44.235 32.4484 44.5422 32.3215C44.8494 32.1946 45.1282 32.0086 45.3626 31.7743C45.5989 31.5418 45.7864 31.2653 45.9144 30.9607C46.0424 30.656 46.1083 30.3293 46.1083 29.9993C46.1083 29.6692 46.0424 29.3425 45.9144 29.0378C45.7864 28.7332 45.5989 28.4567 45.3626 28.2243Z"
        fill="#FCFCFC"/>
</svg>

export const DownwardArrowCircledIcon = () => <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                   xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M20.666 13.3327L15.3327 18.666L9.99935 13.3327L11.3327 11.9993L15.3327 15.9993L19.3327 11.9993L20.666 13.3327Z"
          fill="#004594"/>
    <circle cx="15" cy="15" r="14" transform="rotate(90 15 15)" stroke="#133861" strokeWidth="2"/>
</svg>

export const PlusArrowCircledIcon = () => <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                               xmlns="http://www.w3.org/2000/svg">
    <path
        d="M16.2641 0C7.62479 0 0.595703 7.17785 0.595703 16C0.595703 24.8222 7.62479 32 16.2641 32C24.9034 32 31.9325 24.8222 31.9325 16C31.9325 7.17785 24.9034 0 16.2641 0ZM16.2641 2.46154C23.6005 2.46154 29.522 8.50831 29.522 16C29.522 23.4917 23.6005 29.5385 16.2641 29.5385C8.92767 29.5385 3.00623 23.4917 3.00623 16C3.00623 8.50831 8.92767 2.46154 16.2641 2.46154ZM15.0588 8.61538V14.7692H9.03253V17.2308H15.0588V23.3846H17.4694V17.2308H23.4957V14.7692H17.4694V8.61538H15.0588Z"
        fill="#133861"/>
</svg>

export const TreeSVG2 = () => <svg width="489" height="511" viewBox="0 0 489 511" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
    <path
        d="M335.737 87.7168C357.197 109.048 378.352 130.076 399.948 151.542C319.493 176.961 280.56 238.766 264.715 321.111C258.686 284.344 264.561 250.21 279.425 216.51C247.854 233.604 215.236 241.409 179.639 236.863C257.699 216.487 315.277 173.278 335.737 87.7168Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M157.943 275.575C152.504 278.417 149.113 282.279 146.016 286.244C137.36 297.372 125.834 298.797 112.82 295.251C107.079 293.668 101.726 290.949 96.7422 287.328C94.0993 285.399 94.0698 283.656 95.5401 281.121C103.01 268.241 115.749 262.503 130.667 265.593C139.702 267.468 148.23 271.249 157.943 275.575Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M248.725 280.789C243.174 286.311 236.056 289.007 227.382 286.096C220.149 283.664 216.187 278.141 214.913 270.593C212.465 256.167 225.444 242.356 239.253 244.533C253.741 246.816 260.467 264.288 251.735 276.991C250.93 278.158 250.02 279.156 248.725 280.789Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M229.398 200.029C226.127 191.193 219.665 185.786 215.022 178.95C211.706 174.068 210.831 168.566 210.982 163.005C211.241 151.716 215.294 142.229 222.245 134.037C223.937 132.039 225.103 131.612 227.591 133.64C239.692 143.511 244.725 158.793 240.545 173.673C237.969 182.534 233.349 190.442 229.398 200.029Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M243.159 343.337C246.617 350.265 252.067 353.588 257.172 357.076C265.947 363.07 269.076 371.637 270.06 381.562C270.998 390.615 268.673 398.774 265.404 406.691C264.209 409.564 262.653 409.922 259.836 408.326C246.876 401.029 238.888 390.55 238.535 375.034C238.252 364.411 240.929 354.445 243.159 343.337Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M223.658 375.664C226.312 365.866 225.181 355.604 225.078 344.144C230.105 354.534 234.427 363.78 234.756 374.069C235.212 387.91 225.901 397.627 215.539 402.745C213.566 403.703 212.817 402.323 211.814 401.009C201.135 386.524 200.522 370.37 210.381 356.502C213.517 352.069 216.619 347.601 221.418 343.615C222.982 354.751 222.455 365.268 221.99 375.719C222.276 376.257 222.024 376.959 222.503 377.498C222.823 376.864 223.407 376.366 223.658 375.664Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M298.666 274.782C309.932 272.72 320.952 272.062 332.119 271.741C321.041 268.344 310.139 270.007 299.126 270.799C304.974 266.586 310.948 262.943 317.706 260.714C331.66 256.057 346.989 262.601 355.018 276.229C357.037 279.695 354.848 280.818 353.221 282.247C339.573 294.378 325.184 294.542 308.06 282.725C304.892 280.591 301.387 278.756 298.666 274.782Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M160.407 253.277C147.598 252.347 136.026 249.918 124.463 247.053C134.999 253.529 146.578 254.886 158.121 256.779C148.944 260.03 139.688 263.012 129.547 261.497C118.79 259.878 106.339 247.692 103.93 236.986C103.381 234.536 104.627 233.808 106.055 232.88C118.232 225.061 134.364 226.82 145.973 237.09C151.071 241.65 155.317 247.075 160.407 253.277Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M176.012 315.677C174.418 333.826 171.103 350.454 154.594 358.979C144.807 364.035 144.089 365.035 143.049 351.692C142.184 340.997 145.799 332.345 154.016 326.172C160.5 321.26 168.004 318.567 176.012 315.677Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M127.442 336.433C125.361 343.756 123.075 350.24 118.636 355.535C111.841 363.628 97.3931 364.495 88.5977 357.529C87.0877 356.346 87.1889 355.241 87.4649 353.802C88.3834 348.179 90.0236 342.83 93.1861 338.296C99.4236 329.395 104.624 328.093 114.833 332.656C118.822 334.528 122.587 337.001 127.442 336.433Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M173.093 157.069C166.934 154.311 161.406 150.719 156.652 145.994C148.027 137.421 147.664 121.134 155.715 113.083C156.785 112.053 157.651 112.025 158.864 112.47C166.739 115.475 173.718 119.748 178.438 127.421C181.365 132.234 181.16 136.789 178.977 141.598C176.672 146.541 172.95 150.836 173.093 157.069Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M319.53 336.954C321.725 339.516 324.923 343.393 327.199 348.066C328.463 350.656 328.037 351.691 325.458 352.745C312.653 357.846 298.377 353.554 289.575 341.696C285.764 336.575 282.578 331.124 279.574 325.473C278.43 323.321 278.773 322.519 281.03 322.669C294.502 323.37 307.642 325.074 319.53 336.954Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M189.37 210.43C166.882 206.414 152.239 193.509 147.724 175.316C146.78 171.523 147.65 170.356 151.27 170.148C164.503 169.406 175.842 175.854 182.176 188.432C185.664 195.326 187.832 202.746 189.37 210.43Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M307.416 371.613C312.625 372.857 318.14 374.405 323.273 377.223C334.9 383.64 339.559 398.551 333.754 410.102C332.826 411.971 331.57 411.929 329.986 411.75C324.072 411.137 318.421 409.455 313.286 406C304.684 400.241 302.877 395.336 305.301 385.436C305.981 382.559 306.628 379.649 307.112 376.704C307.415 375.165 307.325 373.489 307.416 371.613Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M154.526 372.046C147.301 382.314 145.753 385.788 145.93 392.054C148.719 387.149 151.24 382.041 154.867 377.778C155.925 376.546 156.578 374.34 158.498 374.789C160.124 375.136 160.277 377.382 160.772 378.826C161.869 381.883 162.905 385.007 163.525 388.161C165.982 400.375 157.315 411.303 145.423 411.284C144.072 411.274 143.154 410.932 142.409 409.62C141.343 407.736 140.058 405.951 139.087 404.034C130.63 387.253 136.302 374.997 154.526 372.046Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M122.754 169.404C126.354 172.982 129.889 176.559 133.488 180.137C135.322 181.959 135.19 183.098 132.681 183.649C128.711 184.525 124.748 185.535 120.599 185.472C109.214 185.322 99.6265 174.397 100.44 162.642C100.562 160.733 101.635 159.769 103.017 159.176C104.869 158.351 106.758 157.628 108.688 157.072C125.765 151.902 135.95 159.615 137.262 179.192C132.134 174.096 127.568 168.668 120.916 166.308C119.708 167.137 120.031 167.776 122.754 169.404Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M355.886 347.838C350.112 345.652 344.627 342.864 339.565 338.975C337.05 337.046 337.121 335.975 339.848 334.688C343.044 333.17 346.132 331.416 349.584 330.469C360.958 327.436 372.328 335.057 374.883 347.305C375.313 349.319 374.433 350.284 373.334 351.348C369.171 355.205 364.439 358.052 358.883 359.319C351.135 361.072 345.234 358.315 340.216 350.472C338.464 347.779 337.175 344.72 335.066 340.785C342.501 344.558 348.598 349.159 355.969 349.38C357.166 348.919 357.394 348.385 355.886 347.838Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M292.082 301.104C309.141 295.029 328.055 301.901 336.38 316.838C338.409 320.505 337.574 321.705 334.152 322.619C318.978 326.697 307.334 319.945 297.333 307.911C295.515 305.787 293.846 303.429 292.082 301.104Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M170.923 228.96C158.31 226.892 146.429 225.298 137.749 214.513C133.79 209.626 128.642 202.351 131.16 198.382C133.853 194.079 141.833 197.253 147.377 199.337C155.018 202.174 160.192 208.142 164.331 215.174C166.806 219.347 169.31 223.487 170.923 228.96Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M196.293 333.615C196.63 347.02 197.237 359.489 188.474 369.243C185.595 372.472 182.155 374.826 178.258 376.473C173.358 378.582 172.86 378.277 172.061 372.407C170.68 362.278 172.711 353.447 179.523 346.259C184.204 341.267 189.551 337.319 196.293 333.615Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M253.378 418.532C254.961 419.28 256.544 420.029 258.131 420.844C273.007 428.525 272.647 440.35 267.483 452.475C266.456 454.88 264.725 454.365 262.794 453.714C247.517 448.745 241.896 435.671 248.398 419.735C253.507 424.497 257.097 430.286 260.653 436.041C261.173 436.748 261.7 437.59 262.132 436.052C260.058 430.374 257.174 425.16 252.983 420.74C252.679 419.867 252.05 418.924 253.378 418.532Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M67.1908 275.084C73.181 274.122 79.1928 273.562 85.545 274.547C78.8022 290.179 67.248 294.049 53.69 285.775C50.4005 283.774 49.8421 281.76 51.2352 278.386C57.5603 263.354 69.3579 260.424 84.1246 270.851C78.1915 271.679 72.3076 271.033 67.1077 273.542C66.6309 273.639 66.1916 273.837 66.1902 274.406C66.1887 274.976 66.7978 274.947 67.1908 275.084Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M201.184 204.854C196.715 199.46 193.208 194.007 190.73 187.991C186.554 177.875 188.755 169.213 194.657 161.248C196.756 158.449 197.95 159.127 199.871 161.989C208.331 174.649 209.014 189.732 201.184 204.854Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M175.141 400.978C178.749 396.95 184.533 395.149 190.494 393.65C192.588 393.13 193.085 394.004 193.67 395.918C198.54 411.166 189.429 424 174.141 423.587C172.564 423.542 171.585 423.267 171.136 421.488C169.23 414.136 169.213 407.268 175.141 400.978Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M268.237 331.591C274.407 335.757 278.93 340.38 282.657 345.735C288.857 354.625 289.06 363.774 284.834 373.025C283.685 375.563 282.807 375.958 280.478 373.898C270.258 364.945 266.467 353.625 267.211 340.597C267.381 337.783 267.846 335.073 268.237 331.591Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M106.69 192.777C111.562 198.508 113.231 205.624 114.365 212.97C114.618 214.68 113.536 214.874 112.193 214.998C94.4217 216.813 86.9998 206.707 86.7597 189.717C86.7266 187.908 87.5676 187.411 89.0588 187.054C94.0809 186.018 102.513 188.625 106.69 192.777Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M168.473 303.996C162.78 309.886 157.328 314.872 150.214 317.635C142.062 320.827 134.585 318.661 127.268 313.482C124.527 311.519 125.237 310.385 127.012 308.722C134.022 302.239 142.303 299.049 152.056 299.924C157.418 300.432 162.658 301.642 168.473 303.996Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M358.267 316.26C354.525 312.413 352.37 307.606 350.185 302.833C349.24 300.815 349.47 299.711 351.677 298.923C367.662 293.175 376.952 300.379 381.509 316.965C381.993 318.777 381.725 319.781 379.873 320.605C372.656 323.836 363.956 322.232 358.267 316.26Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M222.569 440.187C213.649 431.512 213.14 419.077 220.712 410.487C222.014 408.989 222.607 409.261 223.806 410.644C225.661 412.869 227.638 414.96 229.645 417.019C238.179 425.692 238.835 433.704 231.912 443.003C230.591 444.735 229.384 444.995 227.617 443.809C225.714 442.489 223.838 441.068 222.569 440.187Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M97.34 242.365C93.7281 245.12 90.2182 247.976 86.5349 250.596C79.4485 255.672 68.9392 253.284 63.5764 245.605C62.0462 243.45 63.3691 242.354 64.3257 241.02C73.8673 228.424 86.753 228.986 97.34 242.365Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M106.183 311.049C104.543 313.416 103.371 316.122 101.3 318.251C95.0877 324.639 86.4712 324.577 79.4409 318.16C77.6484 316.505 78.514 315.271 78.9866 313.901C83.129 301.902 93.0077 297.953 104.435 303.665C105.689 304.277 106.947 304.956 108.235 305.602C110.198 308.632 106.033 308.87 106.183 311.049Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M283.575 417.075C278.037 411.506 276.737 399.301 280.583 393.733C281.209 392.833 281.807 392.602 282.649 393.312C285.31 395.576 288.682 396.137 291.538 397.833C300.205 403.022 303.069 414.435 297.908 422.439C296.898 423.973 295.768 423.865 294.422 423.352C290.322 421.815 286.31 420.11 283.575 417.075Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M203.091 144.186C199.39 140.507 195.914 137.433 192.867 133.959C186.459 126.575 187.109 116.561 193.857 110.579C195.544 109.084 196.736 110.332 197.964 111.044C209.242 117.559 212.22 128.705 205.611 139.647C204.9 140.781 204.292 142.017 203.091 144.186Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M205.823 306.476C206.591 304.639 207.391 302.768 208.22 300.864C212.535 306.994 210.888 316.397 204.687 320.004C201.229 322.023 196.595 324.905 193.382 322.536C189.701 319.829 190.166 314.136 190.98 309.552C192.158 302.792 197.305 298.708 206.066 297.263C206.068 300.882 204.728 304.056 203.846 307.366C204.272 309.313 203.16 310.746 202.607 312.417C201.92 313.384 200.999 314.181 200.699 314.581C200.777 313.643 202.681 313.188 203.014 311.616C203.379 309.44 205.25 308.382 205.823 306.476Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M196.726 280.813C193.631 284.208 190.668 287.671 187.498 290.865C182.864 295.522 174.554 295.194 169.638 290.434C168.181 289.05 168.767 287.982 169.371 286.679C174.39 276.63 182.494 270.155 196.726 280.813Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M233.926 305.542C234.076 314.891 235.403 324.014 228.139 330.563C225.595 332.856 222.326 336.016 219.414 334.453C216.467 332.857 216.979 328.036 217.272 324.52C218.153 314.039 224.993 309.163 233.926 305.542Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M190.497 261.801C176.485 265.386 172.62 263.448 163.377 248.169C178.024 243.249 185.753 247.125 190.497 261.801Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M362.636 238.622C357.182 248.936 346.562 254.052 337.644 250.771C335.645 250.053 333.029 249.23 332.603 247.283C332.057 244.9 334.82 244.284 336.464 243.19C347.058 236.398 352.753 235.333 362.636 238.622Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M252.46 339.383C241.794 331.7 237.05 315.817 242.338 305.401C252.176 314.419 256.297 328.287 252.46 339.383Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M254.908 122.28C260.852 133.582 259.093 143.889 250.676 148.72C247.433 150.573 246.172 149.826 244.941 146.065C242.144 137.701 245.533 131.996 250.585 126.739C251.675 124.904 252.901 123.204 254.908 122.28Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M364.156 257.33C367.949 253.17 374.49 252.882 379.715 256.806C380.885 257.653 381.216 258.426 380.944 259.931C379.051 269.534 366.062 273.628 358.73 266.974C357.583 265.961 357.807 265.359 358.528 264.426C360.369 262.195 362.111 259.929 364.156 257.33Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M129.042 378.06C125.485 381.821 117.192 382.397 113.05 379.486C111.713 378.538 111.788 377.533 111.942 376.228C112.925 367.624 124.202 362.78 131.6 367.658C132.899 368.505 132.722 369.409 132.516 370.346C131.903 373.29 131.128 376.199 129.042 378.06Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M134.218 152.5C129.796 147.978 129.47 139.532 133.223 134.634C134.032 133.534 134.743 133.606 135.752 133.848C144.03 135.985 148.462 149.654 143.025 156.115C141.937 157.381 140.929 156.569 139.818 156.226C137.592 155.473 135.894 153.785 134.218 152.5Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M347.121 367.678C350.339 370.75 352.108 380.313 350.302 383.784C349.515 385.286 348.333 384.809 347.266 384.7C338.905 384.003 332.468 373.101 335.711 365.284C336.195 364.114 336.914 363.751 338.079 363.893C341.35 364.353 344.51 365.146 347.121 367.678Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M201.6 413.065C204.019 419.784 203.92 425.713 202.498 431.533C201.998 433.574 201.104 435.477 199.538 436.839C197.726 438.401 195.498 439.993 193.118 438.77C190.938 437.682 191.66 434.973 191.714 432.997C191.951 424.856 196.334 419.125 201.6 413.065Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M65.7239 206.686C75.687 206.691 82.1951 211.764 87.4488 219.207C88.112 220.183 88.906 221.797 88.5599 222.532C87.932 224.002 86.2045 223.554 84.8968 223.142C76.6006 220.67 70.4032 215.398 65.7239 206.686Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M363.434 286.31C372.957 285.307 380.05 288.106 385.846 294.882C386.74 295.961 388.548 297.314 387.575 298.949C386.751 300.35 384.841 300.102 383.378 299.79C375.599 297.958 369.589 293.19 363.434 286.31Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M223.657 375.664C223.803 377.776 223.129 379.581 221.878 382.018C220.796 379.229 221.409 377.491 221.992 375.786C222.533 375.69 223.078 375.66 223.657 375.664Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M205.824 306.478C205.146 308.216 204.468 309.953 203.824 311.724C203.313 311.788 202.821 312.186 202.194 311.88C202.032 310.069 203.117 308.737 203.809 307.268C204.502 307.005 205.195 306.742 205.824 306.478Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M122.754 169.405C120.896 168.319 119.071 167.267 116.783 165.944C118.693 164.417 119.851 165.631 121.011 166.276C120.833 167.749 122.121 168.395 122.754 169.405Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M106.184 311.051C105.77 308.736 108.782 307.987 108.267 305.571C109.617 305.581 110.137 306.288 109.329 307.388C108.372 308.721 107.212 309.852 106.184 311.051Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M254.908 122.279C253.478 123.777 252.048 125.274 250.554 126.771C251.006 124.429 252.768 123.135 254.296 121.672C254.357 121.605 254.618 121.674 254.72 121.775C254.855 121.91 254.866 122.112 254.908 122.279Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M67.1901 275.085C66.0276 275.579 64.8868 276.475 63.4107 275.325C64.194 273.756 65.5602 273.464 67.1034 273.476C67.1323 274.012 67.1612 274.548 67.1901 275.085Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M355.887 347.84C357.234 347.783 358.5 348.026 360.139 349.211C358.65 350.205 357.356 350.062 355.974 349.449C355.945 348.913 355.916 348.376 355.887 347.84Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M253.378 418.533C253.225 419.269 253.102 419.972 252.949 420.708C252.036 419.863 250.301 419.281 250.929 417.811C251.332 416.943 252.628 417.723 253.378 418.533Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M262.098 436.018C262.474 437.026 263.801 438.409 262.117 438.765C261.133 438.993 260.654 437.247 260.619 436.007C261.099 435.977 261.583 436.014 262.098 436.018Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M118.279 243.254C120.235 244.374 121.768 244.184 122.569 245.932C120.737 246.522 119.722 245.576 118.279 243.254Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M202.228 311.915C202.773 311.885 203.314 311.789 203.859 311.759C202.645 313.091 201.461 314.389 200.065 315.92C199.946 313.708 201.386 312.981 202.228 311.915Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
</svg>

export const TreeSVG3 = () => <svg width="458" height="490" viewBox="0 0 458 490" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
    <path
        d="M188.633 432.854C162.22 418.955 136.183 405.255 109.603 391.269C179.358 342.532 199.365 271.579 191.624 187.981C207.591 221.331 211.461 255.774 206.628 292.531C232.023 266.594 260.997 249.227 296.245 242.775C227.378 285.96 184.405 344.777 188.633 432.854Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M306.209 199.15C310.613 194.78 312.778 190.055 314.634 185.321C319.808 172.046 330.416 167.186 343.825 166.629C349.747 166.401 355.612 167.379 361.376 169.331C364.435 170.375 364.947 172.034 364.247 174.906C360.693 189.496 350.124 198.852 335.023 200.423C325.876 201.371 316.685 200.341 306.209 199.15Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M218.088 221.71C221.855 214.741 227.902 210.002 236.991 210.155C244.572 210.286 249.888 214.369 253.201 221.205C259.544 234.265 250.988 251.419 237.2 253.525C222.733 255.738 211.46 241.061 216.269 226.257C216.714 224.896 217.305 223.664 218.088 221.71Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M258.967 293.117C264.544 300.579 272.215 303.792 278.546 308.924C283.068 312.589 285.431 317.588 286.832 322.954C289.719 333.836 288.484 344.143 284.123 354.09C283.063 356.516 282.068 357.278 279.13 356.092C264.835 350.319 255.785 337.224 255.644 321.718C255.643 312.458 257.857 303.49 258.967 293.117Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M206.032 160.171C200.807 154.592 194.681 153.066 188.838 151.278C178.796 148.205 173.429 140.958 169.733 131.76C166.324 123.382 166.277 114.87 167.2 106.302C167.543 103.19 168.929 102.376 172.062 103.048C186.461 106.098 196.998 113.7 201.644 128.439C204.865 138.518 205.076 148.866 206.032 160.171Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M215.673 123.322C215.86 133.503 219.79 142.978 223.07 153.912C215.386 145.496 208.691 137.961 205.52 128.216C201.24 115.11 207.432 102.988 215.903 94.9456C217.521 93.4307 218.62 94.523 219.942 95.476C234.16 106.095 239.231 121.367 233.67 137.627C231.907 142.821 230.186 148.037 226.712 153.307C222.126 143.127 219.708 132.904 217.25 122.763C216.828 122.335 216.873 121.587 216.266 121.216C216.137 121.92 215.718 122.574 215.673 123.322Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M172.074 242.611C161.891 248.004 151.553 251.977 140.98 255.673C152.5 255.562 162.447 250.662 172.741 246.563C168.329 252.368 163.636 257.667 157.804 261.85C145.774 270.541 129.322 268.931 117.871 258.328C114.982 255.624 116.76 253.885 117.916 252.024C127.577 236.275 141.27 231.751 160.9 237.863C164.517 238.943 168.373 239.635 172.074 242.611Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M310.048 221.231C322.536 218.234 334.258 217.047 346.094 216.279C334.236 213.28 322.805 215.495 311.258 217.187C319.117 211.291 327.126 205.629 337.228 204.001C347.948 202.285 363.22 210.167 368.492 219.68C369.698 221.857 368.71 222.932 367.604 224.254C358.149 235.431 342.26 238.643 328.324 232.34C322.19 229.523 316.63 225.62 310.048 221.231Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M277.821 166.261C274.302 148.412 272.849 131.495 286.244 118.327C294.184 110.52 294.591 109.345 299.29 121.797C303.086 131.768 302.036 141.143 295.906 149.543C291.079 156.211 284.663 161.065 277.821 166.261Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M318.428 131.661C318.38 124.023 318.762 117.125 321.53 110.711C325.77 100.905 339.323 95.6914 349.655 99.6876C351.425 100.362 351.635 101.45 351.771 102.91C352.456 108.569 352.375 114.185 350.615 119.483C347.132 129.893 342.529 132.717 331.515 131.448C327.186 130.868 322.905 129.644 318.428 131.661Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M324.656 317.136C331.301 317.907 337.577 319.666 343.428 322.745C354.043 328.331 358.913 343.804 353.462 353.95C352.727 355.261 351.908 355.55 350.626 355.493C342.273 355.007 334.423 353.037 327.786 347.127C323.655 343.41 322.585 338.989 323.335 333.725C324.163 328.297 326.523 323.057 324.656 317.136Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M134.89 189.455C132.083 187.669 127.954 184.93 124.482 181.149C122.556 179.055 122.676 177.935 124.845 176.144C135.654 167.378 150.476 167.152 162.172 175.827C167.233 179.57 171.788 183.819 176.226 188.315C177.916 190.027 177.812 190.898 175.614 191.44C162.558 194.857 149.54 197.215 134.89 189.455Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M294.295 271.019C316.88 268.037 334.444 275.942 343.807 291.98C345.761 295.323 345.256 296.703 341.857 298.001C329.429 302.727 316.813 299.997 307.273 289.885C302.027 284.347 297.898 277.905 294.295 271.019Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M136.831 152.616C131.512 153.006 125.817 153.199 120.134 152.06C107.252 149.448 98.6623 136.595 100.997 123.781C101.364 121.71 102.574 121.37 104.137 121.06C109.953 119.852 115.816 119.747 121.678 121.494C131.489 124.394 134.577 128.539 135.012 138.747C135.161 141.706 135.352 144.687 135.708 147.652C135.846 149.217 136.398 150.793 136.831 152.616Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M282.68 105.804C286.726 93.7868 287.239 89.9934 285.33 84.0506C284.03 89.5904 283.042 95.2432 280.762 100.422C280.094 101.923 280.084 104.232 278.126 104.385C276.477 104.546 275.707 102.444 274.833 101.212C272.937 98.6196 271.081 95.9452 269.613 93.1153C263.875 82.1742 269.115 69.0882 280.474 65.4976C281.766 65.0972 282.738 65.1455 283.814 66.1748C285.355 67.6542 287.077 68.9722 288.537 70.5117C301.27 84.0022 299.26 97.45 282.68 105.804Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M369.288 290.058C364.858 287.727 360.49 285.377 356.06 283.046C353.803 281.858 353.613 280.729 355.855 279.44C359.402 277.397 362.905 275.228 366.884 274.03C377.796 270.718 389.983 278.262 392.471 289.757C392.884 291.62 392.128 292.868 390.973 293.855C389.434 295.206 387.831 296.471 386.142 297.589C371.275 307.718 359.408 303.429 352.719 285.094C359.03 288.415 364.897 292.223 371.903 292.462C372.827 291.303 372.34 290.789 369.288 290.058Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M97.1572 190.072C103.277 190.412 109.288 191.414 115.201 193.6C118.137 194.682 118.367 195.729 116.121 197.788C113.491 200.21 111.031 202.825 107.998 204.778C97.9809 211.133 85.0095 207.291 79.1679 196.347C78.1983 194.55 78.7704 193.36 79.5248 192.008C82.4284 187.054 86.1552 182.894 91.108 179.996C98.0177 175.967 104.417 176.814 111.387 182.796C113.807 184.842 115.888 187.377 118.993 190.503C110.848 189.149 103.749 186.596 96.6497 188.622C95.6353 189.426 95.5657 190.006 97.1572 190.072Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M171.052 215.426C156.452 226.416 136.486 225.581 124.39 213.814C121.434 210.922 121.898 209.52 124.911 207.607C138.267 199.1 151.259 202.027 164.148 210.507C166.474 211.988 168.722 213.737 171.052 215.426Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M306.761 247.691C319.378 245.842 331.163 243.762 342.445 251.447C347.582 254.922 354.518 260.32 353.216 264.882C351.84 269.817 343.339 269.202 337.468 268.89C329.385 268.495 322.788 264.354 316.883 258.881C313.362 255.639 309.821 252.439 306.761 247.691Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M253.476 155.251C249.433 142.527 245.39 130.78 251.048 118.788C252.9 114.825 255.531 111.528 258.793 108.77C262.886 105.265 263.447 105.406 265.839 110.779C269.97 120.052 270.483 129.118 265.976 138.063C262.894 144.26 258.885 149.661 253.476 155.251Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M175.391 91.3225C173.672 91.0866 171.953 90.8507 170.212 90.5517C153.876 87.7173 150.936 76.2935 152.498 63.1243C152.811 60.5113 154.607 60.4788 156.632 60.5153C172.596 60.6341 181.594 71.4386 179.812 88.6593C173.612 85.6539 168.577 81.204 163.584 76.7762C162.891 76.2572 162.153 75.6121 162.169 77.2147C165.725 82.0181 169.927 86.1322 175.155 89.0893C175.688 89.8327 176.551 90.5438 175.391 91.3225Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M392.989 172.078C387.537 174.816 381.952 177.176 375.615 178.162C377.711 161.158 387.667 153.949 402.909 157.751C406.605 158.667 407.698 160.425 407.305 164.077C405.441 180.379 394.991 186.763 377.997 181.268C383.432 178.675 389.228 177.507 393.496 173.528C393.924 173.291 394.289 172.968 394.132 172.423C393.975 171.877 393.402 172.09 392.989 172.078Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M284.565 279.939C290.329 283.744 295.193 287.898 299.228 292.902C306.025 301.314 306.329 310.27 302.906 319.682C301.68 322.998 300.352 322.711 297.722 320.555C286.13 311.009 281.289 296.785 284.565 279.939Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M254.964 84.3758C252.639 89.3248 247.616 92.8034 242.341 96.0459C240.487 97.1795 239.77 96.4933 238.68 94.8394C229.795 81.7284 234.93 66.6835 249.64 62.4386C251.158 62.003 252.169 61.9691 253.092 63.5351C256.954 69.991 258.877 76.5584 254.964 84.3758Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M185.351 179.021C178.304 176.907 172.701 173.857 167.656 169.864C159.268 163.239 156.533 154.547 157.999 144.413C158.391 141.635 159.12 140.99 161.915 142.255C174.159 147.721 180.922 157.401 183.829 170.093C184.448 172.836 184.758 175.571 185.351 179.021Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M378.135 262.821C371.892 258.815 368.322 252.513 365.199 245.829C364.482 244.268 365.462 243.755 366.71 243.229C383.173 236.099 393.065 243.517 398.012 259.7C398.546 261.421 397.881 262.152 396.557 262.946C392.05 265.462 383.275 265.525 378.135 262.821Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M288.262 175.149C292.062 167.786 295.882 161.36 301.907 156.557C308.804 151.03 316.543 150.833 324.968 153.568C328.13 154.615 327.767 155.916 326.534 158.045C321.641 166.375 314.622 171.941 305.067 174.063C299.807 175.205 294.468 175.636 288.262 175.149Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M103.653 221.009C108.295 223.554 111.687 227.5 115.099 231.404C116.561 233.048 116.648 234.174 114.76 235.598C101.095 245.949 90.2245 241.875 81.268 227.388C80.3031 225.8 80.2797 224.759 81.8188 223.409C87.8124 218.126 96.5636 217.021 103.653 221.009Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M198.794 61.2509C209.719 66.8454 213.658 78.5888 208.815 89.1057C207.988 90.9343 207.347 90.8537 205.818 89.8948C203.428 88.329 200.96 86.9275 198.473 85.5669C187.916 79.858 185.064 72.3903 189.092 61.3925C189.872 59.3338 190.952 58.7193 192.969 59.3173C195.152 60.0034 197.338 60.7935 198.794 61.2509Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M373.291 212.534C375.974 208.802 378.532 205.004 381.321 201.379C386.677 194.373 397.374 193.469 404.626 199.188C406.686 200.786 405.727 202.236 405.184 203.803C399.572 218.751 387.114 222.123 373.291 212.534Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M345.774 149.498C346.683 146.735 347.05 143.791 348.436 141.125C352.593 133.127 360.837 130.572 369.331 134.579C371.502 135.618 371.018 137.061 370.947 138.516C370.325 151.254 361.99 158.03 349.493 156.033C348.126 155.828 346.736 155.559 345.327 155.332C342.612 153.029 346.522 151.537 345.774 149.498Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M146.967 101.88C153.801 105.527 158.432 116.812 156.307 123.306C155.958 124.357 155.451 124.759 154.451 124.336C151.281 122.977 147.906 123.464 144.709 122.707C134.992 120.372 129.088 110.321 131.793 101.096C132.331 99.3222 133.441 99.083 134.868 99.165C139.209 99.3919 143.513 99.805 146.967 101.88Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M299.591 338.567C304.147 340.964 308.319 342.851 312.193 345.249C320.361 350.37 322.522 360.149 317.74 367.921C316.545 369.864 315.06 369.031 313.69 368.722C301.114 365.911 295.175 356.15 298.446 343.674C298.81 342.374 299.047 341.007 299.591 338.567Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M251.916 184.109C251.692 186.101 251.448 188.133 251.185 190.206C245.363 185.651 244.324 176.154 249.243 170.82C251.984 167.839 255.608 163.676 259.333 164.967C263.599 166.441 264.736 172.029 265.233 176.662C265.985 183.488 262.205 188.958 254.242 192.998C253.235 189.536 253.633 186.093 253.556 182.658C252.608 180.924 253.272 179.216 253.336 177.449C253.723 176.316 254.381 175.273 254.556 174.799C254.742 175.72 253.051 176.734 253.17 178.339C253.425 180.531 251.933 182.112 251.916 184.109Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M267.726 205.905C269.738 201.717 271.606 197.504 273.746 193.486C276.877 187.624 284.901 185.416 290.917 188.479C292.692 189.361 292.43 190.561 292.214 191.991C290.213 203.129 284.274 211.784 267.726 205.905Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M225.344 193.533C222.604 184.633 218.803 176.306 223.92 167.836C225.712 164.869 227.955 160.854 231.17 161.466C234.426 162.099 235.276 166.867 235.973 170.32C238.042 180.617 232.866 187.358 225.344 193.533Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M278.953 222.206C291.335 214.524 295.563 215.206 308.631 227.02C296.013 236.173 287.557 234.81 278.953 222.206Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M121.043 296.623C123.387 285.099 132.104 276.981 141.53 277.414C143.638 277.494 146.364 277.488 147.311 279.221C148.494 281.336 146.028 282.764 144.762 284.31C136.534 294.023 131.392 296.77 121.043 296.623Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M198.25 166.775C210.567 170.89 219.507 184.648 217.351 196.219C205.454 190.576 197.668 178.557 198.25 166.775Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M256.203 375.253C247.39 366.243 246.207 355.847 252.901 348.67C255.483 345.913 256.894 346.244 259.114 349.47C264.107 356.624 262.456 363.111 259.093 369.674C258.561 371.761 257.863 373.76 256.203 375.253Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M114.397 279.184C111.931 284.316 105.766 286.577 99.6876 284.407C98.3352 283.953 97.8046 283.313 97.6465 281.79C96.7873 272.027 108.051 264.168 116.899 268.31C118.276 268.931 118.228 269.575 117.799 270.686C116.662 273.38 115.628 276.077 114.397 279.184Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M305.34 92.3154C307.693 87.6376 315.45 84.5694 320.213 86.0983C321.752 86.5997 321.96 87.5836 322.175 88.8798C323.626 97.4107 314.205 105.468 305.787 103.044C304.312 102.628 304.23 101.71 304.167 100.751C303.934 97.7483 303.866 94.7291 305.34 92.3154Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M363.039 309.713C368.516 312.697 371.173 320.68 368.95 326.506C368.483 327.804 367.785 327.95 366.754 328.025C358.257 328.492 350.229 316.758 353.626 308.926C354.314 307.385 355.502 307.855 356.658 307.846C358.992 307.892 361.082 308.991 363.039 309.713Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M100.017 168.428C96.0911 166.465 91.7473 157.852 92.507 153.983C92.8411 152.306 94.1029 152.405 95.1511 152.185C103.328 150.314 112.5 158.793 111.575 167.257C111.438 168.522 110.852 169.088 109.701 169.306C106.451 169.859 103.213 170.058 100.017 168.428Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M226.346 80.8409C222.171 75.1461 220.618 69.4422 220.359 63.4421C220.271 61.3379 220.596 59.2454 221.712 57.4667C223.008 55.4225 224.694 53.2229 227.305 53.6713C229.689 54.0509 229.752 56.8618 230.249 58.7695C232.284 66.6302 229.69 73.4445 226.346 80.8409Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M413.384 237.079C403.87 240.097 396.248 237.218 389.165 231.691C388.261 230.958 387.055 229.654 387.181 228.846C387.372 227.249 389.146 227.154 390.509 227.151C399.116 226.999 406.497 230.163 413.384 237.079Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M107.038 251.237C98.2245 255.087 90.6756 254.561 83.2597 249.836C82.1069 249.075 80.0046 248.329 80.4791 246.47C80.877 244.879 82.7691 244.537 84.2526 244.391C92.189 243.784 99.2506 246.522 107.038 251.237Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M215.673 123.322C214.948 121.346 215.09 119.414 215.607 116.703C217.415 119.043 217.312 120.892 217.229 122.7C216.739 122.957 216.227 123.15 215.673 123.322Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M251.913 184.108C252.077 182.24 252.242 180.372 252.365 178.482C252.835 178.266 253.194 177.735 253.878 177.838C254.535 179.521 253.869 181.125 253.617 182.741C253.028 183.203 252.44 183.665 251.913 184.108Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M369.29 290.058C371.366 290.533 373.4 290.986 375.952 291.558C374.553 293.599 373.11 292.788 371.823 292.523C371.584 291.06 370.175 290.832 369.29 290.058Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M345.773 149.496C346.811 151.586 344.143 153.216 345.306 155.372C344.015 155.772 343.321 155.253 343.788 153.955C344.331 152.389 345.125 150.955 345.773 149.496Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M256.205 375.252C257.154 373.385 258.104 371.519 259.114 369.633C259.333 372.011 258.01 373.784 256.957 375.648C256.918 375.73 256.65 375.743 256.524 375.677C256.357 375.589 256.291 375.4 256.205 375.252Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M392.991 172.077C393.963 171.251 394.803 170.047 396.532 170.7C396.22 172.439 394.997 173.133 393.52 173.59C393.344 173.086 393.167 172.581 392.991 172.077Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M97.157 190.071C95.8871 190.535 94.6103 190.686 92.7169 190.05C93.8624 188.646 95.1369 188.391 96.6274 188.558C96.804 189.063 96.9805 189.567 97.157 190.071Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M175.391 91.3221C175.333 90.5713 175.255 89.8616 175.197 89.1109C176.304 89.6416 178.121 89.6722 177.93 91.269C177.786 92.2222 176.333 91.8689 175.391 91.3221Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M162.211 77.2363C161.573 76.3859 159.921 75.4652 161.43 74.6132C162.306 74.0969 163.249 75.6218 163.626 76.7978C163.176 76.9723 162.703 77.0838 162.211 77.2363Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M353.052 218.039C350.874 217.561 349.463 218.208 348.213 216.778C349.798 215.658 351.03 216.254 353.052 218.039Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
    <path
        d="M253.837 177.817C253.325 178.01 252.835 178.267 252.323 178.46C253.112 176.818 253.882 175.217 254.789 173.328C255.517 175.409 254.345 176.541 253.837 177.817Z"
        fill="#FCFCFC" fillOpacity="0.05"/>
</svg>

export const CircleIcon = () => <svg width="19" height="21" viewBox="0 0 19 21" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="9.5" cy="10.6613" rx="9.5" ry="9.69451" fill="currentColor"/>
</svg>

export const SmallDownArrow = () => <svg width="15" height="16" viewBox="0 0 15 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
    <path
        d="M0.120117 4.49367C0.120117 4.36769 0.169922 4.23877 0.266602 4.14209C0.459961 3.94873 0.776367 3.94873 0.969727 4.14209L7.58789 10.7603L14.1094 4.23877C14.3027 4.04541 14.6191 4.04541 14.8125 4.23877C15.0059 4.43213 15.0059 4.74853 14.8125 4.94189L7.93945 11.8179C7.74609 12.0112 7.42969 12.0112 7.23633 11.8179L0.266602 4.84815C0.166992 4.74854 0.120117 4.62257 0.120117 4.49367Z"
        fill="currentColor"/>
</svg>


