import './App.scss';
import React, {Component} from 'react';
import {languages, routes} from "./Enums/Enums";
import ButtonMui, {button_theme, buttonRoundnessTypes, variantTypes} from "./components/buttons/buttonMui";
import {Button} from "@mui/material";
import colors from "./colors";
import Answer from "./components/answer/Answer";
import PR2LearningPath from "./components/inner_cont/innerCont";
import Quiz from "./components/quiz/quiz";
import {Navigate, Outlet} from "react-router-dom";
import {NavigateHook} from "./helpers/NavigateHook";
import SideMenu from "./components/side_menu/sideMenu";
import Footer from "./components/footer/footer";
import NavTabs from "./components/nav/nav";
import LeftDrawer from "./components/nav/side_nav";
import RightDrawer from "./components/nav/lang_nav";
import {UpwardArrow} from "./components/icons/svgIcons";
import {ArrowUpward} from "@mui/icons-material";

function isMobileBrowser() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

if (isMobileBrowser()) {
    console.log("Mobile browser detected.");
} else {
    console.log("Not a mobile browser.");
}

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {}

        window.language = this.getLanguage(window.location.href);
        window.languageObject = require(`./languages/${window.language.toLowerCase()}.js`).default;
        window.getContent = this.getContent;
    }

    onScroll = e => {
        if (this.btn_ref) {
            this.btn_ref.style.display = this.ref.scrollTop > 30 ? 'flex' : 'none';
        }
    }

    componentDidMount() {
        this.ref.addEventListener('scroll', this.onScroll)
    }

    componentWillUnmount() {
        this.ref.addEventListener('scroll', this.onScroll)
    }

    getLanguage = (qs) => {

        let language = 'en';

        Object.keys(languages).forEach(key => {
            if (qs.includes('/' + languages[key])) {
                language = languages[key];
            }
        })

        return language;
    }

    getContent = (id) => {
        return window.languageObject[id] ?? '';
    }

    setRef = (ref) => {
        this.ref = ref;

        window.scrollableEl = this.ref;
    }
    setBtnRef = (ref) => {
        this.btn_ref = ref;
    }

    render() {
        return (
            <div className={`App ${isMobileBrowser() ? "mobile" : "desktop"}`}>
                <NavigateHook/>
                <nav className={'nav'}>
                    <div className={'side-nav-anchor'}>
                        <LeftDrawer/>
                    </div>
                    <div className={'img_container'}>
                        <img src="https://nwow4net-zero.eu/images/NWOW4NetZero/Logo-NWoW4NetZero.png" alt="nwow4netzero"
                             className="astroid-logo-default"/>
                    </div>
                    <div className={'side-nav-anchor'}>
                        <RightDrawer/>
                    </div>
                    <NavTabs/>
                </nav>
                <div className={'content-wrapper'} ref={this.setRef}>
                    <div className={'inner-wrapper'}>
                        <SideMenu/>
                        <Outlet/>
                        <ButtonMui onClick={() => {
                            if (window.scrollableEl) {
                                window.scrollableEl.scrollTo({top: 0, behavior: 'smooth'})
                            } else {
                                window.scrollTo({top: 0, behavior: 'smooth'})
                            }
                        }} innerRef={this.setBtnRef} isFloatingButton label={<ArrowUpward/>} style={{
                            width: '40px',
                            height: '40px',
                            position: 'fixed',
                            right: '20px',
                            bottom: '30px',
                            zIndex: 99,
                            display: 'none'
                        }}/>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}


export default App;
