import React, {Component} from 'react';
import './role_chooser.scss';
import {newPallette, roles, rolesdescription} from "../quiz/quizHelper";
import {Button, createTheme, ThemeProvider} from "@mui/material";
import {CircleIcon} from "../icons/svgIcons";


class RoleChooser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRole: this.props.role
        }

        this.selectedRoles = [];

        this.theme = createTheme({palette: newPallette});

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.role != this.props.role) {
            this.state.selectedRole = nextProps.role;
        }

        return true
    }

    handleClick = (selectedRole) => {
        this.setState({selectedRole})
        this.props.changeRole(roles[selectedRole]);
    }

    render() {
        return (
            <div className={'role_chooser'}>
                <div className={'r_c_text'}>{window.getContent('r_c_text_1')} <span
                    style={{textTransform: 'uppercase', fontWeight: 800}}>{rolesdescription()[this.props.role]}</span>
                </div>
                <br/>
                <div className={'r_c_text'}>{window.getContent('r_c_text_2')}</div>
                <br/>
                <div className={'r_c_text'}>{window.getContent('r_c_text_3')}</div>
                <div className={'r_c_text'}>{window.getContent('r_c_text_4')}</div>
                <br/>

                <div className={'roles_container'}>
                    {Object.keys(rolesdescription()).map(key => {
                        return <ThemeProvider theme={this.theme}>
                            <Button
                                onClick={() => this.handleClick(key)}
                                style={{
                                    borderRadius: '16px',
                                    textTransform: 'unset',
                                    justifyContent: 'space-evenly',
                                    fontSize: '17px'
                                }} color={key} variant={this.state.selectedRole == key ? 'contained' : 'outlined'}
                                startIcon={<CircleIcon/>}
                                className={`role ${roles[key]}`}>{rolesdescription()[key]}</Button></ThemeProvider>
                    })}
                    {/*<ThemeProvider theme={this.theme}> <Button onClick={this.handleAll} color={'grey'}*/}
                    {/*                                           variant={this.selectedRoles.length == Object.keys(rolesdescription()).length ? 'contained' : 'outlined'}*/}
                    {/*                                           startIcon={<CircleIcon/>} className={'role'}>All</Button></ThemeProvider>*/}
                </div>
            </div>
        );
    }
}

export default RoleChooser;
