import {roles} from "../components/quiz/quizHelper";

export default {
    "a1": "Да",
    "a2": "Не",
    "a3": "Не сум сигурен",
    "a4": "Да дефинитивно",
    "a5": "До одреден степен",
    "a6": "Забележително (во значителна мера)",
    "a7": "Донекаде (до одреден степен)",
    "a8": "Не многу",
    "a9": "Воопште не",
    "a10": "Раководител на HR или директор на одделот за човечки ресурси",
    "a11": "Член на тимот за човечки ресурси (специјалист за човечки ресурси или партнер за човечки ресурси)",
    "a12": "Асистент за човечки ресурси",
    "a13": "Консултант за човечки ресурси",
    "a14": "Практикант за човечки ресурси",
    "a15": "Микро",
    "a16": "Мала",
    "a17": "Средна",
    "a18": "Голема",
    "a19": "Јавна организација",
    "a20": "Приватна организација",
    "a21": "НВО (невладина организација)",
    "a22": "Градско подрачје",
    "a23": "Урбана област/центар на градот",
    "a24": "Приградски",
    "a25": "Рурален",
    "a26": "Не сум сигурен за што се работи => направи тест (Анекс 4)",
    "a27": "1-5",
    "a28": "6-10",
    "a29": "11-20",
    "a30": "21-50",
    "a31": ">50",
    "q2.2": " Кое е вашето функционално ниво во HR?",
    "q2.3": "Дали сте член на Извршниот одбор во вашата организација?",
    "q2.4": "Дали сметате дека имате стратешка улога за човечки ресурси во вашата компанија (организација)?",
    "q2.5": "Во вашата улога на човечки ресурси, до кој степен би рекле дека придонесувате за деловните стратегии (стратешко планирање во организацијата)?",
    "q2.6": "Во вашата улога на човечки ресурси, колку од вашата работа е поврзана со администрацијата на персоналот (водење евиденција, договори, усогласеност)?",
    "q2.7": "Во вашата улога на човечки ресурси, колку од вашата работа е поврзана со администрацијата на плати и бенефиции и други рутински активности за човечки ресурси?",
    "q2.8": "До кој степен вашата работа се фокусира на обезбедување операции за човечки ресурси како што се регрутирање, обука, управување со перформанси итн?",
    "q2.9": "Во вашата улога на човечки ресурси, до кој степен би рекле дека делувате како мост помеѓу организацијата и нејзините вработени (т.е. ги слушате вашите вработени и ги поддржувате)?",
    "q2.10": "Во вашата улога на човечки ресурси, до кој степен се фокусирате на создавање позитивна работна средина, промовирање на ангажираноста и задоволството на вработените?",
    "q2.11": "До кој степен би рекле дека имате простор да влијаете на културата на вашата организација (како што е промовирање на инклузивност, правичност и почит)?",
    "q2.12": "Во вашата улога на човечки ресурси, до кој степен вашата секојдневна работа се состои од промовирање иницијативи за промени и помагање на вработените и тимовите да се движат низ транзициите?",
    "q2.13": "Во вашата улога на човечки ресурси, до кој степен вашата секојдневна работа се состои од олеснување и управување со организациските промени како што се поттикнување на културна трансформација, организациско реструктуирање итн?",
    "q2.14": "До кој степен вашата секојдневна работа се состои од поттикнување на размислување подготвено за промени кај вработените и менаџерите?",
    "q1.1": "Која е големината на вашата организација?",
    "q1.3": "Дали е јавна, приватна организација или НВО (невладина организација)?",
    "q1.4": "Каде се наоѓа вашата компанија?",
    "q1.5": "Дали имате политика за одржливост во вашата организација?",
    "q2.1": "Колку луѓе работат во HR во вашата организација?",
    "st_3_th1": "NWoW/Хибрид",
    "st_3_th2": "Мобилност",
    "st_3_th3": "ИТ Одржливост",
    "st_3_tc0_0": "Засегнати страни",
    "st_3_tc0_1": "Вклучени внатрешни или надворешни чинители и нивната улога во придонесот за намалување на емисиите.",
    "st_3_tc1_0": "Култура",
    "st_3_tc1_1": "Создавање одржлива организациска култура во соработка со вработените.",
    "st_3_tc2_0": "Алатки",
    "st_3_tc2_1": "Конкретни алатки за поддршка на  намалувањето на емисиите.",
    "st_3_tc3_0": "HR процеси",
    "st_3_tc3_1": "Процеси во управувањето со човечките ресурси кои придонесуваат кон намалување на емисиите.",
    "st_3_tc4_0": "Инспиративни иницијативи",
    "st_3_tc4_1": "Активности или практики кои веќе се практикуваат од претпријатијата.",
    "st_3_tc5_0": "Препораки",
    "st_3_tc5_1": "Водилки кои помагаат на менаџментот за човечки ресурси да постигне конзистентност во поставувањето на целите.",
    "selectValueOption": "-- Изберете вредност --",
    "noInformation": "Врз основа на вашиот избор, нема информации во овој дел",
    "hybridStakeHolders": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "descirpiton": `<div>
<p>Директорот/менаџерот за човечки ресурси е само делумно одговорен за прашањата поврзани со новите начини на работа иако е одговорен за управување со човечки ресурси и организацијата на работа.</p>
<p>Со цел да придонесе за намалување на емисиите на јаглерод на компанијата, директорот/менаџерот за човечки ресурси треба тесно да соработува со различни засегнати страни, кои се идентификувани како:</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2 dir="ltr">Извршен директор / Врвен менаџмент</h2>

<h3>Дефинирајте визија</h3>

<p>Извршните директори мора да ја препознаат водечката улога што лично треба да ја преземат за да ја вградат одржливоста подлабоко и посеопфатно во нивните организации. Извршниот директор и управниот одбор треба да дефинираат визија, да воспостават јасна линија на етика и вредности во однос на одржливоста и да изразат амбиции за одржливост и за намалување на емисиите на јаглерод. Во таа насока каква обврска, треба да превземе компанијата?</p>

<p>Одговорот ќе им помогне на менаџерите и вработените да ја поврзат одржливоста со мисијата на компанијата, а потоа и со нивните секојдневни работни обврски.</p>

<h3>Примерност / Разговор со вработените</h3>

<p>Истражувањата покажуваат дека примерноста е критична и има силно влијание врз прифаќањето на промената од страна на вработените. Од суштинско значење е да се разговара во вработените и да им се обезбедат јасни примери за тоа што се очекува од нив и дека тоа ќе се вреднува. Примерноста може да биде практикувана на различни нивоа по работни места, и од различни вработени.</p>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/image-do44gt28.png",
        },
        // {
        //     "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
        //     "heading": "Оддржување - Објекти на компанијата",
        //     "description": "Комбинација на одговорности\n" +
        //         "Не случајно сега гледаме сè повеќе директори/менаџери  за човечки ресурси кои исто така се задолжени за канцелариите или другите објекти на компанијата. Вклучени се со цел да се добие мислење за интегрирање на стапките на искористеност, димензионирање и оптимизација на просторите, адаптација на апаратите за греење итн.\n" +
        //         "\n" +
        //         "Блиска соработка\n" +
        //         "Ако вработените во објектите се во две посебни одделенија, тогаш е важно да се воспостави тесна соработка. Во објектите може да се помогне да се намали потрошувачката на енергија преку инвестиции во високоефикасни инсталации, со алтернативни извори на енергија со ниско загадување, како што се фотоволтаичните панели.\n" +
        //         "\n" +
        //         "Може да се управува со греењето според луѓето на локацијата и/или да се комбинира со прегрупирање на вработените на локацијата или на друг кат/оддел/блиската локација каде има помалку луѓе,вработени.\n" +
        //         "\n" +
        //         "Предвидување на потреби\n" +
        //         "Проучете ги потребите за недвижнини за во иднина, да ги имате на ум за потребите на вработените како и за потребите на компанијата: вторите може да се охрабрат да ги здружат дисперзираните локации на поцентрално место, што природно  ќе влијае и на подолго  патување на вработените. \n" +
        //         "\n" +
        //         "Во предвид треба да биде земен и урбаниот нанос, и овие практики ќе треба да се анализираат од аспект на нивното влијание врз квалитетот на воздухот, реалните варијации во движењето и генерално движењето во слободното време. Важно е да се анализираат практиките за користење на други места (што може да го намали патувањето до работа но да се го зголеми недвижниот имот) и проширувањата на домувањето: работата на далечина може да доведе до потреба од купување на поголеми живеалишта за вработените за да имаат дедицирано место за работа од дома."
        // },
        // {
        //     "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
        //     "heading": "Комуникација",
        //     "description": "Стратегија за комуникација \n" +
        //         "За да се промени навистина однесувањето поврзано со организацијата на работата, важно е компаниите да воспостават комуникациска стратегија и да се применуваат активности на редовна основа. Во таа насока, мора постојано и да се пренесува пораката до вработените.\n"
        // },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Менаџер / Тим за одржливост</h2>

<p>Менаџерот или тимот за одржливост се клучни актери кои треба да се вклучат во проекти за новите начини на работа со цел намалување на емисиите на јаглерод.</p>

<p>Споделувањето на искуството е од клучно значење и компанијата може да иницира или да учествува во мрежа на менаџери за одржливост во секторот, индустриската гранка или во опкружувањето во областа, со цел споделување знаења и добри практики.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Синдикати</h2>

<p>Ако воспоставувате проект за новите начини на работа или хибридна работа по директива одозгора надолу, без да ги вклучите синдикатите, без да ги вклучите вработените преку нивни амбасадори, и без претходно да ги тестирате работите на терен, нема да функционира. Исто така, мора да бидете подготвени да се вратите наназад кога ќе направите грешка и да прифатите да ги ревидирате вашите планови.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": '',
            "description": `<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Оддржување - Објекти на компанијата</h2>

<h3>Комбинација на одговорности</h3>

<p>Не случајно сега гледаме сè повеќе директори/менаџери за човечки ресурси кои исто така се задолжени за канцелариите или другите објекти на компанијата. Вклучени се со цел да се добие мислење за интегрирање на стапките на искористеност, димензионирање и оптимизација на просторите, адаптација на апаратите за греење итн.</p>

<h3>Блиска соработка</h3>

<p>Ако вработените во објектите се во две посебни одделенија, тогаш е важно да се воспостави тесна соработка. Во објектите може да се помогне да се намали потрошувачката на енергија преку инвестиции во високоефикасни инсталации, со алтернативни извори на енергија со ниско загадување, како што се фотоволтаичните панели.</p>

<p>Може да се управува со греењето според луѓето на локацијата и/или да се комбинира со прегрупирање на вработените на локацијата или на друг кат/оддел/блиската локација каде има помалку луѓе,вработени.</p>

<h3>Предвидување на потреби</h3>

<p>Проучете ги потребите за недвижнини за во иднина, да ги имате на ум за потребите на вработените како и за потребите на компанијата: вторите може да се охрабрат да ги здружат дисперзираните локации на поцентрално место, што природно ќе влијае и на подолго патување на вработените.</p>

<p>Во предвид треба да биде земен и урбаниот нанос, и овие практики ќе треба да се анализираат од аспект на нивното влијание врз квалитетот на воздухот, реалните варијации во движењето и генерално движењето во слободното време. Важно е да се анализираат практиките за користење на други места (што може да го намали патувањето до работа но да се го зголеми недвижниот имот) и проширувањата на домувањето: работата на далечина може да доведе до потреба од купување на поголеми живеалишта за вработените за да имаат дедицирано место за работа од дома.</p>
</div>`,
        },

        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<p dir="ltr">За да се промени навистина однесувањето поврзано со организацијата на работата, важно е компаниите да воспостават комуникациска стратегија и да се применуваат активности на редовна основа. Во таа насока, мора постојано да се пренесува пораката до вработените.</p>`,
        }
    ],
    "mobilityStakeHolders": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<p>Директорот/менаџерот за човечки ресурси е само делумно одговорен за прашањата поврзани со новите начини на работа иако е одговорен за управување со човечки ресурси и организација на работа.</p>
<p>Со цел да придонесе за намалување на емисиите на јаглерод на компанијата, директорот/менаџерот за човечки ресурси треба тесно да соработува со различни засегнати страни, кои се идентификувани како:</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing has-scrolled" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Извршен директор / Топ менаџмент</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Локација на бизнисот</h3>

<p>Деловната локација е дефинирана како место или структура окупирана од компанијата за работа. Кога ќе го погледнеме образложението зад изборот на локација за компаниите, еден критериум не е занемарлив и генерално се појавува во првите пет: Локацијата на вработените и тие кои компанијата планира да ги вработи. Ова е размислување кое треба да се поддржи, но е различно од перспективата за човечки ресурси. Кога вашата организација има планови за преместување, истражете ја одлуката „останете или одете“, и размислете за спојување на повеќе канцеларии или отворете дисперзирани канцеларии, при тоа е важно е да се анализираат поврзаните влијанија и можности за патување. Изберете локација во близина на железничка/метро станица и пристап до друг јавен превоз</p>

<h3>Позитивен пристап</h3>

<p>Една од клучните точки на која треба да се работи е развивање на свест кај врвниот менаџмент и компанијата за тоа што може да се добие со приближување на искуството на вработените од патувањето од дома наутро до дома навечер, и навистина да ја земе мобилноста како составен дел од работната активност. Штом ќе се случи ова, полесно ќе се разбере кои се придобиквките за компанијата да инвестира време на ова, зошто треба да вложи енергија и ресурси во истото и зошто е потребно да преземе одредени мерки за истото. Би било корисно да се оддалечиме од административните ограничувања поврзани со обврската за изготвување на план за мобилност, а наместо тоа да се заземе позитивен пристап за изнаоѓање ефикасни решенија.</p>

<h3>Можности и... ограничувања</h3>

<p>Поддршката на процесот за помалку јаглеродно-интензивна мобилност никогаш не е основна дејност на компанијата - и нема да биде таква. Флексибилноста во однос на ограничувањата што може да се наметнат, не е толку голема. Затоа, првиот чекор е да се утврди нивото на ограничувања што компанијата е подготвена да ги наметне.</p>

<h3>Разговор со вработените</h3>

<p>Доколку се преземат иницијативи во корист на воспоставување јаглеродно поволна мобилност, од суштинско значење е ваквата активност да се користи и да се промовира од стррана на врвниот менаџмент како пример за вработените. Во рамките на плановите, преку нашата студија евидентно е дека лкучот до успех е ако водечката фигура во компанијата го промовира овој план.</p>
</div></div></div>`,
            "imageSource": "https://nwow4net-zero.eu/images/t2/image-Yp7OiIAH.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Финансии</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Сумата на пари што компаниите се подготвени да ги потрошат за прашања поврзани со мобилноста на вработените на стратешко ниво, обично е мала во споредба со тоа што тие можат да го потрошат за даночна оптимизација или за провајдери на услуги поврзани со човечки ресурси. Трошоците за активностите за подигање на свеста, се оценети помеѓу осум до десет пати помалку. Невозможно е со толку ниски амбиции навистина да се променат работите. За жал, можеме да видиме дека работата во врска со мобилноста сè уште се доживува како ограничување од страна на компаниите, а не како реална можност за промена на нивното влијание врз животната средина. Сепак, повратот на инвестицијата на проектите за мобилност може да биде директен и многу значаен.</p>

<p>Секторот за управување со човечките ресурси може да направи напредок во оваа област преку развивање на деловен случај со конкретни податоци.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Комуникација</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Комуникациска стратегија</h3>

<p>За навистина да се промени однесувањето поврзано со мобилноста, важно е компаниите да воспостават комуникациска стратегија со редовни активности. Во таа насока, мора постојано да се пренесува пораката до вработените. На пример, воведување на игри со награди за победниците е одлична можност за остварување на целта. Создавањето лого и/или брендирање за планот за мобилност на работното место може да помогне да се привлече и задржи вниманието во компанијата</p>

<ul>
\t<li>
\t<p>Во SWIFT во Белгија, беше организиран „добротворен предизвик“ за да се поттикне возењето велосипед за време на неделите на мобилност: за секој поминат километар се донираше 1 евро на здруженија поврзани со велосипедизмот. Овој систем беше многу успешен кај вработените, бидејќи комбинираше надворешна и внатрешна мотивација.</p>
\t</li>
</ul>

<ul>
\t<li>
\t<p>Во Service Public de Wallonie во Белгија, беше организирана „велосипедска тура околу светот“: од учесниците беше побарано да ги внесат своите велосипедски патувања поврзани со работата на онлајн формулар како дел од колективниот предизвик да извозат 40.075 километри заедно во текот на 26 недели. Колективно се” достигнуваа дестинациите, со анегдоти, пофалби и диплома на крајот. Динамиката беше користена за постигнувања преку нивоа и беџови, додека се играше за бегство, непредвидливост и креативност на учесниците.</p>
\t</li>
</ul>

<p><em><strong>Извор: UWE Mobility Unit</strong></em></p>

<h3>Фокусирајте се на вистинската работа</h3>

<p>На мобилноста не треба да се пристапува директно од еколошки агол. Еколошката димензија ќе биде позитивниот резултат, целта кон која треба да се стремиме. Но, тоа не е алатка за комуникација: луѓето се менуваат не поради еколошки причини, туку од причини на практичност, ефикасност, поради заедницата... Накратко, од причини поврзани со човечкото однесување.</p>

<p>Вработените нема да го променат своето однесување затоа што така ќе влијаат врз намалување на јаглеродните емисии на компанијата, туку затоа што ќе имаат нови можности за да бидат повеќе со семејството, да го направат своето патување до работа попријатно... и, на крајот, да имаат позитивно влијание на планетата.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Менаџер за мобилност</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Назначете менаџер за мобилност</h3>

<p>Менаџерот за мобилност е внатрешно лице кое го води проектот за мобилност и, идеално би било да поднесува извештај до генералниот менаџмент, бидејќи раководењето му е трансекторско прек секторите за комуникација, човечки ресурси, капацитети, итн. Лицето може да дојде од кој било сектор - и, зошто да не, од секторот за човечки ресурси - но мора да има директен контакт со највисокото раководство и со претставници на вработените. Ова лице потоа треба да биде обучено во врска со предизвиците на мобилноста до работното место.</p>

<h3>Не е доволен само менаџерот за мобилност..</h3>

<p>Мобилноста е израз на индивидуалната слобода надвор од компанијата и од какви било ограничувања поврзани со работата. На пример, од лична перспектива а во врска со слободата на избор, нормативната улога која што ја има автомобилот останува се уште многу важна.. Затоа, треба да се влијае и образува. Секторот за управување со човечки ресурси, може да влијае на патувањата поврзани со работата (на пример во транспортниот сектор или ако компанијата вработува вработени кои често патуваат), но ќе биде потешко да влијае на патувањето од дома до работа, што делумно ја допира приватната сфера. Тогаш тие се многу повеќе зависни од добрата волја на луѓето или од она што луѓето се подготвени да го направат, свесно или несвесно.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Менаџер со податоци</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>За да станат актери на промени компаниите мора да инвестираат во ресурси - финансиски, човечки и во време - за да спроведат темелно истражување на нивните сегашни практики поврзани со мобилноста и како индивидуалните варијабли (возраст, пол, работна позиција, рутата на патување, итн.) влијаат на нивниот претпочитан начин на транспорт. Собирањето информации преку анкети, интервјуа со вработени, добавувачи и клиенти и други алатки се од клучно значење за создавање соодветен одговор. Компаниите веќе имаат огромно количество податоци и, доколку сакаат, често ги имаат при рака потребните елементи за да напредуваат на едноставен и евтин начин.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Синдикати</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Ако воспоставувате проект за новите начини на работа или хибридна работа по директива одозгора надолу, без да ги вклучите синдикатите, вработените преку преку нивни амбасадори, и без претходно да ги тестирате работите на терен, нема да функционира. Мобилноста е една од најчувствителните теми во компаниите. И штом ќе се направи штета, исклучително е тешко враќањето назад во позитивна динамика. Во таа насока, мора да се биде подготвен да се врати назад штом се направи грешка и да се ревидираат плановите.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Јавни институции</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>При изготвување успешен план за мобилност без јаглеродни емисии, од суштинско значење е да се добие потврда од локалните и официјалните институции. Локалното планирање е од клучно значење. И обично, политиките се главниот мотор за промена: 60% од корпоративните промени во планирањето на мобилноста се мотивирани од желбата да се усогласи компанијата со националните, регионалните или локалните регулативи и барања.</p>

<p>Развојот на работа од далечина го регулира работодавачот, но исто така треба да се интегрира во перспективите на јавните политики. Ако се дозволи работата од далечина да се развива без да се специфицираат целите за истата од аспект на јавната политика, мали се шансите тие да се постигнат..<br>
</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Јавни транспортни превозници</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Ако менаџерите за човечки ресурси и мобилност од различни компании, дури и тие кои доаѓаат од различни сектори, работат заедно за да направат транспортен центар од област од 500 до 800 метри - железничка станица, метро станица итн, тие ке постават динамика, ќе ги поттикнат јавните власти, јавните транспортни превозници и службите за мобилност. Притоа можат да кажат:</p>

<p>„Ние , да речеме, 30 компании идентификувавме таква и таква потреба; што можеме да направиме во врска со тоа“. Ако отиде само една компанија, нема да има влијание. Со 30 компании ситуацијата се менува. Потребна е оваа соработка за да имате влијание.</p>
</div></div></div>`
        }

    ],
    "sustainableStakeHolders": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<p>Директорот/Менаџерот за човечки ресурси не е директно одговорен за внатрешните информациски и телекомуникациски системи во рамките на компанијата. Но, тој/таа може да ја подигне свеста на персоналот за прашањата за одржливост поврзани со користењето на дигиталните технологии и да влијае на нивната оддржлива употреба.</p>
<p>За да придонесе кон намалување на јаглеродните емисии во претпријатието, директорот/менаџерот за човечки ресурси треба тесно да соработува со различни засегнати страни (чинители), од кои главните се идентификувани како:</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div>
<p><strong><span style="font-size:1.5em;">Извршен директор/Топ менаџмент</span></strong></p>
<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Дефинирање на визија</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Пожелно е извршните директори да ја препознаат водечката улога во вградување на одржливоста во нивните организации.</h3>

<p>Извршниот директор и Извршниот комитет треба да дефинираат визија, да воспостават јасна линија на етика и вредности во однос на одржливоста и да изразат амбиции за одржливост и намалување на штетните емисии во животната средина. Тие се тие кои дефинираат кои се обврските и насоките на организациите во однос на одговорното користење на информациските и телекомуникациските технологии.</p>

<p>Ова ќе им помогне на менаџерите и вработените да ја направат врската помеѓу одржливоста и мисијата на организацијата, и истата да ја вградат во секојдневната работа.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Управување</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Интегрирање на одговорни дигитални критериуми</h3>

<p>Интегрирањето на одговорни дигитални критериуми во севкупното управување на организацијата и во секој проект поврзан со информатилките технологии, претставува значителен предизвик. Ова не значи дека одредени проекти нема да се спроведат, туку дека треба да се постават вистинските прашања за да се подобри пристапот - на пример, да не се инвестира во развоја на одредени малку корисни дигитални решенија кои неефикасно би ги трошеле ресурсите.</p>

<p>Овој пристап се однесува на инвестициите во информатичките технологии, но може да се прошири и на сите инвестиции и решенија на работа во другите одделенија на организациите, вклучително и во секторот за управување со човечките ресурси: сè повеќе организации денес го поврзуваат јаглеродниот буџет со различни проекти на организациите.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Давање на пример / Држете до зборот</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Истражувањата покажуваат дека примерот е клучен и има силно влијание врз прифаќањето на промените од страна на вработените. Од суштинско значење е врвниот менаџмент да „продолжи да зборува“ и да им обезбеди на вработените јасни примери за тоа што се очекува и вреднува. Давањето пример може, и пожелно е, да се воведе на различни нивоа во организацијата. </p>
</div></div></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/image-ns6btF0w.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-929571f3-be1a-4ea3-9a15-a42c546e1f03"><p><strong><span style="font-size:1.5em;">Главен директор за информатички технологии (CIO) / Директор за ИТ</span></strong></p>

<p>Директорот за информатички технологии и неговиот оддел се организациски чинители задолжени да ја осмислат ИТ стратегијата на компанијата и да ги обезбедат сите системи неопходни за поддршка при реализација на акционите планови и цели.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e92dda56-c129-4b24-b8c1-a4b82751c7d8"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-65a10d763bf1e.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-46916a4a-0b71-4b36-b198-90c8bdb5325f">

<p>Во сегашната состојба на знаење и моменталната практика, решенијата засновани на информациските технологии не се „одржливи“ . Но, тие можат да биде „одговорни“ , во смисла на ограничување на нивното влијание врз животната средина. Одговорното дигитално однесување не е потребно само за „ Зелена ИТ “ - намалување на еколошкиот отпечаток на дигиталната технологија - или „ ИТ за зелено “ - користење на дигитална технологија за намалување на еколошкиот отпечаток во други области. Постојат и две други оски: „ Човечка ИТ “ и „ ИТ за човек “. Овие оски прашуваат како да се намалат негативните општествени влијанија на дигиталната технологија (на пример, прашањата поврзани со пристапност до дигитаните решенија за лицата со попреченост) и како информациските технологии да се стави во служба на луѓето.</p>

<p>Затоа, важно е да бидете сигурни дека работите со ИТ секторот или задолжените луѓе за ИТ, на воведување на одговорно ИТ однесување. Во спротивно, ризикувате да давате препораки кои ИТ нема да ги поддржи, понекогаш едноставно затоа што тие можеби и не знаат за овие трендови. Важно е да се има усогласен и конзистентен пристап.</p>

<p>Пример— Експерт објаснува како работел со голема мултинационална компанија на зелен ИТ проект. Во таа компанија, ИТ решенијата придонесувале за 2% од нивниот јаглероден отпечаток. Но, со оглед на големината на организацијата, ова влијание било значајно. Извршниот комитет одлучил дека сите сектори на организацијата, без разлика на нивната големина и учество во генерирањето на штетинте гасови и придонес во јаглеродниот отпечаток, треба да најдат начини да го преполоват истиот. Од тој момент, корпоративната кохезија се канализираше во заедничка акција, во и меѓу тимовите. Сите се обидоа да разберат што се случува во другите одделенија и/или сектори, како да си помагаат и сл за да се реализираат целите.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-R5KB7XDY.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-26d6c56f-23dd-4489-9f7a-dbb45a9d4ded"><p><strong><span style="font-size:1.5em;">Набавка</span></strong></p>

<p>Одделот за ИТ е одговорен за техничките спецификации при набавка на ИТ решенија и опрема, фреквенцијата на замена на опремата, и нвната набавка.</p>

<p>Главното негативно влијание на ИТ од еколошка гледна точка доаѓа прво и основно од производствениот процес на опремата. Ова негативно влијание доаѓа многу пред центрите за податоци и мрежите. Вистинскиот проблем е брзината на замена на опремата: ние имаме тенденција да ја замениме нашата опрема премногу брзо.</p>

<p>На пример, паметен телефон во Белгија има животен век од 18 до 23 месеци, додека од електронски аспект може да издржи 7 или 8 години без никакви проблеми, па дури и подолго.</p>

<p>Затоа, важно е да се има конзистентна политика во однос на одржливо користење на опремата во рамките на организацијата со цел да се зголеми нејзиниот животен век.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-638dde93-1fe4-4d1b-87dc-7c7cd5340826"><p><strong><span style="font-size:1.5em;">Добавувачи на хардвер за ИТ и телеком</span></strong></p>

<p>Добавувачите на дигитални решенија и уреди се важни чинители бидејќи поседуваат информации и знаење за енергетската ефикасност на производите што ги развиваат и продаваат, животниот циклус на производот, зрелоста на производот и можностите за негово рециклирање.</p>

<p>Оттука одговорните за управувцање со човечките ресурси можат да ги вклучат добавувачите на ИТ опрема и решенија и во процесот на набавка да ги консултираат за потеклото на производите. Добавувачите, исто така, би можеле да ја пренесат својата експертиза преку обука на персоналот за придобивките од одржливата употреба и потрошувачката на енергија и од правилното отстранување на електронскиот отпад.</p>

<p>80% до 90% од еколошкиот отпечаток на уредот е веќе присутен кога првпат ќе се вклучи уредот. Особено, големо влијание има екстракцијата на минерали и загадувањето поврзано со нивното прочистување за да се добијат метали потребни за производство на дигитални уреди. За да се добие грам злато, треба да се извлече не помалку од еден тон камен! За другите метали е уште полошо од тоа. Се проценува дека се потребни повеќе од 200 килограми руда за да се направи паметен телефон од 120 грама. Ова производство ќе испушти околу 80 килограми CO2 еквивалент (мерна единица за емисиите на стакленички гасови).</p>

<p>Понатаму, капацитетот за рециклирање на овие метали е исклучително низок. Во паметниот телефон има околу 60 метали, од кои можеме да рециклираме само 17. Остатокот се фрла. Клучната порака е да купите помалку опрема, да се грижите за неа и да ја чувате што е можно подолго.</p>

<p>Ако веќе не користите некој предмет од опремата, не ставајте го во фиока, туку дајте му втор живот со тоа што ќе го донирате на здруженија, ќе го препродадете, ќе го обновите итн. Денес, е возможно за една организација да купи подобрена веќе користена опрема.</p>

<p>Директорите за човечки ресурси имаат улога во подигањето на свеста за добрите дигитални практики и поконкретно објаснувањето зошто е важно вработените да се грижат за дадената опрема.</p>


</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-JgxtRsaR.jpg"
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-ba79971c-3cf7-4525-ad9a-494e0afef16a"><p><strong><span style="font-size:1.5em;">Регулатори/ Регулаторни Агенции </span></strong></p>

<p>Регулаторите се важни чинители бидејќи развиваат барања поврзани со зелената ИТ - како што се стандардите за енергетска ефикасност, регулативите за отстранување на е-отпад и барањата за известување за јаглеродниот отпечаток. Овие регулативи стануваат многу строги во ЕУ и регулираат широк опсег на однесувања. Денес и во блиска иднина се очекува организациите да подлежат на зголемени регулативи во однос на дигиталната одговорност. Дигиталното поле треба да станува сè постандардизирано, како што беше потврдено од гласањето на Европскиот парламент во јули 2017 година за Резолуцијата за подолг век на траење на производите.</p>

<p>За директорите за човечки ресурси и нивните тимови, регулаторите се важен извор на информации и знаење за тоа како да се развијат организациски политики и стратегии кои покриваат одговорно користење на информациските технологии и дигиталната присебност и како истите да се вклучат во управувањето со човечките ресурси. Затоа, важно е да комуницирате и да соработувате со нив кога ќе се појават можности, да се сретнете со нив на обуки, работилници, информативни денови кои понекогаш ги организираат и самите регулатори и да разговарате со нив за можностите во овој дел кои доаѓаат од регулативата.</p>


</div>`
        }


    ],
    "hybridCulture": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d621e8b2-0c32-40ec-a524-408f805b4851"><p><strong><span style="font-size:1.5em;">Културата е клучна</span></strong></p>

<p>Дејствувањето во корист на намалување на емисиите на јаглерод нужно ја вклучува културната промена. Преку работата на организациската и менаџерската култура, како и на порационална употреба на мобилноста и ИТ алатките, на пример, навистина можеме да постигнеме промени во однесувањето и потрошувачката, што ќе направат попозитивен баланс за животната средина.</p>

<p>Проверете ја конзистентноста на политиката за животна средина на компанијата со нејзината севкупна култура. Како може да се направи ова? Со истражување на веќе постоечките практики, навики и бенефиции за да се провери дали тие се во согласност или се контрадикторни со стратегијата за животна средина.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-ec71dbde-552a-4919-89c6-8adb3e59180f"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-659b0e3914c5e.png" alt="" title="Culture is Key" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": 'https://nwow4net-zero.eu/images/t1/file-b6WaEZDM.png',
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4db97028-02a0-45df-b3dc-a2400e1f6992"><p><span style="font-size:1.5em;"><strong>Фокусирајте се на вистинската работа</strong></span></p>

<p>Работата на далечина не е суштински „подобра“ за околината. Постојат истражувања кои го покажуваат спротивното. Компанијата, на пример, нема да престане да ги загрева и осветлува работните простори, и покрај тоа што некои вработени работат на друго место, дома или во друг простор.</p>

<p>Според тоа, потрошувачката на енергија на компанијата може да остане иста. Но, потрошувачката на енергија во домовите на работниците последователно ќе биде поголема. Овде секако има сезонски ефект, кој може да варира од една до друга земја - иако греењето може да се замени со клима уред.</p>

<p>Дополнително, неколку студии покажуваат дека патувањето на вработените не е елиминирано и дека дури има тенденција да се патува повеќе, како што е извршување на мала задача или потреба, која е поврзана со пократко патување. Од таа причина, зборуваме за „неизвесна“ еко рамнотежа.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e93095af-95fc-4a5d-b59d-b465dbb8ebde"><p><span style="font-size:1.5em;"><strong>Зголемете ја свеста</strong></span></p>

<p>Подигнувањето на свеста и едукацијата за однесувањето на работното место засновано на научни докази е едно од главните премостувања во рамките на управувањето со човечките ресурси. Информирањето на вработените за еколошките трошоци на нивниото работно однесување е секогаш интересно. Уште подобро е кога подигнувањето на свеста, споделувањето на информации и обука за вработените се водени во рамките на стратегија, во согласност со активностите и/или со амбициите на компанијата со цел да се постигне јаглеродна неутралност.</p>

<p>Ова ќе озвозможи и зголемување на иницијативите.</p>

<p>Обично, целта на далечинско/хибридното работење во организацијата е постигнување благосостојба кај вработените. Ппомалку е за воспоставување одржливост. Информирајте ги и обучете ги вработените за влогот кои го има компанијата во насока на климатските промени и за влијанието кое нивната работа може да го има врз јаглеродните емисии. Зголемувањето на свеста за влијанието врз животната средина се воспоставува со постојана интеракција и дијалог.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a2f1c3ca-765a-48bc-9ae8-5bf7b0657d0b"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-659b0e6194c3e.png" alt="" title="Зголемете ја свеста" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-2brlApB0.png",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-56f5683d-ac8f-457a-aa74-6ceab16d8394"><p><strong><span style="font-size:1.5em;">Иницирајте партиципативен пристап</span></strong></p>

<p>Развијте партиципативен пристап кој ги вклучува вработените кои се чувствителни или активни во полето на одржливоста за да ги направите амбасадори кои ќе вклучат и други вработени преку:</p>

<ul dir="ltr">
\t<li>Собирање на идеи</li>
\t<li>Споделување на добри практики</li>
\t<li>Подготовка на предлози</li>
\t<li>итн.</li>
</ul>

<p>Неколку студии спроведени во западните земји во 2022-ра година покажаа дека повеќето вработени гледаат вредност во хибридната работа и дека флексибилноста е еден од најважните фактори при одлучувањето дали да прифатат таква понуда за работа. Нормата која постепено се појавува во големите компании е спроведување на три дневна работна недела.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d36a5e02-5941-4954-acb4-08a1432622b7"><p><strong><span style="font-size:1.5em;">Обрнете внимание на тензиите</span></strong></p>

<p>Треба да се идентификуваат тензиите што ќе се појават помеѓу спроведување на економските перформанси и општествените и еколошки цели на изведбата, во двете краткорочната логика која ги карактеризираат современите организации, и долгорочната логика во која одржливоста е засидрена.</p>
</div>`
        }

    ],
    "mobilityCulture": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-067d5e0e-e4fb-43be-84fa-825384498127"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-6509dd97d9c6f.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8dc96038-5902-4fae-b1bc-6ff31e4bd15f"><p><strong><span style="font-size:1.5em;">Влијание врз однесувањето</span></strong></p>

<p>Организациите не можат да влијаат на тоа каде вработените избираат да живеат, бидејќи тоа би било очигледно нарушување на нивната приватност. Тешко е да се балансира должината на патувањето до работа, дури и кога се земаат предвид параметрите како поевтино домување или подобар квалитет на живот. Работете со вработени кои ги поддржуваат целите за да ги сензибилизирате колегите.</p>

<p><strong>Пример:</strong></p>

<p>Wever е платформа создадена во Франција која предлага повторно да се измисли инклузивна и ефикасна мобилност со ставање на корисникот и неговите или нејзините изразени потреби во рамките на подготовката на понудата. На сите им нуди можност да ги наведат своите потреби, да тестираат нови решенија и да гласаат за спроведување на најинтересните идеи на долг рок .</p>
</div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9772c0de-3792-4fe2-a5f6-899984bb576a"><p><strong>Како работи?</strong></p>

<ol>
\t<li>Дијагностика за мобилност се користи за собирање на навиките за патување и потребите на вработените. Потребни се само неколку минути за да се соберат индивидуалните потреби и за секој од нив се направи извештај.</li>
\t<li>
\t<p>Контролната табла овозможува континуирано следење на извештаите и овозможува преку коментари да се видат препораките за дејствување. Оваа визуелна алатка овозможува понудата да биде ко-конструирана со корисниците.</p>
\t</li>
\t<li>
\t<p>Алгоритмот ги открива можностите за мобилност преку откривање на постоечките решенија и моделирање на нови прифатливи решенија.</p>
\t</li>
\t<li>
\t<p>Со индивидуализирана поддршка за промени и поставување на добра логика на награди, трансформациите се видливи за неколку недели.</p>
\t</li>
</ol>

<p>Повеќе информации:<a target="_blank" href="http://www.wever.fr/" target="_blank">www.wever.fr</a></p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-0lBHW18J.jpg"
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-065d48e1-ed65-4c87-803a-8063f0d2bb9b"><p><strong><span style="font-size:1.5em;">Направете нешто на конкретен начин</span></strong></p>

<p>Не правете кампањи за мобилност поврзана со работата со поставување на постери во лифтот, бидејќи со тоа не менувате никакво однесување. Одете во кафетеријата три пати неделно за да разговарате со луѓето, заинтересирајте се транспортот кој го користат кои се нивните предизвици, и предложете нашини како да се надминат. Накратко, персонализирајте го вашиот пристап за секоја личност. За ова е потребно време и енергија.</p>

<p>Друг пример: Создадете група за велосипедски тури и одржувајте велосипедски настани. Побарајте од колеги во групата да направат презентација и да зборуваат за темата на други компаниски настани или со своите колеги.<br>
</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6d757e35-654c-416e-a38f-5fbf7e44a845"><p><strong><span style="font-size:1.5em;">Обрнете внимание на паркирањето</span></strong></p>

<p>Паркирањето на автомобили е централно прашање за компанијата: компаниите развија мерки кои се движат од стимулации (финансиска награда за тоа што не се носи автомобил на работа), до казни (воведување на трошоци за паркирање на работа или максимален број денови/недела или месец за користење на службениот паркинг).</p>
</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8cb77e76-e0c3-439c-9f90-d582dd82222c"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650a0615ebf86.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-jONe6a2U.jpg"
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42f4fadb-eb58-4f8a-a545-94bbab8675ab"><p><span style="font-size:1.5em;"><strong>Иницирајте партиципативен пристап</strong></span></p>

<p>Создадете кластер за мобилност кој вклучува доброволци од вработените за да собираат идеи, предлози и да одберат внатрешни амбасадори. Некои организации ја делегираа улогата на управување со план за мобилност/патување на линиските менаџери, за да создадат сплотеност помеѓу вработените.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-a57aaa91-50f0-4dce-b73b-23410fda6402"><p><strong><span style="font-size:1.5em;">Промовирајте размислување надвор од рамката</span></strong></p>

<p>Охрабрете ги луѓето да размислуваат за причината за патување пред да го изберат начинот на патување</p>




</div>`
        }
    ],
    "sustainableCulture": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-056e0c6d-cc34-469f-8ced-3b7f996472c6"><p dir="ltr"><strong><span style="font-size:1.5em;">Подигнување на свеста</span></strong></p>

<p>Во зависност од изворот, дигиталната технологија сега сочинува од 3 до 4% од глобалните емисии на стакленички гасови. Во споредба со другите сектори, овој удел останува скромен, но годишниот раст на дигиталната потрошувачка треба да предизвика загриженост за организациите, предупредува Institut du Numérique Responsable (Франција/Белгија). Во услужните компании, на пример, првите три ставки кои генерираат најмногу јаглеродни емисии се патувања, згради и информациските технологии - по редослед што може да варира од една до друга организација. Информациските технологии понекогаш може да бидат главна ставка, без да знае организацијата затоа што нејзиното влијание не се мери. Мобилноста, на пример, е многу јасно поврзана во главите на луѓето со еколошки прашања, но нема таква перцепцијата поврзана со еколошкото влијание на информациските технологии.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-192ec88b-a197-4536-95ee-13ea3703a0e2"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b2348b9ee9.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-HLGgm3YH.jpg"
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-59071f32-37c3-4315-89a9-cd5beb84ac23"><p><strong><span style="font-size:1.5em;">Промовирање на разбирање</span></strong></p>

<p>Клучно е да научите да разликувате што е „суштина“, а што е „моден додаток“. Ако сакате да го намалите влијанието на дигиталната технологија врз животната средина, треба да знаете дека ограничувањето на бројот на пораки во е-пошта ќе има само маргинално влијание - иако ова е акција што често се промовира. Главното негативно влијание на дигиталната технологија на планетата доаѓа од опремата и нејзиното предвремено обновување. Не смееме да водиме погрешна битка.</p>

<p>Многу рано направете разлика помеѓу директните ефекти, индиректните ефекти и повратните ефекти, и затоа да ги вреднуваме позитивните активности кои навистина прават разлика.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-4abab26f-e818-4d12-b498-6b40280bffee"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b67a5efee2.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-19JBhPTu.jpeg"
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8fee0210-9975-43b3-b9a3-862916f098b6"><p><strong><span style="font-size:1.5em;">Поттикнување на дигиталната присебност</span></strong></p>

<p>Одговорното користење на ИКТ решенијата и опремата не значи дека ќе/треба да поттикнеме намалување на сите дигитални активности. Всушност, луѓето се повеќе се прашуваат како да го намалат негативното влијание врз животната средина од дигиталната употреба, бидејќи сè околу нив е дигитално. Целото општество стана дигитално. Без дигитализацијата тешко можеме да направиме било што.</p>

<p>Вистинскиот предизвик е да се отстрани сè што е непотребно. И, овде, HRМ, или менаџерите за управување со човечките ресурси можат да дадат некои вредни одговори.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-80a48b73-1d1c-416d-8cbd-ff20039a441d"><p dir="ltr"><strong><span style="font-size:1.5em;">Промовирање на одговорно користење на ИТ/Телеком технологиите</span></strong></p>

<p>Што се однесува до употребата на опремата, видеото категорично игра најнегативна улога. Дури 80% од пропусниот опсег на интернет се користи за видео - од кои само една четвртина е за видео конференции или видео повици, а останатите три четвртини се користат за стриминг платформи, како што се Netflix, YouTube, содржина за возрасни итн.</p>

<p>Исклучувањето на камерата за време на индивидуален повик прави мала разлика. Работите ескалираат за време на групна видео сесија. Кризата СОВИД-19 беше силен сведок на оваа промена. Интензивната употреба на видеото повлекуваше драстична промена во распределбата на пропусниот опсег и опремата на центарот за податоци - токму тука лежи вистинското влијание на работата од далечина.</p>


</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-c9087ca2-6557-4911-9f64-03a6d9a035df"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b6812dd3f5.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-16ec8bc4-3636-4635-b576-83bb425e0be5"><p>Последиците од пандемијата беа двојни: од една страна, цела низа компании кои не беа опремени за да овозможат работа на далечина мораа да се преопремуваат - купувајќи дополнителни лаптопи, екрани итн. Од друга страна, сите луѓе кои не можеа да работат од далечина и беа заглавени дома за време на заклучувањето, завршија со гледање Нетфликс, и значителни влијанија на пропусниот опсег. Мрежните провајдери додадоа повеќе хардвер за да го зголемат нивниот капацитет, предизвикувајќи значително влијание во однос на еколошкиот отпечаток.</p>

<p>Треба да се запомни дека одговорното користење на технологијата е многу маргинална димензија, во споредба со влијанието на екстремно брзото обновување на опремата и работата на центрите за податоци.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-re4wePLp.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0cddd6a4-52aa-42a3-8484-5539737f8a1d"><p><span style="font-size:1.5em;"><strong>Двојна проверка за кохерентност</strong></span></p>

<p>Денес сите заземаат став за одржливиот развој. Меѓутоа, реалноста е друга. Некои организации навистина сакаат да го променат својот бизнис модел - целосно свесни дека истиот може да биде предуслов да останат на одредени пазари на краток или среден рок. Други прават прилично ефикасно зелено перење (green washing).</p>

<p>Трета категорија се компании каде што еден дел од организацијата навистина сака да ја промени играта, додека, во исто време, другиот дел прави масивни реклами велејќи дека „метаверзумот е иднината на човештвото“. Ова не се разликува од ситуацијата во која еден ден шефот ќе ви каже колку е важно да бидете енергетски ефикасни, а следниот ден лета до град кој е оддалечен помалку од четири часа со брз воз. Имаме работа со длабоко човечки вистини, составени од противречности, големо лицемерие, кукавичлук и малку вистинска желба за дејствување.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-7192ddeb-d87d-4c96-8d91-5f579d91095c"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b68e420112.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-cd099baa-8a59-455f-aa3c-1529a2f405a5"><p dir="ltr">Третата категорија на организации испраќаат загрижувачки измешани пораки. Тие се рекламираат за одржливи практики, но сепак масовно инвестираат во нови, енергетски алчни технологии како што е метаверзумот. Ова би било еквивалент на менаџер кој се залага за позелени практики на мобилност додека резервира авион за патување на кратко растојание. Нето нултата динамика ја открива длабока поделеност на човечка природа, помеѓу создавање фасади од една страна и поттикнување вистинска желба за дејствување од друга.</p>

<p>Директорот/одделот за управувањето со човечките ресурси има улога да биде диригент на сите одделенија во организацијата. Да го земеме примерот за обука: која е поентата на обуката за дигитална одговорност и присебност ако, во исто време, предложите голема програма за обука за метаверзумот за техничари?</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-h1GH98Y5.jpg"
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5c6fe4df-bd40-485d-9629-7de6aeb00952"><p><span style="font-size:1.5em;"><strong>Промовирање на креативното размислување</strong></span></p>

<p>Охрабрете ги луѓето да размислуваат за начините на кои можат да ги намалат јаглеродните емисии преку одговорна употреба на ИКТ.</p>


</div>

<div class="h5p-image-hotspot-popup-content overflowing has-scrolled" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Предизвици за дигитална детоксикација</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">Организирајте предизвици за дигитална детоксикација за вработените, охрабрувајќи ги да се одморат од технологијата одреден временски период, како што е еден ден или една недела. Ова може да се направи како индивидуален предизвик или како активност за градење тим.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Паузи без екран</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">Можете да ги охрабрите вработените да прават паузи без екран во текот на денот, обезбедувајќи можности за физички вежби, практики за внимателност или други недигитални активности.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Политика за состаноци без дигитална технологија</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Можете да воспоставите политика што ја обесхрабрува употребата на дигитални уреди за време на состаноците.</p>
</div></div></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/image-eeGDl5ev.jpg"
        }
    ],
    "hybridTools": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9f6a9bf2-ee6b-4dea-beb7-8f269fdd47e6"><p dir="ltr"><strong><span style="font-size:1.5em;">Воспоставете работна група за иницијатива за одржливост или зелен тим на амбасадори</span></strong></p>

<p dir="ltr">Не може многу да се направи без мотивирани луѓе. Идентификувајте ги агентите за промена во организацијата и ако е можно обидете се да ги лоцирате на неколку нивоа и сектори. Обидете се да ги поттикнете за идејата со истражување на можностите и придобивките од хибридната работа за намалување на јаглеродните емисии. Ставот е тука, мотивацијата исто така, позицијата иако чисто доброволна ќе им обезбеди средства за дејствување во организацијата влечејќи од внатрешна мотивација за грижа за животната средина.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-658bbecb-b411-40b2-8187-4d368eb13db1"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a076563db5.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-GBdvjo7v.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4efad5a4-1ecd-417f-a6e1-c245bfb5ecbe"><p><strong><span style="font-size:1.5em;">Воведете канцеларија за ревизија на потрошувачката</span></strong></p>

<p>Некои примери за активности (извор <a target="_blank" href="http://www.sustainablebusinesstoolkit.com">www.sustainablebusinesstoolkit.com</a>):</p>

<ol>
\t<li>
\t<p><strong>Елиминирајте ги чашите за еднократна употреба:</strong> Чашите за еднократна употреба се штетни за околината. Пластичните и стиропорните чаши не се биоразградливи, и може да поминат години на депоните неразградени или да се пробијат во нашите океани и реки. Обидете се да носите шише за повеќекратна употреба или термос за вашата вода или кафе. Ако целосното откажување од чашите за еднократна употреба е неприфатливо во моментот, тогаш размислете наместо тоа да користите еколошки чаши.</p>
\t</li>
\t<li>
\t<p><strong>Воспоставете политика за “Носење сопствено шише за повеќекратно користење”</strong></p>
\t</li>
\t<li>
\t<p><strong>Користете рециклирана хартија за печатење: </strong>Ставањето рециклирана хартија во вашите печатачи е лесно применлива идеја. Со денешната технологија, вие и вашите колеги нема ни да ја забележите разликата.</p>
\t</li>
\t<li>
\t<p><strong>Печатење двострано:</strong> проверете дали компјутерот и печатарот се автоматски поставени да печатат двострано. Според истражувањето, двостраното печатење може да го намали отпадот од хартија за 10-40%.</p>
\t</li>
\t<li>
\t<p><strong>Обидете се да трошите помалку хартија</strong></p>
\t</li>
\t<li>
\t<p><strong>Обидете се да употребувате рециклирани и биоразградливи пенкала, наместо да фрлате пластични пенкала.</strong></p>
\t</li>
\t<li>
\t<p><strong>Намалете ја осветленоста на мониторот на вашиот компјутер.</strong> Според професори од Универзитетот Харвард, намалувањето на осветленоста на вашиот компјутерски монитор од 100% на 70% може да заштеди до 20% од енергијата на мониторот. Повеќето луѓе нема да ја забележат разликата од ова прилагодување, па затоа е брза и лесно остварлива промена.</p>
\t</li>
\t<li>
\t<p><strong>Исклучете го мониторот на компјутерот ноќе за да заштедите енергија</strong></p>
\t</li>
\t<li>
\t<p><strong>Раздвижување за собирање на ѓубре во канцеларијата (Waste Walk).</strong> Оваа активност е кога вработените ќе погледнат наназад, ќе одат по подот и ќе ги идентификуваат расфрланите канцелариски материјали и ситни отпадоци. Тоа е забавен начин на вклучување на сите вработени да учествуваат со идеи во вашата канцеларија со цел да имаат влијание врз животната средина. Waste Walks не бара дополнителни средства, ресурси или трети лица. Оваа активност е пристап кој се вика “дофат на ниско висечко овошје” кое ќе направи подобрувања во канцеларијата..</p>
\t</li>
\t<li>
\t<p><strong>Ослободете се од поединечните корпи за отпадоци:</strong> некои канцеларии имаат корпи за отпадоци на секој поединец! Ќе ги зголемите стапките на рециклирање и ќе го намалите отпадот откако ќе ги отстраните поединечните корпи за отпадоци и наместо тоа ќе ги искористите централните корпи за отпадоци.</p>
\t</li>
\t<li>
\t<p><strong>Користете еко кафе кое прави помалку отпад</strong></p>
\t</li>
\t<li>
\t<p><strong>Оптимизирајте ја употребата на природна светлина. </strong>Ако вашата канцеларија има прозорци, тогаш погрижете се да ја оптимизирате природната светлина. Избегнувајте да ги вклучувате светилките во текот на денот, со тоа што ќе ги држите завесите, сенките и ролетните отворени.</p>
\t</li>
\t<li>
\t<p><strong>Инвестирајте во енергетско ефикасно осветлување.</strong> Користењето на енергетски ефикасни светилки заштедува електрична енергија и пари. Енергетски ефикасните светилки доаѓаат во сите облици и големини. Има по нешто за секого согласно секоја потреба и секој буџет!</p>
\t</li>
\t<li>
\t<p><strong>Инвестирајте во паметни приклучоци.</strong> Канцелариите можат автоматски да заштедат енергија со користење на паметни приклучоци. Паметните приклучоци автоматски ги исклучуваат светлата, вентилаторите и апаратите. Повеќето паметни приклучоци ви дозволуваат да ги контролирате вашите уреди од каде било со помош на апликација на вашиот телефон.</p>
\t</li>
\t<li>
\t<p><strong>Прилагодете го вашиот термостат 2 степени нагоре во лето и 2 степени надолу во зима.</strong> Алтернативно, можете да користите енергетски ефикасен термостат кој сам заштедува енергија.</p>
\t</li>
\t<li>
\t<p><strong>Поттикнете ја заштедата на вода со совети и инструкции во бањите. </strong>Обидете се да ги замените старите знаци и користете креативни дизајни за да ја одржувате пораката свежа</p>
\t</li>
\t<li>
\t<p><strong>Изберете поправка наместо замена</strong></p>
\t</li>
\t<li>
\t<p><strong>Организирајте зелени натпревари со вашиот тим</strong></p>
\t</li>
</ol>



<p><strong>Совет: </strong>Правењето мали чекори наместо драстични промени е најдобриот начин да ги постигнете вашите еколошки цели. Наместо да вклучите големи промени што може да ги оптоварат вашите вработени, фокусирајте се на правење мала трансформација во секоја област од вашето работно место.</p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-44756050-178f-436e-893f-651f0a73f718"><p><strong><span style="font-size:1.5em;">Проценка на јаглеродните емисии во насока на политиките и практиките за работа од далечина</span></strong></p>

<p>Фокусот на истражувањето кое го спроведе Ројтерс во 20 големи корпорации кои ја прифаќаат работата на далечина, покажа дека само половина од тие компании се обиделе да ги проценат јаглеродните емисии поврзани со работниците од далечина, а помал е бројот на компании кои се обиделе да го ублажат или неутрализираат влијанието. Дури и Мајкрософт - кој има извонредно силна програма за корпоративна одржливост, во 2020-та година почна да ги проценува јаглеродните емисии на работниците во рамките на работата од далечина, и се обиде да се справи со влијанието на емисиите надвор од локацијата.</p>
</div>


</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-kVIDIWNk.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-96673788-aac0-4bb1-a147-1f060a8bad55"><p><strong><span style="font-size:1.5em;">Индикатори за потрошувачка на енергија</span></strong></p>

<p>Постојат мерни алатки, но тие наидуваат на неколку предизвици. Првата е дека мерењето се заснова на тоа колку троши една локација, но, честопати компаниите имаат неколку локации во многу различни географски области и зони и со многу различни опкружувања. Втората е потребата да се земе во предвид цел екосистем, вклучувајќи ги вработените и добавувачите. Да се внесат и тие во равенката како варијабли и да се има влијание врз нив е многу посложено.</p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-72db514d-8a33-4eb4-a525-83ba1cf3c4c2"><p><strong><span style="font-size:1.5em;">Распоред кој го дефинира менаџерот за хибридна работа</span></strong></p>

<p><strong>Донесете политика која ги зема во предвид вкупните влијанија поврзани со патувањето и животната средина (на пр. избегнувајте далечинско работење во понеделник и петок, што го користат повеќето компании).</strong></p>
</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a0e4f2b1-4e13-4f99-b95f-0ef8a62df29a"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a0d17a1b25.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-23380b42-0403-427c-ad5c-a2eb83219cfa"><p>Моделот предложен од Дејвид Аутисиер би можел да биде интересен во овој поглед. Тој модел предложува да се преиспитаат работните методи што треба да се земат во предвид во однос на “работа лице-в-лице” и “работа од далечина” преку поимите „Build“ и „Run“ позајмени од Agile методите. Далечинското работење добро функционира кога е во тек - т.е. во познати, повторливи активности - но е помалку приспособено за Build - т.е. за сите активности кои ги вклучуваат инвестициите во иднина, како што се нови проекти.</p>

<p>Со други зборови, знаеме како далечински да управуваме со тековната активност, за која сме веќе влезени во шема, но помалку би знаеле далечински да управуваме со онаа активност бара интеракции, размени, доверба и колективна интелигенција за да започне. Во тој случај, веројатно ќе се претпочита “работа лице в лице”.</p>

<p>Врз основа на ова, директорот за човечки ресурси може да ги мотивира менаџерите да размислуваат во рамките на своите тимови за распоредот на времето или задачите за кои е важно сите да бидат заедно; и за распоредот на времето и задачите што може да се добијат со спроведување на одредени активности онлајн.</p>

<p>На пример, состанокот за следење на оперативниот проект ќе биде поефективен ако се спроведе онлајн. Од друга страна, што се однесува до влез на нов член во тимот, подобро е тоа да се направи лично. Итн... На овој чекор може да се додаде и критериум за влијанието на јаглеродниот отпечаток.</p>

<p>Ова може да доведе до групирање задачите во кластер за работа од далечина, и кластер за работа лице в лице, земајќи ги предвид сите параметри: бројот на вклучени луѓе, растојание што ќе треба да ги поминат и режимите на транспортот, стапките на зафатеност на канцелариите, влијанието во однос на дигиталната употреба итн.</p>

<p>Идејата не е да се има математички пристап, туку опортунистички: ако состанокот во живо може да има подобар резултат од она што би се постигнало од далечина, и покрај повисоките трошоци за животната средина, веројатно е сè уште релевантно да се спроведе со физичко присуство. Тоа е прашање на избор. Интересот треба да биде ставен на средствата кои се потребни за тимот како и за постојано мерење на различните параметри на работната организација.<br>
</p>

<p dir="ltr"></p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-9MJjwGNw.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6ab0511b-b00e-4b8e-af60-873709089171"><p dir="ltr"><strong><span style="font-size:1.5em;">Апликации кои овозможуваат оптимизација на канцеларискиот простор поврзан со работата од далечина/хибридната работа</span></strong></p>

<p>Различни апликации помагаат да се минимизира напорот на уредувањето на работниот простор но и подобро да се менџира со опкружувањето во канцеларијата како и со присуството на вработените во канцеларијата.</p>

<p>Дел од овие алатки можат да се најдат во Методологијата за проценка на влијанието развиена со овој проект.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-27fceef1-f99c-4f3a-adc2-ca263f01efff"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a11737328b.png" alt="" title="" style="width: 100%; height: 100%;"></div>


</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-kUM3Ss3g.png",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-79477eba-a9a7-4660-bb64-90d2f1600a3e"><p><strong><span style="font-size:1.5em;">Редовни работилници / ручеци и учење преку страната Sustainability@theoffice</span></strong></p>

<p>Работилниците за одржливост имаат за цел да направат можност сите да научат пошироко во контекст на тоа како да се спроведува одржливоста во канцеларијата и да се развијат стратегии кои ќе поттикнат размислувања за одржливост во рамките на нивната секојдневна работа.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-783f311b-b0bc-455e-8ff2-8602b11a7275"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a12151f7ae.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-357JJyIZ.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9e69f91d-beba-4166-86c4-9e3c563b3d40"><p><strong><span style="font-size:1.5em;">Климатски Фреск</span></strong></p>

<p>За само 3 часа, колаборативната работилница “Climate Fresk” ги презентира основите од науката за климатските промени и повикува на акција. Со користење/ активирање на колективната интелигенција на групата, “Climate Fresk” им овозможува на учесниците да поттикнат влијание врз активностите кои ги преземаат. Методологијата не користи хиерархиска структура на учење, им дозволува на сите да учествуваат и да го најдат своето место во вежбата. Додека учесниците ги поврзуваат причините и последиците од климатските промени, и можат да се вратат назад и да ја разберат системската природа на предизвиците за прв пат.</p>

<p dir="ltr"><strong>Повеќе информации:</strong> <a target="_blank" href="https://climatefresk.org/" target="_blank">https://climatefresk.org/</a></p>
</div>`
        }
    ],
    "mobilityTools": [
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4fba2945-7e82-4e52-8e74-afa0bdfe1f8b"><p><strong><span style="font-size:1.5em;">Спроведете анализа на мобилноста</span></strong></p>

<p>Спроведете проценка на јаглеродните емисии на вашата компанија: назначете експерт за да ги идентификува и фокусира темите со најголемо влијание на истата (мобилност, патување со авион, канцеларии и други објекти) итн. Потоа спроведете скенирање за мобилноста врз основа на точни и тековни податоци.</p>

<p><strong>Примери:</strong></p>

<p>Mobility Label, алатки за попаметно патување</p>

<p><a target="_blank" href="https://mobilitylabel.com/">https://mobilitylabel.com</a></p>

<p><strong>Mob Box</strong> собира и составува масовни необработени податоци. Од внатрешни податоци за корпоративната флота на автомобили до надворешни податоци со отворен код, тие ги претвораат бројките во директни карти и интуитивни графикони. Тоа ќе ви помогне да добиете јасен поглед на вашите вработени и мобилноста. Проценете ја севкупната транспортна мешавина и анализирајте ги јаглеродните емисии и поврзаните трошоци.</p>

<p><a target="_blank" href="http://www.mob-box.eu/" target="_blank">www.mob-box.eu</a></p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-08185ad9-d286-407c-8269-b3bcecba9cc2"><p><strong><span style="font-size:1.5em;">Истражете ги достапните јавни решенија</span></strong></p>

<ul>
\t<li>
\t<p><strong>Во Франција:</strong><a target="_blank" href="https://www.ecologie.gouv.fr/sites/default/files/mobilite%20durable%20en%20entreprise.pdf" target="_blank">Forfait Mobilité Durable</a> (Пакет за одржлива мобилност) - секоја компанија може да ги субвенционира или финансира патувањата на своите вработени од дома до работа до 800 евра годишно, со ослободување од данок и придонеси за социјално осигурување, се додека тие користат зелени транспортни средства - јавен превоз, електрични скутери, заеднички електрични автомобили, дружење со автомобил…</p>
\t</li>
\t<li>
\t<p><strong>Во Белгија: </strong>Budget Mobilité (Буџет за мобилност) им овозможува на компаниите да им понудат буџет за поеколошки опции за мобилностна вработените кои користат службено возило —<a target="_blank" href="https://lebudgetmobilite.be/fr" target="_blank">https://lebudgetmobilite.be/fr</a></p>
\t</li>
</ul>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/image-V0D782D4.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-eb98b054-5f2c-454c-9a29-05efff3cce27"><p><strong><span style="font-size:1.5em;">Индивидуална анализа / Анкети за мобилност</span></strong></p>

<p>Преку индивидуализиран маркетинг пристап разгледајте како луѓето патуваат до работното место и понудете им алтернативи. Постои цел процес на дијагностицирање и поддршка за да на вработените им се укаже дека автомобилот не е нужно најефикасниот начин за движење. Подлабока анализа (следејќи ги луѓето неколку дена) може да им покаже дека има и други начини на вршење на работите, помалку моторизирани, поекономични итн.</p>
</div>`

        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "Платформи за мултимодални транспортни рути",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d7ebb14-1b14-4a0b-a034-151adcc5f3bb"><p><strong><span style="font-size:1.5em;">Платформи за мултимодални транспортни рути</span></strong></p>

<p>Платформите за мултимодални рути можат да ги покажат различните опции и да им овозможат на луѓето да сфатат дека патувањето со автомобил ќе биде помалку ефикасно во споредба со другите начини на транспорт. Некои од овие алатки ги прикажуваат јаглеродните емисии кои произлегуваат од различни начини на патување.</p>

<p><strong>Пример:</strong></p>

<p>Skipr, “се-во-едно” е решение за управување со корпоративна мобилност</p>

<p><a target="_blank" href="http://www.skipr.co/">www.skipr.co</a></p>

<p>Skipr се бори против користењето на автомобил за еден корисник во градот. На вработените им нуди апликација која им овозможува да видат како да стигнат од А до Б со комбинирање на различните превозни средства што постојат околу нив: јавен транспорт, заеднички возила, електрични скутери, зелено возило итн. Постои ко-пилот кој поддржува во опцијата да не се користи автомобилот. Овозможува купување билети за јавен превоз на сметката на компанијата, во комбинација со платежна картичка, поврзана со истиот буџет во #мобилност, за алтернативни видови на мобилност.</p>

<p>За компанијата, платформата овозможува управување кое прави две работи: ги поставува насоките на компанијата (кој е предодреден за што ) и обезбедува алатка за известување што дава целосна видливост за генерираните заштеди и намалување на јаглеродните емисии во споредба со користењето на традиционален автомобил. Вработените имаат контролна табла која ја следи нивната личмна ситуација додека компанијата добива збирни извештаи. Кога пребарувате по опциите за патување, вработените добиваат три информации: времето на пристигнување, трошоците во евра и јаглеродните емисии.<br>
</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4bf3cd48-c7e8-4a5f-a5a3-4727c4478547"><p><strong><span style="font-size:1.5em;">Поттикнете здружување за заедничко патување со колегите</span></strong></p>

<p>Предуслови за успех: флексибилноста на работното време е една од првите преизвици за здружување меѓу колегите. Немањето исти работни часови е ограничувачки фактор, особено кога се додаваат личните ограничувања - собирање на децата, извршување на обврски итн. Може да помогне да имате проект менаџер, кој со табела и молив ги евидентира патувањата и ги совпаѓа. Ако им оставите на луѓето да го средат тоа меѓу себе, ќе биде сложено. Ако го оставите на некоја апликација, и тоа е комплицирано. Дигиталните алатки работат многу добро за патувања на долги растојанија, но не се толку ефикасни за кратки патувања, при повторливо и секојдневно патување. Сепак, постојат платформи кои го овозможуваат здружувањето со автомобили со опции за совпаѓање, информации за фискалните придобивки, можности за следење за ориентирање во насока на комуникацијата. Важно е да се напомене дека здружувањето со автомобил не функционира добро кога работното место е во близина и има понуда за јавен превоз.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e97d6b7b-85ca-4b8e-adc4-c549a30315f2"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650ae2ffdc5cc.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-tomPlVwo.jpeg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f2705860-d508-4b16-9089-9bba70468e72"><p><strong><span style="font-size:1.5em;">Шатл превоз</span></strong></p>

<p>Неколку организации кои постигнаа најголем раст во користењето на јавниот превоз обезбедија бесплатен, соодветен шатл-автобус на компанијата, поврзувајќи се со клучните локални градови и/или локалните железнички и автобуски станици. Преку партнерство вклучени беа организации кои го зголемуваат бројот на автобуски услуги што пристигнуваат на локацијата, се обезбедија во просек околу 14 дополнителни автобуси во шпицовите кои беа евидентирани преку истражување. Да се има соодветни и приспособени услуги делува дека е поважно од апсолутните бројки: поевтини билети и подобрување на инфраструктурата/квалитетот на услугата надвор од локацијата и со финансирање на нови автобуски постојки до компанијата или во близина, на пример, е бенефит за вработените.</p>
</div>`

        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c293a877-1d7a-44a0-8b05-524e78f4b318"><p><strong><span style="font-size:1.5em;">Возење велосипед </span></strong></p>

<p>Се чини дека факторите кои се најтесно поврзани со зачестеното возење велосипед се однесуваат на висококвалитетниот пристап до локацијата или подобрувањето на пристапот од локацијата; зголемување на достапниот паркинг простор за велосипедистите и нудење услуга за поправка на велосипедот. Исто така, забележливо беше дека повеќето од организациите/компаниите во студијата обезбедија тушеви, пресоблекување, сушење и/или шкафчиња; како и договорени попусти за опрема за велосипедистите.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-457e418e-7a81-4932-82ba-2fcbf1e21fcd"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650a17655699c.jpg" alt="" title="Cycling" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-rCJUADxS.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-801a1d35-3531-4738-807c-79f59dd9e601"><p><span style="font-size:1.5em;"><strong>Електрични автомобили? Да, но…</strong></span></p>

<p>Електричниот автомобил не е решение за сообраќајниот метеж. Автомобилот е сепак автомобил, без разлика дали е електричен, водороден, бензинец или дизел, сепак зафаќа простор на патот. Не испушта „нула CO2“, испушта помалку: се зависи од тоа како е произведена електричната енергија. Исто така претставува предизвик и тешкотија во врска со мобилноста: не постои такво нешто како “една големина да одговара на сите”. Секој си има своја специфичност. Кај електричните автомобили, има и проблеми поврзани со трошоците и полнењето што треба да се земат предвид. Сè уште има пречки. Работите брзо се менуваат, но соодветно решение од волшебно стапче, нема.</p>

<p>Во таа насока, треба да се инсталираат инфраструктури за полнење на електрични автомобили: тие се неопходни за да се зголеми употребата на електрични автомобили.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-07fc7d73-8673-4f28-8e07-2c87d26235f0"><p><strong><span style="font-size:1.5em;">Лизинг на велосипеди (за електрични велосипеди)</span></strong></p>

<p><strong>Пример:</strong></p>

<p>Како работодавач во Белгија, можете да изберете да понудите лизинг за велосипеди на различни начини: бесплатно или со (делумен) придонес за велосипедот. Во сите ситуации, вработените уживаат даночна предност од околу 40%. На изнајмен велосипед не плаќате никаков надоместок, бидејќи велосипедот го користите редовно за патување. Може да исплатите и додаток за велосипедот. Ова ги мотивира вашите вработени да возат велосипед. Прописите за лизинг на велосипеди не прават разлика помеѓу изнајмен и приватен велосипед. Кои се резултатите? Вработени се физички активни: има 1-2 дена помалку отсуства и голем придонес во планот за зелена мобилност</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6c224ae6-6652-45b9-a674-13597a5bb424"><p><strong><span style="font-size:1.5em;">Обука за еко-возење</span></strong></p>

<p><strong>Пример:</strong></p>

<p>Да се биде еколошки одговорен кога сте на пат бара повеќе од поседување еколошко возило. Треба да имате зелен стил на возење. И ова е нешто што возачите го учат во програмата за обука Е-позитивни - едногодишен курс за обука од ALD Automotive.</p>

<p><strong>Како функционира тоа:</strong></p>

<p>„Се започнува со тоа што можат да направат вашите возачите; тие го опишуваат нивниот сегашен стил на возење на картичка. После тоа, следат е-обука, индивидуално и групно. Инструкторот им дава постојани совети и насоки на кои треба да обрнат внимание. Резултатот? На крајот од обуката сите возат со е-позитивен став.</p>

<p><strong>10 совети за еколошко возење</strong></p>

<ol>
\t<li>
\t<p>Префрлете се на поголема брзина во соодветно време. Со дизел мотор, некаде од 2.000 вртежи и со бензинец, 2.500 вртежи.</p>
\t</li>
\t<li>
\t<p>Време на движење? Притиснете го педалот за гас целосно, бидејќи бавното забрзување сигурно нема да ви заштеди гориво.</p>
\t</li>
\t<li>
\t<p>Одржувајте доволно растојание од возилото пред вас. Тогаш нема да треба постојано да сопирате и да забрзувате.</p>
\t</li>
\t<li>
\t<p>Дали треба да забавите или да престанете? Не одете директно на сопирачката, туку отпуштете го педалот за гас навреме. Ќе успорите со моторно сопирање и без возење на спојката.</p>
\t</li>
\t<li>
\t<p>Вклучете ја контролата на патувањето заради постојаност – а со тоа и еколошка чистота.</p>
\t</li>
\t<li>
\t<p>Дали ќе застанете повеќе од 20 секунди? Тогаш исклучете го моторот.</p>
\t</li>
\t<li>
\t<p>Планирајте ја вашата рута однапред за да не набиете непотребна километража.</p>
\t</li>
\t<li>
\t<p>Не ви е потребна покривна кутија за велосипед или багажник? Тргнете го и намалете го отпорот на воздухот.</p>
\t</li>
\t<li>
\t<p>Редовно проверувајте го притисокот во гумите.</p>
\t</li>
\t<li>
\t<p>Сервисирајте го вашето возило навреме: само така ќе можете да возите економично и безбедно.</p>
\t</li>
</ol>

<p><em><strong>Извор: ALD Automotive</strong></em></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-0c1c9678-0a6c-445a-95ce-4a25e6395d09"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650a17e559c50.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-mbYWXvUd.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3e08a77a-372e-478d-a71b-1e82dbff5942"><p><strong><span style="font-size:1.5em;">MaestroMobile </span></strong></p>

<p>Maestro Mobile е сериозна игра развиена од Espaces-Mobilités во Белгија, во областа на мобилноста која има за цел да го промени однесувањето преку експериментирање.</p>

<p>Повеќе информации:<a target="_blank" href="https://www.maestromobile.eu/">https://www.maestromobile.eu</a></p>

<ul>
\t<li>“Mobility Van” е тим составен од експерти за мобилност кои патуваат на јавни простори и компании и на вработените им ги покажуваат сите решенија за мобилност на практичен начин (преку тест со машини за микромобилност, велосипеди, апликации…)</li>
\t<li>
\t<p>Претставува градска, урбана, сериозна, игра која е организирана во форма на тимбилдинг и им овозможува на учесниците да тестираат различни решенија за мобилност на практичен начин додека (повторно) го откриваат градот, завршуваат предизвици, одговараат квизови итн. Со користење на шифрите од популарните игри како што се “Pokemon Go” или принципите на урбаните трки, “City Game” создаде свој универзум поврзан со откривањето на услуги за мобилност.</p>
\t</li>
\t<li>
\t<p>На ист начин како и програмите за фитнес, “Mobility Passport” нуди предизвик за мобилност што ги води учесниците низ различните фази на учење за мобилноста, од запознавање на услугатa до користење, во период од околу еден месец. Преку мобилната апликација, учесникот добива валидација на своите вештини и му се доделуваат сертификати кои имаат форма со печат за неговата мобилност.</p>
\t</li>
\t<li>
\t<p>“GO Mobility” е хибридна платформа која нуди комплетна програма за коучинг и откривање на алтернативи за мобилност.</p>
\t</li>
\t<li>
\t<p>“Critical MaaS” е персонализиран тренинг за да експериментирате со нови мобилности со цел да го промените вашиот начин на движење. Во придружба на експерти, ги тестирате сите нови услуги и станувате господар на вашата мобилност.</p>
\t</li>
</ul>




</div>`
        }
    ],
    "sustainableTools": [
        {
            "roles": [roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-de5d235d-00c3-4b16-93a5-164773aa7ff5"><p dir="ltr"><strong><span style="font-size:1.5em;">Потпишете ја повелбата „ Дигитално Одговорни “.</span></strong></p>

<p>Со потписот на оваа повелба, организациите ги потврдуваат своите заложби за одлучно влегување во пристапот „Дигитално Одговорни“.</p>

<p>Линк: <a target="_blank" href="https://isit-be.org/wp-content/uploads/2021/03/Sustainable-IT-Charter-ISIT-BE.pdf">https://isit-be.org/wp-content/uploads/2021/03/Sustainable-IT-Charter-ISIT-BE.pdf</a></p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1fb3c548-59b6-43b1-a623-fb6df78af94b"><p><strong><span style="font-size:1.5em;">Мерења</span></strong></p>

<p>Со цел да се расветли влијанието на нашата прекумерна потрошувачка на дигитална технологија, Institut du Numérique Responsable создаде едноставен калкулатор кој им овозможува на поединците да го проценат нивното професионално дигитално влијание.</p>

<p>Друга алатка за мерење, наречена WeNR, го оценува отпечатокот на Информацискиот систем на организациите за да им овозможи да идентификуваат каде лежи влијанието и да го идентификуваат нивното ниво на зрелост на Дигиталната одговорност преку користење на квалитативни и квантитативни индикатори. Оваа алатка има форма на онлајн прашалник, бесплатен за користење и достапен за сите организации, без разлика дали се големи или мали. Произведен во партнерство со Универзитетот Ла Рошел и EIGSI, WeNR се заснова на работата на тезата финансирана од Агломерацијата Ла Рошел и научна соработка помеѓу INR Франција, INR Швајцарија, белгискиот ISIT и Универзитетот во Лувен (UCLouvain).</p>

<p>WeNR доаѓа во 3 верзии за да ги задоволи потребите на што е можно повеќе организации: од квалитативна ревизија на зрелост до компаративна ревизија по сектор на активност. Истата е детална, квантитативна и повикува на акција.</p>


</div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>WeNR Light</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">Овој прашалник, достапен онлајн, овозможува да се процени нивото на зрелост на организациите во однос на дигитална одржливост (Луѓе-Планета-Профит). (крајот на септември 2022 година)</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>WeNR Standard</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">WeNR има форма на збир на квантитативни и квалитативни прашалници што треба да ги пополни секоја организација учесничка, користејќи специфичен образец за датотека обезбеден од ISIT. Овој прашалник е достапен онлајн до 9 септември 2022 година, а потоа податоците се третираат доверливо и асинхроно, а резултатите се доставуваат 2 до 3 месеци подоцна во форма на извештај од прво ниво.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>WeNR Plus</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Оваа верзија на алатката е резервирана за организациите членки на ISIT. WeNR + користи стандарден модел и калкулатор WeNR. Обезбедува сеопфатни и детални извештаи од квантитативна, квалитативна и компаративна гледна точка со организации од истиот сектор.</p>
</div></div></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e98ca209-cede-40c3-a2b8-680db2729109"><p dir="ltr" role="presentation">​​​​​​</p>

<p dir="ltr">Обезбедените алатки за анализа овозможуваат идентификација на правци на дејствување за да се воспостави одржлива ИТ стратегија.</p>

<p dir="ltr">За пристап до овие алатки: <a target="_blank" href="https://wenr.isit-europe.org/">https://wenr.isit-europe.org/ </a></p>

<p>Проценете го вашиот индивидуален/професионален дигитален отпечаток на животната средина во kg CO2.</p>

<p dir="ltr">За да фрли светлина врз влијанијата поврзани со нашата прекумерна потрошувачка на дигитална технологија, ISIT создаде калкулатор кој ни овозможува едноставно да го пресметаме нашето професионално дигитално влијание. Оваа проценка опфаќа:</p>

<ul>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Опрема</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Користење на интернет</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Складирање во облак</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Испраќање пораки</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Деловни патувања</p>
\t</li>
</ul>

<p dir="ltr">И го покажува вашето влијание во споредба со</p>

<ul>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">просечниот јаглероден отпечаток на Французин</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">квотата да се почитува во 2050 година за усогласување со Парискиот договор</p>
\t</li>
</ul>

<p dir="ltr">За пристап до калкулаторот:<a target="_blank" href="https://myimpact.isit-europe.org/">https://myimpact.isit-europe.org/</a></p>

<p dir="ltr"></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-5a88bc86-804b-4334-a3d1-e2a3f3b4ff81"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b78923fbf8.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-75c025a2-65b8-4dba-99fa-ada96ab7a13c"><p>fruggr е апликација развиена за мерење на еколошко и општествено влијание на ИКТ апликациите на организациите. Редовното мерење е клучот за постојано подобрување. fruggr автоматски собира различни необработени податоци: користење податоци, изворен код и аналитика. Софтверот се потпира на препознаените фактори на влијание (модел од еден бајт на проектот Shift, јаглеродна база Адем, IEA, EEA...) за да ги пресмета најточните можни резултати. fruggr нуди проценка на јаглеродот што ги опфаќа сите 3 опсези. Алатката нуди и мрежа за оценување на сертификати.</p>

<p dir="ltr">Линк: <a target="_blank" href="http://www.fruggr.io">www.fruggr.io</a></p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/image-MJ9YeML0.png",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1f715907-f760-4fe6-a7a5-f73d1d0a9432"><p><span style="font-size:1.5em;"><strong>Стратешки патоказ</strong></span></p>

<p>Потребно е да се стави приоритет на дефинирањето на стратешки патоказ за одговорна дигитална технологија, заснован на конкретни и ефективни мерки, со дефинирање на индикатори, имплементација на програма за развој на вештини, потпишување на повелба, учество на глобални настани како што се Digital Clean- up Day (обично во март), глобален ден за подигање на свеста за еколошкиот отпечаток на дигиталната технологија.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-ba27207c-8a81-48a3-bf0e-b3e4e01270bc"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b790538667.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-91a70d9f-e554-44da-be68-4187d51895b9"><p>Како и во многу други области, главното прашање е неактивноста и недостигот на транспарентност. Патоказот, исто така, мора да биде реален, брз и одржлив ако сакаме да биде успешен. На пример, покренувањето на идејата дека која било организација би можела да биде јаглеродно неутрална во рок од шест месеци е опасно тврдење. Невозможно е да се постигне, илузорно и утописки. Ова на крајот би го дискредитирало самиот проект.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-G9NDge8J.png",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-29debb56-e454-4201-9ad4-89a80df5fe27"><p><span style="font-size:1.5em;">Одржливи ИТ MOOCs</span></p>

<ul>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">ISIT и неговите партнери предлагаат MOOC за одржлива ИКТ за да им овозможат на сите да бидат информирани и обучени. Awareness Sustainable IT MOOC е кратка програма за обука која овозможува прв пристап кон одржлива ИТ за секого. Целосниот Sustainable IT MOOC се состои од четири 30 минутни видеа, текст и интерактивна содржина. Обуката нуди теоретско знаење за совладување на основите на одржливиот ИТ пристап. Овие два модула се со слободен пристап: <a target="_blank" href="http://www.isit-academy.org">www.isit-academy.org</a></p>
\t</li>
</ul>

<ul>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">MOOC Еколошките влијанија на дигиталните технологии има за цел да се осврне на влијанието на ИКТ врз животната средина, нејзините позитивни и негативни ефекти, феномените што може да се забележат денес и проекциите што можеме да ги направиме за иднината. Наменет е за образовните медијатори и општо за јавноста. Нивната веб-страница нуди за секој дел презентација на содржината, забавно и едукативно видео, спремни за употреба наставни капсули, концептни листови за продлабочување на одредени поими и библиографија со дополнителни ресурси. Линк: <a target="_blank" href="https://learninglab.gitlabpages.inria.fr/mooc-impacts-num/mooc-impacts-num-ressources/en/index.html">https://learninglab.gitlabpages.inria.fr/mooc-impacts-num/mooc-impacts-num-ressources/en/index.html</a></p>
\t</li>
</ul>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4a03a0c2-6e3d-4bc5-9ca9-83c9b0f413ac"><p><strong><span style="font-size:1.5em;">Водич за одговорни дигитални најдобри практики за организации</span></strong></p>

<p>Целта на овој водич е да се подигне свеста за влијанијата на одредени практики кои се среќаваат во организациите и да се споделат примери на одговорни дигитални практики кои можат да помогнат во намалувањето на еколошкиот отпечаток на дигиталната технологија. Овој водич е наменет за сите видови на организации, во јавниот и приватниот сектор. Некои примери на добри практики се поприлагодени од другите во зависност од контекстот и зрелоста на одговорниот дигитален пристап во организациите. Поодговорно дигитално однесување е огромна тема, која не се однесува само на дигиталниот оддел. Вклучени се и многу други оддели како што се набавки, човечки ресурси, комуникација, општи услуги итн. Затоа, овој водич е наменет за широка публика во однос на информациските системи како што се ИТ оддели, системски и мрежни администратори, програмери, купувачи, корисници итн.</p>



</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<p><span style="font-size:1.5em;"><strong>Сертификат за дигитална присебност</strong></span></p>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-678b08eb-b4f7-4548-9eeb-d4b9fcbb8217"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b79a54737b.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0c979019-a342-4766-b91c-2a64ee1540b3"><p>Сертификатот за дигитална присебност смета дека корисниците можат значително да го намалат влијанието врз животната средина преку нивниот избор на опрема и дигитално однесување. Инспирирани од барањата за заштеда на енергија воведени во неколку европски земји од 2000-та година наваму, овие сертификати овозможуваат интернализирање на надворешните ефекти на животната средина на дигиталната технологија и корегирање на одредени недостатоци на дигиталниот пазар. Можеби во годините што доаѓаат, употребата на овие сертификати би можела да се развие во организациите, заедно со идејата дека истата ќе биде во склоп на функцијата за управување со човечките ресурси преку собирање податоци од менаџерите за она што е направено за да ги поттикне вработените да ја намалат употреба на дигиталната технологија како што се: политики за состаноци, испраќање е-пошта, хибридна работа, видео конференции итн.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-2mBACaFf.jpg",

        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-138519da-a516-4b09-8269-bf6315bd58ce"><p><strong><span style="font-size:1.5em;">Одговорен дизајн на дигитални услуги</span></strong></p>

<p>Преминувањето кон одговорен дизајн на дигитални услуги значи создавање вредност преку дизајнирање на дигитални производи и услуги кои се поефикасни од еколошка, општествена и економска гледна точка. Ова е во согласност со новите показатели за перформанси со кои организациите сега мора да се соочат: не се очекуваат само извештаи за економски перформанси, организациите мора да обезбедат и докази за нивните општествени и еколошки влијанија. Ова не значи дека одредени проекти нема да бидат одобрени, туку дека дизајнот на дигиталните услуги треба да се подобри - на пример, да не се работи на развивање на одредени малку искористени функции во софтверските решенија кои непотребно би ги трошеле ресурсите.</p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-19678c96-8c5b-41a1-999d-10f2fce2a381"><p><strong><span style="font-size:1.5em;">Користете хардвер/функционалности за автоматско исклучување</span></strong></p>

<p>Cap Gemini процени 24 случаи на одржлива употреба на ИКТ. Анализата покажува дека автоматското исклучување на хардверот и функционалноста обезбедува најголема заштеда (14% во просек) преку намалена потрошувачка на енергија, истовремено намалувајќи ги емисиите на јаглерод во оваа категорија.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-625facb2-24e7-4f6e-a22b-082c8dbf4d7d"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b7a2a045c2.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-iNB15wRc.jpg",
        }
    ],
    "hybridHr": [
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-47f54b42-11d9-482f-8720-84285c76de62"><p dir="ltr"><strong><span style="font-size:1.5em;">Програми за човечки ресурси</span></strong></p>

<p>Важно е да се развие програма за човечките ресурси која ќе ја подигне свеста за прашањата за одржливост и ќе се обиде до одреден степен да ги обликува активностите на организацијата во оваа област во неколку фази: вградување, постојана обука, управување со перформансите, компензација.</p>

<p>Пред да ја развиете програмата за одржливост на човечките ресурси, спроведете пресек студија за влијанието на новите нашини на работа врз благосостојбата на вработените но и целите за одржлив развој.</p>

<p>Додека ја развивате програмата обезбедете усогласеност на сите политики за управување со човечките ресурси со целите за намалување најаглеродните емисии.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-40fb375e-8fd6-46af-9670-4af0cb630dbb"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a000f9022c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-VtIux57G.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-51d2007f-0995-4d97-be89-ede2d14b5c4b"><p><strong><span style="font-size:1.5em;">Регрутација</span></strong></p>

<p><strong>Оценувајте ги кандидатите согласно нивната свест и вредностите поврзани со одржливиот развој и нивната компатибилност со амбициите и целите на организацијата</strong></p>

<p>При вработување, на пример, дали ќе разгледате дали кандидатот има влијание врз за јаглеродните емисии? Дали ќе проверите дали однесувањето на кандидатот е усогласено со амбициите на организацијата во однос на јаглеродните емисии?</p>

<p>Предизвиците поврзани со климатските промени претставуваат причина за привлекување на кандидати кои се во потрага по значење како и за мотивација за вработените кои веќе се посветени на ова прашање.</p>

<p>Но, покрај се, сè повеќе кандидати и вработени ги поставуваат овие прашања: Каква е понудата за мобилност на компанијата, дали постои политика за работа на далечина, какви иницијативи постојат за промовирање на одржливиот развој итн.? Доколку компанијата нема јасна политика во рамките на овие прашања, таа ќе биде осудена на импровизација на одговори, различни од случај до случај, што може да го одбие талентот.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-82f5014d-e54b-4a0f-8cab-1a43a166b8b1"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a005f92b37.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-5DD74X27.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0c101637-6a96-4185-86a2-7b6ad760abbd"><p><strong><span style="font-size:1.5em;">Обука</span></strong></p>

<p><strong>Објаснете зошто одржливата трансформација е потреба за компаниите и вработените</strong></p>

<p dir="ltr"><strong>Првиот предизвик </strong>е да се разбере што е клучно во рамките на ова прашање, почнувајќи од принципите на науката: што велат научниците за климатските промени, природните ресурси, биодиверзитетот и влијанието врз нашите животи - заедници, бизниси, поединци.</p>

<p><strong>Вториот предизвик </strong>е поврзан со акција: одржливата трансформација влијае на сите професии.Со преглед низ работните места, позиција по позиција, треба да разберете како да го подготвите вашиот патоказ за акција, со помош на специфични обуки во зависност од тоа дали работите во: Набавки, ИТ, Правна служба, Финансии, Маркетинг, Човечки ресурси итн.</p>

<p><strong>Обучете го менаџерот</strong> да управува со посветеноста,мотивираноста на вработените: како реагирате кога, на пример, најдобриот продавач, кога доаѓа на семинар со тимот, ќе ги потсети дека повеќе нема да патуваат со авион?</p>

<p>Воведете ги во профилирањето на компетенциите и еколошки димензии</p>

<p>Предвидете ги утрешните побарувања за вештини: кои ќе бидат вештините на новиот сметководител, новиот ИТ специјалист, новиот клиент, новите менаџери за човечки ресурси итн. во контекст на одржлива трансформација?</p>

<p>Интегрирајте го еколошкиот критериум во изборот на даватели на услуги, особено за спроведувањето на обуките. Секогаш е можно да се информираат и поттикнат давателите на услуги за овој критериум на избор, со цел да се влијае да преземат акција. Изберете критериум за избор со цел да се охрабрат да дејствуваат во таа насока.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8e9a2632-788a-489a-a9d0-a5cec6137a3a"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a00b6f3b38.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-H35LwF6W.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-78bed962-f188-4975-8f73-b0dea716bef1"><p><strong><span style="font-size:1.5em;">Управување и оценување на перформансите</span></strong></p>

<p>Компаниите се повеќе поставуваат цели за одржлив развој, кои мора да се имплементираат преку одделенијата и тимовите и истите се бара да се постигнат. Затоа е важно да се оцени што прават менаџерите со своите тимови за да ги постигнат тие цели.</p>

<p><strong>Интегрирајте ги прашањата за одржливиот развој во годишните планови на вработените и во нивнот план за развој на вештини.</strong></p>

<p>Многу малку компании до денес ги преточуваат овие цели за одржливост во индикаторите за мерење на успешност. Кога го прават тоа, тие генерално се повикуваат на 17-те цели за одржлив развој на Обединетите нации, но тоа нивната акција треба да биде многу поконкретна од тоа.</p>

<p>Целите треба да се имплементираат низ секојдневното однесување: земете го во предвид аспектот на одржливост кога организирате состанок, кога организирате забава во компанијата, кога одлучувате за спроведување обука, кога правите избор за патување итн.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-bcf69465-7d98-4b54-8f57-16c62f1e31cf"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a010e1bb7c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-xUv8hn2l.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c5702d04-36d7-48a5-bdc3-dfd6ae0b7e6e"><p><span style="font-size: 19.5px;">Плата и надомест</span></p>

<p>Без оглед дали во изборот на бенефиции што го сочинуваат пакетот на плати или преку интегрирање на критериумите поврзани со одржливиот развој, воведете наградување за директорите, менаџерите, па дури и за сите вработени поврзани со оддржливите цели. Ако вработените ги постигнат своите резултати, но на сметка на животната средина, дали бонусот ќе биде даден?</p>

<p><strong>Стимулирајте го и ценете го зеленото однесување на вработените.</strong></p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-31d857a2-1f29-40e7-bf53-f14345717159"><p><strong><span style="font-size:1.5em;">Флексибилност на работа</span></strong></p>

<p>Понудете алтернативни работни места што е можно поблиску до местото каде што живеат вработените (нови локациски места, заеднички простории, итн.)</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-335b1072-9195-49d8-bb14-1838c832c36b"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a013f7d826.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-aSD8KIPy.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<p><strong><span style="font-size:1.5em;">Комуникација</span></strong></p>`,
            "imageSource": "https://nwow4net-zero.eu/images/t1/file-Er7RBrXn.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.EmployeeChampion],
            "heading": false,
            "description": `<p>Комуницирајте на редовна основа наместо по потреба. Годишната комуникација на оваа тема, на пример, нема да биде доволна ако сакате да ги вклучите вашите вработени.</p>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": false,
            "description": `<p>Бидете автентични и транспарентни, особено за напредокот што треба да се постигне. Вашиот дискурс мора да биде усогласен со реалноста на климатскиот напредок на компанијата или нема да биде добро прифатен од вашите вработени.</p>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": false,
            "description": `<p>Насочете ги вашите комуникации на одредени теми. На пример воведување „Комплет со алатки за дејствување на деловно ниво за еколошката транзиција“; „Како да ги инвестирате заштедите на вашите вработени во фондови кои служат на животната средина“; итн.</p>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": false,
            "description": `<p>Направете диверзификација на форматите за комуникација (белешка, видео, подкаст, презентација, итн.) и преку користените канали (е-пошта, интранет, корпоративна социјална мрежа, итн.) со цел да го максимизирате дофатот на вашите пораки.</p>`
        }
    ],
    "mobilityHr": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Работна организација - Формализирање на договори за работа на далечина</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>До пред три или четири години, работата од далечина беше главно неформална. Денес, по здравствената криза работата од далечина станува се повеќе и повеќе формализирана заради нејзиното широко усвојување во големите организации. Ова формализирање може да има форма на политика на компанијата и/или дискусии помеѓу работодавачот/менаџерите и синдикатите/вработените за насоките со кои работодавачот сака да ја постави работата од далечина. Соочени со заситеноста на јавниот транспорт и патните мрежи, предизвик е да се развие работата од далечина како начин за регулирање на овие текови поврзани со мобилноста.</p>
</div></div></div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/image-rWDYpb9a.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>НАГРАДИ - Пакет за одржлива мобилност</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>На ист начин како што јавниот превоз е покриен за вработените, таква шема со компензација може да се направи за оние кои на пример доаѓаат на работа користејќи „етички “ начини како што се возење велосипед или здружување за користење на еден автомобил. Што се однесува до службените автомобили: препорака е да се забранат дизел автомобилите, а да се направи замена на бензинските возила со возила со нула емисија на штетни гасови.</p>

<p><strong>Во Франција,</strong> работодавачите можат да дадат пакет за оддржлива мобилност на своите вработени кои користат одржливо транспортно средство за нивните службени патувања. Опфатени се следните транспортни средства: велосипед (вклучувајќи електричен велосипед), здружување со автомобил како патник и возач, заеднички услуги за мобилност (споделување на електрични или хибридни возила, изнајмување на скутери или велосипеди), јавен превоз (со исклучок на сезонски билети). Максималниот износ на пакетот за одржлива мобилност е 700 евра годишно по вработен. Платениот износ е ослободен од придонеси за социјално осигурување и данок на доход.</p>

<p><strong>Во Белгија,</strong> додатокот за велосипед е надоместок за километража што го доделува работодавачот на вработените кои користат велосипед за да го поминат целото или дел од растојанието помеѓу нивниот дом и работното место. Додатокот е наменет за покривање на трошоците на велосипедистот, но пред се е наменет да поттикне повеќе вработени да ги користат своите велосипеди. Надоместокот за километар е ослободен од данок до 0,25 евра по поминат км за финансиската 2023-та година, согласно приходот од 2022-ра година за плата. Доколку работодавачот додели поголема километража, овој вишок се оданочува како личен доход.</p>

<p><strong>Проверете што постои како опција во вашата земја во оваа насока!</strong></p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing has-scrolled" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>РАБОТНО ВРЕМЕ - Охрабрете Soft Mobility Travel (мека мобилност)</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Работодавачот може да го кредитира времето на мобилност како работно време, но само во случај на патување со мека мобилност - т.е. кој било начин на транспорт што не користи мотор со внатрешно согорување, ниту испушта стакленички гасови. Ова има дополнителна предност што му дава можност на лицето да патува надвор од времето на транспортните тесни грла.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>РЕГРУТИРАЊЕ - Регрутирајте локално</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>При регрутирањето може да го користите растојанието помеѓу домот и канцеларијата како параметар при изборот на кандидатот. Но, ќе биде потешко ова да се примени кога пазарот на труд е мал или кога потребните профили ретко се наоѓаат. Искуството со кое се стекнавме преку работењето од далечина, наметнато од Ковид-19, покажа дека локалното регрутирање продолжува да има смисла само по себе, но е потешко за практикување.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>ДОБРОСОСТОЈБА - Мобилноста е важна</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Мобилноста е еден од столбовите за благосостојбата на работа. Пристапите можат да бидат разновидни. На пример, со промовирањето на возењето на велосипедот, може да се придонесе за подобрување на физичката кондиција, стимулирање на концентрацијата на работа, па дури и поттикнување на активност во заедницата.</p>
</div></div></div>`
        }
    ],
    "sustainableHr": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-752b8e3c-11a1-4c42-973e-dc3ef7f73ad2"><p dir="ltr"><strong><span style="font-size:1.5em;">Вклучете се како стратешки партнер</span></strong></p>

<p>Традиционално, тимовите за управување со човечките ресурси не учествуваат во развојот на стратегии за одржливост - особено во случај на Одговорни ИТ стратегии - ниту во нивното спроведување. Како што е случајот со повеќето „зелени“ иницијативи, врвниот менаџмент има тенденција да поттикнува трендови и да се потпира на средното раководство за нивно спроведување. Меѓутоа, поради нивната импликација во вработувањето на персоналот и нивното влијание врз корпоративната култура, секторот за управување со човечките ресурси може да биде клучен во воведувањето на овие стандарди.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8eb9a5dd-38d3-4496-b38d-d53e12fad37c"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b6c7a88e25.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-365fb268-3738-49d5-bf8b-e5a5432ff57a"><p>Како директор за човечки ресурси, треба да го поддржувате развојот на Одговорни ИКТ стандарди низ организацијата и да соработувате со ИКТ одделот во дефинирањето на задачите, улогите и одговорностите на вработените.</p>


</div>
</div>`,
            "imageSource": "https://nwow4net-zero.eu/images/t3/file-nt1UFmSu.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-ad83967d-c3aa-4c3a-b142-0ba6278bcfe8"><p><strong><span style="font-size:1.5em;">Формализирајте го одговорното ИКТ однесување</span></strong></p>

<p>Формализирајте го Одговорното ИКТ однесување на вработените преку развивање и воведување политики. Овие политики можат да опфатат:</p>

<ol>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Политики за користење на облак, кои дефинираат оптимизирано користење на услугите во облак од страна на вработените, изведувачите и трети страни.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Политики кои бараат од вработените да ги исклучуваат своите компјутери на крајот од денот или бараат од вработените да користат паметни телефони и таблети, наместо десктоп компјутери или лаптопи кои трошат поголема количина на енергија и го зголемуваат јаглеродниот отпечаток.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Политики за одговорно користење на ИКТ опрема. Добри примери се следните политики:</p>
\t</li>
</ol>

<ul>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Спроведување на политиката „Донесете свој уред“ (BYOD).Договорите за вработување би можеле да предвидат вработениот да користи сопствена компјутерска опрема и за тоа да добие компензација.</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Воведете политика за лично користење на корпоративната сопственост, или „личното е дозволено“(COPE). -Вториот лост е употребата на работна опрема за лична употреба дома. Во минатото, беше исклучително ретко компаниите да го дозволат тоа.</p>
\t</li>
</ul>

<p><strong>Во секој случај, овие пристапи гарантираат дека луѓето нема да завршат со работен лаптоп и домашен компјутер. Можно е да се планира двојно подигање за стартување на лаптопот на два начина, едниот за работна, а другиот за лична употреба.</strong></p>



<p><em>Добра практика</em></p>

<p dir="ltr"><strong>Работете преку „план за кафетерија“</strong></p>

<p dir="ltr"><span style="font-size:0.875em;"><em>(ако вашите национални регулативи дозволуваат ваков тип на систем)</em></span></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-c400386d-d3f1-4075-8e89-74d85fed26e6"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b6ea96369c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-96f393ca-7d6a-4bd8-ab36-66d978b4130a"><p dir="ltr">Во Белгија, „ планот за кафетерии “ е програма која му овозможува на вработениот сам да избере одредени елементи од надградбата на опремата, но секогаш вооднапреддефинирана рамка од работодавачот.</p>

<p dir="ltr">Во таква рамка, можете да дадете бефеции за купување на подобрена или реконструирана опрема наместо нови уреди, на ист начин како што можете полесно да субвенционирате електрични велосипеди, отколку големи автомобили.</p>

<p dir="ltr"></p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-EUUxAwta.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-497686b5-fb48-4cd7-83ee-6654c0d12df9"><p dir="ltr"><span style="font-size:1.5em;"><strong>ОБУКА - Намалете ја употребата на ИКТ преку подигање на свеста и обука</strong></span></p>

<p>Обуката е суштинска димензија: Директорите/одделенијата за управување со човечки ресурси треба да обезбедат капацитет кај луѓето во нивната организација да спроведат одговорна дигитална стратегија, да ја разберат, интегрираат и рапределат. Без мозоците и луѓето што стојат зад тоа, одговорната употреба на информациските технологии и дигиталната присебност остануваат пуста желба.</p>

<p>Постојат различни добри практики кои треба да се пренесат, без разлика дали се работи за опрема, користење на видео, па дури и е-пошта.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-b33c71b4-d6d9-4523-a973-e59050b4fc11"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b6f84025db.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-84dfb087-83d8-46cb-a6c5-9d79a26fbeca"><p>На пример, наместо да испраќате документи по е-пошта, подобро е да ги споделите преку заедничка споделена е-папка. Врската до споделен документ им овозможува на луѓето да имаат една копија за работа, наместо да испраќаат коментирани документи едни на други кои се реплицираат на неколку сервери. Кога испраќате е-пошта, наместо обемни списоци на примачи, внимателно изберете ги луѓето кои навистина треба да ја добијат пораката и се засегнати.</p>

<p>Понекогаш се препорачува бришење на е-пошта. Но исто така е точно дека во некои случаи, чистењето на е-поштата може да има поголемо негативно влијание отколку нејзиното задржување - што може да звучи контраинтуитивно! Бришењето на пакети (на пример, целата е-пошта пред одреден датум) е одговорна одлука, која треба да се донесе секогаш кога е можно. Сепак, макотрпното бришење на е-пошта од случај до случај користи многу ИКТ ресурси и станува поскапо за животната средина, отколку нејзиното чување во облак или на сервер.</p>

<p>Важно е да не се испраќаат податоци на прво место ако не се корисни . Испраќањето податоци троши од една до две години во однос на складирање на истите.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-7f6e4a48-84b3-4f31-8416-0206ba746cd6"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b6f743acbb.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d162dbf3-2ecf-4474-b605-3dae52c594d3"><p>Користењето на чет апликација обично ќе биде подобар од е-поштата , особено затоа што дозволува информацијата да се складира на едно место. Но, повторно, се додека се држиме до текстот, ова е маргинално. Поверојатно е дека поставувањето видеа и, во помала мерка, на фотографии да има посериозно негативно влијание.</p>

<p>Пред да креирате ново упатство за обука, најдобро е да проверите дали веќе постојат упатства на темата.</p>

<p>Неколку примери на вештини што треба да се стекнат во обуката:</p>

<ul>
\t<li>Разбирање на глобалните еколошки прашања</li>
\t<li>Разбирање на влијанијата на дигиталната технологија</li>
\t<li>Разбирање за животниот циклус и век на уредите</li>
\t<li>Разбирање на животниот циклус на дигиталната услуга</li>
\t<li>Идентификација на факторите на потрошувачка на веб-страница, во софтвер итн.</li>
\t<li>Идентификација на директните и индиректните влијанија на технологијата</li>
\t<li>Совладување на прашањата и добрите практики поврзани со набавка на опрема, управување со отпад на електрична и електронска опрема (ОЕЕО) и повторна употреба</li>
\t<li>Способност да се спроведе кампања за подигање на свеста внатрешно или со добавувачи и даватели на услуги итн.</li>
\t<li>Пристапност и еко-дизајн на дигитални услуги</li>
\t<li>Способност за мерење на потрошувачката на енергија за време на фазата на користење на дигиталната опрема</li>
\t<li>итн.</li>
</ul>

<p dir="ltr"><em><strong>Извор</strong>: Водич за најдобри практики за одговорни дигитални организации за организации, 2022 година</em></p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-KOyYsXyp.jpeg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d4f52298-4fcd-41dd-bbc1-ff9edd5a07c9"><p><strong><span style="font-size:1.5em;">ПОДАТОЦИ И АНАЛИТИКА - Пракса што треба да се доведе во прашање</span></strong></p>

<p>Функцијата Управување со човечките ресурси не ја избегна возбудата од аналитиката и податоците... Со право?</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-29bc9c0c-9fcc-47fc-adfa-8dc6e8b82f1c"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b6fcf40937.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3afc5921-814b-40b5-be49-8f4c1b457dbe"><p dir="ltr">Според експертите на Institut du Numérique Responsable ова вреди да се доведе во прашање, и тоа од неколку причини. Многу често, сервисите за човечки ресурси користат огромни количини на податоци за да функционираат во рамките на организацијата. Таквите податоци често се повторуваат и се делат и чуваат на различни места. Мора да избегнеме какво било дуплирање на податоците со тоа што ќе се обидеме да имаме централни точки каде што се достапни примарните податоци и каде, кога е потребно, можеме да одиме и да ги најдеме. Тогаш веќе не е потребно копирање, копирање, копирање... а со тоа и множење на количините на податоци, и се разбира зголемување на јаглеродниот отпечаток.</p>

<p>Од друга страна, точно е дека аналитиката на податоци е во пораст во делот на управување со човелките ресурси. Колку е поголема структурата, толку поинтересна може да биде статистиката. Но, во многу случаи, во нашите земји, организациите се мали, со чест на неколку исклучоци. Затоа мораме да ја доведеме во прашање вистинската релевантност на таквите алатки: дали ги користиме затоа што се навистина корисни или затоа што сите други го прават тоа? Да ги намалиме нашите потреби на она што е навистина корисно. Ова е целата вредност на присебноста и тревезноста која важи и за користењето на информациските технологии!</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-fwAS3Cih.png",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6ea068da-64a4-4efe-bb3e-01bf802add29"><p><strong><span style="font-size:1.5em;">ЕВАЛУАЦИЈА И ПРИЗНАВАЊЕ - Вреднувајте ги позитивните акции</span></strong></p>

<p>Вреднувајте ги позитивните акции на вработените. Препознајте ги и наградете ги за да истите бидат поттикнати да продолжат со одговорно ИКТ однесување. Овде директорите/ менаџерите за управување со човечки ресурси се на првата линија. Да престанеме да ги вреднуваме само продажните перформанси, бројот на постигнати милиони, бројот на публикации или следбеници на друштвените мрежи. Постојат и други шампиони.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-791352ae-e018-4f65-a0ea-09af2c26f5f8"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b71b368d4e.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-92591520-9f7c-4687-a25f-cd04c71fdae6"><p>Да ги цениме и позитивните акции во однос на заштитата од климатските промени, општествените прашања, и благосостојбата. Да дадеме извештај за тоа што се прави добро, а што треба да се подобри. Ова значи ре-евалуција на практиките за оценување и давање признавање.</p>

<p>Постои вистинска одговорна дигитална компонента што треба да се вклучи во интервјуата за селекција на нови вработени, како и евалуација на перформансот на постоечките. Прашањата „Колку долго ги имате компјутерот и паметниот телефон?“, „Какви еко-дејства преземате?“, „Кои практики би можеле да ги промените за поодржливо дигитално однесување? ' се прашања кои треба да се постават и да се вклучат во дискусиите. На секое професионално интервју треба да се побараат идеи за активности за одговорна употреба на информациските технологии.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-ZECdRYBX.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4c81c2d8-43d1-4fb3-9ec9-e1e9e578b626"><p><strong><span style="font-size:1.5em;">НАГРАДА - Дали дел од променливата плата треба да се поврзе со имплементација на поодговорни ИКТ практики?</span></strong></p>

<p>Растечкиот тренд смета дека бонусот е очигледно подобар од казната: морковот е подобар од стапот. Наместо да се казнува неодржливото однесување (и да се ризикува да се создаде неангажман, па дури и оставки), секогаш е покорисно за организацијата да ја опфати промената на однесувањето преку позитивната леќа. Затоа, наместо да ги казнувате соработниците кои не се придржуваат до зелено однесување, наградете ги оние кои се придржуваат!</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d23a97ff-921c-43bd-be83-e9d189423939"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b7194630d5.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8b65cbfd-c38d-478a-9698-541665e3f2c6"><p>Од друга страна, со дистрибуцијата на бонуси и казни треба внимателно да се постапува и, пред сè, да не се користи за да се свртат работниците едни против други. Да се каже дека само „најдобрите“ ќе добијат дел од колачот е штетно и нездраво. Ова е особено точно во случај на одговорно зелено однесување бидејќи повеќето од оние кои така се однесуваат не го прават тоа за дополнителен приход.</p>

<p>Дополнително, давањето бонус може да повлече повеќе купувања по патот, што не е добро решение за животната средина. Постојат и други начини за вреднување на општествената вклученост освен финансискиот пристап, и треба да се измислат поиновативни начини за тоа.</p>

<p>Би било многу подобро, одговорното користење на дигиталната технологија да се направи дел од процесот на развој и поттикнување и да се даде можност во процесот за учество на вработените.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-MOtrX9gI.jpg",
        }
    ],
    "hybridInspiringInitiatives": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d50e317c-49f4-4ce7-b827-1e7618ea8802"><p><span style="font-size:1.5em;"><strong>Внесете ги јаглеродните емисии на поединците (вработени од далечина) во пресметката на јаглеродниот отпечаток на компанијата</strong></span></p>

<p>Иако индивидуалниот јаглероден отпечаток не се зема предвид при мерењето на јаглеродниот отпечаток на организацијата, тој треба да се земе предвид при работењето на далечина, бидејќи работните активности се префрлаат во домаќинството на вработениот. Вработените на далечина веројатно ќе го зголемат својот индивидуален јаглероден отпечаток бидејќи трошат повеќе електрична енергија и греење, а во некои случаи овие емисии се зголемуваат повеќе кога се јавува потреба од дополнителен простор за работа во домаќинството, .</p>


</div>`,
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1fe06bb5-7157-48c0-b078-a9a1bbedf826"><p><strong><span style="font-size:1.5em;">Намалете го бројот на квадратни метри при селидба и воведете нови технологии за греење, ладење на зградата</span></strong></p>

<p>Воведувањето нови начини на работа всушност значи намалување на работната сила во канцелариите. Постојните канцеларии може да се реновираат и канцеларискиот простор може да се намали во однос на квадратни метри. Ова е исто така добра можност да се инвестира во енергетски поефикасни технологии за греење и ладење.</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-2e6c4420-0191-429c-ae35-3c163eee07a7"><p><strong><span style="font-size:1.5em;">Затворете го седиштето на компанијата еден ден во неделата</span></strong></p>

<p>Понудете алтернативни решенија за оние кои не се заинтересирани за работа од дома, со работа во канцелариите на компанијата кои остануваат отворени.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-9bb64ff6-9682-46c1-b4d0-851b3c36cfb3"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-659e73b7cfb38.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-fe2e9f0a-6c9f-4004-a698-7d8150687ee6"><p><strong>Пример:</strong></p>

<p><em>Во мај 2022 година, белгиската осигурителна компанија Ethias ги затвори своите национални канцеларии (Лиеж и Хаселт) еден ден во неделата и ги насочи своите вработени да работат од далечина. Притоа, компанијата овозможила работа од далечина до 3 дена во неделата, односно 60% од работното време. Оваа мерка, која е иновативна на белгискиот пазар, му овозможува на Ethias да ја намали својата зависност од фосилни горива. Таа искача на врвот на бројните иницијативи спроведени во текот на изминатите неколку години, кои му овозможија на Ethias да го намали својот јаглероден отпечаток за една третина, четири години пред предвиденото.</em></p>

<p><em>Иако пристапот кон “повеќе работа од далечина” е во согласност со желбите на огромното мнозинство вработени, оние кои не сакаат да го сторат тоа, сè уште имаат можност да го одработат својот работен ден во некои од другите згради. „Пред да го прифати  пристапот за работа со доаѓање во просториите за работа, Ethias имаше долг преглед и проценка на неколку сценарија за да се обезбеди дека вработените со своето доаѓање не ја загрозуваат околината. Според податоците на јаглеродните емисии, еколошката добивка од работата од далечината е позитивна штом патувањето работа-дома е поголемо од 2 километри, што е случај за повеќе од 90% од вработените на Ethia.</em></p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-Ux4xbbe0.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3316ea8d-10cc-4b2e-8619-da7fe9bbc817"><p><strong><span style="font-size:1.5em;">Развој на апликација за селекција на канцелариско место пред доаѓање на работа</span></strong></p>

<p>За да биде хибридната работа ефикасна, организацијата треба да обезбеди алатки кои ќе го олеснат користењето на канцеларискиот простор во деновите кога работниците доаѓаат во канцеларијата. Развивањето апликација за работниците да ја изберат канцеларијата што ќе ја заземат пред да дојдат на работа е добро решение. Постојат апликации достапни на Интернет кои лесно можат да се персонализираат за просторот што го зазема организацијата.</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c6362667-2265-4e67-bf80-74a50e2a5925"><p><strong><span style="font-size:1.5em;">Предлог: можност работодавачот да инвестира, со надомест кој не се оданочува, во решенија кои го намалуваат јаглеродниот отпечаток на вработените</span></strong></p>

<p>Можеме да замислиме да конвертираме еден дел од платата коај денес се оданочува, за плаќање на фактура за инсталација на геотермичка инсталација, соларен панел, реупотреба на вода или слична инвестиција во куќата, живеалиштето на вработениот.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d7c13367-6a61-421e-a373-eebd7190de95"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650ae2a5b19be.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-GwVFSFMm.jpg"
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-706a5767-ffee-4003-b5a9-c2fd99b9978d"><p><strong><span style="font-size:1.5em;">Користете игри</span></strong></p>

<p>Воведете ја играта „Power Ranger“ за мобилизирање на вработените за намалување на потрошувачката на енергија.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-49e80623-fa73-4a32-ad4b-77f75ce8a5c4"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a245a56b04.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-EUWiWwyc.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5ea9601e-15c0-41fa-b006-440a9fd9e737"><p><strong><span style="font-size:1.5em;">Понудете индивидуално советување</span></strong></p>

<p>Помогнете му на секој човек, бидејќи секој има различна ситуација - од помош како да се промени начинот на транспорт, до советување за промена по негова динамика - со малку финансиски поттик.</p>
</div>`
        }
    ],
    "mobilityInspiringInitiatives": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Отстранете ги местата за паркирање</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>За да се обесхрабри употребата на автомобили за да се оди на работа, некои компании ги отстранија резервираните паркинг места во компаниите. Ако на сите вработени им е речиси загарантиран просторот за паркирање, тие ќе го користат автомобилот многу повеќе за да патуваат до работа, отколку ако не го сторите тоа.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Олеснете ја заедничката мобилност</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Една бразилска енергетска компанија смислила апликација за да ја олесни споделената мобилност до нејзината локација во Сао Паоло: корисниците би ја внеле нивната рута за патување, а апликацијата би ги вкрстила податоците со сите други влезови и ги воведувала вработените кои следат слични рути. Заедничката мобилност се зголеми за 18% од транспортот и заштедила на компанијата 300.000 УСД годишно.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Охрабрете го  возењето  на велосипед</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Поиновативните добри практики меѓу студиите на случај, вклучуваат обезбедување на подобра безбедност за велосипедите (индивидуално заклучувачки паркинг простори; полициско обезбедување или понуди за осигурување за велосипеди); заеми за велосипедска опрема (батерии, светла, пумпи, брави, шлемови, детски седишта итн.) мапи за специфични локации; финансиски стимулации и бесплатни производи (на пример, понуда на заштитни јакни).</p>

<p>Неколку примери за позитивни активности: партнерство за изнајмување на товарни велосипеди за испорака или нивна употреба од страна на технички работници, кампања за позајмување електрични велосипеди за да им се овозможи на вработените да ја тестираат, апликацијатаза мерење на јаглеродна неутралност прекуакцијата „со велосипед до работа“ и учество во различни други предизвици.</p>


</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content overflowing has-scrolled" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Децентрализирани заеднички канцеларии</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Пилот-проект за децентрализиран заеднички канцелариски простор со цел да им се овозможи на оние кои не можат да работат од далечина, во некои денови да не мора да доаѓаат до централата, ако живеат далеку или ако им е премногу комплицирано да до доагаат до најблиската канцеларија. Спроведено е со оператор за ко-соработка, преку централизиран систем за функционирање.</p>

<p><strong>Направете го возот поконкурентено транспортно средство</strong></p>

<p>Пример за организациски политики за деловно патување:</p>

<ul>
\t<li>
\t<p>Распределете средства за компензација на пониска цена за авионот, со цел возот да стане конкурентен и користен</p>
\t</li>
\t<li>
\t<p>Не е дозволено да се користи авион за патувања помали од 2 часа каде може да се патува со воз.<br>
\t</p>
\t</li>
</ul>
</div></div></div>`
        },
        // {
        //     "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
        //     "heading": "Направете го возот поконкурентен транспорт",
        //     "description": "Политика за деловно патување:\n" +
        //         "распределба на средства за компензација на повисока цена на авионот, со цел  возот да стане конкурентен и користен\n" +
        //         "Не е дозволено да се користи авион за патувања помали од 2 часа што може да се направат со воз\n"
        // },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-bd0d1aab-e9f3-4954-82d7-5ca803053822"><p><strong><span style="font-size:1.5em;">Пристап “плаќaш колку што трошиш”</span></strong></p>

<p>Пристап „Плаќаш колку што трошиш“ за службените автомобили: луѓето го контролираат тоа што го трошат преку добивање на надомест по километар за тоа не е потрошено или плаќаат надомест за премногу потрошеното кое влијае и на цената на лизингот и на буџетот.</p>


</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/image-u29XBURD.jpg"
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6a2b61ed-ff09-4ac7-b91e-57a126803fc6"><p><strong><span style="font-size:1.5em;">ДОБРА ПРАКТИКА</span></strong></p>

<p><strong><em>Solvay (Солвеј) лансираше внатрешната цена на јаглеродот за патување на компанијата.</em></strong></p>

<p><strong><em>Солвеј покрена нова иницијатива за следење на јаглеродните емисии од патувањата на компанијата, преку собирање средства за поддршка на проекти за одржливост на групацијата и градење свест за одговорно и одржливо патување. Иницијативата имаше за цел да го истакне индивидуалниот јаглероден отпечаток на секој вработен.</em></strong></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-729c6f13-e17a-4f6e-9735-eca08661ed72"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650ae6a3ab523.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c18ce683-799a-4f5b-8956-09bf30e83725"><p><em>Од 2019-та година, Солвеј ја намали вкупната употреба на јаглерод поврзана со воздушните и железничките патувања за скоро 70%,. Истото во голема мера се должеше на пандемијата Ковид-19, но исто така и на имплементацијата на “Новиот начин на работа на Солвеј”, каде што со дигиталните алатки се направија виртуелните посети, лесно достапни за сите.</em></p>

<p><em>Групацијата Солвеј сега е фокусирана на одржување на овие нови однесувања и минимизирање на јаглеродните емисии на компанијата за патување преку закажување ма помалку физички состаноци, намалување на бројот на меѓународни летови, намалување на еднодневните воздушни патувања, преку препорака за префрлање од бизнис во економска класа или преку препорака да користење на авион да се замени со железнички сообраќај секаде каде постојат тие алтернативи. Овие мерки се нагласени како начин да се подигне свеста за сечиј индивидуален јаглероден отпечаток во компанијата.</em></p>

<p><em>Патувањето на Солвеј се финансира од фонд кој се користи за спонзорирање на проекти за одржливост со фокус на јаглеродното намалување каде што истото е изводливо. Глобалните деловни единици придонесуваат со паричен износ врз основа на патните емисии на ентитетот пресметани по 100 евра од тон CO2. Тој износ му се наплаќа на секој ентитет и се префрла на Фондот за придонеси за намалување на јаглеродните емисии при патувањата на групацијата.</em></p>

<p><em>Овој фонд за придонес е стремеж кон исполнување на целите за одржливост на Обединетите нации и охрабрување за секој вработен да даде свој придонес во заштита на планетата земја.</em></p>
</div>
</div>`
        }

    ],
    "sustainableInspiringInitiatives": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3f37caaa-982f-468c-be2a-72514d04656a"><p><strong><span style="font-size:1.5em;">Создадете приказна</span></strong></p>

<p>Создавањето приказна може да ја поттикне општествената ориентација и да ги поттикне вработените. Спроведувањето на различни дејства, како што е намалувањето на пораки во е-поштата, може неправедно да се разбере како зелено перење. Затоа е неопходно да се создаде кохезивен наратив во рамките на компанијата. Треба да имате вистинска приказна за раскажување, онаа која отелотворува посветен, системски и долгорочен пристап. Овој наратив, се разбира, мора да започне со мали чекори, но треба да има и поголема амбиција.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6326cb82-49ee-40c6-8a63-fa8377d14d27"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b7c89cd21f.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-fmT9o4iB.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d67971a-f08c-4bc2-8cb9-0d43903d2d07"><p><strong><span style="font-size:1.5em;">Развијте мрежа на „одговорни дигитални“ референти во рамките на организацијата</span></strong></p>

<p>Таквата мрежа им овозможува на првите корисници (пионери) да бидат најдобри амбасадори на вашата политика. Тие имаат подобро познавање на нивните организации, особено ако се распространети на неколку локации. Овие пионери се обучени и затоа тие се здобиваат со вештини и знаат како да ги мобилизираат ресурсите што им се на располагање за да внатрешно ги циркулираат информациите.<br>
</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-98d6b18e-4994-4314-bbaf-bf7d295acc68"><p><strong><span style="font-size:1.5em;">Ограничете ја геолокализацијата</span></strong></p>

<p>Ресурсите за геолокација (како Google Maps и Waze) го револуционизираа патувањето, но тие можат да потрошат значителни количини на податоци поради долгите патувања и општата прекумерна употреба. Совети за корисниците:</p>

<ul>
\t<li>
\t<p>Ограничете ја употребата на апликации за геолокација кога патувате на претходно посетени дестинации.</p>
\t</li>
\t<li>
\t<p>Користете само една услуга за геолокација.</p>
\t</li>
\t<li>
\t<p>Исклучете ги мобилните податоци кога не е потребна геолокација (како на пример за време на долго возење на автопат).</p>
\t</li>
\t<li>
\t<p>Каде што можнете, користете офлајн опции за гео-следење.</p>
\t</li>
</ul>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d5f8a0b6-ec6a-489d-b2d9-dd8db9bfbe80"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b7d2fccf93.png" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-LGlALooC.png",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-7e55a634-d22d-4d4b-a781-e9a043b632be"><p><strong><span style="font-size:1.5em;">Променете ги поставките на вашите печатари</span></strong></p>

<p>Брза победа во однос на потрошувачката на енергија може да се постигне преку управувањето со флотата на печатачи. Промената на поставките за режим на подготвеност, ниска енергија или режим на мирување е интересна и ќе резултира со заштеда на енергија. Преку користење на контролните табли за потрошувачка на енергија на печатачот достапни за секого, ова е конкретна, „опиплива“ работа што секој може да ја види. Видете ја студијата Cap Gemini за одржлива ИТ: <a target="_blank" href="http://www.capgemini.com/fr-fr/wp-content/uploads/sites/6/2022/04/Final-Web-Report-Sustainable-IT-1.pdf">www.capgemini.com/fr-fr/wp-content/uploads/sites/6/2022/04/Final-Web-Report-Sustainable-IT-1.pdf</a>)</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-f6daacdd-2479-475f-9e8b-a567f54b59e3"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650b7d52602b9.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-X9LcEPlf.jpg",
        }
    ],
    "hybridRecommendedActions": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3427f056-8466-4a95-adec-01f1e32a4592"><p><span style="font-size:1.5em;"><strong>Реорганизирање на работните простори за да трошат помалку енергија</strong></span></p>

<p>Тоа може да се направи на неколку начини, како што се условите во кои се произведува опремата, изборот на снабдување со енергија и регулирање на осветлувањето, видот на материјалите што се користат за застаклување, употребата на топлинска изолација, имплементацијата на системи за греење кои овозможуваат повеќе насочена употреба итн.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6a1ff1da-bea0-45f6-96ee-7326ac1f3395"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650ae72798dfa.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-e5jYUunU.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d7f97920-3d31-4127-9d19-2a50d6307c52"><p><strong><span style="font-size:1.5em;">Приспособување на системите за работа според активностите</span></strong></p>

<p>Ова значи да се размислува однапред за тоа како да се организира работата и видовите на активности што треба да се спроведат. Обезбедете предвидливост на практиките за работа на далечина за да може да се предвиди кога вработените работат или не.</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d04f5047-e710-4960-aea1-5f2dd5c634c4"><p><span style="font-size:1.5em;"><strong>Избегнувајте го пристапот „една решение одговара на сите“</strong></span></p>

<p>Она што може да биде корисно во една компанија, може да биде многу помалку спроведливо на ниво на урбан центар - на пример, ако вториот опаѓа или се испразни поради напуштањето на работниците... Слично на тоа, не сите вработени од далечина се исти: некои имаат посветен работен простор, а некои не. Цената на енергијата се покажува како важна променлива при изборот на работа од дома или од канцеларија. Оваа одлука исто така зависи и од времетраењето на патувањето.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-54956a77-38f6-4873-8ff7-904c45dc5241"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650ae952abe3a.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-5SruR9UH.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c741d811-2784-453f-ae4a-6f38fd9c9466"><p><strong><span style="font-size:1.5em;">Добрите идеи често доаѓаат од оперативниот персонал бидејќи тие се најблиску до вистинската работа</span></strong></p>

<p>И тоа е местото каде што навистина може да имате влијание. Сепак, предизвикот е да се одржи конзистентноста. Доколку партиципативниот пристап е изолиран или се чини дека е изолирана иницијатива, без да биде дел од поширока стратегија; или ако корпоративната порака е дека животната средина е навистина важна, но за тоа има мал буџет, пристапот нема да биде веродостоен. Пазете се од “greenwashing”!</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<p><strong><span style="font-size:1.5em;">Затворете ги канцелариите во одредени денови од неделата за да заштедите енергија</span></strong></p>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-cfe59f92-0f7d-4a1b-8a5b-4dc310c7c7b8"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650ae9e7ad642.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3a5a13fb-d0a4-4437-87c0-8cca2baa698a"><p>Ако многу работници имаат тенденција да работат од дома во одредени денови, организациите може да размислат за затворање на канцеларијата на тој ден. Се препорачува да се изберат последователни денови на затворање бидејќи така се заштедува повеќе енергија. На пример, затворањето во понеделник после викенд ја намалува енергијата што се користи при вклучување и исклучување на системите што трошат енергија во канцеларијата (т.е. греење и ладење и сл.).</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-ynBuLM58.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-93509733-1264-40a8-a25b-183892b8d10d"><p><strong><span style="font-size:1.5em;">Комбинирајте работа од далечина, флексибилна канцеларија и намалување на канцеларискиот простор</span></strong></p>

<p>Студијата на АДЕМЕ (Франција) покажува дека кога се земаат во предвид намалувањата за недвижен имот што ги носи работата од далечина заедно со флексибилната канцеларија (практика да нема посебна канцеларија за работно место), се подобрува севкупната еколошка рамнотежа на работата дури за +52% по ден работа на далечина во неделата (т.е. -234kg eqCO2/годишно за секој дополнителен ден на далечина неделно), и се консолидираат предвидените еколошки придобивки. Еколошките придобивки од флексибилната канцеларија можат да ги надминат придобивките од мобилноста т.е. доаѓањето на работа.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-2d3e4c85-3a9c-4d3a-a7e2-d1ea74bf3ce5"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650aea5b69adc.png" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-BKcUY47M.png",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d25f0f5-0bf7-4f85-b059-69b9e42fb240"><p><strong><span style="font-size:1.5em;">Не поттикнувајте полудневна работа од дома</span></strong></p>

<p>Работата од далечина и работата од дома, во комбинација со работата на трето место доведува до значително негативен биланс за животната средина.</p>


</div>`,
        },
        // {
        //     "roles": [roles.ChangeAgent, roles.StrategicPartner],
        //     "heading": "Поттикнете регрутирањето на вработени чии домови се блиску до работното место",
        //     "description": "",
        //     "imageSource": "https://nwow4net-zero.eu/images/t1/file-hamU1j0R.jpg",
        // },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-cfa3ffa5-6b28-41ef-aa8a-4bc8bde9fc55"><p><strong><span style="font-size:1.5em;">Бидете внимателни со работата од далечина!</span></strong></p>

<p>Студијата на АДЕМЕ (Франција) покажува дека работата од домаја намалува „канцелариската“ потрошувачка (хартија, мастило, материјали, чаши, украси, видео проектори итн.) и потрошувачката на енергија на компанијата. Сепак, некои од овие намалувања се пренесуваат на работникот кој работи од дома. Особено се зголемува потрошувачката на енергија во домот. Студијата проценува дека потрошувачката на енергија во домот за време на еден работен ден од далечина би се зголемила за +20,7 kg eqCO2 во текот на година со еден ден неделно работа од далечина за вработениот.</p>


</div>`
        }
    ],
    "mobilityRecommendedActions": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f3a1154d-cf21-4744-8f37-5b942ddae908"><p><span style="font-size:1.5em;"><strong>Воспоставување на мерење... односно подобро е да напредуваш со несовршени мерки...</strong></span></p>

<p>Бидејќи чекањето за совршенство е најдобриот начин да не правиш ништо…</p>
</div>`,
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9c17bf2a-baba-43d2-a449-63d3a162ff6a"><p><span style="font-size:1.5em;"><strong>Изберете локација на компанијата достапна до одржливи транспортни средства</strong></span></p>

<p>Компаниите немаат капацитет да регрутираат само локално, ниту да наметнуваат локации за живеење на своите вработени, освен преку обезбедување на сместување како дел од договорот за вработување. Тие не можат да влијаат на растојанието од дома до работа, но можат да изберат локација која е достапна за нивните вработени со користење на поодржливи средства за превоз од автомобилот.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-32b491be-187b-4b28-b25e-c93cdd367c4c"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650ae7da4ccaf.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-r5ICVTKu.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42a6405c-6e2a-4f70-b7d8-501f3b5e3f66"><p><strong><span style="font-size:1.5em;">Планирајте, работете, активирајте се</span></strong></p>

<p>Првиот столб е дијагностицирање и дефинирање на стратегијата. Потоа доаѓа имплементацијата со услуги за мобилност, управување со податоци, финансиски мониторинг, инфраструктури итн. Повеќето компании застануваат тука и потоа гледаат дека тоа не функционира.</p>

<p>Зошто? Едноставно затоа што недостасува димензијата за промена на однесувањето и мотивирање.</p>

<p>Сето горенаведено е неопходно, но не е доволно. Мора да се „активирате“ зада имате влијание врз вработените. Кога некоја компанија ќе ви каже дека поставила десет електрични велосипеди во приемниот дел, но никој не ги користи, недостасува овој чекор: активирање. Не сте ги мотивирале луѓето. Нивното мотивирање може да се направи на различни начини, како организирање на игра со награда за оние кои најмногу ги користат велосипедите.</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-7565574d-621a-4f00-b920-c201f9d2f507"><p><strong><span style="font-size:1.5em;">Стекнете разбирање за долгото патување или патувањето на долги растојанија</span></strong></p>

<p>Случаите со двојни резиденции поврзани со работа - или со второ живеалиште, или во хотел, или дури и користење на AirBnB... двојни резиденции поврзани на луѓето со две работни места во два различни града; многу чести патувања поврзани со работа, итн, заслужуваат да се земат во предвид, од гледна точка на човечки ресурси, а уште повеќе кога има преселба на ново работно место во однос на влијанието врз патувањето до работа и воспоставување на неопходна поддршка за истото.</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-80c0adf5-cab3-4fbb-98bd-c46a956d2977"><p><strong><span style="font-size:1.5em;">Не се фокусирајте само на буџетскиот аспект</span></strong></p>

<p>Логиката позади изборот на транспортното средство вклучува и брзина (ако се потребни 1,5 часа со јавен превоз, наспроти 40 минути со автомобил, парите нема да имаат големо влијание). И многу други критериуми се вклучени како удобност т.е. целото искуство на патувањето: возот има компаративна предност ако во него можете да правите други активности - спиење, читање, работа итн. Но, таа предност на пример, се поништува ако возот е преполн и немате место, или се поништува од искуството што го имате за различните начини на транспорт, навики, безбедност итн. Размислете за политика за мобилност која ги интегрира сите димензии (трошокот, брзината, удобноста и сл.) така што алтернативите да станат привлечни.</p>

<p>Префрлете се од буџет за мобилност од 500 евра, на буџет за мобилност од по x килограми CO2 и соодветно распределете го буџетот за мобилност на вработените, дадете можност да се потикне и тргување помеѓу колегите како на пример размена на заштедите за слободни денови.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6594bb16-8d4a-40a3-baae-b2c961991ac6"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650ae87fc0ae4.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            "imageSource": "https://nwow4net-zero.eu/images/t2/file-HA5eJBjj.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-db56f5ca-9960-46ca-b07c-3aa08b24a38f"><p><strong><span style="font-size:1.5em;">Поттикнете мултимодалност</span></strong></p>

<p>Промовирајте промена, така што луѓето ќе ја минимизираат улогата на автомобилот во нивните патувања. Луѓето кои живеат во ретко населени, периферни-урбани области, можеби ќе можат да ја намалат употребата на автомобилот ако користат и други начини на транспорт покрај автомобилот.</p>

<p>Потребите ќе наложат префрлување кон зголемен, поширок, а не на намален автомобил: или доколку имате службено возило, евентуално помало, но да биде со подобри еколошки перформанси. Да им се даде на луѓето можност да патуваат поинаку. Ова не секогаш бара зголемување на понудата, туку само договор за олеснување на пристапот до јавниот превоз или други форми на мобилност.</p>

<p>Денес се нудат многу мултимодални решенија.<br>
</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-2b595af0-7cfc-41da-b1a7-f849be29cfe8"><p><strong><span style="font-size:1.5em;">Автомобили од компанијата: обрнете внимание на…</span></strong></p>

<p>Ако компанијата чувствува обврска да обезбеди службени автомобили (за привлекување таленти, задржување вработени, статус, застапеност), обрнете внимание на типот на возилото, дури и ако знаеме дека влијанието на електричното возило врз животната средина не е неутрално.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e2b6406f-ba7a-45b7-83b8-84806274d67f"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650ae8f013a9d.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-DgZHPTOT.jpg"
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5821544b-625c-4c3a-a64e-9ddf0f3eeb2f"><p><strong><span style="font-size:1.5em;">Справете се со паркинг местата</span></strong></p>

<p>Ако компанијата нуди паркинг простор, таа на некој начин им плаќа на вработените да го користат својот автомобил. Она што ни треба е едноставно да ги избалансираме предностите на различни видови транспорт: ако патувате со велосипед, не заземајте го местото за паркирање. Во таа насока ако заземете помалку простор, компанијата би можела да ви додели одредена сума - на пример 200 евра - затоа што заштедувате пари на компанијата доаѓајќи со велосипед.</p>

<p>Посочете кон резервираните паркинг места за менаџерите, кои генерално се секогаш подобро лоцирани. Оваа хиерархиска предност не ја одразува амбицијата на компанијата за мобилност и ќе биде помалку интензивна кон намалувањето на јаглеродните емисии. Ако има паркинг, просторите најблиску до влезот на канцеларијата треба да бидат резервирани за тие кои споделуваат автомобил за да дојдат на работа.</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d56820b5-cdac-4d65-b9c3-e7e19d058c6a"><p><strong><span style="font-size:1.5em;">Најдете „внатрешни амбасадори“</span></strong></p>

<p>Работете со пилот проекти, со мали групи амбасадори подготвени да ја играат оваа улога. Не ја револуционизирајте мобилноста во вашата компанија; започнете со луѓе кои се подготвени, и со мали иницијативи кои ќе никнат и ќе растат. Мобилизирај го врвниот менаџмент: кога извршниот директор и врвните менаџери се качуваат на својот велосипед и го прават тоа редовно, тоа има голем влијание врз останатите во организацијата.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-96546409-df37-40a1-8000-e20cc49697d3"><img src="https://lp.youngkreativ.eu/pluginfile.php/400/mod_hvp/content/102/images/file-650ae968de311.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-bJXBYOuI.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3c1ff5d8-04bb-4246-b817-45823add9089"><p><strong><span style="font-size:1.5em;">Клучен збор: соработка</span></strong></p>

<p>Пристапот од Kiba-Janiak и Witkowski укажува дека клучен показател за успешен план за мобилност е соработката со мрежа на засегнати страни, кои може да бидат јавни или приватни, и ја најавува како особено својствена на намалувањето на емисиите на стакленички гасови.</p>




</div>`
        }
    ],
    "sustainableRecommendedActions": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-5a468dcd-341e-4c76-a18e-49a772df8881"><img src="https://lp.youngkreativ.eu/pluginfile.php/401/mod_hvp/content/103/images/file-650afa383a24c.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c9054d6f-c1fd-4779-9615-5a933109a477">

<p><strong><span style="font-size:1.5em;">Бидете внимателни со работата од далечина</span></strong></p>



<p>Студијата на ADEME во Франција покажува дека позитивните ефекти од намаленото патување се модулираат со значителни ефекти за враќање. Ефектот на враќање е опишан како неповолен поради четири механизми, вклучително и зголемување на видео тековите главно поврзани со видео конференции и нова потрошувачка на енергија во домот (греење, осветлување, компјутери итн.). Студијата, исто така, укажува на нови системски или долгорочни ефекти за враќање што треба да се проценат и следат, како што е зголемувањето на дигиталната опрема.</p>

<p>Влијанието на работата од далечина ќе биде позитивно само ако организацијата на друг начин го намали обемот на искористениот работен простор. Доколку просториите продолжат да бидат оддржувани, загревани и осветлувани при хибриден работен режим, работата од далечина не претставува вистинска добивка.</p>




</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-8IE7xrpv.jpeg",
        }
    ],
    "foreword": [
        {
            roles: [],
            "heading": "",
            "description": "Новите начини на работа (NWoW) опфаќаат практики на просторно-временска флексибилност - работа на далечина, планирање на работниот простор, флексибилно работно време итн, практики на управување - автономија, доверба, учество и воведување на (семи) автономни тимови итн.\n" +
                "\n" +
                "Во флексибилната работа ќе се најдат такви премостувања (начини) кои ќе придонесат за намалување на CO2 емисиите, преку работењето во работните средини."
        }
    ],
    "hybridLearningObject": [
        {
            "roles": [],
            "heading": "",
            "description": "Целта е да се поттикнат професионалците за човечки ресурси да го водат патот кон создавање хибридна работна средина која не само што ги поддржува организациските цели туку и позитивно придонесува за одржливоста на животната средина.\n" +
                "\n" +
                "1. Компетентност за лидерство и стратегија за хибридна работа:\n" +
                "• Развијте ја способноста за ефективно водење и стратегии во хибридна работна средина, фокусирајќи се на интеграцијата на принципите за одржливост. Ова вклучува стекнување вештини за соработка со лидерски и меѓуфункционални тимови за да се вградат одржливи практики и во далечински и во канцелариски поставки.\n" +
                "• Научете да креирате стратешка визија која ги усогласува хибридните работни модели со целите за одржливост на организацијата.\n" +
                "• Подобрете ги компетенциите во дизајнирањето хибридни работни политики и практики кои го намалуваат јаглеродниот отпечаток на организацијата, како што е оптимизирање на распоредот за работа од далечина за да се минимизира потрошувачката на патување и енергија.\n" +
                "• Добијте увид во оценувањето и изборот на канцелариски локации врз основа на пристапноста до одржлив транспорт и потенцијалот за намалување на емисиите при патување.\n" +
                "2. Одржливо управување со човечки ресурси во хибридни средини:\n" +
                "• Негувајте вештини за управување со човечките ресурси на начин кој ја поддржува и ја подобрува ефективноста на хибридните работни модели, а истовремено промовира одржливост. Ова вклучува развивање и имплементирање на политики за човечки ресурси кои поттикнуваат еколошки работни практики и во далечински и во канцелариски поставки.\n" +
                "• Научете да ја интегрирате одржливоста во регрутирањето, обуката и управувањето со перформансите, осигурувајќи дека персоналот е опремен и мотивиран да се вклучи во одржливи практики без оглед на нивната работна локација.\n" +
                "• Зголемете ги способностите за користење на дигитални алатки и комуникациски стратегии за ефективно ангажирање и поддршка на вработените во хибридна работна поставеност.\n",
        }
    ],
    "mobilityLearningObject": [
        {
            "roles": [],
            "heading": "",
            "description": "Општата цел е да ги поттикне професионалците за човечки ресурси да имплементираат практични, иновативни решенија за мобилност кои придонесуваат за намалување на емисиите на јаглерод на компанијата и подобрување на севкупната одржливост.\n" +
                "1. Ангажирање на засегнатите страни и стратегиско управување со мобилноста:\n" +
                "• Развијте компетенции за ефективно ангажирање со различен опсег на засегнати страни, вклучувајќи извршни директори, врвен менаџмент, сектори за финансии и јавни власти за креирање и спроведување на стратешки планови за мобилност. Ова вклучува разбирање на значењето на одлуките за деловна локација во однос на базенот за вработување и пристапноста на јавниот транспорт, и нивното влијание врз јаглеродниот отпечаток на компанијата.\n" +
                "• Стекнувајте вештини за водење и влијание врз врвниот менаџмент за усвојување позитивен пристап кон работната мобилност, надминувајќи ја само усогласеноста за активно интегрирање на мобилноста како дел од работното искуство.\n" +
                "• Научете да конструирате и презентирате привлечни деловни случаи на финансиските одделенија, покажувајќи го директниот и значителен поврат на инвестицијата на проектите за одржлива мобилност.\n" +
                "• Подобрете ги способностите за поставување ефективни комуникациски стратегии, користејќи алатки како гејмификација и континуирано испраќање пораки, за да го промените однесувањето на вработените поврзано со мобилноста.\n" +
                "2. Оперативна имплементација и иновации во мобилноста:\n" +
                "• Научете како да имплементирате стратегии за мобилност преку назначување и обука на менаџери за мобилност кои можат да водат проекти низ различни оддели.\n" +
                "• Научете да собирате и анализирате податоци за тековните практики за мобилност и индивидуалните преференции за патување за да ги приспособите решенијата за мобилност.\n" +
                "• Развијте вештини за иницирање партиципативни пристапи, создавање внатрешни амбасадори и поттикнување на соработка меѓу вработените за промовирање на одржливи методи за патување.\n" +
                "• Разберете ја важноста од решавањето на практичните аспекти како што се политиките за паркирање автомобили и охрабрувањето на алтернативи како возење велосипед, здружување со автомобил и користење на јавен превоз.\n" +
                "• Добијте увид во искористувањето на технолошките алатки како што се мултимодалните платформи за патување и имплементацијата на пакети за одржлива мобилност за да се поттикне еколошки патување. \n" ,
        }
    ],
    "sustainableLearningObject": [
        {
            "roles": [],
            "heading": "",
            "description": "1. Стратешко лидерство и управување во дигиталната трезвеност:\n" +
                "• Развијте вештини за застапување и зајакнување на важноста на одговорното дигитално однесување во организацијата, соработувајќи со извршните директори, врвниот менаџмент и директорите на CIO/IT.\n" +
                "• Развијте вештини за влијание и вградување на одржливоста во визијата и етиката на организацијата, конкретно во врска со употребата на дигитални технологии.\n" +
                "• Научете да го водите врвниот менаџмент во интегрирањето одговорни дигитални критериуми во управувањето на компанијата, осигурувајќи дека одржливоста е суштинско внимание во секој проект поврзан со ИТ.\n" +
                "• Научете да практикувате и промовирате примерност, каде што лидерите „разговараат“, поставувајќи јасни примери за одговорни дигитални практики.\n" +
                "• Стекнување вештини за координација со ИТ одделенијата за да се развие одговорна ИТ стратегија која го ограничува влијанието врз животната средина додека им служи на потребите на луѓето, фокусирајќи се на аспекти како Зелена ИТ, Човечка ИТ и ИТ за човек. Разберете го значењето на работата со набавки и добавувачи на хардвер за ИТ за да се имплементираат одржливи практики во производството, употребата и рециклирањето опрема.\n" +
                "2. Оперативна имплементација и одговорни дигитални практики:\n" +
                "• Развивање на вештини за спроведување на оперативни стратегии за одговорно дигитално однесување. Научете да работите со набавки за да го продолжите животниот век на ИТ опремата и да промовирате одржливо користење.\n" +
                "• Стекнување на компетенции за ангажирање на добавувачи на ИТ и телеком хардвер за енергетски ефикасни производи и едукација на персоналот за нивните придобивки. Разберете како да ја подигнете свеста кај персоналот за прашањата за одржливост поврзани со дигиталните технологии, промовирајќи култура на дигитална трезвеност.\n" +
                "• Стекнете експертиза во охрабрувањето на персоналот да усвои одговорно дигитално однесување, како што се оптимизирано користење на облакот, одговорни практики за е-пошта и внимателна видео-конференција. Научете да ги формализирате овие однесувања преку политики и да ги интегрирате во практиките за човечки ресурси како обука, евалуација и признавање.\n" +
                "• Развијте способност за мерење на дигиталниот еколошки отпечаток на организацијата и поединечните вработени и да ги користите овие податоци за да поттикнете континуирано подобрување во практиките на дигитална трезвеност."
        }
    ],
    "inner_cont_title": "Самооценување",
    "st_2_title": "Сега да ја одредиме вашата доминантна улога на човечки ресурси во организацијата / компанијата",
    "st_2_p1": "Оваа алатка може да го дефинира вашиот индивидуален пат на учење инспириран од моделот „HR Business Partner Model“ на Дејв Улрих (Улрих, 1997), бидејќи обезбедува рамки и насоки за професионалците за човечки ресурси да ги усогласат своите активности со деловните цели, да ги поттикнат организациските промени и да создадат вредност во рамките на нивните организации со цел да го максимизираат влијанието и ефективноста на нивната функција за човечки ресурси.",
    "st_2_p2": "Ова усогласување осигурува дека иницијативите и практиките за човечки ресурси директно ги поддржуваат целите на организацијата и ги подобруваат севкупните перформанси. Моделот сугерира дека професионалците за човечки ресурси треба да играат четири клучни улоги: стратешки партнер, административен експерт, шампион на вработените и агент за промена (Улрих, 1997; Улрих и Брокбанк, 2005). Описот на овие улоги е даден на следната табела.",
    "st_3_title": "УЧИМЕ ЗА НОВИ НАЧИНИ НА РАБОТА ЗА НЕТО НУЛА ЕМИСИЈА ВО КОМПАНИИТЕ",
    "st_3_p1": "Постојат три начини како HR може да придонесе:",
    "st_3_title_2": "За да го одредите вашиот пат за учење, ве молиме изберете ја вашата област на интерес:",
    "st_3_bullet_1": "да изберете некои или сите алатки (хоризонтално поставени полиња за избор)",
    "st_3_bullet_2": "да изберете некои или сите области на интерес Засегнати страни, култура, алатки, процеси за човечки ресурси, инспиративни иницијативи, препораки (вертикално позиционирани полиња за избор)",
    "st_3_r0_0": "Хибридна работа",
    "st_3_r0_1": "Хибридна работа - кои форми на хибридност помеѓу „канцелариска работа“ (работа во канцеларија, сателитски канцеларии, итн.) и „работа од далечина“ (дома, на други места кои не се канцеларии) можат најдобро да придонесат за позитивно влијание врз животната средина , и под кои услови?",
    "st_3_r1_0": "Одржлива мобилност",
    "st_3_r1_1": "Одржлива мобилност - кои методологии и (дигитални) решенија можат да придонесат за намалување на патувањето и позитивно влијание на јаглеродниот отпечаток?",
    "st_3_r2_0": "Одржливо и одговорно користење на ИТ",
    "st_3_r2_1": "Одржливо и одговорно користење на ИТ – кои (дигитални) решенија и практики за човечки ресурси поддржуваат Одговорно дигитално однесување и дигитална трезвеност во организациите?",
    "r_c_text_1": "Врз основа на вашите одговори, вашата (тековна) улога во HR се смета како:",
    "r_c_text_2": "Препорачуваме тој пат на учење да учествува во развојот на Нови начини на работа за нето нулта емисија во вашата компанија/организација.",
    "r_c_text_3": "Сепак, можете да изберете друга улога или да продолжите со оваа.",
    "r_c_text_4": "Изберете ја вашата претпочитана улога или оставете ја како што е и продолжете понатаму:",
    "takeQuiz": "Поминете го овој квиз за да разберете која улога одговара на вашата позиција и добијте персонализирани препораки.",
    "takeQuizLabel": "ПОЧНИ СО ПРАШАЊАТА",
    "selectHrRole": "Изберете ја вашата HR улога и добијте препораки прилагодени на вашата специфична позиција.",
    "demographics": "Демографија",
    "yourRole": "Вашата улога",
    "interests": "Интереси",
    "learningPath": "Патека за учење",
    "continue": "Продолжи",
    "back": "Назад",
    "selectPosition": "ИЗБЕРЕТЕ УЛОГА",
    "or": "ИЛИ",
    "hrmEductor": "АКО СТЕ ЕДУКАТОР ЗА ЧОВЕЧКИ РЕСУРСИ ИЛИ ЕДНОСТАВНО САКАТЕ ДА ЗНАЕТЕ КАКО Е ЗАМИСЛЕН ПРОЦЕСОТ НА УЧЕЊЕ СО НАШИТЕ ПАТЕКИ ЗА УЧЕЊЕ, ИСТРАЖЕТЕ ГО НАШИОТ ВОДИЧ ЗА УЧЕЊЕ!",
    "downloadPdf": "ПРЕЗЕМЕТЕ ГО ПДФ ДОКУМЕНТОТ",
    "sm_tab_1": "1. Алатки за обука",
    "sm_tab_2": "2. Патека за учење",
    "sm_tab_3": "3. Алатка за влијание",
    "sm_tab_4": "4. Интерактивни алатки",
    "sm_tab_5": "5. Речник",
    "home_tab_label": "Почетна",
    "about_tab_label": "За проектот",
    "project_tab_label": "Проектни резултати",
    "news_tab_label": "Новости",
    "newsletters_tab_label": "Билтени",
    "contact_tab_label": "Контакт",
    "StrategicPartner": 'Стратешки партнер',
    "AdministrativeExpert": "Административен експерт",
    "EmployeeChampion": "Шампион на вработените",
    "ChangeAgent": "Агент за промена",
    "StrategicPartnerContent": "Како стратешки партнери, професионалците за човечки ресурси тесно соработуваат со деловните лидери за да ги разберат стратешките цели на организацијата и соодветно да ги усогласат практиките и иницијативите за човечки ресурси. Тие придонесуваат за развој на деловни стратегии и гарантираат дека активностите за човечки ресурси го поддржуваат постигнувањето на тие стратегии. Стратешките партнери активно учествуваат во процесите на донесување одлуки и даваат увид за тоа како човечките ресурси можат да придонесат за организацискиот успех.",
    "AdministrativeExpertContent": "Административната експертска улога се фокусира на обезбедување ефективни и ефикасни операции и процеси со човечки ресурси. Професионалците за човечки ресурси во оваа улога управуваат со рутински административни задачи како што се платен список, администрација на бенефиции, политики за човечки ресурси, усогласеност и водење евиденција. Со ефикасно управување со овие административни функции, професионалците за човечки ресурси ослободуваат време и ресурси за да се фокусираат на стратешките иницијативи.",
    "EmployeeChampionContent": "Шампионската улога на вработените ја нагласува важноста од создавање позитивна работна средина и застапување за потребите и благосостојбата на вработените. Професионалците за човечки ресурси во оваа улога делуваат како мост помеѓу организацијата и нејзините вработени, промовирајќи го ангажманот на вработените, задоволството и целокупното искуство на вработените. Тие се однесуваат на проблемите на вработените, обезбедуваат поддршка и негуваат култура на инклузивност, правичност и почит.",
    "ChangeAgentContent": "Улогата на агентот за промена ја нагласува одговорноста на човечките ресурси во олеснувањето и управувањето со организациските промени. Професионалците за човечки ресурси дејствуваат како агенти за промена со проактивно идентификување и справување со потребата за промени, промовирање иницијативи за промени и помагање на вработените и тимовите да се движат низ транзициите. Тие играат клучна улога во поттикнувањето на културната трансформација, организациското реструктуирање и поттикнувањето на начин на размислување подготвен за промени во организацијата.",
    "hybrid": "Хибридна работа",
    "SustainableMobility": "Одржлива мобилност",
    "responsibleUseOfIt": "Одржливо и одговорно користење на ИТ",
    "hybridContent": "Хибридна работа - кои форми на хибридност помеѓу „канцелариска работа“ (работа во канцеларија, сателитски канцеларии, итн.) и „работа од далечина“ (дома, на други места кои не се канцеларии) можат најдобро да придонесат за позитивно влијание врз животната средина , и под кои услови?",
    "SustainableMobilityContent": "Одржлива мобилност - кои методологии и (дигитални) решенија можат да придонесат за намалување на патувањето и позитивно влијание на јаглеродниот отпечаток?",
    "responsibleUseOfItContent": "Одржливо и одговорно користење на ИТ – кои (дигитални) решенија и практики за човечки ресурси поддржуваат Одговорно дигитално однесување и дигитална трезвеност во организациите?",
    "nextBook": "СЛЕДНО",
    "backBook":"НАЗАД",
    "stakeholdersTitle": "Засегнати страни",
    "stakeholdersDesc":"Директорот/менаџерот за човечки ресурси мора тесно да соработува со различни засегнати страни за да придонесе за намалување на емисиите на јаглерод на претпријатието.",
    "createCultureTitle": "Создавање на култура на одржливост",
    "createCultureDesc": "Постигнете промени во однесувањето и потрошувачката преку организациската култура.",
    "toolsTitle": "Алатки",
    "toolsDesc":"Конкретни алатки за поддршка на  намалувањето на емисиите.",
    "hrPrTitle":"HRМ Процеси",
    "hrPrDesc":"Приспособување на процесите за управување со човечките ресурси за да помогнат во намалувањето на емисиите на јаглерод.",
    "inspiringTitle":"Инспиративни иницијативи",
    "inspiringDesc":"Интегрирајте го јаглеродниот отпечаток на поединците (вработените кои работат од далечина) во пресметката на јаглеродниот отпечаток на компанијата.",
    "recoTitle":"Препораки",
    "recoDesc":"Препораки за интегрирање на придонесот од управувањето со човечките ресурси за Net-zero емисии.",
    "ZeroCarbon": "Нулта јаглерод",
    "ZeroCarbonContent": "Нулта јаглерод означува дека производот или услугата имаат карактеристика да не генерира апсолутно никакви емисии на јаглерод. На пример, обновливите извори на енергија како што се ветерот и сончевата енергија се категоризираат како нула јаглерод бидејќи тие не испуштаат јаглерод диоксид (CO2) при генерирање електрична енергија. Спротивно на тоа, нето-нулата се однесува на практиката на неутрализирање или балансирање на емисиите на јаглерод генерирани од една компанија, додека нула јаглерод конкретно означува производ или услуга што не емитува јаглерод во форма еквивалентa на CO2 (CO2e). Понатаму, постигнувањето нето-нула емисии на јаглерод значи дека активноста резултира со нето-нула ослободување на емисиите на јаглерод во атмосферата, што укажува на состојба каде што емисиите се ефективно поништени.",
    "Compensation": "Надомест",
    "CompensationContent": "Надоместокот, честопати наречен (јаглерод) неутрализирање, вклучува намерно, а понекогаш и задолжително стекнување на јаглеродни кредити за да се спротивстават на емисиите што му се припишуваат на одреден ентитет. Трошоците поврзани со набавка на јаглеродни кредити за цели на компензација служи како референтна точка при евалуација на инвестициите насочени кон постигнување директни внатрешни намалувања на емисиите. Одредени емисии на стакленички гасови може да биде инхерентно тешко целосно да се елиминираат, а компензацијата преку јаглеродни кредити игра клучна улога во унапредувањето на климатската неутралност и целите за нето-нула емисија. Стратегиите за компензација опфаќаат различни форми на инвестиции, вклучително и оние во обновливи извори на енергија, енергетска ефикасност, пошумување, зафаќање на јаглерод и други квантитативно ефективни активности за ублажување на јаглеродот. ",
    "Offsetting": "Неутрализирање",
    "OffsettingContent": "Поместувањето на јаглеродот е постапка што вклучува намалување или елиминирање на јаглеродниот диоксид и други емисии на стакленички гасови за да се спротивстават на емисиите генерирани на други локации. Проектите за офсет на јаглерод им овозможуваат на компаниите и на поединците да се вклучат во опипливи еколошки иницијативи насочени кон неутрализирање на нивните емисии на јаглерод. Овие иницијативи за неутрализирање опфаќаат низа технологии и активности, вклучувајќи пошумување, почисти печки за готвење и зафаќање на јаглерод. Неутрализирањето на јаглеродот претставува интегрална компонента на корпоративните стратегии за одржливост, особено кога ги надополнува напорите за намалување на емисиите на јаглерод, што на крајот придонесува за целта за постигнување нето-нула емисии.",
    "ScienceBasedTargets": "Цели засновани на наука (СБТ)",
    "ScienceBasedTargetsContent": "Иницијативата за цели на науката (SBTi) нуди јасно зацртана рамка за компаниите систематски да ги намалат емисиите на нивните стакленички гасови (GHG), со специфичен фокус на постигнување на бројки за намалување на емисиите што се усогласуваат со целите утврдени во Парискиот климатски договор. Целите се сметаат за „засновани на наука“ кога се во согласност со најновите наоди од науката за климата, со цел да се исполнат целите за ограничување на глобалното затоплување на не повеќе од 1,5 степени Целзиусови над прединдустриските нивоа.\n" +
        "\n" +
        "SBTi игра клучна улога во промовирањето на најдобрите практики и обезбедувањето добро дефинирани упатства за намалување на емисиите. Тој нуди методологии за поставување цели за намалување на емисиите врз основа на научното разбирање на климатските промени. Иницијативата активно им помага на бизнисите во воспоставувањето цели за намалување на јаглеродот кои се целосно во согласност со целите пропишани во Парискиот договор.",
    "Greenwashing": "Greenwashing",
    "GreenwashingContent": "Greenwashing се однесува на измамничка практика во која компанијата користи рекламирање и јавна комуникација за да се прикаже себеси како еколошки поодговорна и поодржлива отколку што навистина е. Тоа е стратегија што ја применуваат некои компании за да го одвлечат вниманието на потрошувачите од штетните влијанија врз животната средина на нивните деловни операции и модели. Тактиките на greenwashing може да се манифестираат во различни форми, вклучувајќи рекламни кампањи, спонзорства и јавни пораки преку традиционалните медиуми и платформите на социјалните медиуми  .",
    "LifeCycleAssessment": "Проценка на животниот циклус (LCA)",
    "LifeCycleAssessmentContent": "LCA е ригорозен, научен пристап кој се користи за да се процени еколошкиот отпечаток на производот или процесот во текот на неговиот целосен животен циклус. Овој метод ја зема предвид секоја фаза, почнувајќи со екстракција на суровини и проширувајќи се до фазата на евентуално отстранување или рециклирање. LCA служи како вредна алатка за квантифицирање на еколошките последици поврзани со производите и процесите, давајќи им можност на компаниите да направат добро информирани избори и да имплементираат подобрувања во нивните напори за одржливост.",
    "CarbonFootprint": "Јаглероден отпечаток",
    "CarbonFootprintContent": "Показател за цената на стакленички гасови, обично јаглерод диоксид, поврзан со компанија, земја, производ или активност; често ги вклучува и директните и индиректните емисии кои се вклучени. На пример, јаглеродниот отпечаток на производ направен со употреба на увезени материјали ќе ги вклучи и трошоците за јаглерод за транспорт на увезените материјали и трошоците за производство на производот. Овие стапки треба да дадат идеја за влијанието на бизнисите врз животната средина и да обезбедат почетна точка за движење кон поодговорни практики за животната средина.",
    "EmissionsTradingScheme": "Шема за тргување со емисии (ETS)",
    "EmissionsTradingSchemeContent": "Шема за тргување со емисии е систем „cap-and-trade“ кој ги ограничува емисиите и ги казнува компаниите за надминување на ограничувањето. Сепак, на овие компании им се даваат кредити за емисиите што им овозможуваат да ја надминат границата без казна. Овие кредити може да се купат или продаваат на други компании, охрабрувајќи ги да ги намалат емисиите, или да имаат повеќе кредити за продажба, или да ја намалат нивната потреба да ги купат. Ваквите системи вообичаено се користат ширум светот, најдобро е примерот на EU-ETS.",
    "toolkit1": "АЛАТКА 1",
    "toolkit2": "АЛАТКА 2",
    "toolkit3": "АЛАТКА 3",
    "forewordTitle": "Предговор",
    "learningObjectives": "Цели на учењето",
    "pr3_home_title": "ПР4: ИНТЕРАКТИВНИ АЛАТКИ",
    "pr3_home_subtitle": "Три модуларни алатки кои покриваат модификација на практиките за управување со човечките ресурси според барањата што произлегуваат од влијанието на новите трендови во информатичката технологија, мобилноста и одржливоста на современите работни места.",
    "pr3_home_toolkit_1": "СЕТ АЛАТКИ",
    "pr3_home_toolkit_0_2": "Нови начини на работа",
    "pr3_home_toolkit_1_2": "Мобилност поврзана со работата",
    "pr3_home_toolkit_2_2": "Одговорна употреба на ИКТ",
}
