import {createTheme} from "@mui/material/styles";

const {palette} = createTheme();
const {augmentColor} = palette;
const createColor = (mainColor) => augmentColor({color: {main: mainColor}});

export const roles = {
    StrategicPartner: 'StrategicPartner',
    AdministrativeExpert: 'AdministrativeExpert',
    EmployeeChampion: 'EmployeeChampion',
    ChangeAgent: 'ChangeAgent'
}

export const rolesdescription = () => ({
    StrategicPartner: window.getContent('StrategicPartner'),
    AdministrativeExpert: window.getContent('AdministrativeExpert'),
    EmployeeChampion: window.getContent('EmployeeChampion'),
    ChangeAgent: window.getContent('ChangeAgent')
})

export const newPallette = {
    StrategicPartner: createColor('#FFD618'), //rgba(255, 214, 24, 0.2) || #FFD618
    AdministrativeExpert: createColor('#D48CED'), //rgba(212, 140, 237, 0.2) || #D48CED
    EmployeeChampion: createColor('#45BF55'), //rgba(69, 191, 85, 0.2) || #45BF55
    ChangeAgent: createColor('#64AAFA'), //rgba(100, 170, 250, 0.2) || #64AAFA
    grey: createColor('#8B8B8B'), // || #8B8B8B
}

export const questions = [
    {
        "role": "",
        "id": "q2.2",
        "answers": [
            {
                "id": "a10",
                "roles": [
                    roles.StrategicPartner
                ]
            },
            {
                "id": "a11",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion,
                    roles.ChangeAgent,
                ]
            },
            {
                "id": "a12",
                "roles": [
                    roles.AdministrativeExpert,
                ]
            },
            {
                "id": "a13",
                "roles": [
                    roles.ChangeAgent,
                ]
            },
            {
                "id": "a14",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion,
                ]
            }
        ]
    },
    {
        "role": roles.StrategicPartner,
        "id": "q2.3",
        "answers": [
            {
                "id": "a1",
                "roles": [
                    roles.StrategicPartner
                ]
            },
            {
                "id": "a2",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion,
                    roles.ChangeAgent,
                ]
            },
            {
                "id": "a3",
                "roles": [
                    roles.ChangeAgent,
                ]
            }
        ]
    },
    {
        "role": roles.StrategicPartner,
        "id": "q2.4",
        "answers": [
            {
                "id": "a4",
                "roles": [
                    roles.StrategicPartner,
                ]
            },
            {
                "id": "a5",
                "roles": [
                    roles.ChangeAgent,
                    roles.StrategicPartner,
                ]
            },
            {
                "id": "a3",
                "roles": [
                    roles.EmployeeChampion,
                    roles.ChangeAgent
                ]
            },
            {
                "id": "a2",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion
                ]
            }
        ]
    },
    {
        "role": roles.StrategicPartner,
        "id": "q2.5",
        "answers": [
            {
                "id": "a6",
                "roles": [
                    roles.StrategicPartner,
                ]
            },
            {
                "id": "a7",
                "roles": [
                    roles.StrategicPartner,
                ]
            },
            {
                "id": "a8",
                "roles": [
                    roles.EmployeeChampion,
                    roles.ChangeAgent
                ]
            },
            {
                "id": "a9",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion,
                    roles.ChangeAgent
                ]
            }
        ]
    },
    {
        "role": roles.AdministrativeExpert,
        "id": "q2.6",
        "answers": [
            {
                "id": "a6",
                "roles": [
                    roles.AdministrativeExpert,
                ]
            },
            {
                "id": "a7",
                "roles": [
                    roles.AdministrativeExpert,
                ]
            },
            {
                "id": "a8",
                "roles": [
                    roles.EmployeeChampion,
                    roles.ChangeAgent,
                    roles.StrategicPartner
                ]
            },
            {
                "id": "a9",
                "roles": [
                    roles.EmployeeChampion,
                    roles.ChangeAgent,
                    roles.StrategicPartner
                ]
            }
        ]
    },
    {
        "role": roles.AdministrativeExpert,
        "id": "q2.7",
        "answers": [
            {
                "id": "a6",
                "roles": [
                    roles.AdministrativeExpert,
                ]
            },
            {
                "id": "a7",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion,
                ]
            },
            {
                "id": "a8",
                "roles": [
                    roles.EmployeeChampion,
                    roles.ChangeAgent,
                    roles.StrategicPartner
                ]
            },
            {
                "id": "a9",
                "roles": [
                    roles.EmployeeChampion,
                    roles.ChangeAgent,
                    roles.StrategicPartner
                ]
            }
        ]
    },
    {
        "role": roles.AdministrativeExpert,
        "id": "q2.8",
        "answers": [
            {
                "id": "a6",
                "roles": [
                    roles.AdministrativeExpert,
                ]
            },
            {
                "id": "a7",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion,
                ]
            },
            {
                "id": "a8",
                "roles": [
                    roles.EmployeeChampion,
                    roles.ChangeAgent,
                    roles.StrategicPartner
                ]
            },
            {
                "id": "a9",
                "roles": [
                    roles.EmployeeChampion,
                    roles.ChangeAgent,
                    roles.StrategicPartner
                ]
            }
        ]
    },
    {
        "role": roles.EmployeeChampion,
        "id": "q2.9",
        "answers": [
            {
                "id": "a6",
                "roles": [
                    roles.EmployeeChampion,
                ]
            },
            {
                "id": "a7",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion,
                    roles.ChangeAgent
                ]
            },
            {
                "id": "a8",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.StrategicPartner
                ]
            },
            {
                "id": "a9",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.StrategicPartner
                ]
            }
        ]
    },
    {
        "role": roles.EmployeeChampion,
        "id": "q2.10",
        "answers": [
            {
                "id": "a6",
                "roles": [
                    roles.EmployeeChampion,
                ]
            },
            {
                "id": "a7",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion,
                    roles.ChangeAgent,
                    roles.StrategicPartner,
                ]
            },
            {
                "id": "a8",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.ChangeAgent,
                ]
            },
            {
                "id": "a9",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.ChangeAgent
                ]
            }
        ]
    },
    {
        "role": roles.EmployeeChampion,
        "id": "q2.11",
        "answers": [
            {
                "id": "a6",
                "roles": [
                    roles.StrategicPartner,
                    roles.EmployeeChampion,
                ]
            },
            {
                "id": "a7",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion,
                    roles.ChangeAgent,
                ]
            },
            {
                "id": "a8",
                "roles": [
                    roles.AdministrativeExpert,
                ]
            },
            {
                "id": "a9",
                "roles": [
                    roles.AdministrativeExpert,
                ]
            }
        ]
    },
    {
        "role": roles.ChangeAgent,
        "id": "q2.12",
        "answers": [
            {
                "id": "a6",
                "roles": [
                    roles.ChangeAgent,
                ]
            },
            {
                "id": "a7",
                "roles": [
                    roles.StrategicPartner,
                    roles.ChangeAgent,
                ]
            },
            {
                "id": "a8",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion,
                ]
            },
            {
                "id": "a9",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion,
                ]
            }
        ]
    },
    {
        "role": roles.ChangeAgent,
        "id": "q2.13",
        "answers": [
            {
                "id": "a6",
                "roles": [
                    roles.ChangeAgent,
                ]
            },
            {
                "id": "a7",
                "roles": [
                    roles.StrategicPartner,
                    roles.ChangeAgent,
                ]
            },
            {
                "id": "a8",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion,
                ]
            },
            {
                "id": "a9",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion,
                ]
            }
        ]
    },
    {
        "role": roles.ChangeAgent,
        "id": "q2.14",
        "answers": [
            {
                "id": "a6",
                "roles": [
                    roles.ChangeAgent,
                ]
            },
            {
                "id": "a7",
                "roles": [
                    roles.StrategicPartner,
                    roles.ChangeAgent,
                    roles.EmployeeChampion,
                ]
            },
            {
                "id": "a8",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion,
                ]
            },
            {
                "id": "a9",
                "roles": [
                    roles.AdministrativeExpert,
                    roles.EmployeeChampion,
                ]
            }
        ]
    },
]
