export const languages = {
    en: 'en',
    fr: 'fr',
    mk: 'mk',
    et: 'et',
}

export const languageLongNames = {
    mk: 'Македонски',
    fr: 'Français (France)',
    et: 'Estonian',
    en: 'English (United Kingdom)',
}

export const languageRouteAbbreviations = {
    en: {
        short: 'en',
        long: 'en-gb',
        newsletterSpecialSuffix: 'news-3',
        pr1Link: 'https://nwow4net-zero.eu/index.php/en/project-results-en-gb/pr1-training-toolkits'
    },
    fr: {
        short: 'fr',
        long: 'fr',
        newsletterSpecialSuffix: 'news-5',
        pr1Link: 'https://nwow4net-zero.eu/index.php/fr/project-results-fr/pr1-training-toolkits-fr'
    },
    mk: {
        short: 'mk',
        long: 'mk',
        newsletterSpecialSuffix: 'news-7',
        pr1Link: 'https://nwow4net-zero.eu/index.php/mk/project-results-mk/pr1-training-toolkits'
    },
    et: {
        short: 'et',
        long: 'ee',
        newsletterSpecialSuffix: 'news-9',
        pr1Link: 'https://nwow4net-zero.eu/index.php/et/project-results-ee/pr1-training-toolkits'
    },
}

export const routes = {
    pr2: 'pr-2',
    book: 'book',
    dialog: 'dialog'
}
