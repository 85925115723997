import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './stepper.scss';
import ButtonMui, {button_theme} from "../buttons/buttonMui";
import {RightArrowCircledIcon} from "../icons/svgIcons";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useRef} from "react";


const styles = {
    '.MuiStepLabel-iconContainer': {
        zIndex: 1,
        padding: '0 0 8px'
    },
    '.MuiStepLabel-label': {
        color: '#133861',
        textAlign: 'center',
        fontSize: '18px'
    },
    '.MuiStepConnector-root': {
        zIndex: 0,
        // top: '25px'
        top: '30px',
        // left: 'calc(-50% + 32px)',
        // right: 'calc(50% + 33px)',
    },
    '.MuiStepConnector-line': {
        borderTop: '2px solid #133861',
        // height: '40px',
        left: 'calc(-50% + 40px)',
        right: 'calc(50% + 40px)',
    },
    '.MuiSvgIcon-root':
        {
            color: '#D5D5D5',
            '.MuiStepIcon-text':
                {
                    fill: '#133861',
                }
            ,
        }
    ,
    '.Mui-active':
        {
            color: '#133861 !important',
            '.MuiStepIcon-text':
                {
                    fill: '#fff',
                }
            ,
        }
    ,
    '.Mui-completed': {
        color: '#133861 !important',
        '.MuiStepIcon-text':
            {
                fill: '#fff',
            }
        ,
    }
    ,
    '.MuiStep-root': {
        width: '25%',
    },
    '@media screen and (max-width: 900px)': {
        '.MuiStepLabel-label':
            {
                fontSize: '13px'
            }
        ,
        '.MuiStep-root':
            {
                width: '25%',
                padding: '0 4px'
            },
        '.MuiStepConnector-line': {
            height: '30px'
        },
    },
    '@media screen and (max-width: 540px)': {
        '.MuiStepConnector-root': {
            top: '18px',
            left: 'calc(-50% + 30px)',
            right: 'calc(50% + 40px)',
        }
    },
    '@media screen and (max-width: 492px)': {
        '.MuiStepConnector-root': {
            display: 'none',
        }
    }
}

export default function HorizontalLinearStepper(props) {
    const [activeStep, setActiveStep] = React.useState(props.initialStep ?? 0);
    const [skipped, setSkipped] = React.useState(new Set());
    const isStepOptional = (step) => {
        return false;
    };
    const steps = [window.getContent("demographics"), window.getContent("yourRole"), window.getContent("interests"), window.getContent("learningPath")];


    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const getDisabledNext = () => (activeStep === 1 && !props.role);

    const handleNext = () => {
        if (getDisabledNext()) {
            return;
        }
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        if (activeStep + 1 === steps.length) {
            props.handleFinishQuiz()
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        // setTimeout(scrollToTop, 200);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            props.handleCancelQuiz();
            return;
        }
        // window.scrollTo({top: 0, behavior: 'smooth'});
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        // setTimeout(scrollToTop, 200);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const ref = useRef(null);

    const scrollToTop = () => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
    }

    return (
        <Box ref={ref} sx={{
            width: '100%', display: 'grid', rowGap: '40px', gridTemplateColumns: '100%', '.MuiStepper-root': {
                alignItems: 'flex-start'
            }
        }}>
            <div className={'title'}>{window.getContent('inner_cont_title').toUpperCase()}</div>

            <Stepper activeStep={activeStep} sx={styles} alternativeLabel={true}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{mt: 2, mb: 1}}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        <Box sx={{flex: '1 1 auto'}}/>
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {props.stepComponents[activeStep] ??
                        <Typography sx={{mt: 2, mb: 1}}>Step {activeStep + 1}</Typography>}
                    <Box className={'btns_container'} sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        <ButtonMui onClick={handleBack} label={window.getContent("back")}
                                   startIcon={<RightArrowCircledIcon className={'left'}/>} width={220}/>
                        <Box sx={{flex: '1 1 auto'}}/>
                        {(activeStep !== steps.length - 1) &&
                            <ButtonMui onClick={handleNext} disabled={getDisabledNext()}
                                       label={window.getContent("continue")}
                                       endIcon={<RightArrowCircledIcon/>}
                                       width={220}/>}
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}
