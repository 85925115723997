import React, {Component} from 'react';
import FullWidthTabs from "../tabs/tabs";
import AccordionExpandable from "../accordion/accordion";
import {Step} from "./step1";
import {Loader} from "../../pages/book/book_container";

export const colorClasses = {
    green: 'green',
    blue: 'blue',
    purple: 'purple',
    yellow: 'yellow'
}

class Step4 extends Step {

    constructor(props) {
        super(props);
        this.state = {
            toolKit: 0,
            key: new Date().getTime()
        }

        this.toolkitOpenState = {
            0: {
                stakeHoldersContent: false,
                cultureContent: false,
                toolsContent: false,
                hrContent: false,
                inspiringInitiativesContent: false,
                recommendedActionsContent: false,
            },
            1: {
                stakeHoldersContent: false,
                cultureContent: false,
                toolsContent: false,
                hrContent: false,
                inspiringInitiativesContent: false,
                recommendedActionsContent: false,
            },
            2: {
                stakeHoldersContent: false,
                cultureContent: false,
                toolsContent: false,
                hrContent: false,
                inspiringInitiativesContent: false,
                recommendedActionsContent: false,
            },
        }

        this.getContentObjects();
    }

    getColorClassName = () => {
        switch (this.state.toolKit) {
            case 0:
                return colorClasses.green;
            case 1:
                return colorClasses.blue;
            case 2:
                return colorClasses.yellow;
        }
    }

    getContentObjects = (toolKit = this.state.toolKit) => {
        if (toolKit == 0) {
            this.stakeHoldersContent = this.props.toolKitObj.hybrid[1] ? this.getContent(window.getContent('hybridStakeHolders')) : [];
            this.cultureContent = this.props.toolKitObj.hybrid[2] ? this.getContent(window.getContent('hybridCulture')) : [];
            this.toolsContent = this.props.toolKitObj.hybrid[3] ? this.getContent(window.getContent('hybridTools')) : [];
            this.hrContent = this.props.toolKitObj.hybrid[4] ? this.getContent(window.getContent('hybridHr')) : [];
            this.inspiringInitiativesContent = this.props.toolKitObj.hybrid[5] ? this.getContent(window.getContent('hybridInspiringInitiatives')) : [];
            this.recommendedActionsContent = this.props.toolKitObj.hybrid[6] ? this.getContent(window.getContent('hybridRecommendedActions')) : [];
        } else if (toolKit == 1) {
            this.stakeHoldersContent = this.props.toolKitObj.mobility[1] ? this.getContent(window.getContent('mobilityStakeHolders')) : [];
            this.cultureContent = this.props.toolKitObj.mobility[2] ? this.getContent(window.getContent('mobilityCulture')) : [];
            this.toolsContent = this.props.toolKitObj.mobility[3] ? this.getContent(window.getContent('mobilityTools')) : [];
            this.hrContent = this.props.toolKitObj.mobility[4] ? this.getContent(window.getContent('mobilityHr')) : [];
            this.inspiringInitiativesContent = this.props.toolKitObj.mobility[5] ? this.getContent(window.getContent('mobilityInspiringInitiatives')) : [];
            this.recommendedActionsContent = this.props.toolKitObj.mobility[6] ? this.getContent(window.getContent('mobilityRecommendedActions')) : [];
        } else if (toolKit == 2) {
            this.stakeHoldersContent = this.props.toolKitObj.sustanableIt[1] ? this.getContent(window.getContent('sustainableStakeHolders')) : [];
            this.cultureContent = this.props.toolKitObj.sustanableIt[2] ? this.getContent(window.getContent('sustainableCulture')) : [];
            this.toolsContent = this.props.toolKitObj.sustanableIt[3] ? this.getContent(window.getContent('sustainableTools')) : [];
            this.hrContent = this.props.toolKitObj.sustanableIt[4] ? this.getContent(window.getContent('sustainableHr')) : [];
            this.inspiringInitiativesContent = this.props.toolKitObj.sustanableIt[5] ? this.getContent(window.getContent('sustainableInspiringInitiatives')) : [];
            this.recommendedActionsContent = this.props.toolKitObj.sustanableIt[6] ? this.getContent(window.getContent('sustainableRecommendedActions')) : [];
        }
    }

    getContent = (obj) => {

        let array = [];

        obj.forEach(c => {
            if (c.roles.includes(this.props.role)) {
                array.push(c);
            }
        })

        return array;
    }

    handleTabChange = (toolKit) => {
        this.getContentObjects(toolKit)
        this.setState({toolKit, loading: true, key: new Date().getTime()}, () => {
            setTimeout(() => this.setState({loading: false}), 500)
        })
    }

    onChange = (toolKit, key, value) => {
        this.toolkitOpenState[toolKit][key] = value
        this.forceUpdate();
    }

    render() {
        return (
            <>
                <FullWidthTabs tabs={[
                    {
                        label: window.getContent('hybrid'),
                        content: window.getContent('hybridContent')
                    },
                    {
                        label: window.getContent('SustainableMobility'),
                        content: window.getContent('SustainableMobilityContent')
                    },
                    {
                        label: window.getContent('responsibleUseOfIt'),
                        content: window.getContent('responsibleUseOfItContent')
                    },
                ]}
                               fourthStep
                               onChange={this.handleTabChange}
                               initial_value={this.state.toolKit}
                />

                <div className={"book_cont " + this.getColorClassName()} key={this.state.key}>
                    {this.state.loading && <Loader className={'upper'}/>}

                    <AccordionExpandable index={1} onChange={(value) => {
                        this.onChange(this.state.toolKit, 'stakeHoldersContent', value)
                    }} expanded={this.toolkitOpenState[this.state.toolKit].stakeHoldersContent}
                                         label={window.getContent("stakeholdersTitle")}
                                         description={window.getContent("stakeholdersDesc")}
                                         content={this.stakeHoldersContent}/>
                    <AccordionExpandable index={2} onChange={(value) => {
                        this.onChange(this.state.toolKit, 'cultureContent', value)
                    }} expanded={this.toolkitOpenState[this.state.toolKit].cultureContent}
                                         label={window.getContent("createCultureTitle")}
                                         description={window.getContent("createCultureDesc")}
                                         content={this.cultureContent}/>
                    <AccordionExpandable index={3} onChange={(value) => {
                        this.onChange(this.state.toolKit, 'toolsContent', value)
                    }} expanded={this.toolkitOpenState[this.state.toolKit].toolsContent}
                                         label={window.getContent("toolsTitle")}
                                         description={window.getContent("toolsDesc")}
                                         content={this.toolsContent}/>
                    <AccordionExpandable index={4} onChange={(value) => {
                        this.onChange(this.state.toolKit, 'hrContent', value)
                    }} expanded={this.toolkitOpenState[this.state.toolKit].hrContent}
                                         label={window.getContent("hrPrTitle")}
                                         description={window.getContent("hrPrDesc")}
                                         content={this.hrContent}/>
                    <AccordionExpandable index={5} onChange={(value) => {
                        this.onChange(this.state.toolKit, 'inspiringInitiativesContent', value)
                    }} expanded={this.toolkitOpenState[this.state.toolKit.inspiringInitiativesContent]}
                                         label={window.getContent("inspiringTitle")}
                                         description={window.getContent("inspiringDesc")}
                                         content={this.inspiringInitiativesContent}/>
                    <AccordionExpandable index={5} onChange={(value) => {
                        this.onChange(this.state.toolKit, 'recommendedActionsContent', value)
                    }} expanded={this.toolkitOpenState[this.state.toolKit].recommendedActionsContent}
                                         label={window.getContent("recoTitle")}
                                         description={window.getContent("recoDesc")}
                                         content={this.recommendedActionsContent}/>
                </div>
            </>
        );
    }
}

export default Step4;
