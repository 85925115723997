import React, {Component} from 'react';
import './inner_cont.scss';
import BlueCard, {svgPositioningTypes} from "../blue_card/blueCard";
import ButtonMui, {buttonRoundnessTypes} from "../buttons/buttonMui";
import {DownloadIcon, RightArrowCircledIcon} from "../icons/svgIcons";
import Line from "../line/Line";
import HorizontalLinearStepper from "../stepper/Stepper";
import Step1 from "../steps/step1";
import Step2 from "../steps/step2";
import Step3 from "../steps/step3";
import Step4 from "../steps/step4";
import {roles} from "../quiz/quizHelper";
import {routes} from "../../Enums/Enums";

class PR2LearningPath extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            role: '',
            skipQuiz: false,
        }

        this.answersObj = {}

        this.toolKitObj = {
            hybrid: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false
            },
            mobility: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false
            },
            sustanableIt: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false
            }
        }
    }

    componentDidMount() {
        if (!window.returnToHomePage) {
            window.returnToHomePage = {}
        }
        window.returnToHomePage[routes.pr2] = () => this.setState({step: 0})
    }

    componentWillUnmount() {
        delete window.returnToHomePage[routes.pr2]
    }

    handleChangeToolkit = (name, index, value) => {
        this.toolKitObj[name][index] = value;
        this.forceUpdate();
    }
    changeRole = (role) => {
        this.setState({role: role})
    }

    getInitialRole = () => {
        return Object.keys(roles).find(c => roles[c] === roles.StrategicPartner);
    }

    render() {

        if (this.state.step === 0) {
            return (
                <div className={'inner_container'}>
                    <BlueCard treePosition={svgPositioningTypes.type1}>
                        <ButtonMui width={235} label={window.getContent('takeQuizLabel').toUpperCase()}
                                   endIcon={<RightArrowCircledIcon/>}
                                   style={{
                                       minHeight: '68px',
                                       height: 'auto'
                                   }}
                                   onClick={() => this.setState({step: 1, initialStep: 0, role: '', skipQuiz: false})}/>
                        <div className={'heading4 bold right_align'}>
                            {window.getContent('takeQuiz')}
                        </div>
                    </BlueCard>

                    <div className={'flex'}>
                        <Line className={'t1'}/>
                        <div>{window.getContent('or')}</div>
                        <Line/>
                    </div>

                    <BlueCard treePosition={svgPositioningTypes.type2}>
                        <div className={'heading4 bold'}>
                            {window.getContent('selectHrRole')}
                        </div>
                        <ButtonMui width={235} label={window.getContent('selectPosition')}
                                   endIcon={<RightArrowCircledIcon/>}
                                   onClick={() => this.setState({
                                       step: 1,
                                       skipQuiz: true,
                                       initialStep: 1,
                                       role: this.getInitialRole()
                                   })}/>
                    </BlueCard>

                    <Line/>

                    <BlueCard treePosition={svgPositioningTypes.type3}>
                        <div>
                            <FloaterButton style={{height: 'auto', minHeight: '70px', position: 'absolute', top: 0}}/>
                            <div className={'hidden'}>
                                <FloaterButton/>
                            </div>
                        </div>
                        <div className={'heading4 bold right_align'}>
                            {window.getContent('hrmEductor')}
                        </div>
                    </BlueCard>
                </div>
            );
        }

        if (this.state.step === 1) {
            return <div className={'inner_container'}>
                <HorizontalLinearStepper stepComponents={[
                    <Step1/>,
                    <Step2 skipQuiz={this.state.skipQuiz} changeRole={this.changeRole} role={this.state.role}
                           answersObj={this.answersObj}/>,
                    <Step3 toolKitObj={this.toolKitObj} handleChangeToolkit={this.handleChangeToolkit}/>,
                    <Step4 role={this.state.role} toolKitObj={this.toolKitObj}/>,
                ]} skipQuiz={this.state.skipQuiz}
                                         initialStep={this.state.initialStep ?? 0}
                                         role={this.state.role}
                                         handleCancelQuiz={() => this.setState({step: 0, skipQuiz: false})}
                                         handleFinishQuiz={() => this.setState({step: 0, skipQuiz: false})}/>
            </div>
        }
    }
}

const FloaterButton = (props) => <ButtonMui {...props} color={'white'}
                                            onClick={() => window.open('https://nwow4net-zero.eu/images/NWOW4NetZero/NWoW_LearningGuide_' + window.language + '.pdf', "_blank")}
                                            roundnessType={buttonRoundnessTypes.bottomRounded}
                                            label={window.getContent('downloadPdf')}
                                            endIcon={<DownloadIcon/>} width={235}/>

export default PR2LearningPath;
