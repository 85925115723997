import '../../components/inner_cont/inner_cont.scss'
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Book from "./book";
import FullWidthTabs from "../../components/tabs/tabs";
import {hybrid} from "../../ToolKits/hybrid";
import {mobility} from "../../ToolKits/mobility";
import {sustainable} from "../../ToolKits/sustainable";
import {CircularProgress} from "@mui/material";
import Box from "@mui/material/Box";
import {colorClasses} from "../../components/steps/step4";
import ButtonMui from "../../components/buttons/buttonMui";
import {routes} from "../../Enums/Enums";

const imageSrcs = [
    "https://nwow4net-zero.eu/images/NWOW4NetZero/IO1/T1_NWoW_header.png",
    "https://nwow4net-zero.eu/images/NWOW4NetZero/IO1/T2_mobility_header.png",
    "https://nwow4net-zero.eu/images/NWOW4NetZero/IO1/T3_IT_header.png"
]

class BookContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toolKit: 0,
            key: new Date().getTime(),
        }

        this.headings = [window.getContent('forewordTitle'), window.getContent('learningObjectives'), window.getContent('stakeholdersTitle'), window.getContent('createCultureTitle'), window.getContent('toolsTitle'), window.getContent('hrPrTitle'), window.getContent('inspiringTitle'), window.getContent('recoTitle')]

        const search = window.location.search;
        this.toolKit = null;

        if (search) {
            const params = new URLSearchParams(search);
            this.toolKit = parseInt(params.get('toolkitIndex'));
        }

        if (this.toolKit !== null) {
            this.getContentObj();
        }

        this.toolkitOpenState = {
            0: {
                learningObjectives: false,
                stakeHolders: false,
                culture: false,
                hr: false,
                tools: false,
                inspiringInitiatives: false,
                recommendation: false,
            },
            1: {
                learningObjectives: false,
                stakeHolders: false,
                culture: false,
                hr: false,
                tools: false,
                inspiringInitiatives: false,
                recommendation: false,
            },
            2: {
                learningObjectives: false,
                stakeHolders: false,
                culture: false,
                hr: false,
                tools: false,
                inspiringInitiatives: false,
                recommendation: false,
            },
        }

        this.getContentObj();
    }

    setRef = (ref) => {
        this.ref = ref;
        this.setSize()
    }

    setSize = () => {
        if (this.ref) {
            const width = this.ref.getBoundingClientRect().width - (paddingInnerContainer * 2) - 32;
            if (width != this.state.width) {
                this.setState({width});
            }
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.setSize)
        if (!window.returnToHomePage) {
            window.returnToHomePage = {}
        }
        window.returnToHomePage[routes.book] = () => {
            this.toolKit = null;
            this.content = null;
            this.forceUpdate();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setSize)
        delete window.returnToHomePage[routes.book]
    }

    getContentObj = () => {
        if (this.toolKit === 0) {
            this.content = {
                learningObjectives: hybrid[window.language].learningObjectives,
                stakeHolders: hybrid[window.language].stakeHolders,
                culture: hybrid[window.language].culture,
                hr: hybrid[window.language].hr,
                tools: hybrid[window.language].tools,
                inspiringInitiatives: hybrid[window.language].inspiringInitiatives,
                recommendation: hybrid[window.language].recommendation,
            }
            this.forceUpdate();
        } else if (this.toolKit === 1) {
            this.content = {
                learningObjectives: mobility[window.language].learningObjectives,
                stakeHolders: mobility[window.language].stakeHolders,
                culture: mobility[window.language].culture,
                hr: mobility[window.language].hr,
                tools: mobility[window.language].tools,
                inspiringInitiatives: mobility[window.language].inspiringInitiatives,
                recommendation: mobility[window.language].recommendation,
            }
            this.forceUpdate();
        } else if (this.toolKit === 2) {
            this.content = {
                learningObjectives: sustainable[window.language].learningObjectives,
                stakeHolders: sustainable[window.language].stakeHolders,
                culture: sustainable[window.language].culture,
                hr: sustainable[window.language].hr,
                tools: sustainable[window.language].tools,
                inspiringInitiatives: sustainable[window.language].inspiringInitiatives,
                recommendation: sustainable[window.language].recommendation,
            }
            this.forceUpdate();
        }
    }


    handleTabChange = (toolKit) => {
        // this.setState({toolKit}, () => this.getContentObj());

        this.toolKit = toolKit;
        this.setState({loading: true, key: new Date().getTime()}, () => {
            this.getContentObj()
            setTimeout(() => this.setState({loading: false}), 500);
        })
    }

    getContent = (obj) => {

        let array = [];

        obj.forEach(c => {
            array.push(c);
        })

        return array;
    }

    getColorClassName = () => {
        switch (this.toolKit) {
            case 0:
                return colorClasses.green;
            case 1:
                return colorClasses.blue;
            case 2:
                return colorClasses.yellow;
        }
    }

    render() {
        return (
            <div className={'inner_container'} style={{padding: `${paddingInnerContainer}px`}} ref={this.setRef}>
                {!this.content && <>
                    <div className="pr4_home_cont">
                        <div className='pr4_title'>{window.getContent('pr3_home_title')}</div>
                        <div className='pr4_sub_title'>{window.getContent('pr3_home_subtitle')}</div>
                        <div className="toolkits_container">
                            {new Array(3).fill(undefined).map((_, index) => (<div className="toolkit_cont">
                                <div className="outer_cont">
                                    <div className="inner_cont">
                                        <div className="image_cont">
                                            <img src={imageSrcs[index]} className={`image_${index}`} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <ButtonMui color={'primary_blue'} width={239}
                                           style={{paddingTop: '30px', height: 'auto'}} onClick={() => {
                                    this.toolKit = index;
                                    this.getContentObj()
                                }} label={<div>
                                    <div><b>{window.getContent(`pr3_home_toolkit_1`)} {index + 1}</b></div>
                                    <div>{window.getContent(`pr3_home_toolkit_${index}_2`)}</div>
                                </div>}/>
                            </div>))}
                        </div>
                    </div>
                </>}
                {this.content && <>
                    <FullWidthTabs tabs={[
                        {
                            label: window.getContent('toolkit1'),
                            subLabel: window.getContent('hybrid'),
                            content: window.getContent('hybridContent')
                        },
                        {
                            label: window.getContent('toolkit2'),
                            subLabel: window.getContent('SustainableMobility'),
                            content: window.getContent('SustainableMobilityContent')
                        },
                        {
                            label: window.getContent('toolkit3'),
                            subLabel: window.getContent('responsibleUseOfIt'),
                            content: window.getContent('responsibleUseOfItContent')
                        },
                    ]}
                                   onChange={this.handleTabChange}
                                   initial_value={this.toolKit}
                                   fourthStep
                                   isBook
                    />
                    {this.state.width && <div key={this.state.key} className={'book_cont ' + this.getColorClassName()}>
                        {this.state.loading && <Loader/>}
                        <Book width={this.state.width} content={this.content} toolkitOpenState={this.toolkitOpenState}
                              toolKit={this.toolKit} headings={this.headings}/>
                    </div>}
                </>}
            </div>
        );
    }
}

BookContainer.propTypes = {};

export default BookContainer;

const paddingInnerContainer = 20;

export const Loader = ({className = ''}) => <div className={`loading ${className}`}>
    <FacebookCircularProgress/>
</div>

function FacebookCircularProgress(props) {
    return (
        <Box
            sx={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px'}}>
            <CircularProgress
                variant="determinate"
                sx={{
                    color: (theme) =>
                        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                }}
                size={40}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                    color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                    animationDuration: '550ms',
                    position: 'absolute',
                    left: 0,
                    strokeLinecap: 'round',
                }}
                size={40}
                thickness={4}
                {...props}
            />
        </Box>
    );
}
