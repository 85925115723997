import React, {Component} from 'react';
import {questions, roles} from "./quizHelper";
import Answer from "../answer/Answer";
import './quiz.scss'
import RoleChooser from "../role_chooser/roleChooser";
import {SassCalculation as _} from "sass";

class Quiz extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.questions = questions;
        // this.answers = {};
    }

    onAnswerChange = (questionId, cur_roles, answerId) => {
        // this.answers[questionId] = cur_roles;
        this.props.answersObj[questionId] = {id: answerId, roles: cur_roles};

        if (Object.keys(this.props.answersObj).length === this.questions.length) {
            let finalArray = [];
            Object.values(this.props.answersObj).forEach(c => {
                finalArray = [...finalArray, ...c.roles];
            })
            let counts = finalArray.reduce((map, val) => {
                map[val] = (map[val] || 0) + 1;
                return map
            }, {});
            let maxKey = Object.keys(counts).reduce((a, b) => counts[a] > counts[b] ? a : b);
            this.props.changeRole(maxKey);
        } else {
            this.forceUpdate();
        }
    }
    returnQuestions = () => {
        return this.questions.map(c => {
            return <Answer className={c.role} questionId={c.id} answers={c.answers}
                           answersObj={this.props.answersObj}
                           onAnswerChange={this.onAnswerChange}/>
        })
    }

    render() {
        return (
            <div className={'quiz'}>
                {this.returnQuestions()}
                {this.props.role && <RoleChooser role={this.props.role} changeRole={this.props.changeRole}/>}
            </div>
        );
    }
}

export default Quiz;
