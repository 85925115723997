import React, {Component} from 'react';
import './footer.scss'
import {TreeSVG3} from "../icons/svgIcons";

class Footer extends Component {
    render() {
        return (
            <div className={'footer'}>
                <div className={'svg_container'}><TreeSVG3/></div>
                <div className={'content'}>
                    <div>PROJECT COORDINATOR</div>
                    <div>Center for Knowledge Management</div>
                    <br/>
                    <div>11. Oktomvri 25,</div>
                    <div>1000 Skopje, North Macedonia</div>
                    <a target="_blank" href={'https://knowledge-center.org'}>https://knowledge-center.org</a>
                    <br/>
                    <a target="_blank" href={'mailto:info@knowledge-center.org'}>info@knowledge-center.org</a>
                </div>
                <div className={'content'}>
                    <div className={'img_container'}>
                        <img src="https://nwow4net-zero.eu/images/NWOW4NetZero/Logo-EU-EN-Izquierda.png"
                             title="Erasmus Logo" alt="Erasmus Logo"/>
                    </div>
                    <div className={'img_container'}>
                        <img src="https://nwow4net-zero.eu/images/NWOW4NetZero/NA-LogoEN.jpg" title="NA Logo"
                             alt="NA Logo"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
