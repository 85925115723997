export const hybrid = {
    en: {
        learningObjectives: {
            heading: "Learning Objectives",
            html: `
<div>
    
    <p><span><span><span><span><span><span><span style="color:#374151">The aim is to empower HR professionals to lead the way in creating a hybrid work environment that not only supports organisational goals but also contributes positively to environmental sustainability.&nbsp;</span></span></span></span></span></span></span>
    </p>

    <p><span style="font-size:1.25em;"><strong><span style="color:#374151">Hybrid Work Leadership and Strategy Competence: &nbsp;</span></strong></span>
    </p>

    <ul>
        <li><span><span><span><span><span><span><span><span style="color:#374151">Develop the ability to lead and strategize effectively in a hybrid work environment, focusing on the integration of sustainability principles. This includes acquiring skills to collaborate with leadership and cross-functional teams to embed sustainable practices in both remote and office settings. &nbsp;</span></span></span></span></span></span></span></span>
        </li>
        <li><span><span><span><span><span><span><span><span style="color:#374151">Learn to create a strategic vision that aligns hybrid work models with the organisation's sustainability goals. &nbsp;</span></span></span></span></span></span></span></span>
        </li>
        <li><span><span><span><span><span><span><span><span style="color:#374151">Enhance competencies in designing hybrid work policies and practices that reduce the organisation's carbon footprint, such as optimizing remote work schedules to minimize travel and energy consumption. &nbsp;</span></span></span></span></span></span></span></span>
        </li>
        <li><span><span><span><span><span><span><span><span style="color:#374151">Gain insights into evaluating and selecting office locations based on accessibility to sustainable transport and potential for reducing commuting emissions.&nbsp;</span></span></span></span></span></span></span></span>
        </li>
    </ul>

    

    <p><span style="font-size:1.25em;"><strong><span style="color:#374151">Sustainable HR Management in Hybrid Environments: &nbsp;</span></strong></span>
    </p>

    <ul>
        <li><span><span><span><span><span><span><span><span style="color:#374151">Cultivate skills in managing human resources in a way that supports and enhances the effectiveness of hybrid work models, while also promoting sustainability. This includes developing and implementing HR policies that encourage environmentally friendly work practices in both remote and office settings. &nbsp;</span></span></span></span></span></span></span></span>
        </li>
        <li><span><span><span><span><span><span><span><span style="color:#374151">Learn to integrate sustainability into recruitment, training, and performance management, ensuring that staff are equipped and motivated to engage in sustainable practices irrespective of their work location. &nbsp;</span></span></span></span></span></span></span></span>
        </li>
        <li><span><span><span><span><span><span><span><span style="color:#374151">Enhance abilities in using digital tools and communication strategies to effectively engage and support employees in a hybrid work setup. &nbsp;</span></span></span></span></span></span></span></span>
        </li>
    </ul>

    
</div>`
        },
        stakeHolders: {
            heading: "Stakeholders",
            html: `<div>
<p dir="ltr">While in charge of Human Resources Management and Work Organisation, the Human Resources Director/Manager is only partly responsible for matters relating to the New Ways of Working.&nbsp;</p>

<p>In order to contribute to the reduction of company's carbon emissions, the Human Resources Director/Manager has to work closely with different stakeholders, identified as:&nbsp;</p>

<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2 dir="ltr">CEO/Top Management</h2>

<h3>Define a Vision&nbsp;</h3>

<p>CEOs must recognize the leadership role they personally need to play to embed sustainability deep and wide in their organizations. The CEO and Executive Committee have to define a vision, establish a clear line of ethics and values in terms of sustainability, and articulate ambitions for sustainability and carbon reduction. At the end, they answer on what is the commitment, the direction the company is taking.&nbsp;</p>

<p>This will help managers and employees to connect sustainability to company's mission, and to their own daily work.&nbsp;</p>

<h3>Exemplarity / Walk the Talk</h3>

<p>Research shows that exemplarity is crucial and has a strong influence on employees’ change acceptance. It is essential 'to walk the talk' and provide employees with clear examples of what is expected and valued. Exemplarity can be practiced at diverse levels.&nbsp;</p>
</div>

<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Trade Unions&nbsp;</h2>

<p>If you develop a project on NWoW or Hybrid Work using a top down approach, without involving trade unions, without making sure you have ambassadors, and without testing things on the ground, it won't work. You also have to be prepared to go back when you make a mistake and accept to review your plans.</p>
</div>

<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Sustainability Manager/Team</h2>

<p>The Sustainability manager, or team is a key actor to involve in NWoW Projects which aim towards the carbon emission reduction.&nbsp;</p>

<p>Experience sharing is key and the company can initiate or participate in a network of Sustainability managers in a sector, industrial park, or geographic area to share knowledge and good practices.&nbsp;</p>
</div>

<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Maintenance department &nbsp;</h2>

<h3>Combination of Responsibilities&nbsp;</h3>

<p>It is of no coincidence that we are now seeing more and more HR Directors who are also in charge of maintainance of facilities, with the aim of integrating a reflection on occupancy rates, dimensioning, and optimisation of spaces, the adaptation of heating technologies, etc.</p>

<h3>Close Cooperation&nbsp;</h3>

<p>If Human Resources and Facilities remain two separate departments, then it is important to establish close cooperation. Facilities can help to reduce energy consumption through investments in high-efficiency installations, low-polluting alternative energies such as photovoltaic panels e.g.&nbsp;</p>

<p>They can manage heating according to people on-site and/or explore with HR regrouping people on the site or floor/ close site or floor with few people.&nbsp;</p>

<h3>Prospective&nbsp;</h3>

<p>Study future real estate needs, both on the side of employees and companies: the latter may be encouraged to bring together distributed local sites into a more central location, which naturally implies a longer commute.&nbsp;</p>

<p>Coupled with the potential for urban drift, these practices will need to be analysed for their impact on air quality, actual mobility variations, and leisure mobility. It is important to analyse trends in the use of third places (which could maintain residual travel and multiply real estate) and housing extensions: remote work could lead to the acquisition of larger dwellings to ensure a dedicated place for telework.&nbsp;&nbsp;</p>
</div>

<p dir="ltr">To really change behaviors related to work organisation, it is important that companies set up a communication strategy and that the communication is done on a regular basis. The message on sustainability needs to communicated continuously.</p>
</div>`
        },
        culture: {
            heading: "Culture",
            html: `<div>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d621e8b2-0c32-40ec-a524-408f805b4851"><p><strong><span style="font-size:1.5em;">The Culture is Key</span></strong></p>

<p>Acting in favour of a reduction of carbon emissions involves the cultural variable. It is truly through work on the organisational and managerial culture, as well as on more rational use of travel and IT tools, for example, that we can achieve changes in behaviour and consumption that will make the balance more positive for the environment.</p>

<p>Check the consistency of company's environmental policy with its overall culture. How can this be done? By examining the practices, habits, and incentives already in place to check whether they are consistent or contradictory with the environmental strategy.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-ec71dbde-552a-4919-89c6-8adb3e59180f"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-659b0e3914c5e.png" alt="" title="Culture is Key" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4db97028-02a0-45df-b3dc-a2400e1f6992"><p><span style="font-size:1.5em;"><strong>Focus On the Right Thing</strong></span></p>

<p>Remote work is not intrinsically "better" for the environment. There is just as much research showing that this would be the case, as there is research demonstrating the opposite. The company, for example, is not going to stop heating and lighting the workspaces because some employees are working elsewhere, at home, or in another space.&nbsp;</p>

<p>The company's energy consumption might therefore remain the same. But the energy consumption at workers' homes will consequently be higher. There is, of course, a seasonal effect here, which can vary from one country to another – even though heating can be replaced by air conditioning.&nbsp;</p>

<p>In addition, several studies show that travel is not eliminated and that there is even a tendency to travel more, such as running a small errand, continuing to drop/pick up the children from the nursery or school, going out for lunch, etc. There would thus be additional travel, but shorter trips. As a result, in the end, we are talking about an "uncertain" eco-balance.&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e93095af-95fc-4a5d-b59d-b465dbb8ebde"><p><span style="font-size:1.5em;"><strong>Raising Awareness</strong></span></p>

<p>Awareness raising and education on workplace behaviours based on scientific evidence are one of the main levers available to HRDs. Informing employees about the environmental cost of their work behaviour is always interesting. It is even better when this awareness-raising, information, or training is driven by a strategy, in line with the activities, and/or with the ambitions of the company to achieve carbon neutrality.&nbsp;</p>

<p>This triggers the multiplication of initiatives.</p>

<p>Usually, the focus on remote/hybrid work is mainly placed on well-being/work organisation, and less on sustainability. Inform and train employees on the stakes of climate change and on the impact their work behaviour can have on carbon emission reduction. Awareness is facilitated by constant interaction and dialogue.&nbsp;&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a2f1c3ca-765a-48bc-9ae8-5bf7b0657d0b"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-659b0e6194c3e.png" alt="" title="Raising Awareness" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-56f5683d-ac8f-457a-aa74-6ceab16d8394"><p><strong><span style="font-size:1.5em;">Initiate a Participatory Approach</span></strong></p>

<p>Develop a participatory approach involving employees, who are sensitive to, or active in the field of sustainability, and make them ambassadors to get other employees on board.&nbsp;</p>

<ul dir="ltr">
\t<li>collecting ideas, \`</li>
\t<li>sharing best practices,</li>
\t<li>making proposals,&nbsp;</li>
\t<li>etc.&nbsp;</li>
</ul>

<p>Several studies conducted in Western countries in 2022 have shown that most employees see the value in hybrid work and that work flexibility is one of the most important factors in deciding whether to accept a job offer. The norm that is gradually emerging in large companies is 3 days in the office per week.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d36a5e02-5941-4954-acb4-08a1432622b7"><p><strong><span style="font-size:1.5em;">Pay Attention to Tensions &nbsp;</span></strong></p>

<p>You need to identify the tensions that will emerge when it comes to pursuing both economic performance and social and environmental performance objectives, in both short-term logic - which characterises organisations today - and longer-term logic - in which the notion of sustainability is anchored.</p>
</div></div>`
        },
        hr: {
            heading: "HR Policies",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e9287db0-3f7f-4113-88d3-e2117c140a0e">

</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-47f54b42-11d9-482f-8720-84285c76de62"><p dir="ltr"><strong><span style="font-size:1.5em;">HR POLICIES</span></strong></p>

<p>It is important to <strong>develop an HR policy to raise awareness on sustainability</strong> and try to shape to some extent the organization's actions in this area at several stages: onboarding, ongoing training, performance management, compensation.</p>

<p dir="ltr">Before developing the HR Sustainability policy conduct a cross-sectional study that involves NWoW, and its impact on employee well-being and sustainable development goals.&nbsp;</p>



<p dir="ltr">While developing the HR policies ensure coherence of all policies to the objectives of carbon emission reduction.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-40fb375e-8fd6-46af-9670-4af0cb630dbb"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a000f9022c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-51d2007f-0995-4d97-be89-ede2d14b5c4b"><p><strong><span style="font-size:1.5em;">RECRUITMENT</span></strong></p>

<p><strong>Evaluate candidates’ awareness and values related to sustainable development and their compatibility with the ambitions and objectives of the organisation</strong></p>

<p>When hiring, for example, will we look at candidates’ carbon footprint? Will we check whether their behaviour is aligned with the organisation's ambitions?</p>

<p>The challenges linked to the climate constitute a formidable lever of attractiveness for candidates in search of meaning and motivation for employees who are already committed to the issue.&nbsp;</p>

<p>That said, it is increasingly the candidates and workers who come up with these questions: what is the company’s mobility offer, is there a remote working policy, what initiatives are in place to promote sustainable development, etc.? If the company does not have a clear policy, it will be condemned to improvisation and to case-by-case responses, which may fail to attract promising candidates.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-82f5014d-e54b-4a0f-8cab-1a43a166b8b1"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a005f92b37.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0c101637-6a96-4185-86a2-7b6ad760abbd"><p><strong><span style="font-size:1.5em;">TRAINING</span></strong></p>

<p><strong>Explain why sustainable transformation is an issue for companies and employees.&nbsp;</strong></p>

<p dir="ltr"><strong>The first challenge </strong>is about understanding what is at stake, starting with science: what scientists are saying about climate change, natural resources,&nbsp;biodiversity and the impact on our lives - communities, businesses, individuals.&nbsp;</p>

<p><strong>The second challenge</strong> is about action: sustainable transformation affects all professions.&nbsp;Job by job, you have to understand how, then draw up your roadmap for action thanks to specific courses depending on whether you work in Purchasing, IT, legal, Finance, Marketing, HR, etc."&nbsp;&nbsp;</p>

<p><strong>Train managers</strong> to manage the commitment of employees: how do you react when, for example, the best salesperson, when coming to a seminar with the team, reminds them that they should no longer fly?</p>

<p>Introduce in libraries of competences the ecological dimensions.</p>

<p>Anticipate tomorrow's skill requirements: what will be the skills of the new accountant, the new IT specialist, the new buyer, the new HR, etc. in the context of sustainable transformation?</p>

<p>Integrate the environmental criterion into the choice of service providers, particularly for training. It is always possible to inform and challenge service providers on this criterion of choice, in order to encourage them to take action.&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8e9a2632-788a-489a-a9d0-a5cec6137a3a"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a00b6f3b38.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-78bed962-f188-4975-8f73-b0dea716bef1"><p><strong><span style="font-size:1.5em;">PERFORMANCE MANAGEMENT &amp; EVALUATION</span></strong></p>

<p>Companies are increasingly setting sustainable development objectives for themselves, which must be implemented in departments and teams in order to be achieved. It is therefore important to evaluate what managers do, with their teams, to achieve those objectives.&nbsp;</p>

<p><strong>Integrate sustainable development issues in the annual objectives of employees and in their skills development plan.&nbsp;</strong></p>

<p>Very few companies to date translate these sustainability objectives into performance indicators. When they do, they generally refer to the 17 United Nations Sustainable Development Goals, but it needs to be much more concrete than that.&nbsp;</p>

<p>Take it as far as daily behaviour: take the sustainability aspect into consideration when organising a meeting, when organising a company party, when deciding on a training offer, when making choices about travel, etc.&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-bcf69465-7d98-4b54-8f57-16c62f1e31cf"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a010e1bb7c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c5702d04-36d7-48a5-bdc3-dfd6ae0b7e6e"><p><strong><span style="font-size:1.5em;">REMUNERATION</span></strong></p>

<p><strong>Incentivize and appreciate green behaviors.&nbsp;</strong></p>

<p>Whether in the choice of benefits that make up the salary package, or in the integration of criteria linked to sustainable development in the remuneration of directors, managers, and even of all employees. If employees achieve their results, but at the expense of the environment, will the bonus be questioned?</p>




</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-31d857a2-1f29-40e7-bf53-f14345717159"><p><strong><span style="font-size:1.5em;">WORKING FLEXIBILITY</span></strong></p>

<p>Offer alternative workplaces as close as possible to where employees live (third places, coworking spaces, etc.). coworking spaces, etc.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-335b1072-9195-49d8-bb14-1838c832c36b"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a013f7d826.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-038d68de-3a7b-450d-9248-7cc305cdf537"><p><strong><span style="font-size:1.5em;">COMMUNICATION</span></strong></p>

<p>Communicate on a regular basis rather than on an ad hoc basis. Annual communication on the subject will not, for example, be enough if you want to get your employees on board and involved.</p>

<p>Be authentic and transparent, especially about the progress that remains to be made. Your discourse must be aligned with the reality of the company's climate trajectory, or it will not be well received by your employees.</p>

<p>Target your communications to specific topics. For example: "toolkit to act on a business level for the ecological transition"; "how to invest your employee savings in funds serving the environment"; etc.</p>

<p>Diversify the formats (memo, video, podcast, presentation, etc.) and channels used (email, intranet, corporate social network, road show, etc.) in order to maximise the reach of your messages.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-c0f62eee-721d-477c-80c6-823a977066d4"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a017fdc062.jpg" alt="" title="" style="width: 100%; height: 100%;"></div></div>`
        },
        tools: {
            heading: "Tools",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-fafe62b2-6e1d-4dab-8614-5eae029317ac">


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9f6a9bf2-ee6b-4dea-beb7-8f269fdd47e6"><p dir="ltr"><strong><span style="font-size:1.5em;">Establish a Sustainability Initiative Task Force or Green Team/Ambassadors</span></strong></p>

<p dir="ltr">Not much can be done without motivated people behind it. Identify the likely change agents in the organisation, and if possible try to locate them at several levels and departments. Try to solicit them for the idea by exploring possibilities and benefits of hybrid work for the reduction of carbon emissions.The attitude is there, the motivation too, the position although purely voluntarily will provide them with a means/title for action within the organisation working well with their intrinsic motivation for taking care of the environment.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-658bbecb-b411-40b2-8187-4d368eb13db1"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a076563db5.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4efad5a4-1ecd-417f-a6e1-c245bfb5ecbe"><p><strong><span style="font-size:1.5em;">Audit Office-Related Consumption </span></strong></p>

<p>Some examples of actions below (source: <a target="_blank" href="http://www.sustainablebusinesstoolkit.com">www.sustainablebusinesstoolkit.com</a>):</p>

<ol>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Eliminate Single-Use Cups:</strong> Single-use cups are harmful to the environment. Plastic and Styrofoam cups are not biodegradable can spend years in landfills or make their way into our oceans and rivers. Try carrying around a reusable bottle or thermos for your water or coffee. If quitting single-use cups altogether is too jarring, then consider using environmentally friendly cups instead.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Institute a BYORB policy </strong>(Bring Your Own Reusable Bottle)</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Use Recycled Printing Paper:</strong> Putting recycled paper in your printers is an easy-go-green idea. With today's technology, you and your colleagues won't even notice the difference.&nbsp;</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Print Double-sided:</strong> Make sure your computer and printer are automatically set to print double-sided. According to the research, printing double-sided can reduce your paper waste by 10-40%.&nbsp;</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Go Paperless </strong></p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Try Recycled and Biodegradable pens</strong>, rather than throwing away plastic pens.&nbsp;</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Decrease the Brightness on Your Computer Monitor.</strong> According to a Harvard University professor, reducing your computer monitor’s brightness from 100% to 70% can save up to 20% of the monitor's energy. Most people will not notice the difference from making this adjustment, so it is a quick and easy-go-green idea.&nbsp;</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Turn Off Your Computer Monitor at Night</strong> to save energy.&nbsp;</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Implement Waste Walks.</strong> A Waste Walk is when employees take a step back, walk the floor and identify wasteful activities. It is a fun way to get everyone involved in creating ideas to decrease your office’s impact on the environment. Waste Walks don’t require additional funds, resources, or third parties. They take advantage of everyone’s brainpower to identify low hanging fruit and make improvements.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Get Rid of Individual Trash Cans:</strong> Some offices have trash cans at every individual’s desk. You will increase your recycling rates and reduce waste after removing individual trash cans and utilizing central trash cans instead.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Buy Eco-friendly and Responsibly Sourced Coffee.</strong></p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Optimize the Use of Natural Light. </strong>If your office has windows, then be sure to optimize natural light. Avoid turning on light bulbs during the day by keeping curtains, shades, and blinds open.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Invest in Energy-Efficient Lighting: </strong>Using energy-efficient lights saves electricity and money. Energy-efficient lights come in all shapes and sizes. There is something for every need and every budget!</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Invest in Smart Plugs:</strong> Offices can automatically save energy by using smart plugs. Smart plugs turn off lights, fans, and appliances automatically. Most smart plugs allow you to control your devices from anywhere using an app on your phone.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Adjust Your Thermostat 2 degrees up in the summer and 2 degrees down in the winter. </strong>Alternatively, you could use an energy-efficient thermostat that conserves energy by itself.&nbsp;</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Encourage Water Saving with Tips and Instructions in Bathrooms. </strong>Try to swap the signs out and use creative designs to keep the message fresh.</p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Choose repairing over replacing.</strong></p>
\t</li>
\t<li aria-level="3" dir="ltr">
\t<p dir="ltr" role="presentation"><strong>Organize green competition events with your team</strong></p>
\t</li>
</ol>

<p><strong>Tip: </strong>Taking baby steps instead of drastic changes is the best way to achieve your environmental goals. Rather than incorporating large changes which may overwhelm some of your employees, focus on making one little transformation in each area of your workplace.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-44756050-178f-436e-893f-651f0a73f718"><p><strong><span style="font-size:1.5em;">Carbon Footprint Assessment on Remote Work Policy and Practices</span></strong></p>

<p>A survey by Reuters of 20 major corporations that embrace remote work found that only half had attempted to estimate the emissions associated with remote workers, and even fewer had attempted to mitigate or offset the impact. Even Microsoft—which has an extraordinarily robust corporate sustainability program and started estimating remote worker emissions in 2020—is still trying to get a handle on the carbon impact of its off-site workers.</p>
</div>

</div></div></div></div></div></div>      </div>    </div>  </div>  </div></div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-96673788-aac0-4bb1-a147-1f060a8bad55"><p><strong><span style="font-size:1.5em;">Energy Consumption Indicators </span></strong></p>

<p>There are measurement tools, but they come with several challanges. The first is that the measurement is based on what a site consumes and, often, companies have several sites in very different geographical areas and with very different histories. The second is the need to factor in an entire ecosystem, including workers and suppliers. Bringing them into the equation and having an impact on them is much more complicated.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-72db514d-8a33-4eb4-a525-83ba1cf3c4c2"><p><strong><span style="font-size:1.5em;">Hybrid-Manager Scheduling</span></strong></p>

<p><strong>Adopt a policy that takes into account the overall impacts on travel and the environment (e.g. avoid remote working on Mondays and Fridays, which is used by most companies).&nbsp;</strong></p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a0e4f2b1-4e13-4f99-b95f-0ef8a62df29a"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a0d17a1b25.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-23380b42-0403-427c-ad5c-a2eb83219cfa"><p>The model proposed by David Autissier could be interesting in this respect. It invites us to question the work methods to be considered in terms of face-to-face and remote work through the notions of 'Build' and 'Run' borrowed from agile methods. Remote working works well in the Run - i.e. in known, recurring activities - but is less adapted for the Build - i.e. all activities that involve investment in the future, such as new projects, for example. In other words, we know how to remotely manage the current activity, for which we are processed, but less so the one that requires interactions, exchanges, trust and collective intelligence to get started. In this case, face-to-face will probably be preferable.&nbsp;&nbsp;</p>

<p>As a result, the HR Director can stimulate managers to think with their teams about the times or tasks when it is important for everyone to be there, together, and for what purpose; and about the times and tasks that can be gained by conducting certain activities online. For example, an operational project monitoring meeting will be more effective if conducted online. As for welcoming a new staff member to the team, it is better to do it in person. A carbon impact criterion can then be added to this reflection. This can lead to tasks being grouped together to make a remote work cluster and others to make a face-to-face work cluster, taking into account all the parameters: the number of people involved, the distances they will have to travel, and modes of transportation, office occupancy rates, the impact in terms of digital use, etc.&nbsp;</p>

<p>The idea is not to have a mathematical approach, but rather an opportunistic one: if a group meeting can have a better result than what would be achieved remotely, despite a higher environmental cost, it is probably still relevant to conduct it face-to-face. It will be a question of choice. The interest will be in being able to equip oneself with the means to decide by constantly weighing up the different parameters of work organisation.</p>

<p dir="ltr">&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6ab0511b-b00e-4b8e-af60-873709089171"><p dir="ltr"><strong><span style="font-size:1.5em;">App to Allow Office Space Optimisation Related to Remote/Hybrid Working</span></strong></p>

<p>Various apps exist on the market in helping to minimize effort on workspace arrangement and better manage office layout as well as employee attendance. Please see our selection of tools for impact assessemnt in result 3.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-27fceef1-f99c-4f3a-adc2-ca263f01efff"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a11737328b.png" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-79477eba-a9a7-4660-bb64-90d2f1600a3e"><p><strong><span style="font-size:1.5em;">Organise Regular Workshop/Lunchs &amp; Learn on Sustainability@theoffice</span></strong></p>

<p>Sustainability Workshop is intended to make it easy and accessible for all to learn the broader context of how to use sustainability at the office, and enable them to develop strategies to incorporate sustainability considerations into their daily work.&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-783f311b-b0bc-455e-8ff2-8602b11a7275"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a12151f7ae.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9e69f91d-beba-4166-86c4-9e3c563b3d40"><p><strong><span style="font-size:1.5em;">Climate Fresk</span></strong></p>

<p>In just 3 hours, the collaborative Climate Fresk workshop teaches the fundamental science behind climate change and empowers to take action. By using, activating the group’s collective intelligence, Climate Fresk enables participants to take ownership of the subject matter. The methodology doesn’t use a hierarchical learning structure, allowing everyone to take part and find their place in the exercise. As participants link the cause and effects of climate change, they are able to take a step back and understand the systemic nature of the challenges for the first time.</p>

<p dir="ltr"><strong>More information:</strong> <a target="_blank" href="https://climatefresk.org/" target="_blank">https://climatefresk.org/</a></p>
</div></div>`
        },
        inspiringInitiatives: {
            heading: "Inspiring initiatives",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-bf6fc80e-b7f5-4199-82ff-19ca3e262b6b">
    

</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d50e317c-49f4-4ce7-b827-1e7618ea8802"><p><span style="font-size:1.5em;"><strong>Integrate the carbon footprint of individuals (remote workers) in the calculation of company's carbon footprint.</strong></span></p>

<p>Although individual carbon footprint is not taken into account in the measurement of organisation’s carbon footprint, it needs to be considered in remote working, because the work activities are shifted to the household of the worker. Remote workers will likely increase their individual carbon footprint because they use more electricity and heating, and in some cases these emissions increase more because of adding additional space for work in the household, .</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1fe06bb5-7157-48c0-b078-a9a1bbedf826"><p><strong><span style="font-size:1.5em;">Reduce the number of square meters associated with relocation projects and new technologies in heating and cooling the building.</span></strong></p>

<p>Introducing New Ways of Working actually means reducing the workforce in offices. Existing offices can be remodelled and office space can be reduced in terms of square metres. This is also a good opportunity to invest in more energy efficient heating and cooling technologies.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-2e6c4420-0191-429c-ae35-3c163eee07a7"><p><strong><span style="font-size:1.5em;">Close the headquarters one day a week.</span></strong></p>

<p>Offer alternative solutions for those not interested in homeworking to work in commercial agencies spread throughout the territory, which remain open.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-9bb64ff6-9682-46c1-b4d0-851b3c36cfb3"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-659e73b7cfb38.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-fe2e9f0a-6c9f-4004-a698-7d8150687ee6"><p><strong>Example:</strong></p>

<p><em>Since May 2022, the Belgian insurance company Ethias has closed its national offices (Liege and Hasselt) one day a week and invites its employees to work from home. It also allows homeworking for up to 3 days a week, i.e., 60% of working time. This measure, which is innovative in the Belgian market, enables Ethias to reduce its dependence on fossil fuels. It comes on top of the numerous initiatives deployed over the past few years, which have enabled Ethias to reduce its carbon footprint by a third, four years ahead of schedule.&nbsp;</em></p>

<p dir="ltr"><em>Although the move towards more teleworking is in line with the wishes of the vast majority of employees, those who do not wish to do so still have the option of doing their working day in some of the other buildings. "Before streamlining access to its buildings, Ethias took a long view and evaluated several scenarios to ensure that they were beneficial to both employees and the environment. According to CO2Logic's data, the ecological gain of teleworking is positive as soon as the work-home journey is greater than 2 km, which is the case for more than 90% of our employees.</em></p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3316ea8d-10cc-4b2e-8619-da7fe9bbc817"><p><strong><span style="font-size:1.5em;">Development of an app for employees to select the office they will occupy before coming to work.</span></strong></p>

<p>For hybrid work to be efficient, the organisation needs to provide tools to facilitate the use of office space in days when workers come to the office. Developing an application for workers to select the office they will occupy before coming to work is a good solution. There are applications available on the Internet that can easily be personalised for the space the organisation occupies.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c6362667-2265-4e67-bf80-74a50e2a5925"><p><strong><span style="font-size:1.5em;">Proposal: possibility for an employer to invest, with de-taxable remuneration, in solutions that reduce the carbon footprint at the employee’s place.</span></strong></p>

<p>One could imagine converting part of the remuneration, which today is taxable, to pay the bill for the installation of a geothermic installation, a solar panel, a water collector, or a similar type of investment at an employee’s house.&nbsp;&nbsp;</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d7c13367-6a61-421e-a373-eebd7190de95"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650ae2a5b19be.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-706a5767-ffee-4003-b5a9-c2fd99b9978d"><p><strong><span style="font-size:1.5em;">Use gamification.</span></strong></p>

<p>Introduce the 'Power ranger' game to mobilise employees on reduction of energy consumption&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-49e80623-fa73-4a32-ad4b-77f75ce8a5c4"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650a245a56b04.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5ea9601e-15c0-41fa-b006-440a9fd9e737"><p><strong><span style="font-size:1.5em;">Offer personalized counseling.&nbsp;</span></strong></p>

<p>Help to each person as each has a different situation – from helping to change transport mode, to help change at their own place – with a little bit of financial incentive.</p>
</div></div>`
        },
        recommendation: {
            heading: "Recommendation",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9ff39201-b913-41dc-9ad8-c1d24b78e893">


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3427f056-8466-4a95-adec-01f1e32a4592"><p><span style="font-size:1.5em;"><strong>Reorganise Workspaces so That They Consume Less Energy</strong></span></p>

<p>This can be done in several ways, such as the conditions in which equipment is manufactured, the choice of energy supply and lighting regulation, the type of materials used for glazing, the use of thermal insulation, the implementation of heating systems that allow for more targeted use, etc.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6a1ff1da-bea0-45f6-96ee-7326ac1f3395"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650ae72798dfa.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d7f97920-3d31-4127-9d19-2a50d6307c52"><p><strong><span style="font-size:1.5em;">Adapt These Systems According to Activities and Uses</span></strong></p>

<p>This means thinking ahead about how to organise the work and the types of activities to be carried out. Ensuring predictability of remote-work practices to be able to anticipate when people are there or not.&nbsp;</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d04f5047-e710-4960-aea1-5f2dd5c634c4"><p><span style="font-size:1.5em;"><strong>Avoid 'One Size Fits All'&nbsp;</strong></span></p>

<p>What might be beneficial in a company may be much less so at the level of an urban centre - for example, if the latter declines or empties out due to the desertion of its workers... Similarly, not all remote workers are equal: some have a dedicated workspace and some not, for example. The cost of energy proves to be an important variable in the choice of working from home or the office, depending on the mode and duration of travel, for example.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-54956a77-38f6-4873-8ff7-904c45dc5241"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650ae952abe3a.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c741d811-2784-453f-ae4a-6f38fd9c9466"><p><strong><span style="font-size:1.5em;">Good Ideas Often Come From Operational Staff because they are closest to the real work</span></strong></p>

<p>And that's where you can really have an impact. However, the challenge is to maintain consistency. If the participatory approach is isolated or appears to be an isolated initiative, without being part of a broader strategy; or if the corporate message is that the environment is important, but that there are only financial performance KPIs, the approach will not be credible. Beware of greenwashing!</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-aa1ea73d-5992-4018-b240-0879fed576de"><p><strong><span style="font-size:1.5em;">Close Offices on Certain Days of the Week to Save Energy</span></strong></p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-cfe59f92-0f7d-4a1b-8a5b-4dc310c7c7b8"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650ae9e7ad642.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3a5a13fb-d0a4-4437-87c0-8cca2baa698a"><p>If many workers tend to work from home on certain days, organisations may consider closing the office on that day. It is recommended to select consecutive days of closure as it saves more energy. For example closing the Monday after the weekend reduces the energy used in switching on and off the energy consuming systems in the office (i.e. heating and cooling and etc).</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-93509733-1264-40a8-a25b-183892b8d10d"><p><strong><span style="font-size:1.5em;">Combine Remote Work, Flex-Office and Office Space Reduction </span></strong></p>

<p>A study by ADEME (France) shows that when the reductions in real estate that telework brings about when coupled with flex office (the practice of not having a dedicated office in the workplace) are taken into account, the overall environmental balance of telework improves significantly by +52% per day of telework per week (i.e. -234kg eqCO2/year for each additional day of telework per week), thus consolidating its environmental benefits. The environmental benefits of the flex office could exceed the mobility benefits.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-2d3e4c85-3a9c-4d3a-a7e2-d1ea74bf3ce5"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650aea5b69adc.png" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d25f0f5-0bf7-4f85-b059-69b9e42fb240"><p><strong><span style="font-size:1.5em;">Discourage Part-Day Homeworking</span></strong></p>

<p>Remote working both at home and in third place leads to a significantly negative environmental balance.&nbsp;</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-28e9f5f3-2c2d-4dbf-94b1-1e6affc73458"><img src="https://lp.youngkreativ.eu/pluginfile.php/391/mod_hvp/content/93/images/file-650aeaf74c038.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-cfa3ffa5-6b28-41ef-aa8a-4bc8bde9fc55"><p><strong><span style="font-size:1.5em;">Remote Work: Be Careful!&nbsp;&nbsp;</span></strong></p>

<p>A study by ADEME (France) shows that remote work reduces "office" consumption (paper, ink, supplies, cups, decoration, video projectors, etc.) and the company's energy consumption. However, some of these reductions are passed on to the remote worker. In particular, energy consumption at home is increasing. The study estimates that energy consumption at home during the telework day would increase mechanically: +20.7 kg eqCO2/year for one day of telework per week for the teleworker.</p>


</div></div>`,
        }
    },
    mk: {
        learningObjectives: {
            heading: "Целите на учењето",
            html: `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-681e908e-49b8-4ea2-835e-d6b8b485b3e6">   


<p>Целта е да се поттикнат професионалците за човечки ресурси да го водат патот кон создавање хибридна работна средина која не само што ги поддржува организациските цели туку и позитивно придонесува за одржливоста на животната средина.</p>

<p><span style="font-size:1.25em;"><strong>Компетентност за лидерство и стратегија за хибридна работа:</strong></span></p>

<ul>
\t<li>Развијте ја способноста за лидерство и носете ефективни планови во хибридната работна средина, фокусирајќи се на интеграцијата на принципите за одржливост. Ова вклучува стекнување вештини за соработка со водечки и мултифункционални тимови за да се вградат одржливи практики како во далечински, така и во канцелариски услови.</li>
\t<li>Научете да креирате стратешка визија која ги усогласува хибридните работни модели со целите за одржливост на организацијата.</li>
\t<li>Подобрете ги компетенциите во дизајнирањето хибридни работни политики и практики кои ја намалуваат јаглеродната потрошувачка на организацијата, како на пример подобрување на распоредите за работа од дома, со цел трошоците за патување и потрошувачката на енергија да се сведат на минимум.</li>
\t<li>Земете го во предвид оценувањето и изборот на канцелариски локации врз основа на пристапноста до одржлив транспорт и потенцијалот за намалување на емисиите при патување.</li>
</ul>

<p><span style="font-size:1.25em;"><strong>Одржливо управување со човечки ресурси во хибридните средини:</strong></span></p>

<ul>
\t<li>Негувајте вештини за управување со човечките ресурси на начин кој ја поддржува и подобрува ефективноста на хибридните работни модели, а истовремено промовира одржливост. Ова вклучува развивање и имплементирање на политики за човечки ресурси кои поттикнуваат еколошки работни практики како во далечинските, така и во канцелариските работни услови.</li>
\t<li>Научете да ја интегрирате одржливоста во регрутирањето, обуката и управувањето со перформансите, осигурувајќи дека персоналот е опремен и мотивиран да се вклучи во одржливи практики без оглед на нивната работна локација.</li>
\t<li>Зголемете ги способностите за користење на дигитални алатки и комуникациски стратегии за ефективно ангажирање и поддршка на вработените во хибридни работни услови.</li>
</ul>
</div>`
        },
        stakeHolders: {
            heading: "Засегнати страни",
            html: `<div>
<p>Директорот/менаџерот за човечки ресурси е само делумно одговорен за прашањата поврзани со новите начини на работа иако е одговорен за управување со човечки ресурси и организацијата на работа.</p>
<p>Со цел да придонесе за намалување на емисиите на јаглерод на компанијата, директорот/менаџерот за човечки ресурси треба тесно да соработува со различни засегнати страни, кои се идентификувани како:&nbsp;</p>
<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2 dir="ltr">Извршен директор / Врвен менаџмент</h2>

<h3>Дефинирајте визија</h3>

<p>Извршните директори мора да ја препознаат водечката улога што лично треба да ја преземат за да ја вградат одржливоста подлабоко и посеопфатно во нивните организации. Извршниот директор и управниот одбор треба да дефинираат визија, да воспостават јасна линија на етика и вредности во однос на одржливоста и да изразат амбиции за одржливост и за намалување на емисиите на јаглерод. Во таа насока каква обврска, треба да превземе компанијата?</p>

<p>Одговорот ќе им помогне на менаџерите и вработените да ја поврзат одржливоста со мисијата на компанијата, а потоа и со нивните секојдневни работни обврски.&nbsp;</p>

<h3>Примерност / Разговор со вработените</h3>

<p>Истражувањата покажуваат дека примерноста е критична и има силно влијание врз прифаќањето на промената од страна на вработените. Од суштинско значење е да се &nbsp;разговара во вработените &nbsp;и да им се обезбедат јасни примери за тоа што се очекува од нив и дека тоа ќе се вреднува. Примерноста може да биде&nbsp; практикувана на различни нивоа по работни места, и од различни вработени.</p>
</div>

<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Синдикати</h2>

<p>Ако воспоставувате проект за новите начини на работа или хибридна работа по директива одозгора надолу, без да ги вклучите синдикатите, без да ги вклучите вработените преку нивни амбасадори, и без претходно да ги тестирате работите на терен, нема да функционира. Исто така, мора да бидете подготвени да се вратите наназад кога ќе направите грешка и да прифатите да ги ревидирате вашите планови.</p>
</div>

<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Менаџер / Тим за одржливост</h2>

<p>Менаџерот или тимот за одржливост се клучни актери кои треба да се вклучат во проекти за новите начини на работа со цел намалување на емисиите на јаглерод.</p>

<p>Споделувањето на искуството е од клучно значење и компанијата може да иницира или да учествува во мрежа на менаџери за одржливост во секторот, индустриската гранка или во опкружувањето во областа, со цел споделување знаења и добри практики.&nbsp;</p>
</div>

<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Оддржување - Објекти на компанијата</h2>

<h3>Комбинација на одговорности</h3>

<p>Не случајно сега гледаме сè повеќе директори/менаџери &nbsp;за човечки ресурси кои исто така се задолжени за канцелариите или другите објекти на компанијата. Вклучени се со цел да се добие мислење за интегрирање на стапките на искористеност, димензионирање и оптимизација на просторите, адаптација на апаратите за греење итн.</p>

<h3>Блиска соработка</h3>

<p>Ако вработените во објектите се во две посебни одделенија, тогаш е важно да се воспостави тесна соработка. Во објектите може да се помогне да се намали потрошувачката на енергија преку инвестиции во високоефикасни инсталации, со алтернативни извори на енергија со ниско загадување, како што се фотоволтаичните панели.</p>

<p>Може да се управува со греењето според луѓето на локацијата и/или да се комбинира со прегрупирање на вработените на локацијата или на друг кат/оддел/блиската локација каде има помалку луѓе,вработени.</p>

<h3>Предвидување на потреби</h3>

<p>Проучете ги потребите за недвижнини за во иднина, да ги имате на ум за потребите на вработените како и за потребите на компанијата: вторите може да се охрабрат да ги здружат дисперзираните локации на поцентрално место, што природно &nbsp;ќе влијае и на подолго&nbsp; патување на вработените.&nbsp;</p>

<p>Во предвид треба да биде земен и урбаниот нанос, и овие практики ќе треба да се анализираат од аспект на нивното влијание врз квалитетот на воздухот, реалните варијации во движењето и генерално движењето во слободното време. Важно е да се анализираат практиките за користење на други места (што може да го намали патувањето до работа но да се го зголеми недвижниот имот) и проширувањата на домувањето: работата на далечина може да доведе до потреба од купување на поголеми живеалишта за вработените за да имаат дедицирано место за работа од дома.</p>
</div>

<p dir="ltr">За да се промени навистина однесувањето поврзано со организацијата на работата, важно е компаниите да воспостават комуникациска стратегија и да се применуваат активности на редовна основа. Во таа насока, мора постојано да се пренесува пораката до вработените.</p>
</div>`
        },
        culture: {
            heading: "Култура",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-51fdc91f-d822-4585-b029-c3132b46ab15">

</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d621e8b2-0c32-40ec-a524-408f805b4851"><p><strong><span style="font-size:1.5em;">Културата е клучна</span></strong></p>

<p>Дејствувањето во корист на намалување на емисиите на јаглерод нужно ја вклучува културната промена. Преку работата на организациската и менаџерската култура, како и на порационална употреба на мобилноста и ИТ алатките, на пример, навистина можеме да постигнеме промени во однесувањето и потрошувачката, што ќе направат попозитивен баланс за животната средина.</p>

<p>Проверете ја конзистентноста на политиката за животна средина на компанијата со нејзината севкупна култура. Како може да се направи ова? Со истражување на веќе постоечките практики, навики и бенефиции за да се провери дали тие се во согласност или се контрадикторни со стратегијата за животна средина.&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-ec71dbde-552a-4919-89c6-8adb3e59180f"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-659b0e3914c5e.png" alt="" title="Culture is Key" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4db97028-02a0-45df-b3dc-a2400e1f6992"><p><span style="font-size:1.5em;"><strong>Фокусирајте се на вистинската работа</strong></span></p>

<p>Работата на далечина не е суштински „подобра“ за околината. Постојат истражувања кои го покажуваат спротивното. Компанијата, на пример, нема да престане да ги загрева и осветлува работните простори, и покрај тоа што некои вработени работат на друго место, дома или во друг простор.</p>

<p>Според тоа, потрошувачката на енергија на компанијата може да остане иста. Но, потрошувачката на енергија во домовите на работниците последователно ќе биде поголема. Овде секако има сезонски ефект, кој може да варира од една до друга земја - иако греењето може да се замени со клима уред.</p>

<p>Дополнително, неколку студии покажуваат дека патувањето на вработените не е елиминирано и дека дури има тенденција да се патува повеќе, како што е извршување на мала задача или потреба, која е поврзана со &nbsp;пократко патување. Од таа причина, зборуваме за „неизвесна“ еко рамнотежа.&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e93095af-95fc-4a5d-b59d-b465dbb8ebde"><p><span style="font-size:1.5em;"><strong>Зголемете ја свеста</strong></span></p>

<p>Подигнувањето на свеста и едукацијата за однесувањето на работното место засновано на научни докази е едно од главните премостувања во рамките на управувањето со човечките ресурси. Информирањето на вработените за еколошките трошоци на нивниото работно однесување е секогаш интересно. Уште подобро е кога подигнувањето на свеста, споделувањето на информации и обука за вработените се водени во рамките на стратегија, во согласност со активностите и/или со амбициите на компанијата со цел да се постигне јаглеродна неутралност.</p>

<p>Ова ќе озвозможи и зголемување на иницијативите.</p>

<p>Обично, целта на далечинско/хибридното работење во организацијата е постигнување благосостојба кај вработените. Ппомалку е за воспоставување одржливост. Информирајте ги и обучете ги вработените за влогот кои го има компанијата во насока на &nbsp;климатските промени и за влијанието кое нивната работа може да го има врз јаглеродните емисии. Зголемувањето на свеста за влијанието врз животната средина се воспоставува со постојана интеракција и дијалог.&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a2f1c3ca-765a-48bc-9ae8-5bf7b0657d0b"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-659b0e6194c3e.png" alt="" title="Зголемете ја свеста" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-56f5683d-ac8f-457a-aa74-6ceab16d8394"><p><strong><span style="font-size:1.5em;">Иницирајте партиципативен пристап</span></strong></p>

<p>Развијте партиципативен пристап кој ги вклучува вработените кои се чувствителни или активни во полето на одржливоста за да ги направите амбасадори кои ќе вклучат и други вработени преку:</p>

<ul dir="ltr">
\t<li>Собирање на идеи</li>
\t<li>Споделување на добри практики</li>
\t<li>Подготовка на предлози</li>
\t<li>итн.&nbsp;</li>
</ul>

<p>Неколку студии спроведени во западните земји во 2022-ра година покажаа дека повеќето вработени гледаат вредност во хибридната работа и дека флексибилноста е еден од најважните фактори при одлучувањето дали да прифатат таква понуда за работа. Нормата која постепено се појавува во големите компании е спроведување на три дневна работна недела.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d36a5e02-5941-4954-acb4-08a1432622b7"><p><strong><span style="font-size:1.5em;">Обрнете внимание на тензиите</span></strong></p>

<p>Треба да се идентификуваат тензиите што ќе се појават помеѓу спроведување на економските перформанси и општествените и еколошки цели на изведбата, во двете краткорочната логика која ги карактеризираат современите организации, и &nbsp;долгорочната логика во која одржливоста е засидрена.</p>
</div></div>`
        },
        hr: {
            heading: "Политики за HR",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e9287db0-3f7f-4113-88d3-e2117c140a0e">

</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-47f54b42-11d9-482f-8720-84285c76de62"><p dir="ltr"><strong><span style="font-size:1.5em;">Програми за човечки ресурси</span></strong></p>

<p>Важно е да се развие програма за човечките ресурси која ќе ја подигне свеста за прашањата за одржливост и ќе се обиде до одреден степен да ги обликува активностите на организацијата во оваа област во неколку фази: вградување, постојана обука, управување со перформансите, компензација.</p>

<p>Пред да ја развиете програмата за одржливост на човечките ресурси, спроведете пресек студија за влијанието на новите нашини на работа врз благосостојбата на вработените но и целите за одржлив развој.</p>

<p>Додека ја развивате програмата обезбедете усогласеност на сите политики за управување со човечките ресурси со целите за намалување на&nbsp;јаглеродните емисии.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-40fb375e-8fd6-46af-9670-4af0cb630dbb"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a000f9022c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-51d2007f-0995-4d97-be89-ede2d14b5c4b"><p><strong><span style="font-size:1.5em;">Регрутација</span></strong></p>

<p><strong>Оценувајте ги кандидатите согласно нивната свест и вредностите поврзани со одржливиот развој и нивната компатибилност со амбициите и целите на организацијата</strong></p>

<p>При вработување, на пример, дали ќе разгледате дали кандидатот има влијание врз за јаглеродните емисии? Дали ќе проверите дали однесувањето на кандидатот е усогласено со амбициите на организацијата во однос на јаглеродните емисии?</p>

<p>Предизвиците поврзани со климатските промени претставуваат причина за привлекување на кандидати кои се во потрага по значење како и за &nbsp;мотивација за вработените кои веќе се посветени на ова прашање.</p>

<p>Но, покрај се, сè повеќе кандидати и вработени ги поставуваат овие прашања: Каква е понудата за мобилност на компанијата, дали постои политика за работа на далечина, какви иницијативи постојат за промовирање на одржливиот развој итн.? Доколку компанијата нема јасна политика во рамките на овие прашања, таа ќе биде осудена на импровизација на одговори, различни од случај до случај, што може да го одбие талентот.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-82f5014d-e54b-4a0f-8cab-1a43a166b8b1"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a005f92b37.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0c101637-6a96-4185-86a2-7b6ad760abbd"><p><strong><span style="font-size:1.5em;">Обука</span></strong></p>

<p><strong>Објаснете зошто одржливата трансформација е потреба за компаниите и вработените</strong></p>

<p dir="ltr"><strong>Првиот предизвик </strong>е да се разбере што е клучно во рамките на ова прашање, почнувајќи од принципите на науката: што велат научниците за климатските промени, природните ресурси, биодиверзитетот и влијанието врз нашите животи - заедници, бизниси, поединци.</p>

<p><strong>Вториот предизвик </strong>е поврзан со акција: одржливата трансформација влијае на сите професии.Со преглед низ работните места, позиција по позиција, треба да разберете како да го подготвите вашиот патоказ за акција, &nbsp;со помош на специфични обуки во зависност од тоа дали работите во: Набавки, ИТ, Правна служба, Финансии, Маркетинг, Човечки ресурси итн.</p>

<p><strong>Обучете го менаџерот</strong> да управува со посветеноста,мотивираноста &nbsp;на вработените: како реагирате кога, на пример, најдобриот продавач, кога доаѓа на семинар со тимот, ќе ги потсети дека повеќе нема да патуваат со авион?</p>

<p>Воведете ги во профилирањето на компетенциите и еколошки димензии</p>

<p>Предвидете ги утрешните побарувања за вештини: кои ќе бидат вештините на новиот сметководител, новиот ИТ специјалист, новиот клиент, новите менаџери за човечки ресурси итн. во контекст на одржлива трансформација?</p>

<p>Интегрирајте го еколошкиот критериум во изборот на даватели на услуги, особено за спроведувањето на обуките. Секогаш е можно да се информираат и поттикнат давателите на услуги за овој критериум на избор, со цел да се влијае да преземат акција. Изберете критериум за избор со цел да се охрабрат да дејствуваат во таа насока.&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8e9a2632-788a-489a-a9d0-a5cec6137a3a"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a00b6f3b38.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-78bed962-f188-4975-8f73-b0dea716bef1"><p><strong><span style="font-size:1.5em;">Управување и оценување на перформансите</span></strong></p>

<p>Компаниите се повеќе поставуваат цели за одржлив развој, кои мора да се имплементираат преку одделенијата и тимовите и истите се бара да се постигнат. Затоа е важно да се оцени што прават менаџерите со своите тимови за да ги постигнат тие цели.</p>

<p><strong>Интегрирајте ги прашањата за одржливиот развој во годишните планови на вработените и во нивнот план за развој на вештини.</strong></p>

<p>Многу малку компании до денес ги преточуваат овие цели за одржливост во индикаторите за мерење на успешност. Кога го прават тоа, тие генерално се повикуваат на 17-те цели за одржлив развој на Обединетите нации, но тоа нивната акција треба да биде многу поконкретна од тоа.</p>

<p>Целите треба да се имплементираат низ секојдневното однесување: земете го во предвид аспектот на одржливост кога организирате состанок, кога организирате забава во компанијата, кога одлучувате за спроведување обука, кога правите избор за патување итн.&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-bcf69465-7d98-4b54-8f57-16c62f1e31cf"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a010e1bb7c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c5702d04-36d7-48a5-bdc3-dfd6ae0b7e6e"><p><span style="font-size: 19.5px;">Плата и надомест</span></p>

<p>Без оглед дали во изборот на бенефиции што го сочинуваат пакетот на плати или преку интегрирање на критериумите поврзани со одржливиот развој, воведете наградување за директорите, менаџерите, па дури и за сите вработени поврзани со оддржливите цели. Ако вработените ги постигнат своите резултати, но на сметка на животната средина, дали бонусот ќе биде даден?</p>

<p><strong>Стимулирајте го и ценете го зеленото однесување на вработените.</strong></p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-31d857a2-1f29-40e7-bf53-f14345717159"><p><strong><span style="font-size:1.5em;">Флексибилност на работа</span></strong></p>

<p>Понудете алтернативни работни места што е можно поблиску до местото каде што живеат вработените (нови локациски места, заеднички простории, итн.)</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-335b1072-9195-49d8-bb14-1838c832c36b"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a013f7d826.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-038d68de-3a7b-450d-9248-7cc305cdf537"><p><strong><span style="font-size:1.5em;">Комуникација</span></strong></p>

<p>Комуницирајте на редовна основа наместо по потреба. &nbsp;Годишната комуникација на оваа тема, на пример, нема да биде доволна ако сакате да ги вклучите вашите вработени.</p>

<p>Бидете автентични и транспарентни, особено за напредокот што треба да се постигне. Вашиот дискурс мора да биде усогласен со реалноста на климатскиот напредок на компанијата или нема да биде добро прифатен од вашите вработени.</p>

<p>Насочете ги вашите комуникации на одредени теми. На пример воведување „Комплет со алатки за дејствување на деловно ниво за еколошката транзиција“; „Како да ги инвестирате заштедите на вашите вработени во фондови кои служат на животната средина“; итн.</p>

<p>Направете диверзификација на форматите за комуникација &nbsp;(белешка, видео, подкаст, презентација, итн.) и преку користените канали (е-пошта, интранет, корпоративна социјална мрежа, итн.) со цел да го максимизирате дофатот на вашите пораки.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-c0f62eee-721d-477c-80c6-823a977066d4"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a017fdc062.jpg" alt="" title="" style="width: 100%; height: 100%;"></div></div>`
        },
        tools: {
            heading: "Алатки",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-fafe62b2-6e1d-4dab-8614-5eae029317ac">


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9f6a9bf2-ee6b-4dea-beb7-8f269fdd47e6"><p dir="ltr"><strong><span style="font-size:1.5em;">Воспоставете работна група за иницијатива за одржливост или зелен тим на амбасадори</span></strong></p>

<p dir="ltr">Не може многу да се направи без мотивирани луѓе. Идентификувајте ги агентите за промена во организацијата и ако е можно обидете се да ги лоцирате на неколку нивоа и сектори. Обидете се да ги поттикнете за идејата со истражување на можностите и придобивките од хибридната работа за намалување на јаглеродните емисии. Ставот е тука, мотивацијата исто така, позицијата иако чисто доброволна ќе им обезбеди средства за дејствување во организацијата влечејќи од внатрешна мотивација за грижа за животната средина.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-658bbecb-b411-40b2-8187-4d368eb13db1"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a076563db5.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4efad5a4-1ecd-417f-a6e1-c245bfb5ecbe"><p><strong><span style="font-size:1.5em;">Воведете канцеларија за ревизија на потрошувачката</span></strong></p>

<p>Некои примери за активности (извор&nbsp; <a target="_blank" href="http://www.sustainablebusinesstoolkit.com">www.sustainablebusinesstoolkit.com</a>):</p>

<ol>
\t<li>
\t<p><strong>Елиминирајте ги чашите за еднократна употреба:</strong> Чашите за еднократна употреба се штетни за околината. Пластичните и стиропорните чаши не се биоразградливи, и може да поминат години на депоните неразградени или да се пробијат во нашите океани и реки. Обидете се да носите шише за повеќекратна употреба или термос за вашата вода или кафе. Ако целосното откажување од чашите за еднократна употреба е неприфатливо во моментот, тогаш размислете наместо тоа да користите еколошки чаши.</p>
\t</li>
\t<li>
\t<p><strong>Воспоставете политика за “Носење сопствено шише за повеќекратно користење”</strong></p>
\t</li>
\t<li>
\t<p><strong>Користете рециклирана хартија за печатење: </strong>Ставањето рециклирана хартија во вашите печатачи е лесно применлива &nbsp;идеја. Со денешната технологија, вие и вашите колеги нема ни да ја забележите разликата.</p>
\t</li>
\t<li>
\t<p><strong>Печатење двострано:</strong> проверете дали компјутерот и печатарот се автоматски поставени да печатат двострано. Според истражувањето, двостраното печатење може да го намали отпадот од хартија за 10-40%.</p>
\t</li>
\t<li>
\t<p><strong>Обидете се да трошите помалку хартија</strong></p>
\t</li>
\t<li>
\t<p><strong>Обидете се да употребувате рециклирани и биоразградливи пенкала, наместо да фрлате пластични пенкала.</strong></p>
\t</li>
\t<li>
\t<p><strong>Намалете ја осветленоста на мониторот на вашиот компјутер.</strong> Според професори од Универзитетот Харвард, намалувањето на осветленоста на вашиот компјутерски монитор од 100% на 70% може да заштеди до 20% од енергијата на мониторот. Повеќето луѓе нема да ја забележат разликата од ова прилагодување, па затоа е брза и лесно &nbsp;остварлива промена.</p>
\t</li>
\t<li>
\t<p><strong>Исклучете го мониторот на компјутерот ноќе за да заштедите енергија</strong></p>
\t</li>
\t<li>
\t<p><strong>Раздвижување за собирање на ѓубре во канцеларијата (Waste Walk).</strong> Оваа активност е кога вработените ќе погледнат наназад, ќе одат по подот и ќе ги идентификуваат расфрланите канцелариски материјали и ситни отпадоци. Тоа е забавен начин на вклучување на &nbsp;сите вработени да учествуваат со &nbsp;идеи во вашата канцеларија со цел да &nbsp;имаат влијание врз животната средина. Waste Walks не бара дополнителни средства, ресурси или трети лица. Оваа активност е пристап кој се вика “дофат на ниско висечко овошје” кое ќе направи подобрувања во канцеларијата..</p>
\t</li>
\t<li>
\t<p><strong>Ослободете се од поединечните корпи за отпадоци:</strong> некои канцеларии имаат корпи за отпадоци на секој поединец! Ќе ги зголемите стапките на рециклирање и ќе го намалите отпадот откако ќе ги отстраните поединечните корпи за отпадоци и наместо тоа ќе ги искористите централните корпи за отпадоци.</p>
\t</li>
\t<li>
\t<p><strong>Користете еко кафе кое прави помалку отпад</strong></p>
\t</li>
\t<li>
\t<p><strong>Оптимизирајте ја употребата на природна светлина. </strong>Ако вашата канцеларија има прозорци, тогаш погрижете се да ја оптимизирате природната светлина. Избегнувајте да ги вклучувате светилките во текот на денот, со тоа што ќе ги држите завесите, сенките и ролетните отворени.</p>
\t</li>
\t<li>
\t<p><strong>Инвестирајте во енергетско ефикасно осветлување.</strong> Користењето на енергетски ефикасни светилки заштедува електрична енергија и пари. Енергетски ефикасните светилки доаѓаат во сите облици и големини. Има по нешто за секого согласно секоја потреба и секој буџет!</p>
\t</li>
\t<li>
\t<p><strong>Инвестирајте во паметни приклучоци.</strong> Канцелариите можат автоматски да заштедат енергија со користење на паметни приклучоци. Паметните приклучоци автоматски ги исклучуваат светлата, вентилаторите и апаратите. Повеќето паметни приклучоци ви дозволуваат да ги контролирате вашите уреди од каде било со помош на апликација на вашиот телефон.</p>
\t</li>
\t<li>
\t<p><strong>Прилагодете го вашиот термостат 2 степени нагоре во лето и 2 степени надолу во зима.</strong> Алтернативно, можете да користите енергетски ефикасен термостат кој сам заштедува енергија.</p>
\t</li>
\t<li>
\t<p><strong>Поттикнете ја заштедата на вода со совети и инструкции во бањите. </strong>Обидете се да ги замените старите знаци и користете креативни дизајни за да ја одржувате пораката свежа</p>
\t</li>
\t<li>
\t<p><strong>Изберете поправка наместо замена</strong></p>
\t</li>
\t<li>
\t<p><strong>Организирајте зелени натпревари со вашиот тим</strong></p>
\t</li>
</ol>



<p><strong>Совет: </strong>Правењето мали чекори наместо драстични промени е најдобриот начин да ги постигнете вашите еколошки цели. Наместо да вклучите големи промени што може да ги оптоварат вашите вработени, фокусирајте се на правење мала трансформација во секоја област од вашето работно место.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-44756050-178f-436e-893f-651f0a73f718"><p><strong><span style="font-size:1.5em;">Проценка на јаглеродните емисии во насока на политиките и практиките за работа од далечина</span></strong></p>

<p>Фокусот на истражувањето кое го спроведе Ројтерс во 20 големи корпорации кои ја прифаќаат работата на далечина, &nbsp;покажа дека само половина од тие компании се обиделе да ги проценат јаглеродните емисии поврзани со работниците од далечина, а помал е бројот на компании кои се обиделе да го ублажат или неутрализираат влијанието. Дури и Мајкрософт - кој има извонредно силна програма за корпоративна одржливост, во 2020-та година почна да ги проценува јаглеродните емисии на работниците во рамките на работата од далечина, и се обиде да се справи со влијанието на емисиите надвор од локацијата.&nbsp;</p>
</div><div></div>

</div></div></div></div></div></div>      </div>    </div>  </div>  </div></div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-96673788-aac0-4bb1-a147-1f060a8bad55"><p><strong><span style="font-size:1.5em;">Индикатори за потрошувачка на енергија</span></strong></p>

<p>Постојат мерни алатки, но тие наидуваат на неколку предизвици. Првата е дека мерењето се заснова на тоа колку троши една локација, но, честопати компаниите имаат неколку локации во многу различни географски области и зони и со многу различни опкружувања. Втората е потребата да се земе во предвид цел екосистем, вклучувајќи ги вработените и добавувачите. Да се внесат и тие во равенката како варијабли и да се има влијание врз нив е многу посложено.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-72db514d-8a33-4eb4-a525-83ba1cf3c4c2"><p><strong><span style="font-size:1.5em;">Распоред кој го дефинира менаџерот за хибридна работа</span></strong></p>

<p><strong>Донесете политика која ги зема во предвид вкупните влијанија поврзани со патувањето и животната средина (на пр. избегнувајте далечинско работење во понеделник и петок, што го користат повеќето компании).</strong></p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a0e4f2b1-4e13-4f99-b95f-0ef8a62df29a"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a0d17a1b25.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-23380b42-0403-427c-ad5c-a2eb83219cfa"><p>Моделот предложен од Дејвид Аутисиер би можел да биде интересен во овој поглед. Тој модел предложува да се &nbsp;преиспитаат работните методи што треба да се земат во предвид во однос на “работа лице-в-лице” и “работа од далечина” преку поимите „Build“ и „Run“ позајмени од Agile методите. &nbsp;Далечинското работење добро функционира кога е во тек - т.е. во познати, повторливи активности - но е помалку приспособено за Build - т.е. за сите активности кои ги вклучуваат инвестициите во иднина, како што се нови проекти.</p>

<p>Со други зборови, знаеме како далечински да управуваме со тековната активност, за која сме веќе влезени во шема, но помалку би знаеле далечински да управуваме со онаа активност бара интеракции, размени, доверба и колективна интелигенција за да започне. Во тој случај, веројатно ќе се претпочита “работа лице в лице”.</p>

<p>Врз основа на ова, директорот за човечки ресурси може да ги мотивира менаџерите да размислуваат во рамките на своите тимови за распоредот на времето или задачите за кои е важно сите да бидат заедно; и за распоредот на времето и задачите што може да се добијат со спроведување на одредени активности онлајн.</p>

<p>На пример, состанокот за следење на оперативниот проект ќе биде поефективен ако се спроведе онлајн. Од друга страна, што се однесува до влез на нов член во тимот, подобро е тоа да се направи лично. Итн... На овој чекор може да се додаде и &nbsp;критериум за влијанието на јаглеродниот отпечаток.</p>

<p>Ова може да доведе до групирање задачите во кластер за работа од далечина, и кластер за работа лице в лице, земајќи ги предвид сите параметри: бројот на вклучени луѓе, растојание што ќе треба да ги поминат и режимите на транспортот, стапките на зафатеност на канцелариите, влијанието во однос на дигиталната употреба итн.</p>

<p>Идејата не е да се има математички пристап, туку опортунистички: ако состанокот во живо &nbsp;може да има подобар резултат од она што би се постигнало од далечина, и покрај повисоките трошоци за животната средина, веројатно е сè уште релевантно да се спроведе со физичко присуство. Тоа е прашање на избор. Интересот треба да биде ставен на средствата кои се потребни за тимот како и за постојано мерење на различните параметри на работната организација.<br>
&nbsp;</p>

<p dir="ltr">&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6ab0511b-b00e-4b8e-af60-873709089171"><p dir="ltr"><strong><span style="font-size:1.5em;">Апликации кои овозможуваат оптимизација на канцеларискиот простор поврзан со работата од далечина/хибридната работа</span></strong></p>

<p>Различни апликации помагаат да се минимизира напорот на уредувањето на работниот простор но и подобро да се менџира &nbsp;со опкружувањето &nbsp;во канцеларијата &nbsp;како и со присуството на вработените во канцеларијата.</p>

<p>Дел од овие алатки можат да се најдат во Методологијата за проценка на влијанието развиена со овој проект.&nbsp;</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-27fceef1-f99c-4f3a-adc2-ca263f01efff"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a11737328b.png" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-79477eba-a9a7-4660-bb64-90d2f1600a3e"><p><strong><span style="font-size:1.5em;">Редовни работилници / ручеци и учење преку &nbsp;страната Sustainability@theoffice</span></strong></p>

<p>Работилниците за одржливост имаат за цел да направат можност сите да научат пошироко во контекст на тоа како да се спроведува одржливоста во канцеларијата и да се развијат стратегии кои ќе поттикнат размислувања за одржливост во рамките на нивната секојдневна работа.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-783f311b-b0bc-455e-8ff2-8602b11a7275"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a12151f7ae.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9e69f91d-beba-4166-86c4-9e3c563b3d40"><p><strong><span style="font-size:1.5em;">Климатски Фреск</span></strong></p>

<p>За само 3 часа, колаборативната работилница “Climate Fresk” ги презентира основите од науката за климатските промени и повикува на акција. Со користење/ активирање на колективната интелигенција на групата, “Climate Fresk” им овозможува на учесниците да поттикнат влијание врз активностите кои ги преземаат. Методологијата не користи хиерархиска структура на учење, им дозволува на сите да учествуваат и да го најдат своето место во вежбата. Додека учесниците ги поврзуваат причините и последиците од климатските промени, и можат да се вратат назад и да ја разберат системската природа на предизвиците за прв пат.</p>

<p dir="ltr"><strong>Повеќе информации:</strong> <a target="_blank" href="https://climatefresk.org/" target="_blank">https://climatefresk.org/</a></p>
</div></div>`
        },
        inspiringInitiatives: {
            heading: "Инспиративни иницијативи",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-bf6fc80e-b7f5-4199-82ff-19ca3e262b6b">


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d50e317c-49f4-4ce7-b827-1e7618ea8802"><p><span style="font-size:1.5em;"><strong>Внесете ги јаглеродните емисии на поединците (вработени од далечина) во пресметката на јаглеродниот отпечаток на компанијата</strong></span></p>

<p>Иако индивидуалниот јаглероден отпечаток не се зема предвид при мерењето на јаглеродниот отпечаток на организацијата, тој треба да се земе предвид при работењето на далечина, бидејќи работните активности се префрлаат во домаќинството на вработениот. Вработените на далечина веројатно ќе го зголемат својот индивидуален јаглероден отпечаток бидејќи трошат повеќе електрична енергија и греење, а во некои случаи овие емисии се зголемуваат повеќе кога се јавува потреба од дополнителен простор за работа во домаќинството, .</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1fe06bb5-7157-48c0-b078-a9a1bbedf826"><p><strong><span style="font-size:1.5em;">Намалете го бројот на квадратни метри при селидба и воведете нови технологии за греење, ладење на зградата</span></strong></p>

<p>Воведувањето нови начини на работа всушност значи намалување на работната сила во канцелариите. Постојните канцеларии може да се реновираат и канцеларискиот простор може да се намали во однос на квадратни метри. Ова е исто така добра можност да се инвестира во енергетски поефикасни технологии за греење и ладење.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-2e6c4420-0191-429c-ae35-3c163eee07a7"><p><strong><span style="font-size:1.5em;">Затворете го седиштето на компанијата еден ден во неделата</span></strong></p>

<p>Понудете алтернативни решенија за оние кои не се заинтересирани за работа од дома, со работа во канцелариите на компанијата кои остануваат отворени.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-9bb64ff6-9682-46c1-b4d0-851b3c36cfb3"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-659e73b7cfb38.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-fe2e9f0a-6c9f-4004-a698-7d8150687ee6"><p><strong>Пример:</strong></p>

<p><em>Во мај 2022 година, белгиската осигурителна компанија Ethias ги затвори своите национални канцеларии (Лиеж и Хаселт) еден ден во неделата и ги насочи своите вработени да работат од далечина. Притоа, компанијата овозможила работа од далечина до 3 дена во неделата, односно 60% од работното време. Оваа мерка, која е иновативна на белгискиот пазар, му овозможува на Ethias да ја намали својата зависност од фосилни горива. Таа искача на врвот на бројните иницијативи спроведени во текот на изминатите неколку години, кои му овозможија на Ethias да го намали својот јаглероден отпечаток за една третина, четири години пред предвиденото.</em></p>

<p><em>Иако пристапот кон “повеќе работа од далечина” е во согласност со желбите на огромното мнозинство вработени, оние кои не сакаат да го сторат тоа, сè уште имаат можност да го одработат својот работен ден во некои од другите згради. „Пред да го прифати &nbsp;пристапот за работа со доаѓање во просториите за работа, Ethias имаше долг преглед и проценка на неколку сценарија за да се обезбеди дека вработените со своето доаѓање не ја загрозуваат околината. Според податоците на јаглеродните емисии, еколошката добивка од работата од далечината е позитивна штом патувањето работа-дома е поголемо од 2 километри, што е случај за повеќе од 90% од вработените на&nbsp;Ethia.</em></p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3316ea8d-10cc-4b2e-8619-da7fe9bbc817"><p><strong><span style="font-size:1.5em;">Развој на апликација за селекција на канцелариско место пред доаѓање на работа</span></strong></p>

<p>За да биде хибридната работа ефикасна, организацијата треба да обезбеди алатки кои ќе го олеснат користењето на канцеларискиот простор во деновите кога работниците доаѓаат во канцеларијата. Развивањето апликација за работниците да ја изберат канцеларијата што ќе ја заземат пред да дојдат на работа е добро решение. Постојат апликации достапни на Интернет кои лесно можат да се персонализираат за просторот што го зазема организацијата.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c6362667-2265-4e67-bf80-74a50e2a5925"><p><strong><span style="font-size:1.5em;">Предлог: можност работодавачот да инвестира, со надомест кој не се оданочува, во решенија кои го намалуваат јаглеродниот отпечаток на вработените</span></strong></p>

<p>Можеме да замислиме да конвертираме еден дел од платата коај денес се оданочува, за плаќање на фактура за инсталација на геотермичка инсталација, соларен панел, реупотреба на вода или слична инвестиција во куќата, живеалиштето на вработениот.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d7c13367-6a61-421e-a373-eebd7190de95"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650ae2a5b19be.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-706a5767-ffee-4003-b5a9-c2fd99b9978d"><p><strong><span style="font-size:1.5em;">Користете игри</span></strong></p>

<p>Воведете ја играта „Power Ranger“ за мобилизирање на вработените за намалување на потрошувачката на енергија.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-49e80623-fa73-4a32-ad4b-77f75ce8a5c4"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650a245a56b04.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5ea9601e-15c0-41fa-b006-440a9fd9e737"><p><strong><span style="font-size:1.5em;">Понудете индивидуално советување</span></strong></p>

<p>Помогнете му на секој човек, бидејќи секој има различна ситуација - од помош како да се промени начинот на транспорт, до советување за промена по негова динамика - со малку финансиски поттик.</p>
</div></div>`
        },
        recommendation: {
            heading: "Препораки",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9ff39201-b913-41dc-9ad8-c1d24b78e893">


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3427f056-8466-4a95-adec-01f1e32a4592"><p><span style="font-size:1.5em;"><strong>Реорганизирање на работните простори за да трошат помалку енергија</strong></span></p>

<p>Тоа може да се направи на неколку начини, како што се условите во кои се произведува опремата, изборот на снабдување со енергија и регулирање на осветлувањето, видот на материјалите што се користат за застаклување, употребата на топлинска изолација, имплементацијата на системи за греење кои овозможуваат повеќе насочена употреба итн.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6a1ff1da-bea0-45f6-96ee-7326ac1f3395"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650ae72798dfa.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d7f97920-3d31-4127-9d19-2a50d6307c52"><p><strong><span style="font-size:1.5em;">Приспособување на системите за работа според активностите</span></strong></p>

<p>Ова значи да се размислува однапред за тоа како да се организира работата и видовите на активности што треба да се спроведат. Обезбедете предвидливост на практиките за работа на далечина за да може да се предвиди кога вработените работат или не.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d04f5047-e710-4960-aea1-5f2dd5c634c4"><p><span style="font-size:1.5em;"><strong>Избегнувајте го пристапот „една решение одговара на сите“</strong></span></p>

<p>Она што може да биде корисно во една компанија, може да биде многу помалку спроведливо на ниво на урбан центар - на пример, ако вториот опаѓа или се испразни поради напуштањето на работниците... Слично на тоа, не сите вработени од далечина се исти: некои имаат посветен работен простор, а некои не. Цената на енергијата се покажува како важна променлива при изборот на работа од дома или од канцеларија.&nbsp; Оваа одлука исто така зависи и од времетраењето на патувањето.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-54956a77-38f6-4873-8ff7-904c45dc5241"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650ae952abe3a.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c741d811-2784-453f-ae4a-6f38fd9c9466"><p><strong><span style="font-size:1.5em;">Добрите идеи често доаѓаат од оперативниот персонал бидејќи тие се најблиску до вистинската работа</span></strong></p>

<p>И тоа е местото каде што навистина може да имате влијание. Сепак, предизвикот е да се одржи конзистентноста. Доколку партиципативниот пристап е изолиран или се чини дека е изолирана иницијатива, без да биде дел од поширока стратегија; или ако корпоративната порака е дека животната средина е навистина важна, но за тоа има мал буџет, пристапот нема да биде веродостоен. Пазете се од “greenwashing”!</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-aa1ea73d-5992-4018-b240-0879fed576de"><p><strong><span style="font-size:1.5em;">Затворете ги канцелариите во одредени денови од неделата за да заштедите енергија</span></strong></p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-cfe59f92-0f7d-4a1b-8a5b-4dc310c7c7b8"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650ae9e7ad642.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3a5a13fb-d0a4-4437-87c0-8cca2baa698a"><p>Ако многу работници имаат тенденција да работат од дома во одредени денови, организациите може да размислат за затворање на канцеларијата на тој ден. Се препорачува да се изберат последователни денови на затворање бидејќи така се заштедува повеќе енергија. На пример, затворањето во понеделник после викенд ја намалува енергијата што се користи при вклучување и исклучување на системите што трошат енергија во канцеларијата (т.е. греење и ладење и сл.).</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-93509733-1264-40a8-a25b-183892b8d10d"><p><strong><span style="font-size:1.5em;">Комбинирајте работа од далечина, флексибилна канцеларија и намалување на канцеларискиот простор</span></strong></p>

<p>Студијата на АДЕМЕ (Франција) покажува дека кога се земаат во предвид намалувањата за недвижен имот што ги носи работата од далечина заедно со флексибилната канцеларија (практика да нема посебна канцеларија за работно место), се подобрува севкупната еколошка рамнотежа на работата дури за +52% по ден работа на далечина во неделата (т.е. -234kg eqCO2/годишно за секој дополнителен ден на далечина неделно), и се консолидираат предвидените еколошки придобивки. Еколошките придобивки од флексибилната канцеларија можат да ги надминат придобивките од мобилноста т.е. доаѓањето на работа.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-2d3e4c85-3a9c-4d3a-a7e2-d1ea74bf3ce5"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650aea5b69adc.png" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d25f0f5-0bf7-4f85-b059-69b9e42fb240"><p><strong><span style="font-size:1.5em;">Не поттикнувајте полудневна работа од дома</span></strong></p>

<p>Работата од далечина и работата од дома, во комбинација со работата на трето место доведува до значително негативен биланс за животната средина.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-28e9f5f3-2c2d-4dbf-94b1-1e6affc73458"><img src="https://lp.youngkreativ.eu/pluginfile.php/399/mod_hvp/content/101/images/file-650aeaf74c038.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-cfa3ffa5-6b28-41ef-aa8a-4bc8bde9fc55"><p><strong><span style="font-size:1.5em;">Бидете внимателни со работата од далечина!</span></strong></p>

<p>Студијата на АДЕМЕ (Франција) покажува дека работата од дома&nbsp;ја намалува „канцелариската“ потрошувачка (хартија, мастило, материјали, чаши, украси, видео проектори итн.) и потрошувачката на енергија на компанијата. Сепак, некои од овие намалувања се пренесуваат на работникот кој работи од дома. Особено се зголемува потрошувачката на енергија во домот. Студијата проценува дека потрошувачката на енергија во домот за време на еден работен ден од далечина би се зголемила за +20,7 kg eqCO2 во текот на година со еден ден неделно работа од далечина за вработениот.</p>


</div></div>`,
        }
    },
    fr: {
        learningObjectives: {
            heading: "Objectifs d'apprentissage",
            html: `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8879ace5-ae4e-413e-afb3-4d31e11ba1d5">

<p>L’objectif est de permettre aux professionnels des RH d’ouvrir la voie en créant un environnement de travail hybride qui non seulement soutient les objectifs organisationnels, mais contribue également positivement à la durabilité environnementale.</p>



<p><span style="font-size:1.25em;"><strong>Compétences en leadership et stratégie de travail hybride :</strong></span></p>

<ul>
\t<li>Développer la capacité de diriger et d’élaborer des stratégies efficacement dans un environnement de travail hybride, en se concentrant sur l’intégration des principes de durabilité. Cela comprend l'acquisition de compétences nécessaires pour collaborer avec la direction et les équipes interfonctionnelles afin d'intégrer des pratiques durables dans les environnements à distance et au bureau.</li>
\t<li>Apprenez à créer une vision stratégique qui aligne les modèles de travail hybrides avec les objectifs de développement durable de l'organisation.</li>
\t<li>Améliorer les compétences dans la conception de politiques et de pratiques de travail hybride qui réduisent l'empreinte carbone de l'organisation, comme l'optimisation des horaires de travail à distance pour minimiser les déplacements et la consommation d'énergie.</li>
\t<li>Obtenez des informations sur l'évaluation et la sélection des emplacements de bureaux en fonction de l'accessibilité aux transports durables et du potentiel de réduction des émissions liées aux déplacements domicile-travail.</li>
</ul>



<p><span style="font-size:1.25em;"><strong>Gestion durable des RH dans des environnements hybrides :</strong></span></p>

<ul>
\t<li>Cultiver les compétences en matière de gestion des ressources humaines de manière à soutenir et à améliorer l’efficacité des modèles de travail hybrides, tout en favorisant la durabilité. Cela comprend l'élaboration et la mise en œuvre de politiques RH qui encouragent des pratiques de travail respectueuses de l'environnement, tant à distance qu'au bureau.</li>
\t<li>Apprenez à intégrer la durabilité dans le recrutement, la formation et la gestion des performances, en vous assurant que le personnel est équipé et motivé pour s'engager dans des pratiques durables, quel que soit son lieu de travail.</li>
\t<li>Améliorer les capacités d'utilisation des outils numériques et des stratégies de communication pour impliquer et soutenir efficacement les employés dans une configuration de travail hybride.</li>
</ul>
</div>`
        },
        stakeHolders: {
            heading: "Les parties prenantes",
            html: `<div>
<p dir="ltr">En charge de la gestion des ressources humaines et de l'organisation du travail, le Directeur/Responsable des Ressources Humaines n'est que partiellement responsable des questions relatives aux nouvelles méthodes de travail.&nbsp;</p>
<p dir="ltr">Afin de contribuer à la réduction des émissions de carbone de l'entreprise, le Directeur/Responsable des Ressources Humaines doit travailler en étroite collaboration avec différentes parties prenantes, dont les principales ont été identifiées par les partenaires du projet comme suit :</p>
<div class="h5p-image-hotspot-popup-content overflowing has-scrolled" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>PDG/Direction générale</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3 dir="ltr">Définir une vision</h3>

<p>Les PDG doivent accepter le rôle de leader qu'ils jouent personnellement pour intégrer les enjeux de durabilité au sein de leur organisation. Le PDG et le comité exécutif doivent définir une vision, établir une ligne éthique et des valeurs claires, et exprimer leurs ambitions en matière de durabilité et de réduction des émissions de carbone. Quels engagements la direction est-elle prête à prendre en matière de durabilité ?</p>

<p>Cela aidera les cadres et les employés à faire le lien entre le développement durable et la mission de l'entreprise, puis avec leur travail quotidien.&nbsp;</p>

<h3>Exemplarité / Joindre le geste à la parole</h3>

<p>Les recherches montrent que l'exemplarité est très importante et exerce une forte influence sur l'acceptation du changement par les employés. Il est essentiel de joindre le geste à la parole et de fournir aux employés des exemples clairs de ce qui est attendu et valorisé. L'exemplarité peut être déclinée à différents niveaux.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Syndicats</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>L’élaboration d’un projet NWoW ou de travail hybride suivant une logique verticale descendante, sans impliquer les syndicats, sans d’assurer du soutien d’ambassadeurs, sans réaliser d’étude terrain, ne fonctionnera pas. Il faut aussi être prêt à revenir sur ses pas en cas d’erreur et accepter de revoir ses projets.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Responsable ou équipe de durabilité</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Le responsable ou l'équipe de durabilité est un acteur-clé à impliquer dans les projets NWoW visant à réduire la mission carbone.<br>
Le partage d'expérience est essentiel et l'entreprise pourrait lancer ou participer à un réseau de responsables de la durabilité dans un secteur, un parc industriel ou une zone géographique afin de partager les connaissances et les bonnes pratiques.</p>
</div></div></div>
<div class="h5p-image-hotspot-popup-content overflowing" style="height: 100%; overflow: auto;"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Services généraux / Facilities</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Partage des responsabilités</h3>

<p>Ce n'est pas un hasard si l'on voit aujourd'hui de plus en plus de DRH également en charge des services généraux, dans le but d'intégrer une réflexion sur les taux d'occupation, le dimensionnement et l'optimisation des espaces, l'adaptation des technologies de chauffage, etc.</p>

<h3>Une étroite coopération&nbsp;</h3>

<p>Si les Ressources Humaines et les services généraux restent deux départements distincts, il est alors important d'établir une coopération étroite. Les services généraux peuvent contribuer à réduire la consommation d'énergie par des investissements dans des installations à haut rendement, ou des énergies alternatives peu polluantes comme le sont les panneaux photovoltaïques par exemple. Ils peuvent gérer le chauffage en fonction des personnes présentes sur le site et/ou étudier avec les RH le regroupement des personnes sur certaines zones ou étages du site, et fermer les endroits vides.&nbsp;</p>

<h3>Prospective</h3>

<p>La prospective renvoie à l’étude des besoins immobiliers futurs, tant du côté des salariés que des entreprises : ces dernières peuvent être incitées à regrouper différents sites en un lieu plus central, ce qui implique naturellement un allongement des trajets domicile-travail.</p>

<p>Si l’on y ajoute un potentiel d’exode rural, ces pratiques doivent être analysées pour leur impact sur la qualité de l'air, en prenant en compte les variations de mobilité effective et la mobilité liée aux loisirs. Il est important d'analyser les tendances dans l'utilisation des tiers-lieux (qui pourraient maintenir les déplacements résiduels et permettre une meilleure répartition de l’implantation de biens immobiliers) et dans l’augmentation de la surface des logements privés : en effet, le travail à distance pourrait conduire à l'acquisition de logements plus grands pour disposer d'un lieu dédié au télétravail.</p>
</div></div></div>
<p dir="ltr">Pour réellement changer les comportements liés à l'organisation du travail, il est important que les entreprises mettent en place une stratégie de communication et que l'effort soit déployé de manière régulière. Le message doit être martelé en permanence.</p>
</div>`
        },
        culture: {
            heading: "Culture",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-51fdc91f-d822-4585-b029-c3132b46ab15">

</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d621e8b2-0c32-40ec-a524-408f805b4851"><p><strong><span style="font-size:1.5em;">La culture est la clé</span></strong></p>

<p>Agir en faveur d'une réduction des émissions de carbone passe nécessairement par la variable culturelle. C’est par un travail sur la culture organisationnelle et managériale, ainsi que sur une utilisation plus raisonnée des déplacements et des outils informatiques, que l'on peut obtenir des changements de comportement et de consommation qui rendront le bilan plus positif pour l'environnement.</p>

<p>Vérifiez la cohérence de la politique environnementale de l'entreprise avec sa culture globale. Comment procéder ? En examinant les pratiques, les habitudes et les incitations déjà en place pour vérifier qu'elles ne sont pas incohérentes ou contradictoires avec la stratégie environnementale.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-ec71dbde-552a-4919-89c6-8adb3e59180f"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-659b0e3914c5e.png" alt="La culture est la clé" title="La culture est la clé" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4db97028-02a0-45df-b3dc-a2400e1f6992">

<p><strong><span style="font-size:1.5em;">Se concentrer sur le bon levier</span></strong></p>

<p>Le travail à distance n'est pas intrinsèquement "meilleur" pour l'environnement. Il existe autant de recherches abondant dans ce sens que d’études démontrant le contraire. L'entreprise, par exemple, ne va pas cesser de chauffer et d'éclairer les espaces de travail lorsque certains employés travaillent ailleurs, que ce soit chez eux ou dans un autre espace.</p>

<p>La consommation d'énergie de l'entreprise peut donc rester la même, mais la consommation d'énergie au domicile des travailleurs sera par conséquent plus élevée. Il y a bien sûr un effet saisonnier, qui peut varier d'un pays à l'autre - même si le chauffage peut être remplacé par la climatisation. Par ailleurs, plusieurs études montrent que les déplacements ne sont pas supprimés et qu'il y a même une tendance des employés en télétravail à se déplacer davantage, par exemple pour faire une petite course, continuer à déposer/aller chercher ses enfants à la crèche ou à l'école, les sorties pour le déjeuner, etc. Il y aurait donc des déplacements supplémentaires, mais des déplacements plus courts. En fin de comptes, on parle d'un éco-bilan "incertain".</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e93095af-95fc-4a5d-b59d-b465dbb8ebde">

<p><strong><span style="font-size:1.5em;">Sensibiliser</span></strong></p>

<p>La sensibilisation et l'éducation aux comportements sur le lieu de travail basées sur des preuves scientifiques est l'un des principaux leviers à disposition des DRH. Informer les salariés sur le coût environnemental de leurs pratiques présente un intérêt certain. L’impact est même plus grand lorsque cette sensibilisation, information ou formation est portée par une stratégie et mise en lien avec les activités, et/ou avec les ambitions de l'entreprise d'atteindre la neutralité carbone.&nbsp;</p>

<p>Cela peut déclencher une multiplication des initiatives.</p>

<p>Habituellement, le discours sur le travail à distance/hybride est principalement lié au bien-être et à l'organisation du travail, mais moins à la durabilité. Il est important d’informer et de former également les employés sur les enjeux du changement climatique et sur l'impact que leur organisation du travail peut avoir sur les émissions de carbone. La sensibilisation doit être facilitée par une interaction et un dialogue constants.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a2f1c3ca-765a-48bc-9ae8-5bf7b0657d0b"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-659b0e6194c3e.png" alt="Sensibiliser" title="Raising Awareness" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-56f5683d-ac8f-457a-aa74-6ceab16d8394">

<p><strong><span style="font-size:1.5em;">Initier une démarche participative</span></strong></p>

<p>Développer une démarche participative impliquant les salariés sensibles ou actifs dans le domaine du développement durable afin d'en faire des ambassadeurs et de susciter l'adhésion des autres salariés par différents moyens :&nbsp;</p>

<ul>
\t<li>collecte d'idées,</li>
\t<li>partage de bonnes pratiques,</li>
\t<li>formulation de propositions,</li>
\t<li>etc.</li>
</ul>

<p>Plusieurs études menées dans des pays occidentaux en 2022 ont montré que la plupart des salariés reconnaissaient la valeur du travail hybride et que la flexibilité était l'un des facteurs les plus importants dans leur décision d'accepter ou non une offre d'emploi. La norme qui émerge progressivement dans les grandes entreprises est de 3 jours au bureau par semaine.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d36a5e02-5941-4954-acb4-08a1432622b7">

<p><strong><span style="font-size:1.5em;">Faire attention aux tensions </span></strong></p>

<p>Vous devez identifier les tensions qui apparaîtront lorsqu'il s'agira de poursuivre à la fois des objectifs de performance économique et de performance sociale et environnementale, tant dans les logiques de court terme - qui caractérisent les organisations aujourd'hui - que dans les logiques de plus long terme - dans lesquelles s'ancre la notion de durabilité.</p>


</div></div>`
        },
        hr: {
            heading: "Processus RH",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e9287db0-3f7f-4113-88d3-e2117c140a0e">

</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-47f54b42-11d9-482f-8720-84285c76de62">

<strong><span style="font-size:1.5em;">POLITIQUES RH</span></strong></p>

<p>Développer une politique de sensibilisation aux enjeux du développement durable et aux actions de l'organisation dans ce domaine à plusieurs stades : onboarding, formation continue...</p>

<ul>
\t<li>Mener une étude transversale qui implique lesa NWoW, et le bien-être et les enjeux du développement durable</li>
\t<li>Assurer la cohérence de toutes les politiques RH avec les objectifs de réduction des émissions de carbone</li>
</ul>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-40fb375e-8fd6-46af-9670-4af0cb630dbb"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a000f9022c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-51d2007f-0995-4d97-be89-ede2d14b5c4b">

<p><strong><span style="font-size:1.5em;">RECRUTEMENT</span></strong></p>

<p><strong>Évaluer la conscience et les valeurs des candidats en matière de développement durable et leur compatibilité avec les ambitions et les objectifs de l'organisation </strong></p>

<p>Lors du recrutement, par exemple, allons-nous regarder l'empreinte carbone des candidats ? Allons-nous évaluer si leurs comportements sont en adéquation avec les ambitions de notre organisation ?</p>

<p>Les enjeux liés au climat constituent un formidable levier d'attractivité pour les candidats en quête de sens et de motivation pour les salariés déjà engagés sur la question.&nbsp;</p>

<p>Cependant, ce sont de plus en plus les candidats et les travailleurs qui posent ces questions : quelle est l'offre de mobilité de l'entreprise, existe-t-il une politique de travail à distance, quelles sont les initiatives en faveur du développement durable, etc. Si l'entreprise n'a pas de politique claire, elle sera condamnée à l'improvisation et aux réponses au cas par cas, ce qui risque de ne pas attirer les candidats prometteurs.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-82f5014d-e54b-4a0f-8cab-1a43a166b8b1"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a005f92b37.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0c101637-6a96-4185-86a2-7b6ad760abbd">

<p><span style="font-size:1.5em;"><strong>FORMATION</strong></span></p>

<p><strong>Expliquer pourquoi la transformation durable est un enjeu pour les entreprises et les salariés</strong></p>

<p>Le premier défi consiste à comprendre les enjeux, en commençant par la science : ce que disent les scientifiques sur le changement climatique, les ressources naturelles, la biodiversité et l'impact sur nos vies - communautés, entreprises, individus.</p>

<p>Le deuxième défi est celui de l'action : la transformation durable touche tous les métiers. Métier par métier, il faut comprendre quels en sont les aspects concernés, puis établir une feuille de route pour l'action grâce à des formations spécifiques, selon que l'on travaille dans les achats, l'informatique, le juridique, la finance, le marketing, les RH, etc.</p>

<p><strong>Former le manager à gérer l'engagement des salariés : comment réagir lorsque, par exemple, le meilleur vendeur d’une équipe, en partance pour un séminaire, rappelle qu'il ne prend plus l’avion ?</strong></p>

<p><strong>Introduire les dimensions écologiques dans les bibliothèques de compétences</strong></p>

<p><strong>Anticiper les besoins en compétences de demain : quelles seront les compétences du nouveau comptable, du nouvel informaticien, du nouvel acheteur, du nouveau RH, etc. dans le cadre d'une transformation durable ?</strong></p>

<p>Intégrer le critère écologique dans le choix des prestataires, notamment pour la formation. Il est toujours possible d'informer et d'interpeller les prestataires sur ce critère de choix afin de les inciter à agir de leur côté.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8e9a2632-788a-489a-a9d0-a5cec6137a3a"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a00b6f3b38.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-78bed962-f188-4975-8f73-b0dea716bef1">

<p><span style="font-size:1.5em;"><strong>GESTION &amp; EVALUATION DE LA PERFORMANCE</strong></span></p>

<p>Les entreprises fixent de plus en plus d’objectifs de développement durable, qui doivent être mis en œuvre dans les services et les équipes pour être atteints. Il est donc important d'évaluer ce que les managers font, avec leurs équipes, pour atteindre ces objectifs.&nbsp;</p>

<p><strong>Il devient crucial d’intégrer les enjeux du développement durable dans les objectifs annuels des salariés et dans leur plan de développement des compétences.</strong></p>

<p>Très peu d'entreprises à ce jour traduisent ces objectifs de durabilité en indicateurs de performance. Quand elles le font, elles se réfèrent généralement aux 17 objectifs de développement durable des Nations Unies, mais il faut que ce soit beaucoup plus concret que cela.</p>

<p>Ces objectifs peuvent transparaître jusque dans les comportements quotidiens : prendre en compte l'aspect du développement durable lors de l'organisation d'une réunion, d'une fête d'entreprise, d'une offre de formation, d'un choix de voyage, etc.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-bcf69465-7d98-4b54-8f57-16c62f1e31cf"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a010e1bb7c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c5702d04-36d7-48a5-bdc3-dfd6ae0b7e6e">

<p><span style="font-size:1.5em;"><strong>RÉMUNÉRATION</strong></span></p>

<p>Il existe une possibilité d’inclure des critères liés au développement durable dans le choix des avantages qui composent le package salarial ou dans la rémunération des dirigeants, des managers et même de tous les employés. Si les employés atteignent leurs résultats, mais au détriment de l'environnement, la prime sera-t-elle remise en question ?</p>

<p><strong>Encouragez et appréciez les comportements écologiques.</strong></p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8c680f0a-8eaf-4e88-a823-ca934fad28e7"><p><span style="font-size:1.5em;"><strong>FLEXIBILITÉ DE TRAVAIL</strong></span></p>

<p>Proposez des lieux de travail alternatifs aussi proches que possible du lieu de résidence des employés (tiers-lieux, espaces de coworking, etc.).</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-335b1072-9195-49d8-bb14-1838c832c36b"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a013f7d826.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-038d68de-3a7b-450d-9248-7cc305cdf537">

<p><span style="font-size:1.5em;"><strong>COMMUNICATION</strong></span></p>

<p>Communiquez de manière régulière plutôt que de manière ponctuelle. Une communication annuelle sur le sujet ne suffira pas, par exemple, si vous voulez que vos employés adhèrent et s'impliquent.</p>

<p>Soyez authentique et transparent, notamment sur les progrès qui restent à faire. Votre discours doit être aligné sur la réalité de la trajectoire climatique de l'entreprise, sinon il ne sera pas bien accueilli par vos employés.</p>

<p>Ciblez vos communications sur des sujets spécifiques. Par exemple : "boîte à outils pour agir au niveau de l'entreprise pour la transition écologique" ; "comment investir son épargne salariale dans des fonds au service de l'environnement" ; etc.</p>

<p>Diversifiez les formats (mémo, vidéo, podcast, présentation, etc.) et les canaux utilisés (email, intranet, réseau social d'entreprise, road show, etc.) afin de maximiser la portée de vos messages.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-c0f62eee-721d-477c-80c6-823a977066d4"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a017fdc062.jpg" alt="" title="" style="width: 100%; height: 100%;"></div></div>`
        },
        tools: {
            heading: "Outils",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-fafe62b2-6e1d-4dab-8614-5eae029317ac">


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-785717ef-b903-49c5-9c06-297f8584f0b2"><p dir="ltr"><span style="font-size:1.5em;"><strong>Mettre en place un groupe de travail ou une équipe d’ambassadeurs sur le sujet de la durabilité.</strong></span></p>

<p dir="ltr">Peu de choses peuvent être accomplies sans l'implication de personnes motivées. Identifiez les potentiels acteurs du changement dans votre organisation, situés si possible à différents échelons hiérarchiques et dans différents départements. Sollicitez-les sur la mise en place d’un groupe de travail ou d’une équipe d’ambassadeurs, en explorant les possibilités et les bénéfices qu’offre le travail hybride en matière de réduction des émissions carbone. L’attitude et la motivation étant déjà présentes, ce rôle, bien que purement volontaire, leur apportera les moyens d’agir en adéquation avec leur motivation intrinsèque au sein de l’organisation.&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-658bbecb-b411-40b2-8187-4d368eb13db1"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a076563db5.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4efad5a4-1ecd-417f-a6e1-c245bfb5ecbe">

<p><strong><span style="font-size:1.5em;">Réaliser un audit sur les pratiques de consommation au sein de l’entreprise &nbsp;</span></strong></p>

<p>Quelques exemples d'actions ci-dessous (source: <a target="_blank" href="http://www.sustainablebusinesstoolkit.com">www.sustainablebusinesstoolkit.com</a>):</p>

<ol>
\t<li><strong>Éliminez les gobelets à usage unique et remplacez- les par des bouteilles ou des thermos réutilisables : </strong>les gobelets à usage unique sont nuisibles à l'environnement. Les gobelets en plastique et en polystyrène ne sont pas biodégradables et peuvent passer des années dans les décharges ou se retrouver dans les océans et les rivières. Si l'abandon total des gobelets à usage unique est trop difficile, envisagez d'utiliser des gobelets écologiques recyclables à la place.</li>
\t<li><strong>Instaurez une politique BYORB </strong>(Bring Your Own Reusable Bottle).</li>
\t<li><strong>Utilisez du papier d'impression recyclé.</strong> Grâce à la technologie d'aujourd'hui, on ne voit plus la différence entre le papier standard et recyclé.</li>
\t<li><strong>Imprimez en recto-verso :</strong> assurez-vous que votre ordinateur et votre imprimante soient automatiquement configurés pour cela. L'impression recto-verso peut réduire vos déchets de papier de 10 à 40%.</li>
\t<li><strong>Lorsque cela est possible, n'utilisez pas de papier.</strong></li>
\t<li><strong>Servez-vous de stylos recyclés et biodégradables</strong>, plutôt que de jeter des stylos en plastique.</li>
\t<li><strong>Diminuez la luminosité de votre écran d'ordinateur. </strong>Selon un professeur de l'Université de Harvard, passerla luminosité de votre écran d'ordinateur de 100 % à 70 % peut permettre d'économiser jusqu'à 20 % de l'énergie de l'écran. La plupart des gens ne remarqueront pas la différence en faisant ce réglage, c'est donc une idée verte rapide et facile à mettre en place.</li>
\t<li><strong>Éteignez votre écran d'ordinateur</strong> la nuit pour économiser de l'énergie</li>
\t<li><strong>Mettez en place des marches de ramassage des déchets. </strong>Celles-ci permettent aux employés de prendre du recul et d’identifier les sources de gaspillage &nbsp; &nbsp; &nbsp;au sein de l’entreprise. C'est une façon ludique de faire participer tout le monde et de stimuler des idées pour réduire l'impact de votre entreprise sur l'environnement. Les marches de ramassage des déchets ne nécessitent pas de fonds, de ressources ou de tierces parties supplémentaires. Elles tirent parti de la contribution de chaque collaborateur pour apporter des améliorations au fonctionnement de l’entreprise.&nbsp;</li>
\t<li><strong>Débarrassez-vous des poubelles individuelles </strong>dans les bureaux et remplacez-les par des poubelles centrales. Vous augmenterez ainsi votre taux de recyclage et réduirez les déchets.&nbsp;</li>
\t<li><strong>Achetez du café écologique et d'origine responsable.</strong></li>
\t<li><strong>Optimisez l'utilisation de la lumière naturelle. </strong>Évitez d'allumer les ampoules pendant la journée en gardant les rideaux, les stores et les volets ouverts.</li>
\t<li><strong>Investissez dans un éclairage à basse consommation d’énergie,</strong> qui permet d’économiser de l'électricité et de l'argent. Il existe des lampes à haut rendement énergétique de toutes formes et de toutes tailles. Il y en a pour tous les besoins et tous les budgets !</li>
\t<li><strong>Investissez dans des prises intelligentes. </strong>Ces prises permettent d’éteindre automatiquement les lumières, les ventilateurs et les appareils. La plupart des prises intelligentes vous permettent de contrôler vos appareils de n'importe où grâce à une application sur votre téléphone.</li>
\t<li><strong>Réglez votre thermostat de 2 degrés vers le haut en été et de 2 degrés vers le bas en l'hiver. </strong>Vous pouvez aussi utiliser un thermostat économe en énergie qui conserve lui-même l'énergie.</li>
\t<li><strong>Encouragez les économies d'eau avec des conseils et des instructions dans les salles de bains. </strong>Essayez d'utiliser des designs créatifs pour que le message reste positif</li>
\t<li><strong>Choisissez la réparation plutôt que le remplacement.&nbsp;</strong></li>
\t<li><strong>Organisez des concours écologiques avec votre équipe.&nbsp;</strong></li>
</ol>



<p><strong>Conseil : </strong>La meilleure façon d'atteindre vos objectifs environnementaux est de procéder par petits pas plutôt que par des changements radicaux. Plutôt que d'intégrer de grands changements qui risquent d'accabler une partie de votre personnel, concentrez-vous sur une petite transformation dans chaque secteur de votre lieu de travail.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-44756050-178f-436e-893f-651f0a73f718"><p><span style="font-size:1.5em;"><strong>Réaliser le bilan carbone de la politique de télétravail de l’organisation</strong></span></p>

<p>Une enquête menée par Reuters auprès de vingt grandes entreprises ayant une politique de télétravail a révélé que seule la moitié d'entre elles avaient tenté d'estimer les émissions associées au travail à distance, et qu'un nombre encore plus faible avait tenté d'atténuer ou de compenser cet impact. Même une entreprise comme Microsoft, qui a adopté un programme de développement durable très avancé et a commencé à estimer les effets du télétravail dès 2020, est encore en expérimentation pour tenter de maîtriser l'impact carbone de ses travailleurs hors site.&nbsp;</p>
</div><div></div>

</div></div></div></div></div></div>      </div>    </div>  </div>  </div></div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-96673788-aac0-4bb1-a147-1f060a8bad55">

<p><span style="font-size:1.5em;"><strong>Indicateurs de consommation d'énergie</strong></span></p>

<p>Il existe des outils de mesure, qui &nbsp; &nbsp; &nbsp;se heurtent toutefois à plusieurs difficultés. La première est que la mesure est basée sur ce que consomme un seul site. Or, les entreprises ont souvent plusieurs sites dans des zones géographiques très &nbsp; &nbsp; &nbsp;variées, présentant &nbsp; &nbsp; &nbsp;des caractéristiques très différentes. La seconde est la nécessité de prendre en compte tout un écosystème, y compris les travailleurs et les fournisseurs. Les intégrer au calcul, et avoir un impact sur eux est beaucoup plus complexe.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-83be948d-110c-462e-b1f0-1e0ad0c27c72"><p><span style="font-size:1.5em;"><strong>Planifier le travail hybride </strong></span></p>

<p><strong>Il est nécessaire d’adopter une politique qui tienne compte des impacts globaux sur les déplacements et l'environnement (par exemple, éviter le travail à distance les lundis et vendredis, pourtant mis en œuvre par la plupart des entreprises).</strong></p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a0e4f2b1-4e13-4f99-b95f-0ef8a62df29a"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a0d17a1b25.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-23380b42-0403-427c-ad5c-a2eb83219cfa"><p>Le modèle proposé par David Autissier peut être intéressant à cet égard. Il invite à s'interroger sur les modes de travail à envisager en termes de travail en présentiel et à distance à travers les notions de "Build" et de "Run" empruntées aux méthodes agiles. Le travail à distance fonctionne bien pour le Run (c'est-à-dire dans les activités routinières) mais est moins adapté pour le Build (soient toutes les activités qui impliquent un investissement dans le long terme, comme les nouveaux projets par exemple). En d'autres termes, il est possible de &nbsp; &nbsp; &nbsp;gérer à distance les activités courantes, mais moins celles qui nécessitent des interactions, des échanges, de la confiance et de l'intelligence collective. Dans ce cas, le face-à-face sera probablement préférable.</p>

<p>Sur cette base, le DRH peut inciter les managers à réfléchir avec leurs équipes sur les moments où il est important que tout le monde soit là, ensemble, et dans quel but ; et sur les moments où l’on peut mener certaines activités en ligne. Par exemple, une réunion de suivi opérationnel d'un projet sera plus efficace si elle est menée en ligne, alors qu’il est préférable d’accueillir un nouveau collaborateur en personne. Un critère de mesure d'impact carbone peut ensuite être ajouté à cette réflexion. Cela peut conduire à regrouper des tâches, et à créer un pôle de travail à distance et un pôle de travail en présentiel, en tenant compte de tous les paramètres : le nombre de personnes concernées, les distances qu'elles auront à parcourir, les modes de transport utilisés, le taux d'occupation des bureaux, l'impact en termes d'utilisation du numérique, etc.</p>

<p>L'idée n'est pas d'avoir une approche mathématique, mais plutôt opportuniste : si une réunion de groupe peut avoir un meilleur résultat que ce qui serait obtenu à distance, malgré un coût environnemental plus élevé, il est probablement toujours pertinent de la mener en présentiel. Ce sera une question de choix. L'intérêt sera de pouvoir se donner les moyens de décider en prenant en compte en permanence les différents paramètres de l'organisation du travail.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9e69f91d-beba-4166-86c4-9e3c563b3d40">

<p><span style="font-size:1.5em;"><strong>La fresque du climat &nbsp;</strong></span></p>

<p>En seulement 3 heures, la fresque du climat enseigne les fondamentaux du changement climatique et donne des moyens d'agir. En s’appuyant sur l'intelligence collective, la fresque permet aux participants de s'approprier le sujet. La méthodologie utilisée permet à chacun de participer et de trouver sa place dans l'exercice. En reliant les causes et les effets du changement climatique, les participants sont capables de prendre du recul et de comprendre la nature systémique des défis auxquels ils font face. &nbsp;</p>

<p>Plus d'informations :&nbsp;<a target="_blank" href="https://climatefresk.org/" target="_blank">https://climatefresk.org/</a></p>


</div></div>`
        },
        inspiringInitiatives: {
            heading: "Inspiring initiatives",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-bf6fc80e-b7f5-4199-82ff-19ca3e262b6b">



</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0a30b813-ecda-4afb-a597-6e3fcdf3f9d4"><p><span style="font-size:1.5em;"><strong>Intégrer l'empreinte carbone des individus (i.e. les travailleurs à distance) dans le calcul de l'empreinte carbone de l'entreprise.</strong></span></p>

<p>Bien que l’empreinte carbone individuelle ne soit pas prise en compte dans la mesure de l’empreinte carbone de l’organisation, elle doit tout de même être considérée dans le cas du travail à distance, les activités de travail étant déplacées au domicile du salarié. En effet, l’empreinte carbone individuelle des salariés travaillant à distance va probablement augmenter en raison d’un usage accru d’électricité et de chauffage ou climatisation. &nbsp;Dans certains cas, l’empreinte individuelle augmentera encore davantage en raison d’un besoin d’espace supplémentaire dédié au travail au sein du domicile du salarié.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5f53795c-1d1a-4b3f-a966-fac758fec1c5"><p><strong><span style="font-size:1.5em;">Réduire le nombre de mètres carrés associés aux projets de relocalisation et aux nouvelles technologies de chauffage, ou de refroidissement du bâtiment.</span></strong></p>

<p>Introduire de nouvelles façons de travailler signifie réduire les effectifs présents dans les bureaux. Les bureaux existant peuvent alors être réagencés et les espaces de travail peuvent être réduits en termes de mètres carrés. Ce réagencement peut également être l’occasion d’investir dans des technologies de chauffage et de climatisation à faible consommation énergétique.&nbsp;</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d3378cd7-6379-438e-8695-2e00506e0b1e"><p><strong><span style="font-size:1.5em;">Fermer le siège social un jour par semaine.</span></strong></p>

<p>Proposer des solutions alternatives pour ceux qui ne sont pas intéressés par le télétravail en laissant ouvertes par exemple des agences réparties sur le territoire.&nbsp;</p>


</div><div></div><div class="h5p-column-content not-an-ios-device h5p-image-hotspots" id="h5p-interactive-book-section-0fddce02-1ea9-4c37-b17b-ba0e29cee19f"><div class="h5p-image-hotspots-container" style="width: 862.037px; height: 483px; font-size: 24px;"><img class="h5p-image-hotspots-background" src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/image-650a20abd3184.jpg" aria-hidden="true" style="width: 862.037px; height: 483px;"></div></div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-db043ca5-705f-431d-aad5-39bd84f45d6f"><p><em><strong>Exemple :</strong></em></p>

<p><em>Depuis mai 2022, la compagnie d'assurance belge Ethias ferme ses bureaux nationaux (situés à Liège et Hasselt) un jour par semaine, et invite ses employés à travailler à domicile. Elle autorise également le télétravail jusqu'à 3 jours par semaine, soit 60% du temps de travail. Cette mesure, innovante sur le marché belge, permet à Ethias de réduire sa dépendance aux énergies fossiles. Elle vient s'ajouter aux nombreuses initiatives déployées ces dernières années, qui ont permis à Ethias de réduire d'un tiers son empreinte carbone, avec quatre ans d'avance sur son ambition initiale.</em></p>

<p dir="ltr"><em>Si l'évolution vers plus de télétravail répond au souhait de la grande majorité des employés, ceux qui ne le souhaitent pas ont toujours la possibilité d'effectuer leur journée de travail dans certains autres bâtiments. « Avant de rationaliser l'accès à ses bâtiments, Ethias a pris du recul et a évalué plusieurs scénarios pour s'assurer qu'ils étaient bénéfiques à la fois pour les employés et pour l'environnement. Selon les données de CO2Logic, le gain écologique du télétravail est positif dès que le trajet travail-domicile est supérieur à 2km, ce qui est le cas pour plus de 90% de nos employés ».</em></p>

<p dir="ltr">&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3316ea8d-10cc-4b2e-8619-da7fe9bbc817"><p><strong><span style="font-size:1.5em;">Développer une application permettant aux employés de sélectionner le bureau qu'ils occuperont avant de venir travailler.</span></strong></p>

<p>Pour que le travail hybride soit efficace, l’organisation doit fournir des outils facilitant l’utilisation des espaces de travail lorsque les salariés viennent travailler dans les bureaux. Développer une application permettant aux salariés de sélectionner le bureau qu’ils occuperont constitue une solution efficace pour gérer cette organisation. De telles applications sont disponibles sur internet, et certaines permettent une personnalisation de l’espace de travail.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-7fa5861a-7573-4b91-8d58-ae8b84db7f88"><p><span style="font-size:1.5em;"><strong>Proposer aux employeurs d'investir, grâce à une rémunération défiscalisée, dans des solutions permettant de réduire l'empreinte carbone sur le lieu de travail de l'employé.</strong></span></p>

<p>On pourrait imaginer de convertir une partie de la rémunération, qui est aujourd'hui imposable, pour compenser la facture d'une installation géothermique, d'un panneau solaire ou d'un système de récupération d'eau au sein de l’entreprise ou au domicile de l’employé. &nbsp;</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d7c13367-6a61-421e-a373-eebd7190de95"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650ae2a5b19be.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-706a5767-ffee-4003-b5a9-c2fd99b9978d">

<p><strong><span style="font-size:1.5em;">Utiliser la gamification pour sensibiliser </span></strong></p>

<p>Par exemple, le jeu "Power Ranger" vise à mobiliser les employés sur la réduction de la consommation d'énergie.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-49e80623-fa73-4a32-ad4b-77f75ce8a5c4"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a245a56b04.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5ea9601e-15c0-41fa-b006-440a9fd9e737">

<p><span style="font-size:1.5em;"><strong>Offrir un conseil personnalisé</strong></span></p>

<p>Chaque situation étant singulière, il est nécessaire de pouvoir proposer une aide individualisée (pour discuter de ses pratiques de mobilités, ou des bons gestes à adopter chez soi), et d’offrir une petite incitation financière.</p>


</div></div>`
        },
        recommendation: {
            heading: "Recommandations",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9ff39201-b913-41dc-9ad8-c1d24b78e893">


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-b2dd1029-879d-4467-851d-7515066bd5a7"><p><strong><span style="font-size:1.5em;">Réorganiser les espaces de travail pour qu'ils consomment moins d'énergie.</span></strong></p>

<p>Cela peut se faire de plusieurs manières, comme revoir le choix des équipements et de l’alimentation, la régulation de l'éclairage, le type de matériaux utilisés pour les vitrages, améliorer l'isolation thermique, mettre en place des systèmes de chauffage permettant une utilisation plus ciblée, etc.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6a1ff1da-bea0-45f6-96ee-7326ac1f3395"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650ae72798dfa.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d7f97920-3d31-4127-9d19-2a50d6307c52">

<p><span style="font-size:1.5em;"><strong>Adapter son organisation en fonction des activités et des usages.</strong></span></p>

<p>Il s'agit de réfléchir en amont à l'organisation du travail et aux types d'activités à réaliser. Par exemple, assurer la planification des pratiques de télétravail pour pouvoir anticiper la présence ou non de personnes.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-611eb0c2-4350-4c7f-a602-8723e8b51b8e"><p><span style="font-size:1.5em;"><strong>Éviter les solutions toutes faites.</strong></span></p>

<p>Ce qui peut être bénéfique pour une entreprise peut l'être beaucoup moins au niveau d'un centre urbain - par exemple, si ce dernier décline ou se vide en raison de la désertion de ses travailleurs... De même, toutes les personnes qui travaillent à distance ne sont pas égales : certaines disposent d'un espace de travail dédié et d'autres non. Le coût de l'énergie, le mode et la durée du déplacement, s'avèrent également être des variables importantes dans le choix du lieu de travail.&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-54956a77-38f6-4873-8ff7-904c45dc5241"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650ae952abe3a.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c741d811-2784-453f-ae4a-6f38fd9c9466">

<p><span style="font-size:1.5em;"><strong>Les bonnes idées viennent souvent du personnel.&nbsp;</strong></span></p>

<p>L’approche bottom-up peut avoir un réel impact. Toutefois, le défi consiste à maintenir une approche cohérente. Si l'approche participative est isolée et ne s’inscrit pas dans une stratégie plus large, ou si les KPI de performance et financiers ne sont pas pris en compte, l'approche ne sera pas crédible. Attention au greenwashing !&nbsp;</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-be753b9f-780a-4d2b-b79e-d243220a732b"><p><strong><span style="font-size:1.5em;">Fermer les bureaux certains jours de la semaine pour économiser l'énergie.</span></strong></p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-cfe59f92-0f7d-4a1b-8a5b-4dc310c7c7b8"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650ae9e7ad642.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3a5a13fb-d0a4-4437-87c0-8cca2baa698a"><p>Dans le cas où de nombreux salariés auraient tendance à travailler à distance certains jours, les organisations peuvent considérer la fermeture de leurs bureaux ces jours-ci. Il est recommandé de sélectionner des jours de fermeture consécutifs, ce qui conduira à des économies d’énergies supérieures. Par exemple, fermer les bureaux le lundi, à la suite de la fermeture du week-end, réduit l’énergie dépensée lors de la mise en marche et de l’arrêt des systèmes consommateurs d’énergie (chauffage, climatisation, etc.).&nbsp;</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5b83f471-c473-4cc6-a446-bf3282adb362"><p><span style="font-size:1.5em;"><strong>Combiner le travail à distance, le flex-office et la réduction de l'espace de travail </strong></span></p>

<p>Une étude de l'ADEME (France) montre que si l'on prend en compte les réductions immobilières que le télétravail entraîne lorsqu'il est couplé au flex office (pratique consistant à ne pas avoir de bureau dédié sur le lieu de travail), le bilan environnemental global du télétravail s'améliore de manière significative de 52% par jour de télétravail par semaine (soit -234kg eqCO2/an pour chaque jour de télétravail supplémentaire par semaine). Les bénéfices environnementaux du flex office pourraient dépasser les bénéfices de la mobilité optimisée.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-2d3e4c85-3a9c-4d3a-a7e2-d1ea74bf3ce5"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650aea5b69adc.png" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d25f0f5-0bf7-4f85-b059-69b9e42fb240">

<p><span style="font-size:1.5em;"><strong>Décourager le travail à domicile à temps partiel.&nbsp;</strong></span></p>

<p>Le télétravail &nbsp; &nbsp; à temps partiel &nbsp;entraîne un bilan environnemental significativement négatif pour l’entreprise.<br>
&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-28e9f5f3-2c2d-4dbf-94b1-1e6affc73458"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650aeaf74c038.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-cfa3ffa5-6b28-41ef-aa8a-4bc8bde9fc55">

<p><strong><span style="font-size:1.5em;">Télétravail : attention !&nbsp;&nbsp;</span></strong></p>

<p>Une étude de l'ADEME (France) montre que le travail à distance permet de réduire la consommation liée à l’usage des bureaux par l’entreprise (papier, encre, fournitures, gobelets, décoration, vidéoprojecteurs, etc). Cependant, certaines de ces réductions sont répercutées sur l’employé. &nbsp; &nbsp; &nbsp;Ceci est particulièrement vrai &nbsp; &nbsp; &nbsp;pour la consommation d'énergie à domicile &nbsp; &nbsp; . L'étude estime que la consommation d'énergie faite à domicile pendant la journée de télétravail augmenterait mécaniquement : +20,7 kg eqCO2/an pour une journée de télétravail par semaine pour le télétravailleur.</p>


</div></div>`,
        }
    },
    et: {
        learningObjectives: {
            heading: "Õppeeesmärgid",
            html: `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-681e908e-49b8-4ea2-835e-d6b8b485b3e6">


<p><span><span><span><span><span><span><span style="color:#374151">Eesmärk on anda personalispetsialistidele eeskuju hübriidse töökeskkonna loomisel, mis mitte ainult ei toeta organisatsiooni eesmärke, vaid aitab positiivselt kaasa keskkonnasäästlikkusele.</span></span></span></span></span></span></span></p>

<p><span style="font-size:1.25em;"><strong><span style="color:#374151">Hübriidtöö juhtimise ja strateegia pädevus:</span></strong></span></p>

<ul>
\t<li><span><span><span><span><span><span><span><span style="color:#374151">Arendada oskust juhtida ja strateegiat tõhusalt luua hübriidses töökeskkonnas, keskendudes jätkusuutlikkuse põhimõtete integreerimisele. See hõlmab oskuste omandamist koostööks juhtkonna ja ristfunktsionaalsete meeskondadega, et juurutada jätkusuutlikke tavasid nii kaug- kui ka kontorikeskkonda.</span></span></span></span></span></span></span></span></li>
\t<li><span><span><span><span><span><span><span><span style="color:#374151">Õppige looma strateegilist visiooni, mis viib hübriidtöömudelid vastavusse organisatsiooni jätkusuutlikkuse eesmärkidega.</span></span></span></span></span></span></span></span></li>
\t<li><span><span><span><span><span><span><span><span style="color:#374151">Täiustage pädevust hübriidtööpoliitika ja -tavade kujundamisel, mis vähendavad organisatsiooni süsiniku jalajälge, näiteks optimeerides kaugtöögraafikuid, et minimeerida reisimist ja energiatarbimist.</span></span></span></span></span></span></span></span></li>
\t<li><span><span><span><span><span><span><span><span style="color:#374151">Saate ülevaate kontorite asukohtade hindamisest ja valimisest säästva transpordi kättesaadavuse ja pendelrände heitkoguste vähendamise potentsiaali põhjal.</span></span></span></span></span></span></span></span></li>
</ul>



<p><span style="font-size:1.25em;"><strong><span style="color:#374151">Jätkusuutlik personalijuhtimine hübriidkeskkondades:</span></strong></span></p>

<ul>
\t<li>Kasvatada oskusi inimressursside juhtimisel viisil, mis toetab ja suurendab hübriidtöömudelite tõhusust, edendades samal ajal ka jätkusuutlikkust. See hõlmab personalipoliitika väljatöötamist ja rakendamist, mis soodustab keskkonnasõbralikke töövõtteid nii kaug- kui ka kontoritingimustes.</li>
\t<li>Õppige integreerima jätkusuutlikkust värbamisse, koolitusse ja tulemusjuhtimisse, tagades, et töötajad on varustatud ja motiveeritud kasutama säästvaid tavasid olenemata nende töökohast.</li>
\t<li>Täiustage digitaalsete tööriistade ja suhtlusstrateegiate kasutamise oskusi, et tõhusalt kaasata ja toetada töötajaid hübriidse töökorraldusega.</li>
</ul>
</div>`
        },
        stakeHolders: {
            heading: "Sidusrühmad",
            html: `<div>
<p dir="ltr">Kuigi personalijuhtimise ja töökorralduse eest vastutab personalijuht, siis reeglina vastutab ta ainult osaliselt uute tööviisidega seotud küsimuste eest.</p>
<p>Selleks, et aidata kaasa ettevõtte süsinikdioksiidi heitkoguste vähendamisele, peab personalijuht tegema tihedat koostööd erinevate sidusrühmadega, kellest projektipartnerid on määratlenud olulisteks järgmised:</p>

<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2 dir="ltr">Tegevjuht / juhtkond&nbsp;</h2>

<h3 dir="ltr">Visiooni määratlemine</h3>

<p dir="ltr">Tegevjuhid peavad tunnistama, et nad peavad isiklikult täitma juhtivat rolli, et juurutada jätkusuutlikkust sügavuti ja laiaulatuslikult oma organisatsioonis. Tegevjuht ja juhtkond peavad määratlema visiooni, kehtestama selge eetika ja väärtused jätkusuutlikkuse osas ning väljendama jätkusuutlikkuse ja süsinikdioksiidi heitkoguste vähendamise alaseid ambitsioone. Milline on ettevõtte kohustusenda jaoks ja suund, mille ta võtab?</p>

<p>Sõnastatud visioon aitab juhtidel ja töötajatel luua seoseid jätkusuutlikkuse ja ettevõtte missiooni vahel ning seejärel ka oma igapäevase tööga.</p>

<h3>Eeskuju / käituge oma sõnade järgi</h3>

<p dir="ltr">Uuringud näitavad, et isiklik eeskuju on väga oluline ja sellel on tugev mõju töötajate poolsele muutuste aktsepteerimisele. Oluline on näidata, mida oodatakse ja mida hinnatakse, ning anda töötajatele selgeid näiteid selle kohta, mida hinnatakse ja neilt oodatakse.</p>
</div>

<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Ametiühingud&nbsp;</h2>

<p>Kui te rakendate hübriidtööprojekti ülalt alla (top-down), ilma ametiühinguid kaasamata, ilma et teil oleks nn. “saadikuid” ehk toetajaid ja viite läbi seda orgatsioonilist muutust ilma piloteerimata, siis ei pruugi seda tüüpi muutused tööle hakata. Samuti peate olema valmis tagasi pöörduma, kui teete vea, ja nõustuma oma plaanide läbivaatamisega.</p>
</div>

<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Jätkussutlikkuse juht / meeskond&nbsp;</h2>

<p>Jätkusuutlikkuse juht või meeskond on peamine osaline, keda tuleb kaasata NWoW projektidesse, mille eesmärk on vähendada süsinikdioksiidi heitkoguseid.&nbsp;</p>

<p>Kogemuste jagamine on võtmetähtsusega ning ettevõte võiks luua sektori, tööstuspargi või geograafilise piirkonna jätkusuutlikkuse eest vastutavate juhtide võrgustiku või osaleda selles, et jagada teadmisi ja häid tavasid.</p>
</div>

<div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h2>Hooned ja rajatised</h2>

<h3 dir="ltr">Kohustuste kombinatsioon&nbsp;</h3>

<p dir="ltr">Pole juhus, et viimasel ajal näeme üha enam personalijuhte, kes vastutavad ka hoonete eest, eesmärgiga integreerida jätkusuutlikkusele orienteeritud ümbermõtestamine ka hoonete kasutusmäära, ruumide dimensioneerimisse ja optimeerimisse, küttetehnoloogiate kohandamisse jms.</p>

<h3 dir="ltr">Tihe koostöö</h3>

<p dir="ltr">Juhul, kui personaliosakond ja hooned jäävad kaheks eraldi osakonnaks, siis on oluline luua tihe koostöö. Õige ruumide valik ja nende olukord aitab vähendada energiatarbimist. Seda on võimaliku saavutada läbi investeeringute suure energiatõhususega seadmetesse ja vähemsaastavatesse alternatiivsetesse energiaallikatesse – nagu näiteks fotogalvaanilised paneelid.</p>

<p dir="ltr">Hoonete ja ruumide temperatuuri peaks saama juhtida vastavalt kohal viibivatele inimestele ja personaliosakond peaks uurima töötajate ümbergrupeerimise võimalusi ruumides või korrustel, kui töötajate arv on vähenenud.</p>

<h3>Tulevikuvajadused</h3>

<p dir="ltr">On vajalik uurida tulevasi kinnisvaravajadusi nii töötajate kui ka ettevõtte enda tulevikuvajadusi arvestades. Kuigi ettevõtte hajutatud kohalikke tegevuskohti võib koondada kesksemasse asukohta, tuleb arvestada, et see võib kaasa tuua töötajate pikema pendelrände.</p>

<p dir="ltr">Koos võimalike linnades toimuvate liikumistega tuleb analüüsida nende mõju õhukvaliteedile, tegelikule liikuvusele ja elanike vaba aja veetmisele. Oluline on analüüsida suundumusi kolmandate kohtade (st töötaja ei tee tööd ei kontoris ega kodus, vaid üürib-kasutab selleks eraldi pinda) kasutamisel, mis võivad tekitada jääkreisi ja mitmekordistada kinnisvara kasutust ja hindu. Lisaks võib tekkida eluruumide laiendamise vajadus: kaugtöö võib kaasa tuua suuremate eluruumide soetamise, et tagada elukohas võimalus spetsiaalse koha või ruumi jaoks, kust tehakse kaugtööd.</p>
</div>

<p dir="ltr">Selleks, et töökorraldusega seotud käitumist tõepoolest muuta, on oluline, et ettevõtted kehtestaksid kommunikatsioonistrateegia ja et vastavaid jõupingutusi tehtaks regulaarselt. Sõnumit tuleb pidevalt levitada.</p>
</div>`
        },
        culture: {
            heading: "Kultuuri",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-51fdc91f-d822-4585-b029-c3132b46ab15">

</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d621e8b2-0c32-40ec-a524-408f805b4851"><p><strong><span style="font-size:1.5em;">Kultuur kui võti</span></strong></p>

<p>Süsinikdioksiidi heitkoguste vähendamist toetav tegevus hõlmab kindlasti ka kultuurilist muutujat. Just tööga organisatsioonilise ja juhtimiskultuuri, aga ka näiteks reisimise ja IT-vahendite ratsionaalsema kasutamise kallal on võimalik saavutada muutusi töötajate käitumises ja tarbimises, mis muudavad tasakaalu keskkonna jaoks positiivsemaks.</p>

<p>Kontrollige ettevõtte keskkonnapoliitika ja üldise kultuuri kooskõla. Kuidas te saaksite seda teha? Uurides juba olemasolevaid tavasid, harjumusi ja stiimuleid, et kontrollida, kas need ei ole keskkonnastrateegiaga vastuolus.&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-ec71dbde-552a-4919-89c6-8adb3e59180f"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-659b0e3914c5e.png" alt="" title="Culture is Key" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4db97028-02a0-45df-b3dc-a2400e1f6992"><p><span style="font-size:1.5em;"><strong>Keskenduge õigele asjale</strong></span></p>

<p>Kaugtöö ei ole keskkonnale iseenesest "parem." On palju uuringuid, mis toietavad seda väidet ja ka neid, mis näitavad vastupidist. Ettevõte ei lõpeta näiteks tööruumide kütmist ja valgustamist sellepärast, et mõned töötajad töötavad mujal, kodus või mõnes teises ruumis.</p>

<p>Ettevõtte energiatarbimine võib seega vaatmata tööruumides viibivate inimeste väiksemale arvule jääda samaks. Kuid töötajate kodude energiatarbimine on sellest tulenevalt suurem. Tuleb arvestada ka hooajalist mõju, mis võib riigiti erineda – isegi kui kütmist saab asendada kliimaseadmega.</p>

<p>Lisaks sellele näitavad mitmed uuringud, et kaugtöö kasutuselevõtt ei välista inimeste liikuvust ja kohati on isegi kalduvus reisida rohkem, näiteks teha väikeseid otsi, jätkata pereliikmete viimist ja toomist, nt. laste transport lasteaeda või kooli, kodust väljas lõunasöögid jne. Seega, on võimalik, et tekiksid täiendavad, kuigi lühemad, sõidud. Kokkuvõtlikult võib rääkida "ebakindlast" ökotasakaalust.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e93095af-95fc-4a5d-b59d-b465dbb8ebde"><p><span style="font-size:1.5em;"><strong>Tõstke teadlikkust </strong></span></p>

<p>Teadlikkuse tõstmine ja tõenduspõhistel materjalidel põhinev haridus töökoha käitumisviiside kohta on üks peamisi inimressursside arendajate käsutuses olevaid vahendeid. Töötajate teavitamine nende tegevuse keskkonnakuludest on äärmiselt vajalik. Veelgi parem, kui see teadlikkuse tõstmine, teavitamine või koolitus on juhitud strateegiast, mis on kooskõlas tegevusega ja/või ettevõtte ambitsioonidega saavutada süsinikdioksiidi neutraalsus.</p>

<p>Tavaliselt keskendutakse kaug-/hübriidtöö puhul peamiselt heaolule/töökorraldusele ja vähem jätkusuutlikkusele. Töötajate teavitamine ja koolitamine kliimamuutuste teemal ja sellest, kuidas nende töökorraldus võib mõjutada süsinikdioksiidi heitkoguseid peaks toimuma läbi pideva suhtlemise ja dialoogi.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a2f1c3ca-765a-48bc-9ae8-5bf7b0657d0b"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-659b0e6194c3e.png" alt="" title="Raising Awareness" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-56f5683d-ac8f-457a-aa74-6ceab16d8394"><p><strong><span style="font-size:1.5em;">Algatage kaasav lähenemine</span></strong></p>

<p>Töötada välja osaluspõhine lähenemisviis, mis kaasab töötajaid, kes on jätkusuutlikkuse suhtes tundlikud või aktiivsed, et muuta nad antud valdkonna saadikuteks ja kaasata teisi töötajaid.</p>

<ul dir="ltr">
\t<li>ideede kogumine,</li>
\t<li>parimate tavade jagamine</li>
\t<li>ettepanekute tegemine</li>
\t<li>… jne.</li>
</ul>

<p>Mitmed 2022. aastal lääneriikides läbi viidud uuringud on näidanud, et enamik töötajaid näevad hübriidtöö väärtust ja et paindlikkus on üks olulisemaid tegureid, kui otsustatakse, kas võtta vastu tööpakkumine. Suurtes ettevõtetes on järk-järgult kujunemas norm, mille kohaselt viibitakse 3 päeva nädalas kontoris kohal.</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d36a5e02-5941-4954-acb4-08a1432622b7"><p><strong><span style="font-size:1.5em;">Pöörake tähelepanu pingetele </span></strong></p>

<p>Peate tuvastama pinged, mis tekivad nii majandusliku tulemuslikkuse kui ka sotsiaalse ja keskkonnaalase tulemuslikkuse eesmärkide saavutamisel, nii lühiajalises loogikas - mis iseloomustab organisatsioone tänapäeval - kui ka pikemaajalises loogikas - milles jätkusuutlikkuse mõiste on kinnitatud.</p>
</div></div>`
        },
        hr: {
            heading: "HR protsessid",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e9287db0-3f7f-4113-88d3-e2117c140a0e">

</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-47f54b42-11d9-482f-8720-84285c76de62"><p dir="ltr"><strong><span style="font-size:1.5em;">PERSONALIPOLIITIKA</span></strong></p>

<p>Töötada välja poliitika, et tõsta teadlikkust säästva arengu küsimustest ja organisatsiooni tegevusest selles valdkonnas mitmes etapis: töölevõtmine, pidev koolitus...</p>

<ul>
\t<li>Viia läbi läbilõikeuuring, mis hõlmab NWoW ja heaolu ning säästva arengu küsimusi.</li>
\t<li>Tagada kõigi personalipoliitikate kooskõla süsinikdioksiidi heitkoguste vähendamise eesmärkidega.</li>
</ul>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-40fb375e-8fd6-46af-9670-4af0cb630dbb"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a000f9022c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-51d2007f-0995-4d97-be89-ede2d14b5c4b"><p><strong><span style="font-size:1.5em;">VÄRBAMINE</span></strong></p>

<p><strong>Hindame kandidaatide teadlikkust ja väärtusi seoses säästva arenguga ning nende vastavust organisatsiooni ambitsioonidele ja eesmärkidele.</strong></p>

<p>Kas me näiteks värbamisel arvestame kandidaatide süsinikujalajälge? Kas me kontrollime, kas nende käitumine on kooskõlas organisatsiooni eesmärkidega?</p>

<p>Kliimaga seotud väljakutsed kujutavad endast märkimisväärset atraktiivsuse hooba kandidaatide jaoks, kes otsivad tähendust ja motivatsiooni töötajana, kes on juba pühendunud sellele küsimusele.</p>

<p>See tähendab, et üha enam on värvatvatel ja töötajatel sellised küsimused nagu milline on ettevõtte liikuvuspakkumine, kas on olemas kaugtööpoliitika, milliseid algatusi on tehtud säästva arengu edendamiseks jne. Kui ettevõttel ei ole selget poliitikat, siis on ta sunnitud improviseerima ja vastama juhtumipõhiselt, mis ei pruugi paljulubavaid kandidaate ligi meelitada.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-82f5014d-e54b-4a0f-8cab-1a43a166b8b1"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a005f92b37.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0c101637-6a96-4185-86a2-7b6ad760abbd"><p><strong><span style="font-size:1.5em;">KOOLITUS</span></strong></p>

<p><strong>Selgitage, miks jätkusuutlik ümberkujundamine on ettevõtete ja töötajate jaoks probleemiks.</strong></p>

<p><strong>Esimene väljakutse </strong>seisneb selles, et mõista, mis on kaalul, alustades teadusest: mida teadlased ütlevad kliimamuutuste, loodusvarade, bioloogilise mitmekesisuse ja nende mõju kohta meie elule – kogukondadele, ettevõtetele, üksikisikutele.</p>

<p><strong>Teine väljakutse </strong>on seotud tegevustega, kuna jätkusuutlik ümberkujundamine puudutab kõiki kutsealasid. Töö kohta tuleb aru saada, kuidas, siis koostada oma teekaart tegevuseks spetsiaalsete kursustena sõltuvalt sellest, kas töö on ostu-, IT-, õigus-, finants-, turundus-, personaliosakonnas jne.</p>

<p>Koolitage juhti, kuidas juhtida töötajate pühendumust: kuidas reageerida, kui näiteks parim müügimees, kui ta tuleb koos meeskonnaga seminarile, tuletab meelde, et ta enam ei lenda?</p>

<p>Tutvustage ökoloogilisi mõõtmeid ja nende hindamist.</p>

<p>Prognoosige tulevaste oskuste nõudeid: millised on uue raamatupidaja, uue IT-spetsialisti, uue ostujuhi, uue personalitöötaja jt. oskused jätkusuutlikkusele ülemineku kontekstis?</p>

<p>Keskkonnakriteeriumi integreerimine ettevõtte teenusepakkujate valikusse, eelkõige koolituse puhul. Alati on võimalik teavitada ja vaidlustada teenuseosutajaid selle valikukriteeriumi kohta, et julgustada neid tegutsema.&nbsp;<br>
&nbsp;</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8e9a2632-788a-489a-a9d0-a5cec6137a3a"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a00b6f3b38.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-78bed962-f188-4975-8f73-b0dea716bef1"><p><strong><span style="font-size:1.5em;">TULEMUSLIKKUSE JUHTIMINE &amp; HINDAMINE</span></strong></p>

<p>Ettevõtted seavad endale üha enam säästva arengu eesmärke, mille saavutamiseks tuleb neid rakendada osakondades ja meeskondades. Seetõttu on oluline hinnata, mida juhid teevad oma meeskondadega nende eesmärkide saavutamiseks.</p>

<p><strong>Integreerige säästva arengu küsimused töötajate iga-aastastesse eesmärkidesse ja nende oskuste arendamise kavasse.</strong></p>

<p>Väga vähesed ettevõtted on seni need jätkusuutlikkuse eesmärgid tulemusnäitajateks muutnud. Kui nad seda teevad, viitavad nad tavaliselt ÜRO 17 säästva arengu eesmärgile, kuid see peab olema palju konkreetsem.</p>

<p>Võtke seda arvesse ka igapäevase käitumise osana: arvestage jätkusuutlikkuse aspekti koosolekuzte korraldamisel, firmapeo korraldamisel, koolituspakkumise üle otsustamisel, reisivalikute tegemisel jne.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-bcf69465-7d98-4b54-8f57-16c62f1e31cf"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a010e1bb7c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c5702d04-36d7-48a5-bdc3-dfd6ae0b7e6e"><p><strong><span style="font-size:1.5em;">TASUSTAMINE</span></strong></p>

<p>Palgapaketti kuuluvate soodustuste valikul või säästva arenguga seotud kriteeriumide integreerimine tuleks sisse viia nii direktorite, juhtide kui ka kõigi muude töötajate tasustamisse. Kui töötajad saavutavad oma tulemusi, kuid keskkonna arvelt, siis, kas seada nende preemia kahtluse alla?</p>

<p><strong>Soodustage ja hinnake oma töötajate keskkonnahoidlikku käitumist.</strong></p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-31d857a2-1f29-40e7-bf53-f14345717159"><p><strong><span style="font-size:1.5em;">TÖÖ PAINDLIKKUS</span></strong></p>

<p>Pakkuge alternatiivseid töökohti võimalikult lähedal töötajate elukohale (kolmandad kohad, koostöötamise ruumid jne).&nbsp;</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-335b1072-9195-49d8-bb14-1838c832c36b"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a013f7d826.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-038d68de-3a7b-450d-9248-7cc305cdf537"><p><strong><span style="font-size:1.5em;">KOMMUNIKATSIOON</span></strong></p>

<p>Suhtle regulaarselt, mitte ad hoc. Näiteks ei piisa iga-aastasest teabevahetusest, kui soovite oma töötajaid kaasata otsustusprotsessidesse.</p>

<p>Olge autentne ja läbipaistev, eriti mis puutub veel saavutamata edusammudesse. Teie sõnavõtt peab olema kooskõlas ettevõtte tegeliku kliimakujundusega, vastasel juhul ei võta töötajad seda hästi vastu.</p>

<p>Suunake oma kommunikatsioon konkreetsetele teemadele. Näiteks: "tööriistakomplekt, et tegutseda ettevõtte tasandil ökoloogilise ülemineku nimel"; "kuidas investeerida oma töötajate säästud keskkonda teenivatesse fondidesse" jne.</p>

<p>Mitmekesistage kasutatavaid formaate (memo, video, podcast, esitlus jne) ja e-kanaleid (e-post, intranet, ettevõtte sotsiaalvõrgustik, road show jne), et maksimeerida oma sõnumite levikut.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-c0f62eee-721d-477c-80c6-823a977066d4"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a017fdc062.jpg" alt="" title="" style="width: 100%; height: 100%;"></div></div>`
        },
        tools: {
            heading: "Vahendid",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-fafe62b2-6e1d-4dab-8614-5eae029317ac">

</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9f6a9bf2-ee6b-4dea-beb7-8f269fdd47e6"><p dir="ltr"><strong><span style="font-size:1.5em;">Säästvuse algatuse töörühma või rohelise meeskonna / saadikute loomine</span></strong></p>

<p dir="ltr">Ei saa ära teha olulist, kui ei ole motiveeritud inimesi. Tuvastage organisatsioonis tõenäolised muudatuste elluviijad ja proovige neid võimaluse korral leida mitmetest tasemetest ja osakondadest. Proovige neid idee jaoks kaasata, uurides hübriidtöö võimalusi ja kasu süsiniku heitkoguste vähendamiseks. Kuigi see poisitsioon on puhtalt vabatahtlik, on õige hoiak ja motivatsioon olemas, ning see annab neile võimaluse tegevuseks organisatsioonis, kooskõlas nende sisemise motivatsiooniga keskkonna eest hoolitsemiseks.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-658bbecb-b411-40b2-8187-4d368eb13db1"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a076563db5.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4efad5a4-1ecd-417f-a6e1-c245bfb5ecbe"><p><strong><span style="font-size:1.5em;">Kontoriga seotud tarbimise auditeerimine</span></strong></p>

<p>Mõned näited alljärgnevatest tegevustest (allikas: <a target="_blank" href="http://www.sustainablebusinesstoolkit.com">www.sustainablebusinesstoolkit.com</a>):</p>

<ol>
\t<li><strong>Eemaldage kasutuselt ühekordsed tassid/topsid</strong> kui keskkonnale kahjulikud. Plastik- ja stürofoamtopsid ei ole biolagunevad ja võivad säilida aastaid pinnases või sattuda merre ja jõgedesse. Proovige kanda kaasas korduvkasutatavat pudelit või termost vee või kohvi jaoks. Kui ühekordselt kasutatavatest tassidest loobumine on liiga häiriv, siis kaaluge selle asemel keskkonnasõbralike tasside kasutamist.</li>
\t<li><strong>Kehtestage BYORB-poliitika</strong> (Bring Your Own Reusable Bottle).</li>
\t<li><strong>Kasutage ringlussevõetud trükipaberit:</strong> ringlussevõetud paberi kasutamine printerites on lihtne roheline idee. Tänapäevase tehnoloogia abil ei märka teie ja teie kolleegid isegi vahet.</li>
\t<li><strong>Prindi kahepoolselt: </strong>Veenduge, et teie arvuti ja printer on vaikimisi seadistatud kahepoolseks printimiseks. Uuringute kohaselt võib kahepoolne printimine vähendada paberijäätmeid 10-40%.</li>
\t<li>Võtke kasutusele <strong>paberivaba dokumendivahetus.</strong></li>
\t<li><strong>Proovige ringlussevõetud ja biolagunevaid pliiatseid</strong>, selle asemel, et plastmassist pastapliiatseid ära visata.</li>
\t<li><strong>Vähendage arvutimonitori heledust.</strong> Harvardi ülikooli uuirngute põhjal võib arvutimonitori heleduse vähendamine 100%-lt 70%-le säästa kuni 20% monitori energiast. Enamik inimesi ei märka selle kohandamise erinevust, seega on see kiire ja lihtne idee roheliseks muutuda.</li>
\t<li><strong>Lülitage oma arvutimonitor öösel välja,</strong> et säästa energiat.</li>
\t<li><strong>Rakendada “jäätmelkäike”. </strong>Jäätmelkäik seisneb selles, kui töötajad käivad oma töökoha korrusel ringi ja teevad kindlaks raiskavad tegevused. See on lõbus viis, kuidas kaasata kõiki, et luua ideid kontori keskkonnamõju vähendamiseks. Jäätmelkäigud ei nõua täiendavaid vahendeid, ressursse ega kolmandaid osapooli. Nad kasutavad ära igaühe aju, et tuvastada “madalal rippuvad viljad” ja teha parandusi.</li>
\t<li><strong>Vabanege individuaalsetest prügikastidest! </strong>Mõnes kontoris on prügikastid iga inimese laua juures. Kui eemaldate individuaalsed prügikastid ja kasutate nende asemel keskseid prügikaste, suurendate oma ringlussevõtu määra ja vähendate jäätmete hulka.</li>
\t<li><strong>Osta keskkonnasõbralikku ja vastutustundlikult hangitud kohvi.</strong></li>
\t<li><strong>Optimeerige loomuliku valguse kasutamist. </strong>Kui teie kontoris on aknad, siis optimeerige kindlasti loomulikku valgust. Vältige päevasel ajal elektripirnide sisselülitamist, hoides kardinad, varjud ja rulood lahti.</li>
\t<li><strong>Investeerige energiatõhusasse valgustusse. </strong>Energiasäästlike valgustite kasutamine säästab elektrit ja raha. Energiaefektiivseid valgusteid on igas vormis ja suuruses. Igale vajadusele ja igale eelarvele leidub midagi!</li>
\t<li><strong>Investeeri nutipistikutesse.</strong> Bürood saavad automaatselt energiat säästa kasutades nutikaid pistikupesasid. Nutipistikud lülitavad tuled, ventilaatorid ja seadmed automaatselt välja. Enamik nutipistikuid võimaldab teil oma seadmeid telefonis oleva rakenduse abil kõikjalt juhtida.</li>
\t<li><strong>Reguleerige oma termostaati suvel 2 kraadi kõrgemale ja talvel 2 kraadi madalamale.</strong> Alternatiivina võite kasutada energiasäästlikku termostaati, mis säästab ise energiat.</li>
\t<li><strong>Julgustage vee säästmist nõuannete ja juhistega vannitubades. </strong>Püüdke märke välja vahetada ja kasutage loomingulisi kujundusi, et hoida sõnumit värskena.</li>
\t<li><strong>Vali parandamine asendamise asemel.</strong></li>
\t<li><strong>Korraldage koos oma meeskonnaga rohelisi võistlusüritusi.</strong></li>
</ol>

<p><strong>Vihje</strong>: Kõige parem viis keskkonnaalaste eesmärkide saavutamiseks on astuda järskude muudatuste asemel väikeseid samme. Selle asemel, et teha suuri muudatusi, mis võivad mõnel töötajal üle jõu käia, keskenduge pigem ühe väikese muudatuse tegemisele igas töökohas.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-44756050-178f-436e-893f-651f0a73f718"><p><strong><span style="font-size:1.5em;">Kaugtööpoliitika ja -tavade süsinikujalajälje hindamine</span></strong></p>

<p>Reutersi uuringus 20 suurema ettevõtte kohta, kus kasutatakse kaugtööd, leiti, et ainult pooled neist olid püüdnud hinnata kaugtöötajatega seotud heitkoguseid ja veel vähem olid püüdnud nende mõju leevendada või kompenseerida. Isegi Microsoft, kus on erakordselt jõuline ettevõtte jätkusuutlikkuse programm ja kus alustati kaugtöötajate heitkoguste hindamist 2020. aastal, püüab ikka veel saada aru oma kaugtöötajate süsinikuheitmete mõju suurust.&nbsp;</p>


</div><div></div>

</div></div></div></div></div></div>      </div>    </div>  </div>  </div></div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-96673788-aac0-4bb1-a147-1f060a8bad55"><p><strong><span style="font-size:1.5em;">Energiatarbimise näitajad </span></strong></p>

<p>Kuigi mõõtmisvahendid on olemas, on nendega seoses mitmeid raskusi. Esiteks see, et mõõtmine põhineb sellel, mida üks tegevuskoht tarbib, ja sageli on ettevõtetel mitu tegevuskohta väga erinevates geograafilistes piirkondades ja väga erineva ajalooga. Teine vajadus arvestada kogu ökosüsteemi, sealhulgas töötajaid ja tarnijaid. Nende kaasamine võrrandisse ja nende mõjutamine on palju keerulisem.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-72db514d-8a33-4eb4-a525-83ba1cf3c4c2"><p><strong><span style="font-size:1.5em;">Hübriidhalduri planeerimine</span></strong></p>

<p><strong>Võtke vastu poliitika, mis võtab arvesse üldist mõju reisimisele ja keskkonnale (nt vältida kaugtööd esmaspäeviti ja reedeti, mida enamik ettevõtteid kasutab).</strong></p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a0e4f2b1-4e13-4f99-b95f-0ef8a62df29a"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a0d17a1b25.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-23380b42-0403-427c-ad5c-a2eb83219cfa"><p>David Autissieri pakutud mudel võiks selles osas olla huvitav. See kutsub meid üles seadma kahtluse alla töömeetodid, mida tuleb käsitleda näost-näkku- ja kaugtöö osas Agile meetoditest laenatud mõistete "Build" ja "Run" kaudu. Kaugtöö toimib hästi "Run", st tuntud, korduvate tegevuste puhul, kuid on vähem kohandatud "Build", st kõigi tegevuste puhul, mis hõlmavad investeeringuid tulevikku, näiteks uued projektid. Teisisõnu, me oskame kaugtööd teha jooksva tegevuse puhul, mille puhul me töötleme, kuid vähem selle puhul, mis nõuab interaktsioone, vahetusi, usaldust ja kollektiivset intelligentsust, et alustada. Sel juhul on tõenäoliselt eelistatavam näost näkku kohtumine.</p>

<p>Sellest lähtuvalt saab personalijuht innustada juhte mõtlema koos oma meeskonnaga, millal või millistel ülesannetel on oluline, et kõik koos kohal oleksid ja mis eesmärgil; ning millal ja millistel ülesannetel on võimalik võita, kui teatud tegevusi teostatakse veebis. Näiteks operatiivse projekti järelevalve koosolek on tõhusam, kui see viiakse läbi veebis. Mis puutub uue töötaja tervitamisse meeskonnas, siis on parem seda teha isiklikult. jne... Sellele refleksioonile saab siis lisada süsiniku mõju kriteeriumi. Selle tulemusel võib rühmitada ülesanded nii, et need moodustavad kaugtööklastri, ja teised nii, et need moodustavad silmast silma töö klastri, võttes arvesse kõiki parameetreid: kaasatud inimeste arvu, nende poolt läbitavaid vahemaid ja transpordiliike, kontori täituvuse määra, mõju digitaalkasutuse osas jne.</p>

<p>Idee ei ole matemaatiline, vaid pigem oportunistlik lähenemine: kui rühmakohtumine võib anda parema tulemuse kui see, mida saavutataks eemalt, hoolimata suuremast keskkonnakulust, on tõenäoliselt siiski asjakohane seda silmast silma läbi viia. See on valiku küsimus. Huvi on selles, et oleks võimalik anda endale vahendid otsustamiseks, kaaludes pidevalt erinevaid töökorralduse parameetreid.</p>

<p dir="ltr">&nbsp;</p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6ab0511b-b00e-4b8e-af60-873709089171"><p dir="ltr"><strong><span style="font-size:1.5em;">Rakendus, mis võimaldab kontoriruumi optimeerimist seoses kaug-/hübriidtööga</span></strong></p>

<p>Erinevad rakendused on turul olemas, mis aitavad vähendada tööruumide korraldamisega seotud jõupingutusi ja paremini hallata kontori paigutust ning töötajate kohalolekut.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-27fceef1-f99c-4f3a-adc2-ca263f01efff"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a11737328b.png" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-79477eba-a9a7-4660-bb64-90d2f1600a3e"><p><strong><span style="font-size:1.5em;">Korraldage regulaarseid töötubasid / lõuna- ja õppetunde jätkusuutlikkus@kontor teemal.</span></strong></p>

<p>Jätkusuutlikkuse töötuba on mõeldud selleks, et kõik saaksid hõlpsasti ja hõlpsasti tutvuda laiema kontekstiga, kuidas kasutada jätkusuutlikkust kontoris, ning võimaldada neil töötada välja strateegiaid, kuidas lisada jätkusuutlikkuse kaalutlused oma igapäevatöösse.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-783f311b-b0bc-455e-8ff2-8602b11a7275"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a12151f7ae.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9e69f91d-beba-4166-86c4-9e3c563b3d40"><p><strong><span style="font-size:1.5em;">Kliima Freski</span></strong></p>

<p>Vaid 3 tunniga õpetab koostöövõimeline Climate Freski töötuba kliimamuutuste aluseks olevat teadust ja annab võimaluse tegutseda. Kasutades ja aktiveerides osalejate kollektiivset intelligentsust, võimaldab Climate Fresk neil võtta teema eest vastutuse. Metoodika ei kasuta hierarhilist õpistruktuuri, mistõttu leiab iga osaleja oma koha õppetöös. Kuna osalejad õpivad seostama kliima põhjuseid, mõjusid ning muutusi, suudavad nad esimest korda mõelda valdkonnale teise nurga alt ja mõista probleemide süsteemset olemust.&nbsp;</p>

<p><strong>Lisateave</strong>:&nbsp;<a target="_blank" href="https://climatefresk.org/" target="_blank">https://climatefresk.org/</a></p>
</div></div>`
        },
        inspiringInitiatives: {
            heading: "Inspireerivad algatused",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-bf6fc80e-b7f5-4199-82ff-19ca3e262b6b">


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d50e317c-49f4-4ce7-b827-1e7618ea8802"><p><span style="font-size:1.5em;"><strong>Integreerige üksikisikute (kaugtöötajate) süsinikujalajälg ettevõtte süsinikujalajälje arvutamisse</strong></span></p>

<p>Kuigi individuaalset süsinikujalajälge ei arvestata organisatsiooni süsinikujalajälje mõõtmisel, tuleb kaugtöö korral seda siiski arvesse võtta, kuna töötegevused nihkuvad töötaja kodusesse keskkonda. Kaugtöötajate individuaalne süsinikujalajälg suureneb tõenäoliselt, kuna nad kasutavad rohkem elektrit ja kütet ning mõnel juhul on vajadus lisaruumi järele koduses keskkonnas, mis suurendab neid heitkogust veelgi.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1fe06bb5-7157-48c0-b078-a9a1bbedf826"><p><strong><span style="font-size:1.5em;">Vähendage ümberpaigutamisprojektide ja uute tehnoloogiatega seotud ruutmeetrite arvu hoone kütmisel, jahutamisel.</span></strong></p>

<p>Uute tööviiside juurutamine tähendab tegelikult tööjõu vähendamist kontorites. Olemasolevaid kontoreid saab ümber kujundada ja kontoripinda saab vähendada ruutmeetrite osas. See on samuti hea võimalus investeerida energiatõhusamatesse küte- ja jahutustehnoloogiatesse.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-2e6c4420-0191-429c-ae35-3c163eee07a7"><p><strong><span style="font-size:1.5em;">Sulgege peakorter üheks päevaks nädalas.</span></strong></p>

<p>Pakkuge alternatiivseid lahendusi neile, kes ei ole huvitatud kodutööst, et töötada ettevõtte teistes kontorites, mis jäävad avatuks.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-9bb64ff6-9682-46c1-b4d0-851b3c36cfb3"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-659e73b7cfb38.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-fe2e9f0a-6c9f-4004-a698-7d8150687ee6"><p><strong>Näide:</strong></p>

<p><em>Alates 2022. aasta maist on Belgia kindlustusselts Ethias sulgenud oma riiklikud kontorid (Liege ja Hasselt) üheks päevaks nädalas ja kutsub oma töötajaid üles töötama kodus. Samuti lubab ta kodutööd kuni 3 päeva nädalas, st 60% tööajast. See Belgia turul uuenduslik meede võimaldab Ethiasel vähendada oma sõltuvust fossiilkütustest. Antud uuendus lisandub viimastel aastatel rakendatud arvukatele algatustele, mis on võimaldanud Ethiasel vähendada oma süsinikujalajälge kolmandiku võrra, mis on neli aastat enne kavandatud tähtaega.</em></p>

<p><em>Kuigi liikumine kaugtöö suunas on kooskõlas enamiku töötajate sooviga, on neil, kes seda ei soovi, endiselt võimalus veeta oma tööpäev mõnes teises ettevõtte kontoris, mis asub mujal. "Enne hoonetele juurdepääsu ühtlustamist analüüsis Ethias pikalt ja hindas mitmeid stsenaariume, et tagada, et need oleksid kasulikud nii töötajatele kui ka keskkonnale. CO2Logic'i andmete kohaselt on kaugtöö ökoloogiline kasu positiivne, niipea kui töö- ja kodu vahemaa on suurem kui 2 km, mis kehtib üle 90% meie töötajate puhul.”</em></p>
</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3316ea8d-10cc-4b2e-8619-da7fe9bbc817"><p><strong><span style="font-size:1.5em;">Rakenduse väljatöötamine töötajatele, et nad saaksid enne tööle tulekut valida, millise tööruumi nad tööle asuvad.</span></strong></p>

<p>Hübriidtöö tõhusaks toimimiseks peab organisatsioon pakkuma vahendeid, mis hõlbustavad kontoripinna kasutamist päevadel, mil töötajad kontorisse tulevad. Töötajatele võimaluse andmine valida enne tööle tulekut kontoriruum, arendades selleks rakendust, on hea lahendus. Internetis leidub rakendusi, mis saab kergesti kohandada organisatsiooni hõivatud ruumide jaoks.</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c6362667-2265-4e67-bf80-74a50e2a5925"><p><strong><span style="font-size:1.5em;">Ettepanek: tööandja võimalus investeerida maksuvaba töötasu abil lahendustesse, mis vähendavad süsiniku jalajälge töötaja töökohal</span></strong></p>

<p>Võiksime ette kujutada, et üks osa töötasust, mis täna on maksustatav, konverteeritakse geotermilise paigalduse või päikesepaneeli või veekogumisseadme või muu sarnase investeeringu arve tasumiseks töötaja asukohas (kodus).</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d7c13367-6a61-421e-a373-eebd7190de95"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650ae2a5b19be.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-706a5767-ffee-4003-b5a9-c2fd99b9978d"><p><strong><span style="font-size:1.5em;">Kasutage gamification’i</span></strong></p>

<p>Mäng "Power ranger", et mobiliseerida töötajaid energiatarbimise vähendamiseks.</p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-49e80623-fa73-4a32-ad4b-77f75ce8a5c4"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650a245a56b04.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5ea9601e-15c0-41fa-b006-440a9fd9e737"><p><strong><span style="font-size:1.5em;">Individuaalse nõustamise pakkumine</span></strong></p>

<p>Abistage iga töötajat eraldi, kuna igaühel on erinev olukord - alates transpordiviisi muutmise valiku toetamisest, kuni abi muutmiseks nende enda juures – nt väikese rahalise stiimuliga.</p>
</div></div>`
        },
        recommendation: {
            heading: "Soovitused",
            html: `<div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9ff39201-b913-41dc-9ad8-c1d24b78e893">


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3427f056-8466-4a95-adec-01f1e32a4592"><p><span style="font-size:1.5em;"><strong>Korraldage tööruumid ümber nii, et need tarbiksid vähem energiat</strong></span></p>

<p>Seda saab teha mitmel viisil, näiteks seadmete valmistamise tingimustest lähtumine nende soetamisel, energiavarustuse ja valgustuse reguleerimise teel, klaasidena kasutatavate materjalide tüübii valik, soojusisolatsiooni kasutamine, sihipärasemat kasutamist võimaldavate küttesüsteemide rakendamine jne.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6a1ff1da-bea0-45f6-96ee-7326ac1f3395"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650ae72798dfa.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d7f97920-3d31-4127-9d19-2a50d6307c52"><p><strong><span style="font-size:1.5em;">Kohandage infra- ja tugisüsteeme vastavalt tegevustele ja kasutusaladele</span></strong></p>

<p>See tähendab, et tuleb ette mõelda, kuidas tööd korraldada ja milliseid tegevusi läbi viia. Kaugtööpraktikate prognoositavuse tagamine, et oleks võimalik ette näha, millal inimesed seda teevad või mitte.&nbsp;</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d04f5047-e710-4960-aea1-5f2dd5c634c4"><p><span style="font-size:1.5em;"><strong>Vältige põhimõtet "Üks suurus sobib kõigile"</strong></span></p>

<p>Mis võib olla kasulik ettevõttes, võib olla palju vähem kasulik linna elamurajoonis - näiteks kui viimase tähtsus väheneb või see tühjeneb oma inimeste lahkumise tõttu... Samamoodi ei ole kõik kaugtöötajad võrdsed: mõnel on spetsiaalne tööruum (oma kodus) ja mõnel mitte. Energia maksumus osutub oluliseks muutujaks kodus või kontoris töötamise valikul, sõltuvalt näiteks kontrorissetuleku transpordiviisist ja selleks kulunud ajast.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-54956a77-38f6-4873-8ff7-904c45dc5241"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650ae952abe3a.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c741d811-2784-453f-ae4a-6f38fd9c9466"><p><strong><span style="font-size:1.5em;">Head ideed tulevad sageli tootmistöötajatelt, sest nad on tegelikule tööle kõige lähemal</span></strong></p>

<p>Ja see on koht, kus saate tõesti mõju avaldada. Väljakutseks on aga järjekindluse säilitamine. Kui osaluspõhine lähenemisviis ei toimi või näib olevat isoleeritud mõni algatus, ilma et see oleks osa laiemast strateegiast; või kui ettevõtte sõnum on, et keskkond on oluline, kuid on olemas ainult finantstulemuste KPI-d, ei ole see lähenemisviis usaldusväärne. Hoiduge rohepesust!</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-aa1ea73d-5992-4018-b240-0879fed576de"><p><strong><span style="font-size:1.5em;">Energia säästmiseks sulgege kontorid teatud nädalapäevadel</span></strong></p>
</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-cfe59f92-0f7d-4a1b-8a5b-4dc310c7c7b8"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650ae9e7ad642.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3a5a13fb-d0a4-4437-87c0-8cca2baa698a"><p>Kui paljud töötajad kipuvad teatud päevadel kodus töötama, võivad organisatsioonid kaaluda kontori sulgemist sel päeval. Soovitatav on valida järjestikused sulgemispäevad, kuna see säästab rohkem energiat. Näiteks nädalavahetusele järgneva esmaspäeva sulgemine vähendab kontoris energiat tarbivate süsteemide sisse- ja väljalülitamiseks kuluvat energiat (st küte ja jahutus jne).</p>


</div><div class="h5p-column-ruler"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-93509733-1264-40a8-a25b-183892b8d10d"><p><strong><span style="font-size:1.5em;">Kombineerige kaugtöö, Flex-Office ja kontoriruumi kasutuse vähendamine </span></strong></p>

<p>ADEME (Prantsusmaa) uuring näitab, et kui võtta arvesse kinnisvarakasutuse vähenemist, sest kaugtöö tekitab olukorra, kus koos paindliku kontoriga (tava, et töökohal ei ole spetsiaalset kontorit), paraneb kaugtöö üldine keskkonnatasakaal märkimisväärselt +52% kaugtöö päeva kohta nädalas (st -234 kg eqCO2/aastas iga täiendava kaugtööpäeva kohta nädalas), &nbsp;kindlustades seega oma keskkonnakasu. Paindliku kontori keskkonnakasu võib ületada liikuvuse eeliseid.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-2d3e4c85-3a9c-4d3a-a7e2-d1ea74bf3ce5"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650aea5b69adc.png" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d25f0f5-0bf7-4f85-b059-69b9e42fb240"><p><strong><span style="font-size:1.5em;">Hoiduge osapäeva kodutööst</span></strong></p>

<p>Kaugtöö nii kodus kui ka kolmandal kohal toob kaasa oluliselt negatiivse keskkonnatasakaalu.</p>


</div><div></div><div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-28e9f5f3-2c2d-4dbf-94b1-1e6affc73458"><img src="https://lp.youngkreativ.eu/pluginfile.php/395/mod_hvp/content/97/images/file-650aeaf74c038.jpg" alt="" title="" style="width: 100%; height: 100%;"></div><div class="h5p-column-ruler h5p-column-space-before"></div><div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-cfa3ffa5-6b28-41ef-aa8a-4bc8bde9fc55"><p><strong><span style="font-size:1.5em;">Kaugtöö: ole ettevaatlik!</span></strong></p>

<p>ADEME (Prantsusmaa) uuring näitab, et kaugtöö vähendab kontoritarbimist (paber, tint, tarvikud, tassid, videoprojektorid jne) ja ettevõtte energiatarbimist. Mõned neist vähendamistest kanduvad siiski edasi kaugtöötajale. Eelkõige suureneb energiatarbimine kodus. Uuringu hinnangul suureneks energiatarbimine kodus kaugtööpäeva ajal mehaaniliselt: +20,7 kg eqCO2/aastas ühe kaugtööpäeva kohta nädalas kaugtöötajale.</p>


</div></div>`,
        }
    }
}


// learningObjectives: {
//     heading: "Learning Objectives",
//         html: ``
// },
// stakeHolders: {
//     heading: "Stakeholders",
//         html: ``
// },
// culture: {
//     heading: "Culture",
//         html: ``
// },
// hr: {
//     heading: "HR Policies",
//         html: ``
// },
// tools: {
//     heading: "Tools",
//         html: ``
// },
// inspiringInitiatives: {
//     heading: "Inspiring initiatives",
//         html: ``
// },
// recommendation: {
//     heading: "Recommendation",
//         html: ``,
// }
