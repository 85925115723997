import React, {Component} from 'react';
import '../../components/inner_cont/inner_cont.scss';
import {SimpleAccordion} from "../../components/accordion/accordion";


class Dialog extends Component {

    constructor(props) {
        super(props);

        this.categories = [
            {
                heading: window.getContent('ZeroCarbon'),
                description: window.getContent('ZeroCarbonContent'),
            },
            {
                heading: window.getContent('Compensation'),
                description: window.getContent('CompensationContent'),
            },
            {
                heading: window.getContent('Offsetting'),
                description: window.getContent('OffsettingContent'),
            },
            {
                heading: window.getContent('ScienceBasedTargets'),
                description: window.getContent('ScienceBasedTargetsContent'),
            },
            {
                heading: window.getContent('Greenwashing'),
                description: window.getContent('GreenwashingContent'),
            },
            {
                heading: window.getContent('LifeCycleAssessment'),
                description: window.getContent('LifeCycleAssessmentContent'),
            },
            {
                heading: window.getContent('CarbonFootprint'),
                description: window.getContent('CarbonFootprintContent'),
            },
            {
                heading: window.getContent('EmissionsTradingScheme'),
                description: window.getContent('EmissionsTradingSchemeContent'),
            },
        ]
    }

    render() {
        return (
            <div className={'inner_container'}>
                {this.categories.map((c, index) => <SimpleAccordion index={index} label={c.heading}
                                                                    content={c.description} sx={{minHeight: '70px'}}/>)}
            </div>
        );
    }
}

Dialog.propTypes = {};

export default Dialog;
