import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {PlusArrowCircledIcon} from "../icons/svgIcons";
import {Component} from "react";
import PropTypes from "prop-types";
import './accordion.scss'
import {AccordionActions, IconButton} from "@mui/material";
import {ArrowUpward} from "@mui/icons-material";

export default class AccordionExpandIcon extends Component {
    buildContent = () => {
        if (this.props.content.length) {
            return this.props.content.map(c => {
                return <>
                    {c.heading && <h1>{c.heading}</h1>}
                    <div className={'accordion_description'}
                         dangerouslySetInnerHTML={this.createMarkup(c.description)}/>
                </>
            })
        } else {
            return <div className={'accordion_description'}>{window.getContent('noInformation')}</div>
        }
    }

    createMarkup(markup) {
        return {__html: markup};
    }

    render() {

        return (<SimpleAccordion
            index={this.props.index}
            label={this.props.label}
            description={this.props.description}
            expanded={this.props.expanded}
            onChange={this.props.onChange}
            content={this.buildContent()}
        />);
    }
}

export const SimpleAccordion = (props) => {

    const [expanded, setExpanded] = React.useState(!!props.expanded);

    const handleChange = (event, isExpanded) => {
        props.onChange && props.onChange(isExpanded);
        setExpanded(isExpanded);
        if (isExpanded === true) {
            scrollIntoView();
        }
    };

    const scrollIntoView = () => {
        setTimeout(() => document.getElementById(`panel${props.index}-header`).scrollIntoView({
            behavior: "smooth",
            block: "start"
        }), 500)
    }

    return <div className={'accordion_container'}>
        <Accordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary
                className={'accordion_summary'}
                expandIcon={<ArrowDownwardIcon/>}
                aria-controls={`panel${props.index}-content`}
                id={`panel${props.index}-header`}
            >
                <div>
                    <div className={'accordion_label'}>{props.label}</div>
                    <div className={'accordion_description'}>{props.description}</div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    {props.content}
                </Typography>
            </AccordionDetails>
            <AccordionActions>
                <IconButton onClick={(r) => handleChange(r, false)}>
                    <ArrowUpward/>
                </IconButton>
            </AccordionActions>
        </Accordion>
    </div>
}

Accordion.propTypes = {
    index: PropTypes.any.isRequired,
    label: PropTypes.any,
    description: PropTypes.any,
    content: PropTypes.any,
}
