import {roles} from "../components/quiz/quizHelper";

export default {
    "a1": "Oui",
    "a2": "Non",
    "a3": "Je ne suis pas sûr",
    "a4": "Oui, complètement",
    "a5": "Dans une certaine mesure",
    "a6": "Complètement",
    "a7": "Assez",
    "a8": "Pas vraiment",
    "a9": "Pas du tout",
    "a10": "Dirigeant/dirigeante des RH ou directeur/directrice du département",
    "a11": "Membre de l’équipe RH (spécialiste RH ou partenaire RH)",
    "a12": "Assistant RH",
    "a13": "Consultant RH",
    "a14": "Interne RH",
    "a15": "Micro",
    "a16": "Petite",
    "a17": "Moyenne",
    "a18": "Grande",
    "a19": "Organisation publique",
    "a20": "Organisation privée",
    "a21": "ONG (organisation non-gouvernementale)",
    "a22": "Zone métropolitaine",
    "a23": "Zone urbaine/centre-ville",
    "a24": "Zone périphérique",
    "a25": "Zone rurale",
    "a26": "Je ne suis pas sûr => faites un test (Annexe 4)",
    "a27": "1-5",
    "a28": "6-10",
    "a29": "11-20",
    "a30": "21-50",
    "a31": ">50",
    "q2.2": "Quelle est votre fonction dans les RH ?",
    "q2.3": "Êtes-vous un ou une membre du Comité Exécutif de votre organisation ?",
    "q2.4": "Considérez-vous que vous avez un rôle RH stratégique dans votre organisation ?",
    "q2.5": "Dans votre rôle RH, dans quelle mesure diriez-vous que vous contribuez à la stratégie (organisation stratégique de l’entreprise) ?",
    "q2.6": "Dans votre rôle RH, quelle part de votre travail concerne l’administration du personnel (archivage, contrats, normes) ?",
    "q2.7": "Dans votre rôle RH, quelle part de votre travail concerne le paiement des salaires, l’administration des bénéfices de l’organisation, et autres activités RH de routine ?",
    "q2.8": "Dans quelle mesure votre travail concerne-t-il les opérations RH telles que le recrutement, la formation, la gestion de la performance, etc. ?",
    "q2.9": "Dans votre rôle RH, dans quelle mesure diriez-vous que vous agissez en tant que pont entre l’organisation et ses employés (vous écoutez et soutenez vos employés) ?",
    "q2.10": " Dans votre rôle RH, dans quelle mesure travaillez-vous à la création d’un environnement de travail positif et l’encouragement de l’engagement et de a satisfaction de l’employé ?",
    "q2.11": "Dans votre rôle RH, dans quelle mesure influencez-vous la culture de votre organisation (par exemple en encourageant l’inclusivité, l’équité, et le respect) ?",
    "q2.12": "Dans votre rôle RH, dans quelle mesure votre travail quotidien consiste-t-il à promouvoir des initiatives de changement et à aider les employés et les équipes à naviguer aux travers des transitions ?",
    "q2.13": " Dans votre rôle RH, dans quelle mesure votre travail quotidien consiste-t-il à faciliter et gérer le changement organisationnel, par exemple en pilotant la transformation culturelle ou en restructurant l’organisation ?",
    "q2.14": "Dans quelle mesure votre travail quotidien consiste-t-il à promouvoir un état d’esprit pro-changement chez les employés et managers ?",
    "q1.1": "Quelle est la taille de votre organisation ?",
    "q1.3": "Est-ce une organisation publique, privée, ou une ONG (organisation non-gouvernementale) ?",
    "q1.4": "Où est située votre organisation?",
    "q1.5": "Avez-vous une politique portant sur le développement durable dans votre organisation ?",
    "q2.1": "Combien de personnes travaillent dans les RH dans votre organisation ?",
    "st_3_th1": "NWoW/Hybride",
    "st_3_th2": "Mobilité",
    "st_3_th3": "Informatique durable",
    "st_3_tc0_0": "Parties prenantes",
    "st_3_tc0_1": "Identifier les parties internes ou externes impliquées et leur rôle dans la contribution à la réduction des émissions de l'entreprise",
    "st_3_tc1_0": "Culture",
    "st_3_tc1_1": "Créer une culture durable en collaboration avec les employés (recueillir des idées et des propositions, établir des habitudes durables, mettre en œeuvre des politiques vertes",
    "st_3_tc2_0": "Outils",
    "st_3_tc2_1": "Instruments concrets pour soutenir la réduction des émissions (par exemple logiciels, applications, méthodologie, réglage et évaluation)",
    "st_3_tc3_0": "Processus RH",
    "st_3_tc3_1": "Rôle des processus RH dans la contribution à la réduction des émissions (par exemple, évaluation de la définition d'objectifs durables)",
    "st_3_tc4_0": "Initiatives inspirantes",
    "st_3_tc4_1": "Actions ou pratiques déjà entreprises par des entreprises qui peuvent en inspirer d'autres",
    "st_3_tc5_0": "Recommandations",
    "st_3_tc5_1": "Les conseils aident les RH à atteindre la cohérence dans la définition des objectifs et la mise en œuvre de leur contribution nette zéro.",
    "selectValueOption": "-- Sélectionnez la valeur --",
    "noInformation": "En fonction de votre sélection, il n'y a aucune information dans cette section",
    "hybridStakeHolders": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<p dir="ltr">En charge de la gestion des ressources humaines et de l'organisation du travail, le Directeur/Responsable des Ressources Humaines n'est que partiellement responsable des questions relatives aux nouvelles méthodes de travail.</p>
<p dir="ltr">Afin de contribuer à la réduction des émissions de carbone de l'entreprise, le Directeur/Responsable des Ressources Humaines doit travailler en étroite collaboration avec différentes parties prenantes, dont les principales ont été identifiées par les partenaires du projet comme suit :</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>PDG/Direction générale</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3 dir="ltr">Définir une vision</h3>

<p>Les PDG doivent accepter le rôle de leader qu'ils jouent personnellement pour intégrer les enjeux de durabilité au sein de leur organisation. Le PDG et le comité exécutif doivent définir une vision, établir une ligne éthique et des valeurs claires, et exprimer leurs ambitions en matière de durabilité et de réduction des émissions de carbone. Quels engagements la direction est-elle prête à prendre en matière de durabilité ?</p>

<p>Cela aidera les cadres et les employés à faire le lien entre le développement durable et la mission de l'entreprise, puis avec leur travail quotidien.</p>

<h3>Exemplarité / Joindre le geste à la parole</h3>

<p>Les recherches montrent que l'exemplarité est très importante et exerce une forte influence sur l'acceptation du changement par les employés. Il est essentiel de joindre le geste à la parole et de fournir aux employés des exemples clairs de ce qui est attendu et valorisé. L'exemplarité peut être déclinée à différents niveaux.</p>
</div></div></div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/image-do44gt28.png",
        },

        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Responsable ou équipe de durabilité</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Le responsable ou l'équipe de durabilité est un acteur-clé à impliquer dans les projets NWoW visant à réduire la mission carbone.<br>
Le partage d'expérience est essentiel et l'entreprise pourrait lancer ou participer à un réseau de responsables de la durabilité dans un secteur, un parc industriel ou une zone géographique afin de partager les connaissances et les bonnes pratiques.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Syndicats</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>L’élaboration d’un projet NWoW ou de travail hybride suivant une logique verticale descendante, sans impliquer les syndicats, sans d’assurer du soutien d’ambassadeurs, sans réaliser d’étude terrain, ne fonctionnera pas. Il faut aussi être prêt à revenir sur ses pas en cas d’erreur et accepter de revoir ses projets.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Services généraux / Facilities</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Partage des responsabilités</h3>

<p>Ce n'est pas un hasard si l'on voit aujourd'hui de plus en plus de DRH également en charge des services généraux, dans le but d'intégrer une réflexion sur les taux d'occupation, le dimensionnement et l'optimisation des espaces, l'adaptation des technologies de chauffage, etc.</p>

<h3>Une étroite coopération</h3>

<p>Si les Ressources Humaines et les services généraux restent deux départements distincts, il est alors important d'établir une coopération étroite. Les services généraux peuvent contribuer à réduire la consommation d'énergie par des investissements dans des installations à haut rendement, ou des énergies alternatives peu polluantes comme le sont les panneaux photovoltaïques par exemple. Ils peuvent gérer le chauffage en fonction des personnes présentes sur le site et/ou étudier avec les RH le regroupement des personnes sur certaines zones ou étages du site, et fermer les endroits vides.</p>

<h3>Prospective</h3>

<p>La prospective renvoie à l’étude des besoins immobiliers futurs, tant du côté des salariés que des entreprises : ces dernières peuvent être incitées à regrouper différents sites en un lieu plus central, ce qui implique naturellement un allongement des trajets domicile-travail.</p>

<p>Si l’on y ajoute un potentiel d’exode rural, ces pratiques doivent être analysées pour leur impact sur la qualité de l'air, en prenant en compte les variations de mobilité effective et la mobilité liée aux loisirs. Il est important d'analyser les tendances dans l'utilisation des tiers-lieux (qui pourraient maintenir les déplacements résiduels et permettre une meilleure répartition de l’implantation de biens immobiliers) et dans l’augmentation de la surface des logements privés : en effet, le travail à distance pourrait conduire à l'acquisition de logements plus grands pour disposer d'un lieu dédié au télétravail.</p>
</div></div></div>`
        },
        // {
        //     "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
        //     "heading": "Communication",
        //     "description": "Stratégie de communication\n" +
        //         "Pour réellement changer les comportements liés à l'organisation du travail, il est important que les entreprises mettent en place une stratégie de communication et que l'effort soit déployé de manière régulière. Le message doit être martelé en permanence.\n"
        // },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<p dir="ltr">Pour réellement changer les comportements liés à l'organisation du travail, il est important que les entreprises mettent en place une stratégie de communication et que l'effort soit déployé de manière régulière. Le message doit être martelé en permanence.</p>`
        }
    ],
    "mobilityStakeHolders": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<p>Le directeur/responsable des ressources humaines n'est que partiellement responsable des questions relatives à la mobilité vers et depuis le travail.</p>
<p>Afin de contribuer à la réduction des émissions de carbone de l'entreprise, le Directeur/Responsable des Ressources Humaines doit travailler en étroite collaboration avec différentes parties prenantes, dont les principales ont été identifiées par les partenaires du projet comme :</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content has-scrolled"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>PDG/Direction générale</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Site de l'entreprise</h3>

<p dir="ltr">L'emplacement de l'entreprise est défini comme un lieu ou une structure occupée par une entreprise pour mener ses activités. Lorsque l'on s'intéresse aux raisons qui motivent le choix de l'implantation des entreprises, un critère n'est pas négligeable et apparaît généralement dans le top 5 des critères : le bassin d'emploi dans lequel l'entreprise va se positionner. C'est une considération qui pourrait être soutenue, mais qui se situe plutôt en amont du point de vue des DRH. Lorsque votre organisation a des projets de délocalisation, qu'elle étudie une décision de "rester ou partir", qu'elle envisage de fusionner plusieurs bureaux ou d'ouvrir un bureau satellite, il est important d'analyser les impacts associés et les opportunités de déplacements. pendulaire. Choisissez un emplacement proche d'une station de train/métro et d'un accès à d'autres transports publics.</p>

<h3>Approche positive</h3>

<p dir="ltr">L'un des points essentiels à travailler est de faire comprendre à la direction et à l'entreprise ce qu'elles peuvent gagner en abordant l'expérience professionnelle de la maison le matin à la maison le soir, et en considérant réellement la mobilité comme une partie intégrante de l'activité professionnelle. Une fois que c'est le cas, il est plus facile de comprendre pourquoi l'entreprise doit y consacrer du temps, pourquoi elle doit y consacrer de l'énergie et des ressources ou prendre certaines mesures. Il serait bénéfique de s'éloigner des contraintes administratives liées à l'obligation d'établir un plan de mobilité et d'adopter plutôt une approche positive.</p>

<h3>Opportunités et... contraintes</h3>

<p dir="ltr">Accompagner la démarche d'une mobilité moins carbonée n'est jamais le cœur de métier d'une entreprise - et ne le deviendra pas. La flexibilité en termes de contraintes pouvant être imposées n’est pas si grande. La première étape consiste donc à établir le niveau de contraintes que l'entreprise est prête à imposer.</p>

<h3>Joindre le geste à la parole </h3>

<p dir="ltr">Si des initiatives sont prises en faveur d'une mobilité plus respectueuse du carbone/décarbonnée , il est essentiel qu'elles soient utilisées et promues par le top management/la direction de manière exemplaire. Parmi les plans étudiés dans le cadre de la recherche documentaire, une figure de proue qui se fait le champion du plan sera une clé du succès.</p>
</div></div></div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/image-Yp7OiIAH.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Les finances</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Le montant que les entreprises sont prêtes à consacrer aux questions de mobilité à un niveau stratégique est généralement extrêmement faible par rapport à ce qu'elles peuvent dépenser pour l'optimisation fiscale ou les prestataires de services RH. Pour les activités de sensibilisation, il a été évalué entre huit et dix fois moins. Il est impossible, avec des ambitions aussi faibles, de changer réellement les choses. On constate malheureusement que travailler sur la mobilité est encore perçu comme une contrainte par les entreprises, et non comme une réelle opportunité de modifier leur impact environnemental.</p>

<p dir="ltr">Pourtant, le retour sur investissement des projets de mobilité peut être direct et très important.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Communication</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Stratégie de communication</h3>

<p>Pour réellement changer les comportements liés à la mobilité, il est important que les entreprises mettent en place une stratégie de communication et que l'effort soit déployé de manière régulière. Le message doit être martelé en permanence. La gamification, par exemple, est une excellente opportunité à cet égard, en la rendant légère, avec des prix à gagner. La création d'un logo et/ou d'une image de marque pour le plan de mobilité professionnelle peut contribuer à attirer et à maintenir l'attention.</p>

<ul>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Chez SWIFT en Belgique, un "défi caritatif" a été organisé pour encourager la pratique du vélo pendant les semaines de mobilité : pour chaque kilomètre parcouru, 1 euro était reversé à des associations liées au cyclisme. Ce système a eu beaucoup de succès auprès des employés, car il combinait motivation extrinsèque et intrinsèque.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Au Service public de Wallonie, en Belgique, un "Tour du monde à vélo" a été organisé : les participants étaient invités à inscrire leurs trajets à vélo liés au travail sur un formulaire en ligne dans le cadre d'un défi collectif consistant à parcourir ensemble 40 075 kilomètres en 26 semaines. Les destinations étaient atteintes collectivement, avec des anecdotes, des goodies et un diplôme à la fin. Le projet a utilisé la dynamique des niveaux et des badges, tout en jouant sur l'évasion, l'imprévisibilité et la créativité des participants.</p>
\t</li>
</ul>

<p><em><strong>Source: Unité de mobilité de l'UWE</strong></em></p>

<h3>Se concentrer sur la bonne chose</h3>

<p dir="ltr">La mobilité ne doit pas être abordée directement sous l'angle environnemental. La dimension environnementale sera le résultat positif, l'objectif vers lequel nous devons tendre. Mais ce n'est pas l'outil de communication : les gens ne changent pas pour des raisons environnementales, mais pour des raisons de commodité/convenances, d'efficacité, de communauté...</p>

<p dir="ltr">En bref, pour des raisons liées aux comportements humains. Les employés ne vont pas changer leur comportement parce que cela améliore l'empreinte carbone de l'entreprise, mais parce qu'ils vont avoir de nouvelles options pour améliorer la gestion de leur famille, rendre leurs trajets plus agréables... et, en définitive, avoir un impact positif sur la planète.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Gestionnaire de mobilité</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Désigner un responsable de la mobilité</h3>

<p dir="ltr">Une personne interne qui mène le projet de mobilité et, idéalement, rend compte à la direction générale, car le mandat est transversal : communication, RH, installations, etc. La personne en question personne peut venir de n'importe quel service - et, pourquoi pas, des RH - mais doit être en contact direct avec la direction générale et les représentants du personnel. Cette personne doit ensuite être formée aux questions de mobilité.</p>

<h3 dir="ltr">Un gestionnaire de mobilité ne suffit pas</h3>

<p dir="ltr">La mobilité reste l'expression de la liberté individuelle en dehors de toute contrainte de service. Du point de vue de la liberté individuelle, le rôle normatif de la voiture reste très important par exemple. Il y a donc un gros travail de conviction et de pédagogie à faire. Les DRH peuvent agir sur les déplacements liés au travail (par exemple dans le secteur des transports ou si l'entreprise emploie des salariés mobiles), mais ce sera plus difficile pour les déplacements domicile-travail, qui touchent en partie à la sphère privée. Ils sont alors beaucoup plus dépendants de la bonne volonté des gens ou de ce qu'ils sont prêts à faire, consciemment ou inconsciemment.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Gestionnaire de données</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Pour devenir des acteurs du changement, les entreprises doivent investir les ressources - financières, humaines et temporelles - nécessaires à un examen approfondi de leurs pratiques actuelles en matière de mobilité et de la manière dont les variables individuelles (âge, sexe, poste, trajet domicile-travail, etc.) influent sur leur mode de transport préféré. La collecte d'informations par le biais d'enquêtes, d'entretiens avec les employés, les fournisseurs et les clients et d'autres outils est essentielle pour élaborer une réponse appropriée. Les entreprises disposent déjà d'une énorme quantité de données et, si elles le souhaitent, elles ont souvent en main les éléments nécessaires pour progresser de manière simple et peu coûteuse.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Les syndicats</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Si vous élaborez des plans de mobilité du haut vers le bas, sans impliquer les syndicats, sans vous assurer que vous avez des ambassadeurs, sans tester les choses sur le terrain, cela ne fonctionnera pas. La mobilité est l'un des sujets les plus sensibles dans les entreprises. Et une fois que le mal est fait, il est extrêmement difficile de revenir dans une dynamique positive. Il faut aussi être prêt à revenir en arrière quand on fait une erreur et accepter de revoir ses plans.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Les autorités publiques</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Lors de l'élaboration d'un plan de mobilité neutre en carbone, il est essentiel d'obtenir un engagement clair des territoires et des institutions officielles. La planification locale est cruciale. Et généralement, les politiques sont le principal moteur du changement : 60 % des changements opérés par les entreprises en matière de planification de la mobilité sont motivés par la volonté de s'aligner (ou de se conformer) aux politiques nationales, régionales ou locales.</p>

<p dir="ltr">Le développement du travail à distance sera réglementé par l'employeur, mais il doit également être intégré dans une perspective de politique publique. Si on laisse le travail à distance se développer sans préciser les objectifs recherchés du point de vue de la politique publique, il y a peu de chances de les atteindre.</p>
</div></div></div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Opérateurs de transport public</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Si des responsables mobilité ou RH d'entreprises, même de secteurs différents, travaillent ensemble dans un périmètre de 500 à 800 mètres autour d'un pôle de transport - une gare, une station de métro, etc. - ils pourront lancer une dynamique et, surtout, aller à la rencontre des pouvoirs publics, des opérateurs de transport public et des services de mobilité pour leur dire : «Nous sommes, disons, 30, nous avons identifié tel ou tel besoin, que pouvons-nous faire ? Si une seule entreprise vient, elle n'aura pas d'impact. Avec 30 entreprises, la situation change. Vous avez besoin de cette échelle pour avoir un impact.</p>
</div></div></div>`
        }


    ],
    "sustainableStakeHolders": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<p dir="ltr">Le directeur/gestionnaire des ressources humaines n'est pas directement responsable des systèmes d'information et de télécommunications internes à l'entreprise. Mais il peut sensibiliser le personnel aux questions de durabilité liées aux technologies numériques et influencer leur utilisation.</p>
<p dir="ltr">Pour contribuer à la réduction des émissions carbone de l'entreprise, le directeur/gestionnaire des ressources humaines doit travailler en étroite collaboration avec différentes parties prenantes, dont les principales ont été identifiées par les partenaires du projet comme étant les suivantes :</p>
</div>`,
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div>
<p><strong><span style="font-size:1.5em;">Le PDG/la direction générale</span></strong></p>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Définir une vision</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Les PDG doivent reconnaître le rôle de leader qu'ils jouent personnellement pour intégrer les enjeux de durabilité en profondeur et à grande échelle dans leur organisation.</h3>

<p>Le PDG et le comité exécutif doivent définir une vision, établir une ligne éthique et des valeurs claires, et exprimer des ambitions en matière de durabilité et de réduction des émissions de carbone.</p>

<p>Quels sont les engagements et les orientations de l'entreprise en termes d'utilisation responsable des technologies de l'information et des télécommunications ? S’interroger sur ces questions aidera les cadres et les employés à faire le lien entre la durabilité et la mission de l'entreprise, puis avec leur propre travail quotidien.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>La gouvernance</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><h3>Un autre défi consiste à intégrer les critères du numérique responsable dans la gouvernance globale de l'entreprise, et ce pour chaque projet lié aux technologies de l'information. Cela ne signifie pas que certains projets ne seront pas réalisés, mais plutôt que les bonnes questions seront posées pour améliorer l'approche - par exemple, ne pas développer certaines fonctionnalités peu utilisées qui gaspilleraient des ressources.</h3>

<p>Cet esprit s'applique à l'informatique mais pourrait très bien s'étendre à tous les services de l'entreprise, y compris aux RH : de plus en plus d'entreprises associent désormais un budget carbone à leurs différents projets.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Exemplarité / Joindre le geste à la parole</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>La recherche montre que l'exemplarité est très importante et possède une forte influence sur l'acceptation du changement par les employés. Il est essentiel que les cadres supérieurs joignent le geste à la parole et donnent aux employés des exemples clairs de ce qui est attendu et apprécié. L'exemplarité peut être déclinée à différents niveaux.</p>
</div></div></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/image-ns6btF0w.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-929571f3-be1a-4ea3-9a15-a42c546e1f03"><p><strong><span style="font-size:1.5em;">Directeur des systèmes d'information (DSI) / Directeur des technologies de l'information (DTI)</span></strong></p>

<p>Le DSI/Directeur informatique et son département sont les acteurs chargés de concevoir la stratégie informatique de l'entreprise et de veiller à ce que tous les systèmes nécessaires pour soutenir ses opérations et ses objectifs soient en place.<br>
</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e92dda56-c129-4b24-b8c1-a4b82751c7d8"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-65a10da0221b6.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-46916a4a-0b71-4b36-b198-90c8bdb5325f">

<p>En l'état actuel des connaissances et des pratiques, l'informatique ne peut pas être « durable ». Mais elle peut être « responsable », dans le sens d'une limitation de son impact sur l'environnement. Le numérique responsable ne se limite pas au « Green IT » - réduire l'empreinte environnementale de la technologie numérique - ou à l’« IT for Green » - utiliser la technologie numérique pour réduire l'empreinte environnementale dans d'autres domaines. Il existe deux autres axes : « Human IT » et « IT for Human ». Ces axes s'interrogent sur la manière de réduire les impacts sociaux négatifs des technologies numériques (par exemple, les problèmes d'accessibilité pour les personnes handicapées) et de mettre les technologies de l'information au service des personnes.</p>

<p>Il est donc important de s'assurer que vous travaillez avec les TI sur des comportements responsables. Sinon, vous risquez de faire des recommandations que les services informatiques ne soutiendront pas, parfois simplement parce qu'ils n'en ont même pas connaissance. Il est crucial d'adopter une approche concertée et cohérente.</p>

<p><strong>Exemple:</strong></p>

<p>Un expert explique comment il a travaillé avec une grande multinationale française sur un projet d'informatique verte. Pour lui, l'informatique représentait 2 % de l'empreinte carbone globale de la structure. Mais compte tenu de la taille de l'organisation, cet impact était très important. Le comité exécutif a décidé que chaque département, quelle que soit sa taille et son empreinte carbone, devait trouver des moyens de réduire cette empreinte de moitié. À partir de ce moment, la cohésion de l'entreprise s’est développée par l’effort commun, au sein des équipes et entre elles. Ils ont essayé de comprendre ce qui se passait dans les autres départements, comment s'entraider, etc.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-R5KB7XDY.jpg"
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-26d6c56f-23dd-4489-9f7a-dbb45a9d4ded"><p><strong><span style="font-size:1.5em;">Approvisionnement  </span></strong></p>

<p>Le service informatique est responsable des spécificités techniques et de la fréquence de remplacement du matériel, ainsi que des achats.<br>
<br>
Le principal impact de l'informatique sur l'environnement est avant tout la fabrication des équipements, bien avant les data centers et les réseaux. Le problème central est la vitesse de remplacement : nous avons tendance à remplacer nos équipements beaucoup trop rapidement.</p>

<p>Par exemple, un smartphone en Belgique a une durée de vie de 18 à 23 mois, alors que du point de vue électronique, il peut durer 7 ou 8 ans sans problème, voire plus.</p>

<p>Il est donc important d'avoir une politique cohérente en matière d'utilisation responsable du matériel au sein de l'organisation.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-638dde93-1fe4-4d1b-87dc-7c7cd5340826"><p><strong><span style="font-size:1.5em;">Fournisseurs de matériel informatique et de télécommunications</span></strong></p>

<p>Les fournisseurs de solutions et d'appareils numériques sont des acteurs importants car ils possèdent des informations et des connaissances sur l'efficacité énergétique des produits qu'ils développent et vendent, sur le cycle de vie et sur la maturité de leurs produits, ainsi que sur les possibilités de recyclage.</p>

<p>Outre le personnel chargé des achats, les professionnels des ressources humaines peuvent impliquer les fournisseurs de technologies de l'information en leur posant des questions sur l'origine des produits. Les fournisseurs pourraient également transmettre leur expertise en formant le personnel aux avantages d'une utilisation et d'une consommation durables d'énergie, ainsi qu'à l'élimination correcte des déchets électroniques.</p>

<p>80 à 90 % de l'empreinte écologique de l'appareil est déjà présente lorsqu'il est allumé pour la première fois. L'extraction des minerais et la pollution liée à leur purification pour obtenir les métaux nécessaires à la fabrication des appareils numériques ont un impact majeur. Pour obtenir un gramme d'or, il faut extraire pas moins d'une tonne de roche ! Pour d’autres métaux, le bilan peut être encore plus lourd. On estime qu'il faut plus de 200 kilogrammes de minerai pour fabriquer un smartphone de 120 grammes. Cette production émettra environ 80 kilogrammes d'équivalent CO2 (l'unité de mesure des émissions de gaz à effet de serre).</p>

<p>De plus, la capacité de recyclage des métaux rares est extrêmement faible. Dans un smartphone, il y a environ 60 métaux, dont on ne peut recycler que 17. Le reste est jeté. Le message clé est d'acheter moins d'équipements, d'en prendre soin et de les conserver le plus longtemps possible.</p>

<p>Si vous n'utilisez plus un équipement, ne le mettez pas dans un tiroir : donnez-lui une seconde vie en le donnant à des associations, en le revendant d'occasion, en le reconditionnant, etc. Aujourd'hui, il est devenu possible pour une entreprise d'acheter du matériel reconditionné.</p>

<p>Les DRH ont un rôle à jouer pour sensibiliser aux bonnes pratiques numériques et plus particulièrement expliquer pourquoi il est important de prendre soin de son matériel.</p>


</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-JgxtRsaR.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-ba79971c-3cf7-4525-ad9a-494e0afef16a"><p><strong><span style="font-size:1.5em;">Les régulateurs </span></strong></p>

<p>Les régulateurs sont des acteurs importants car ils développent des exigences liées aux technologies de l'information vertes - telles que les normes d'efficacité énergétique, les réglementations relatives à l'élimination des déchets électroniques et les exigences en matière d'établissement de rapports sur l'empreinte carbone. Ces réglementations deviennent très strictes au sein de l'UE et couvrent un large éventail de comportements. Les organisations seront soumises à des réglementations de plus en plus strictes en faveur de la responsabilité numérique. Le domaine du numérique est appelé à devenir de plus en plus normalisé, comme en témoigne le vote par le Parlement européen, en juillet 2017, d'une résolution en faveur de l'allongement de la durée de vie des produits.</p>

<p>Pour les DRH et leurs équipes, les régulateurs sont une source importante d'informations et de connaissances sur la façon de développer des politiques et des stratégies organisationnelles qui couvrent l'utilisation responsable de l'informatique et la sobriété numérique et comment les intégrer dans la gestion des ressources humaines. Il est donc important pour vous de communiquer et de collaborer avec eux lorsque l'occasion se présente, en les rencontrant lors de formations, d'ateliers, de journées d'information qui sont parfois organisés par les régulateurs.</p>


</div>`
        }


    ],
    "hybridCulture": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d621e8b2-0c32-40ec-a524-408f805b4851"><p><strong><span style="font-size:1.5em;">La culture est la clé</span></strong></p>

<p>Agir en faveur d'une réduction des émissions de carbone passe nécessairement par la variable culturelle. C’est par un travail sur la culture organisationnelle et managériale, ainsi que sur une utilisation plus raisonnée des déplacements et des outils informatiques, que l'on peut obtenir des changements de comportement et de consommation qui rendront le bilan plus positif pour l'environnement.</p>

<p>Vérifiez la cohérence de la politique environnementale de l'entreprise avec sa culture globale. Comment procéder ? En examinant les pratiques, les habitudes et les incitations déjà en place pour vérifier qu'elles ne sont pas incohérentes ou contradictoires avec la stratégie environnementale.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-ec71dbde-552a-4919-89c6-8adb3e59180f"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-659b0e3914c5e.png" alt="La culture est la clé" title="La culture est la clé" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": 'https://nwow4net-zero.eu/images/t1/file-b6WaEZDM.png',
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4db97028-02a0-45df-b3dc-a2400e1f6992">

<p><strong><span style="font-size:1.5em;">Se concentrer sur le bon levier</span></strong></p>

<p>Le travail à distance n'est pas intrinsèquement "meilleur" pour l'environnement. Il existe autant de recherches abondant dans ce sens que d’études démontrant le contraire. L'entreprise, par exemple, ne va pas cesser de chauffer et d'éclairer les espaces de travail lorsque certains employés travaillent ailleurs, que ce soit chez eux ou dans un autre espace.</p>

<p>La consommation d'énergie de l'entreprise peut donc rester la même, mais la consommation d'énergie au domicile des travailleurs sera par conséquent plus élevée. Il y a bien sûr un effet saisonnier, qui peut varier d'un pays à l'autre - même si le chauffage peut être remplacé par la climatisation. Par ailleurs, plusieurs études montrent que les déplacements ne sont pas supprimés et qu'il y a même une tendance des employés en télétravail à se déplacer davantage, par exemple pour faire une petite course, continuer à déposer/aller chercher ses enfants à la crèche ou à l'école, les sorties pour le déjeuner, etc. Il y aurait donc des déplacements supplémentaires, mais des déplacements plus courts. En fin de comptes, on parle d'un éco-bilan "incertain".</p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e93095af-95fc-4a5d-b59d-b465dbb8ebde">

<p><strong><span style="font-size:1.5em;">Sensibiliser</span></strong></p>

<p>La sensibilisation et l'éducation aux comportements sur le lieu de travail basées sur des preuves scientifiques est l'un des principaux leviers à disposition des DRH. Informer les salariés sur le coût environnemental de leurs pratiques présente un intérêt certain. L’impact est même plus grand lorsque cette sensibilisation, information ou formation est portée par une stratégie et mise en lien avec les activités, et/ou avec les ambitions de l'entreprise d'atteindre la neutralité carbone.</p>

<p>Cela peut déclencher une multiplication des initiatives.</p>

<p>Habituellement, le discours sur le travail à distance/hybride est principalement lié au bien-être et à l'organisation du travail, mais moins à la durabilité. Il est important d’informer et de former également les employés sur les enjeux du changement climatique et sur l'impact que leur organisation du travail peut avoir sur les émissions de carbone. La sensibilisation doit être facilitée par une interaction et un dialogue constants.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a2f1c3ca-765a-48bc-9ae8-5bf7b0657d0b"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-659b0e6194c3e.png" alt="Sensibiliser" title="Raising Awareness" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-2brlApB0.png",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-56f5683d-ac8f-457a-aa74-6ceab16d8394">

<p><strong><span style="font-size:1.5em;">Initier une démarche participative</span></strong></p>

<p>Développer une démarche participative impliquant les salariés sensibles ou actifs dans le domaine du développement durable afin d'en faire des ambassadeurs et de susciter l'adhésion des autres salariés par différents moyens :</p>

<ul>
\t<li>collecte d'idées,</li>
\t<li>partage de bonnes pratiques,</li>
\t<li>formulation de propositions,</li>
\t<li>etc.</li>
</ul>

<p>Plusieurs études menées dans des pays occidentaux en 2022 ont montré que la plupart des salariés reconnaissaient la valeur du travail hybride et que la flexibilité était l'un des facteurs les plus importants dans leur décision d'accepter ou non une offre d'emploi. La norme qui émerge progressivement dans les grandes entreprises est de 3 jours au bureau par semaine.</p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d36a5e02-5941-4954-acb4-08a1432622b7">

<p><strong><span style="font-size:1.5em;">Faire attention aux tensions </span></strong></p>

<p>Vous devez identifier les tensions qui apparaîtront lorsqu'il s'agira de poursuivre à la fois des objectifs de performance économique et de performance sociale et environnementale, tant dans les logiques de court terme - qui caractérisent les organisations aujourd'hui - que dans les logiques de plus long terme - dans lesquelles s'ancre la notion de durabilité.</p>


</div>`
        }

    ],
    "mobilityCulture": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8dc96038-5902-4fae-b1bc-6ff31e4bd15f"><p><strong><span style="font-size:1.5em;">Influencer les comportements</span></strong></p>

<p>Les organisations ne peuvent pas influencer le lieu de résidence de leurs employés, car cela constituerait une violation évidente de leur vie privée. Il est difficile de contrebalancer les déplacements domicile-travail, même si des paramètres tels qu'un logement moins cher ou une meilleure qualité de vie personnelle sont pris en compte. Travaillez avec des champions pour sensibiliser vos collègues.</p>

<p><strong>Example:</strong></p>

<p>Wever est une plateforme créée en France qui propose de réinventer une mobilité inclusive et efficace en plaçant l'usager et ses besoins exprimés au cœur de la conception de l'offre. Elle offre à chacun la possibilité d'exprimer ses besoins, de tester de nouvelles solutions et de voter pour mettre en œuvre les plus intéressantes sur le long terme.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-067d5e0e-e4fb-43be-84fa-825384498127"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-6509dd97d9c6f.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9772c0de-3792-4fe2-a5f6-899984bb576a"><p><strong>Comment cela fonctionne-t-il ?</strong></p>

<ol>
\t<li>Un diagnostic de mobilité permet de recueillir les habitudes et les besoins de déplacement des salariés. Il suffit de quelques minutes pour recueillir les besoins de chacun et établir un diagnostic.</li>
\t<li>Un tableau de bord permet de suivre en continu les diagnostics réalisés et de voir les recommandations d'actions commentées. Cet outil visuel permet de co-construire l'offre avec les utilisateurs.</li>
\t<li>L'algorithme détecte les opportunités de mobilité en révélant les solutions existantes et en modélisant de nouvelles solutions acceptables.</li>
\t<li>Avec un accompagnement individualisé du changement et une logique de récompenses de proximité, les transformations sont visibles en quelques semaines.</li>
</ol>

<p>Plus d'informations : <a target="_blank" href="http://www.wever.fr/" target="_blank">www.wever.fr</a></p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-0lBHW18J.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-065d48e1-ed65-4c87-803a-8063f0d2bb9b"><p><strong><span style="font-size:1.5em;">Faire du concret </span></strong></p>

<p>Ne faites pas de campagnes sur la mobilité liée au travail avec des affiches dans l'ascenseur, car vous ne changerez aucun comportement en faisant cela. Allez à la cafétéria trois fois par semaine pour parler aux gens, intéressez-vous à leur mobilité et à leurs préoccupations, proposez des solutions. Bref, personnalisez votre approche pour chaque personne. Cela demande du temps et de l'énergie. Un autre exemple : créez un groupe de cyclistes et organisez des événements autour de la bicyclette, sur le site et en dehors. Demandez à des collègues convaincus de faire une présentation ou de parler d'un sujet.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8cb77e76-e0c3-439c-9f90-d582dd82222c"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650a0615ebf86.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6d757e35-654c-416e-a38f-5fbf7e44a845"><p><strong><span style="font-size:1.5em;">Aborder la question du stationnement des voitures </span></strong></p>

<p>Le stationnement des voitures est une question centrale : les entreprises ont mis au point des mesures allant des incitations (une récompense financière pour ne pas amener sa voiture sur le site, par exemple) aux sanctions (introduction de frais de stationnement sur le site ou d'un nombre maximum de jours par semaine ou par mois pour utiliser le parking).</p>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-jONe6a2U.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42f4fadb-eb58-4f8a-a545-94bbab8675ab"><p><strong><span style="font-size:1.5em;">Initier une démarche participative</span></strong></p>

<p>Créez un pôle Mobilité impliquant des membres du personnel volontaires pour recueillir des idées, des suggestions, et développer des ambassadeurs internes. Certaines organisations ont délégué le rôle de gestion de la mobilité/du plan de voyage aux responsables hiérarchiques, afin de créer une certaine émulation au sein du groupe.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-a57aaa91-50f0-4dce-b73b-23410fda6402"><p><strong><span style="font-size:1.5em;">Promouvoir la réflexion hors des sentiers battus </span></strong></p>

<p>Encouragez les gens à réfléchir à la raison de leur déplacement avant de choisir/réfléchir au moyen/mode de déplacement.</p>


</div>`
        }
    ],
    "sustainableCulture": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-056e0c6d-cc34-469f-8ced-3b7f996472c6"><p dir="ltr"><strong><span style="font-size:1.5em;">Sensibilisation</span></strong></p>

<p>D’après de récentes recherches en la matière, la technologie numérique représente aujourd'hui 3 à 4 % des émissions mondiales de gaz à effet de serre. Bien qu’elle puisse paraître modeste en comparaison à d’autres secteurs, la croissance annuelle de la consommation numérique doit interpeller les entreprises, prévient l'Institut du Numérique Responsable (France/Belgique). Dans les entreprises de services, par exemple, les trois premiers postes d'émissions de carbone sont les déplacements, les bâtiments et l'informatique - dans un ordre qui peut varier d'une organisation à l'autre. L'informatique peut parfois être le premier poste, à l'insu de l'entreprise, lorsque celle-ci n'est pas mesurée ou mal évaluée. La mobilité, par exemple, est très clairement associée dans l'esprit des gens à la question environnementale ; l'informatique l'est beaucoup moins.</p>


</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-192ec88b-a197-4536-95ee-13ea3703a0e2"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b2348b9ee9.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-HLGgm3YH.jpg"
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-59071f32-37c3-4315-89a9-cd5beb84ac23"><p><strong><span style="font-size:1.5em;">Promouvoir la compréhension</span></strong></p>

<p>Il est important de distinguer l'essentiel de l'accessoire. Si l'on veut réduire l'impact environnemental du numérique, sachez que limiter le nombre de mails n'aura qu'un impact marginal - même si c'est une action souvent préconisée. Le principal impact négatif du numérique sur la planète provient des équipements et de leur renouvellement prématuré. Il ne faut pas se tromper de combat.</p>

<p>Il est important de distinguer au plus tôt effets directs, effets indirects et effets rebonds de nos habitudes numériques, et ainsi de valoriser les actions positives qui font réellement la différence.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-4abab26f-e818-4d12-b498-6b40280bffee"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b67a5efee2.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-19JBhPTu.jpeg"
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8fee0210-9975-43b3-b9a3-862916f098b6"><p><strong><span style="font-size:1.5em;">Encourager la sobriété numérique </span></strong></p>

<p>L'informatique responsable ne signifie pas encourager la réduction de tous les usages numériques. De fait, notre société actuelle est presque intégralement numérisée. Chaque pendant de notre vie, peu ou prou, est lié à une activité digitale – il est donc presque impossible de se dégager de ce secteur.</p>

<p>Le vrai défi est donc de supprimer tout ce qui est futile. C’est là-même que les RH peuvent apporter de précieuses réponses.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-80a48b73-1d1c-416d-8cbd-ff20039a441d"><p dir="ltr"><strong><span style="font-size:1.5em;">Promouvoir une utilisation responsable des technologies de l'information et des télécommunications </span></strong></p>

<p>En ce qui concerne l'utilisation des équipements, la vidéo joue incontestablement le rôle le plus négatif. Aux alentours de 80 % de la bande passante d’Internet est utilisée pour la gestion, création et diffusion de contenu vidéo. Seul un quart de ce contenu est utilisé pour les vidéoconférences ou les appels vidéo ; les trois quarts restants sont accaparés par les plateformes de diffusion en continu, telles que Netflix, YouTube, les contenus pour adultes, etc.</p>

<p>Éteindre sa caméra lors d'un appel individuel a peu d’impact. Les conséquences sont toutefois plus sérieuses lors d'une session vidéo de groupe. La crise de la COVID-19 a été un témoignage fort de ce changement. L'utilisation intensive de la vidéo a entraîné une modification radicale de l'allocation de la bande passante et un sérieux renouveau de l'équipement des centres de données. Or, c'est précisément là que réside le véritable impact écologique du travail à distance.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-c9087ca2-6557-4911-9f64-03a6d9a035df"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b6812dd3f5.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-16ec8bc4-3636-4635-b576-83bb425e0be5"><p>Les conséquences de la pandémie ont été doubles : d'une part, toute une série d'entreprises qui n'étaient pas équipées pour permettre le travail à distance ont dû se suréquiper - acheter des ordinateurs portables supplémentaires, des écrans, etc. D'autre part, toutes les personnes qui ne pouvaient pas travailler à distance et qui étaient bloquées chez elles pendant les différentes périodes de confinement ont développé de nouvelles habitudes de visionnage vidéo (un abonnement à Netflix, par exemple). Ces nouvelles habitudes ont un réel impact : en effet, les fournisseurs de réseaux ont dû ajouter du matériel pour augmenter leur capacité, ce qui a des conséquences significatives en termes d'empreinte environnementale.</p>

<p>Il convient de rappeler qu'une utilisation responsable de la technologie est une dimension très marginale, comparée à l'impact du renouvellement extrêmement rapide des équipements et des centres de données.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-re4wePLp.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0cddd6a4-52aa-42a3-8484-5539737f8a1d"><p><span style="font-size:1.5em;"><strong>Double vérification de la cohérence </strong></span></p>

<p>Aujourd'hui, la prise de position générale est en faveur du développement durable. La réalité est cependant plus nuancée : il y a des entreprises qui veulent vraiment changer leur modèle d'entreprise - en étant pleinement conscientes qu'il pourrait s'agir d'une condition sine qua non pour rester sur certains marchés à court ou moyen terme - et d'autres qui se livrent à un greenwashing assez efficace.</p>

<p>Une troisième catégorie d'entreprises envoie des messages contradictoires inquiétants. Elles font de la publicité pour des pratiques durables, mais investissent massivement dans de nouvelles technologies gourmandes en énergie, telles que le métavers. Cela équivaudrait à ce qu'un directeur plaide pour des pratiques de mobilité plus écologiques tout en réservant un jet pour un voyage de courte distance. La dynamique du "net zero" révèle une nature humaine profonde, entre la création de façades et la promotion d'un véritable désir d'agir.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-7192ddeb-d87d-4c96-8d91-5f579d91095c"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b68e420112.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-cd099baa-8a59-455f-aa3c-1529a2f405a5"><p dir="ltr">Le directeur/département des ressources humaines a un rôle à jouer en tant que chef d'orchestre de tous les départements de l'organisation. Prenons l'exemple de la formation : quel est l'intérêt de former à la responsabilité et à la sobriété numérique si, dans le même temps, on propose un grand programme de formation au métavers pour les techniciens ?</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-h1GH98Y5.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5c6fe4df-bd40-485d-9629-7de6aeb00952"><p><span style="font-size:1.5em;"><strong>Promouvoir la pensée créative</strong></span></p>

<p>Encouragez la réflexion sur les moyens de réduire les émissions de gaz à effet de serre par une utilisation responsable des technologies de l'information. </p>


</div>

<div class="h5p-image-hotspot-popup-content has-scrolled"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Défis de désintoxication numérique</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">Organisez des défis de désintoxication numérique pour les employés, en les encourageant à faire une pause technologique pendant une période donnée, par exemple un jour ou une semaine. Il peut s'agir d'un défi individuel ou d'une activité de team building.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Pauses sans écran</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">Vous pouvez encourager les employés à faire des pauses sans écran tout au long de la journée, en leur offrant la possibilité de faire de l'exercice physique, de pratiquer la pleine conscience ou d'autres activités non numériques.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>Politique de réunion sans écran</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Vous pouvez mettre en place une politique qui décourage l'utilisation d'appareils numériques pendant les réunions.</p>
</div></div></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/image-eeGDl5ev.jpg",
        }
    ],
    "hybridTools": [
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-785717ef-b903-49c5-9c06-297f8584f0b2"><p dir="ltr"><span style="font-size:1.5em;"><strong>Mettre en place un groupe de travail ou une équipe d’ambassadeurs sur le sujet de la durabilité.</strong></span></p>

<p dir="ltr">Peu de choses peuvent être accomplies sans l'implication de personnes motivées. Identifiez les potentiels acteurs du changement dans votre organisation, situés si possible à différents échelons hiérarchiques et dans différents départements. Sollicitez-les sur la mise en place d’un groupe de travail ou d’une équipe d’ambassadeurs, en explorant les possibilités et les bénéfices qu’offre le travail hybride en matière de réduction des émissions carbone. L’attitude et la motivation étant déjà présentes, ce rôle, bien que purement volontaire, leur apportera les moyens d’agir en adéquation avec leur motivation intrinsèque au sein de l’organisation.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-658bbecb-b411-40b2-8187-4d368eb13db1"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a076563db5.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-GBdvjo7v.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4efad5a4-1ecd-417f-a6e1-c245bfb5ecbe">

<p><strong><span style="font-size:1.5em;">Réaliser un audit sur les pratiques de consommation au sein de l’entreprise </span></strong></p>

<p>Quelques exemples d'actions ci-dessous (source: <a target="_blank" href="http://www.sustainablebusinesstoolkit.com">www.sustainablebusinesstoolkit.com</a>):</p>

<ol>
\t<li><strong>Éliminez les gobelets à usage unique et remplacez- les par des bouteilles ou des thermos réutilisables : </strong>les gobelets à usage unique sont nuisibles à l'environnement. Les gobelets en plastique et en polystyrène ne sont pas biodégradables et peuvent passer des années dans les décharges ou se retrouver dans les océans et les rivières. Si l'abandon total des gobelets à usage unique est trop difficile, envisagez d'utiliser des gobelets écologiques recyclables à la place.</li>
\t<li><strong>Instaurez une politique BYORB </strong>(Bring Your Own Reusable Bottle).</li>
\t<li><strong>Utilisez du papier d'impression recyclé.</strong> Grâce à la technologie d'aujourd'hui, on ne voit plus la différence entre le papier standard et recyclé.</li>
\t<li><strong>Imprimez en recto-verso :</strong> assurez-vous que votre ordinateur et votre imprimante soient automatiquement configurés pour cela. L'impression recto-verso peut réduire vos déchets de papier de 10 à 40%.</li>
\t<li><strong>Lorsque cela est possible, n'utilisez pas de papier.</strong></li>
\t<li><strong>Servez-vous de stylos recyclés et biodégradables</strong>, plutôt que de jeter des stylos en plastique.</li>
\t<li><strong>Diminuez la luminosité de votre écran d'ordinateur. </strong>Selon un professeur de l'Université de Harvard, passerla luminosité de votre écran d'ordinateur de 100 % à 70 % peut permettre d'économiser jusqu'à 20 % de l'énergie de l'écran. La plupart des gens ne remarqueront pas la différence en faisant ce réglage, c'est donc une idée verte rapide et facile à mettre en place.</li>
\t<li><strong>Éteignez votre écran d'ordinateur</strong> la nuit pour économiser de l'énergie</li>
\t<li><strong>Mettez en place des marches de ramassage des déchets. </strong>Celles-ci permettent aux employés de prendre du recul et d’identifier les sources de gaspillage   au sein de l’entreprise. C'est une façon ludique de faire participer tout le monde et de stimuler des idées pour réduire l'impact de votre entreprise sur l'environnement. Les marches de ramassage des déchets ne nécessitent pas de fonds, de ressources ou de tierces parties supplémentaires. Elles tirent parti de la contribution de chaque collaborateur pour apporter des améliorations au fonctionnement de l’entreprise.</li>
\t<li><strong>Débarrassez-vous des poubelles individuelles </strong>dans les bureaux et remplacez-les par des poubelles centrales. Vous augmenterez ainsi votre taux de recyclage et réduirez les déchets.</li>
\t<li><strong>Achetez du café écologique et d'origine responsable.</strong></li>
\t<li><strong>Optimisez l'utilisation de la lumière naturelle. </strong>Évitez d'allumer les ampoules pendant la journée en gardant les rideaux, les stores et les volets ouverts.</li>
\t<li><strong>Investissez dans un éclairage à basse consommation d’énergie,</strong> qui permet d’économiser de l'électricité et de l'argent. Il existe des lampes à haut rendement énergétique de toutes formes et de toutes tailles. Il y en a pour tous les besoins et tous les budgets !</li>
\t<li><strong>Investissez dans des prises intelligentes. </strong>Ces prises permettent d’éteindre automatiquement les lumières, les ventilateurs et les appareils. La plupart des prises intelligentes vous permettent de contrôler vos appareils de n'importe où grâce à une application sur votre téléphone.</li>
\t<li><strong>Réglez votre thermostat de 2 degrés vers le haut en été et de 2 degrés vers le bas en l'hiver. </strong>Vous pouvez aussi utiliser un thermostat économe en énergie qui conserve lui-même l'énergie.</li>
\t<li><strong>Encouragez les économies d'eau avec des conseils et des instructions dans les salles de bains. </strong>Essayez d'utiliser des designs créatifs pour que le message reste positif</li>
\t<li><strong>Choisissez la réparation plutôt que le remplacement.</strong></li>
\t<li><strong>Organisez des concours écologiques avec votre équipe.</strong></li>
</ol>



<p><strong>Conseil : </strong>La meilleure façon d'atteindre vos objectifs environnementaux est de procéder par petits pas plutôt que par des changements radicaux. Plutôt que d'intégrer de grands changements qui risquent d'accabler une partie de votre personnel, concentrez-vous sur une petite transformation dans chaque secteur de votre lieu de travail.</p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-44756050-178f-436e-893f-651f0a73f718"><p><span style="font-size:1.5em;"><strong>Réaliser le bilan carbone de la politique de télétravail de l’organisation</strong></span></p>

<p>Une enquête menée par Reuters auprès de vingt grandes entreprises ayant une politique de télétravail a révélé que seule la moitié d'entre elles avaient tenté d'estimer les émissions associées au travail à distance, et qu'un nombre encore plus faible avait tenté d'atténuer ou de compenser cet impact. Même une entreprise comme Microsoft, qui a adopté un programme de développement durable très avancé et a commencé à estimer les effets du télétravail dès 2020, est encore en expérimentation pour tenter de maîtriser l'impact carbone de ses travailleurs hors site.</p>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-kVIDIWNk.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-96673788-aac0-4bb1-a147-1f060a8bad55">

<p><span style="font-size:1.5em;"><strong>Indicateurs de consommation d'énergie</strong></span></p>

<p>Il existe des outils de mesure, qui   se heurtent toutefois à plusieurs difficultés. La première est que la mesure est basée sur ce que consomme un seul site. Or, les entreprises ont souvent plusieurs sites dans des zones géographiques très   variées, présentant   des caractéristiques très différentes. La seconde est la nécessité de prendre en compte tout un écosystème, y compris les travailleurs et les fournisseurs. Les intégrer au calcul, et avoir un impact sur eux est beaucoup plus complexe.</p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-83be948d-110c-462e-b1f0-1e0ad0c27c72"><p><span style="font-size:1.5em;"><strong>Planifier le travail hybride </strong></span></p>

<p><strong>Il est nécessaire d’adopter une politique qui tienne compte des impacts globaux sur les déplacements et l'environnement (par exemple, éviter le travail à distance les lundis et vendredis, pourtant mis en œuvre par la plupart des entreprises).</strong></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-a0e4f2b1-4e13-4f99-b95f-0ef8a62df29a"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a0d17a1b25.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-23380b42-0403-427c-ad5c-a2eb83219cfa"><p>Le modèle proposé par David Autissier peut être intéressant à cet égard. Il invite à s'interroger sur les modes de travail à envisager en termes de travail en présentiel et à distance à travers les notions de "Build" et de "Run" empruntées aux méthodes agiles. Le travail à distance fonctionne bien pour le Run (c'est-à-dire dans les activités routinières) mais est moins adapté pour le Build (soient toutes les activités qui impliquent un investissement dans le long terme, comme les nouveaux projets par exemple). En d'autres termes, il est possible de   gérer à distance les activités courantes, mais moins celles qui nécessitent des interactions, des échanges, de la confiance et de l'intelligence collective. Dans ce cas, le face-à-face sera probablement préférable.</p>

<p>Sur cette base, le DRH peut inciter les managers à réfléchir avec leurs équipes sur les moments où il est important que tout le monde soit là, ensemble, et dans quel but ; et sur les moments où l’on peut mener certaines activités en ligne. Par exemple, une réunion de suivi opérationnel d'un projet sera plus efficace si elle est menée en ligne, alors qu’il est préférable d’accueillir un nouveau collaborateur en personne. Un critère de mesure d'impact carbone peut ensuite être ajouté à cette réflexion. Cela peut conduire à regrouper des tâches, et à créer un pôle de travail à distance et un pôle de travail en présentiel, en tenant compte de tous les paramètres : le nombre de personnes concernées, les distances qu'elles auront à parcourir, les modes de transport utilisés, le taux d'occupation des bureaux, l'impact en termes d'utilisation du numérique, etc.</p>

<p>L'idée n'est pas d'avoir une approche mathématique, mais plutôt opportuniste : si une réunion de groupe peut avoir un meilleur résultat que ce qui serait obtenu à distance, malgré un coût environnemental plus élevé, il est probablement toujours pertinent de la mener en présentiel. Ce sera une question de choix. L'intérêt sera de pouvoir se donner les moyens de décider en prenant en compte en permanence les différents paramètres de l'organisation du travail.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-9MJjwGNw.jpg",
        },
        // {
        //     "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
        //     "heading": "Апликации кои овозможуваат оптимизација на канцеларискиот простор поврзан со работата на далечина/хибридната работа  ",
        //     "description": "Различни апликации помагаат да се минимизира напорот на уредувањето на работниот простор но и подобро да се менџира  со опкружувањето  во канцеларијата  како и на присуството на вработените во канцеларијата"
        // },
        // {
        //     "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
        //     "heading": "Организирајте редовни работилници / ручеци и учете преку  страната Sustainability@theoffice",
        //     "description": "Работилницата за одржливост има за цел да направи лесно и достапно сите да научат пошироко во контекст на тоа како да се спроведи  одржливоста во канцеларијата и да се овозможи да развијат стратегии кои ќе поттикнат размислувања за одржливост во рамките на нивната секојдневна работа"
        // },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9e69f91d-beba-4166-86c4-9e3c563b3d40">

<p><span style="font-size:1.5em;"><strong>La fresque du climat </strong></span></p>

<p>En seulement 3 heures, la fresque du climat enseigne les fondamentaux du changement climatique et donne des moyens d'agir. En s’appuyant sur l'intelligence collective, la fresque permet aux participants de s'approprier le sujet. La méthodologie utilisée permet à chacun de participer et de trouver sa place dans l'exercice. En reliant les causes et les effets du changement climatique, les participants sont capables de prendre du recul et de comprendre la nature systémique des défis auxquels ils font face. </p>

<p>Plus d'informations :<a target="_blank" href="https://climatefresk.org/" target="_blank">https://climatefresk.org/</a></p>


</div>`
        }
    ],
    "mobilityTools": [
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4fba2945-7e82-4e52-8e74-afa0bdfe1f8b"><p><strong><span style="font-size:1.5em;">Effectuer une analyse de mobilité</span></strong></p>

<p>Réalisez un bilan carbone de votre entreprise : dédiez un expert pour identifier et cibler les sujets les plus impactants (mobilité, voyage en avion, bâtiment, etc.). Effectuez ensuite une analyse de mobilité basée sur des données précises et à jour.</p>

<p><strong>Exemples </strong>:</p>

<p>Mobility Label , des outils pour un trajet plus intelligent</p>

<p><a target="_blank" href="https://mobilitylabel.com/">https://mobilitylabel.com</a></p>

<p>Mob Box agrège et compile des données brutes en masse. Des données internes de la flotte d'entreprise aux données externes open source, il transforme les chiffres en cartes simples et en graphiques intuitifs. Il vous aidera à avoir une vision claire de la mobilité de vos employés. Évaluer le mix modal global et analyser les émissions de CO2 et les coûts associés.</p>

<p><a target="_blank" href="http://www.mob-box.eu/" target="_blank">www.mob-box.eu</a></p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-08185ad9-d286-407c-8269-b3bcecba9cc2"><p><strong><span style="font-size:1.5em;">Explorer les solutions publiques disponibles</span></strong></p>

<ul dir="ltr">
\t<li><strong>En France :</strong> Le <a target="_blank" href="https://www.ecologie.gouv.fr/sites/default/files/mobilite%20durable%20en%20entreprise.pdf" target="_blank">Forfait Mobilité Durable</a> - chaque entreprise peut subventionner ou financer les trajets domicile-travail de ses salariés jusqu'à 800 euros par an, exonérés d'impôts et de cotisations sociales, à condition qu'ils soient utilisés dans des moyens de transport "verts" - transports en commun, trottinettes électriques, voitures électriques partagées, covoiturage…</li>
\t<li><strong>En Belgique :</strong> Le Budget Mobilité permet aux entreprises d'offrir aux salariés disposant d'une voiture de société un budget pour des mobilités plus respectueuses de l'environnement — <a target="_blank" href="https://lebudgetmobilite.be/fr">https://lebudgetmobilite.be/fr</a></li>
</ul>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/image-V0D782D4.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-eb98b054-5f2c-454c-9a29-05efff3cce27"><p><strong><span style="font-size:1.5em;">Diagnostics individuels / Enquêtes Mobilité</span></strong></p>

<p dir="ltr">Observez les déplacements des personnes vers leur lieu de travail et proposez-leur des alternatives, au travers d'actions marketing individualisées. Il y a tout un processus de diagnostic et d'accompagnement des personnes pour leur montrer que la voiture n'est pas forcément le moyen le plus efficace pour se déplacer. Une analyse plus approfondie (en suivant les personnes pendant plusieurs jours) peut leur montrer qu'il existe d'autres façons de faire, moins motorisées, plus économiques, etc.</p>
</div>`

        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d7ebb14-1b14-4a0b-a034-151adcc5f3bb"><p><span style="font-size:1.5em;"><strong>Plateformes d'itinéraires multimodaux</strong></span></p>

<p>Les plateformes d'itinéraires multimodaux peuvent montrer les différentes options et permettre aux gens de se rendre compte que se déplacer en voiture sera moins efficace qu'avec d'autres modes de transport. Certains de ces outils affichent les émissions de CO2 associées aux différents modes de déplacement.</p>

<p><strong>Exemple :</strong></p>

<p>Skipr , la solution tout-en-un de gestion de la mobilité en entreprise</p>

<p><a target="_blank" href="http://www.skipr.co/">www.skipr.co</a></p>

<p>Skipr lutte contre l'usage unique de la voiture en ville. Elle met à disposition des salariés une application qui leur permet de voir comment se rendre de A à B en combinant les différents moyens de transport qui existent autour d'eux : transports en commun, véhicules partagés, trottinettes électriques, véhicules verts pour se rendre à la gare, etc. est un copilote qui leur assurera la possibilité de ne pas prendre leur voiture. Il permet d'acheter des titres de transports en commun sur le compte de l'entreprise, associés à une carte de paiement, liés au même « budget mobilité », pour des mobilités alternatives. Pour l'entreprise, il fournit une plateforme de gestion qui fera deux choses : mettre en place les lignes directrices de l'entreprise (déterminer qui a droit à quoi) et fournir un outil de reporting qui donne une visibilité complète sur les économies générées et la réduction des émissions de carbone par rapport à la utilisation d'une voiture traditionnelle. Les employés ont un tableau de bord avec leur propre situation, mais l'entreprise n'a que des rapports agrégés. Lors de la recherche d'options de déplacement, les collaborateurs reçoivent trois informations : l'heure d'arrivée, le coût en euros et les émissions de CO2.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4bf3cd48-c7e8-4a5f-a5a3-4727c4478547"><p><strong><span style="font-size:1.5em;">Encourager le covoiturage</span></strong></p>

<p>Conditions de réussite : la flexibilité des horaires de travail est l'une des premières raisons de ne pas faire de covoiturage entre collègues. Ne pas avoir les mêmes horaires de travail est un facteur limitant, surtout quand s'y ajoutent des contraintes personnelles - aller chercher les enfants, faire des courses, etc. -. Avoir un chef de projet, qui avec un tableau et un crayon, enregistre les trajets et les associe, peut aider. Si vous laissez les gens s'arranger entre eux, c'est plus compliqué. Si vous le laissez à une application, c'est compliqué aussi. Les outils numériques fonctionnent très bien pour les longues distances, mais ils sont moins efficaces pour les courtes distances, les usages récurrents et quotidiens.</p>

<p>Cependant, il existe des plateformes pour faciliter le covoiturage avec des options de correspondance, des informations sur les avantages fiscaux et des possibilités de suivi pour orienter la communication. Il est important de noter que le covoiturage ne fonctionne pas bien lorsque le chantier est à proximité d'une bonne offre de transport en commun.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e97d6b7b-85ca-4b8e-adc4-c549a30315f2"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650ae2ffdc5cc.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-tomPlVwo.jpeg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f2705860-d508-4b16-9089-9bba70468e72"><p><span style="font-size:1.5em;"><strong>Navette</strong></span></p>

<p>Plusieurs organisations ayant la plus forte croissance de l'utilisation des transports publics avaient mis en place une navette d'entreprise gratuite et dédiée reliée aux villes locales et/ou aux gares ferroviaires et routières locales. Un partenariat comprenait des organisations qui ont augmenté le nombre de services d'autobus arrivant sur le site d'une moyenne impressionnante d'environ 14 autobus supplémentaires à l'heure de pointe dans une étude. Disposer de services appropriés et adaptés est apparu plus important que des chiffres absolus : tarifs moins chers, billets négociés, amélioration de l'infrastructure/de la qualité des services hors site et financement de nouveaux arrêts de bus sur le site ou à proximité, par exemple.</p>
</div>`

        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c293a877-1d7a-44a0-8b05-524e78f4b318"><p><span style="font-size:1.5em;"><strong>Cyclisme</strong></span></p>

<p>Les facteurs les plus étroitement corrélés avec des niveaux élevés de cyclisme semblaient avoir un accès hors site de haute qualité ou améliorer l'accès hors site ; augmenter le stationnement disponible pour les cyclistes; et offrant un service de réparation de vélos. Il était également à noter que la plupart des organisations de l'étude avaient fourni des douches, des vestiaires, des sèche-linge et/ou des casiers ; des remises négociées sur le matériel de vélo.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-457e418e-7a81-4932-82ba-2fcbf1e21fcd"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650a17655699c.jpg" alt="Cycling" title="Cycling" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-rCJUADxS.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-801a1d35-3531-4738-807c-79f59dd9e601"><p><strong><span style="font-size:1.5em;">Voitures électriques ? Oui mais…</span></strong></p>

<p>La voiture électrique n'est pas une solution aux embouteillages. Une voiture reste une voiture, qu'elle soit électrique, hydrogène, essence ou diesel, elle prend de la place sur la route. Elle n'émet pas « zéro CO2 », elle en émet moins : tout dépend de la façon dont l'électricité a été produite. C'est toute la difficulté des problèmes de mobilité : il n'y a pas de taille unique. Chacun aura sa spécificité. Avec les voitures électriques, il y a aussi des problèmes liés au coût et à la recharge à prendre en compte. Il y a encore des obstacles. Les choses évoluent rapidement, mais il n'y a pas de solution miracle.</p>

<p>Installer des infrastructures de recharge pour les voitures électriques : elles sont nécessaires pour augmenter l'utilisation des voitures électriques.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-07fc7d73-8673-4f28-8e07-2c87d26235f0"><p><strong><span style="font-size:1.5em;">Location de vélos (avec vélos électriques)</span></strong></p>

<p dir="ltr"><strong>Exemple: </strong></p>

<p dir="ltr">Louer un vélo (Belgique)</p>

<p dir="ltr">En tant qu'employeur en Belgique, vous pouvez choisir de proposer le leasing de vélos de différentes manières : gratuitement ou avec une participation (partielle) au vélo. Dans tous les scénarios, les salariés bénéficient d'un avantage fiscal d'environ 40 %.</p>

<p>«Vous ne payez aucun avantage en nature sur un vélo loué, car vous utilisez régulièrement le vélo pour vos déplacements. Vous pouvez également verser une allocation vélo. Cela motive vos employés à faire du vélo. La réglementation sur la location de vélos ne fait aucune distinction entre un vélo loué et un vélo privé. Résultats? Salariés plus en forme : 1 à 2 jours d'absentéisme en moins et une contribution puissante à un plan de mobilité verte.</p>
</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6c224ae6-6652-45b9-a674-13597a5bb424"><p><strong><span style="font-size:1.5em;">Formation à l'éco-conduite</span></strong></p>



<p dir="ltr"><strong>Exemple : </strong></p>

<p dir="ltr">Être éco-responsable sur la route demande plus qu'un véhicule respectueux de l'environnement. Vous devez avoir un style de conduite écologique. Et c'est ce que vos conducteurs apprennent dans le programme de formation E-positive, une formation d'un an par ALD Automotive.</p>

<p dir="ltr"><strong>Comment ça fonctionne:</strong></p>

<p dir="ltr">« Nous commençons par ce que vos chauffeurs savent faire ; cela met leurs styles de conduite actuels et leur utilisation du véhicule sur les cartes. Après cela, ils suivent un cours d'apprentissage en ligne et reçoivent une formation - individuellement et en groupe.</p>

<p dir="ltr">L'instructeur leur donne constamment des conseils personnalisés et des points auxquels ils doivent prêter attention. Le résultat? A l'issue de la formation, chacun conduit avec une attitude e-positive. »</p>

<p dir="ltr">10 conseils pour une conduite écologique</p>

<ol>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Passez à une vitesse supérieure au moment opportun. Avec un moteur diesel, à partir de 2 000 tours et avec un moteur à essence, 2 500 tours.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Il est temps de bouger ? Poussez votre accélérateur à fond, car accélérer lentement ne vous fera certainement pas économiser de carburant.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Gardez une distance suffisante avec le véhicule qui vous précède. Ainsi, vous n'aurez pas besoin de freiner et d'accélérer constamment.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Vous avez besoin de ralentir ou de vous arrêter ? Ne freinez pas directement, mais relâchez l'accélérateur à temps. Vous ralentirez avec le frein moteur et sans utiliser votre embrayage.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Activez le régulateur de vitesse pour plus de cohérence - et donc de respect de l'environnement.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Serez-vous à l'arrêt complet pendant plus de 20 secondes ? Coupez ensuite votre moteur.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Planifiez votre itinéraire à l'avance afin de ne pas cliquer sur un kilométrage inutile.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Vous n'avez pas besoin de coffre de toit, de porte-vélos ou de porte-bagages ? Enlevez-le et réduisez votre résistance à l'air.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Vérifiez régulièrement la pression de vos pneus.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Faites entretenir votre véhicule dans les délais : ce n'est qu'alors que vous pourrez conduire de manière économique et sûre.</p>
\t</li>
</ol>

<p dir="ltr"><em><strong>Source : ALD Automobile</strong></em></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-0c1c9678-0a6c-445a-95ce-4a25e6395d09"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650a17e559c50.jpg" alt="Maestro mobile" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-mbYWXvUd.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3e08a77a-372e-478d-a71b-1e82dbff5942"><p><strong><span style="font-size:1.5em;">Maestro Mobile </span></strong></p>

<p>MaestroMobile est un serious game développé par Espaces-Mobilités en Belgique, dans le domaine de la mobilité qui vise à faire évoluer les comportements par l'expérimentation.</p>

<p>Plus d'informations :<a target="_blank" href="https://www.maestromobile.eu/">https://www.maestromobile.eu</a></p>

<ul>
\t<li><strong>Le Mobility Van</strong> est composé d'experts de la mobilité qui se déplacent dans les espaces publics et les entreprises et présentent aux salariés toutes les solutions de mobilité de manière pratique (test d'engins de micro mobilité, vélos, applications…)</li>
\t<li><strong>Le City Game</strong> est un serious game urbain qui s'organise sous la forme d'un teambuilding et permet aux participants de tester de manière pratique différentes solutions de mobilité tout en (re)découvrant la ville, en relevant des défis, en répondant à des quizz, etc. En utilisant les codes de des jeux populaires comme Pokemon Go ou les principes des courses urbaines, le City Game a créé son propre univers lié à la découverte des services de mobilité.</li>
\t<li>A l'instar des programmes de remise en forme, <strong>le Passeport Mobilité</strong> propose un challenge mobilité qui accompagne les participants à travers les différentes étapes de l'apprentissage de la multi mobilité, de la découverte d'un service à son utilisation, sur une  durée d'environ un mois. Grâce à une application mobile, le participant obtient des validations de ses compétences et se voit attribuer des attestations matérialisées par un tampon apposé sur son passeport mobilité.</li>
\t<li><strong>GO Mobility</strong> est une plateforme hybride qui propose un programme complet de coaching et de découverte des alternatives de mobilité.</li>
\t<li><strong>Critical MaaS</strong> est un coaching personnalisé pour expérimenter de nouvelles mobilités et changer sa façon de se déplacer. Accompagné d'experts, vous testez tous les nouveaux services et devenez maître de votre mobilité.</li>
</ul>


</div>`
        }
    ],
    "sustainableTools": [
        {
            "roles": [roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-de5d235d-00c3-4b16-93a5-164773aa7ff5"><p dir="ltr"><strong><span style="font-size:1.5em;">Signer la charte " numérique responsable </span></strong></p>

<p>En signant cette charte, les organisations affirment leurs engagements à entrer résolument dans une démarche de " Numérique Responsable ".</p>

<p>Lien: <a target="_blank" href="https://isit-be.org/wp-content/uploads/2021/03/Sustainable-IT-Charter-ISIT-BE.pdf">https://isit-be.org/wp-content/uploads/2021/03/Sustainable-IT-Charter-ISIT-BE.pdf</a></p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1fb3c548-59b6-43b1-a623-fb6df78af94b"><p><strong><span style="font-size:1.5em;">Mesure  </span></strong></p>

<p dir="ltr">Afin de mettre en lumière l'impact de notre surconsommation numérique, l'Institut du Numérique Responsable a créé un calculateur simple qui permet à chacun d'évaluer son impact numérique professionnel.</p>

<p dir="ltr">Lien ici : <a target="_blank" href="https://myimpact.isit-europe.org/">https://myimpact.isit-europe.org/</a></p>

<p dir="ltr">Un autre outil de mesure, appelé WeNR, évalue l'empreinte du Système d'Information des organisations pour leur permettre d'identifier où se situe l'impact et d'identifier leur niveau de maturité en matière de Responsabilité Numérique, à la fois qualitatif et quantitatif. Cet outil prend la forme d'un questionnaire en ligne, gratuit et accessible à toutes les organisations, grandes ou petites. Réalisé en partenariat avec l'Université de La Rochelle et l'EIGSI, WeNR s'appuie sur les travaux d'une thèse financée par La Rochelle Agglomération et sur une collaboration scientifique entre l'INR France, l'INR Suisse, l'ISIT belge et l'Université de Louvain (UCLouvain).</p>

<p dir="ltr">WeNR se décline en 3 versions pour répondre aux besoins du plus grand nombre d'organisations : de l'audit qualitatif de maturité à l'audit comparatif par secteur d'activité, quantitatif détaillé et actionnable.</p>


</div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>WeNR Light</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">Ce questionnaire, disponible en ligne, permet d'évaluer le niveau de maturité des organisations en matière de durabilité numérique (People-Planet-Profit). (fin septembre 2022)</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>WeNR Standard</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr" role="presentation">WeNR prend la forme d'un ensemble de questionnaires quantitatifs et qualitatifs à remplir par chaque organisation participante, à l'aide d'un modèle de fichier spécifique fourni par l'ISIT. Ce questionnaire est accessible en ligne jusqu'au 9 septembre 2022, les données sont ensuite traitées de manière confidentielle et asynchrone, les résultats étant soumis 2 à 3 mois plus tard sous la forme d'un rapport de premier niveau.</p>
</div></div></div>

<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h3>WeNR Plus</h3></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Cette version de l'outil est réservée aux organisations membres de l'ISIT. WeNR + utilise le modèle WeNR standard ainsi que son calculateur. Il fournit des rapports complets et détaillés d'un point de vue quantitatif, qualitatif et comparatif avec les organisations du même secteur.</p>
</div></div></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-e98ca209-cede-40c3-a2b8-680db2729109"><p dir="ltr" role="presentation">​​​​​​</p>

<p dir="ltr">Les outils d'analyse fournis permettent d'identifier des pistes d'action pour établir une stratégie informatique durable.</p>

<p dir="ltr">Pour accéder à ces outils :: <a target="_blank" href="https://wenr.isit-europe.org/">https://wenr.isit-europe.org/ </a></p>

<p>Evaluez votre empreinte environnementale numérique individuelle/professionnelle en kg CO2e.</p>

<p>Pour mettre en lumière les impacts liés à notre surconsommation de technologies numériques, l'ISIT a créé un calculateur qui permet de calculer simplement son impact numérique professionnel. Cette évaluation porte sur :</p>

<ul>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">L'équipement</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Les habitudes en ligne </p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Le stockage dans le cloud</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">L'envoi de mails </p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Les voyages d'affaires </p>
\t</li>
</ul>

<p dir="ltr">Et montre votre impact par rapport à</p>

<ul>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">L'empreinte carbone moyenne d'un Français</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p dir="ltr" role="presentation">Le quota à respecter en 2050 pour se conformer à l'Accord de Paris.</p>
\t</li>
</ul>

<p dir="ltr">Pour accéder au calculateur: <a target="_blank" href="https://myimpact.isit-europe.org/">https://myimpact.isit-europe.org/</a></p>

<p dir="ltr"></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-5a88bc86-804b-4334-a3d1-e2a3f3b4ff81"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b78923fbf8.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-75c025a2-65b8-4dba-99fa-ada96ab7a13c"><p>fruggr est une application développée pour mesurer l'impact environnemental et social des applications informatiques des entreprises. Une prise de mesure régulière est la clé d’une amélioration continue. Aussi, fruggr collecte automatiquement différentes données brutes : utilisation des données, code source, analytics. Le logiciel s'appuie sur des facteurs d'impact reconnus (modèle One byte du Shift Project, base carbone de l'Ademe, AIE, AEE...) pour calculer les scores les plus précis possibles. fruggr propose un bilan carbone couvrant les 3 scopes. L'outil propose également une grille de notation des certificats.</p>

<p dir="ltr">Link: <a target="_blank" href="http://www.fruggr.io">www.fruggr.io</a></p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/image-MJ9YeML0.png",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-1f715907-f760-4fe6-a7a5-f73d1d0a9432"><p><span style="font-size:1.5em;"><strong>Une feuille de route stratégique </strong></span></p>

<p>La priorité doit être la définition d'une feuille de route stratégique pour un numérique responsable, basée sur des mesures concrètes et efficaces, avec la définition d'indicateurs, la mise en place d'un programme de développement des compétences, la signature d'une charte ainsi que la participation à des événements mondiaux comme le Digital Clean-up Day (généralement en mars), journée mondiale de sensibilisation à l'empreinte environnementale du numérique.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-ba27207c-8a81-48a3-bf0e-b3e4e01270bc"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b790538667.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-91a70d9f-e554-44da-be68-4187d51895b9"><p>Comme dans beaucoup d'autres domaines, le principal problème est l'inaction et le manque de transparence. La feuille de route doit également être réaliste, rythmée et durable si l'on veut qu'elle soit couronnée de succès. Lancer l'idée qu'une organisation donnée peut être neutre en carbone en six mois est une allégation dangereuse, par exemple. C’est un postulat impossible à réaliser, illusoire et utopique, qui discréditerait en fin de compte le projet lui-même.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-G9NDge8J.png",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-29debb56-e454-4201-9ad4-89a80df5fe27"><p><span style="font-size:1.5em;">MOOC sur l'informatique durable</span></p>

<ul>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">L'ISIT et ses partenaires proposent un MOOC sur l'informatique durable pour permettre à chacun de s'informer et de se former. Le MOOC Sensibilisation à l'informatique durable est une formation courte permettant une première approche de l'informatique durable pour tous. Il est composé de 4h30 de vidéo, de texte et de contenu interactif, qui offrent des connaissances théoriques pour maîtriser les fondamentaux de l'approche de l'informatique durable. Ces deux modules sont en accès libre: <a target="_blank" href="http://www.isit-academy.org">www.isit-academy.org</a></p>
\t</li>
</ul>

<ul>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Le MOOC Impacts environnementaux des technologies numériques a pour objectif d'aborder l'impact du numérique sur l'environnement, ses effets positifs et négatifs, les phénomènes observables aujourd'hui et les projections que l'on peut faire pour l'avenir. Il s'adresse aux médiateurs éducatifs et plus généralement au public. Leur page web propose pour chaque partie une présentation du contenu, une vidéo ludique et pédagogique, des capsules pédagogiques prêtes à l'emploi, des fiches conceptuelles pour approfondir certaines notions et une bibliographie avec des ressources complémentaires. Lien:<a target="_blank" href="https://learninglab.gitlabpages.inria.fr/mooc-impacts-num/mooc-impacts-num-ressources/en/index.html">https://learninglab.gitlabpages.inria.fr/mooc-impacts-num/mooc-impacts-num-ressources/en/index.html</a></p>
\t</li>
</ul>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4a03a0c2-6e3d-4bc5-9ca9-83c9b0f413ac"><p><strong><span style="font-size:1.5em;">Guide des bonnes pratiques du numérique responsable pour les organisations</span></strong></p>

<p>L'objectif de ce guide est de sensibiliser aux impacts de certaines pratiques rencontrées au sein des organisations et de partager des exemples de pratiques numériques responsables qui peuvent contribuer à réduire l'empreinte environnementale du numérique. Ce guide s'adresse à tous les types d'organisations, dans les secteurs public et privé. Certains exemples de bonnes pratiques sont plus adaptés que d'autres en fonction du contexte et de la maturité de la démarche numérique responsable au sein des organisations. Le numérique plus responsable est un sujet vaste, qui ne concerne pas uniquement la direction du numérique. De nombreux services transversaux tels que les achats, les ressources humaines, la communication, les services généraux, etc. sont également concernés. Ce guide s'adresse donc à un large public en relation avec les systèmes d'information tels que les directions informatiques, les administrateurs systèmes et réseaux, les développeurs, les acheteurs, les utilisateurs, etc.</p>



</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<p><span style="font-size:1.5em;"><strong>Certificat de sobriété numérique</strong></span></p>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-678b08eb-b4f7-4548-9eeb-d4b9fcbb8217"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b79a54737b.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0c979019-a342-4766-b91c-2a64ee1540b3"><p>Un certificat de sobriété numérique considère que les utilisateurs peuvent réduire de manière significative leur impact sur l'environnement par leur choix d'équipement et leur comportement en ligne. Inspirés des obligations d'économie d'énergie mises en place dans plusieurs pays européens à partir de l'an 2000, ces certificats permettraient d'internaliser les externalités environnementales du numérique et de corriger certains dysfonctionnements du marché du numérique. Il se pourrait que dans les années à venir, l'usage de ces certificats se développe dans les entreprises, avec l'idée qu'il appartiendrait à la fonction RH de collecter auprès des managers des données sur ce qui a été mis en place pour inciter les salariés à réduire leur usage du numérique : politique de réunion, envoi d’emails, travail hybride, visioconférence, etc.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-2mBACaFf.jpg",

        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-138519da-a516-4b09-8269-bf6315bd58ce"><p><strong><span style="font-size:1.5em;">Conception responsable des services numériques</span></strong></p>

<p>Passer à une conception responsable des services numériques signifie créer de la valeur en concevant des produits et services numériques plus efficaces d'un point de vue environnemental, social et économique. Cela correspond aux nouveaux indicateurs de performance auxquels les entreprises doivent désormais faire face : au-delà de la performance économique, les organisations doivent également fournir des preuves de leurs performances sociales et environnementales. Cela ne signifie pas que certains projets ne seront pas approuvés, mais plutôt que la conception des services numériques doit être améliorée - par exemple, ne pas travailler au développement de certaines fonctions peu utilisées qui gaspilleraient des ressources. </p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-19678c96-8c5b-41a1-999d-10f2fce2a381"><p><strong><span style="font-size:1.5em;">Utiliser le matériel/les fonctions d'extinction automatique</span></strong></p>

<p>Cap Gemini a évalué 24 cas d'utilisation de technologies de l'information durables. L'analyse montre que l'arrêt automatique du matériel et des fonctionnalités permet de réaliser les économies les plus importantes (14 % en moyenne) grâce à la réduction de la consommation d'énergie tout en réduisant les émissions de carbone dans cette catégorie.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-625facb2-24e7-4f6e-a22b-082c8dbf4d7d"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b7a2a045c2.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-iNB15wRc.jpg",
        }
    ],
    "hybridHr": [
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-47f54b42-11d9-482f-8720-84285c76de62"><p dir="ltr"></p>

<p dir="ltr"><strong><span style="font-size:1.5em;">POLITIQUES RH</span></strong></p>

<p>Développer une politique de sensibilisation aux enjeux du développement durable et aux actions de l'organisation dans ce domaine à plusieurs stades : onboarding, formation continue...</p>

<ul>
\t<li>Mener une étude transversale qui implique lesa NWoW, et le bien-être et les enjeux du développement durable</li>
\t<li>Assurer la cohérence de toutes les politiques RH avec les objectifs de réduction des émissions de carbone</li>
</ul>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-40fb375e-8fd6-46af-9670-4af0cb630dbb"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a000f9022c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-VtIux57G.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.AdministrativeExpert, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-51d2007f-0995-4d97-be89-ede2d14b5c4b">

<p><strong><span style="font-size:1.5em;">RECRUTEMENT</span></strong></p>

<p><strong>Évaluer la conscience et les valeurs des candidats en matière de développement durable et leur compatibilité avec les ambitions et les objectifs de l'organisation </strong></p>

<p>Lors du recrutement, par exemple, allons-nous regarder l'empreinte carbone des candidats ? Allons-nous évaluer si leurs comportements sont en adéquation avec les ambitions de notre organisation ?</p>

<p>Les enjeux liés au climat constituent un formidable levier d'attractivité pour les candidats en quête de sens et de motivation pour les salariés déjà engagés sur la question.</p>

<p>Cependant, ce sont de plus en plus les candidats et les travailleurs qui posent ces questions : quelle est l'offre de mobilité de l'entreprise, existe-t-il une politique de travail à distance, quelles sont les initiatives en faveur du développement durable, etc. Si l'entreprise n'a pas de politique claire, elle sera condamnée à l'improvisation et aux réponses au cas par cas, ce qui risque de ne pas attirer les candidats prometteurs.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-82f5014d-e54b-4a0f-8cab-1a43a166b8b1"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a005f92b37.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-5DD74X27.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0c101637-6a96-4185-86a2-7b6ad760abbd">

<p><span style="font-size:1.5em;"><strong>FORMATION</strong></span></p>

<p><strong>Expliquer pourquoi la transformation durable est un enjeu pour les entreprises et les salariés</strong></p>

<p>Le premier défi consiste à comprendre les enjeux, en commençant par la science : ce que disent les scientifiques sur le changement climatique, les ressources naturelles, la biodiversité et l'impact sur nos vies - communautés, entreprises, individus.</p>

<p>Le deuxième défi est celui de l'action : la transformation durable touche tous les métiers. Métier par métier, il faut comprendre quels en sont les aspects concernés, puis établir une feuille de route pour l'action grâce à des formations spécifiques, selon que l'on travaille dans les achats, l'informatique, le juridique, la finance, le marketing, les RH, etc.</p>

<p><strong>Former le manager à gérer l'engagement des salariés : comment réagir lorsque, par exemple, le meilleur vendeur d’une équipe, en partance pour un séminaire, rappelle qu'il ne prend plus l’avion ?</strong></p>

<p><strong>Introduire les dimensions écologiques dans les bibliothèques de compétences</strong></p>

<p><strong>Anticiper les besoins en compétences de demain : quelles seront les compétences du nouveau comptable, du nouvel informaticien, du nouvel acheteur, du nouveau RH, etc. dans le cadre d'une transformation durable ?</strong></p>

<p>Intégrer le critère écologique dans le choix des prestataires, notamment pour la formation. Il est toujours possible d'informer et d'interpeller les prestataires sur ce critère de choix afin de les inciter à agir de leur côté.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8e9a2632-788a-489a-a9d0-a5cec6137a3a"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a00b6f3b38.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-H35LwF6W.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-78bed962-f188-4975-8f73-b0dea716bef1">

<p><span style="font-size:1.5em;"><strong>GESTION &amp; EVALUATION DE LA PERFORMANCE</strong></span></p>

<p>Les entreprises fixent de plus en plus d’objectifs de développement durable, qui doivent être mis en œuvre dans les services et les équipes pour être atteints. Il est donc important d'évaluer ce que les managers font, avec leurs équipes, pour atteindre ces objectifs.</p>

<p><strong>Il devient crucial d’intégrer les enjeux du développement durable dans les objectifs annuels des salariés et dans leur plan de développement des compétences.</strong></p>

<p>Très peu d'entreprises à ce jour traduisent ces objectifs de durabilité en indicateurs de performance. Quand elles le font, elles se réfèrent généralement aux 17 objectifs de développement durable des Nations Unies, mais il faut que ce soit beaucoup plus concret que cela.</p>

<p>Ces objectifs peuvent transparaître jusque dans les comportements quotidiens : prendre en compte l'aspect du développement durable lors de l'organisation d'une réunion, d'une fête d'entreprise, d'une offre de formation, d'un choix de voyage, etc.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-bcf69465-7d98-4b54-8f57-16c62f1e31cf"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a010e1bb7c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-xUv8hn2l.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c5702d04-36d7-48a5-bdc3-dfd6ae0b7e6e">

<p><span style="font-size:1.5em;"><strong>RÉMUNÉRATION</strong></span></p>

<p>Il existe une possibilité d’inclure des critères liés au développement durable dans le choix des avantages qui composent le package salarial ou dans la rémunération des dirigeants, des managers et même de tous les employés. Si les employés atteignent leurs résultats, mais au détriment de l'environnement, la prime sera-t-elle remise en question ?</p>

<p><strong>Encouragez et appréciez les comportements écologiques.</strong></p>


</div>`
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8c680f0a-8eaf-4e88-a823-ca934fad28e7"><p><span style="font-size:1.5em;"><strong>FLEXIBILITÉ DE TRAVAIL</strong></span></p>

<p>Proposez des lieux de travail alternatifs aussi proches que possible du lieu de résidence des employés (tiers-lieux, espaces de coworking, etc.).</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-335b1072-9195-49d8-bb14-1838c832c36b"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a013f7d826.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-aSD8KIPy.jpg",
        },
        {
            "roles": [roles.StrategicPartner, roles.ChangeAgent, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<p><span style="font-size:1.5em;"><strong>COMMUNICATION</strong></span></p>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-Er7RBrXn.jpg",

        },
        {
            "roles": [roles.ChangeAgent, roles.EmployeeChampion],
            "heading": false,
            "description": `<p>Communiquez de manière régulière plutôt que de manière ponctuelle. Une communication annuelle sur le sujet ne suffira pas, par exemple, si vous voulez que vos employés adhèrent et s'impliquent.</p>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": false,
            "description": `<p>Soyez authentique et transparent, notamment sur les progrès qui restent à faire. Votre discours doit être aligné sur la réalité de la trajectoire climatique de l'entreprise, sinon il ne sera pas bien accueilli par vos employés.</p>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": false,
            "description": `<p>Ciblez vos communications sur des sujets spécifiques. Par exemple : "boîte à outils pour agir au niveau de l'entreprise pour la transition écologique" ; "comment investir son épargne salariale dans des fonds au service de l'environnement" ; etc.</p>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": false,
            "description": `<p>Diversifiez les formats (mémo, vidéo, podcast, présentation, etc.) et les canaux utilisés (email, intranet, réseau social d'entreprise, road show, etc.) afin de maximiser la portée de vos messages.</p>`
        }
    ],
    "mobilityHr": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(ORGANISATION DU TRAVAIL) Formaliser les accords de travail à distance</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Il y a encore trois ou quatre ans, le travail à distance était largement informel. Aujourd'hui, il est de plus en plus formalisé en raison de son adoption généralisée dans les grandes organisations suite à la crise sanitaire. Cette formalisation peut prendre la forme d'une politique d'entreprise et/ou de discussions entre l'employeur/direction et les syndicats/salariés sur les lignes directrices dans lesquelles l'employeur souhaite encadrer le travail à distance. Face à la saturation des réseaux de transports publics et routiers, il y a un enjeu à développer le travail à distance comme moyen de réguler ces flux de mobilité.</p>
</div></div></div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/image-rWDYpb9a.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(RÉCOMPENSES) Pack mobilité durable</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p><strong>En France</strong>, les employeurs peuvent verser un forfait de mobilité durable à leurs employés qui utilisent un moyen de transport durable pour leurs déplacements professionnels. Les moyens de transport suivants sont couverts: le vélo personnel (y compris le vélo électrique), le covoiturage en tant que passager et conducteur, les services de mobilité partagée (covoiturage électrique ou hybride, location et mise à disposition en libre-service de scooters ou de vélos), les transports publics (à l'exclusion des abonnements). Le montant maximal du forfait mobilité durable est de 700 € par an et par salarié. Le montant versé est exonéré de cotisations sociales et d'impôt sur le revenu.</p>

<p dir="ltr"><strong>En Belgique</strong>, l'indemnité vélo est une indemnité kilométrique accordée par l'employeur aux salariés qui utilisent un vélo pour parcourir tout ou partie de la distance entre leur domicile et leur lieu de travail. L'indemnité est destinée à couvrir les frais du cycliste, mais elle vise surtout à inciter davantage de salariés à utiliser leur vélo. L’allocation kilométrique est exonérée d'impôt à hauteur de 0,25 euro par km parcouru pour l'exercice 2023, revenus 2022. En d'autres termes, tant que le montant de l'indemnité kilométrique  n'excède pas 0,25 euro par kilomètre parcouru, il n'y a pas d'impôt à payer. Si l'employeur accorde une indemnité kilométrique plus élevée, ce surplus est imposable en tant que revenu professionnel.</p>

<p dir="ltr"><strong>Vérifiez ce qui existe dans votre pays !</strong></p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(TEMPS DE TRAVAIL) Encourager les déplacements de mobilité douce</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>L'employeur peut créditer le temps de mobilité comme temps de travail, mais uniquement dans le cas de déplacements à mobilité douce - c'est-à-dire tout mode de transport qui n'utilise pas de moteur à combustion, ni n'émet de gaz à effet de serre. Cela présente l'avantage supplémentaire de donner à la personne la possibilité de voyager en dehors des heures de pointe.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(RECRUTEMENT) Recruter localement</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Recruter en tenant compte de la distance entre le domicile et le bureau dans la sélection des candidats pourrait être utilisé comme paramètre. Mais il sera d'autant plus difficile à activer que le marché du travail est tendu ou que les profils sont rares à trouver. Avec la très forte expérience que nous avons acquise en matière de travail à distance - imposée par Covid-19 -, le recrutement local continue de faire sens en soi, mais semble encore plus difficile à pratiquer.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>(BIEN-ÊTRE) La mobilité est importante</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr">La mobilité est l'un des piliers du bien-être au travail. Les approches peuvent alors être très transversales. La promotion du vélo peut, par exemple, contribuer à améliorer la forme physique, à stimuler la concentration au travail, voire à favoriser la vie en communauté.</p>

<p>.</p>
</div></div></div>`
        }
    ],
    "sustainableHr": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-752b8e3c-11a1-4c42-973e-dc3ef7f73ad2"><p dir="ltr"><strong><span style="font-size:1.5em;">S'impliquer en tant que partenaire stratégique </span></strong></p>

<p>Traditionnellement, les équipes RH ne participent pas à l'élaboration des stratégies de développement durable - en particulier dans le cas des stratégies d'informatique responsable - ni à leur mise en œuvre. Comme c'est le cas pour la plupart des initiatives dites « vertes », la direction générale a tendance à encourager les tendances et à s'appuyer sur le management intermédiaire pour la mise en œuvre. Cependant, en raison de leur implication dans le recrutement du personnel et de leur impact sur la culture d'entreprise, les membres des RH peuvent jouer un rôle déterminant dans l'introduction de ces normes.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-8eb9a5dd-38d3-4496-b38d-d53e12fad37c"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b6c7a88e25.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-365fb268-3738-49d5-bf8b-e5a5432ff57a"><p>En tant que directeur des ressources humaines, vous devez soutenir le développement de normes d'informatique responsables dans l'ensemble de l'organisation et collaborer avec le département informatique pour définir les tâches, les rôles et les responsabilités des employés.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-nt1UFmSu.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-ad83967d-c3aa-4c3a-b142-0ba6278bcfe8"><p><strong><span style="font-size:1.5em;">Formaliser les comportements en matière d'informatique responsable </span></strong></p>

<p>Formalisez les comportements des salariés en matière d'informatique responsable en élaborant et en introduisant des politiques. Ces politiques peuvent porter sur les points suivants :</p>

<ol>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">Des politiques d'utilisation du cloud, qui définissent l'utilisation optimisée des services de cloud par les employés, les sous-traitants et les fournisseurs tiers.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p>Des politiques demandant aux employés d'éteindre leur ordinateur à la fin de la journée ou d'utiliser des smartphones et des tablettes au lieu d'ordinateurs de bureau ou d'ordinateurs portables, lesquels consomment plus d'énergie et augmentent l'empreinte carbone.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p>Politiques d'utilisation responsable des équipements informatiques. Les politiques suivantes en sont de bons exemples :</p>
\t</li>
</ol>

<ul>
\t<li aria-level="2" dir="ltr">
\t<p>Mise en œuvre une politique de Bring Your Own Device (BYOD) (Apportez votre propre appareil).</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p>Les contrats de travail actuels peuvent stipuler que l'employé peut utiliser son propre matériel informatique et qu'il doit être rémunéré pour cela.</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p>Introduire une politique COPE (Corporate Owned, Personally Enabled).</p>
\t</li>
\t<li aria-level="2" dir="ltr">
\t<p>Un deuxième levier est l'utilisation d'équipements professionnels pour un usage personnel à domicile. Dans le passé, il était extrêmement rare que les entreprises l'autorisent.</p>
\t</li>
</ul>

<p><strong>In either case Dans les deux cas, ces approches permettent de s'assurer que les personnes ne se retrouvent pas avec un ordinateur portable professionnel et un ordinateur personnel. Il est possible de planifier une double session de démarrage pour les ordinateurs portables : l'une pour le travail et l'autre pour l'usage personnel.</strong></p>



<p><em>Bonne pratique </em></p>

<p dir="ltr"><strong>Travailler via un "plan cafétéria"</strong></p>

<p dir="ltr"><span style="font-size:0.875em;"><em>(si votre réglementation nationale autorise ce type de système)</em></span></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-c400386d-d3f1-4075-8e89-74d85fed26e6"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b6ea96369c.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-96f393ca-7d6a-4bd8-ab36-66d978b4130a"><p>En Belgique, le " plan cafétéria" est un programme qui permet au travailleur de choisir lui-même certains éléments de sa rémunération, mais toujours dans un cadre défini à l'avance par l'employeur.</p>

<p>Dans un tel cadre, vous pourriez inciter vos collaborateurs à l'achat de matériel reconditionnés plutôt que de nouveaux appareils, de la même manière qu'il est plus facile de subventionner les vélos électriques et moins les grosses voitures.</p>

<p dir="ltr"></p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-EUUxAwta.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-497686b5-fb48-4cd7-83ee-6654c0d12df9"><p dir="ltr"><span style="font-size:1.5em;"><strong>(FORMATION) Diminuer l'utilisation par la sensibilisation et la formation</strong></span></p>

<p>La formation est une dimension essentielle : les DRH devront s'assurer que les personnes de leur organisation ont la capacité de mener une stratégie numérique responsable, de la comprendre, de l'intégrer et de la déployer. Sans une réelle stratégie ni une opérationnalisation concrète, cela reste un vœu pieux.</p>

<p>Il existe de nombreuses bonnes pratiques à transférer, qu'il s'agisse de l'équipement, de l'utilisation de la vidéo ou même des emails.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-b33c71b4-d6d9-4523-a973-e59050b4fc11"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b6f84025db.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-84dfb087-83d8-46cb-a6c5-9d79a26fbeca"><p>Ainsi, plutôt que d'envoyer des documents par courrier électronique, il est préférable de les partager dans un dossier commun. Un lien vers un document partagé permet aux personnes d'avoir une seule copie sur laquelle travailler, plutôt que de s'envoyer des documents commentés qui sont répliqués sur plusieurs serveurs. Lors de l'envoi d'un courriel, au lieu d'établir des listes de destinataires étendues, choisissez soigneusement les personnes réellement concernées.</p>

<p>La suppression des emails est parfois recommandée. Cependant, il est également vrai que dans certains cas, l'effacement des emails peut avoir plus d'effets négatifs que leur conservation - ce qui peut sembler contre-intuitif ! La suppression d'une large quantité de mails (par exemple tous les courriels antérieurs à une certaine date) est une décision responsable, qui devrait être mise en œuvre chaque fois que possible. Toutefois, la suppression minutieuse des courriels au cas par cas utilise des ressources informatiques considérables et s'avère plus coûteuse pour l'environnement que leur conservation.</p>

<p>L’idée maîtresse de cette stratégie est de ne pas envoyer de données si elles ne sont pas utiles. L'envoi de données consomme jusqu'à l’équivalent d’un ou deux ans de stockage.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-7f6e4a48-84b3-4f31-8416-0206ba746cd6"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b6f743acbb.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d162dbf3-2ecf-4474-b605-3dae52c594d3"><p>Un tchat d'équipe est généralement préférable aux emails, notamment parce qu'il permet de retrouver les échanges dans l'historique et que l'information est alors stockée en un seul endroit. Toutefois là encore, tant que l'on s'en tient au texte, l’impact du stockage ou de l’envoi reste marginal. C'est plutôt le téléchargement de vidéos et, dans une moindre mesure, de photos qui aura de réelles conséquences.</p>

<p>Avant de créer un nouveau tutoriel à propos d’un sujet ou d’un environnement de travail donné, il est préférable de vérifier qu'il n'en existe pas déjà.</p>

<p>Quelques exemples de compétences à acquérir en formation :</p>

<ul dir="ltr">
\t<li>Compréhension des enjeux environnementaux mondiaux</li>
\t<li role="presentation">Compréhension des impacts de la technologie numérique</li>
\t<li role="presentation">Notion de cycle de vie d'un appareil</li>
\t<li role="presentation">Compréhension du cycle de vie d'un service numérique</li>
\t<li role="presentation">Identification des facteurs de consommation sur une page web, dans un logiciel, etc.</li>
\t<li role="presentation">Identification des impacts directs et indirects d'une technologie</li>
\t<li role="presentation">Maîtrise des enjeux et des bonnes pratiques liés à l'achat d'équipements, à la gestion des Déchets d'Équipements Électriques et Électroniques (DEEE) et au réemploi</li>
\t<li role="presentation">Capacité à mener une campagne de sensibilisation en interne ou auprès des fournisseurs et prestataires de services, etc.</li>
\t<li role="presentation">Accessibilité et éco-conception des services numériques</li>
\t<li role="presentation">Capacité à mesurer la consommation d'énergie pendant la phase d'utilisation des équipements numériques</li>
\t<li role="presentation">etc.</li>
</ul>

<p dir="ltr"><em><strong>Source: Guide des bonnes pratiques du numérique responsable pour les organisations, 2022.</strong></em></p>


</div>
</div>`,
            "imageSource": "https://nwow4net-zero.eu/images/t3/file-KOyYsXyp.jpeg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d4f52298-4fcd-41dd-bbc1-ff9edd5a07c9"><p><strong><span style="font-size:1.5em;">(DATA &amp; ANALYTICS) Une pratique à remettre en question</span></strong></p>

<p>La fonction RH n'a pas échappé à l'engouement pour les analytics et les données... Est-ce à juste titre ?</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-29bc9c0c-9fcc-47fc-adfa-8dc6e8b82f1c"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b6fcf40937.png" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3afc5921-814b-40b5-be49-8f4c1b457dbe"><p>Cela mérite d'être remis en question, et ce pour plusieurs raisons, selon les experts de l'Institut du Numérique Responsable. Très souvent, les services RH utilisent de grandes quantités de données pour fonctionner au sein de l'entreprise. Ces données sont souvent répétées et cloisonnées à différents endroits. Il faudrait cependant éviter toute duplication de données, en essayant d'avoir des points centraux où les données primaires sont disponibles et accessibles en cas de besoin. Il n'est alors plus nécessaire de copier, copier, copier... et donc de multiplier les quantités de données.</p>

<p>D'autre part, il est vrai que l'analyse des données est en plein essor dans les RH. Plus la structure est grande, plus les statistiques peuvent être intéressantes. Mais bien souvent, dans nos pays, les entreprises sont de petite taille, à quelques exceptions près. Il faut donc s'interroger sur la pertinence réelle de ces outils : les utilisons-nous parce qu'ils sont vraiment utiles ou parce que tout le monde le fait ? Réduisons nos besoins à ce qui est vraiment utile. C'est toute la valeur de la sobriété, qui s'applique aussi à l'informatique !</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-fwAS3Cih.png",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6ea068da-64a4-4efe-bb3e-01bf802add29"><p><strong><span style="font-size:1.5em;">(EVALUATION &amp; RECONNAISSANCE) Valoriser les actions positives </span></strong></p>

<p>Les actions positives des salariés doivent être récompensées pour être motivées. Ici, les DRH sont en première ligne. Cessons de valoriser uniquement les performances commerciales, le nombre de millions atteints, le nombre de publications ou de followers.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-791352ae-e018-4f65-a0ea-09af2c26f5f8"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b71b368d4e.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-92591520-9f7c-4687-a25f-cd04c71fdae6"><p>Valorisons aussi les actions positives pour le climat, pour le social, pour le bien-être. Rendons compte de ce qui est bien fait, de ce qui doit être fait. Cela implique de revoir les pratiques d'évaluation et de reconnaissance.</p>

<p>Il y a une véritable composante numérique responsable à inclure dans les entretiens d'évaluation : « Depuis combien de temps avez-vous votre ordinateur et votre smartphone ? », « Quels éco-gestes adoptez-vous ? », « Quelles pratiques pourriez-vous changer pour des pratiques numériques plus durables ? » sont des questions qui doivent être posées et incluses dans les discussions. Une grille d'action sur l'utilisation responsable du numérique devrait être demandée lors de chaque entretien professionnel.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-ZECdRYBX.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-4c81c2d8-43d1-4fb3-9ec9-e1e9e578b626"><p><strong><span style="font-size:1.5em;">(RÉCOMPENSE) Une partie de la rémunération variable devrait-elle être liée à la mise en œuvre de pratiques informatiques plus responsables? </span></strong></p>

<p>Une tendance croissante considère que le bonus vaut nettement mieux que le malus : la carotte vaut mieux que le bâton. Plutôt que de punir un comportement non durable (et risquer de créer un désengagement, voire des démissions), il est toujours plus bénéfique pour l'entreprise d'appréhender le changement de comportement sous un angle positif. Ainsi, plutôt que de donner un malus aux collaborateurs qui n'adhèrent pas à des comportements plus écologiques, récompensez ceux qui le font !</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d23a97ff-921c-43bd-be83-e9d189423939"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b7194630d5.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-8b65cbfd-c38d-478a-9698-541665e3f2c6"><p>En revanche, la distribution des bonus et des malus doit être faite avec précaution et surtout ne pas servir à monter les collaborateurs les uns contre les autres. Dire que seuls les « meilleurs » se verront récompensés est néfaste et malsain. C'est particulièrement vrai dans le cas des comportements verts : la plupart de ceux qui agissent ne le font pas pour obtenir un revenu supplémentaire.</p>

<p>En outre, l'octroi d'une prime peut entraîner davantage d'achats par la suite, ce qui n'est pas une bonne solution pour l'environnement. Il existe d'autres façons de valoriser l'engagement social que l'approche financière, et il faut inventer des moyens plus innovants.</p>

<p>Il serait bien plus judicieux d'inscrire le numérique responsable dans un processus de développement et d'encouragement, d'en faire une force et une opportunité dans un processus de participation.</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-MOtrX9gI.jpg",
        }
    ],
    "hybridInspiringInitiatives": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-0a30b813-ecda-4afb-a597-6e3fcdf3f9d4"><p><span style="font-size:1.5em;"><strong>Intégrer l'empreinte carbone des individus (i.e. les travailleurs à distance) dans le calcul de l'empreinte carbone de l'entreprise.</strong></span></p>

<p>Bien que l’empreinte carbone individuelle ne soit pas prise en compte dans la mesure de l’empreinte carbone de l’organisation, elle doit tout de même être considérée dans le cas du travail à distance, les activités de travail étant déplacées au domicile du salarié. En effet, l’empreinte carbone individuelle des salariés travaillant à distance va probablement augmenter en raison d’un usage accru d’électricité et de chauffage ou climatisation. Dans certains cas, l’empreinte individuelle augmentera encore davantage en raison d’un besoin d’espace supplémentaire dédié au travail au sein du domicile du salarié.</p>
</div>`,
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5f53795c-1d1a-4b3f-a966-fac758fec1c5"><p><strong><span style="font-size:1.5em;">Réduire le nombre de mètres carrés associés aux projets de relocalisation et aux nouvelles technologies de chauffage, ou de refroidissement du bâtiment.</span></strong></p>

<p>Introduire de nouvelles façons de travailler signifie réduire les effectifs présents dans les bureaux. Les bureaux existant peuvent alors être réagencés et les espaces de travail peuvent être réduits en termes de mètres carrés. Ce réagencement peut également être l’occasion d’investir dans des technologies de chauffage et de climatisation à faible consommation énergétique.</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d3378cd7-6379-438e-8695-2e00506e0b1e"><p><strong><span style="font-size:1.5em;">Fermer le siège social un jour par semaine.</span></strong></p>

<p>Proposer des solutions alternatives pour ceux qui ne sont pas intéressés par le télétravail en laissant ouvertes par exemple des agences réparties sur le territoire.</p>


</div>

<div class="h5p-column-content not-an-ios-device h5p-image-hotspots" id="h5p-interactive-book-section-0fddce02-1ea9-4c37-b17b-ba0e29cee19f"><div class="h5p-image-hotspots-container" style="width: 632.763px; height: 355px; font-size: 24px;"><img class="h5p-image-hotspots-background" src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/image-650a20abd3184.jpg" aria-hidden="true" style="width: 632.763px; height: 355px;"></div></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-db043ca5-705f-431d-aad5-39bd84f45d6f"><p><em><strong>Exemple :</strong></em></p>

<p><em>Depuis mai 2022, la compagnie d'assurance belge Ethias ferme ses bureaux nationaux (situés à Liège et Hasselt) un jour par semaine, et invite ses employés à travailler à domicile. Elle autorise également le télétravail jusqu'à 3 jours par semaine, soit 60% du temps de travail. Cette mesure, innovante sur le marché belge, permet à Ethias de réduire sa dépendance aux énergies fossiles. Elle vient s'ajouter aux nombreuses initiatives déployées ces dernières années, qui ont permis à Ethias de réduire d'un tiers son empreinte carbone, avec quatre ans d'avance sur son ambition initiale.</em></p>

<p dir="ltr"><em>Si l'évolution vers plus de télétravail répond au souhait de la grande majorité des employés, ceux qui ne le souhaitent pas ont toujours la possibilité d'effectuer leur journée de travail dans certains autres bâtiments. « Avant de rationaliser l'accès à ses bâtiments, Ethias a pris du recul et a évalué plusieurs scénarios pour s'assurer qu'ils étaient bénéfiques à la fois pour les employés et pour l'environnement. Selon les données de CO2Logic, le gain écologique du télétravail est positif dès que le trajet travail-domicile est supérieur à 2km, ce qui est le cas pour plus de 90% de nos employés ».</em></p>

<p dir="ltr"></p>
</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-Ux4xbbe0.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3316ea8d-10cc-4b2e-8619-da7fe9bbc817"><p><strong><span style="font-size:1.5em;">Développer une application permettant aux employés de sélectionner le bureau qu'ils occuperont avant de venir travailler.</span></strong></p>

<p>Pour que le travail hybride soit efficace, l’organisation doit fournir des outils facilitant l’utilisation des espaces de travail lorsque les salariés viennent travailler dans les bureaux. Développer une application permettant aux salariés de sélectionner le bureau qu’ils occuperont constitue une solution efficace pour gérer cette organisation. De telles applications sont disponibles sur internet, et certaines permettent une personnalisation de l’espace de travail.</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-7fa5861a-7573-4b91-8d58-ae8b84db7f88"><p><span style="font-size:1.5em;"><strong>Proposer aux employeurs d'investir, grâce à une rémunération défiscalisée, dans des solutions permettant de réduire l'empreinte carbone sur le lieu de travail de l'employé.</strong></span></p>

<p>On pourrait imaginer de convertir une partie de la rémunération, qui est aujourd'hui imposable, pour compenser la facture d'une installation géothermique, d'un panneau solaire ou d'un système de récupération d'eau au sein de l’entreprise ou au domicile de l’employé. </p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d7c13367-6a61-421e-a373-eebd7190de95"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650ae2a5b19be.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-GwVFSFMm.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-706a5767-ffee-4003-b5a9-c2fd99b9978d">

<p><strong><span style="font-size:1.5em;">Utiliser la gamification pour sensibiliser </span></strong></p>

<p>Par exemple, le jeu "Power Ranger" vise à mobiliser les employés sur la réduction de la consommation d'énergie.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-49e80623-fa73-4a32-ad4b-77f75ce8a5c4"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650a245a56b04.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-EUWiWwyc.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5ea9601e-15c0-41fa-b006-440a9fd9e737">

<p><span style="font-size:1.5em;"><strong>Offrir un conseil personnalisé</strong></span></p>

<p>Chaque situation étant singulière, il est nécessaire de pouvoir proposer une aide individualisée (pour discuter de ses pratiques de mobilités, ou des bons gestes à adopter chez soi), et d’offrir une petite incitation financière.</p>


</div>`
        }
    ],
    "mobilityInspiringInitiatives": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Supprimer des espaces de stationnement</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Pour décourager l'utilisation de la voiture pour se rendre au travail, certaines entreprises ont supprimé les places de stationnement réservées dans les entreprises. Si tous les employés sont presque assurés d'avoir une place à destination, ils utiliseront beaucoup plus la voiture pour se rendre au travail que s'ils ne le faisaient pas.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Faciliter la mobilité partagée</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Une entreprise brésilienne d'énergie a conçu une application pour faciliter la mobilité partagée sur son site de São Paulo : les utilisateurs saisiraient leur itinéraire de déplacement, et l'application croiserait les données avec toutes les autres entrées et présenterait les employés suivant des itinéraires similaires. La mobilité partagée a augmenté de 18 % le transport et a permis à l'entreprise d'économiser 300 000 USD par an.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Encourager le cyclisme</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p dir="ltr">Les meilleures pratiques les plus innovantes parmi les études de cas comprenaient la fourniture d'une meilleure sécurité pour les vélos (stations de stationnement verrouillables individuellement ; marquage de la police ou offre de programmes d'assurance vélo); le prêt d'équipements vélo (batteries, éclairages, pompes, antivols, casques, porte-vélos, sièges enfants, etc.) ; des cartes cyclables spécifiques au site ; incitations financières et produits complémentaires (par exemple, offre de gilets de sécurité).</p>

<p dir="ltr">Exemples d'actions positives : un partenariat pour la location de vélos cargos pour les livraisons ou l'utilisation par des techniciens, une campagne de prêt de vélos électriques pour permettre aux collaborateurs de les tester, un 'Bike to work tool' pour mesurer la neutralité carbone et participer à différents challenges.</p>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-image-hotspot-popup-content"><div class="h5p-image-hotspot-popup-header" tabindex="-1"><h2>Bureaux partagés décentralisés</h2></div><div class="h5p-image-hotspot-popup-body"><div class="h5p-image-hotspot-popup-body-fraction h5p-text"><p>Projet pilote d'espace de bureau partagé centralisé pour permettre à ceux qui ne peuvent pas faire de télétravail certains jours de ne pas avoir à faire tout le trajet jusqu'au siège, s'ils habitent loin ou si c'est trop compliqué pour eux. Fait avec un opérateur de coworking, avec un système centralisé de facturation.</p>

<h2 dir="ltr">Rendre le train plus compétitif</h2>

<p dir="ltr">Politique voyages d'affaires :</p>

<ul>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">allocation de fonds pour compenser le prix plus élevé de l'avion afin de rendre les trains compétitifs.</p>
\t</li>
\t<li aria-level="1" dir="ltr">
\t<p dir="ltr" role="presentation">pas le droit de prendre l'avion pour les trajets de moins de 2h qui peuvent se faire en train.</p>
\t</li>
</ul>
</div></div></div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-bd0d1aab-e9f3-4954-82d7-5ca803053822"><p><strong><span style="font-size:1.5em;">Approche "payer ce que je consomme"</span></strong></p>

<p dir="ltr">Approche "payer ce que je consomme" pour les voitures de société : les gens contrôlent ce qu'ils consomment + remboursement des km non consommés ou surconsommés / influence le prix de location et le budget qui sera alloué.</p>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/image-u29XBURD.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-6a2b61ed-ff09-4ac7-b91e-57a126803fc6"><p><span style="font-size:1.5em;"><strong>BONNES PRATIQUES</strong></span></p>

<p dir="ltr"><strong><em>Solvay lance un prix interne du carbone pour les voyages d'affaires</em></strong></p>

<p dir="ltr"><strong><em>Solvay déploie une nouvelle initiative pour suivre l'empreinte carbone des voyages de l'entreprise, collecter des fonds pour soutenir les projets de développement durable du Groupe et sensibiliser aux voyages responsables et durables. L'initiative vise à mettre en évidence l'empreinte carbone individuelle de chaque collaborateur.</em></strong></p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-729c6f13-e17a-4f6e-9735-eca08661ed72"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650ae6a3ab523.jpeg" alt="Solvay logo" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c18ce683-799a-4f5b-8956-09bf30e83725"><p><em>Depuis 2019, Solvay a réduit de près de 70 % sa consommation globale de carbone liée aux voyages en avion et en train, en grande partie grâce à la pandémie de Covid-19, mais aussi à la mise en œuvre de la nouvelle méthode de travail de Solvay, où les outils numériques ont rendu virtuel visites facilement accessibles à tous.</em></p>

<p dir="ltr"><em>Le Groupe s'attache désormais à pérenniser ces nouveaux comportements et à minimiser l'empreinte carbone des déplacements de l'entreprise en programmant moins de rendez-vous physiques, en réduisant le nombre de vols internationaux, en supprimant les trajets aériens d'une journée et en recommandant de passer de la classe affaires à la classe économique ou de l'avion à ferroviaire lorsque ces alternatives existent. Ces mesures sont mises en avant comme un moyen de sensibiliser à l'empreinte carbone individuelle de chacun.</em></p>

<p dir="ltr"><em>Le suivi de l'empreinte de voyage de Solvay financera en fin de compte un fonds qui sera utilisé pour parrainer des projets de développement durable axés sur la compensation carbone dans la mesure du possible. Les unités commerciales mondiales contribueront un montant monétaire basé sur l'empreinte de déplacement de l'entité calculée à 100 € par tonne de CO2. Ce montant sera facturé à chaque entité et transféré au Group Travel Carbon Contribution Fund.</em></p>

<p dir="ltr"><em>Ce fonds de contribution vise à atteindre les objectifs de durabilité des Nations Unies et à encourager chaque employé à faire sa part pour protéger la planète en tant que propriétaires et citoyens.</em></p>
</div>
</div>`
        }
    ],
    "sustainableInspiringInitiatives": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3f37caaa-982f-468c-be2a-72514d04656a"><p><strong><span style="font-size:1.5em;">Écrire une histoire </span></strong></p>

<p>La création d'un storytelling peut stimuler l'orientation sociale et obtenir l'engagement des employés. La mise en œuvre d'actions variées, telles que la réduction du courrier électronique, peut être injustement perçue comme du greenwashing. C'est pourquoi il est nécessaire de créer un récit cohérent au sein de l'entreprise. Vous devez avoir une véritable histoire à raconter, une histoire qui incarne une approche engagée, systémique et à long terme. Ce récit doit, bien sûr, commencer par de petites étapes, mais il doit aussi avoir une plus grande ambition.</p>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6326cb82-49ee-40c6-8a63-fa8377d14d27"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b7c89cd21f.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-fmT9o4iB.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d67971a-f08c-4bc2-8cb9-0d43903d2d07"><p><strong><span style="font-size:1.5em;">Développer un réseau de référents « numérique responsable » au sein de l'organisation</span></strong></p>

<p>Un tel réseau permet aux early adopters (pionniers) d'être les meilleurs ambassadeurs de votre politique. Ils ont une meilleure connaissance de leur organisation, surtout si celle-ci est répartie sur plusieurs sites. Ces early adopters sont formés, ils acquièrent donc des compétences et savent mobiliser les ressources à leur disposition pour faire circuler l'information en interne. <br>
</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-98d6b18e-4994-4314-bbaf-bf7d295acc68"><p><strong><span style="font-size:1.5em;">Limiter la géolocalisation </span></strong></p>

<p>Les ressources de géolocalisation (telles que Google Maps et Waze) ont révolutionné les déplacements, mais elles peuvent consommer des quantités considérables de données en raison des longs trajets et de la surutilisation générale. Conseils aux utilisateurs :</p>

<ul>
\t<li>
\t<p>Limitez l'utilisation des applications de géolocalisation lorsque vous voyagez vers des destinations déjà visitées.</p>
\t</li>
\t<li>
\t<p>N'utilisez qu'un seul service de géolocalisation.</p>
\t</li>
\t<li>
\t<p>Désactiver les données mobiles lorsque la géolocalisation n'est pas nécessaire (par exemple lors d'un long trajet sur l'autoroute).</p>
\t</li>
\t<li>
\t<p>Utilisez les options de géolocalisation hors ligne lorsque c'est possible.</p>
\t</li>
</ul>


</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-d5f8a0b6-ec6a-489d-b2d9-dd8db9bfbe80"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b7d2fccf93.png" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            "imageSource": "https://nwow4net-zero.eu/images/t3/file-LGlALooC.png",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-7e55a634-d22d-4d4b-a781-e9a043b632be"><p><strong><span style="font-size:1.5em;">Modifiez les paramètres de vos imprimantes </span></strong></p>

<p>Un gain rapide en termes de consommation d'énergie peut être réalisé dans la gestion du parc d'imprimantes. Il est intéressant de modifier les paramètres de mise en veille, de faible consommation ou de mode d'attente, ce qui permettra de réaliser des économies d'énergie. Avec des tableaux de bord de consommation d'énergie des imprimantes accessibles à tous, il s'agit d'un élément concret et « tangible » que tout le monde peut voir (voir l'étude de Cap Gemini sur les technologies de l'information durables: <a target="_blank" href="http://www.capgemini.com/fr-fr/wp-content/uploads/sites/6/2022/04/Final-Web-Report-Sustainable-IT-1.pdf">www.capgemini.com/fr-fr/wp-content/uploads/sites/6/2022/04/Final-Web-Report-Sustainable-IT-1.pdf</a>)</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-f6daacdd-2479-475f-9e8b-a567f54b59e3"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650b7d52602b9.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-X9LcEPlf.jpg",
        }
    ],
    "hybridRecommendedActions": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-b2dd1029-879d-4467-851d-7515066bd5a7"><p><strong><span style="font-size:1.5em;">Réorganiser les espaces de travail pour qu'ils consomment moins d'énergie.</span></strong></p>

<p>Cela peut se faire de plusieurs manières, comme revoir le choix des équipements et de l’alimentation, la régulation de l'éclairage, le type de matériaux utilisés pour les vitrages, améliorer l'isolation thermique, mettre en place des systèmes de chauffage permettant une utilisation plus ciblée, etc.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6a1ff1da-bea0-45f6-96ee-7326ac1f3395"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650ae72798dfa.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-e5jYUunU.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d7f97920-3d31-4127-9d19-2a50d6307c52">

<p><span style="font-size:1.5em;"><strong>Adapter son organisation en fonction des activités et des usages.</strong></span></p>

<p>Il s'agit de réfléchir en amont à l'organisation du travail et aux types d'activités à réaliser. Par exemple, assurer la planification des pratiques de télétravail pour pouvoir anticiper la présence ou non de personnes.</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-611eb0c2-4350-4c7f-a602-8723e8b51b8e"><p><span style="font-size:1.5em;"><strong>Éviter les solutions toutes faites.</strong></span></p>

<p>Ce qui peut être bénéfique pour une entreprise peut l'être beaucoup moins au niveau d'un centre urbain - par exemple, si ce dernier décline ou se vide en raison de la désertion de ses travailleurs... De même, toutes les personnes qui travaillent à distance ne sont pas égales : certaines disposent d'un espace de travail dédié et d'autres non. Le coût de l'énergie, le mode et la durée du déplacement, s'avèrent également être des variables importantes dans le choix du lieu de travail.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-54956a77-38f6-4873-8ff7-904c45dc5241"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650ae952abe3a.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-5SruR9UH.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c741d811-2784-453f-ae4a-6f38fd9c9466">

<p><span style="font-size:1.5em;"><strong>Les bonnes idées viennent souvent du personnel.</strong></span></p>

<p>L’approche bottom-up peut avoir un réel impact. Toutefois, le défi consiste à maintenir une approche cohérente. Si l'approche participative est isolée et ne s’inscrit pas dans une stratégie plus large, ou si les KPI de performance et financiers ne sont pas pris en compte, l'approche ne sera pas crédible. Attention au greenwashing !</p>


</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<p><strong><span style="font-size:1.5em;">Fermer les bureaux certains jours de la semaine pour économiser l'énergie.</span></strong></p>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-cfe59f92-0f7d-4a1b-8a5b-4dc310c7c7b8"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650ae9e7ad642.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3a5a13fb-d0a4-4437-87c0-8cca2baa698a"><p>Dans le cas où de nombreux salariés auraient tendance à travailler à distance certains jours, les organisations peuvent considérer la fermeture de leurs bureaux ces jours-ci. Il est recommandé de sélectionner des jours de fermeture consécutifs, ce qui conduira à des économies d’énergies supérieures. Par exemple, fermer les bureaux le lundi, à la suite de la fermeture du week-end, réduit l’énergie dépensée lors de la mise en marche et de l’arrêt des systèmes consommateurs d’énergie (chauffage, climatisation, etc.).</p>


</div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-ynBuLM58.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5b83f471-c473-4cc6-a446-bf3282adb362"><p><span style="font-size:1.5em;"><strong>Combiner le travail à distance, le flex-office et la réduction de l'espace de travail </strong></span></p>

<p>Une étude de l'ADEME (France) montre que si l'on prend en compte les réductions immobilières que le télétravail entraîne lorsqu'il est couplé au flex office (pratique consistant à ne pas avoir de bureau dédié sur le lieu de travail), le bilan environnemental global du télétravail s'améliore de manière significative de 52% par jour de télétravail par semaine (soit -234kg eqCO2/an pour chaque jour de télétravail supplémentaire par semaine). Les bénéfices environnementaux du flex office pourraient dépasser les bénéfices de la mobilité optimisée.</p>
</div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-2d3e4c85-3a9c-4d3a-a7e2-d1ea74bf3ce5"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650aea5b69adc.png" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t1/file-BKcUY47M.png",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3d25f0f5-0bf7-4f85-b059-69b9e42fb240">

<p><span style="font-size:1.5em;"><strong>Décourager le travail à domicile à temps partiel.</strong></span></p>

<p>Le télétravail   à temps partiel entraîne un bilan environnemental significativement négatif pour l’entreprise.<br>
</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-28e9f5f3-2c2d-4dbf-94b1-1e6affc73458"><img src="https://lp.youngkreativ.eu/pluginfile.php/392/mod_hvp/content/94/images/file-650aeaf74c038.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-cfa3ffa5-6b28-41ef-aa8a-4bc8bde9fc55">

<p><strong><span style="font-size:1.5em;">Télétravail : attention !</span></strong></p>

<p>Une étude de l'ADEME (France) montre que le travail à distance permet de réduire la consommation liée à l’usage des bureaux par l’entreprise (papier, encre, fournitures, gobelets, décoration, vidéoprojecteurs, etc). Cependant, certaines de ces réductions sont répercutées sur l’employé.   Ceci est particulièrement vrai   pour la consommation d'énergie à domicile   . L'étude estime que la consommation d'énergie faite à domicile pendant la journée de télétravail augmenterait mécaniquement : +20,7 kg eqCO2/an pour une journée de télétravail par semaine pour le télétravailleur.</p>


</div>`
        }
    ],
    "mobilityRecommendedActions": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-f3a1154d-cf21-4744-8f37-5b942ddae908"><p><span style="font-size:1.5em;"><strong>Mesurer : mais il vaut mieux avancer avec des mesures imparfaites</strong></span></p>

<p>Attendre la perfection est le meilleur moyen de ne rien faire…</p>
</div>`,
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-9c17bf2a-baba-43d2-a449-63d3a162ff6a"><p><strong><span style="font-size:1.5em;">Choisir un emplacement d'entreprise accessible par des moyens de transport durables</span></strong></p>

<p>Les entreprises n'ont pas la capacité de « recruter localement », ni d'imposer des lieux de vie à leurs salariés, sauf à prévoir un logement dans le cadre du contrat de travail. Ils ne peuvent influencer la distance domicile-travail, mais ils peuvent choisir un lieu accessible à leurs travailleurs par des moyens plus durables que la voiture.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-32b491be-187b-4b28-b25e-c93cdd367c4c"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650ae7da4ccaf.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-r5ICVTKu.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-42a6405c-6e2a-4f70-b7d8-501f3b5e3f66"><p><strong><span style="font-size:1.5em;">Planifier, exploiter, activer</span></strong></p>

<p>Le premier pilier est le diagnostic et la définition d'une stratégie. Vient ensuite la mise en place avec les services de mobilité, la gestion des données, le suivi financier, l'infrastructure, etc. La plupart des entreprises s'arrêtent là et voient ensuite que cela ne fonctionne pas. Pourquoi Tout simplement parce qu'il manque la dimension du changement de comportement.</p>

<p>Tout ce qui précède est nécessaire, mais pas suffisant. Vous devez "activer". Lorsqu'une entreprise vous indique qu'elle a placé dix vélos électriques dans la zone d'accueil, mais que personne ne les utilise, il manque cette étape : l'activation. Vous n'avez pas motivé les gens.</p>

<p>Les motiver peut se faire de différentes manières, comme organiser un jeu avec une récompense pour ceux qui les utilisent le plus.</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-7565574d-621a-4f00-b920-c201f9d2f507"><p><strong><span style="font-size:1.5em;">Acquérir une compréhension dans les déplacements de longue durée ou sur de très longues distances</span></strong></p>

<p>Ex : les doubles résidences liées au travail - soit avec une résidence secondaire, soit dans un hôtel, voire dans un AirBnB -, les doubles résidences liées à des personnes ayant deux emplois dans deux villes différentes ; déplacements très fréquents liés au travail, etc.</p>

<p>Cela mérite pourtant d'être considéré d'un point de vue RH, et plus encore lors d'un déménagement dans un nouveau lieu de travail en termes d'impact sur les déplacements et d'accompagnement nécessaire.</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-80c0adf5-cab3-4fbb-98bd-c46a956d2977"><p><strong><span style="font-size:1.5em;">Ne vous concentrez pas uniquement sur l'aspect budgétaire</span></strong></p>

<p>La logique du choix de la mobilité inclut aussi la vitesse (si l'on prend 1h30 en transports en commun, contre 40 minutes en voiture, l'argent n'aura pas beaucoup d'impact), mais aussi bien d'autres critères comme le confort (c'est-à-dire toute l'expérience du voyage : le train a un avantage comparatif si vous pouvez y faire d'autres activités - dormir, lire, travailler, etc. - mais cet avantage est annulé si le train est bondé et que vous n'avez pas de siège, par exemple), l'image ou expérience que vous avez des différents modes de transport, habitudes, sécurité, etc. Envisagez une politique de mobilité qui intègre toutes les dimensions (coût, vitesse, confort, etc.) pour que les alternatives soient attractives.</p>

<p>Passer d'un budget mobilité de 500 euros, à un budget de x kilos de CO2 et allouer le budget mobilité du collaborateur en conséquence, éventuellement avec des échanges entre collègues ou la possibilité d'échanger des économies contre des jours de congés.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-6594bb16-8d4a-40a3-baae-b2c961991ac6"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650ae87fc0ae4.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            "imageSource": "https://nwow4net-zero.eu/images/t2/file-HA5eJBjj.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-db56f5ca-9960-46ca-b07c-3aa08b24a38f"><p><strong><span style="font-size:1.5em;">Encourager la multimodalité</span></strong></p>

<p>Favoriser les transferts modaux afin que les gens minimisent le rôle de la voiture dans leurs déplacements. Les personnes qui vivent dans des régions peu peuplées ; les zones périurbaines peuvent être en mesure de réduire l'utilisation de la voiture si elles utilisent d'autres modes de transport en plus de la voiture.</p>

<p>Passer à la voiture augmentée plutôt qu'à la voiture diminuée : continuer à avoir une voiture de société, éventuellement plus petite, avec de meilleures performances environnementales, mais donnons aussi la possibilité aux gens de se déplacer autrement. Cela ne nécessite pas toujours d'augmenter l'offre, mais simplement d'accepter de faciliter l'accès aux transports en commun ou à d'autres formes de mobilité.</p>

<p>Il existe aujourd'hui une multitude de solutions multimodales.</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-2b595af0-7cfc-41da-b1a7-f849be29cfe8"><p><strong><span style="font-size:1.5em;">Voitures de société : faites attention à…</span></strong></p>

<p>Si l'entreprise se sent obligée de mettre à disposition des voitures de société (pour l'attraction des talents, la fidélisation, le statut, la représentation…), attention au type de véhicule, même si l'on sait que l'impact environnemental d'un véhicule électrique n'est pas non plus neutre.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-e2b6406f-ba7a-45b7-83b8-84806274d67f"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650ae8f013a9d.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-DgZHPTOT.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-5821544b-625c-4c3a-a64e-9ddf0f3eeb2f"><p><span style="font-size:1.5em;"><strong>Gérer les places de stationnement</strong></span></p>

<p>Si l'entreprise propose des places de parking, c'est en quelque sorte payer pour que les salariés utilisent leur voiture. Ce dont nous avons besoin, c'est simplement d'équilibrer les avantages entre les différents types de transport : si vous vous déplacez à vélo, vous n'occupez aucune place de parking. Si vous prenez moins de place, l'entreprise pourrait vous accorder une certaine somme - par exemple 200 euros - car vous faites économiser de l'argent à l'entreprise en venant à vélo.</p>

<p>Cibler les places de stationnement réservées aux gestionnaires, généralement mieux situées. Cet avantage hiérarchique ne reflète pas l'ambition de l'entreprise pour une mobilité moins carbonée. S'il y a un parking, les places les plus proches de l'entrée du bureau doivent être réservées au covoiturage.</p>
</div>`
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-d56820b5-cdac-4d65-b9c3-e7e19d058c6a"><p><span style="font-size:1.5em;"><strong>Utiliser des "ambassadeurs internes"</strong></span></p>

<p>Travailler avec des projets pilotes, avec de petits groupes d'ambassadeurs prêts à jouer ce rôle. Ne révolutionnez pas la mobilité dans votre entreprise ; commencez avec des gens qui sont volontaires et avec de petites initiatives qui germeront et se développeront. Mobiliser le top management : lorsque le PDG et les top managers enfourchent leurs vélos et le font régulièrement, cela a un effet majeur sur l'organisation.</p>
</div>

<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-96546409-df37-40a1-8000-e20cc49697d3"><img src="https://lp.youngkreativ.eu/pluginfile.php/393/mod_hvp/content/95/images/file-650ae968de311.jpg" alt="" title="" style="width: 100%; height: 100%;"></div>
</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t2/file-bJXBYOuI.jpg",
        },
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-3c1ff5d8-04bb-4246-b817-45823add9089"><p><span style="font-size:1.5em;"><strong>Mot-clé : Collaboration</strong></span></p>

<p>L' étude de Kiba-Janiak et Witkowski note qu'un indicateur clé d'un plan de mobilité réussi est la collaboration avec un réseau d'acteurs, qu'ils soient publics ou privés, et l'annonce comme inhérente à la réduction des émissions de GES.</p>




</div>
</div>`
        }
    ],
    "sustainableRecommendedActions": [
        {
            "roles": [roles.ChangeAgent, roles.StrategicPartner, roles.EmployeeChampion, roles.AdministrativeExpert],
            "heading": "",
            "description": `<div>
<div class="h5p-column-content h5p-image" id="h5p-interactive-book-section-5a468dcd-341e-4c76-a18e-49a772df8881"><img src="https://lp.youngkreativ.eu/pluginfile.php/398/mod_hvp/content/100/images/file-650afa383a24c.jpeg" alt="" title="" style="width: 100%; height: 100%;"></div>

<div class="h5p-column-content h5p-advanced-text" id="h5p-interactive-book-section-c9054d6f-c1fd-4779-9615-5a933109a477">

<p><strong><span style="font-size:1.5em;">Attention au travail à distance</span></strong></p>

<p>Une étude de l'ADEME en France montre que les effets positifs de la réduction des trajets domicile-travail sont modulés par d'importants effets rebonds. L'effet rebond est décrit comme défavorable en raison de quatre mécanismes, dont une augmentation des flux vidéo principalement liée à la vidéoconférence et une nouvelle consommation d'énergie à la maison (chauffage, éclairage, ordinateurs portables, etc.). L'étude pointe également de nouveaux effets systémiques ou de rebond à long terme à évaluer et à suivre, comme l'augmentation des équipements numériques.</p>

<p>L'impact du télétravail ne sera positif que si l'entreprise réduit par ailleurs l'espace de travail utilisé. Si elle les maintient et continue de les chauffer ou de les éclairer dans le cadre d’un environnement de travail hybride, le télétravail ne représente pas de gain réel.</p>




</div>

</div>`,
            // "imageSource": "https://nwow4net-zero.eu/images/t3/file-8IE7xrpv.jpeg",
        }
    ],
    "foreword": [
        {
            roles: [],
            "heading": "",
            "description": "Les nouvelles méthodes de travail (NWoW pour New Ways of Working) couvrent les pratiques de flexibilité spatio-temporelle (travail à distance, aménagement de l'espace de travail, horaires flexibles, etc.), les pratiques managériales (autonomie, confiance, participation, etc.), ainsi que les pratiques organisationnelles (équipes autonomies et semi-autonomes par exemple). \n" +
                "\n" +
                "C'est plutôt dans le travail flexible que l'on trouvera des leviers pour contribuer à la réduction des émissions de CO2, en jouant en particulier sur les environnements de travail."
        }
    ],
    "hybridLearningObject": [
        {
            "roles": [],
            "heading": "",
            "description": "L’objectif est de permettre aux professionnels des RH d’ouvrir la voie en créant un environnement de travail hybride qui non seulement soutient les objectifs organisationnels, mais contribue également positivement à la durabilité environnementale.\n" +
                "\n" +
                "1. Compétences en leadership et stratégie de travail hybride :\n" +
                "• Développer la capacité d'élaborer et de diriger efficacement des stratégies dans un environnement de travail hybride, en se concentrant sur l'intégration des principes de durabilité. Cela comprend l'acquisition de compétences nécessaires pour collaborer avec la direction et les équipes interfonctionnelles, afin d'intégrer des pratiques durables dans les environnements à distance et au bureau.\n" +
                "• Apprendre à créer une vision stratégique qui aligne les modèles de travail hybrides avec les objectifs de développement durable de l'organisation.\n" +
                "• Améliorer les compétences dans la conception de politiques et de pratiques de travail hybride qui réduisent l'empreinte carbone de l'organisation, comme l'optimisation des horaires de travail à distance pour minimiser les déplacements et la consommation d'énergie.\n" +
                "• Obtenir des informations sur l'évaluation et la sélection des emplacements de bureaux en fonction de l'accessibilité aux transports durables et du potentiel de réduction des émissions liées aux déplacements domicile-travail.\n" +
                "2. Gestion durable des RH dans des environnements hybrides :\n" +
                "• Cultiver les compétences en matière de gestion des ressources humaines de manière à soutenir et à améliorer l'efficacité des modèles de travail hybrides, tout en favorisant la durabilité. Cela comprend l'élaboration et la mise en œuvre de politiques RH qui encouragent des pratiques de travail respectueuses de l'environnement, tant à distance qu'au bureau.\n" +
                "• Apprendre à intégrer la durabilité dans le recrutement, la formation et la gestion des performances, en veillant à ce que le personnel soit équipé et motivé à s'engager dans des pratiques durables, quel que soit son lieu de travail.\n" +
                "• Améliorer les capacités d'utilisation des outils numériques et des stratégies de communication pour impliquer et soutenir efficacement les employés dans une configuration de travail hybride.\n",
        }
    ],
    "mobilityLearningObject": [
        {
            "roles": [],
            "heading": "",
            "description": "L'objectif global est de permettre aux professionnels des ressources humaines de mettre en œuvre des solutions de mobilité pratiques et innovantes, qui contribuent à réduire les émissions de carbone de l'entreprise et à améliorer la durabilité globale.\n" +
                "1. Engagement des parties prenantes et gestion stratégique de la mobilité :\n" +
                "• Développer les compétences nécessaires pour collaborer efficacement avec un large éventail de parties prenantes, notamment les PDG, la direction, les services financiers et les autorités publiques, pour créer et mettre en œuvre des plans de mobilité stratégiques. Cela implique de comprendre l'importance des décisions d'implantation des entreprises par rapport au bassin d'emploi et à l'accessibilité des transports publics, ainsi que leur impact sur l'empreinte carbone de l'entreprise.\n" +
                "• Acquérir des compétences pour diriger et influencer la direction afin qu'elle adopte une approche positive envers la mobilité professionnelle, dépassant la conformité pour intégrer activement la mobilité dans le cadre de l'expérience de travail.\n" +
                "• Apprendre à construire et à présenter des analyses de rentabilisation convaincantes aux services financiers, démontrant le retour sur investissement direct et significatif des projets de mobilité durable.\n" +
                "• Améliorer les capacités à mettre en place des stratégies de communication efficaces, en utilisant des outils tels que la gamification et la messagerie continue, pour changer le comportement des employés lié à la mobilité.\n" +
                "2. Mise en œuvre opérationnelle et innovation en mobilité :\n" +
                "• Apprendre à mettre en œuvre des stratégies de mobilité grâce à la nomination et à la formation de responsables de la mobilité capables de diriger des projets dans différents départements.\n" +
                "• Apprendre à rassembler et analyser des données sur les pratiques de mobilité actuelles et les préférences individuelles en matière de déplacements domicile-travail pour adapter les solutions de mobilité.\n" +
                "• Développer des compétences pour initier des démarches participatives, créer des ambassadeurs internes et favoriser la collaboration entre les collaborateurs pour promouvoir des modes de déplacement durables.\n" +
                "• Comprendre l'importance d'aborder les aspects pratiques tels que les politiques de stationnement automobile et d'encourager des alternatives comme le vélo, le covoiturage et l'utilisation des transports publics.\n" +
                "• Obtenir des informations sur l'exploitation d'outils technologiques tels que les plateformes d'itinéraires multimodaux et la mise en œuvre de forfaits de mobilité durable pour encourager les déplacements domicile-travail respectueux de l'environnement.\n",
        }
    ],
    "sustainableLearningObject": [
        {
            "roles": [],
            "heading": "",
            "description": "1. Leadership stratégique et gouvernance en matière de sobriété numérique :\n" +
                "• Développer des compétences pour défendre et renforcer l'importance d'un comportement numérique responsable au sein de l'organisation, en travaillant en collaboration avec les PDG, la direction et les DSI/directeurs informatiques.\n" +
                "• Développer des compétences pour influencer et intégrer la durabilité dans la vision et l'éthique de l'organisation, en particulier en ce qui concerne l'utilisation des technologies numériques.\n" +
                "• Apprendre à guider la direction dans l'intégration de critères numériques responsables dans la gouvernance de l'entreprise, en garantissant que la durabilité est une considération centrale dans chaque projet lié à l'informatique.\n" +
                "• Apprendre à pratiquer et à promouvoir l'exemplarité, incarnée par les dirigeants, en donnant des exemples clairs de pratiques numériques responsables.\n" +
                "• Acquérir des compétences en coordination avec les services informatiques pour développer une stratégie informatique responsable qui limite l'impact environnemental tout en répondant aux besoins des personnes, en se concentrant sur des aspects tels que Green IT, Human IT et IT for Human. Comprendre l'importance de travailler avec les fournisseurs d'approvisionnement et de matériel informatique pour mettre en œuvre des pratiques durables dans la fabrication, l'utilisation et le recyclage des équipements.\n" +
                "2. Mise en œuvre opérationnelle et pratiques numériques responsables :\n" +
                "• Développer des compétences dans la mise en œuvre de stratégies opérationnelles pour un comportement numérique responsable. Apprendre à travailler avec les achats pour prolonger la durée de vie des équipements informatiques et promouvoir une utilisation durable.\n" +
                "• Acquérir des compétences pour engager des fournisseurs de matériel informatique et de télécommunications pour des produits économes en énergie et pour sensibiliser le personnel à leurs avantages. Comprendre comment sensibiliser le personnel aux enjeux de durabilité liés aux technologies numériques, en favorisant une culture de sobriété numérique.\n" +
                "• Acquérir une expertise pour encourager le personnel à adopter des comportements numériques responsables, tels qu'une utilisation optimisée du cloud, des pratiques de courrier électronique et de vidéoconférences responsables. Apprenez à formaliser ces comportements à travers des politiques et à les intégrer dans les pratiques RH telles que la formation, l'évaluation et la reconnaissance.\n" +
                "• Développer la capacité à mesurer l'empreinte environnementale numérique de l'organisation et de chaque employé, et à utiliser ces données pour favoriser l'amélioration continue des pratiques de sobriété numérique.\n",
        }
    ],
    "inner_cont_title": "Auto-évaluation",
    "st_2_title": "Déterminons maintenant votre rôle RH prédominant dans l'organisation/l'entreprise",
    "st_2_p1": "Cet outil peut définir votre parcours d'apprentissage individuel inspiré du modèle « HR Business Partner Model » de Dave Ulrich (Ulrich, 1997) car il fournit des cadres et des conseils aux professionnels des RH pour aligner leurs activités aux objectifs de l'entreprise, conduire le changement organisationnel et créer de la valeur au sein de leur entreprise afin de maximiser l’impact et l’efficacité de leur fonction RH.",
    "st_2_p2": " Cet alignement garantit que les initiatives et pratiques RH soutiennent directement les objectifs de l'organisation et améliorent la performance globale. Le modèle suggère que les professionnels des RH devraient jouer quatre rôles clés : partenaire stratégique, expert administratif, champion des employés et agent de changement (Ulrich, 1997 ; Ulrich et Brockbank, 2005). La description de ces rôles est donnée dans le tableau suivant.",
    "st_3_title": "APPRENDRE SUR LES NOUVELLES FAÇONS DE TRAVAILLER CONTRIBUANT A L'AMBITION DE NEUTRALITE CARBONE DANS LES ENTREPRISES",
    "st_3_p1": "Les RH peuvent contribuer de trois façons :",
    "st_3_title_2": "Pour déterminer votre parcours de formation, veuillez s’il vous plaît sélectionner votre sujet d’intérêt:",
    "st_3_bullet_1": "Pour sélectionner une partie ou toutes les boîtes à outils (cases horizontales)",
    "st_3_bullet_2": "Pour sélectionner une partie ou tous les sujet d’intérêt Parties Prenantes, Culture, Outils, Processus RH, Initiatives inspirantes, Recommandations (cases verticales)",
    "st_3_r0_0": "Travail hybride",
    "st_3_r0_1": "Travail hybride – quelles formes d'hybridation entre le « travail de bureau » (travail au bureau, bureaux satellites, etc.) et le « travail à distance » (à domicile, dans d'autres lieux hors bureau...) peuvent le mieux contribuer à un impact environnemental positif, et dans quelles conditions ?",
    "st_3_r1_0": "Mobilité durable",
    "st_3_r1_1": "Mobilité durable – quelles méthodologies et solutions (numériques) peuvent contribuer à réduire les déplacements et avoir un impact positif sur l’empreinte carbone ?",
    "st_3_r2_0": "Utilisation responsable des technologies de l'information et sobriété numérique",
    "st_3_r2_1": "Utilisation responsable des technologies de l'information et sobriété numérique – Quelles sont les solutions (numériques) et les pratiques RH qui soutiennent le comportement numérique responsable et la sobriété numérique dans les organisations ?",
    "r_c_text_1": "Sur la base de vos réponses, votre rôle (actuel) dans les RH est considéré comme :",
    "r_c_text_2": "Nous recommandons ce parcours d'apprentissage pour participer au développement de nouvelles méthodes de travail pour une émission nette zéro dans votre entreprise/organisation.",
    "r_c_text_3": "Vous pouvez néanmoins sélectionner un rôle différent ou continuer avec celui-ci.",
    "r_c_text_4": "Veuillez sélectionner votre rôle préféré ou laissez-le tel quel et continuez :",
    "takeQuiz": "Répondez à ce quiz pour comprendre à quel rôle RH correspond votre activité et recevez des recommandations personnalisées",
    "takeQuizLabel": "FAITES LE QUIZ",
    "selectHrRole": "Sélectionnez votre rôle RH et obtenez des recommandations adaptées à votre poste spécifique.",
    "demographics": "Données démographiques",
    "yourRole": "Ton rôle",
    "interests": "Intérêts",
    "learningPath": "Parcours d'apprentissage",
    "continue": "Continuer",
    "back": "Dos",
    "selectPosition": "CHOISISSEZ UN RÔLE",
    "or": "OU",
    "hrmEductor": "SI VOUS ÊTES UN ÉDUCATEUR EN GRH, OU SI VOUS SOUHAITEZ SIMPLEMENT SAVOIR COMMENT LE PROCESSUS D'APPRENTISSAGE EST ENVISAGÉ AVEC NOS PARCOURS D'APPRENTISSAGE, EXPLOREZ NOTRE GUIDE D'APPRENTISSAGE!",
    "downloadPdf": "TÉLÉCHARGER LE PDF",
    "sm_tab_1": "1. Trousse à outils de formation",
    "sm_tab_2": "2. Parcours d'apprentissage",
    "sm_tab_3": "3. Outil d'impact",
    "sm_tab_4": "4. Trousse à outils interactives",
    "sm_tab_5": "5. Glossaire",
    "home_tab_label": "Page d'accueil",
    "about_tab_label": "À propos du projet",
    "project_tab_label": "Résultats du projet",
    "news_tab_label": "Nouvelles",
    "newsletters_tab_label": "Bulletins d'information",
    "contact_tab_label": "Contactez-nous",
    "StrategicPartner": 'Partenaire stratégique',
    "AdministrativeExpert": "Expert Administratif",
    "EmployeeChampion": "Champion des Employés",
    "ChangeAgent": "Agent de changement",
    "StrategicPartnerContent": "En tant que partenaires stratégiques, les professionnels des RH collaborent étroitement avec les dirigeants d'entreprise pour comprendre les objectifs stratégiques de l'organisation et aligner les pratiques et initiatives RH en conséquence. Ils contribuent à l’élaboration des stratégies d’entreprises et s’assurent que les activités RH soutiennent la réalisation de ces stratégies. Les partenaires stratégiques participent activement aux processus décisionnels et fournissent des informations sur la manière dont les RH peuvent contribuer au succès organisationnel.",
    "AdministrativeExpertContent": "Le rôle d’expert administratif se concentre sur la garantie d’opérations et de processus RH efficaces et efficients. Les professionnels des RH occupant ce rôle gèrent les tâches administratives courantes telles que la paie, l'administration des avantages sociaux, les politiques RH, la conformité et la tenue des dossiers. En gérant efficacement ces fonctions administratives, les professionnels RH libèrent du temps et des ressources pour se concentrer sur des initiatives stratégiques.",
    "EmployeeChampionContent": "Le rôle de champion des employés met l’accent sur l’importance de créer un environnement de travail positif et de défendre les besoins et le bien-être des employés. Les professionnels des RH dans ce rôle agissent comme un pont entre l'organisation et ses employés, favorisant l'engagement, la satisfaction et l'expérience globale des employés. Ils répondent aux préoccupations des employés, fournissent un soutien et favorisent une culture d'inclusion, d'équité et de respect.",
    "ChangeAgentContent": "Le rôle d'agent de changement met en évidence la responsabilité des RH dans la facilitation et la gestion du changement organisationnel. Les professionnels des RH agissent comme des agents de changement en identifiant et en répondant de manière proactive aux besoins de changement, en promouvant les initiatives de changement et en aidant les employés et les équipes à gérer les transitions. Ils jouent un rôle crucial dans la conduite de la transformation culturelle, de la restructuration organisationnelle et dans la promotion d’un état d’esprit prêt au changement au sein de l’organisation.",
    "hybrid": "Travail hybride",
    "SustainableMobility": "Mobilité durable",
    "responsibleUseOfIt": "Utilisation responsable des technologies de l'information et sobriété numérique\n",
    "hybridContent": "Travail hybride – quelles formes d'hybridation entre le « travail de bureau » (travail au bureau, bureaux satellites, etc.) et le « travail à distance » (à domicile, dans d'autres lieux hors bureau...) peuvent le mieux contribuer à une impact environnemental positif, et dans quelles conditions ?",
    "SustainableMobilityContent": "Mobilité durable – quelles méthodologies et solutions (numériques) peuvent contribuer à réduire les déplacements et avoir un impact positif sur l’empreinte carbone ?",
    "responsibleUseOfItContent": "Utilisation responsable des technologies de l'information et sobriété numérique – Quelles sont les solutions (numériques) et les pratiques RH qui soutiennent le comportement numérique responsable et la sobriété numérique dans les organisations ?",
    "nextBook": "SUIVANTE",
    "backBook":"DOS",
    "stakeholdersTitle": "Parties prenantes",
    "stakeholdersDesc":"Afin de contribuer à la réduction des émissions carbone de l'entreprise, le directeur/gestionnaire des ressources humaines doit travailler en étroite collaboration avec différentes parties prenantes.",
    "createCultureTitle": "Créer une culture de durabilité",
    "createCultureDesc": "Réaliser des changements de comportement et de consommation à travers la culture organisationnelle et managériale.",
    "toolsTitle": "Outils",
    "toolsDesc":"Outils pour soutenir la réduction des émissions",
    "hrPrTitle":"Processus RH",
    "hrPrDesc":"Adapter les processus RH pour contribuer à réduire les émissions de carbone",
    "inspiringTitle":"Des initiatives inspirantes",
    "inspiringDesc":"Intégrer l'empreinte carbone des individus (travailleurs à distance) dans le calcul de l'empreinte carbone de l'entreprise.",
    "recoTitle":"Recommandations",
    "recoDesc":"Recommandation pour mettre en œuvre la contribution des RH au Net-zéro",
    "ZeroCarbon": "Zéro carbone",
    "ZeroCarbonContent": "Zéro carbone fait référence à un produit ou service qui a la caractéristique de ne générer aucune émission de carbone, ou équivalents CO2 (CO2e). Par exemple, les sources d'énergie renouvelable comme l'éolien et le solaire sont classées comme zéro carbone car elles n'émettent pas de dioxyde de carbone (CO2) lors de la production d'électricité.\n" +
        "\n" +
        "En contraste, le net-zéro se rapporte à la pratique de compensation ou d'équilibrage de toutes les émissions de carbone générées par une entreprise.\n" +
        "\n" +
        "Enfin, atteindre des émissions de carbone net-zéro signifie qu'une activité résulte en une libération nette nulle d'émissions de carbone dans l'atmosphère, indiquant un état où les émissions sont efficacement contrebalancées ou annulées.",
    "Compensation": "Compensation volontaire (insetting)",
    "CompensationContent": "La compensation, souvent désignée sous le terme de « compensation carbone volontaire », implique l'acquisition délibérée et parfois obligatoire de crédits carbone pour contrebalancer les émissions attribuées à une entité particulière. Le coût associé à l'acquisition de crédits carbone à des fins de compensation sert de point de référence lors de l'évaluation des investissements visant à atteindre des réductions directes des émissions . Certaines émissions de gaz à effet de serre (GES) peuvent être intrinsèquement difficiles à éliminer complètement, et la compensation par le biais de crédits carbone joue un rôle central dans l'avancement de la neutralité climatique et des objectifs d'émissions nettes zéro. Les stratégies de compensation englobent diverses formes d'investissement, y compris celles dans les énergies renouvelables, l'efficacité énergétique, la reforestation, la capture du carbone et d'autres activités de mitigation du carbone quantifiablement efficaces. \n" +
        "\n" +
        "https://plana.earth/academy/what-are-scope-1-2-3-emissions",
    "Offsetting": "Compensation classique (offsetting)",
    "OffsettingContent": "La compensation carbone classique ou « offsetting » est une procédure qui implique la réduction ou l'élimination des émissions de dioxyde de carbone et d'autres gaz à effet de serre afin de contrebalancer les émissions générées dans d'autres endroits. Les projets de compensation carbone permettent aux entreprises et aux individus de participer à des initiatives environnementales concrètes visant à compenser leurs émissions de carbone. Ces initiatives de compensation englobent une gamme de technologies et d'activités, y compris la reforestation et la capture du carbone. La compensation carbone constitue un élément essentiel des stratégies de durabilité d'entreprise, en particulier lorsqu'elle complète les efforts visant à réduire les émissions de carbone, contribuant ainsi à l'objectif d'atteindre des émissions nettes zéro.",
    "ScienceBasedTargets": "Objectifs Basés sur la Science",
    "ScienceBasedTargetsContent": "L'initiative des Objectifs Basés sur la Science (Science-Based Targets initiative, SBTi) offre un cadre clair et défini pour que les entreprises réduisent systématiquement leurs émissions de gaz à effet de serre (GES), en se concentrant spécifiquement sur l'atteinte de chiffres de réduction d'émissions qui s'alignent sur les objectifs établis dans l'Accord de Paris sur le climat. Des objectifs sont considérés comme 'basés sur la science' lorsqu'ils sont en accord avec les dernières découvertes scientifiques, visant à atteindre l'objectif de limiter le réchauffement planétaire à 1,5 degré Celsius au-dessus des niveaux préindustriels au plus.\n" +
        "\n" +
        "La SBTi joue un rôle central dans la promotion des meilleures pratiques et l’apport de directives bien définies pour la réduction des émissions. Elle propose des méthodologies pour établir des objectifs de réduction des émissions basés sur la compréhension scientifique du changement climatique. L'initiative assiste activement les entreprises dans l'établissement d'objectifs de réduction de carbone qui sont entièrement conformes aux cibles stipulées dans l'Accord de Paris.",
    "Greenwashing": "Greenwashing",
    "GreenwashingContent": "Le greenwashing, éco-blanchiment ou verdissement, fait référence à une pratique trompeuse où une entreprise utilise la publicité et la communication publique pour se présenter comme étant plus respectueuse de l'environnement et plus durable qu'elle ne l'est réellement. C'est une stratégie utilisée par certaines entreprises pour détourner l'attention des consommateurs des impacts environnementaux néfastes de leurs opérations et modèles commerciaux. Les tactiques d'écoblanchiment peuvent se manifester sous différentes formes, y compris des campagnes publicitaires, des parrainages et des messages publics sur les médias traditionnels et les plateformes de réseaux sociaux.",
    "LifeCycleAssessment": "Analyse du Cycle de Vie (ACV)",
    "LifeCycleAssessmentContent": "L'ACV est une approche rigoureuse et scientifique utilisée pour évaluer l'empreinte écologique d'un produit ou d'un processus sur l'ensemble de son cycle de vie. Cette méthode prend en compte chaque étape, de l'extraction des matières premières jusqu'à la phase finale de mise au rebut ou de recyclage. L'ECV sert d'outil précieux pour quantifier les conséquences environnementales associées aux produits et aux processus, permettant ainsi aux entreprises de faire des choix éclairés et de mettre en œuvre des améliorations dans leurs efforts de durabilité.",
    "CarbonFootprint": "Empreinte Carbone",
    "CarbonFootprintContent": "Un indicateur du coût en GES, généralement en Dioxyde de Carbone, associé à une entreprise, un pays, un produit ou une activité. L’empreinte carbone inclue souvent à la fois les émissions de GES directes et indirectes. Par exemple, l'empreinte carbone d'un produit fabriqué avec des matériaux importés inclura à la fois les coûts en carbone du transport des matériaux importés et le coût de fabrication du produit. Ces empreintes devraient donner une idée de l'impact environnemental des entreprises et fournir un point de départ pour évoluer vers des pratiques plus respectueuses de l'environnement.",
    "EmissionsTradingScheme": "Système d'Échange de Quotas d'Émission (SEQE)",
    "EmissionsTradingSchemeContent": "Un Système d'Échange de Quotas d'Émission est un système de 'plafonnement et d'échange' qui fixe des plafonds d'émissions et impose des amendes aux entreprises qui les dépassent. Cependant, des crédits d'émission peuvent être accordés à ces entreprises, leur permettant de dépasser le plafond sans pénalité. Ces crédits peuvent être achetés ou vendus à d'autres entreprises, les encourageant ainsi à réduire leurs émissions, soit pour avoir plus de crédits à vendre, soit pour réduire leur besoin d'en acheter. De tels systèmes sont couramment utilisés dans le monde entier, le meilleur exemple étant le SEQE de l'UE.",
    "toolkit1": "TROUSSE À OUTILS 1",
    "toolkit2": "TROUSSE À OUTILS 2",
    "toolkit3": "TROUSSE À OUTILS 3",
    "forewordTitle": "Avant-propos",
    "learningObjectives": "Objectifs d'apprentissage",
    "pr3_home_title": "PR4 TROUSSE À OUTILS INTERACTIVES",
    "pr3_home_subtitle": "Trois boîtes à outils modulaires couvrant la modification des pratiques des ressources humaines pour répondre aux exigences découlant des nouvelles tendances en matière de technologie de l'information, de mobilité et de durabilité qui impactent les lieux de travail contemporains.",
    "pr3_home_toolkit_1": "TROUSSE À OUTILS",
    "pr3_home_toolkit_0_2": "De nouvelles façons de travailler",
    "pr3_home_toolkit_1_2": "Mobilité liée au travail",
    "pr3_home_toolkit_2_2": "Utilisation responsable de l'informatique",
}
