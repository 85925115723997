import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter, Navigate,
    RouterProvider,
} from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import PR2LearningPath from "./components/inner_cont/innerCont";
import {languages, routes} from "./Enums/Enums";
import Book from "./pages/book/book";
import Dialog from "./pages/dialog/dialog";
import BookContainer from "./pages/book/book_container";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: routes.pr2 + '/*',
                element: <PR2LearningPath/>
            },
            {
                path: routes.book + '/*',
                element: <BookContainer/>
            },
            {
                path: routes.dialog + '/*',
                element: <Dialog/>
            },
            {
                index: true,
                element: <PR2LearningPath/>
            },
            {
                path: '*',
                element: <Navigate to={`${routes.pr2}/${window.language ?? languages.en}`}/>
            }
        ]

    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
